import { CartItemsList } from './OrderSummary'
import useCollapsible from 'shop/hooks/Order/useCollapsible'
import styled from '@emotion/styled'
import { IoIosArrowForward } from 'react-icons/io'

interface CollapsibleListProps {
  children: React.ReactElement
  showLabel: string
  hideLabel: string
  hasBottomBorder: boolean
  lastPadding?: string
}

const CollapsibleList = ({
  children,
  showLabel,
  hideLabel,
  hasBottomBorder,
  lastPadding
}: CollapsibleListProps) => {
  const { ref, showItems, showScrollGuide, setShowItems } = useCollapsible()

  return (
    <div>
      <MobileCartItemToggle
        onClick={() => setShowItems(!showItems)}
        data-testid='listToggle'
      >
        {showItems ? hideLabel : showLabel}
        <Arrow rotate={showItems ? '90deg' : '0deg'} />
      </MobileCartItemToggle>
      <CartItemsList
        ref={ref}
        visible={showItems}
        hasBottomBorder={hasBottomBorder}
        lastPadding={lastPadding}
        data-testid='itemsList'
      >
        <ScrollGuideContainer>
          <ScrollGuide
            show={showScrollGuide}
            onClick={() => ref.current?.scrollTo(0, ref.current.clientHeight)}
          >
            ↓ Scroll down for more
          </ScrollGuide>
        </ScrollGuideContainer>
        {children}
      </CartItemsList>
    </div>
  )
}

export const ScrollGuide = styled.div(({ theme, show }: any) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  cursor: 'pointer',
  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.85)' },
  padding: '8px 16px',
  borderRadius: '36px',
  color: 'white',
  fontSize: theme.fontSizes[0],
  transform: show ? 'translateY(-8px)' : 'translateY(0)',
  transition: 'all 100ms ease-out',
  opacity: 0,
  pointerEvents: 'none',
  [theme.mediaQueries.viewport6]: {
    pointerEvents: show ? 'auto' : 'none',
    opacity: show ? 1 : 0,
    display: 'block'
  }
}))

export const ScrollGuideContainer = styled.div(() => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

const MobileCartItemToggle = styled.div(({ theme }: any) => ({
  borderTop: '0.5px solid #ddd',
  borderBottom: '0.5px solid #ddd',
  backgroundColor: '#fafafa',
  margin: '0 -16px',
  textAlign: 'center',
  padding: '16px',
  fontSize: theme.fontSizes[0],
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.mediaQueries.viewport6]: {
    display: 'none'
  }
}))

const Arrow = styled(IoIosArrowForward)(({ rotate }: { rotate: string }) => ({
  transition: 'all 100ms ease-out',
  marginLeft: '4px',
  transform: `rotate(${rotate})`
}))

export default CollapsibleList
