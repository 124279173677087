import { DeliveryAddress } from '../Checkout/types'

export type Payor = {
  amount: number
  name: string
  internal_reference: string
  status: string
}

export type DiscountCache = {
  code: string
  total_discount: number
  type: 'fixed' | 'percentage' | null
  target: 'all_products' | 'delivery_fee' | 'product_category'
  value: number
}

export type OrderAddress = DeliveryAddress & {
  geom: { coordinates: number[] }
  contact_num: string
  lat: number
  lng: number
}

export type StoreAddress = OrderAddress & { flat_number: string }

export type Store = {
  id: string
  email: string
  slug: string
  address: StoreAddress
  name: string
  pre_order_delivery: boolean
  is_courier_partner: boolean
  same_day_delivery: boolean
  pickup_tipping_enabled?: boolean
  delivery_tipping_enabled?: boolean
  oat_tipping_enabled?: boolean
}

export type CustomerInfo = {
  id: string
  first_name: string
  last_name: string
  contact_num: string
  email: string
}

export type Delivery = {
  cancelled_by: string | null
  default_transport_type: string
  delivery_status: string
  driver: Driver
  dropoff_time: string
  id: string
  initial_fee: string
  inserted_at: string
  job_id: string
  pickup_time: string | null
  proxy: string
  proxy_job_id: string
  quote_id: string
  service: string
  updated_at: string
  pincode: string
}

export type Driver = {
  first_name: string
  id: string
  last_name: string
  location: Location
  phone: string
  transport_type: string
}

export type Location = {
  id: string
  lat: number
  lng: number
}

export enum AccountCreationType {
  Loyalty = 'loyalty',
  Account = 'account'
}
