import gql from 'graphql-tag'

const ORDER_FIELDS = `
    status
    fulfilledAt
    delivery {
      dropoffTime
      pincode
      service
      status
      updatedAt
      driver {
        contactNumber
        firstName
        lastName
        coordinates {
          lat
          lng
        }
        transportType
      }
    }
    fulfillment {
      type
      orderType
      tableNumber
      window {
        from
        to
      }
    }
`

export const SUBSCRIBE_TO_ORDER = gql`
  subscription Order {
    order {
        ${ORDER_FIELDS}
      }
    }
  `
