import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { format } from 'date-fns'

interface WorkTimeProps {
  times?: { from: Date; to: Date }[]
  day: string
  closed?: boolean
}

export const WorkTime = ({
  closed = false,
  times = [],
  day
}: WorkTimeProps) => {
  const workTimes = closed ? (
    <Text>Closed</Text>
  ) : (
    times.map(({ from, to }) => {
      const fromTime = from && format(from, 'h:mm aaa')
      const toTime = to && format(to, 'h:mm aaa')

      return (
        <Text key={`${from}-${to}`}>
          {fromTime} - {toTime}
        </Text>
      )
    })
  )

  return (
    <DayLine isOpen={!closed}>
      <div>
        <OpenDay>
          <OpenSign isOpen={!closed} />
          <Day>{day}</Day>
        </OpenDay>
      </div>

      <OpenTime>{workTimes}</OpenTime>
    </DayLine>
  )
}

const DayLine = styled.div<
  StyledHTMLElement & { isOpen?: boolean },
  Required<Theme>
>(({ isOpen }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  color: isOpen ? '#595959' : '#BFBFBF'
}))

const OpenDay = styled.div(() => ({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
  color: 'inherit'
}))

const OpenSign = styled.div<
  StyledHTMLElement & { isOpen?: boolean },
  Required<Theme>
>(({ isOpen = false }) => ({
  width: '10px',
  height: '10px',
  borderRadius: '100%',
  background: isOpen ? '#52C41A' : '#BFBFBF'
}))

const OpenTime = styled.div(() => ({
  margin: '0',
  display: 'flex',
  flexFlow: 'column',
  gap: '6px',
  color: 'inherit'
}))

const Text = styled.p(() => ({
  fontSize: '14px',
  color: 'inherit',

  margin: 0,
  width: 'fit-content'
}))

const Day = styled(Text)(() => ({
  textTransform: 'capitalize'
}))
