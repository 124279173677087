export const thinScrollbar = (
  theme: any,
  color: string = theme?.colors?.lightgray
) => ({
  '&::-webkit-scrollbar': {
    display: 'block',
    width: '5px'
  },

  '&::-webkit-scrollbar-track': {
    background: 'none'
  },

  '&::-webkit-scrollbar-thumb': {
    background: color,
    borderRadius: '4px'
  },

  scrollbarWidth: 'thin',
  msOverflowStyle: '-ms-autohiding-scrollbar'
})

export const hideScrollbar = {
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  // Hide scrollbar for IE, Edge and Firefox
  msOverflowStyle: 'none' /* IE and Edge */,
  scrollbarWidth: 'none' /* Firefox */
}

export const commonButton = (theme: any) => ({
  border: 'none',
  padding: '12px',
  borderRadius: '12px',
  minHeight: '38px',
  backgroundColor: theme.colors['primary'],

  display: 'flex',
  justifyContent: 'center',

  color: '#fff',
  fontSize: '14px',
  fontWeight: 600,
  fontFamily: 'Roboto, sans-serif'
})

export const itemScollListShadows = (theme: any) => ({
  background: `
    /* Shadow Cover TOP */
    linear-gradient(
      white 30%,
      rgba(255, 255, 255, 0)
    ) center top,

    /* Shadow Cover BOTTOM */
    linear-gradient(
      rgba(255, 255, 255, 0), 
      white 70%
    ) center bottom,


    /* Shadow Cover TOP */
    linear-gradient(to bottom, rgb(240,240,240) 0%, white 100%) center top,

    /* Shadow Cover BOTTOM */
    linear-gradient(to top, rgb(240,240,240) 0%, white 100%)  center bottom
  `,

  backgroundRepeat: `no-repeat`,
  backgroundSize: `100% 40px, 100% 40px, 100% 20px, 100% 20px`,
  backgroundAttachment: `local, local, scroll, scroll`
})
