import React, { useState } from 'react'
import styled from '@emotion/styled'
import { IoIosPin as PinIcon } from 'react-icons/io'
import CustomerAddressForm from './CustomerAddressForm'
import AddressActions from './AddressActions'
import AddressRadioButtons from './Address/AddressRadioButtons'
import SpinnerModal from 'shop/components/Loader/SpinnerModal'
import { QUERY_CUSTOMER_ADDRESSES } from 'shop/client/queries/CustomerQueries'
import { CustomerAddress } from './types'
import { useShop } from 'shop/hooks'
import { Button } from '../Controls'

interface Props {
  addressList: CustomerAddress[]
  customerId: string
}

interface AddressProps {
  index: number
  address: CustomerAddress
  customerId: string
  openForm: (arg0: CustomerAddress, arg1: string) => void
  setIsLoading: React.Dispatch<boolean>
  requeryAddresses: () => void
}

const CustomerAddresses = ({ addressList, customerId }: Props) => {
  const [showForm, setShowForm] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState({})
  const [mode, setMode] = useState('create')
  const [addresses, setAddresses] = useState(addressList)
  const [isLoading, setIsLoading] = useState(false)
  const { useShopClient } = useShop()
  const client = useShopClient()

  const requeryAddresses = () => {
    client
      .query({
        query: QUERY_CUSTOMER_ADDRESSES,
        variables: { customerId },
        fetchPolicy: 'no-cache'
      })
      .then((results) => {
        setAddresses(results.data.addresses)
        setIsLoading(false)
      })
  }

  const emptyAddress = {
    id: '',
    line_1: '',
    line_2: '',
    zip: '',
    city: '',
    country: '',
    contact_num: '',
    flat_number: '',
    default_shipping_address: false,
    default_billing_address: false
  }

  const openForm = (address: CustomerAddress, modeValue: string) => {
    setMode(modeValue)
    setShowForm(true)
    setSelectedAddress(address)
  }

  const isAddressListEmpty = addresses.length === 0

  return (
    <Container>
      {isLoading && <SpinnerModal />}
      {showForm && (
        <CustomerAddressForm
          customerId={customerId}
          onClose={() => setShowForm(false)}
          mode={mode}
          address={selectedAddress}
          requeryAddresses={requeryAddresses}
        />
      )}
      <Header>
        <h3>Addresses</h3>
        <Button
          variant='link'
          size='md'
          onClick={() => openForm(emptyAddress, 'create')}
        >
          Create new address
        </Button>
      </Header>
      {isAddressListEmpty && (
        <p data-testid='emptyAddressMessage'>
          You don't have any saved addresses yet
        </p>
      )}
      {!isAddressListEmpty && (
        <AddressList data-testid='addressList'>
          {addresses.map((address: CustomerAddress, index: number) => (
            <Address
              key={index}
              {...{
                index,
                address,
                openForm,
                customerId,
                setIsLoading,
                requeryAddresses
              }}
            />
          ))}
        </AddressList>
      )}
    </Container>
  )
}

const Address = ({
  index,
  address,
  openForm,
  customerId,
  setIsLoading,
  requeryAddresses
}: AddressProps) => {
  const {
    default_shipping_address,
    default_billing_address,
    flat_number,
    line_1,
    label
  } = address

  const flatLabel = flat_number ? flat_number : ''
  const line1Label = line_1 ? line_1 : ''

  const toggleLoading = (state: boolean) => {
    setIsLoading(state)
  }

  return (
    <AddressContainer data-testid={`address-${index}`}>
      <AddressInfo>
        <AddressLabel>
          {label && label.length > 0 ? (
            <>
              <PinIcon />
              <span data-testid={`addressNickname-${index}`}>{label}</span>
            </>
          ) : (
            <p
              data-testid={`addNicknameLink-${index}`}
              onClick={() => {
                openForm(address, 'edit')
              }}
            >
              Add nickname
            </p>
          )}
        </AddressLabel>
        <p
          data-testid={`line1Label-${index}`}
        >{`${flatLabel} ${line1Label}`}</p>
        <p data-testid={`line2Label-${index}`}>{address.line_2}</p>
        <p
          data-testid={`cityPostcodeLabel-${index}`}
        >{`${address.city}, ${address.zip}`}</p>
      </AddressInfo>
      <AddressRadioButtons
        {...{
          index,
          default_shipping_address,
          default_billing_address,
          addressId: address.id,
          customerId,
          toggleLoading,
          requeryAddresses
        }}
      />
      <AddressActions
        {...{ index, address, openForm, requeryAddresses, toggleLoading }}
      />
    </AddressContainer>
  )
}

const Container = styled.div(({ theme }: any) => ({
  marginBottom: '20px'
}))

const Header = styled.div(({ theme }: any) => ({
  width: '100%',
  display: 'grid', // di ko mapagana sa flex hahahhahaa
  alignItems: 'center',
  gridTemplateColumns: '1fr auto',
  '> h3': {
    fontWeight: theme.fontWeights.bold
  },
  '> button': {
    padding: '8px 0'
  }
}))

const AddressList = styled.div(({ theme }: any) => ({
  maxWidth: '960px',
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridRowGap: '24px',
  [theme.mediaQueries.viewport6]: {
    gridTemplateColumns: 'repeat(3, 1fr)'
  },
  [theme.mediaQueries.viewport7]: {
    gridTemplateColumns: 'repeat(4, 1fr)'
  }
}))

const AddressContainer = styled.div(({ theme }: any) => ({}))

const AddressInfo = styled.div(({ theme }: any) => ({
  margin: '12px 0 8px',
  '> p': {
    margin: '4px 0',
    fontSize: theme.fontSizes[1],
    lineHeight: theme.lineHeights.condensedUltra
  }
}))

const AddressLabel = styled.div(({ theme }: any) => ({
  display: 'flex',
  '> svg': {
    marginLeft: '-4px'
  },
  '> span': {
    fontSize: theme.fontSizes[1],
    fontWeight: theme.fontWeights.bold
  },
  '> p': {
    margin: 0,
    fontSize: theme.fontSizes[1],
    fontWeight: theme.fontWeights.normal,
    color: theme.colors.textMute,
    cursor: 'pointer'
  }
}))

export default CustomerAddresses
