import styled from '@emotion/styled'
import { StyledHTMLElement } from 'shop/theme/types'
import Spinner from 'shop/components/Loader/Spinner'

const ProductInfoLoader = () => (
  <Container>
    <Spinner size='50px' />
  </Container>
)

const Container = styled.div<StyledHTMLElement>(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

export default ProductInfoLoader
