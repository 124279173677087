import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'

export const MobileBorderBottomWrapper = styled.div<
  StyledHTMLElement,
  Required<Theme>
>(({ theme }) => ({
  padding: '20px 0',
  borderBottom: `1px solid #f3f2f4`,
  [theme.mediaQueries.viewport10]: {
    border: 'none',
    padding: '0 0 8px 0'
  }
}))

export const DesktopBorderBottomWrapper = styled.div<
  StyledHTMLElement,
  Required<Theme>
>(({ theme }) => ({
  [theme.mediaQueries.viewport10]: {
    padding: '12px 0',
    borderBottom: `1px solid #f3f2f4`
  }
}))

export const Content = styled.div(() => ({
  maxWidth: '700px',
  width: '100%',
  margin: '0 auto'
}))
