import { useQuery, useMutation } from '@apollo/react-hooks'
import { createContext, useContext, useState } from 'react'
import {
  MUTATE_APPLY_DISCOUNT_CART_CONSUMER,
  MUTATE_REMOVE_DISCOUNT_CART_CONSUMER,
  MUTATE_APPLY_REWARD_CART_CONSUMER,
  MUTATE_CREATE_CART_CONSUMER,
  MUTATE_SWITCH_CART_STORE_CONSUMER,
  MUTATE_UPDATE_CART_CONSUMER,
  MUTATE_UPDATE_FULFILLMENT_CART_CONSUMER,
  QUERY_GET_CART_CONSUMER,
  QUERY_VALIDATE_CART_CONSUMER,
  useShopClient
} from 'shop/client'
import {
  ConsumerCart,
  ConsumerCartPayload,
  CreateCartResponse,
  CartMutationResponse,
  ConsumerCartUpdateResponse,
  ConsumerCartUpdatePayload,
  ConsumerCartFulfillmentUpdatePayload,
  ConsumerCartFulfillmentUpdateResponse,
  ConsumerCartWarning,
  ConsumerCartError,
  ConsumerCartApplyRewardResponse,
  ConsumerCartApplyDiscountResponse,
  ConsumerCartRemoveDiscountResponse,
  ConsumerCartValidations,
  ConsumerCartValidationsResponse
} from 'shop/types/cart'
import { useCart } from './useCart'
import { useShop } from './useGlobalContext'
import { getCurrentCartId } from 'shop/components/Cart/utils'
import {
  isUserLoggedInViaHasura,
  updateCartWithCustomerId
} from 'shop/components/Cart/utils'
import { ApiError } from 'shop/types'

interface CartState {
  cart: ConsumerCart | null
  setCart: (cart: ConsumerCart | null) => void
  cartLoading: boolean
  cartError: string | null
  setCartError: (error: string | null) => void
  initConsumerCart: (createCartPayload: {
    variables: ConsumerCartPayload
  }) => Promise<CartMutationResponse | void>
  getConsumerCart: () => Promise<ConsumerCart | null>
  updateConsumerCart: (
    variables: ConsumerCartUpdatePayload
  ) => Promise<CartMutationResponse | null>
  updateFulfillmentConsumerCart: (
    variables: ConsumerCartFulfillmentUpdatePayload
  ) => Promise<ConsumerCart | null>
  applyDiscountConsumerCart: ({
    variables
  }: {
    variables: { discountCode: string }
  }) => Promise<CartMutationResponse | null>
  removeDiscountConsumerCart: () => Promise<ConsumerCart | null>
  applyRewardConsumerCart: ({
    variables
  }: {
    variables: { rewardId: string }
  }) => Promise<ConsumerCart | null>
  clearConsumerCart: () => void
  switchConsumerCartStore: (storeId: string) => Promise<CreateCartResponse>
  validateCart: (
    skipValidate: boolean
  ) => Promise<ConsumerCartValidations | null>
  warnings: ConsumerCartWarning[]
  errors: ConsumerCartError[]
}

const CartContext = createContext<CartState>({
  cart: null,
  setCart: () => null,
  cartLoading: false,
  cartError: null,
  initConsumerCart: () => new Promise<CartMutationResponse>(() => {}),
  getConsumerCart: () => new Promise<ConsumerCart>(() => {}),
  updateConsumerCart: (variables: ConsumerCartUpdatePayload) =>
    new Promise<CartMutationResponse>(() => {}),
  updateFulfillmentConsumerCart: (
    variables: ConsumerCartFulfillmentUpdatePayload
  ) => new Promise<ConsumerCart>(() => {}),
  applyDiscountConsumerCart: () => new Promise<CartMutationResponse>(() => {}),
  removeDiscountConsumerCart: () => new Promise<ConsumerCart>(() => {}),
  applyRewardConsumerCart: () => new Promise<ConsumerCart>(() => {}),
  setCartError: () => null,
  clearConsumerCart: () => null,
  switchConsumerCartStore: () => new Promise<CreateCartResponse>(() => {}),
  validateCart: (skipValidate: boolean) =>
    new Promise<ConsumerCartValidations | null>(() => {}),
  warnings: [],
  errors: []
})

export const ConsumerCartProvider: React.FC = ({ children }) => {
  const { saveCartToLocalStorage } = useCart()
  const { merchant, config } = useShop()
  const shopClient = useShopClient()
  const [cart, setCart] = useState<ConsumerCart | null>(null)
  const [cartLoading, setCartLoading] = useState<boolean>(false)
  const [cartError, setCartError] = useState<string | null>(null)
  const [warnings, setWarnings] = useState<ConsumerCartWarning[]>([])
  const [errors, setErrors] = useState<ConsumerCartError[]>([])

  const cartId = cart?.id || getCurrentCartId(config.domain)

  const consumerCartContext = { context: { clientName: 'consumerApi' } }
  const [createCart] = useMutation<CreateCartResponse, ConsumerCartPayload>(
    MUTATE_CREATE_CART_CONSUMER,
    consumerCartContext
  )
  const { refetch: refetchConsumerCart } = useQuery<{ cart: ConsumerCart }>(
    QUERY_GET_CART_CONSUMER,
    {
      ...consumerCartContext,
      fetchPolicy: 'network-only',
      variables: { cartId },
      skip: true
    }
  )
  const { refetch: refetchValidateCart } =
    useQuery<ConsumerCartValidationsResponse>(QUERY_VALIDATE_CART_CONSUMER, {
      ...consumerCartContext,
      skip: true
    })
  const [updateCart] = useMutation<
    ConsumerCartUpdateResponse,
    ConsumerCartUpdatePayload
  >(MUTATE_UPDATE_CART_CONSUMER, consumerCartContext)

  const [updateCartFulfillment] = useMutation<
    ConsumerCartFulfillmentUpdateResponse,
    ConsumerCartFulfillmentUpdatePayload
  >(MUTATE_UPDATE_FULFILLMENT_CART_CONSUMER, consumerCartContext)

  const [applyDiscount] = useMutation(
    MUTATE_APPLY_DISCOUNT_CART_CONSUMER,
    consumerCartContext
  )
  const [removeDiscount] = useMutation(
    MUTATE_REMOVE_DISCOUNT_CART_CONSUMER,
    consumerCartContext
  )

  const [applyReward] = useMutation(
    MUTATE_APPLY_REWARD_CART_CONSUMER,
    consumerCartContext
  )
  const [switchCartStore] = useMutation(
    MUTATE_SWITCH_CART_STORE_CONSUMER,
    consumerCartContext
  )

  const createConsumerCart = async (createCartPayload: {
    variables: ConsumerCartPayload
  }): Promise<CreateCartResponse> => {
    const { data } = await createCart(createCartPayload)
    return data as CreateCartResponse
  }

  const initConsumerCart = (createCartPayload: {
    variables: ConsumerCartPayload
  }): Promise<CartMutationResponse | void> => {
    setCartLoading(true)
    return createConsumerCart(createCartPayload)
      .then(async ({ createCart }: CreateCartResponse) => {
        if (!createCart) {
          return
        }

        const { cart, errors, warnings } = createCart

        handleCartResponse(cart, errors, warnings)
        // cart creation success, clear any previous hard errors.
        setCartError(null)

        const { id: cartId } = cart
        const { id, slug } = merchant || {}

        const customerId = localStorage.getItem('customerId')

        /* Temp: If user has logged in via hasura API but has created cart via consumer API
         * we need to update the cart with customerId so cart & user are linked and
         * user can claim loyalty points */
        if (isUserLoggedInViaHasura(!!customerId) && customerId) {
          await updateCartWithCustomerId(shopClient, customerId, cartId)
        }

        saveCartToLocalStorage(cartId, { slug, id })

        return createCart
      })
      .catch((createCartError: ApiError) => {
        const { graphQLErrors } = createCartError
        let errorContent =
          graphQLErrors && graphQLErrors[0]
            ? graphQLErrors[0]['message']
            : 'cart_creation_error'
        if (errorContent === 'invalid_fulfillment_type') {
          errorContent = `${errorContent}_${createCartPayload.variables.fulfillmentType.toLowerCase()}`
        }

        setCartError(errorContent)
      })
      .finally(() => {
        setCartLoading(false)
      })
  }

  const getConsumerCart = (): Promise<ConsumerCart | null> => {
    setCartLoading(true)
    const cartId = cart?.id || getCurrentCartId(config.domain)
    if (!cartId) {
      setCartLoading(false)
      return Promise.resolve(null)
    }

    return refetchConsumerCart()
      .then(({ data }) => {
        if (data?.cart) {
          setCart(data.cart)
          setCartLoading(false)
          return data.cart
        }
        setCartLoading(false)
        return null
      })
      .catch((error) => {
        setCartError(error)
        setCartLoading(false)
        throw error
      })
  }

  const updateConsumerCart = (
    variables: ConsumerCartUpdatePayload
  ): Promise<CartMutationResponse | null> => {
    setCartLoading(true)
    return updateCart({ variables })
      .then(({ data }: { data?: ConsumerCartUpdateResponse }) => {
        const { updateCart } = data || {}
        if (updateCart) {
          const { cart, errors, warnings } = updateCart
          handleCartResponse(cart, warnings, errors)
          setCartLoading(false)
          return updateCart
        }
        setCartLoading(false)
        return null
      })
      .catch((error) => {
        setCartLoading(false)
        throw error
      })
  }

  const updateFulfillmentConsumerCart = (
    variables: ConsumerCartFulfillmentUpdatePayload
  ): Promise<ConsumerCart | null> => {
    setCartLoading(true)
    return updateCartFulfillment({ variables })
      .then(({ data }: { data?: ConsumerCartFulfillmentUpdateResponse }) => {
        const { updateCartFulfillment } = data || {}
        if (updateCartFulfillment) {
          const { errors, warnings, cart } = updateCartFulfillment
          handleCartResponse(cart, warnings, errors)
          setCartLoading(false)
          return cart
        }
        setCartLoading(false)
        return null
      })
      .catch((error) => {
        throw error
      })
      .finally(() => {
        setCartLoading(false)
      })
  }

  /** Exposes validateCart query & handles response */
  const validateCart = (skipValidate: boolean = false) => {
    if (skipValidate) return Promise.resolve(null)
    return refetchValidateCart()
      .then(({ data }: { data: ConsumerCartValidationsResponse }) => {
        if (data) {
          const { validateCart } = data
          const { warnings, errors } = validateCart || {}
          handleValidations(warnings, errors)
          return validateCart
        }
        return null
      })
      .catch((error) => {
        throw error
      })
  }

  /** Only set cart if it is non null (otherwise keep existing cart to avoid removing cart details on error */
  const handleCartResponse = (
    cart: ConsumerCart,
    warnings: ConsumerCartWarning[],
    errors: ConsumerCartError[]
  ) => {
    !!cart && setCart(cart)
    handleValidations(warnings, errors)
  }

  /** Sets and removes errors/warnings in case of them being cleared */
  const handleValidations = (
    warnings: ConsumerCartWarning[],
    errors: ConsumerCartError[]
  ) => {
    setWarnings(warnings.length ? warnings : [])
    setErrors(errors.length ? errors : [])
  }

  const applyDiscountConsumerCart = ({
    variables
  }: {
    variables: { discountCode: string }
  }): Promise<CartMutationResponse | null> => {
    setCartLoading(true)
    return applyDiscount({ variables })
      .then(({ data }: { data?: ConsumerCartApplyDiscountResponse }) => {
        const { applyDiscount } = data || {}
        if (applyDiscount) {
          const { cart, warnings, errors } = applyDiscount
          handleCartResponse(cart, warnings, errors)
          setCartLoading(false)
          return applyDiscount
        }
        setCartLoading(false)
        return null
      })
      .catch((error) => {
        setCartLoading(false)
        throw error
      })
  }

  const removeDiscountConsumerCart = (): Promise<ConsumerCart | null> => {
    setCartLoading(true)
    return removeDiscount()
      .then(({ data }: { data?: ConsumerCartRemoveDiscountResponse }) => {
        const { removeDiscount } = data || {}
        if (removeDiscount) {
          const { cart, warnings, errors } = removeDiscount
          handleCartResponse(cart, warnings, errors)
          setCartLoading(false)
          return cart
        }
        setCartLoading(false)
        return null
      })
      .catch((error) => {
        setCartLoading(false)
        throw error
      })
  }

  const applyRewardConsumerCart = ({
    variables
  }: {
    variables: { rewardId: string }
  }): Promise<ConsumerCart | null> => {
    setCartLoading(true)
    return applyReward({ variables })
      .then(({ data }: { data?: ConsumerCartApplyRewardResponse }) => {
        const { applyReward } = data || {}
        if (applyReward) {
          const { cart, warnings, errors } = applyReward
          handleCartResponse(cart, warnings, errors)
          setCartLoading(false)
          return cart
        }
        setCartLoading(false)
        return null
      })
      .catch((error) => {
        setCartLoading(false)
        throw error
      })
  }

  const clearConsumerCart = () => {
    setCart(null)
    setCartError(null)
  }

  const switchConsumerCartStore = async (storeId: string) => {
    const { data } = await switchCartStore({
      variables: { storeId },
      fetchPolicy: 'network-only'
    })
    return data as Promise<CreateCartResponse>
  }

  return (
    <CartContext.Provider
      value={{
        cart,
        setCart,
        cartLoading,
        initConsumerCart,
        getConsumerCart,
        updateConsumerCart,
        updateFulfillmentConsumerCart,
        applyDiscountConsumerCart,
        removeDiscountConsumerCart,
        applyRewardConsumerCart,
        cartError,
        setCartError,
        clearConsumerCart,
        switchConsumerCartStore,
        validateCart,
        warnings,
        errors
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export const useConsumerCart = () => useContext(CartContext)
