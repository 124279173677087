import React from 'react'
import styled from '@emotion/styled'

const Divider = () => (
  <DividerContainer>
    <LineDivider />
    <LineText>or</LineText>
    <LineDivider />
  </DividerContainer>
)

const DividerContainer = styled.div(({ theme }: any) => ({
  color: theme.colors.textMute,
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  margin: '20px 0'
}))

const LineDivider = styled.div(({ theme }: any) => ({
  flex: 1,
  borderBottom: `1px solid ${theme.colors.lineColor}`
}))

const LineText = styled.div(() => ({
  fontSize: '12px',
  margin: '0 8px'
}))

export default Divider
