import React, { useCallback, useEffect, useState } from 'react'

import { useAppContent, useLoader } from 'shop/hooks'
import { LoaderProps } from './types'

export const AppContentLoader = ({
  isParentLoaded = true,
  children
}: LoaderProps) => {
  const { fetchAppContent } = useLoader()
  const { setAppContent } = useAppContent()

  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const fetchData = useCallback(async () => {
    const appContentResult = await fetchAppContent()

    if (appContentResult) {
      setAppContent(appContentResult)
    }

    setIsLoaded(Boolean(appContentResult) && isParentLoaded)
  }, [fetchAppContent, setAppContent, isParentLoaded])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return <React.Fragment>{children(isLoaded)}</React.Fragment>
}
