import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'

interface Props {
  options: OptionProps[]
  hasBackground?: boolean
}

interface OptionProps {
  title: string
  type: string
  selected?: boolean
  onClick?(): any
}

const SelectablePill = ({ options, hasBackground = false }: Props) => {
  return (
    <PillContainer {...{ hasBackground }}>
      {options.map(({ title, type, selected, onClick }, index) => (
        <OptionContainer
          className={`option-${index} ${selected ? 'selected' : ''}`}
          onClick={onClick}
          key={`${type}-button`}
          data-testid={`${type}-button`}
        >
          <label htmlFor={title}>
            <Title>{title}</Title>
          </label>
        </OptionContainer>
      ))}
    </PillContainer>
  )
}

const PillContainer = styled.div<
  StyledHTMLElement & { hasBackground: boolean },
  Required<Theme>
>(({ theme, hasBackground }) => ({
  display: 'flex',
  position: 'relative',
  flexGrow: 1,
  backgroundColor: hasBackground ? theme.colors['white'] : '#f5f5f5',
  color: theme.colors['primary'] || '#000',
  borderRadius: '12px',
  height: '40px',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  [theme.mediaQueries.viewport7]: {
    height: '54px'
  }
}))

const OptionContainer = styled.div<
  StyledHTMLElement & { className: string },
  Required<Theme>
>(({ theme, className }) => ({
  display: 'flex',
  width: '50%',
  borderRadius: '12px',
  height: '40px',
  transition: 'border-color 200ms ease-in-out',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.mediaQueries.viewport7]: {
    height: '54px'
  },

  '& label': {
    zIndex: 2,
    cursor: 'pointer'
  },

  ':after': {
    background: `${className ? theme.colors['primary'] : null}`,
    content: '""',
    height: '100%',
    position: 'absolute',
    top: 0,
    transition: 'left 200ms cubic-bezier(0.77, 0, 0.175, 1)',
    width: '50%',
    borderRadius: '12px',
    zIndex: 1
  },

  '&.option-0': {
    ':after': {
      display: 'none'
    }
  },
  '&.option-1': {
    ':after': {
      left: 0
    }
  },

  '&.selected': {
    transition: 'color 200ms',
    color: `${className ? theme.colors['white'] : null}`,
    '&.option-1': {
      ':after': {
        left: '50%'
      }
    }
  }
}))

const Title = styled.h4<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  fontFamily: theme.fonts['heading'].family,
  fontWeight: theme.fontWeights['normal'],
  fontSize: theme.fontSizes[0],
  textTransform: 'uppercase',
  [theme.mediaQueries.viewport7]: {
    fontSize: theme.fontSizes[1]
  }
}))

export default SelectablePill
