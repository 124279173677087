import sanitizeHtml from 'sanitize-html'
import TruncateMarkup from 'react-truncate-markup'
import ReactHtmlParser from 'react-html-parser'

export interface SimpleFormatProps {
  children: string | undefined
  onTruncate?: (arg0: boolean) => void
  truncate?: boolean
}

export const allowedTags = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'blockquote',
  'p',
  'a',
  'ul',
  'ol',
  'nl',
  'li',
  'b',
  'i',
  'strong',
  'em',
  'strike',
  'abbr',
  'code',
  'hr',
  'br',
  'div',
  'table',
  'thead',
  'caption',
  'tbody',
  'tr',
  'th',
  'td',
  'tfoot',
  'span',
  'u'
]

export const allowedAttributes = {
  a: ['href', 'name', 'target'],
  abbr: ['title'],
  '*': ['style']
}

export const allowedStyles = {
  '*': {
    // Match HEX and RGB
    color: [
      /^#(0x)?[0-9a-f]+$/i,
      /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/
    ],
    'text-align': [/^left$/, /^right$/, /^center$/],
    // Match any number with px, em, or %
    'font-size': [/^\d+(?:px|em|%)$/]
  },
  p: {
    'font-size': [/^\d+rem$/]
  }
}

// From https://github.com/rilkevb/react-simple-format/blob/master/src/index.js
// https://github.com/rails/rails/blob/312485f3e88af3966b586275ae5097198bfef6a0/actionview/lib/action_view/helpers/text_helper.rb#L454-L460

const SimpleFormat = (props: SimpleFormatProps) => {
  const pattern = /([^\n]\n)(?=[^\n])/g
  const multibleBreakRow = /(<br\s*\/?>\s*){2,}/g
  const text =
    props.children !== undefined
      ? sanitizeHtml(props.children, {
          allowedTags,
          allowedAttributes,
          allowedStyles
        })
      : ''
  const paragraphs = text
    .replace(/\r\n?/g, '\n')
    .replace(multibleBreakRow, '<br />')
    .split(/\n\n+/)
    .map((t: string) => {
      if (t.match(pattern)) {
        return t
          .replace(pattern, '$1<br />')
          .replace(multibleBreakRow, '<br />')
      } else {
        return t
      }
    })

  const parser = new DOMParser()
  const parsedHtml = parser.parseFromString(paragraphs.join(' '), 'text/html')
  const markup = ReactHtmlParser(parsedHtml.body.innerHTML)

  if (props.onTruncate) {
    if (!props.truncate) return <div>{markup}</div>
    return (
      <TruncateMarkup lines={4} onTruncate={props.onTruncate} ellipsis={<></>}>
        <div>{markup}</div>
      </TruncateMarkup>
    )
  }

  if (paragraphs.length === 1) {
    return <div dangerouslySetInnerHTML={{ __html: paragraphs[0] }} />
  }

  return (
    <>
      {paragraphs.map((paragraph, index) =>
        index === paragraphs.length - 1 ? (
          <span key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
        ) : (
          <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
        )
      )}
    </>
  )
}

export default SimpleFormat
