import styled from '@emotion/styled'
import { UseFormMethods, FieldError } from 'react-hook-form'
import { scrollToElement } from './utils'

interface Props
  extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  label: string
  defaultValue?: string
  variant?: 'countryCode' | 'textbox'
  placeholder: string
  readOnly: boolean
  name: string
  // The any here is a React.Ref custom type from react-hook-form. The type isn't exposed
  formRef?:
    | UseFormMethods['register']
    | ((ref: any) => void)
    | ((ref: any) => any)
  hidden: boolean
  error?: FieldError
  inputType?: string
  onBlur?: () => void
}

const Container = styled.div((props: any) => ({
  position: 'relative',
  borderRadius: props.isTextbox ? '25px' : '50px',
  border: '5px solid transparent',
  borderColor: props.hasError && props.theme.colors.state.error[1]
}))

const Input = styled.input((props: any) => ({
  width: '100%',
  height: '40px',
  fontSize: props.theme.fontSizes[0],
  [props.theme.mediaQueries.viewport6]: { fontSize: props.theme.fontSizes[1] },
  fontWeight: props.theme.fontWeights.light,
  padding: '4px 22px',
  outline: 0,
  border: '1px solid #E7E5E9',
  borderRadius: '50px',
  borderColor:
    props.hasError && (props.theme.colors.state.error[5] || '#cc0000'),
  '&:focus': { borderColor: !props.hasError && props.theme.colors.black },
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',

  // Allows iOS to fill in contact number
  userSelect: 'text',
  WebkitUserSelect: 'text',

  '&::placeholder': {
    color: props.hasError ? props.theme.colors.state.error[5] : '#A4A4B3',
    fontWeight: props.hasError && 'bold'
  }
}))

const Textarea = styled.textarea((props: any) => ({
  width: '100%',
  height: '80px',
  fontSize: props.theme.fontSizes[0],
  [props.theme.mediaQueries.viewport6]: { fontSize: props.theme.fontSizes[1] },
  fontFamily: 'unset',
  lineHeight: '1.4em',
  fontWeight: props.theme.fontWeights.light,
  padding: '9px 22px',
  outline: 0,
  border: '1px solid #E7E5E9',
  borderRadius: '20px',

  // Allows iOS to fill in contact number
  userSelect: 'text',
  WebkitUserSelect: 'text',

  borderColor:
    props.hasError && (props.theme.colors.state.error[5] || '#cc0000'),
  '&:focus': { borderColor: props.theme.colors.black },
  resize: 'vertical',
  display: props.hidden ? 'none' : 'block',

  '&::placeholder': {
    color: props.hasError ? props.theme.colors.state.error[5] : '#A4A4B3',
    fontWeight: props.hasError && 'bold'
  }
}))

const ErrorMessage = styled.label((props: any) => ({
  fontSize: props.theme.fontSizes[0],
  margin: '4px 15px 0',
  color: '#cc0000',
  display: props.hidden ? 'none' : 'block'
}))

const Label = styled.label((props: any) => ({
  fontSize: props.theme.fontSizes[0],
  marginBottom: '8px',
  display: props.hidden ? 'none' : 'block'
}))

const InputField = ({
  label,
  variant,
  defaultValue,
  placeholder,
  readOnly,
  formRef,
  name,
  hidden,
  error,
  inputType,
  autoComplete,
  pattern,
  inputMode,
  autoFocus,
  onBlur
}: Props) => {
  return (
    <Container {...{ hasError: !!error, isTextbox: variant === 'textbox' }}>
      {variant === 'textbox' ? (
        <Textarea
          {...{ defaultValue, variant }}
          id={name}
          key={`${name}_textarea`}
          placeholder={`${label} ${placeholder ? `(${placeholder})` : ''}`}
          readOnly={readOnly}
          ref={formRef}
          name={name}
          hidden={hidden}
          hasError={!!error}
          data-testid={`${name}_textarea`}
          autoComplete={autoComplete}
          pattern={pattern}
          inputMode={inputMode}
          onFocus={() => !autoFocus && scrollToElement(name)}
          autoFocus={autoFocus}
          onBlur={onBlur}
        />
      ) : (
        <Input
          {...{ defaultValue, variant }}
          id={name}
          key={`${name}_input`}
          placeholder={`${label} ${placeholder ? `(${placeholder})` : ''}`}
          readOnly={readOnly}
          ref={formRef}
          name={name}
          hidden={hidden}
          type={inputType || 'text'}
          hasError={!!error}
          data-testid={`${name}_input`}
          autoComplete={autoComplete}
          pattern={pattern}
          inputMode={inputMode}
          onFocus={() => !autoFocus && scrollToElement(name)}
          autoFocus={autoFocus}
          onBlur={onBlur}
        />
      )}
    </Container>
  )
}

InputField.defaultProps = {
  label: '',
  variant: '',
  placeholder: '',
  readOnly: false,
  hidden: false
}

export default InputField
export { Textarea, Input, Label, ErrorMessage }
