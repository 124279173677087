import styled from '@emotion/styled'
import { getStoreUrl } from 'shop/utils/store'
import { useShop } from 'shop/hooks'
import { useLocation } from 'react-router-dom'

import { MobileNavBar, NavBar } from '.'
import BackButton from '../Buttons/BackButton'

const CheckoutNavbar = () => {
  const { cartSession } = useShop()
  const { cart } = cartSession
  const location = useLocation()

  const store = cart?.store

  return (
    <>
      <Mobile>
        <MobileNavBar
          leftAction={
            <BackButton
              url={
                location.pathname === '/checkout'
                  ? getStoreUrl(cart?.store.slug || '')
                  : '/checkout'
              }
              size='24px'
            />
          }
        />
      </Mobile>
      <Desktop>
        <NavBar transparent={false} page='checkout' storeSlug={store?.slug} />
      </Desktop>
    </>
  )
}

const Mobile = styled.div(({ theme }: any) => ({
  display: 'block',
  [theme.mediaQueries.viewport6]: { display: 'none' }
}))

const Desktop = styled.div(({ theme }: any) => ({
  display: 'none',
  [theme.mediaQueries.viewport6]: { display: 'block' }
}))

export default CheckoutNavbar
