import styled from '@emotion/styled'

// First child should be a <form> and second child <div> for this to work

const ContentWrapper = styled.div(({ theme }: any) => ({
  minHeight: `calc(100vh - ${theme.components.navbar.height})`,
  position: 'relative',
  margin: '0 auto',
  padding: '0',
  width: '100%',
  maxWidth: '1200px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column-reverse',
  [theme.mediaQueries.viewport6]: {
    display: 'grid',
    gridTemplateColumns: '55% 45%',
    padding: '0 24px'
  },
  [theme.mediaQueries.viewport7]: {
    gridTemplateColumns: '60% 40%'
  },
  '& > form': {
    backgroundColor: 'white',
    height: '100%',
    flex: '1 0 auto',
    padding: '0 0 64px',
    [theme.mediaQueries.viewport6]: {
      borderRight: '1px solid #ddd',
      padding: '28px 24px 24px 0'
    },
    [theme.mediaQueries.viewport8]: {
      padding: '28px 32px 24px 0'
    },
    [theme.mediaQueries.viewport12]: {
      padding: '28px 56px 24px 0'
    }
  },
  '& > div': {
    height: '100%',
    backgroundColor: 'white',
    [theme.mediaQueries.viewport6]: {
      position: 'sticky',
      top: 0,
      height: 'fit-content',
      paddingLeft: '24px'
    }
  }
}))

export default ContentWrapper
