import React, { useCallback, useEffect, useState } from 'react'
import { CheckoutOrderDetailsList } from './OrderDetailsList'
import Logo from './Logo'
import styled from '@emotion/styled'
import { useCart, useShop, useAppContent } from 'shop/hooks'
import { getStore, location, buildCartDate } from './utils'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import CartActions from './CartActions'
import { SearchBar } from '../Product'
import { IoIosSearch } from 'react-icons/io'
import { FaChevronLeft } from 'react-icons/fa'
import { Order } from 'shop/types/cart'
import { fadeIn } from '../Shop/commonStyles'
import { getLogoURL } from 'shop/utils/common'

interface NavBarProps {
  transparent: boolean
  page?: 'checkout' | 'shop' | 'order'
  order?: Order
  storeSlug?: string
}

const NavBar = ({ transparent, page, order, storeSlug }: NavBarProps) => {
  const { config, isStoreLoading, merchant } = useShop()
  const { logoImage } = useAppContent()

  const [isShopSearchVisible, setIsShopSearchVisible] = useState(false)

  const { cartSession, requestId } = useCart()
  const logoUrl = logoImage || getLogoURL(config.domain, config.assetHost)

  const cart = cartSession.cart || null

  const searchBarRef = React.useRef<HTMLInputElement | null>(null)
  const nav = React.useRef<HTMLElement | null>(null)
  const store = React.useRef<HTMLParagraphElement | null>(null)
  const [shopNavDetails, setShopNavDetails] = useState({
    logo: logoUrl,
    store: '...',
    date: '...',
    time: '...',
    address: '...',
    merchantId: merchant ? merchant.id : ''
  })

  useEffect(() => {
    if (transparent) window.onscroll = scrollHandler
  }, [transparent])

  const handleBlur = () => {
    setIsShopSearchVisible(false)
    searchBarRef.current?.blur()
  }

  const handleResize = useCallback(() => {
    if (isShopSearchVisible && window.innerWidth > 768) {
      setIsShopSearchVisible(false)
    }
  }, [isShopSearchVisible])

  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleBlur()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    window.addEventListener('keypress', handleKeyPress)

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('keypress', handleKeyPress)
    }
  }, [handleResize, handleKeyPress])

  const scrollHandler = () => {
    if (nav && nav.current && store && store.current) {
      if (document.documentElement.scrollTop > 10) {
        nav.current.classList.remove('-transparent')
        store.current.classList.add('-visible')
      } else {
        nav.current.classList.add('-transparent')
        store.current.classList.remove('-visible')
      }
    }
  }

  useEffect(() => {
    if (isStoreLoading || !cart) return
    setShopNavDetails(getNavDetails())
  }, [cart, isStoreLoading, requestId, cartSession.isCartLoading])

  // if DST, add 1 hour to time
  const getNavDetails = () => ({
    logo: logoUrl,
    store: getStore(cart),
    date: buildCartDate({ deliver_by: cart?.deliver_by }),
    time: cart ? cart.fulfillment_time_range : '...',
    address: location(cart),
    merchantId: merchant ? merchant.id : ''
  })

  const cartTableEnabled =
    cart && cart.metadata && cart.metadata.pickup_type === 'table'
  const orderTableEnabled = !!order?.fulfillment?.tableNumber
  const tableEnabled = cartTableEnabled || orderTableEnabled

  const slug = storeSlug || (order && order.store.slug)
  const tableLandingPath = `/table/${slug}`

  const handleSearchIconClick = () => {
    setIsShopSearchVisible(true)

    const inputHelper = document.createElement('input')
    inputHelper.style.position = 'absolute'
    inputHelper.style.opacity = '0'

    nav.current?.appendChild(inputHelper)
    inputHelper.focus()

    setTimeout(() => {
      searchBarRef.current?.focus()
      nav.current?.removeChild(inputHelper)
    }, 100)
  }

  // TODO: Make this more flexible
  return (
    <Nav
      ref={nav}
      className={transparent ? '-transparent' : ''}
      data-testid='nav-bar-component'
    >
      <NavActionContainer>
        <Flexbox>
          <FadeInWrapper hidden={isShopSearchVisible}>
            <Logo
              imageUrl={logoUrl}
              url={
                tableEnabled ? tableLandingPath : slug ? `/store/${slug}` : '/'
              }
            />
          </FadeInWrapper>
          {page === 'checkout' && (
            <Store ref={store}>Ordering from {shopNavDetails.store}</Store>
          )}

          {page === 'shop' && (
            <FadeInWrapper hidden={!isShopSearchVisible}>
              <FaChevronLeft onClick={handleBlur} />
            </FadeInWrapper>
          )}
        </Flexbox>

        <Flexbox justify='flex-end'>
          {page === 'shop' && (
            <SearchBarWrapper isActive={isShopSearchVisible}>
              <SearchBar ref={searchBarRef} />
            </SearchBarWrapper>
          )}
        </Flexbox>

        <Flexbox justify='flex-end'>
          {page === 'shop' && (
            <FadeInWrapper hidden={isShopSearchVisible}>
              <IconContainer onClick={handleSearchIconClick}>
                <IoIosSearch color='#000' />
              </IconContainer>
            </FadeInWrapper>
          )}

          {page === 'checkout' && (
            <CheckoutOrderDetailsList
              {...shopNavDetails}
              isDelivery={cart?.fulfillment_type === 'delivery'}
            />
          )}

          {page === 'shop' && (
            <FadeInWrapper hidden={isShopSearchVisible}>
              <CartActions {...shopNavDetails} />
            </FadeInWrapper>
          )}
        </Flexbox>
      </NavActionContainer>
    </Nav>
  )
}

const FadeInWrapper = styled.div<StyledHTMLElement>(({ hidden }) => ({
  display: hidden ? 'none' : 'flex',
  animation: `${fadeIn} 0.5s ease-in-out forwards`,
  height: '100%',
  alignItems: 'center'
}))

const IconContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    backgroundColor: '#fff',
    borderRadius: '12px',
    width: '32px',
    height: '32px',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '12px',

    '>svg': {
      width: '20px',
      height: '20px'
    },

    [theme.mediaQueries.viewport7]: {
      display: 'none'
    }
  })
)

const SearchBarWrapper = styled.div<
  StyledHTMLElement & { isActive: boolean },
  Required<Theme>
>(({ theme, isActive }) => ({
  width: '100%',
  margin: '0 auto',
  marginLeft: '12px',
  display: isActive ? 'block' : 'none',
  animation: `${fadeIn} 0.5s ease-in-out forwards`,

  [theme.mediaQueries.viewport7]: {
    animation: 'none',
    display: 'block',
    maxWidth: '560px',
    margin: '0 auto'
  }
}))

const Flexbox = styled.div<
  StyledHTMLElement & {
    justify?: 'space-between' | 'flex-end'
  },
  Required<Theme>
>(({ theme, justify = 'space-between' }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: justify,
  transition: 'all 100ms ease-in-out',
  width: '100%',

  [theme.mediaQueries.viewport7]: {
    display: 'flex'
  }
}))

const NavActionContainer = styled.div(({ theme }: any) => ({
  display: 'grid',
  gridTemplateColumns: 'min-content 1fr min-content',
  transition: 'all 100ms ease-in-out',
  fontWeight: theme.fonts['body'].weight,
  fontFamily: theme.fonts['body'].family,
  height: '32px',
  margin: '0 auto',
  maxWidth: '1920px',
  width: '100%',
  [theme.mediaQueries.viewport2]: {
    padding: '0px 0px'
  },
  [theme.mediaQueries.viewport7]: {
    gridTemplateColumns: '1fr 2fr 1fr',
    columnGap: '12px',
    maxWidth: '1300px',
    padding: '0px 0px'
  },
  [theme.mediaQueries.viewport14]: {
    maxWidth: '1920px',
    padding: '0px 60px',
    columnGap: 'none'
  },
  [theme.mediaQueries.viewport16]: {
    padding: '0px 0px'
  }
}))

export const Nav = styled.nav<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    fontWeight: theme.fonts['body'].weight,
    fontFamily: theme.fonts['body'].family,
    transition: theme['defaults'].transitions.background,
    padding: theme.components['navbar'].padding,
    height: '64px',
    color: '#fff',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: theme.designMode ? 'unset' : 'sticky',
    right: 0,
    left: 0,
    top: 0,
    backgroundColor: theme.colors['primary'],
    [theme.mediaQueries.viewport16]: {
      padding: '0px 75px'
    },
    '&.-transparent': {
      backgroundColor:
        theme.components['banner'].height === 'none' ? 'auto' : 'transparent',
      position: theme.designMode ? 'unset' : 'fixed',
      [theme.mediaQueries.viewport7]: {
        position: theme.designMode ? 'unset' : 'sticky',
        backgroundColor: theme.colors['primary']
      }
    },
    zIndex: 20
  })
)

export const Store = styled.p<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    fontSize: theme.fontSizes[0] as any,
    textAlign: 'center',
    opacity: theme.components['banner'].height !== 'none' ? 0 : 1,
    transition: 'all 500ms ease-out',
    '&.-visible': {
      transition: 'all 300ms ease-out',
      opacity: 1
    },
    whiteSpace: 'nowrap',
    display: 'none',
    [theme.mediaQueries.viewport12]: {
      display: 'block',
      position: 'relative',
      flexGrow: 1,
      opacity: 1,
      margin: '0 0 0 16px',
      textAlign: 'left'
    }
  })
)

export default NavBar
