import { FoodEstablishment } from 'schema-dts'
import { helmetJsonLdProp } from 'react-schemaorg'
import {
  useAppContent,
  useMerchantStore,
  useShop,
  useSlerpMerchant
} from 'shop/hooks'
import { createOpeningHours } from './utils'
import { Helmet } from 'react-helmet'

interface Props {
  storeSlug: string
}

export const Metadata = ({ storeSlug }: Props) => {
  const { config } = useShop()
  const { isSeoEnabled } = useSlerpMerchant()
  const { merchantName, bannerImage, splashImage } = useAppContent()
  const { storeName, storeAddress, openingTimes } = useMerchantStore()

  if (!isSeoEnabled) {
    return <></>
  }

  const { line1, line2, zip, city, geom } = storeAddress || {}
  const { coordinates } = geom || { coordinates: [0, 0] }

  const openingHoursSpecification = createOpeningHours(openingTimes || {})

  return (
    <Helmet
      script={[
        helmetJsonLdProp<FoodEstablishment>({
          '@context': 'https://schema.org',
          '@type': 'FoodEstablishment',
          image: [bannerImage || splashImage],
          name: `${merchantName} - ${storeName}`,
          address: {
            '@type': 'PostalAddress',
            streetAddress: line2 || line1,
            addressLocality: city,
            postalCode: zip,
            addressCountry: 'GB'
          },
          geo: {
            '@type': 'GeoCoordinates',
            latitude: coordinates[0],
            longitude: coordinates[1]
          },
          openingHoursSpecification,
          menu: `https://${config.domain}.slerp.com/order/store/${storeSlug}`
        })
      ]}
    />
  )
}
