import Decimal from 'decimal.js'
import { DiscountTarget } from './DiscountForm'
import {
  CheckoutForm,
  CheckoutFormV2,
  DeliveryAddress,
  DeliveryReduction,
  FORM_DEFAULTS_V2
} from './types'
import { ErrorOption, FieldError } from 'react-hook-form'
import { isEmpty } from 'lodash'
import { formatMoney } from '../Cart/utils'
import { Cart, Store } from '../Landing'
import {
  BaseAddress,
  CartErrorMessage,
  CartWarningMessage,
  ConsumerCart,
  ConsumerCartError,
  ConsumerCartWarning,
  DiscountWarningCode,
  DomesticAddress,
  FullAddress,
  LoyaltyCard,
  RewardStatus
} from 'shop/types/cart'
import { History } from 'history'
import {
  AbsintheError,
  CountryCode,
  DELIVERY_FULFILLMENT,
  FulfillmentType,
  ORDER_AT_TABLE_FULFILLMENT,
  PICKUP_FULFILLMENT
} from 'shop/types'
import { FORM_DEFAULTS } from 'shop/pages/Checkout'
import {
  getErrorMessage,
  isNetworkErrorUnauthorized,
  keysHaveValues
} from 'shop/utils/common'
import { default as countryCodes } from 'shop/countryCodes.json'
import parsePhoneNumberFromString from 'libphonenumber-js'
import { ApolloError } from '@apollo/client'

export const onlySpaces = (str: string) => {
  return /^\s*$/.test(str)
}

export const ukNumberRegex =
  /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/

export const redirectUrl = (cartId: string) => {
  return `https://${process.env.REACT_APP_CHECKOUT_HOST}/checkout_loading/${cartId}`
}

export const redirectUrlV2 = (
  transactionId: string,
  withTracking: boolean = false
) => {
  const origin = window.location.origin
  if (withTracking) return `${origin}/order/purchase/${transactionId}`
  return `${origin}/order/track/${transactionId}`
}

export const getQuotationErrorMessage = (graphQLErrors: AbsintheError[]) => {
  if (!graphQLErrors || graphQLErrors.length === 0)
    return 'We are unable to deliver to your location at the moment'

  return errorGlossary(graphQLErrors[0].error, graphQLErrors[0].message)
}

export const errorGlossary = (error_code: string, default_message: string) => {
  switch (error_code) {
    case 'SLERP_NO_PRICE_INFORMATION': {
      return 'Unfortunately, we do not currently deliver to your address.'
    }
    case 'SLERP_CART_DELIVERY_CHARGE_UPDATE_ERROR': {
      return 'We have encountered an error while updating the delivery cost. Please re-enter your address.'
    }
    case 'SLERP_CART_ADDRESS_UPDATE_ERROR': {
      return 'We have encountered an error while updating the your delivery details. Please re-enter your address.'
    }
    case 'SLERP_INVALID_ADDRESS_TYPES': {
      // missing geocode component types requirement
      return 'Sorry we are unable to find your address. Please try again with a different address, or choose pickup.'
    }
    case 'SLERP_POINT_OUTSIDE_POLYGON': {
      return 'Sorry the address you entered is outside our delivery area. Please try again with a different address, or choose pickup.'
    }
    case 'SLERP_OUT_OF_RANGE': {
      return 'Sorry the address you entered is outside our delivery radius. Please try again with a different address, or choose pickup.'
    }
    case 'SLERP_STUART_QUOTATION_EXCEPTIONAL_ERROR': {
      return 'We have encountered an unexpected error. Please contact support@slerp.com, or re-enter your address.'
    }
    case 'SLERP_THIRD_PARTY_GEOCODE_ERROR': {
      return 'Sorry we are unable to find your address. Please try again with a different address, or choose pickup.'
    }
    case 'SLERP_INCONSISTENT_FORMAT_ERROR': {
      return default_message
    }
    // Temp ugly hotfix until CheckoutV2: Have to switch and look for specific error message
    case 'SLERP_UNCATCHED_ERROR':
      if (default_message === '"SLERP_DISPATCH_PRICING_NOT_FOUND"') {
        return 'Sorry we are unable to deliver your address. Please try again with a different address, or choose pickup.'
      }
      return 'We have encountered an unexpected error. Please contact support@slerp.com, or refresh the page.'
    default: {
      return 'We have encountered an unexpected error. Please contact support@slerp.com, or refresh the page.'
    }
  }
}

export type DeliveryAddressErrorCode =
  | CartErrorMessage.COULD_NOT_GEOCODE
  | CartWarningMessage.ADDRESS_OUTSIDE_DELIVERY_AREA
  | CartWarningMessage.ADDRESS_OUTSIDE_DELIVERY_RANGE
  | CartWarningMessage.INVALID_ADDRESS

export type CartErrorCode =
  | CartWarningMessage.SUBTOTAL_BELOW_MINIMUM_ORDER_VALUE
  | CartWarningMessage.TOTAL_BELOW_MINIMUM_ORDER_VALUE

export type CheckoutV2ErrorCode = CartWarningMessage | CartErrorMessage

export type DiscountErrorCode =
  | CartErrorMessage.DISCOUNT_INVALID
  | CartErrorMessage.DISCOUNT_EXPIRED
  | CartErrorMessage.DISCOUNT_LIMIT_REACHED

/** Type guard function to check if a value is a valid DeliveryAddressErrorCode */
export const isDeliveryAddressErrorCode = (
  value: CheckoutV2ErrorCode
): value is DeliveryAddressErrorCode => {
  return [
    CartErrorMessage.COULD_NOT_GEOCODE,
    CartWarningMessage.ADDRESS_OUTSIDE_DELIVERY_AREA,
    CartWarningMessage.ADDRESS_OUTSIDE_DELIVERY_RANGE,
    CartWarningMessage.INVALID_ADDRESS
  ].includes(value as DeliveryAddressErrorCode)
}

export const isCartErrorCode = (
  value: CheckoutV2ErrorCode
): value is CartErrorCode => {
  return [
    CartWarningMessage.SUBTOTAL_BELOW_MINIMUM_ORDER_VALUE,
    CartWarningMessage.TOTAL_BELOW_MINIMUM_ORDER_VALUE
  ].includes(value as CartErrorCode)
}

export const isDiscountErrorCode = (
  validation: CheckoutV2ErrorCode
): validation is DiscountErrorCode => {
  return [
    CartErrorMessage.DISCOUNT_INVALID,
    CartErrorMessage.DISCOUNT_EXPIRED,
    CartErrorMessage.DISCOUNT_LIMIT_REACHED
  ].includes(validation as DiscountErrorCode)
}

export const isDiscountWarningCode = (
  validation: CheckoutV2ErrorCode
): validation is DiscountWarningCode => {
  return [
    CartWarningMessage.NO_TARGET_CATEGORIES_IN_CART,
    CartWarningMessage.NO_TARGET_PRODUCTS_IN_CART,
    CartWarningMessage.NO_TARGET_VARIANTS_IN_CART,
    CartWarningMessage.TOTAL_DISCOUNT_IS_ZERO,
    CartWarningMessage.MINIMUM_VALUE,
    CartWarningMessage.DISCOUNT_DELIVERY_ONLY,
    CartWarningMessage.DISCOUNT_NOT_APPLICABLE_TO_STORE,
    CartWarningMessage.CUSTOMER_LOGIN_REQUIRED
  ].includes(validation as DiscountWarningCode)
}

export const isTimeSlotError = (
  validation: CheckoutV2ErrorCode
): validation is CartWarningMessage.INVALID_TIMESLOT => {
  return validation === CartWarningMessage.INVALID_TIMESLOT
}

/** Returns UI messages based on consumer cart delivery address validation errors/warnings */
export const deliveryAddressErrorGlossaryV2 = (
  errorCode: DeliveryAddressErrorCode
): string | null => {
  switch (errorCode) {
    case CartWarningMessage.INVALID_ADDRESS:
    case CartErrorMessage.COULD_NOT_GEOCODE:
      return 'Sorry we are unable to find your address. Please try again with a different address, or choose pickup'
    case CartWarningMessage.ADDRESS_OUTSIDE_DELIVERY_RANGE:
    case CartWarningMessage.ADDRESS_OUTSIDE_DELIVERY_AREA:
      return 'Unfortunately, we do not currently deliver to your address'
    default:
      return null
  }
}

/** Returns UI messages based on consumer cart delivery address validation errors/warnings */
export const cartErrorGlossaryV2 = (
  errorCode: CartErrorCode,
  hasDiscount: boolean = false
): string | null => {
  switch (errorCode) {
    case CartWarningMessage.SUBTOTAL_BELOW_MINIMUM_ORDER_VALUE:
    case CartWarningMessage.TOTAL_BELOW_MINIMUM_ORDER_VALUE:
      const ctaDescription = hasDiscount
        ? `you can either keep shopping, or check out without your discount code.`
        : `take another look at our shop page and add more items to you cart.`
      return `Your cart total doesn't meet the minimum value required to check out. To continue, ${ctaDescription}`
    default:
      return null
  }
}

/** Takes warnings/errors array and finds error object using guard function */
export const findErrorWarning = (
  errorsOrWarnings: (ConsumerCartWarning | ConsumerCartError)[],
  errorCodeGuardFunc: (validation: CheckoutV2ErrorCode) => boolean
): ConsumerCartWarning | ConsumerCartError | undefined => {
  return errorsOrWarnings.find((item) => {
    return errorCodeGuardFunc(item.message)
  })
}

/** Takes warnings/errors array and finds error object using guard function */
export const findTopPrioErrorWarning = (
  errors: ConsumerCartError[],
  warnings: ConsumerCartWarning[],
  errorCodeGuardFunc: (validation: CheckoutV2ErrorCode) => boolean
): ConsumerCartWarning | ConsumerCartError | null => {
  const foundError = findErrorWarning(errors, errorCodeGuardFunc)
  if (foundError) return foundError

  const foundWarning = findErrorWarning(warnings, errorCodeGuardFunc)
  if (foundWarning) return foundWarning
  return null
}

/** Takes warnings/errors, finds the highest priority Cart error and sets it in the form for the user to see.
 * @returns The error code for reference
 */
export const findAndSetCartErrors = (
  errors: ConsumerCartError[],
  warnings: ConsumerCartWarning[],
  hasSubtotalDiscount: boolean,
  setFormError: (name: string, error: ErrorOption) => void,
  clearErrors: (name: string) => void
): CartErrorCode | null => {
  let cartErrorCode: CheckoutV2ErrorCode | undefined

  // Find cart error code from cart errors
  cartErrorCode = findTopPrioErrorWarning(errors, warnings, isCartErrorCode)
    ?.message as CartErrorCode

  if (cartErrorCode) {
    const cartErrorMessage = cartErrorGlossaryV2(
      cartErrorCode,
      hasSubtotalDiscount
    )
    if (cartErrorMessage) {
      setFormError('checkout_cart', {
        type: 'manual',
        message: cartErrorMessage
      })
      return cartErrorCode
    }
  } else {
    // clear any previously set errors
    clearErrors('checkout_cart')
  }
  return null
}

/** Takes warnings/errors, finds the highest priority Delivery error and sets it in the form for the user to see.
 * @returns The error code for reference
 */
export const findAndSetDeliveryErrors = (
  errors: ConsumerCartError[],
  warnings: ConsumerCartWarning[],
  setFormError: (name: string, error: ErrorOption) => void,
  clearErrors: (name: string) => void
) => {
  let deliveryErrorCode: DeliveryAddressErrorCode | undefined

  // Find delivery error code from cart errors
  deliveryErrorCode = findTopPrioErrorWarning(
    errors,
    warnings,
    isDeliveryAddressErrorCode
  )?.message as DeliveryAddressErrorCode

  if (deliveryErrorCode) {
    const deliveryErrorMessage =
      deliveryAddressErrorGlossaryV2(deliveryErrorCode)
    if (deliveryErrorMessage) {
      setFormError('address_api', {
        type: 'manual',
        message: deliveryErrorMessage
      })
      return deliveryErrorCode
    }
  } else {
    // clear any previously set errors
    clearErrors('address_api')
  }
  return null
}

/** Default address error message for consumer cart delivery address errors/warnings */
export const defaultCheckoutErrorMessage =
  'We have encountered an unexpected error. Please contact support@slerp.com, or refresh the page'

export const getTargetDescription = (target: DiscountTarget) => {
  const default_description = 'your order' // just in case

  if (target === null) {
    console.error('Discount does not have a target')
    return default_description
  }

  const descriptions = {
    delivery_fee: 'delivery fee',
    all_products: 'all products',
    category: 'select products',
    product: 'select products',
    subtotal_delivery_fee: 'all products & delivery fee',
    all_charges: 'all products, delivery fee and additional fee'
  }

  const description = descriptions[target]

  if (!description) {
    console.error('Discount does not have a valid target')
    return default_description
  }

  return description
}

export const inputCharactersLimitValidator =
  (name = 'N/A', limit = 255) =>
  (value: string) => {
    if (value.length > limit) {
      return `Character limit in the ${name} is ${limit}, please remove ${
        value.length - limit
      } character(s)`
    }
    return true
  }

export const hasCustomField = (
  cart_details: {
    fulfillment_type: string
    id: string
  },
  field_details: {
    enabled: boolean
    name: string
    fulfillment_types: string[]
  }
) => {
  const { enabled, name, fulfillment_types } = field_details
  const isNameValid = name && name !== ''
  const areFulfillmentTypesValid =
    fulfillment_types && fulfillment_types.length > 0

  if (!enabled || !isNameValid || !areFulfillmentTypesValid) return false
  return fulfillment_types.includes(cart_details.fulfillment_type)
}

export const getAmountNeeded = (
  hasValidMOV: boolean,
  minimumOrderValue: number | null,
  subtotal: number
) => {
  if (hasValidMOV || !minimumOrderValue) return 0
  const decimalMinimumOrderValue = new Decimal(minimumOrderValue)
  const decimalSubtotal = new Decimal(subtotal)

  return decimalMinimumOrderValue.sub(decimalSubtotal).toNumber()
}

export const validateMOV = (
  subtotal: number,
  minimumOrderValue: number | null
) => (minimumOrderValue && subtotal > 0 ? subtotal > minimumOrderValue : true)

export const validateAddressParams = (address: DeliveryAddress) => {
  if (address.line_1 === '') return false
  if (address.zip.length < 5) return false

  return true
}

export const isAddressParamsV2Valid = (address: DomesticAddress) => {
  if (address.lineOne === '') return false
  if (address.zip.length < 5) return false

  return true
}

export const getPrefilledAddress = (
  loadedCart: Cart,
  tempSavedCheckoutValues: CheckoutForm | null,
  defaults: DeliveryAddress
) => {
  const deliveryAddressKeys = Object.keys(FORM_DEFAULTS['delivery_address'])

  const tempCheckoutFormHasValues =
    !!tempSavedCheckoutValues?.delivery_address &&
    keysHaveValues(
      deliveryAddressKeys,
      tempSavedCheckoutValues.delivery_address
    )

  const cartDeliveryAddressHasValues =
    !!loadedCart?.delivery_address &&
    keysHaveValues(deliveryAddressKeys, loadedCart.delivery_address)

  let address = defaults

  if (cartDeliveryAddressHasValues) {
    address = loadedCart.delivery_address as DeliveryAddress
  } else if (tempSavedCheckoutValues && tempCheckoutFormHasValues) {
    address = tempSavedCheckoutValues.delivery_address
  }

  const { line_1, line_2, city, zip } = address

  return {
    line_2: line_2 || '',
    line_1: line_1 || '',
    city: city || '',
    zip: zip || ''
  }
}

export const getFormDefaultValue = (
  field: string,
  sources: (Cart | CheckoutForm | null)[]
) => {
  for (const source of sources) {
    const value = source?.[field]

    if (value) {
      if (typeof value === 'object') {
        if (keysHaveValues(Object.keys(FORM_DEFAULTS[field]), value)) {
          return value
        }
      }
      return value
    }
  }

  return FORM_DEFAULTS[field]
}

export const focusNextInput = (inputList: string[]) => {
  const currentInputId = document.activeElement?.id
  let nextInputId = ''
  if (currentInputId) {
    inputList.every((input, index) => {
      if (currentInputId.includes(input)) {
        if (index + 1 > inputList.length) return false
        nextInputId = `${inputList[index + 1]}`
        return false
      }
      return true
    })
    const nextInputEl = document.getElementById(nextInputId)
    if (nextInputEl) {
      // Very weird bug with JS.
      // Needs a timeout of 1ms so that the focus allows you to type. ¯\_(ツ)_/¯
      setTimeout(() => {
        nextInputEl.focus()
      }, 1)
    }
  }
}

export const getAddressErrorMessage = (errors: {
  [key: string]: FieldError
}) => {
  const errorKeysArray = Object.keys(errors)
  const lastErrorKey = errorKeysArray[errorKeysArray.length - 1]
  const lastError = errors[lastErrorKey]

  if (lastError.message && lastError.message !== '') {
    return lastError.message
  }

  return `${addressFieldGlossary(lastErrorKey)} ${addressErrorGlossary(
    lastError.type
  )}.`
}

const addressFieldGlossary = (key: string) => {
  switch (key) {
    case 'line_1':
      return 'Street address'
    case 'zip':
      return 'Post code'
    case 'city':
      return 'City'
    default:
      return 'The field'
  }
}

const addressErrorGlossary = (type: string) => {
  switch (true) {
    case type === 'isRequired':
      return 'is required'
    case /^length_\d+$/.test(type):
      const amount = type.split('_')[1]
      return `must be at least ${amount} characters`
    default:
      return 'is incorrect'
  }
}

export const hasAddressErrors = (addressErrors: {
  [key: string]: FieldError
}): boolean => {
  return Boolean(addressErrors) && Boolean(Object.values(addressErrors).length)
}

const CUSTOMER_PREFIX = 'customer_details'
export const CUSTOMER_INPUTS = {
  firstNameInputId: `${CUSTOMER_PREFIX}[first_name]`,
  lastNameInputId: `${CUSTOMER_PREFIX}[last_name]`,
  emailInputId: `${CUSTOMER_PREFIX}[email]`,
  phonePrefixId: `${CUSTOMER_PREFIX}[contact_num_prefix]`,
  phoneInputId: `${CUSTOMER_PREFIX}[contact_num]`
}

const CUSTOMER_PREFIX_V2 = 'customerDetails'
export const CUSTOMER_INPUTS_V2 = {
  firstNameInputId: `${CUSTOMER_PREFIX_V2}[firstName]`,
  lastNameInputId: `${CUSTOMER_PREFIX_V2}[lastName]`,
  emailInputId: `${CUSTOMER_PREFIX_V2}[email]`,
  phonePrefixId: `${CUSTOMER_PREFIX_V2}[contactNumPrefix]`,
  phoneInputId: `${CUSTOMER_PREFIX_V2}[contactNumber]`
}

export const RECIPIENT_PREFIX_V2 = 'recipientDetails'
export const RECIPIENT_INPUTS_V2 = {
  firstNameInputId: `${RECIPIENT_PREFIX_V2}[firstName]`,
  lastNameInputId: `${RECIPIENT_PREFIX_V2}[lastName]`,
  phonePrefixId: `${RECIPIENT_PREFIX_V2}[contactNumPrefix]`,
  phoneInputId: `${RECIPIENT_PREFIX_V2}[contactNumber]`
}

export const SHIPPING_PREFIX = 'delivery_address'
export const SHIPPING_INPUTS = {
  line1InputId: `${SHIPPING_PREFIX}[line_1]`,
  line2InputId: `${SHIPPING_PREFIX}[line_2]`,
  zipInputId: `${SHIPPING_PREFIX}[zip]`,
  cityInputId: `${SHIPPING_PREFIX}[city]`,
  dropoffNotesInputId: 'dropoff_notes'
}

export const SHIPPING_PREFIX_V2 = 'deliveryAddress'
export const SHIPPING_INPUTS_V2 = {
  line1InputId: `${SHIPPING_PREFIX_V2}[lineOne]`,
  line2InputId: `${SHIPPING_PREFIX_V2}[lineTwo]`,
  zipInputId: `${SHIPPING_PREFIX_V2}[zip]`,
  cityInputId: `${SHIPPING_PREFIX_V2}[city]`,
  dropoffNotesInputId: 'dropoffNotes'
}

/** START of Delivery Charge methods */

/** We want the deliveryChargeReduction to be a Number so we can manipulate it later */
export const formatDeliveryChargeInfo = (deliveryChargeData?: {
  deliveryCharge: string
  deliveryChargeBeforeDiscount: string
  deliveryChargeReduction: string
  deliveryChargeReductionReason: string
  deliveryPricingBand: {
    lowerThreshold: string
    percentageDiscount: number
    upperThreshold: string
  }
}): DeliveryReduction | undefined => {
  if (isEmpty(deliveryChargeData) || !deliveryChargeData) return undefined
  const {
    deliveryCharge,
    deliveryChargeBeforeDiscount,
    deliveryChargeReduction,
    deliveryChargeReductionReason,
    deliveryPricingBand
  } = deliveryChargeData
  return {
    deliveryCharge: Number(deliveryCharge),
    deliveryChargeBeforeDiscount: Number(deliveryChargeBeforeDiscount),
    deliveryChargeReduction: Number(deliveryChargeReduction),
    deliveryChargeReductionReason,
    deliveryPricingBand
  }
}

type DeliveryChargeHelperData = {
  deliveryChargeBeforeDiscount?: number
  deliveryCharge?: number
  deliveryChargeReduction: number
  discountAmount: number
  discountTarget: string
}

/** Given a discount, give the original value of the delivery charge */
export const getBeforeDeliveryDiscountValue = ({
  deliveryChargeBeforeDiscount,
  deliveryCharge,
  deliveryChargeReduction,
  discountAmount,
  discountTarget
}: DeliveryChargeHelperData) => {
  const deliveryCost = Number(deliveryCharge)

  if (deliveryChargeBeforeDiscount === deliveryCost) {
    return ''
  }

  if (
    deliveryChargeBeforeDiscount &&
    deliveryChargeBeforeDiscount !== deliveryCost
  ) {
    return formatMoney(Number(deliveryChargeBeforeDiscount))
  }

  if (!!deliveryCharge) {
    if (
      !!deliveryChargeReduction ||
      (!!discountAmount &&
        ['delivery_fee', 'all_charges', 'subtotal_delivery_fee'].includes(
          discountTarget
        ))
    ) {
      return formatMoney(Number(deliveryCharge))
    }
  }
  return ''
}

/** Generate tooltip message */
export const createDeliveryTooltipMessage = ({
  percentage,
  amount,
  lowerThreshold
}: {
  percentage: number
  amount: number
  lowerThreshold: string
}) => {
  return `
    You have received ${percentage}% (-£${amount}) off your delivery
    for spending over £${lowerThreshold}.
  `
}

/** Depending on Delivery Charge reduction, generate the tooltip message to display */
export const getTooltipMessage = (deliveryReduction?: DeliveryReduction) => {
  return createDeliveryTooltipMessage({
    percentage: deliveryReduction?.deliveryPricingBand?.percentageDiscount || 0,
    amount: deliveryReduction?.deliveryChargeReduction || 0,
    lowerThreshold: deliveryReduction?.deliveryPricingBand?.lowerThreshold || ''
  })
}

/** END of Delivery Charge methods */

/** For phone number inputs */
export const trimAndRemoveLeadingZero = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  let { value } = event.target
  value = value.trim()
  if (value.startsWith('0')) {
    value = value.slice(1)
  }
  event.target.value = value
  return value
}

/** Temp: Strips +44 prefixes from contact nums */
export const stripDialCodePrefix = (contactNum: string) => {
  const phoneNumber = parsePhoneNumberFromString(contactNum)
  return phoneNumber ? phoneNumber.nationalNumber : contactNum
}

export const handlePhoneNumberOnChange = (
  e: React.ChangeEvent<HTMLInputElement>
) => {
  let newValue = trimAndRemoveLeadingZero(e)
  newValue = stripDialCodePrefix(newValue)
  e.target.value = newValue
}

// Checkout V2 Functions:
export const returnToShop = (
  currentStore: Store | undefined,
  history: History
) => {
  !!currentStore
    ? history.push(`/store/${currentStore.slug}`)
    : history.push('/')
}

export const getFormDefaultValueV2 = (
  field: string,
  sources: (ConsumerCart | CheckoutFormV2 | null)[]
) => {
  for (const source of sources) {
    const value = source?.[field]

    if (value) {
      if (typeof value === 'object') {
        if (keysHaveValues(Object.keys(FORM_DEFAULTS_V2[field]), value)) {
          return value
        }
      }
      return value
    }
  }

  return FORM_DEFAULTS_V2[field]
}

export const getPrefilledAddressV2 = (
  loadedCart: ConsumerCart | null,
  tempSavedCheckoutValues: CheckoutFormV2 | null,
  defaults: DomesticAddress
) => {
  const deliveryAddressKeys = Object.keys(FORM_DEFAULTS_V2.deliveryAddress)

  const tempCheckoutFormHasValues =
    !!tempSavedCheckoutValues?.deliveryAddress &&
    keysHaveValues(deliveryAddressKeys, tempSavedCheckoutValues.deliveryAddress)

  const cartDeliveryAddressHasValues =
    !!loadedCart?.deliveryAddress &&
    keysHaveValues(deliveryAddressKeys, loadedCart.deliveryAddress)

  let address = defaults

  if (cartDeliveryAddressHasValues) {
    address = loadedCart.deliveryAddress
  } else if (tempSavedCheckoutValues && tempCheckoutFormHasValues) {
    address = tempSavedCheckoutValues.deliveryAddress
  }

  const { lineOne, lineTwo, city, zip } = address

  return {
    lineOne: lineOne || '',
    lineTwo: lineTwo || '',
    city: city || '',
    zip: zip || ''
  }
}

export const findFlagFromCountryCode = (countryCode: string) => {
  if (!countryCode) return ''
  return (
    countryCodes.find((option: CountryCode) => option.dial_code === countryCode)
      ?.flag || ''
  )
}

export const updateContactNum = (
  contactNum: string,
  contactNumPrefix: string
) => {
  let flag = ''
  if (contactNum) {
    const phoneNumber = parsePhoneNumberFromString(
      `${contactNumPrefix}${contactNum}`
    )
    if (phoneNumber?.isValid()) {
      flag = findFlagFromCountryCode(`+${phoneNumber.countryCallingCode}`)
      return {
        contact_num_prefix: `${flag} +${phoneNumber.countryCallingCode}`,
        contact_num: phoneNumber.nationalNumber
      }
    }
  } else if (contactNumPrefix) {
    return {
      contact_num_prefix: `${contactNumPrefix}`,
      contact_num: ''
    }
  }

  return {
    contact_num_prefix: '🇬🇧 +44',
    contact_num: ''
  }
}

export const updateContactNumV2 = (
  contactNum: string,
  contactNumPrefix: string
) => {
  let flag = ''
  if (contactNum) {
    const phoneNumber = parsePhoneNumberFromString(
      `${contactNumPrefix}${contactNum}`
    )
    if (phoneNumber?.isValid()) {
      flag = findFlagFromCountryCode(`+${phoneNumber.countryCallingCode}`)
      return {
        contactNumPrefix: `${flag} +${phoneNumber.countryCallingCode}`,
        contactNumber: phoneNumber.nationalNumber
      }
    }
  } else if (contactNumPrefix) {
    return {
      contactNumPrefix: `${contactNumPrefix}`,
      contactNumber: ''
    }
  }

  return {
    contactNumPrefix: '🇬🇧 +44',
    contactNumber: ''
  }
}

export const handlePhoneNumberOnBlur = async (
  e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  setValue: (name: string, value: string) => void,
  trigger: (name: string) => void,
  fieldName: string
) => {
  let newValue = trimAndRemoveLeadingZero(e)
  newValue = stripDialCodePrefix(newValue)
  await setValue(fieldName, newValue)

  // Trigger re-validation after setting trimmed/zeros removed value
  trigger(fieldName)
}

export const returnStoreBaseAddress = (
  storeAddress: FullAddress
): BaseAddress => {
  const { lineOne, lineTwo, zip, city, country } = storeAddress || {}
  return {
    lineOne: lineOne || '',
    lineTwo: lineTwo || '',
    zip: zip || '',
    city: city || '',
    country: country || ''
  }
}

export const getFulfillmentFlags = (
  fulfillmentType: FulfillmentType | undefined
): { isOrderAtTable: boolean; isDelivery: boolean; isPickup: boolean } => {
  if (!fulfillmentType)
    return { isOrderAtTable: false, isDelivery: false, isPickup: false }
  return {
    isOrderAtTable: fulfillmentType === ORDER_AT_TABLE_FULFILLMENT,
    isDelivery: fulfillmentType === DELIVERY_FULFILLMENT,
    isPickup: fulfillmentType === PICKUP_FULFILLMENT
  }
}

/** Open delivery address form on init if deliveryAddress has required fields that are empty
 * Checks delivery address has required fields filled, used as a separate validity check on init so as to not
 * have to show delivery address section on UI in order to register the address form inputs in order to trigger validation */
export const showDeliveryFormOnLoad = (deliveryAddress: {
  lineOne: string
  zip: string
  city: string
}) => {
  if (isEmpty(deliveryAddress)) return true
  const deliveryAddressFormRequiredFields = ['lineOne', 'zip', 'city']

  return !deliveryAddressFormRequiredFields.every((key) => {
    const value = deliveryAddress[key]
    const isValid = !!value && !onlySpaces(value)
    // Additional check for zip to be greater than 4 characters
    if (key === 'zip') return isValid && value.length > 4
    return isValid
  })
}

export const checkHandleUnauthorizedError = (
  error: ApolloError,
  domain: string,
  currentStore: Store | undefined,
  history: any
) => {
  const { networkError, graphQLErrors } = error

  const isUnauthorized =
    (networkError && isNetworkErrorUnauthorized(networkError)) ||
    (graphQLErrors &&
      ['unauthorized', 'auth_missing'].includes(getErrorMessage(graphQLErrors)))

  if (isUnauthorized) {
    // If unauth'd clear cart related credentials & return to shop
    localStorage.removeItem(domain)
    localStorage.removeItem(`${domain}-csrf`)
    returnToShop(currentStore, history)
    return
  }
}

export const getIsAddressFormOpen = (
  fulfillmentType: FulfillmentType,
  deliveryAddress: DomesticAddress
): boolean => {
  const { isDelivery } = getFulfillmentFlags(fulfillmentType)
  return isDelivery && showDeliveryFormOnLoad(deliveryAddress)
}

export const getApplicableRewards = (loyaltyCards?: LoyaltyCard[]) => {
  if (!loyaltyCards?.length) return []

  return loyaltyCards.flatMap((card) =>
    card.availableRewards.filter(
      ({ isApplicable, status }) =>
        isApplicable && status === RewardStatus.ACTIVE
    )
  )
}
