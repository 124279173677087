import { useMediaQuery } from 'react-responsive'

export const useMediaQueries = () => {
  const isMobile = useMediaQuery({ maxDeviceWidth: 768 })
  const isTablet = useMediaQuery({ minDeviceWidth: 768.1 })
  const isLaptop = useMediaQuery({
    minDeviceWidth: 1024
  })
  const isDesktop = useMediaQuery({
    minDeviceWidth: 1200
  })

  return {
    isMobile,
    isTablet,
    isLaptop,
    isDesktop
  }
}
