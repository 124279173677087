import gql from 'graphql-tag'

const slerpMerchantFragment = `
  isSeoEnabled
  tagline
  seoDescription
  `

export const QUERY_GET_SLERP_MERCHANT = gql`
query GetMerchantInformation($merchantSlug: String!) {
  getMerchant(merchantSlug: $merchantSlug) {
    ${slerpMerchantFragment}
  }
}
`
