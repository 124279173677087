import { useEffect, useContext, useState } from 'react'
import styled from '@emotion/styled'
import FulfillmentOptions from './FulfillmentOptions'
import {
  useShop,
  useReactRouter,
  useModal,
  useAppContent,
  useConsumerCart
} from 'shop/hooks'
import { LandingContext } from './LandingContext'
import { LandingProps } from './types'
import { SimpleFormat } from 'shop/utils'
import { Button } from 'shop/components'
import AccountMenu from './AccountMenu'
import {
  getStorePreOrderSettings,
  getUpcomingSpecialCutoffs
} from './preOrderUtils'
import { getEnabledPreorderDays } from './datePickerUtils'
import { AccountIcon } from 'shop/assets/icons'
import Spinner from '../Loader/Spinner'
import CloseButton from '../Modal/CloseButton'
import { SpinnerContainer } from './commonStyles'

const LandingForm = ({ onClose }: { onClose: () => void }) => {
  const { cartSession } = useShop()
  const { altLogoImage } = useAppContent()
  const { merchant, stores, inModal, loadingStores, fulfillmentType } =
    useContext(LandingContext)
  const isLoggedIn = localStorage.getItem('customerId') !== null
  const { openModal, closeModal } = useModal()
  const { history } = useReactRouter()
  const { clearConsumerCart } = useConsumerCart()

  // if (!merchant) return <div />
  const openStores = stores && stores.filter((store) => store.is_open)

  const hasValidPreorderStore = !!stores.find((store) => {
    const preorderSettings = getStorePreOrderSettings(store)
    const acceptsPreorder =
      preorderSettings?.pickup_enabled || preorderSettings?.delivery_enabled
    const enabledCutoffs =
      preorderSettings && preorderSettings.cutoffs
        ? getEnabledPreorderDays(preorderSettings.cutoffs)
        : []
    const upcomingEnabledSpecialCutoffs = preorderSettings?.special_cutoffs_map
      ? getUpcomingSpecialCutoffs(preorderSettings?.special_cutoffs_map, true)
      : []
    const hasValidCutoffs =
      enabledCutoffs.length > 0 || upcomingEnabledSpecialCutoffs.length > 0

    return acceptsPreorder && hasValidCutoffs
  })

  const showForm = openStores.length > 0 || hasValidPreorderStore

  // resets cart
  useEffect(() => {
    cartSession.setCart(null)
    clearConsumerCart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLoginButtonClick = () => {
    if (isLoggedIn) {
      history.push('/account')
    } else {
      openModal('login')
    }
  }

  const form = (
    <>
      {merchant && stores && showForm ? (
        <FormContent
          {...{
            stores,
            merchant,
            handleLoginButtonClick
          }}
        />
      ) : (
        <>
          {loadingStores[fulfillmentType] ? (
            <SpinnerContainer>
              <Spinner size='48px' />
            </SpinnerContainer>
          ) : (
            <>
              <ClosedStoresMessage>
                {merchant && merchant.closed_store_message}
              </ClosedStoresMessage>
            </>
          )}
        </>
      )}
    </>
  )

  const handleCloseModal = () => {
    onClose()
    closeModal('fulfillment')
  }

  return (
    <>
      <LandingHeader>
        <LogoContainer>
          <Logo src={altLogoImage} />
        </LogoContainer>
        {inModal && (
          <ModalCloseButtonContainer>
            <CloseButton handleCloseModal={handleCloseModal} />
          </ModalCloseButtonContainer>
        )}
        {!inModal && (
          <AccountContainer>
            {isLoggedIn && <AccountMenu />}
            {!isLoggedIn && (
              <CustomerAccountSection>
                <CustomerAccountButton
                  onClick={() => handleLoginButtonClick()}
                  size='md'
                  testId='accountsButton'
                  width='auto'
                >
                  <AccountIcon />
                  Log in / Sign up
                </CustomerAccountButton>
              </CustomerAccountSection>
            )}
          </AccountContainer>
        )}
      </LandingHeader>
      <LandingContainer>{form}</LandingContainer>
    </>
  )
}

interface FormContentProps extends LandingProps {
  handleLoginButtonClick: () => void
}

const FormContent = ({ merchant, stores }: FormContentProps) => {
  const { isModalOpen, closeModal, loginModal } = useModal()
  const [isMounted, setIsMounted] = useState<boolean>(false)

  const isLoggedIn = localStorage.getItem('customerId') !== null

  useEffect(() => {
    if (isMounted) {
      return
    }

    setIsMounted(true)
  }, [closeModal, isModalOpen, isMounted])

  const welcomeMessageHasText = !!merchant?.welcome_message
    ?.replace(/<[^>]*>/g, '')
    .trim()

  const welcomeMessageHeaderHasText = !!merchant?.welcome_message_header
    ?.replace(/<[^>]*>/g, '')
    .trim()

  const hasWelcomeDescription =
    welcomeMessageHasText || welcomeMessageHeaderHasText

  const shouldHaveLineBreak =
    welcomeMessageHeaderHasText && welcomeMessageHeaderHasText

  return (
    <>
      {!isLoggedIn &&
        isModalOpen('login') &&
        loginModal({ merchantId: merchant.id })}
      {hasWelcomeDescription && (
        <>
          <Description>
            {welcomeMessageHeaderHasText && (
              <SimpleFormat>{merchant.welcome_message_header}</SimpleFormat>
            )}
            {shouldHaveLineBreak && <br />}
            {welcomeMessageHasText && (
              <SimpleFormat>{merchant.welcome_message}</SimpleFormat>
            )}
          </Description>

          <Divider />
        </>
      )}

      {!hasWelcomeDescription && <Padding />}

      <FulfillmentOptions
        merchant={merchant}
        stores={stores}
        hasPadding={false}
        label={
          <h5>
            1.<span>Choose order type</span>
          </h5>
        }
      />
    </>
  )
}

const Padding = styled.div(() => ({
  paddingTop: '16px'
}))

const LandingHeader = styled.header(({ theme }: any) => ({
  borderBottom: `1px solid ${theme.colors['lineColor']}`,
  padding: theme.space[3],
  display: 'flex',
  position: 'sticky',
  top: 0,
  backgroundColor: theme.colors['white'],
  zIndex: theme.zIndex['stickyHeader']
}))

const LandingContainer = styled.section(({ theme }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: theme.space[3],
  paddingBottom: 0,
  'div > h5': {
    display: 'flex',
    alignItems: 'center',
    margin: '32px 0 18px',
    fontWeight: 'normal',
    fontSize: '20px',
    textTransform: 'uppercase',

    '&:first-of-type': {
      marginTop: '0'
    },

    '> span': {
      flexGrow: 1,
      marginLeft: '1ch',
      fontSize: '14px'
    },

    [theme.mediaQueries.viewport7]: {
      fontWeight: 'bold'
    }
  }
}))

const ModalCloseButtonContainer = styled.div(({ theme }: any) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& > div': {
    position: 'unset'
  }
}))

const AccountContainer = styled.div(({ theme }: any) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  WebkitTapHighlightColor: 'transparent'
}))

const CustomerAccountButton = styled((props) => <Button {...props} />)(
  ({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    lineHeight: '16px',
    padding: '8px 16px',
    // Max width to force multi-line of CTA text
    maxWidth: '125px',

    // Viewport 4 is wide enough for a single line of CTA text
    [theme.mediaQueries.viewport4]: {
      maxWidth: 'unset'
    },

    // Bigger svg for multi-line
    '& svg': {
      height: '24px',
      width: '24px',

      [theme.mediaQueries.viewport4]: {
        height: '16px',
        width: '16px'
      }
    }
  })
)

const CustomerAccountSection = styled.div(({ theme }: any) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  [theme.mediaQueries.viewport7]: {
    justifyContent: 'flex-start',
    alignItems: 'unset'
  }
}))

const Logo = styled.img(({ theme }: any) => ({
  maxWidth: '80%',
  maxHeight: '36px',
  [theme.mediaQueries.viewport7]: {
    maxHeight: '40px'
  }
}))

export const LogoContainer = styled.div(({ theme }: any) => ({
  display: 'flex',
  flexGrow: 1,
  WebkitTapHighlightColor: 'transparent'
}))

const ClosedStoresMessage = styled.h2(({ theme }: any) => ({
  color: theme.colors.textBold,
  fontWeight: theme.fonts.heading.weight,
  fontFamily: theme.fonts.heading.family,
  marginTop: theme.space[4],
  [theme.mediaQueries.viewport7]: {
    fontSize: theme.fontSizes[5],
    paddingRight: '10%'
  }
}))

const Description = styled.div(({ theme }: any) => ({
  overflow: 'hidden',
  textOverflow: 'hidden',
  transition: 'all 200ms ease-in',
  color: theme.colors.textBold,
  fontWeight: theme.fonts.heading.weight,
  fontFamily: theme.fonts.heading.family,
  fontSize: '8px',
  '& ul': {
    listStyleType: 'revert',
    padding: 'revert'
  },
  '*': {
    margin: '0 !important'
  },
  h1: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: 'normal'
  },
  'h6,p': {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 'normal'
  },
  a: {
    color: 'inherit',
    fontWeight: 700
  },
  [theme.mediaQueries.viewport7]: {
    padding: '0 16px',
    fontSize: '10px'
  }
}))

export const Divider = styled.hr(({ theme }: any) => ({
  margin: '24px 0',
  border: 0,
  borderBottom: '1px solid #f5f5f5',

  [theme.mediaQueries.viewport7]: {
    margin: '32px 0'
  }
}))

export default LandingForm
