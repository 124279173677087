import { IoIosArrowBack } from 'react-icons/io'

import styled from '@emotion/styled'

import { useHistory } from 'react-router-dom'

interface Props {
  size: '12px' | '24px'
  url?: string
  label?: string
  trackAction?: () => void
}
const BackButton = ({ url, label, size, trackAction }: Props) => {
  const history = useHistory()
  const onClick = () => (url ? history.push(url) : history.goBack())

  const BackButtonContainer = styled.div(({ theme }: any) => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: theme.fontSizes[0],
    cursor: 'pointer'
  }))

  return (
    <BackButtonContainer
      onClick={() => {
        trackAction && trackAction()
        onClick()
      }}
    >
      <IoIosArrowBack size={size} />
      {label && <p>{label}</p>}
    </BackButtonContainer>
  )
}

export default BackButton
