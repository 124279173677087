import React, { useEffect, SetStateAction } from 'react'
import styled from '@emotion/styled'
import {
  ProductOptions,
  SelectedOption,
  ProductVariant,
  Product,
  ProductModifierGroup
} from '../../Product/types'
import { CartPayload } from 'shop/components/Cart/types'
import { Actions } from '..'
import { useReactRouter } from 'shop/hooks'
import { scrollToMissingOption } from '../utils'

import { VariantControl } from 'shop/components/Variants'
import { MissingRequiredSection } from 'shop/types'

interface Props {
  product?: Product
  productVariants: ProductVariant[]
  loadingProduct: boolean
  isProductInStock: boolean
  isSelectedVariantInStock: boolean
  selectedVariant?: ProductVariant
  modifiers?: ProductModifierGroup[]
  parentUrl: string
  // DONT USE THIS PROP. FOR TESTING LANG E2
  // MockedProvider doesn't support dynamic values and
  // the cart_item_id is auto generated. So i had to
  // put a default value here for testing :(
  testCartItemId?: string
  productScrollRef?: React.RefObject<HTMLDivElement>
  productOptions: ProductOptions[]
  selectedOptions: SelectedOption[]
  setSelectedOptions: React.Dispatch<SetStateAction<SelectedOption[] | []>>
  cartParams: CartPayload | undefined
  setCartParams: React.Dispatch<SetStateAction<CartPayload | undefined>>
  invalidModifierGroups: string[]
  setInvalidModifierGroups: React.Dispatch<SetStateAction<string[] | []>>
  hasFailedAddToCart: boolean
  setHasFailedAddToCart: React.Dispatch<SetStateAction<boolean>>
  missingRequiredSections?: MissingRequiredSection[]
  setMissingRequiredSections: React.Dispatch<
    SetStateAction<MissingRequiredSection[]>
  >
  variantQuantity: number
  setVariantQuantity: React.Dispatch<SetStateAction<number>>
}

export const getControlsBarHeight = (): number => {
  const controlsBarHeight =
    document.getElementById('productControls')?.clientHeight || 0
  return controlsBarHeight
}

const Controls = ({
  product,
  selectedVariant,
  loadingProduct,
  modifiers,
  isProductInStock,
  isSelectedVariantInStock = true,
  testCartItemId,
  parentUrl,
  productScrollRef,
  productOptions,
  selectedOptions,
  cartParams,
  setCartParams,
  invalidModifierGroups,
  hasFailedAddToCart,
  setHasFailedAddToCart,
  missingRequiredSections,
  setMissingRequiredSections,
  variantQuantity,
  setVariantQuantity
}: Props) => {
  const updateMissingSections = (): void => {
    const missingOptions: MissingRequiredSection[] = productOptions.map(
      (option) => ({
        type: 'option',
        name: option.name
      })
    )
    const selectedOptionNames = selectedOptions.map((option) => option.name)
    const invalidModifiers =
      modifiers?.filter((modifier) =>
        invalidModifierGroups.some(
          (invalidModifier) => invalidModifier === modifier.id
        )
      ) || []
    const missingModifiers: MissingRequiredSection[] = invalidModifiers.map(
      (modifier) => ({
        type: 'modifier',
        id: modifier.id,
        name: modifier.name
      })
    )
    if (!selectedOptionNames?.length) {
      setMissingRequiredSections([...missingOptions, ...missingModifiers])
    } else {
      setMissingRequiredSections([
        ...missingOptions.filter(
          (option) => !selectedOptionNames.includes(option.name)
        ),
        ...missingModifiers
      ])
    }
  }

  const handleFailedAddToCart = (): void => {
    setHasFailedAddToCart(true)
    updateMissingSections()
  }

  useEffect(() => {
    if (hasFailedAddToCart) {
      updateMissingSections()
    }
  }, [selectedOptions, invalidModifierGroups])

  useEffect(() => {
    if (missingRequiredSections?.length && productScrollRef) {
      const scrollToId =
        missingRequiredSections[0].id || missingRequiredSections[0].name
      scrollToMissingOption(productScrollRef, scrollToId, 16)
    }
  }, [missingRequiredSections])

  const { history } = useReactRouter()
  const newLinkState = history.location.state
    ? {
        state: {
          scrollPosition: history.location.state.scrollPosition
        }
      }
    : {}

  const optionsValid = () => {
    if (productOptions.length === 0 && selectedOptions.length === 0) {
      return true
    }

    const options = productOptions.map((option) => option.name)

    const isValid = options.reduce((acc: boolean, option: string) => {
      const optionValid = selectedOptions.find(
        (selectedOption: SelectedOption) => selectedOption.name === option
      )
      return acc && !!optionValid
    }, true)

    return isValid
  }

  const redirectUser = () => {
    history.push({
      pathname: parentUrl,
      ...newLinkState
    })
  }

  const calculateNextSection = (): string => {
    if (missingRequiredSections?.length) {
      return missingRequiredSections[0].name
    }
    const optionNames = productOptions.map((option) => option.name)
    const selectedOptionNames = selectedOptions.map((option) => option.name)

    for (let i = 0; i < optionNames.length; i++) {
      const optionFound = selectedOptionNames.some(
        (selectedOptionName) => optionNames[i] === selectedOptionName
      )
      if (!optionFound) {
        return optionNames[i]
      }
    }
    if (invalidModifierGroups.length && modifiers?.length) {
      const nextRequiredModifier = modifiers.find(
        (modifier) => modifier.id === invalidModifierGroups[0]
      )?.name
      return nextRequiredModifier || ''
    }
    return ''
  }

  if (!product) return <></>
  const getInStock = () => {
    // matched variant stock
    if (selectedVariant) return isSelectedVariantInStock
    // no matched variant, but user has selected all required options
    if (optionsValid() && productOptions.length > 0) return false
    // default variant stock
    return isProductInStock
  }
  return (
    <Container>
      <ControlsContainer data-testid='productControls' id='productControls'>
        {isProductInStock && (
          <VariantControlContainer key={product.id}>
            <VariantControl
              productId={product.id}
              productVariantName={product.name}
              productVariantLimit={product.limit}
              inStock={getInStock()}
              nextSection={calculateNextSection()}
              variantQuantity={variantQuantity}
              setVariantQuantity={setVariantQuantity}
            />
          </VariantControlContainer>
        )}

        <Actions
          testCartItemId={testCartItemId}
          cartParams={cartParams}
          setCartParams={setCartParams}
          isSelectedVariantInStock={isSelectedVariantInStock}
          loadingProduct={loadingProduct}
          isProductInStock={isProductInStock}
          modifierGroupsValid={!invalidModifierGroups.length}
          optionsValid={optionsValid()}
          redirectUser={redirectUser}
          product={product}
          selectedVariant={selectedVariant}
          modifiers={modifiers}
          handleFailedAddToCart={handleFailedAddToCart}
          setHasFailedAddToCart={setHasFailedAddToCart}
          variantQuantity={variantQuantity}
        />
      </ControlsContainer>
    </Container>
  )
}

const VariantControlContainer = styled.div(({ theme, inline }: any) => ({
  flex: '0 1 50%',
  marginRight: '8px',
  marginTop: '0',
  marginBottom: '0',
  alignSelf: 'center'
}))

const Container = styled.div(({ theme }: any) => ({
  backgroundColor: '#F5F5F5',
  width: '100%',
  position: 'fixed',
  bottom: 0,
  margin: `0 -${theme.space[3]}px`,
  borderTop: '2px solid #D8D8D8',

  [theme.mediaQueries.viewport7]: {
    position: 'sticky',
    width: 'unset',
    margin: `0 -${theme.space[4]}px`
  }
}))

const ControlsContainer = styled.div(({ theme }: any) => ({
  flexGrow: 1,
  display: 'flex',
  padding: `${theme.space[3]}px`,

  [theme.mediaQueries.viewport7]: {
    padding: `${theme.space[4]}px`
  }
}))

export default Controls
