import { useState } from 'react'
import { MdInfoOutline as InfoIcon } from 'react-icons/md'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'

type TooltipPointerSide = 'left' | 'right'

const IconTooltip = ({
  tooltipMessage,
  iconSize = '16px',
  tooltipPointerSide = 'left'
}: {
  tooltipMessage: string
  iconSize?: string
  tooltipPointerSide?: TooltipPointerSide
}) => {
  const [showToolip, setShowToolip] = useState(false)
  return (
    <TooltipContainer
      onMouseEnter={() => setShowToolip(true)}
      onMouseLeave={() => setShowToolip(false)}
    >
      {showToolip && (
        <TooltipPopup
          tooltipMessage={tooltipMessage}
          tooltipPointerSide={tooltipPointerSide}
        />
      )}
      <InfoIcon data-testid='info-icon' size={iconSize} />
    </TooltipContainer>
  )
}

const TooltipPopup = ({
  tooltipMessage,
  tooltipPointerSide
}: {
  tooltipMessage: string
  tooltipPointerSide: TooltipPointerSide
}) => {
  return (
    <>
      <TooltipTip tooltipPointerSide={tooltipPointerSide}>
        {tooltipMessage}
      </TooltipTip>
    </>
  )
}

const TooltipContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    position: 'relative',

    '& svg': {
      color: '#595959'
    }
  })
)

const TooltipTip = styled.div<
  StyledHTMLElement & { tooltipPointerSide?: TooltipPointerSide },
  Required<Theme>
>(({ theme, tooltipPointerSide }) => ({
  position: 'absolute',
  borderRadius: '12px',
  left: '50%',
  transform:
    tooltipPointerSide === 'left' ? 'translateX(-9%)' : 'translateX(-85%)',
  padding: '12px',
  background: '#f5f5f5',
  lineHeight: '20px',
  zIndex: 100,
  minWidth: '300px',
  fontSize: '14px',
  bottom: '40px',

  boxShadow: `
      0px 8px 10px rgba(0, 0, 0, 0.08),
      -10px 8px 15px rgba(0, 0, 0, 0.08),
      10px 8px 15px rgba(0, 0, 0, 0.08)
    `,

  '&::before': {
    content: '" "',
    left: tooltipPointerSide === 'left' ? '10%' : '85%',
    border: 'solid transparent',
    height: '0',
    width: '0',
    position: 'absolute',
    pointerEvents: 'none',
    borderWidth: '12px',
    marginLeft: '-12px',

    top: '100%',
    borderTopColor: '#f5f5f5'
  }
}))

export default IconTooltip
