import { emailRegex } from './common'

/** Removes local login credentials */
export const removeLoginCredentials = (domain: string) => {
  localStorage.removeItem('customerId')
  localStorage.removeItem('customerApiKey')
  localStorage.removeItem('customerName')
  localStorage.removeItem(`${domain}-csrf`)
  // Remove authVersion for tracking purposes
  localStorage.removeItem('authVersion')
}

export const isValidEmail = (email: string) => {
  return emailRegex.test(email)
}
