import { Modifier as ModifierType } from 'shop/types/cart'
import { ModifierOptionName } from '../commonStyles'

interface Props {
  modifiers: ModifierType[]
}

const Modifier = ({ modifiers }: Props) => {
  return (
    <>
      {modifiers.map(({ name, quantity }, index) => (
        <ModifierOptionName
          data-testid='modifier-name'
          key={`modifier-${name}-${index}`}
          title={quantity > 1 ? `${quantity}x ${name}` : name}
        >
          {(!!index ? ', ' : ' ') +
            `${quantity > 1 ? `${quantity}x ${name}` : name}`}
        </ModifierOptionName>
      ))}
    </>
  )
}

export default Modifier
