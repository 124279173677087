import { CSSProperties, ReactText, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import useEscModalHandler from 'shop/hooks/useEscModalHandler'
import CloseButton from './CloseButton'

const ANIMATION_DURATION = 50

type Props = {
  children: React.ReactNode
  onClose?: () => void
  isLandingColumn?: boolean
  newFulfillmentModal?: boolean
  hide?: boolean
  withCloseButton?: boolean
  styles?: { [key: string]: ReactText | CSSProperties }
}

const FullscreenModal = ({
  children,
  onClose,
  isLandingColumn = false,
  newFulfillmentModal = false,
  hide = false,
  withCloseButton = false,
  styles
}: Props) => {
  const container = useRef<HTMLDivElement>(null)

  const handleClose = () => {
    if (onClose) {
      container?.current?.classList.add('-hide')
      setTimeout(() => onClose(), ANIMATION_DURATION)
    }
  }

  useEffect(() => {
    if (hide && container?.current) {
      container.current.style.visibility = 'hidden'
    } else {
      if (container?.current) {
        container.current.style.visibility = 'visible'
      }
    }
  }, [hide])

  useEscModalHandler({ onClose: handleClose })

  useEffect(() => {
    if (!newFulfillmentModal) {
      document.body.style.overflow = 'hidden'

      const rootElement = document.getElementById('root')
      const chatElement = document.getElementById('hbl-live-chat-wrapper')

      if (rootElement && !isLandingColumn) rootElement.style.opacity = '0'
      if (chatElement) chatElement.style.display = 'none'

      return () => {
        if (rootElement && !isLandingColumn) rootElement.style.opacity = '1'

        if (chatElement) chatElement.style.display = 'block'
        document.body.style.overflow = 'auto'
      }
    } else return
  }, [newFulfillmentModal, isLandingColumn])

  return createPortal(
    <Container
      ref={container}
      data-testid={`fullscreen-modal`}
      {...{ isLandingColumn, newFulfillmentModal, styles }}
    >
      {withCloseButton && (
        <CloseButton variant='newModal' handleCloseModal={handleClose} />
      )}
      {children}
    </Container>,
    document.body
  )
}

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const fadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`

const Container = styled.div(
  ({ theme, isLandingColumn, newFulfillmentModal, styles }: any) => ({
    overflowY: 'auto',
    position: 'fixed',
    top: newFulfillmentModal ? '48px' : 0,
    borderRadius: newFulfillmentModal ? '12px' : 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: 'white',
    padding: newFulfillmentModal ? '0' : '12px 0',
    zIndex: theme.zIndex.addressSelectionModal,
    animationDelay: '50ms',
    animation: `${fadeIn} ${ANIMATION_DURATION}ms ease-out`,
    '&.-hide': {
      animation: `${fadeOut} ${ANIMATION_DURATION}ms ease-in`
    },
    [theme.mediaQueries.viewport7]: {
      width: isLandingColumn && '45%'
    },
    [theme.mediaQueries.viewport9]: {
      width: isLandingColumn && '40%'
    },
    ...styles
  })
)

export default FullscreenModal
