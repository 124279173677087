import CartItem from './../Cart/CartItem'
import { CartItem as CartItemType } from 'shop/components/Landing'
import styled from '@emotion/styled'
import { thinScrollbar } from '../common'
import { containerPaddingTop } from './OrderSummary'

const CartItems = ({ cartItems }: { cartItems: CartItemType[] }) => (
  <Container>
    {cartItems.map((cartItem: CartItemType, index: number) => (
      <CartItem
        key={cartItem.id}
        {...{ cartItem }}
        canEditQuantity={false}
        index={index}
        testLabel={'checkout'}
      />
    ))}
  </Container>
)

const Container = styled.div(({ theme }: any) => ({
  display: 'grid',
  gridGap: '12px',
  marginBottom: '12px',
  '> div': {
    paddingBottom: '12px',
    borderBottom: '1px solid #E7E5E9'
  },
  '> div:last-child': {
    paddingBottom: 0,
    borderBottom: 'none'
  },
  [theme['mediaQueries']['viewport6']]: {
    position: 'relative',
    margin: '0',
    minHeight: '72px',
    // Handles long cart item list in desktop
    // 56px * 4  accounts for discount, delivery, gift wrap and total height
    // ${theme.fontSizes[4]}px is the <h4>Your cart</h4> height
    // 16px is the marginTop of this component
    // there might be a better way to make this flexible
    // doing this for now 😬 Note from @jekri - OrderSummary.tsx
    maxHeight: `calc(100vh - ${
      theme.components.navbar.height
    } - ${containerPaddingTop} - ${56 * 4}px - ${theme.fontSizes[4]}px - 16px)`,
    overflowY: 'auto',
    scrollBehavior: 'smooth',
    '> div:last-child': {
      paddingBottom: '12px'
    },

    ...(thinScrollbar(theme) as any)
  }
}))

export default CartItems
