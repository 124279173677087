import styled from '@emotion/styled'
import { Message as MessageType } from 'shop/types'
import { Message } from './Message'
import { Button } from '../Controls'
import BottomModal from '../Modal/BottomModal'
import { useEffect } from 'react'

interface Props {
  messages: Array<MessageType>
  handleCloseModal: () => void
}

export const MessagesModal = ({ messages, handleCloseModal }: Props) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  return (
    <BottomModal isMessage canClose={false} handleCloseModal={handleCloseModal}>
      <ModalContent>
        <h3>Communications</h3>
        <Messages>
          {messages.map((message) => (
            <Message
              {...message}
              canOpenModal={false}
              key={`${message.title}-${message.description}-modal-item`}
            />
          ))}
        </Messages>
        <Button onClick={handleCloseModal}>Close</Button>
      </ModalContent>
    </BottomModal>
  )
}

const ModalContent = styled.div(() => ({
  display: 'flex',
  flexFlow: 'column',
  gap: '16px',
  padding: '16px',

  h3: {
    display: 'inline',

    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '23.44px'
  }
}))

const Messages = styled.div(() => ({
  display: 'flex',
  flexFlow: 'column',
  gap: '16px'
}))
