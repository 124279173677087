import styled from '@emotion/styled'

export const HalfWidthFlexContainer = styled.div(() => ({
  display: 'flex',
  gap: '3%',
  '> div': { width: '50%' }
}))

export const HeaderWithSwitch = styled.div(
  ({ enabled }: { enabled: boolean }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: enabled ? '16px' : '0'
  })
)
