import { RouteComponentProps } from 'react-router-dom'

import { DineIn } from '../shop/pages'

interface StoreParams {
  slug: string
}

export const LoadDineIn = ({ match }: RouteComponentProps<StoreParams>) => (
  <DineIn slug={match.params.slug} />
)
