import styled from '@emotion/styled'
import { fadeInOutMs } from '../Landing/utils'
import { keyframes } from '@emotion/core'
import { ReactComponent as MailIcon } from 'shop/assets/icons/mail.svg'
import Theme, { StyledHTMLElement } from 'shop/theme/types'

export const Icon = styled.div(() => ({
  '> svg': {
    width: '18px',
    height: '18px',
    fill: '#000'
  }
}))

export const overlayOpenAnim = (isOpen: boolean, fadeMs = fadeInOutMs) => ({
  animation: isOpen
    ? `${fadeIn} ${fadeMs}ms linear forwards`
    : `${fadeOut} ${fadeMs}ms linear forwards`
})

export const slideUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

export const fadeIn = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 } 
`

export const fadeOut = keyframes`
  0% { opacity: 1 }
  100% { opacity: 0 } 
`

export const iconDimensions = {
  width: '124px',
  height: '124px'
}

export const StyledMailIcon = styled(MailIcon)(() => ({
  ...iconDimensions,
  color: '#389E0D',
  margin: '0 auto'
}))

export const SendAgain = styled.span<StyledHTMLElement>(() => ({
  textDecoration: 'underline',
  color: 'black',
  fontWeight: 600
}))

export const ErrorMessage = styled.p<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    margin: '2px 12px',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
    color: theme.colors['state'].error[5],

    '& svg': {
      aspectRatio: '1/1',
      width: '15px'
    },

    '& span': {
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  })
)
