import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { LineBreak } from 'shop/components/CartV2/commonStyles'
import { LoyaltyCard } from 'shop/types/cart'
import CardProgress from './CardProgress'
import {
  getLoyaltyCardProgressText,
  getLoyaltyCardProgressTitle
} from './utils'
import { capitalize } from 'lodash'

type Props = {
  loyaltyCard: LoyaltyCard
  isLast: boolean
}

const OrderLoyalty = ({ loyaltyCard, isLast }: Props) => {
  // Convert stampLabel from "POINTS" or "STAMPS" to "Points" or "Stamps"
  const stampLabel = capitalize(loyaltyCard.stamps.stampLabel.toLowerCase())

  return (
    <LoyaltyContainer data-testid={loyaltyCard.id}>
      <h1 data-testid={`${loyaltyCard.id}-title`}>
        {getLoyaltyCardProgressTitle(
          loyaltyCard.nextRewards?.[0]?.stampsForNextReward || 0,
          stampLabel,
          loyaltyCard.availableRewards.length
        )}
      </h1>
      <Description data-testid={`${loyaltyCard.id}-description`}>
        {getLoyaltyCardProgressText(
          loyaltyCard.stamps.currentStamps,
          loyaltyCard.name,
          stampLabel,
          loyaltyCard.availableRewards.length
        )}
      </Description>
      <LineBreak />
      <ProgressContainer>
        <h2>{loyaltyCard.name}</h2>
        <CardProgress
          availableRewards={loyaltyCard.availableRewards}
          nextRewards={loyaltyCard.nextRewards}
          currentStamps={loyaltyCard.stamps.currentStamps}
        />
      </ProgressContainer>
      <LineBreak />
      {isLast && (
        <Disclaimer>
          {stampLabel} and/or rewards will only be added to your account after
          your order has been accepted.
        </Disclaimer>
      )}
    </LoyaltyContainer>
  )
}

const LoyaltyContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',

    '& h1': {
      fontSize: '20px',

      fontFamily: theme.fonts['heading'].family,
      [theme.mediaQueries.viewport7]: {
        fontSize: '24px'
      }
    }
  })
)

const Description = styled.p(() => ({
  fontSize: '16px',
  margin: 0
}))
const ProgressContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',

    '& h2': {
      fontSize: '16px',
      fontFamily: theme.fonts['heading'].family
    }
  })
)

const Disclaimer = styled.p<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    fontSize: '12px',
    margin: '0',
    fontStyle: 'italic',
    color: theme.colors['textMute']
  })
)
export default OrderLoyalty
