import { useEffect, useContext, useCallback, useState } from 'react'
import AlertMessage from '../Cart/AlertMessage'
import styled from '@emotion/styled'
import { CheckoutNavbar, StyledHeading, Button } from 'shop/components'
import {
  useShop,
  useCart,
  useCheckout,
  useModal,
  FlashContext
} from 'shop/hooks'
import { SPLIT_PAYMENT_LINKS } from 'shop/client'
import {} from 'shop/components/Cart/utils'
import { getStoreUrl } from 'shop/utils/store'
import { BackButton } from 'shop/components/Buttons'
import { Cart } from 'shop/components/Landing/types'
import { PaymentLink } from '../Checkout/Network'
import QRCode from 'react-qr-code'
import { CgCheckO as Check } from 'react-icons/cg'
import { FaWhatsapp } from 'react-icons/fa'
import { useQuery } from '@apollo/react-hooks'
import { formatMoney } from 'shop/components/Cart/utils'
import { buildWhatsAppLink } from './utils'
import { RiMessage2Line } from 'react-icons/ri'
import Action from '../Order/OrderAction'
import CopyLinkButton from './CopyLinkButton'
import smsLink from 'sms-link'
import { ContentWrapper, Header } from './Split'
import { usePageViewTracker } from 'tracker'

const PaymentLinks = () => {
  const { cartSession, useShopClient, setIsStoreLoading } = useShop()
  const { cartId } = cartSession
  const { inbox } = useContext(FlashContext)
  const { setCart } = cartSession
  const { loadCart } = useCart()
  const cart = cartSession.cart as Cart
  const client = useShopClient()
  const { isModalOpen, cartValidation } = useCheckout(cart, client)
  const { timeSlotModal } = useModal()
  const [paymentLinks, setPaymentLinks] = useState<PaymentLink[]>([])

  const startLoad = useCallback(async () => {
    const cart = await loadCart()
    setCart(cart)
    setIsStoreLoading(false)
  }, [loadCart, setIsStoreLoading])

  useEffect(() => {
    startLoad()
  }, [])

  // Track page view
  usePageViewTracker()

  const { data } = useQuery<{
    payments: PaymentLink[]
    orders: { transaction_id: string }[]
  }>(SPLIT_PAYMENT_LINKS, {
    variables: {
      cartId: cartId
    },
    pollInterval: 3000
  })

  useEffect(() => {
    if (data) {
      setPaymentLinks(data.payments)
    }
  }, [data])

  const computeTotal = () => cart.total

  // Check if we're fully paid
  if (data && data.orders && data.orders[0]?.transaction_id) {
    // Clear cart now we have a successful
    localStorage.removeItem('cartId')
    window.location.href = `/order/track/${data.orders[0]?.transaction_id}`
  }

  if (!cart) return null

  return (
    <>
      {isModalOpen('timeslot') && timeSlotModal(cartValidation)}
      <Container>
        <CheckoutNavbar />
        <ContentWrapper>
          {inbox.map((message) => (
            <AlertMessage heading={message.content} flash type={message.type} />
          ))}
          <DesktopBackButton>
            <BackButton
              url={getStoreUrl(cart.store.slug)}
              label='Back'
              size='12px'
            />
          </DesktopBackButton>
          <Header>
            <Heading>{cart.store.name}</Heading>
            <Price>{formatMoney(computeTotal())}</Price>
          </Header>

          {paymentLinks &&
            paymentLinks.map((payment) => {
              const paymentRef = payment.internal_reference
              const paymentLink = `https://${process.env.REACT_APP_PAYMENT_HOST}/pay/${paymentRef}`
              const body = `Pay for your order here: ${paymentLink}`
              const encodedBody = encodeURI(body)
              const { amount, status } = payment
              const { name, contact_num } = payment.customer_details

              return (
                <Payment>
                  <Details>
                    <Text>Share this QR code with</Text>
                    <Heading>{name}</Heading>

                    <QRContainer>
                      <QRCode value={paymentLink} size={200} />
                    </QRContainer>
                  </Details>
                  <Actions>
                    {status === 'pending' && (
                      <ButtonGroup>
                        <CopyLinkButton link={paymentLink} />
                        <a href={paymentLink} target='_blank'>
                          <Button variant='secondary' size='md'>
                            Pay £{amount}
                          </Button>
                        </a>
                      </ButtonGroup>
                    )}
                    {status !== 'pending' && (
                      <Button state='success' size='md'>
                        <Check /> Paid - £{amount}
                      </Button>
                    )}

                    {status === 'pending' && (
                      <>
                        <p>Or send it via</p>
                        <Links>
                          <Link href={smsLink({ phone: contact_num, body })}>
                            <Action
                              icon={<RiMessage2Line />}
                              label='SMS'
                              color='white'
                            />
                          </Link>
                          <Link
                            href={buildWhatsAppLink(contact_num, encodedBody)}
                          >
                            <Action
                              icon={<FaWhatsapp />}
                              label='Whatsapp'
                              color='white'
                            />
                          </Link>
                        </Links>
                      </>
                    )}
                  </Actions>
                </Payment>
              )
            })}
        </ContentWrapper>
      </Container>
    </>
  )
}

const Container = styled.div(() => ({
  minHeight: '100vh',
  backgroundColor: '#f2f3f4',
  position: 'relative'
}))

const Actions = styled.div(({ theme }: any) => ({
  fontSize: theme.fontSizes[0],
  '> p': {
    textAlign: 'center'
  }
}))

const Links = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  '> *': { margin: '0 8px' },
  justifyContent: 'center'
}))

const Link = styled.a(() => ({
  textDecoration: 'none'
}))

const Payment = styled.div(({ theme }: any) => ({
  padding: '24px 0',
  margin: '24px 0 24px',
  borderRadius: '4px',
  fontSize: theme.fontSizes[2],
  borderBottom: '1px solid #F0F0F0',
  [theme.mediaQueries.viewport8]: {
    padding: '24px'
  }
}))

const Details = styled.div(({ theme }: any) => ({
  textAlign: 'center'
}))

export const Price = styled.div(() => ({
  padding: '4px 0'
}))
const QRContainer = styled.div(({ theme }: any) => ({
  boxShadow: '0px 1px 2px rgba(74, 74, 104, 0.1)',
  backgroundColor: 'white',
  margin: '24px 0',
  padding: '40px',
  borderRadius: '4px',
  display: 'inline-block'
}))

const Heading = styled(StyledHeading)(({ theme }: any) => ({
  fontWeight: 'bold',
  margin: 0
}))

const Text = styled.p(({ theme }: any) => ({
  margin: '0 0 8px',
  fontSize: theme.fontSizes[1]
}))

const DesktopBackButton = styled.div(({ theme }: any) => ({
  display: 'none'
}))

const ButtonGroup = styled.div(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  width: '280px',
  margin: '0 auto',
  gridGap: '12px'
}))

export default PaymentLinks
