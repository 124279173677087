import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'

interface Props {
  quantity: number
}

const Quantity = ({ quantity }: Props): JSX.Element => {
  return (
    <Content data-testid='quantity'>
      <Quant>{quantity}</Quant>
    </Content>
  )
}

const Content = styled.div<StyledHTMLElement>(() => ({
  justifySelf: 'center'
}))

const Quant = styled.strong<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    fontSize: theme.fontSizes[2],
    fontWeight: 400,
    margin: '0 6px'
  })
)

export default Quantity
