import { ButtonHTMLAttributes } from 'react'
import styled from '@emotion/styled'
import defaultTheme, { stateColors } from 'shop/theme/defaultTheme'
import Theme from 'shop/theme/types'
import Spinner from '../Loader/Spinner'

type ButtonVariant = 'primary' | 'secondary' | 'link'

export interface IProps {
  size?: 'sm' | 'md' | 'lg' | 'auto'
  children: React.ReactNode
  testId?: string
  width?: string
  state?: string
  variant?: ButtonVariant
  uppercase?: boolean
  icon?: React.ReactNode
  fontWeight?: 'bold' | 'lighter' | 'normal'
  fontColor?: string
  isLoading?: boolean
}

export type StyledButtonType = IProps &
  React.DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >

const Button: React.FC<StyledButtonType> = ({
  children,
  testId,
  icon,
  isLoading = false,
  ...props
}) => {
  return (
    <Container data-testid={testId} {...props}>
      <Content>
        <SpinnerWrapper isLoading={isLoading}>
          <Spinner />
        </SpinnerWrapper>
        <TextWrapper isLoading={isLoading}>
          {icon && icon}
          {children}
        </TextWrapper>
      </Content>
    </Container>
  )
}

const _paddingSizes = { sm: 1, md: 2, lg: 2 }
const _fontSizes = { sm: 0, md: 1, lg: 2 }
const _height = { sm: '28px', md: '36px', lg: '48px', auto: 'auto' }

export const Container = styled.button<StyledButtonType, Theme>(
  ({
    state,
    theme,
    size = 'lg',
    width = '100%',
    variant = 'primary',
    uppercase = false,
    disabled = false,
    fontWeight = 'bold',
    fontColor
  }) => {
    const themeSpace = theme.space ?? defaultTheme.space!
    const {
      colors = defaultTheme['colors']!,
      fonts = defaultTheme['fonts']!,
      fontSizes = defaultTheme['fontSizes']!
    } = theme

    const successStateStyling = {
      borderColor: stateColors.success[5],
      color: 'white',
      backgroundColor: stateColors.success[5],
      '&:hover': {
        backgroundColor: stateColors.success[5]
      }
    }

    const disabledStyles = {
      backgroundColor: colors['primary'],
      opacity: 0.4,
      color: colors['white'],
      cursor: 'not-allowed'
    }

    const variantStyles = {
      primary: {
        backgroundColor: colors['primary'],
        color: fontColor || colors['white'],
        '&:hover': {
          opacity: 0.8
        },
        '&:active': {
          opacity: 0.6
        }
      },
      secondary: {
        color: fontColor || colors['black'],
        backgroundColor: '#FAFAFA',
        '&:hover': {
          backgroundColor: '#F0F0F0'
        },
        '&:active': {
          backgroundColor: '#D9D9D9'
        }
      },
      link: {
        color: colors['primary'],
        backgroundColor: 'transparent'
      }
    }

    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 0,
      borderRadius: '12px',
      cursor: 'pointer',
      lineHeight: '1em',
      '&:focus': { outline: 'none' },
      height: `${_height[size]}`,
      padding: `12px ${themeSpace[_paddingSizes[size] + 2]}px`,
      fontFamily: fonts['heading']['family'],
      textTransform: uppercase ? 'uppercase' : 'unset',
      fontSize: fontSizes[_fontSizes[size]],
      WebkitTapHighlightColor: 'transparent',
      width: width,
      fontWeight: fontWeight,
      ...(disabled ? disabledStyles : variantStyles[variant]),
      ...(state === 'success' ? successStateStyling : {})
    }
  }
)

const Content = styled.div(() => ({
  position: 'relative'
}))

const SpinnerWrapper = styled.div<{ isLoading: boolean }>(({ isLoading }) => ({
  visibility: isLoading ? 'visible' : 'hidden',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
}))

const TextWrapper = styled.div<{ isLoading: boolean }>(({ isLoading }) => ({
  visibility: isLoading ? 'hidden' : 'visible',
  '> svg': { marginRight: '8px' },
  display: 'flex',
  alignItems: 'center'
}))

export default Button
