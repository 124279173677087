import styled from '@emotion/styled'

type Props = {
  children: React.ReactElement | string
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  margin?: string
  fontWeight?: string | number
  fontSize?: string | number
  color?: string
}

const Heading = ({
  fontWeight = 'bold',
  color,
  fontSize,
  children,
  as,
  margin
}: Props) => (
  <StyledHeading
    as={as || 'h1'}
    fontWeight={fontWeight}
    color={color}
    fontSize={fontSize}
    margin={margin}
  >
    {children}
  </StyledHeading>
)

export const StyledHeading = styled.div(
  ({ fontWeight, theme, fontSize, color, margin }: any) => ({
    fontWeight,
    fontSize,
    color,
    fontFamily: theme.fonts.heading.family,
    margin: margin || '0  0 24px'
  })
)

export default Heading
