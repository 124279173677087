import { DeliveryAreaSetting, Store } from './types'
import { getStorePreOrderSettings } from './preOrderUtils'
import { CurrentLocationState } from './types'
import { getDistanceFromLocation } from './locationUtils'

export const isAccessible = (
  store: Store,
  currentLocation: CurrentLocationState | undefined,
  fulfillmentType: string = 'pickup',
  isPreorder: boolean
) => {
  if (fulfillmentType === 'pickup') return false
  if (!currentLocation) return false

  const distance =
    getDistanceFromLocation(
      store.address.geom.coordinates[0],
      store.address.geom.coordinates[1],
      currentLocation.latLng.lat,
      currentLocation.latLng.lng,
      'M'
    ) || 0

  const storePreOrderSettings = getStorePreOrderSettings(store)
  const storeSamedaySettings = store.settings

  const getDeliveryArea = (deliveryAreaSetting?: DeliveryAreaSetting) => {
    if (!deliveryAreaSetting) return []
    const isBusy = !!deliveryAreaSetting.busy_delivery_area_enabled
    const deliveryArea = isBusy
      ? deliveryAreaSetting.busy_delivery_area
      : deliveryAreaSetting.delivery_area
    return deliveryArea || []
  }

  const checkDeliveryArea = ({
    settings,
    currentLocation,
    deliveryRadius,
    distance
  }: {
    settings?: DeliveryAreaSetting
    currentLocation: CurrentLocationState
    deliveryRadius: number
    distance: number
  }) => {
    const deliveryArea = getDeliveryArea(settings)
    if (!!deliveryArea?.length) {
      return isWithinCustomDeliveryArea(deliveryArea, currentLocation)
    }
    return deliveryRadius >= distance
  }

  // Check preorder delivery areas
  if (isPreorder && storePreOrderSettings) {
    return checkDeliveryArea({
      settings: storePreOrderSettings.delivery_area_setting,
      currentLocation,
      deliveryRadius: storePreOrderSettings.delivery_radius,
      distance
    })
  }

  // Check sameday delivery areas
  if (storeSamedaySettings) {
    return checkDeliveryArea({
      settings: storeSamedaySettings,
      currentLocation,
      deliveryRadius: storeSamedaySettings.delivery_radius,
      distance
    })
  }
  return false
}

export const isWithinCustomDeliveryArea = (
  deliveryArea: any[], // see DeliveryAreaSetting
  location: CurrentLocationState
) => {
  const referenceLocation = new window.google.maps.LatLng(
    location.latLng.lat,
    location.latLng.lng
  )
  const bounds = new window.google.maps.Polygon({
    paths: deliveryArea
  })

  const within = window.google.maps.geometry.poly.containsLocation(
    referenceLocation,
    bounds
  )

  return within
}
