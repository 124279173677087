import CenterModal from './CenterModal'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { Button } from 'shop/components'
import { useHistory } from 'react-router-dom'
import { getErrorMessage, getErrorTitle } from './errorUtils'
import { IoIosArrowBack as BackArrowIcon } from 'react-icons/io'

export type ErrorModalButton = {
  message?: string
  onClick?: () => void
  icon?: React.ReactNode
}

type ErrorModalProps = {
  errorCode: string
  errorTitle?: string
  errorMessages?: string[]
  primaryButton?: ErrorModalButton
  secondaryButton?: ErrorModalButton
}

const ErrorModal = ({
  errorCode,
  errorTitle,
  errorMessages,
  primaryButton,
  secondaryButton
}: ErrorModalProps) => {
  const history = useHistory()
  const handleClick = (buttonClick?: () => void) => {
    if (buttonClick) {
      buttonClick()
      return
    }
    history.goBack()
  }
  const messages = errorMessages || getErrorMessage(errorCode)
  const title = errorTitle || getErrorTitle(errorCode)

  const showPrimaryButton = !!primaryButton?.message
  const showSecondaryButton = !!secondaryButton?.message

  const useDefaultButton = !showPrimaryButton && !showSecondaryButton

  return (
    <CenterModal canClose={false}>
      <Container>
        <Header>{title}</Header>
        <Content>
          {messages.map((line, index) => (
            <Line key={index}>{line}</Line>
          ))}
        </Content>
        <ButtonContainer>
          {useDefaultButton && (
            // default button
            <Button
              fontWeight={'bold'}
              onClick={() => handleClick()}
              icon={<BackArrowIcon />}
            >
              {'Back'}
            </Button>
          )}
          {primaryButton && (
            <Button
              fontWeight={'bold'}
              onClick={() => handleClick(primaryButton.onClick)}
              icon={primaryButton?.icon}
            >
              {primaryButton.message}
            </Button>
          )}
          {showSecondaryButton && (
            <Button
              variant='secondary'
              fontWeight={'bold'}
              onClick={() => handleClick(secondaryButton.onClick)}
              icon={secondaryButton?.icon}
            >
              {secondaryButton.message}
            </Button>
          )}
        </ButtonContainer>
      </Container>
    </CenterModal>
  )
}
const Container = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    padding: '32px'
  })
)

const Header = styled.h1<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  fontSize: '20px',
  marginBottom: '24px'
}))

const Content = styled.div<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  color: theme.colors['textMute'],
  marginBottom: '40px'
}))

const ButtonContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '15px'
  })
)

const Line = styled.p<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  marginBottom: '1em',
  ':last-child': {
    marginBottom: '0'
  }
}))

export default ErrorModal
