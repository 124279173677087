import { useCallback, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Category } from './types'

interface Props {
  selectedCategory: string
  categories: Category[]
  onClick: (categoryId: string) => void
  scrollTo: (offsetLeft: number, elementWidth: number) => void
}

export const CategoriesList = ({
  categories,
  selectedCategory,
  onClick,
  scrollTo
}: Props) => {
  const [indicatorWidth, setIndicatorWidth] = useState<number>(42)
  const [indicatorOffset, setIndicatorOffset] = useState<number>(0)

  useEffect(() => {
    const element = document.getElementById(`category-li-${selectedCategory}`)

    if (element) {
      scrollTo(element.offsetLeft, element.offsetWidth)
      setIndicatorOffset(element.offsetLeft)
      setIndicatorWidth(element.offsetWidth)
    }
  }, [categories, selectedCategory, scrollTo])

  const handleCategoryClick = useCallback(
    (categoryName: string, categoryId?: string) => () => {
      if (categoryId) {
        return onClick(categoryId)
      }

      return onClick(categoryName)
    },
    [onClick]
  )

  const renderCategories = useCallback(() => {
    if (!categories?.length) {
      return <></>
    }

    return categories.map((category, i) => {
      return (
        <ListItem
          className={selectedCategory === category.id ? 'active' : ''}
          key={i}
          id={`category-li-${category.id}`}
          data-testid={`${category.name}Category`}
          onClick={handleCategoryClick(
            category.name.toLowerCase(),
            category.id
          )}
        >
          {category.name}
        </ListItem>
      )
    })
  }, [categories, handleCategoryClick, selectedCategory])

  return (
    <Root>
      <List
        data-testid='categories'
        indicatorWidth={indicatorWidth}
        indicatorOffset={indicatorOffset}
      >
        {renderCategories()}
      </List>
    </Root>
  )
}

const Root = styled.div(() => ({
  width: 'fit-content',
  margin: '0 auto',
  position: 'relative',
  zIndex: 10
}))

const List = styled.ul(({ theme, indicatorWidth, indicatorOffset }: any) => ({
  margin: '0',
  width: 'inherit',

  listStyleType: 'none',
  fontFamily: theme.fonts.body.family,
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
  justifyContent: 'flex-start',

  '&:after': {
    content: '""',
    zIndex: -1,
    position: 'absolute',
    width: indicatorWidth,
    top: 0,
    bottom: 0,
    left: `${indicatorOffset}px`,
    right: 0,
    transition: 'all  .4s ease-in',
    backgroundColor: theme.colors['primary'],
    borderRadius: '12px'
  }
}))

const ListItem = styled.li(() => ({
  padding: '8px 12px',
  borderRadius: '12px',
  minHeight: '38px',
  display: 'flex',
  alignItems: 'center',

  color: '#595959',
  fontSize: '14px',
  textAlign: 'center',
  fontWeight: 600,
  whiteSpace: 'nowrap',

  cursor: 'pointer',
  WebkitTapHighlightColor: 'transparent',

  position: 'relative',
  overflow: 'hidden',

  '&:after': {
    content: '""',
    zIndex: -2,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: '12px'
  },

  '&:hover': {
    '&:after': {
      backgroundColor: '#f0f0f0'
    }
  },

  '&:active': {
    '&:after': {
      backgroundColor: '#d9d9d9'
    }
  },

  '&.active': {
    transition: 'color  .8s ease-in',
    color: '#fff',

    '&:hover': {
      '&:after': {
        backgroundColor: 'transparent'
      }
    }
  },

  '&.disabled': {
    color: '#bfbfbf'
  }
}))
