import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { SimpleFormat } from 'shop/utils'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { InfoHeader } from './commonStyles'
import {
  FaChevronDown as DownArrowIcon,
  FaChevronRight as RightArrowIcon
} from 'react-icons/fa'

interface Props {
  description?: string
  forceShowDescription?: boolean
  isStoreDescription?: boolean
}

interface SeeMoreProps {
  hasExpandedText: boolean
  isStoreDescription: boolean
}

const Description = ({
  description = '',
  forceShowDescription = false,
  isStoreDescription = false
}: Props) => {
  const ref: React.RefObject<HTMLParagraphElement> = React.createRef()
  const [isSeeMoreEnabled, setIsSeeMoreEnabled] = useState(false)
  const [hasExpandedText, setHasExpandedText] = useState(forceShowDescription)
  const [maxHeight, setMaxHeight] = useState<number | undefined>()

  useEffect(() => {
    // for animation purposes, setting the maxHeight to fit the content
    setMaxHeight(ref.current?.scrollHeight)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current?.scrollHeight, setMaxHeight])

  // Detects whether we need to show the "See more" or not
  useLayoutEffect(() => {
    // no need to set "See more" if we are forcing to show the description
    if (ref.current && !forceShowDescription) {
      if (ref.current?.clientHeight < ref.current.scrollHeight) {
        setIsSeeMoreEnabled(true)
      }
    }
  }, [ref, forceShowDescription])

  const handleSeeMoreClick = () => setHasExpandedText(!hasExpandedText)

  const SeeMoreControl = ({
    hasExpandedText,
    isStoreDescription
  }: SeeMoreProps) => {
    return (
      <SeeMoreContainer
        data-testid='expand-control'
        onClick={handleSeeMoreClick}
        isStoreDescription={isStoreDescription}
      >
        {hasExpandedText ? 'See less' : 'See more'}
        {hasExpandedText ? <DownArrowIcon /> : <RightArrowIcon />}
      </SeeMoreContainer>
    )
  }

  if (!description) return <></>

  return (
    <Container>
      {!isStoreDescription && (
        <HeaderContainer>
          <InfoHeader>Description</InfoHeader>
          {isSeeMoreEnabled && (
            <SeeMoreControl
              hasExpandedText={hasExpandedText}
              isStoreDescription={isStoreDescription}
            />
          )}
        </HeaderContainer>
      )}
      <DescriptionContainer
        data-testid='product-description'
        ref={ref}
        {...{
          hasExpanded: hasExpandedText,
          isSeeMoreEnabled,
          maxHeight: maxHeight,
          isStoreDescription
        }}
      >
        <SimpleFormat>{description}</SimpleFormat>
      </DescriptionContainer>
      {isStoreDescription && isSeeMoreEnabled && (
        <SeeMoreControl
          hasExpandedText={hasExpandedText}
          isStoreDescription={isStoreDescription}
        />
      )}
    </Container>
  )
}

const Container = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column'
  })
)

const HeaderContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    lineHeight: '18px',
    marginBottom: '8px',

    '& h2': {
      margin: '0'
    }
  })
)

const DescriptionContainer = styled.div<
  StyledHTMLElement & {
    hasExpanded: boolean
    isSeeMoreEnabled: boolean
    maxHeight?: number
    isStoreDescription?: boolean
  },
  Required<Theme>
>(
  ({
    theme,
    hasExpanded,
    isSeeMoreEnabled,
    maxHeight,
    isStoreDescription
  }) => ({
    position: 'relative',
    transition: 'max-height 0.5s ease-in-out',
    fontSize: '14px',
    lineHeight: isStoreDescription ? 'normal' : '1',
    // maxHeight values of "fit-content" and "100%" don't animate properly
    maxHeight: hasExpanded
      ? `${maxHeight || 2000}px`
      : isStoreDescription
        ? '42px'
        : '72px',
    marginBottom: isStoreDescription ? '4px' : '24px',
    overflow: 'hidden',
    color: '#262626',

    [theme.mediaQueries.viewport7]: {
      maxHeight: hasExpanded
        ? `${maxHeight || 2000}px`
        : isStoreDescription
          ? '40px'
          : '54px'
    },

    '& p:first-of-type': {
      marginTop: 0
    },

    // Gradient effect to better display overflow of text
    '::after': {
      content: "''",
      position: 'absolute',
      zIndex: 1,
      bottom: 0,
      left: 0,
      pointerEvents: 'none',
      backgroundImage:
        hasExpanded || !isSeeMoreEnabled
          ? ''
          : isStoreDescription
            ? 'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 46.35%, #f1f1f1 100%)'
            : 'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 46.35%, #FFF 100%)',
      width: '100%',
      height: '4em'
    }
  })
)

const SeeMoreContainer = styled.div<
  StyledHTMLElement & { isStoreDescription?: boolean },
  Required<Theme>
>(({ theme, isStoreDescription }) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 600,
  fontSize: isStoreDescription ? '14px' : '12px',
  cursor: 'pointer',
  margin: '0',
  gap: '3px',

  '& svg': {
    height: '10px',
    width: '10px'
  }
}))

export default Description
