import { useState } from 'react'
import styled from '@emotion/styled'
import { Props } from './types'
import { MobileTimeSlotList } from './MobileTimeSlotList'
import FullscreenModal from 'shop/components/Modal/FullscreenModal'
import SelectInput from 'shop/components/Inputs/SelectInput'
import { FaAngleDown as DownArrow } from 'react-icons/fa'
import { BiEditAlt as EditIcon } from 'react-icons/bi'
import { useMediaQueries } from 'shop/hooks'
import CenterModal from 'shop/components/Modal/CenterModal'

const TimeSlotSelector = (props: Props) => {
  const [isTimeSlotSelectionOpen, setIsTimeSlotSelectionOpen] = useState(false)

  const selectedTimeSlotRange =
    props.timeSlots.find((ts) => ts.value === props.value)?.range || ''

  return (
    <>
      <SelectInput
        testId='change-time-select-input'
        textContent={selectedTimeSlotRange}
        textFallback={'Select Time Slot'}
        onClick={() => setIsTimeSlotSelectionOpen(true)}
        hasSelected={!!selectedTimeSlotRange.length}
        ToSelectIcon={DownArrow}
        HasSelectedIcon={EditIcon}
        disabled={props.timeSlots.length === 1}
      />
      {isTimeSlotSelectionOpen && (
        <MobileTimeSlotSelectorModal
          {...props}
          onClose={() => setIsTimeSlotSelectionOpen(false)}
        />
      )}
    </>
  )
}

export const MobileTimeSlotSelectorModal = (
  props: Props & { onClose: () => void }
) => {
  const { isMobile } = useMediaQueries()
  const { inModal = false } = props

  const handleUpdateWithClose = (
    time: string | null,
    range: string | null,
    extended: boolean
  ): void => {
    props.handleUpdate(time, range, extended)
    setTimeout(() => props.onClose(), 200)
  }

  const timeslotComponent = (
    <MobileTimeSlotList
      {...props}
      onClick={props.onClose}
      handleUpdate={handleUpdateWithClose}
    />
  )

  return inModal && !isMobile ? (
    <CenterModal
      handleCloseModal={props.onClose}
      styles={{ minHeight: '50%', overflowY: 'hidden' }}
      inModal={inModal}
    >
      <CenterMain>{timeslotComponent}</CenterMain>
    </CenterModal>
  ) : (
    <FullscreenModal onClose={props.onClose} isLandingColumn={!isMobile}>
      <FullscreenMain>{timeslotComponent}</FullscreenMain>
    </FullscreenModal>
  )
}

const CenterMain = styled.main`
  padding: 24px 36px;
`

const FullscreenMain = styled.main`
  padding: 0 36px;
`

export default TimeSlotSelector
