import { Option } from 'shop/types/cart'
import { ModifierOptionGroupName } from '../commonStyles'
import styled from '@emotion/styled'
import { OptionV2 } from '../index'

interface Props {
  options: Option[]
}

const OptionsV2 = ({ options }: Props) => {
  return (
    <Content data-testid='options'>
      {options.map(({ name, values }, index) => (
        <div key={`option-${name}-${index}`}>
          <ModifierOptionGroupName>
            {name}
            <OptionV2 values={values} />
          </ModifierOptionGroupName>
        </div>
      ))}
    </Content>
  )
}

const Content = styled.div(() => ({
  whiteSpace: 'pre'
}))

export default OptionsV2
