import { useState } from 'react'
import styled from '@emotion/styled'
import CustomerEditForm from './CustomerEditForm'
import ChangePasswordForm from './ChangePasswordForm'
import { Button } from '../Controls'

export interface PersonalInfoProps {
  first_name: string
  last_name: string
  email: string
  merchant_id: string
  customerId: string
  contact_num: string
  birthdate: string
  birthday_update_count: number
  marketing_opt_in: boolean
}

type UpdateInfoProps = {
  updatedFirstName: string
  updatedLastName: string
  updatedEmail: string
  updatedContactNum: string
  updatedBirthdate: string
  updatedBirthdayEditCount: number
  updatedOptIn: boolean
}

const PersonalInfo = ({
  first_name,
  last_name,
  email,
  merchant_id,
  contact_num,
  customerId,
  birthdate,
  birthday_update_count,
  marketing_opt_in
}: PersonalInfoProps) => {
  const [showEditModal, setShowEditModal] = useState(false)
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
  const [firstName, setFirstName] = useState(first_name)
  const [lastName, setLastName] = useState(last_name)
  const [contactNum, setContactNum] = useState(contact_num)
  const [displayedEmail, setDisplayedEmail] = useState(email)
  const [birthdayUpdateCount, setBirthdayEditCount] = useState(
    birthday_update_count
  )
  const [marketingOptIn, setMarketingOptIn] = useState(marketing_opt_in)
  const [customerBirthdate, setCustomerBirthdate] = useState(birthdate || '')
  const headerText = !!firstName ? `Welcome, ${firstName}!` : 'Welcome!'

  const updateInfo = ({
    updatedFirstName,
    updatedLastName,
    updatedEmail,
    updatedContactNum,
    updatedBirthdate,
    updatedBirthdayEditCount,
    updatedOptIn
  }: UpdateInfoProps) => {
    setFirstName(updatedFirstName)
    setLastName(updatedLastName)
    setDisplayedEmail(updatedEmail)
    setContactNum(updatedContactNum)
    setCustomerBirthdate(updatedBirthdate)
    setBirthdayEditCount(updatedBirthdayEditCount)
    setMarketingOptIn(updatedOptIn)
  }

  return (
    <Container data-testid='personalInfo'>
      <Header>
        <div>
          <h1>{headerText}</h1>
          <UserDetails>
            {`${firstName} ${lastName}`} &bull; {displayedEmail} &bull;{' '}
            {contactNum}
          </UserDetails>
        </div>
        <ButtonWrapper>
          <Button
            variant='secondary'
            size='md'
            testId='changePasswordLink'
            onClick={() => setShowChangePasswordModal(true)}
          >
            Change password
          </Button>
          <Button
            size='md'
            onClick={() => setShowEditModal(true)}
            testId='editCustomerLink'
          >
            Edit profile
          </Button>
        </ButtonWrapper>
      </Header>
      {showEditModal && (
        <CustomerEditForm
          {...{
            customerId,
            merchant_id,
            first_name: firstName,
            last_name: lastName,
            email: displayedEmail,
            contact_num: contactNum,
            birthdate: customerBirthdate,
            birthday_update_count: birthdayUpdateCount,
            marketing_opt_in: marketingOptIn,
            onClose: () => setShowEditModal(false),
            updateInfo: updateInfo
          }}
        />
      )}
      {showChangePasswordModal && (
        <ChangePasswordForm
          {...{
            customerId,
            merchant_id,
            first_name: firstName,
            last_name: lastName,
            email: displayedEmail,
            onClose: () => setShowChangePasswordModal(false)
          }}
        />
      )}
    </Container>
  )
}

const Container = styled.div(({ theme }: any) => ({
  padding: '24px 4px',
  width: '100%',
  textAlign: 'left',
  '> h3': {
    fontWeight: theme.fontWeights.bold
  }
}))

const UserDetails = styled.p(({ theme }: any) => ({
  margin: ' 8px 0 24px'
}))

const ButtonWrapper = styled.div(({ theme }: any) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridColumnGap: '16px',
  flex: '1 0 auto',
  marginBottom: '24px',
  [theme.mediaQueries.viewport6]: {
    marginBottom: '0',
    flex: '0 0 auto'
  }
}))

const Header = styled.div(({ theme }: any) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  [theme.mediaQueries.viewport6]: {
    alignItems: 'flex-start',
    flexDirection: 'row'
  }
}))

export default PersonalInfo
