import ReactPixel from 'react-facebook-pixel'
import Tracker from 'tracker'

interface MerchantProps {
  pixel_id: string
  slug: string
}

export const initializePixel = ({ pixel_id: pixelId }: MerchantProps) => {
  if (pixelId) {
    ReactPixel.init(pixelId)
    Tracker.getInstance().setFbPixelEnabled(true)
    if (!window.localStorage.getItem('cookiesAllowed')) {
      ReactPixel.revokeConsent()
      Tracker.getInstance().setFbPixelEnabled(false)
    }
  }
}

export const grantPixelConsent = () => {
  ReactPixel.grantConsent()
}

export const revokePixelConsent = () => {
  ReactPixel.revokeConsent()
}

export const trackFBPixeltrackProductListViewed = () => {
  const { domain, store } = setup()

  ReactPixel.trackCustom('Product List Viewed', {
    category: 'Product List',
    domain,
    store
  })
}

export const trackUserActionsFBPixel = (
  action: string,
  actionParams?: object
) => {
  ReactPixel.trackCustom(action, actionParams)
}

export const trackUserPurchaseFBPixel = (actionParams: object) => {
  if (Tracker.getInstance().fbPixelEnabled) {
    ReactPixel.track('Purchase', actionParams)
  }
}

const setup = () => {
  let domain: string | null = null
  let store: string | null = null

  const root = document.getElementById('root')

  if (root) {
    domain = root.getAttribute('domain')
    store = root.getAttribute('store')
  }

  return { domain, store }
}
