import { ReactElement } from 'react'
import { Loading } from '../Loading'

interface Props {
  isParentLoaded?: boolean
  children: ReactElement
}

export const LoaderSwitcher = ({
  isParentLoaded,
  children
}: Props): ReactElement => {
  return isParentLoaded ? children : <Loading />
}
