export const humaniseParam = (param: string | undefined) => {
  if (!param) {
    return null
  }

  return param
    .replace('---', '--')
    .split('-')
    .map((word: string | null) => word || '-')
    .join(' ')
}
