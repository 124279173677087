import { trackUserActionsGA4, UserParams } from './GAtools'
import { trackUserActionsFBPixel } from './PixelTools'

type Options = {
  event: string
}

/*
  This block of code is everywhere
  and it's quite repetitive so I
  created this function to be reusable
*/

const trackAction = (params: UserParams, options: Options) => {
  // legacy tracking
  trackUserActionsFBPixel(options.event || params.action)

  trackUserActionsGA4(params, 'slerpGA4Tracking')
  trackUserActionsGA4(params, 'merchantGA4Tracking')
}

export default trackAction
