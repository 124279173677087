import { useState } from 'react'
import { useShop } from 'shop/hooks'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import CenterModal from '../Modal/CenterModal'
import { ReactComponent as PiggyBankIcon } from 'shop/assets/icons/piggy-bank.svg'
import TippingList from './TippingList'
import { FaChevronRight } from 'react-icons/fa'
import CustomTip from './CustomTip'
import { MUTATE_UPDATE_TIP_VALUE } from 'shop/client'

type Props = {
  handleCloseModal: () => void
  proceedCheckout: (addedTip?: number) => void
  tippingValues: { [key: number]: number }
}

export type TipType = 'custom' | 'standard'

const Tipping = ({
  handleCloseModal,
  proceedCheckout,
  tippingValues
}: Props) => {
  const { cartSession, useShopClient } = useShop()
  const { cart } = cartSession
  const client = useShopClient()

  const [tipAmount, setTipAmount] = useState<number>(0)
  // TipType clears other selected options
  const [tipType, setTipType] = useState<TipType>('standard')

  const handleButtonClick = () => {
    if (tipAmount) {
      client
        .mutate({
          mutation: MUTATE_UPDATE_TIP_VALUE,
          variables: {
            id: cart?.id,
            tip: tipAmount
          }
        })
        .then(() => {
          handleCloseModal()
          proceedCheckout(tipAmount)
        })
    } else {
      handleCloseModal()
      proceedCheckout(0)
    }
  }

  return (
    <CenterModal
      handleCloseModal={handleCloseModal}
      styles={{ padding: '48px 34px 33px 34px', borderRadius: '20px' }}
    >
      <TippingContainer date-testid='tipping-container'>
        <TippingHeader>
          <TippingIcon>
            <PiggyBankIcon />
          </TippingIcon>
          <TippingTextContainer>
            <h1>would you like to add a tip?</h1>
            <h2>100% of your tip goes to our amazing staff!</h2>
          </TippingTextContainer>
        </TippingHeader>
        <TippingList
          tippingValues={tippingValues}
          setTipAmount={setTipAmount}
          tipType={tipType}
          setTipType={setTipType}
        />
        <CustomTip
          setTipAmount={setTipAmount}
          tipType={tipType}
          setTipType={setTipType}
        />
        <Button onClick={handleButtonClick}>
          <h1>
            {tipAmount ? `add £${tipAmount.toFixed(2)} tip` : 'maybe next time'}
          </h1>
          <FaChevronRight />
        </Button>
      </TippingContainer>
    </CenterModal>
  )
}

const TippingContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  })
)
const TippingHeader = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    gap: '20px'
  })
)
const TippingIcon = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    height: '60px',
    width: '60px',
    backgroundColor: theme.colors['primary'],
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',

    '& svg': {
      height: '40px',
      width: '40px',

      '& path': {
        fill: '#fff'
      }
    }
  })
)
const TippingTextContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: '10px',
    justifyContent: 'center',

    '& h1': {
      fontSize: '14px',
      textTransform: 'uppercase'
    },
    '& h2': {
      fontSize: '13px',
      fontWeight: 'lighter',
      lineHeight: '20px',
      color: '#000'
    }
  })
)

const Button = styled.button<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexGrow: 1,
    backgroundColor: theme.colors['primary'],
    border: 0,
    borderRadius: '20px',
    minHeight: '50px',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '30px',
    cursor: 'pointer',

    '& h1': {
      color: '#fff',
      fontSize: '14px',
      textTransform: 'uppercase'
    },

    '& svg': {
      color: '#fff',
      height: '8px'
    }
  })
)

export default Tipping
