import styled from '@emotion/styled'
import { AppliedModifier } from 'shop/components/Landing/types'
import { groupBy, isEmpty } from 'lodash'
import React from 'react'

interface Props {
  options: [{ name: string; value: string }]
  modifiers: AppliedModifier[]
}

const Options = ({ options, modifiers }: Props) => {
  const groupedModifiers = !!modifiers.length
    ? groupBy(modifiers, (item) => item.modifier_group.id)
    : []

  return (
    <Container>
      {!!options.length &&
        options.map(
          ({ name, value }: { name: string; value: string }, index: number) => {
            return (
              <React.Fragment key={`${name}-${index}`}>
                <Name>{name}</Name>
                <p>{value}</p>
              </React.Fragment>
            )
          }
        )}
      {!isEmpty(groupedModifiers) &&
        Object.keys(groupedModifiers).map((id: string) => {
          const modifierGroup = groupedModifiers[id]
          return (
            <span key={id}>
              <Name>{modifierGroup[0].modifier_group.name}</Name>
              {modifierGroup.map((item: AppliedModifier, index: number) => {
                const { modifier, quantity, amount } = item
                return (
                  <p key={modifier.id}>
                    {quantity > 1 && <span>{quantity} </span>}
                    {modifier.name}
                    {amount > 0 && <span> (+£{amount.toFixed(2)})</span>}
                    {index !== modifierGroup.length - 1 && ', '}
                  </p>
                )
              })}
            </span>
          )
        })}
    </Container>
  )
}

const Name = styled.p(({ theme }: any) => ({
  marginBottom: '4px',
  color: theme.colors.black
}))

const Container = styled.div(({ theme }: any) => ({
  fontSize: theme.fontSizes[0],
  color: theme.colors.textMute,
  margin: '2px 15% 4px 0',
  lineHeight: '1.3em',
  '> p': {
    margin: '0 0 2px 0',
    '&:last-child': {
      margin: '0 0 0px 0'
    }
  },
  span: {
    '> p': {
      margin: '0 0 2px 0',
      ':first-of-type': {
        margin: '4px 0 2px 0'
      }
    }
  },
  [theme['mediaQueries']['viewport7']]: {
    fontSize: theme.fontSizes[1]
  }
}))

export default Options
