import { RouteComponentProps } from 'react-router-dom'
import ConfirmationPage from 'shop/pages/Order'
import { AppContentLoader, LoaderSwitcher } from 'router/loaders'

export const TrackWrapper = ({
  match
}: RouteComponentProps<{ id: string }>) => {
  return (
    <AppContentLoader>
      {(isParentLoaded) => (
        <LoaderSwitcher isParentLoaded={isParentLoaded}>
          <ConfirmationPage entityId={match.params.id} mode='order' />
        </LoaderSwitcher>
      )}
    </AppContentLoader>
  )
}
