import gql from 'graphql-tag'

export const QUERY_LOYALTY_CARDS = gql`
  query DisplayCards($merchantId: uuid!) {
    loyalty_cards(
      where: {
        merchant: { id: { _eq: $merchantId } }
        archived_at: { _is_null: true }
        published: { _eq: true }
      }
    ) {
      loyalty_card_id: id
      store_ids
      name
      description
      image
      theme
      stamp_label
      starts_at
      ends_at
      rewards {
        description
        name
        required_quantity
      }
      published
    }
  }
`

export const QUERY_CUSTOMER_CARDS = gql`
  query CustomerCards($customerId: uuid!, $merchantId: uuid!) {
    customer_loyalty_cards(
      where: {
        customer: {
          id: { _eq: $customerId }
          merchant: { id: { _eq: $merchantId } }
        }
        archived_at: { _is_null: true }
        loyalty_card: { archived_at: { _is_null: true } }
      }
    ) {
      customer_card_id: id
      loyalty_card_id
      redeemed
      stamps_aggregate {
        aggregate {
          count
        }
      }
      loyalty_card {
        loyalty_card_id: id
        store_ids
        name
        description
        image
        theme
        stamp_label
        starts_at
        ends_at
        rewards {
          description
          name
          required_quantity
        }
        published
      }
      customer_rewards {
        redeemed_at
        end_datetime
        id
      }
    }
  }
`

export const QUERY_CUSTOMER_REWARDS = gql`
  query GetRewards($customerId: uuid!, $merchantId: uuid!) {
    customer_rewards(
      where: {
        customer: {
          id: { _eq: $customerId }
          merchant: { id: { _eq: $merchantId } }
        }
        archived_at: { _is_null: true }
        reward: { archived_at: { _is_null: true } }
        redeemed_at: { _is_null: true }
      }
    ) {
      id
      redeemed_at
      required_quantity
      reward {
        description
        name
        required_quantity
        loyalty_card_id
        loyalty_card {
          name
          image
          description
          ends_at
        }
      }
    }
  }
`
