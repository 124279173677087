import styled from '@emotion/styled'
import { Switch } from './FormElements'
import { inputCharactersLimitValidator } from 'shop/components/Checkout'
import { FieldError, UseFormMethods } from 'react-hook-form'
import FormInput from '../Inputs/FormInput'
import { FormFields } from './types'

type OrderNotesProps = {
  formHandle: UseFormMethods<FormFields>
  placeholder: string
  enabled: boolean
  error?: FieldError
}

const OrderNotes = ({
  error,
  placeholder,
  formHandle,
  enabled
}: OrderNotesProps) => {
  const { register, watch } = formHandle
  const orderNotes = watch('order_notes')

  return (
    <>
      <HeaderWithSwitch enabled={!!enabled}>
        <Switch
          formRef={register}
          name={'order_notes_enabled'}
          labelText='Add order notes'
        />
      </HeaderWithSwitch>
      {enabled && (
        <FormInput
          id={'order_notes'}
          variant='textarea'
          value={orderNotes}
          data-testid='order-notes-text-area'
          labelText='Order notes'
          formRef={register({
            required: 'Please enter order notes',
            validate: inputCharactersLimitValidator('Order notes')
          })}
          name='order_notes'
          placeholder={placeholder}
          showError={!!error}
        />
      )}
    </>
  )
}

const HeaderWithSwitch = styled.div(({ enabled }: { enabled: boolean }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: enabled ? '16px' : '0'
}))

export default OrderNotes
