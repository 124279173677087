import React from 'react'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { cssSetColorOpacity } from 'shop/theme/helpers'
import { HiCheck as CheckIcon } from 'react-icons/hi'
import { AvailableReward, NextReward } from 'shop/types/cart'

type Props = {
  availableRewards: AvailableReward[]
  nextRewards: NextReward[] | null
  currentStamps: number
}

type RewardList = {
  isEarned: boolean
  isNew: boolean
  stampsForNextReward?: number
  description: string
  name: string
}[]

const CardProgress = ({
  availableRewards,
  nextRewards,
  currentStamps
}: Props) => {
  let combinedRewardList: RewardList = []
  if (!!availableRewards.length) {
    availableRewards.forEach((reward) => {
      combinedRewardList.push({
        isEarned: true, // availableRewards will always be earned
        isNew: reward.isNew, // temp disable isNew until we find the trigger to display
        description: reward.details.description,
        name: reward.details.name
      })
    })
  }
  if (!!nextRewards?.length) {
    nextRewards.forEach((nextReward) => {
      combinedRewardList.push({
        isEarned: false, // nextRewards will never be earned
        isNew: false, // nextRewards will never be new
        stampsForNextReward: nextReward.stampsForNextReward,
        description: nextReward.rewardDetails.description,
        name: nextReward.rewardDetails.name
      })
    })
  }

  return (
    <RewardProgressList data-testid='reward-progress-list'>
      {combinedRewardList.map((reward, index) => {
        const isLast = combinedRewardList.length === index + 1
        const rewardKey = `${reward.name}-${index}`
        const totalStampsForNextReward =
          currentStamps + (reward.stampsForNextReward || 0)
        return (
          <React.Fragment key={rewardKey}>
            <RewardProgressItem key={rewardKey} data-testid={rewardKey}>
              <IconStep isEarned={reward.isEarned}>
                {reward.isEarned ? (
                  <CheckIcon />
                ) : (
                  <span>{totalStampsForNextReward}</span>
                )}
              </IconStep>
              <RewardName isEarned={reward.isEarned}>{reward.name}</RewardName>
              {reward.isNew && (
                <NewRewardContainer>
                  <NewReward>New</NewReward>
                </NewRewardContainer>
              )}
            </RewardProgressItem>
            {!isLast && (
              <ProgressBarConnector
                isEarned={!!combinedRewardList[index + 1]?.isEarned}
              />
            )}
          </React.Fragment>
        )
      })}
    </RewardProgressList>
  )
}

const RewardProgressList = styled.ul(() => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '12px 0 0'
}))

const RewardProgressItem = styled.li(() => ({
  display: 'flex',
  flexGrow: 1,
  margin: '8px 0',
  alignItems: 'center',
  gap: '12px'
}))

const IconStep = styled.div<
  StyledHTMLElement & { isEarned: boolean },
  Required<Theme>
>(({ theme, isEarned }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: isEarned ? theme.colors['primary'] : '#D9D9D9',
  height: '24px',
  width: '24px',
  borderRadius: '50%',

  '& svg': {
    color: '#fff'
  },

  '& span': {
    fontSize: '12px',
    color: '#8B8B8B',
    lineHeight: '24px'
  }
}))

const RewardName = styled.p<{ isEarned: boolean }>(({ isEarned }) => ({
  margin: '0',
  color: isEarned ? 'inherit' : '#8B8B8B',
  fontWeight: 700,
  fontSize: '14px'
}))

const ProgressBarConnector = styled.div<
  StyledHTMLElement & { isEarned: boolean },
  Required<Theme>
>(({ theme, isEarned }) => ({
  display: 'block',
  height: '32px',
  width: '4px',
  backgroundColor: isEarned ? theme.colors['primary'] : '#D9D9D9',
  marginLeft: '10px',
  borderRadius: '1px'
}))

const NewRewardContainer = styled.div(() => ({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'flex-end'
}))

const NewReward = styled.span<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => {
    const fadedPrimaryColor = cssSetColorOpacity(theme.colors['primary'], 35)

    return {
      display: 'flex',
      color: theme.colors['primary'],
      fontWeight: 600,
      fontSize: '12px',
      backgroundColor: fadedPrimaryColor,
      padding: '6px 8px',
      marginLeft: '10px',
      borderRadius: '8px'
    }
  }
)

export default CardProgress
