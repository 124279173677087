import { RouteComponentProps } from 'react-router-dom'
import { AppContentLoader, LoaderSwitcher } from 'router/loaders'
import { Checkout, CheckoutV2 } from '../../shop/pages'
import { CheckoutContextV2Provider } from 'shop/hooks'
import { ConsumerCartLoader } from 'router/loaders/ConsumerCartLoader'

export const CheckoutWrapper = (props: RouteComponentProps) => {
  const checkoutV2Enabled = !!localStorage.getItem('checkoutv2')
  return (
    <AppContentLoader>
      {(isParentLoaded) =>
        checkoutV2Enabled ? (
          // Using ConsumerCart loader until consumer cart is on shop page (always loads consumer cart)
          <ConsumerCartLoader isParentLoaded={isParentLoaded}>
            {(isParentLoaded) => (
              <CheckoutContextV2Provider>
                <LoaderSwitcher isParentLoaded={isParentLoaded}>
                  <CheckoutV2 />
                </LoaderSwitcher>
              </CheckoutContextV2Provider>
            )}
          </ConsumerCartLoader>
        ) : (
          <Checkout {...props} />
        )
      }
    </AppContentLoader>
  )
}
