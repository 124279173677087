import { HTMLAttributes } from 'react'
import styled from '@emotion/styled'
import { AccountIcon } from 'shop/assets/icons'
import { useReactRouter } from 'shop/hooks'
import Theme from 'shop/theme/types'

type StyledHTMLElement = React.DetailedHTMLProps<
  HTMLAttributes<HTMLElement>,
  HTMLElement
> & {
  withIconBorder?: boolean
  inverted?: boolean
}

type Props = {
  inverted?: boolean
}

const AccountMenu = ({ inverted }: Props) => {
  const { history, location } = useReactRouter()

  const handleMenuClick = () => {
    history.push('/account', { from: location.pathname })
  }

  return (
    <AccountMenuContainer id='account-container'>
      <IconContainer {...{ inverted }} onClick={handleMenuClick}>
        <AccountIcon height='18px' width='18px' />
      </IconContainer>
    </AccountMenuContainer>
  )
}

const AccountMenuContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  () => ({
    alignSelf: 'baseline'
  })
)

const IconContainer = styled.button<StyledHTMLElement, Required<Theme>>(
  ({ theme, inverted }) => {
    const invertedStyles = {
      backgroundColor: '#F5F5F5',

      '&:hover': {
        backgroundColor: '#F0F0F0'
      },

      '&:active': {
        backgroundColor: '#D8D8D8'
      },

      '>svg': {
        fill: 'black',
        '>path': {
          fill: 'black'
        }
      }
    }

    const defaultStyles = {
      backgroundColor: theme.colors['primary'],

      '&:hover': {
        opacity: 0.9
      },

      '&:active': {
        opacity: 0.8
      }
    }

    return {
      height: '32px',
      width: '32px',
      border: 'none',
      borderRadius: '12px',
      cursor: 'pointer',
      padding: '7px',
      WebkitTapHighlightColor: 'transparent',

      ...(inverted ? invertedStyles : defaultStyles)
    }
  }
)

export default AccountMenu
