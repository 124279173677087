import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
  FC,
  RefObject
} from 'react'
import styled from '@emotion/styled'
import { IoIosSearch } from 'react-icons/io'
import { FaChevronLeft } from 'react-icons/fa'
import Logo from './Logo'
import CartActions from './CartActions'
import { SearchBar } from '../Product'
import { NavDetails, useConsumerCart, useNavBar } from 'shop/hooks'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { Order } from 'shop/types/cart'
import { getStoreUrl } from 'shop/utils'
import { fadeIn } from '../Shop/commonStyles'
import { PAGE_CHECKOUT, PAGE_SHOP, PageType } from './utils'
import { getFulfillmentFlags } from '../Checkout'
import { FulfillmentType } from 'shop/types'
import React from 'react'

interface Props {
  transparent?: boolean
  page?: PageType
  order?: Order
  storeSlug?: string
}

interface LogoSectionProps {
  fulfillmentType: FulfillmentType | undefined
  page: PageType | undefined
  isShopSearchVisible: boolean
  logoUrl: string
  slug: string | undefined
  storeRef: MutableRefObject<HTMLParagraphElement | null>
  navDetails: NavDetails
  handleBlur: () => void
}

interface SearchProps {
  page: PageType | undefined
  isShopSearchVisible: boolean
  searchBarRef: RefObject<HTMLInputElement>
  handleSearchIconClick: () => void
  navDetails: NavDetails
}

/** Consumer Cart equivalent of the original navbar */
const NavBarV2 = ({ transparent = false, page, order, storeSlug }: Props) => {
  const { cart } = useConsumerCart()
  const { logoUrl, navDetails } = useNavBar()
  const [isShopSearchVisible, setIsShopSearchVisible] = useState(false)
  const nav = useRef<HTMLElement | null>(null)
  const store = useRef<HTMLParagraphElement | null>(null)
  const searchBarRef = useRef<HTMLInputElement | null>(null)

  const orderStoreSlug = order && order.store.slug
  const slug = storeSlug || orderStoreSlug

  const fulfillmentType = cart?.fulfillment.type || order?.fulfillment.type

  useEffect(() => {
    if (transparent) window.onscroll = scrollHandler
  }, [transparent])

  const handleResize = useCallback(() => {
    if (isShopSearchVisible && window.innerWidth > 768) {
      setIsShopSearchVisible(false)
    }
  }, [isShopSearchVisible])

  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleBlur()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    window.addEventListener('keypress', handleKeyPress)

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('keypress', handleKeyPress)
    }
  }, [handleResize, handleKeyPress])

  const scrollHandler = () => {
    if (nav && nav.current && store && store.current) {
      if (document.documentElement.scrollTop > 10) {
        nav.current.classList.remove('-transparent')
        store.current.classList.add('-visible')
      } else {
        nav.current.classList.add('-transparent')
        store.current.classList.remove('-visible')
      }
    }
  }

  const handleSearchIconClick = () => {
    setIsShopSearchVisible(true)

    const inputHelper = document.createElement('input')
    inputHelper.style.position = 'absolute'
    inputHelper.style.opacity = '0'

    nav.current?.appendChild(inputHelper)
    inputHelper.focus()

    setTimeout(() => {
      searchBarRef.current?.focus()
      nav.current?.removeChild(inputHelper)
    }, 100)
  }

  const handleBlur = () => {
    setIsShopSearchVisible(false)
    searchBarRef.current?.blur()
  }

  return (
    <Nav
      ref={nav}
      className={transparent ? '-transparent' : ''}
      data-testid='nav-bar-component'
    >
      <NavActionContainer>
        <LogoSection
          page={page}
          logoUrl={logoUrl}
          storeRef={store}
          handleBlur={handleBlur}
          isShopSearchVisible={isShopSearchVisible}
          fulfillmentType={fulfillmentType}
          navDetails={navDetails}
          slug={slug}
        />
        <SearchBarSection
          page={page}
          isShopSearchVisible={isShopSearchVisible}
          searchBarRef={searchBarRef}
          handleSearchIconClick={handleSearchIconClick}
          navDetails={navDetails}
        />
      </NavActionContainer>
    </Nav>
  )
}

export default NavBarV2

const LogoSection: FC<LogoSectionProps> = React.memo(
  ({
    fulfillmentType,
    page,
    isShopSearchVisible,
    logoUrl,
    slug,
    storeRef,
    navDetails,
    handleBlur
  }) => {
    const { isOrderAtTable } = getFulfillmentFlags(fulfillmentType)
    const navigateOnLogoClick = slug ? getStoreUrl(slug) : '/'
    const tableLandingPath = `/table/${slug}`

    const isCheckoutPage = page === PAGE_CHECKOUT
    const isShopPage = page === PAGE_SHOP

    return (
      <Flexbox isCheckout={isCheckoutPage}>
        <FadeInWrapper hidden={isShopSearchVisible}>
          <Logo
            imageUrl={logoUrl}
            url={
              isOrderAtTable
                ? tableLandingPath
                : slug
                  ? navigateOnLogoClick
                  : '/'
            }
          />
        </FadeInWrapper>

        {isCheckoutPage && (
          <Store ref={storeRef}>Ordering from {navDetails.store}</Store>
        )}

        {isShopPage && (
          <FadeInWrapper hidden={!isShopSearchVisible}>
            <FaChevronLeft onClick={handleBlur} />
          </FadeInWrapper>
        )}
      </Flexbox>
    )
  }
)

const SearchBarSection: FC<SearchProps> = ({
  page,
  isShopSearchVisible,
  searchBarRef,
  handleSearchIconClick,
  navDetails
}) => {
  const isShopPage = page === PAGE_SHOP
  return isShopPage ? (
    <>
      <Flexbox justify='flex-end'>
        <SearchBarWrapper isActive={isShopSearchVisible}>
          <SearchBar ref={searchBarRef} />
        </SearchBarWrapper>
      </Flexbox>
      <Flexbox justify='flex-end'>
        <FadeInWrapper hidden={isShopSearchVisible}>
          <IconContainer onClick={handleSearchIconClick}>
            <IoIosSearch color='#000' />
          </IconContainer>
        </FadeInWrapper>
        <FadeInWrapper hidden={isShopSearchVisible}>
          <CartActions {...navDetails} />
        </FadeInWrapper>
      </Flexbox>
    </>
  ) : (
    <Flexbox justify='flex-end'></Flexbox>
  )
}

const FadeInWrapper = styled.div<
  StyledHTMLElement & { hidden: boolean; minWidth?: string }
>(({ hidden }) => ({
  display: hidden ? 'none' : 'flex',
  animation: `${fadeIn} 0.5s ease-in-out forwards`,
  height: '100%',
  alignItems: 'center'
}))

const IconContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    backgroundColor: '#fff',
    borderRadius: '12px',
    width: '32px',
    height: '32px',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '12px',

    '>svg': {
      width: '20px',
      height: '20px'
    },

    [theme.mediaQueries.viewport7]: {
      display: 'none'
    }
  })
)

const SearchBarWrapper = styled.div<
  StyledHTMLElement & { isActive: boolean },
  Required<Theme>
>(({ theme, isActive }) => ({
  width: '100%',
  margin: '0 auto',
  marginLeft: '12px',
  display: isActive ? 'block' : 'none',
  animation: `${fadeIn} 0.5s ease-in-out forwards`,

  [theme.mediaQueries.viewport7]: {
    animation: 'none',
    display: 'block',
    maxWidth: '560px',
    margin: '0 auto'
  }
}))

const Flexbox = styled.div<
  StyledHTMLElement & {
    justify?: 'space-between' | 'flex-end'
    isCheckout?: boolean
  },
  Required<Theme>
>(({ theme, justify = 'space-between', isCheckout = false }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: isCheckout ? 'center' : justify,
  transition: 'all 100ms ease-in-out',
  width: '100%',

  [theme.mediaQueries.viewport7]: {
    display: 'flex',
    justifyContent: justify
  }
}))

const NavActionContainer = styled.div(({ theme }: any) => ({
  display: 'grid',
  gridTemplateColumns: 'min-content 1fr',
  transition: 'all 100ms ease-in-out',
  fontWeight: theme.fonts['body'].weight,
  fontFamily: theme.fonts['body'].family,
  height: '32px',
  margin: '0 auto',
  maxWidth: '1920px',
  width: '100%',
  padding: '0',
  [theme.mediaQueries.viewport7]: {
    gridTemplateColumns: '1fr 2fr 1fr',
    columnGap: '12px',
    maxWidth: '1300px'
  },
  [theme.mediaQueries.viewport14]: {
    maxWidth: '1920px',
    columnGap: 'none'
  }
}))

export const Nav = styled.nav<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    fontWeight: theme.fonts['body'].weight,
    fontFamily: theme.fonts['body'].family,
    transition: theme['defaults'].transitions.background,
    padding: '8px 16px',
    height: '56px',
    color: '#fff',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: theme.designMode ? 'unset' : 'sticky',
    right: 0,
    left: 0,
    top: 0,
    backgroundColor: theme.colors['primary'],
    [theme.mediaQueries.viewport4]: {
      height: '64px',
      padding: '8px 40px'
    },
    [theme.mediaQueries.viewport7]: {
      padding: '8px 75px'
    },
    [theme.mediaQueries.viewport16]: {
      padding: '0px 75px'
    },
    '&.-transparent': {
      backgroundColor:
        theme.components['banner'].height === 'none' ? 'auto' : 'transparent',
      position: theme.designMode ? 'unset' : 'fixed',
      [theme.mediaQueries.viewport7]: {
        position: theme.designMode ? 'unset' : 'sticky',
        backgroundColor: theme.colors['primary']
      }
    },
    zIndex: 20
  })
)

export const Store = styled.p<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    fontSize: theme.fontSizes[0] as any,
    textAlign: 'center',
    opacity: theme.components['banner'].height !== 'none' ? 0 : 1,
    transition: 'all 500ms ease-out',
    '&.-visible': {
      transition: 'all 300ms ease-out',
      opacity: 1
    },
    whiteSpace: 'nowrap',
    display: 'none',
    [theme.mediaQueries.viewport12]: {
      display: 'block',
      position: 'relative',
      flexGrow: 1,
      opacity: 1,
      margin: '0 0 0 16px',
      textAlign: 'left'
    }
  })
)
