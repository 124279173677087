import React, { useState } from 'react'

type Message = {
  timeout: number
  content: string
  type?: 'warning' | 'error' | 'info' | 'success'
}

type Hook = () => {
  inbox: Message[]
  pushMessage: (message: Message) => void
}

type ContextState = {
  inbox: Message[]
  pushMessage: (message: Message) => void
}

const initialState: ContextState = {
  inbox: [],
  pushMessage: () => {}
}

export const FlashContext = React.createContext<ContextState>(initialState)

export const useFlash: Hook = () => {
  const [inbox, setInbox] = useState<Message[]>([])

  const popMessage = () => {
    setInbox(inbox.slice(1))
  }

  const pushMessage = (message: Message) => {
    setInbox([...inbox, message])
    setTimeout(popMessage, message.timeout)
  }

  return {
    inbox,
    pushMessage
  }
}
