import styled from '@emotion/styled'
import { StyledHTMLElement } from 'shop/theme/types'
import { BasePrice, Price as PriceType } from 'shop/types/cart'

type Orientation = 'vertical' | 'horizontal'
interface Props {
  price: PriceType | BasePrice
  type?: Orientation
}

const Price = ({ price, type = 'vertical' }: Props): JSX.Element => {
  const { base: basePrice, discounted: discountedPrice = null } =
    price as PriceType

  /** Check if basePrice and discountedPrice are equal in case of subtotal discountedPrice is provided
   * when a delivery discount is applied */
  const basePriceDiscountEqCheck = basePrice === discountedPrice
  const showDiscount =
    !!(discountedPrice && basePrice) && !basePriceDiscountEqCheck

  return (
    <Content data-testid='item-price' showDiscount={showDiscount} type={type}>
      <Amount type={type} showDiscount={showDiscount}>
        {'£' + basePrice}
      </Amount>
      {showDiscount && (
        <Amount type={type} showDiscount={showDiscount} discountedPrice={true}>
          {'£' + discountedPrice}
        </Amount>
      )}
    </Content>
  )
}

const Content = styled.div<
  StyledHTMLElement & { type: Orientation; showDiscount: boolean }
>(({ type, showDiscount }) => ({
  justifySelf: 'end',
  display: 'flex',
  flexDirection: type === 'horizontal' ? 'row' : 'column-reverse',
  gap: type === 'horizontal' && showDiscount ? '12px' : '2px',
  alignItems: 'flex-end',
  position: 'relative'
}))

const Amount = styled.p<
  StyledHTMLElement & {
    discountedPrice?: boolean
    showDiscount: boolean
    type: Orientation
  }
>(({ discountedPrice, showDiscount, type }) => ({
  fontSize: '16px',
  color: discountedPrice ? '#389E0D' : showDiscount ? '#59595A' : '#2A2A2A',
  margin: 0,
  ...(!discountedPrice && showDiscount
    ? {
        textDecoration: 'line-through',
        fontWeight: 400
      }
    : {
        textDecoration: 'none',
        fontWeight: 'inherit'
      }),
  ...(!discountedPrice && showDiscount && type === 'vertical'
    ? {
        position: 'absolute',
        top: '18px'
      }
    : {
        position: 'static',
        top: 'auto'
      })
}))

export default Price
