import styled from '@emotion/styled'
import useClipboard from 'react-use-clipboard'
import { Button as StyledButton } from '../Controls'
import { CgCheckO as Check } from 'react-icons/cg'

const CopyLinkButton = ({ link }: { link: string }) => {
  const [isCopied, setCopied] = useClipboard(link, { successDuration: 1500 })

  return (
    <>
      <Button
        variant='secondary'
        size='md'
        onClick={setCopied}
        state={isCopied ? 'success' : null}
        uppercase
      >
        {!isCopied ? (
          'Copy link'
        ) : (
          <>
            <Check /> Copied
          </>
        )}
      </Button>
    </>
  )
}

const Button = styled(StyledButton)(({ theme }: any) => ({
  '> svg': { marginRight: '8px' },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 4px'
}))

export default CopyLinkButton
