import { useEffect, useState } from 'react'
import {
  buildCartDateV2,
  getStoreName,
  getStoreAddressOrName
} from 'shop/components/NavBar/utils'
import { formatFulfillmentWindow, getLogoURL } from 'shop/utils/common'
import { useAppContent, useShop } from '../useGlobalContext'
import { useConsumerCart } from '../useConsumerCart'

export interface NavDetails {
  logo: string
  store: string
  date: string
  time: string
  address: string
  merchantId: string
}

export const useNavBar = () => {
  const { logoImage } = useAppContent()
  const { merchant, config } = useShop()
  const { cart, cartLoading } = useConsumerCart()

  const logoUrl = logoImage || getLogoURL(config.domain, config.assetHost)

  const [navDetails, setNavDetails] = useState({
    logo: logoUrl,
    store: '...',
    date: '...',
    time: '...',
    address: '...',
    merchantId: merchant ? merchant.id : ''
  })

  /** Fulfillment Line created for non order confirmation page navbars */
  const createFulfillmentLine = () => {
    if (!cart) return '...'
    const orderType = cart?.fulfillment.orderType
    const window = cart?.fulfillment.window
    return orderType === 'ASAP' ? 'ASAP' : formatFulfillmentWindow(window)
  }

  const getNavDetails = () => ({
    logo: logoUrl,
    store: getStoreName(cart),
    date: buildCartDateV2(cart?.fulfillment.window.from),
    time: createFulfillmentLine(),
    address: getStoreAddressOrName(cart),
    merchantId: merchant?.id || ''
  })

  useEffect(() => {
    if (cartLoading || !cart) return
    setNavDetails(getNavDetails())
  }, [cart, cartLoading])

  return {
    navDetails,
    logoUrl
  }
}
