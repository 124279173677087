import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'

interface Props {
  type?: string
  value?: string
  icon: React.ReactNode
  name: string
  inputProps: any
  hasValue: boolean
}

const InputFieldNew = ({
  type,
  value,
  icon,
  name,
  inputProps,
  hasValue
}: Props) => {
  return (
    <Container className={icon ? '-hasIcon' : ''} {...{ hasValue }}>
      {icon && <IconContainer hasValue={hasValue}>{icon}</IconContainer>}
      <Input
        type={type}
        value={value}
        className={icon ? '-hasIcon' : ''}
        data-testid={`${name || ''}Field`}
        onBeforeInput={inputProps && inputProps.onFocus}
        {...inputProps}
      />
    </Container>
  )
}

const Input = styled.input<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  outline: 0,
  border: 0,
  padding: 0,
  flexGrow: 1,
  fontSize: theme.fontSizes[2],
  fontWeight: theme.fontWeights['light'],
  backgroundColor: '#F9F9F9',
  width: '100%',
  '&.-hasIcon': {
    paddingLeft: '3px'
  }
}))

const Container = styled.div<
  StyledHTMLElement & { hasValue: boolean },
  Required<Theme>
>(({ theme, hasValue }) => ({
  display: 'flex',
  outline: 0,
  borderRadius: '12px',
  border: `1px solid ${theme.colors['lineColor']}`,
  padding: '7px 20px 7px 8px',
  alignItems: 'center',
  backgroundColor: '#F9F9F9',
  lineHeight: '30px',
  '&:focus-within': {
    border: `1px solid #096DD9`,
    '& svg': {
      '& path': {
        color: 'black'
      }
    }
  },
  '&:active': {
    border: `1px solid #096DD9`
  }
}))

const IconContainer = styled.div<StyledHTMLElement & { hasValue: boolean }>(
  ({ hasValue }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '30px',
    aspectRatio: '1/1',
    paddingBottom: '3px',

    '& svg': {
      height: '16px',
      width: '16px',
      '& path': {
        color: hasValue ? 'black' : '#757575'
      }
    }
  })
)

export default InputFieldNew
