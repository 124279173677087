import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Button } from '../Controls'
import { useForm, UseFormMethods } from 'react-hook-form'
import { SplitBillPayor } from 'shop/components/Checkout/types'
import { Cart } from 'shop/components/Landing/types'
import Spinner from 'shop/components/Loader/Spinner'
import { StyledHeading } from 'shop/components'
import { initSplitBill } from 'shop/components/Checkout/Network'
import { useShopClient } from 'shop/client'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import Decimal from 'decimal.js'
import { useHistory } from 'react-router-dom'
import { rebalanceAmount, totalSplitAmount } from '../SplitCheckout/utils'
import { formatMoney } from '../Cart/utils'
import { ukNumberRegex } from '../Checkout/utils'
import { BiMinus, BiPlus, BiTrash } from 'react-icons/bi'
import Draggable from 'react-draggable'
import { IoAddCircleOutline } from 'react-icons/io5'

interface SplitPayorInputGroupProps {
  cart: Cart
  total: number
}

const SplitPayorInputGroup = ({ cart, total }: SplitPayorInputGroupProps) => {
  const client = useShopClient()
  const cartId = cart.id
  const [isRequesting, setIsRequesting] = useState(false)
  const history = useHistory()
  const [splitCounter, setSplitCounter] = useState(0)
  const [showForm, setShowForm] = useState(true)

  const [payors, setPayors] = useState<SplitBillPayor[]>([
    {
      name: cart.customer_details?.first_name as string,
      email: cart.customer_details?.email as string,
      contactNum: cart.customer_details?.contact_num as string,
      amount: new Decimal(total).toDecimalPlaces(2).toNumber()
    }
  ])

  const {
    register,
    getValues,
    reset,
    errors,
    formState,
    handleSubmit
  }: UseFormMethods<SplitBillPayor> = useForm({
    mode: 'onBlur'
  })

  const addPayor = () => {
    setPayors((prev) => {
      prev.push({ ...getValues() })
      return splitTheBill(prev)
    })

    reset({
      name: undefined,
      email: undefined,
      contactNum: undefined,
      amount: 0
    })

    setShowForm(false)
  }

  const submit = () => {
    setIsRequesting(true)
    initSplitBill(client, {
      cartId,
      payors
    }).then((data) => {
      history.push('/split/payment')
    })
  }

  const [valid, setValid] = useState(false)

  useEffect(() => {
    const split = new Decimal(totalSplitAmount(payors).toPrecision(2))
    const totalAsDecimal = new Decimal(total.toPrecision(2))
    setValid(split.equals(totalAsDecimal))
    if (payors.length === 1) setShowForm(true)
  }, [payors.length, formState.isDirty, splitCounter])

  /* 
    Computation as follows  
    Dividing the split isn't as easy as dividing them by their length
    therefore any difference should be added to the first entry 
  */
  const getShare = (_payors: SplitBillPayor[], index: number) => {
    Decimal.set({ rounding: Decimal.ROUND_DOWN })

    const totalAsDecimal = new Decimal(total)

    // Equally split based on the length
    const split = totalAsDecimal.dividedBy(payors.length)
    // const splitTotal = split.times(payors.length)

    // Multiply back without rounding
    const splitTotal = split.toDecimalPlaces(2).toNumber() * _payors.length

    // Get the difference from the total
    const difference = totalAsDecimal.minus(splitTotal)

    if (index === 0) {
      // Add the difference to the first entry
      return split.add(difference).toDecimalPlaces(2).toNumber()
    } else {
      return split.toDecimalPlaces(2).toNumber()
    }
  }

  const splitTheBill = (_payors: SplitBillPayor[]) => {
    return payors.map((payor, index) => ({
      ...payor,
      amount: getShare(_payors, index)
    }))
  }

  const adjustAmount = (value: number, index: number) => {
    const { rebalancedPayors, updated } = rebalanceAmount(
      total,
      payors,
      index,
      Number(value)
    )
    setPayors(rebalancedPayors)
    return updated
  }

  const PayorInputGroup = ({
    noBorder,
    payor,
    index
  }: {
    noBorder: boolean
    payor: SplitBillPayor
    index: number
  }) => {
    const isMainPayor = index === 0
    return (
      <Payor key={`${payor.name}-${index}`} noBorder={noBorder}>
        <PayorLabel>
          <strong>{isMainPayor ? 'You' : payor.name}</strong>
          <p>{!isMainPayor && payor.contactNum}</p>
        </PayorLabel>
        <ButtonModifier
          className='ignore-drag'
          variant='secondary'
          disabled={isMainPayor}
          onClick={() => {
            adjustAmount(payor.amount - 0.25, index)
            setSplitCounter(new Date().getUTCMilliseconds())
          }}
        >
          <BiMinus />
        </ButtonModifier>
        <PayorInputAmount
          className='ignore-drag'
          type='number'
          step='0.01'
          defaultValue={payor.amount}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
            adjustAmount(Number(e.target.value), index)
            setSplitCounter(new Date().getUTCMilliseconds())
          }}
          disabled={index === 0}
        />
        <ButtonModifier
          className='ignore-drag'
          variant='secondary'
          disabled={isMainPayor}
          onClick={() => {
            adjustAmount(payor.amount + 0.25, index)
            setSplitCounter(new Date().getUTCMilliseconds())
          }}
        >
          <BiPlus />
        </ButtonModifier>
      </Payor>
    )
  }

  return (
    <>
      <FormWrapper>
        <PayorsContainer>
          {payors.map((payor, index) => (
            <PayorItem key={`payor-${index}`}>
              <DeleteButton
                onClick={() => {
                  const newPayors = payors.filter(
                    (_payor, payorIndex) => payorIndex !== index
                  )
                  const removePayor = () =>
                    setPayors(
                      newPayors.map((payor, index) => ({
                        ...payor,
                        amount: getShare(newPayors, index)
                      }))
                    )

                  document.onclick = function () {}
                  setTimeout(removePayor, 100)
                }}
              >
                <BiTrash />
                Delete
              </DeleteButton>
              <Draggable
                cancel='.ignore-drag'
                disabled={index === 0}
                axis={'x'}
                defaultPosition={{ x: 0, y: 0 }}
                bounds={{ left: -124, right: 0 }}
                defaultClassNameDragging={'-dragging'}
                onStop={(e, data) => {
                  const resetPosition = () => {
                    if (data.node) data.node.style.transform = 'none'
                  }
                  if (data.lastX > -124) return resetPosition()
                }}
              >
                <PayorInputGroupContainer>
                  <PayorInputGroup
                    payor={payor}
                    index={index}
                    noBorder={!showForm && index === payors.length - 1}
                  />
                </PayorInputGroupContainer>
              </Draggable>
            </PayorItem>
          ))}
          <small>
            {!valid && (
              <Error>
                Your total split amount ({formatMoney(totalSplitAmount(payors))}
                ) is greater than your cart total. Please adjust the amount.
              </Error>
            )}
          </small>
        </PayorsContainer>
        {showForm && (
          <AddContainer>
            <MainHeading as='h5'>Add another person</MainHeading>
            <form onSubmit={handleSubmit(addPayor)}>
              <InputGroup>
                <InputWrapper>
                  <Input
                    name='name'
                    placeholder='Name'
                    ref={register({
                      required: 'Please enter name',
                      validate: (value: string) => {
                        if (value.trim().length > 0) return true
                        return 'Please enter name'
                      }
                    })}
                    error={errors.name && 'Please enter name'}
                    hasError={errors?.name && true}
                  />
                  {errors.name && (
                    <InputError>{errors.name.message}</InputError>
                  )}
                </InputWrapper>
                <InputWrapper>
                  <Input
                    name='contactNum'
                    placeholder='Phone number'
                    type='tel'
                    ref={register({
                      required: 'Please enter contact number',
                      pattern: {
                        value: ukNumberRegex,
                        message: 'Not a valid UK number'
                      }
                    })}
                    error={
                      errors.contactNum && 'Please enter a valid UK number'
                    }
                    hasError={errors?.contactNum && true}
                  />

                  {errors.contactNum && (
                    <InputError>{errors.contactNum.message}</InputError>
                  )}
                </InputWrapper>
              </InputGroup>
              <Button type='submit' variant='primary'>
                Add
              </Button>
            </form>
          </AddContainer>
        )}
      </FormWrapper>
      {!showForm && (
        <AddButton
          type='button'
          variant='link'
          size='md'
          onClick={() => setShowForm(true)}
        >
          <IoAddCircleOutline size='18px' />
          Add another person
        </AddButton>
      )}
      <Action>
        <Button
          type='button'
          variant='secondary'
          onClick={() => (window.location.href = '/order/checkout')}
        >
          Cancel Split
        </Button>
        {payors.length > 1 && (
          <Button
            type='submit'
            variant='primary'
            disabled={!!!valid}
            onClick={submit}
          >
            {isRequesting && <Spinner />}
            Request Payment
          </Button>
        )}
      </Action>
    </>
  )
}

const PayorInputGroupContainer = styled.div(({ theme }: any) => ({
  backgroundColor: 'white',
  padding: '0 16px',
  [theme.mediaQueries.viewport4]: {
    padding: '0 24px'
  }
}))

const PayorItem = styled.div(() => ({
  '> .-dragging': {
    borderRadius: '4px',
    boxShadow: '0px 5.5px 5px -3px rgba(14, 14, 44, 0.2)',
    '> div': { borderBottomColor: 'transparent' }
  },
  position: 'relative'
}))

const InputGroup = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    width: '100%',
    display: 'grid',
    gridGap: '8px',
    margin: '8px 0 16px',
    gridTemplateColumns: '1fr',
    [theme.mediaQueries.viewport5]: {
      gridTemplateColumns: '1fr 1fr'
    }
  })
)

const PayorsContainer = styled.div(({ theme }: any) => ({
  width: '100%'
}))

const Error = styled.div(({ theme }: any) => ({
  padding: '16px 24px 0',
  color: 'red'
}))

const Payor = styled.div(({ noBorder }: { noBorder?: boolean }) => ({
  cursor: 'grab',
  backgroundColor: 'white',
  position: 'relative',
  zIndex: 10,
  borderBottom: noBorder ? 'transparent' : '1px solid rgba(0, 0, 0, 0.1)',
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  padding: '16px 0'
}))

const Action = styled.div<StyledHTMLElement, Required<Theme>>(() => ({
  '> button': { padding: 0 },
  margin: '124px 0 0',
  gap: '8px',
  display: 'flex',
  justifyContent: 'space-between'
}))

const MainHeading = styled(StyledHeading)(({ theme }: any) => ({
  fontWeight: 'normal',
  padding: '8px 0px',
  margin: '24px 0 0'
}))

const AddContainer = styled.div(({ theme }: any) => ({
  padding: '0 16px 16px',
  [theme.mediaQueries.viewport4]: {
    padding: '0 24px 24px'
  }
}))

const Input = styled.input(({ hasError, theme }: any) => ({
  borderRadius: '4px',
  boxShadow: 'inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)',
  border: '1px solid #e7e5e9',
  background: '#fafafb',
  height: '44px',
  fontSize: theme.fontSizes[0],
  [theme.mediaQueries.viewport4]: {
    fontSize: theme.fontSizes[1]
  },
  padding: '8px',
  width: '100%',
  borderColor: hasError && (theme.colors.state.error[5] || '#cc0000')
}))

const PayorInputAmount = styled.input(({ theme }: any) => ({
  width: '36px',
  height: '24px',
  backgroundColor: 'white',
  border: 0,
  textAlign: 'center',
  margin: '0 4px',
  padding: 0,
  fontSize: theme.fontSizes[0],
  [theme.mediaQueries.viewport4]: {
    width: '48px',
    height: '36px',
    fontSize: theme.fontSizes[1]
  },
  '&:focus': { outline: 0 },
  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none'
  },
  '-moz-appearance': 'textfield'
}))

const InputWrapper = styled.div(({ theme }: any) => ({
  fontSize: theme.fontSizes[1]
}))
const InputError = styled.div(({ theme }: any) => ({
  fontSize: theme.fontSizes[0],
  color: theme.colors.state.error[5]
}))

const AddButton = styled(Button)(() => ({
  '> svg': { marginRight: '8px' },
  fontSize: '14px',
  display: 'inline-flex',
  alignItems: 'center',
  width: 'auto',
  marginTop: '12px',
  padding: '24px 0'
}))

const ButtonModifier = styled(Button)(({ theme }: any) => ({
  padding: 0,
  flex: '0 0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  lineHeight: '1em',
  height: '28px',
  width: '28px',
  '> svg': { fontSize: theme.fontSizes[1], marginRight: '0' },
  [theme.mediaQueries.viewport4]: {
    width: '36px',
    height: '36px',
    '> svg': { fontSize: theme.fontSizes[2], marginRight: '0' }
  }
}))

const PayorLabel = styled.div(({ theme }: any) => ({
  wordBreak: 'break-all',
  margin: 0,
  paddingRight: '8px',
  '> p': { margin: 0 },
  fontSize: theme.fontSizes[0],
  [theme.mediaQueries.viewport4]: {
    fontSize: theme.fontSizes[1]
  },
  marginRight: 'auto'
}))

const FormWrapper = styled.div(() => ({
  padding: '8px 0',
  background: 'white',
  borderRadius: '4px',
  boxShadow: '0px 1px 2px rgba(74, 74, 104, 0.1)'
}))

const DeleteButton = styled(Button)(({ theme }: any) => ({
  textTransform: 'lowercase',
  backgroundColor: '#A40000',
  position: 'absolute',
  borderRadius: 0,
  width: 'auto',
  height: '100%',
  fontSize: '12px',
  padding: 'inherit 40px',
  display: 'flex',
  alignItems: 'center',
  '> svg': {
    fontSize: '20px',
    marginRight: '8px'
  },
  right: 0,
  top: 0,
  bottom: 0,
  marginRight: '16px',
  [theme.mediaQueries.viewport4]: {
    marginRight: '24px'
  }
}))

export default SplitPayorInputGroup
