import styled from '@emotion/styled'
import { FiArchive as ArchiveIcon } from 'react-icons/fi'
import { ProductsContainer } from './commonStyles'

const Container = styled.div(({ theme }: any) => ({
  padding: '24px',
  height: '360px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  color: theme.colors.textMute,
  '> svg': {
    fontSize: '64px',
    marginBottom: '24px',
    strokeWidth: '1'
  },
  '> h1': {
    fontFamily: theme.fonts.heading.family,
    fontWeight: theme.fonts.heading.weight
  }
}))

export const EmptyState = () => {
  return (
    <ProductsContainer>
      <Container>
        <ArchiveIcon />
        <h2>There are no products yet</h2>
      </Container>
    </ProductsContainer>
  )
}
