import Dropdown from 'shop/components/Inputs/Dropdown'
import ModalWrapper from './ModalWrapper'
import {
  calendarIsEditable,
  createReadableTimeSlotRangeInput,
  dropdownIsEditable
} from '../utils'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import styled from '@emotion/styled'
import { useCallback } from 'react'
import { format, isToday, isTomorrow } from 'date-fns'
import { AvailableDates, TimeSlot } from '../types'
import { IoCalendarClearOutline as CalendarIcon } from 'react-icons/io5'
import { BsChevronRight as ForwardArrow } from 'react-icons/bs'
import SelectInput from 'shop/components/Inputs/SelectInput'
import SimpleLoader from 'shop/components/Loader/SimpleLoader'
import { CommonOverlayInputHeader } from './commonStyles'

interface DeliveryCalendarProps {
  fulfillmentDate: Date
  fulfillmentTime: string | null
  fulfillmentType: string
  isCalendarOpen: boolean
  calendarInputClickHandler: () => void
  availableDates: AvailableDates
  isOverlayOpen: boolean
  onClickOutsideDatepicker: () => void
  timeSlots: TimeSlot[]
  datepicker: () => JSX.Element
  isLoading: boolean
}

const DeliveryCalendar = ({
  fulfillmentDate,
  isCalendarOpen,
  calendarInputClickHandler,
  availableDates,
  fulfillmentType,
  isOverlayOpen,
  onClickOutsideDatepicker,
  timeSlots,
  fulfillmentTime,
  datepicker,
  isLoading
}: DeliveryCalendarProps) => {
  /** Creates human readable display value for date picker input */
  const createDatePickerDisplayValue = useCallback(() => {
    const timeslotIsSelectable: boolean = dropdownIsEditable(timeSlots)
    let calendarInputTextValue: string

    if (isToday(fulfillmentDate)) {
      calendarInputTextValue = 'Today'
    } else if (isTomorrow(fulfillmentDate)) {
      calendarInputTextValue = 'Tomorrow'
    } else {
      calendarInputTextValue = `${format(fulfillmentDate, 'EE d MMMM')}`
    }

    // If timeslot is not selectable, calendar input is full width & input text altered
    if (!timeslotIsSelectable) {
      const readableTimeslot = createReadableTimeSlotRangeInput(
        timeSlots,
        fulfillmentTime,
        true
      )
      if (readableTimeslot === 'ASAP') {
        return `${calendarInputTextValue} ${readableTimeslot}`
      }
      return `${calendarInputTextValue} from ${readableTimeslot}`
    } else {
      return calendarInputTextValue
    }
  }, [fulfillmentDate, timeSlots])

  const datepickerInput = (
    <SelectInput
      testId='change-calendar-date'
      onClick={() => null}
      ToSelectIcon={CalendarIcon}
      newFulfillmentModal={true}
      hasSelected={!!fulfillmentDate}
      textFallback={'Date'}
      textContent={createDatePickerDisplayValue()}
    />
  )

  return (
    <>
      <CalendarContainer>
        <SimpleLoader
          isLoading={isLoading}
          additionalStyles={{ borderRadius: '12px' }}
        >
          <Dropdown
            testId='change-calendar-date-dropdown'
            textContent={createDatePickerDisplayValue()}
            textFallback={'Date'}
            hasSelected={!!fulfillmentDate}
            IconLeft={<CalendarIcon />}
            IconRight={<ForwardArrow />}
            isOpen={isCalendarOpen}
            onClick={calendarInputClickHandler}
            canEdit={
              isCalendarOpen ||
              calendarIsEditable(availableDates, fulfillmentType)
            }
          >
            <ModalWrapper isOpen={isOverlayOpen}>
              <CommonOverlayInputHeader
                onBackClick={onClickOutsideDatepicker}
                children={datepickerInput}
              />
              {datepicker()}
            </ModalWrapper>
          </Dropdown>
        </SimpleLoader>
      </CalendarContainer>
    </>
  )
}

const CalendarContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  () => ({
    position: 'relative',
    height: '50px'
  })
)

export default DeliveryCalendar
