import styled from '@emotion/styled'
import { IconBaseProps } from 'react-icons'
import { IoCloseOutline as Close } from 'react-icons/io5'
import Theme, { StyledHTMLElement } from 'shop/theme/types'

export const Name = styled.p(() => ({
  color: '#2A2A2A',
  margin: 0
}))

export const ellipsis = {
  textOverflow: 'ellipsis',
  overflow: 'hidden'
}

export const ModifierOptionName = styled.span(() => ({
  color: '#8B8B8B'
}))

export const ModifierOptionGroupName = styled(Name)<
  StyledHTMLElement,
  Required<Theme>
>(({ theme }) => ({
  width: '100%',
  [theme.mediaQueries.viewport8]: {
    whiteSpace: 'nowrap'
  },
  ...ellipsis
}))

export const LineBreak = styled.hr<StyledHTMLElement & { height?: string }>(
  ({ height = '1px' }) => ({
    backgroundColor: '#EFEFF0',
    border: '0',
    height: height,
    margin: 0
  })
)

export const Title = styled.h1<StyledHTMLElement, Required<Theme>>(
  ({ theme }: any) => ({
    fontSize: '20px',
    fontFamily: theme.fonts.heading.family,
    [theme.mediaQueries.viewport6]: {
      fontSize: '24px'
    },
    fontWeight: 700
  })
)

export const Label = styled.p<
  StyledHTMLElement & { withEllipsis: boolean; fontWeight?: number }
>(({ withEllipsis, fontWeight = 400 }) => ({
  ...(withEllipsis && {
    ...ellipsis,
    whiteSpace: 'nowrap'
  }),
  fontWeight: fontWeight
}))

export const Clear = styled(Close)<IconBaseProps & { top: string }>(
  ({ top }) => ({
    right: '6px',
    position: 'absolute',
    width: '22px',
    height: '22px',
    top: top,
    cursor: 'pointer'
  })
)
