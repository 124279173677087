import { Fragment } from 'react'
import { isValidBirthdate } from './utils'
import FormInput from '../Inputs/FormInput'
import styled from '@emotion/styled'
import { UseFormMethods } from 'react-hook-form'

interface Props {
  disableDateFields: boolean
  updateCount: number
  formHandler: UseFormMethods
}

const CustomerBirthday = ({
  disableDateFields,
  formHandler,
  updateCount
}: Props) => {
  const { register, watch, errors, getValues, setError, clearErrors, trigger } =
    formHandler

  const handleBlur = async () => {
    const { day, month, year } = getValues()

    if (!day && !month && !year) {
      clearErrors(['day', 'month', 'year'])
      return
    }

    if (!day || !month || !year) {
      return
    }

    const isValid = isValidBirthdate(`${year}-${month}-${day}`)

    if (isValid) {
      clearErrors(['day', 'month', 'year'])
      trigger(['day', 'month', 'year'])
    } else {
      const message = 'Please enter a valid date'

      setError('day', { type: 'custom', message })
      setError('month', { type: 'custom', message })
      setError('year', { type: 'custom', message })
    }
  }

  return (
    <Fragment>
      <Label>Birthdate (optional)</Label>
      <Row>
        <RowField width={'25%'}>
          <FormInput
            id='customerBirthDay'
            labelText='Day'
            name='day'
            showError={errors.day}
            placeholder='DD'
            hidePlaceholder={true}
            maxLength={2}
            inputMode='numeric'
            type='text'
            disabled={disableDateFields}
            value={watch('day')}
            onBlur={handleBlur}
            formRef={register({
              pattern: { value: /^[0-9]*$/, message: 'Invalid day' },
              min: { value: 1, message: 'Invalid day' },
              max: { value: 31, message: 'Invalid day' }
            })}
          />
        </RowField>
        <RowDivider />
        <RowField width={'25%'}>
          <FormInput
            id='customerBirthMonth'
            value={watch('month')}
            labelText='Month'
            name='month'
            showError={errors.month}
            placeholder='MM'
            hidePlaceholder={true}
            maxLength={2}
            inputMode='numeric'
            type='text'
            disabled={disableDateFields}
            onBlur={handleBlur}
            formRef={register({
              pattern: { value: /^[0-9]*$/, message: 'Invalid month' },
              min: { value: 1, message: 'Invalid month' },
              max: { value: 12, message: 'Invalid month' }
            })}
          />
        </RowField>
        <RowDivider />
        <RowField>
          <FormInput
            id='customerBirthYear'
            value={watch('year')}
            labelText='Year'
            name='year'
            showError={errors.year}
            placeholder='YYYY'
            hidePlaceholder={true}
            maxLength={4}
            inputMode='numeric'
            type='text'
            disabled={disableDateFields}
            onBlur={handleBlur}
            formRef={register({
              pattern: { value: /^[0-9]*$/, message: 'Invalid year' },
              min: { value: 1930, message: 'Invalid year' },
              max: { value: new Date().getFullYear(), message: 'Invalid year' }
            })}
          />
        </RowField>
      </Row>
      <ReasonMessage>
        So that we can send you exclusive birthday offers.
      </ReasonMessage>
      {updateCount > 1 && (
        <InfoMessage>{`You have changed your birthday ${updateCount} times. (Max. 3 times)`}</InfoMessage>
      )}
    </Fragment>
  )
}

const RowField = styled.div((props: any) => ({
  width: props.width ? props.width : '50%'
}))

const Row = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  margin: '16px 0'
}))

const RowDivider = styled.div(() => ({
  margin: '0 8px'
}))

const Label = styled.label((props: any) => ({
  marginBottom: '4px',
  display: 'block',
  fontSize: props.theme.fontSizes[1],
  color: props.theme.colors.textBody
}))

const InfoMessage = styled.p(({ theme }: any) => ({
  marginTop: '0',
  fontSize: theme.fontSizes[1],
  fontWeight: theme.fontWeights.normal
}))

const ReasonMessage = styled.p(({ theme }: any) => ({
  margin: '0 0 8px',
  transform: 'translateY(-8px)',
  fontSize: theme.fontSizes[0],
  fontWeight: theme.fontWeights.normal,
  color: theme.colors.textMute
}))

export default CustomerBirthday
