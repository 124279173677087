import React, { useContext, useEffect } from 'react'
import styled from '@emotion/styled'
import FulfillmentOptions from './FulfillmentOptions'
import { useShop, useReactRouter, useModal, useConsumerCart } from 'shop/hooks'
import { LandingContext } from 'shop/components/Landing'
import { SimpleFormat } from 'shop/utils'
import { Merchant, ValidStore } from 'shop/components/Landing/types'
import { isASAPTimeSlotValid } from 'shop/components/Landing/utils'
import { Button } from 'shop/components'
import { LogoContainer } from '../Landing/LandingForm'
import AccountMenu from '../Landing/AccountMenu'

const DineInForm = () => {
  const { config, cartSession } = useShop()
  const { merchant, currentStore, stores } = useContext(LandingContext)
  const isLoggedIn = localStorage.getItem('customerId') !== null
  const { clearConsumerCart } = useConsumerCart()

  // resets cart
  useEffect(() => {
    cartSession.setCart(null)
    clearConsumerCart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // if (!merchant) return <div />
  const logo = `https://${config.domain}.${config.assetHost}/assets/logo`
  const openStores =
    stores &&
    stores.filter((store) => {
      return store.is_open && isASAPTimeSlotValid('pickup', store)
    })

  const finishedLoading = merchant && currentStore
  const fullyLoadedAndOpen = finishedLoading && openStores.length > 0
  const fullyLoadedButClosed = finishedLoading && openStores.length === 0

  return (
    <Container>
      <LogoContainer>
        <Logo
          src={logo}
          data-testid={currentStore && `dine-in-logo-${currentStore.slug}`}
        />
        {isLoggedIn && <AccountMenu />}
      </LogoContainer>
      {fullyLoadedAndOpen &&
        (currentStore?.settings?.table_enabled ? (
          <FormContent {...{ currentStore, merchant }} />
        ) : (
          <>
            <ClosedStoresMessage>
              Sorry but this store doesn't allow order at the table
            </ClosedStoresMessage>
          </>
        ))}

      {fullyLoadedButClosed && (
        <>
          <ClosedStoresMessage>
            {merchant && merchant.closed_store_message}
          </ClosedStoresMessage>
        </>
      )}
    </Container>
  )
}

interface FormContentProps {
  merchant: Merchant
  currentStore: ValidStore
}

const FormContent = ({ merchant, currentStore }: FormContentProps) => {
  const { history, location } = useReactRouter()
  const { isModalOpen, openModal, closeModal, loginModal } = useModal()
  const { inModal } = useContext(LandingContext)

  useEffect(() => {
    closeModal('login')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isLoggedIn = localStorage.getItem('customerId') !== null

  const handleLoginButtonClick = () => {
    if (isLoggedIn) {
      history.push('/account', { from: location.pathname })
    } else {
      openModal('login')
    }
  }

  return (
    <>
      {isModalOpen('login') && loginModal({ merchantId: merchant.id })}
      <Heading>
        <SimpleFormat>
          {currentStore.settings?.table_welcome_message || 'Order at table'}
        </SimpleFormat>
      </Heading>
      {!inModal && !isLoggedIn && (
        <Button
          variant='secondary'
          onClick={() => handleLoginButtonClick()}
          size='md'
          testId='accountsButton'
          width='auto'
        >
          Sign up or log in
        </Button>
      )}
      <h5>Please select your table number</h5>
      <FulfillmentOptions merchant={merchant} stores={[]} />
    </>
  )
}

const Container = styled.section(({ theme }: any) => ({
  zIndex: 10,
  flexShrink: 0,
  backgroundColor: 'white',
  padding: '40px 24px',
  position: 'relative',
  minHeight: '80vh',
  justifyContent: 'center',
  textAlign: 'center',
  '> h5': {
    margin: '32px 0 8px',
    fontWeight: 'normal',
    color: theme.colors.textMute
  },
  [theme.mediaQueries.viewport7]: {
    margin: '0',
    overflowY: 'auto',
    padding: '48px',
    justifyContent: 'left',
    textAlign: 'left',
    minHeight: '100vh'
  },
  [theme.mediaQueries.viewport10]: {
    padding: '56px'
  }
}))

const Logo = styled.img(({ theme }: any) => ({
  marginBottom: theme.space[3],
  height: '48px',
  [theme.mediaQueries.viewport7]: {
    height: '64px',
    marginBottom: theme.space[4]
  }
}))

const ClosedStoresMessage = styled.h2(({ theme }: any) => ({
  color: theme.colors.textBold,
  fontWeight: theme.fonts.heading.weight,
  fontFamily: theme.fonts.heading.family,
  marginTop: theme.space[4],
  [theme.mediaQueries.viewport7]: {
    fontSize: theme.fontSizes[5],
    paddingRight: '10%'
  }
}))

const Heading = styled.h2(({ theme }: any) => ({
  marginBottom: theme.space[4],
  color: theme.colors.textBold,
  fontWeight: theme.fonts.heading.weight,
  fontFamily: theme.fonts.heading.family,
  fontSize: theme.fontSizes[2],
  [theme.mediaQueries.viewport7]: {
    fontSize: theme.fontSizes[4],
    marginBottom: theme.space[4]
  },
  'p, h6': {
    fontWeight: 'normal',
    lineHeight: '1em'
  }
}))

export default DineInForm
