import styled from '@emotion/styled'
import { ModifierGroup } from 'shop/types/cart'
import { Modifier } from '../index'
import { ModifierOptionGroupName } from '../commonStyles'

interface Props {
  modifierGroups: ModifierGroup[]
}

const Modifiers = ({ modifierGroups }: Props) => {
  return (
    <Content data-testid='modifiers'>
      {modifierGroups.map(({ name, modifiers }, index) => (
        <div key={`modifier-${name}-${index}`}>
          <ModifierOptionGroupName>
            {name}
            <Modifier modifiers={modifiers} />
          </ModifierOptionGroupName>
        </div>
      ))}
    </Content>
  )
}

const Content = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}))

export default Modifiers
