import styled from '@emotion/styled'
import { useAppContent } from 'shop/hooks'

const PromoBanner = () => {
  const { promotionalBanner } = useAppContent()

  if (!promotionalBanner) {
    return <></>
  }

  return <Container> {promotionalBanner} </Container>
}

const Container = styled.div(({ theme }: any) => ({
  display: 'block',
  background: '#000',
  color: '#fff',
  textAlign: 'center',
  zIndex: 1000,
  padding: '8px 16px',
  fontSize: theme.fontSizes[1],
  width: '100%',
  [theme.mediaQueries.viewport7]: {
    padding: '14px'
  }
}))

export default PromoBanner
