import React, { useCallback, useEffect, useState } from 'react'

import { useMerchantStore, useLoader } from 'shop/hooks'
import { LoaderProps } from './types'

interface Props extends LoaderProps {
  storeSlug: string
}

export const MerchantStoreLoader = ({
  isParentLoaded = true,
  children,
  storeSlug
}: Props) => {
  const { fetchMerchantStore } = useLoader()
  const { setMerchantStore } = useMerchantStore()

  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const fetchData = useCallback(async () => {
    const merchantStoreResult = await fetchMerchantStore(storeSlug)

    if (merchantStoreResult) {
      setMerchantStore(merchantStoreResult)
    }

    setIsLoaded(Boolean(merchantStoreResult && isParentLoaded))
  }, [fetchMerchantStore, setMerchantStore, storeSlug, isParentLoaded])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return <React.Fragment>{children(isLoaded)}</React.Fragment>
}
