import React, {
  useContext,
  useEffect,
  useCallback,
  useReducer,
  useMemo
} from 'react'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { Store, TimeSlot, ValidStore } from '../types'
import { InvalidTimeSlot } from 'shop/components'
import {
  FulfillmentDateContainer,
  FulfillmentDatePicker
} from '../FulfillmentDatePicker'
import { LandingContextNew } from '../LandingContext'
import {
  dropdownIsEditable,
  fadeInOutMs,
  findSameDayAsapEnabledNoneAsapOnlyStore,
  findSameDayAsapEnabledStore,
  getInvalidStoresMessage,
  getValidPreorderStores,
  getValidSamedayStores,
  hasValidStore,
  isAsapValidOperatingHours,
  isUKLocation,
  matchTimeSlotToFulfillmentTime,
  returnFullStore,
  storesAsapEnabled,
  storesAsapOnly,
  trackDateChanged,
  trackOrderDayChanged,
  trackTimeslotChanged
} from '../utils'
import { QUERY_CUSTOMER_DEFAULT_ADDRESS } from 'shop/client/queries/CustomerQueries'
import { useShop, useMerchantStore } from 'shop/hooks'
import { useUTMTracker } from '../trackingUtils'
import { formatStoreTimeslots } from '../TimeSlot/utils'
import { isToday } from 'date-fns'
import Dropdown from 'shop/components/Inputs/Dropdown'
import { isDateAvailable } from '../datePickerUtils'
import { RiMapPinLine as PinIcon } from 'react-icons/ri'
import { initialSelectedOrderDayMapping, OrderDayMapping } from '../utils'
import AddressSelectionNew from '../AddressSelectionNew'
import { findNextAvailableDate } from '../preOrderUtils'
import { useDelivery } from 'shop/hooks/Delivery/useDelivery'
import { DeliveryFormHeading } from './commonStyles'
import {
  DeliveryCalendar,
  DeliverySuggestedStore,
  DeliveryTimeslot,
  ModalWrapper
} from './index'
import { usePrevious } from 'shop/hooks'
import DeliveryStore from './DeliveryStore'
import DeliveryOrderDay from './DeliveryOrderDay'
import { createAddressString } from 'shop/utils/address'

const DeliveryFormNew = () => {
  const {
    stores,
    currentStore,
    currentLocation,
    setCurrentLocation,
    fulfillmentType,
    fulfillmentTime,
    fulfillmentDate,
    setFulfillmentDate,
    setFulfillmentTime,
    setFulfillmentTimeRange,
    merchant,
    setUseCustomerDefaultAddress,
    validStores,
    setCurrentStore,
    loadingStores,
    loadingDates,
    isExtendedHour,
    setIsExtendedHour,
    storeOutOfRange,
    availableDates,
    preOrderOnly,
    isOverlayOpen,
    setIsOverlayOpen,
    address,
    setAddress,
    selectedOrderDay,
    setSelectedOrderDay,
    isEditMode,
    setIsEditMode,
    prevCartId,
    setPrevCartId
  } = useContext(LandingContextNew)

  const {
    orderDayDropdownHandler,
    storeListDropdownHandler,
    calendarInputClickHandler,
    isOrderDayDropdownOpen,
    isStoreListDropdownOpen,
    isCalendarOpen,
    isStoreListDropdownEnabled,
    setIsStoreListDropdownEnabled,
    setIsCalendarOpen,
    handleAddressSelectionModal,
    isAddressSelectionOpen,
    setIsOrderDayDropdownOpen,
    setIsStoreListDropdownOpen,
    handleClickOutsideOrderDayDropdown,
    onClickOutsideDatepicker,
    handleClickOutsideTimeSlotDropdown,
    isTimeSlotDropdownOpen,
    setIsTimeSlotDropdownOpen,
    handleClickOutsideStoreListDropdown,
    showInvalidTimeslotModal,
    setShowInvalidTimeslotModal,
    handleLocationSelect,
    onClickTimeSlotDropdownHandler,
    clearAddressHandler,
    setCurrentStoreHandler,
    validStoresList,
    hideMobileModal
  } = useDelivery({
    setCurrentLocation,
    currentLocation,
    setCurrentStore,
    validStores,
    address,
    setAddress,
    setSelectedOrderDay,
    selectedOrderDay,
    setIsEditMode,
    setFulfillmentTime,
    setFulfillmentTimeRange,
    isOverlayOpen,
    setIsOverlayOpen
  })

  const { tracker } = useUTMTracker()
  const { useShopClient } = useShop()
  const client = useShopClient()
  const { cartSession } = useShop()
  const { FulfillmentTimeStaticTimeStamp } = useMerchantStore()
  const { cart } = cartSession

  const oldFulfillmentType = usePrevious(cart?.fulfillment_type)

  const addressLocationCheck: boolean = !!address && !!currentLocation

  /** Reused boolean var check for location, validstores & fulfillment date */
  const fulfillmentDateValidStoresLocationCheck: boolean =
    addressLocationCheck && !!fulfillmentDate

  /** Store dropdown input list is shown if there is a fulfillment date, stores are loaded and more than 1 valid stores  */
  const showStoreList: boolean =
    fulfillmentDateValidStoresLocationCheck && validStores.length > 1

  const showDatepickerDelivery: boolean =
    fulfillmentDateValidStoresLocationCheck &&
    !!(currentLocation && isUKLocation(currentLocation)) &&
    !loadingStores[fulfillmentType] &&
    !loadingDates[fulfillmentType]

  const timeSlots: TimeSlot[] = formatStoreTimeslots(
    currentStore?.fulfillmentTimeslots || [],
    fulfillmentDate
  )

  const { pickup_address_enabled } = merchant

  const showDatepickerPickup: boolean =
    !loadingStores[fulfillmentType] &&
    !loadingDates[fulfillmentType] &&
    !!validStores.length &&
    !!fulfillmentDate

  const isPickup: boolean = fulfillmentType === 'pickup'

  const isPickupAddressEnabled: boolean = isPickup && !!pickup_address_enabled

  // Resets form when user switches from delivery to pickup
  useEffect(() => {
    // Remove currentLocation/address to recall GVS / save address in ref if user switches back
    if (isPickup && !pickup_address_enabled) {
      setAddress('')
      setCurrentLocation(undefined)
      setIsStoreListDropdownEnabled(false)
    }
    // Sets edit mode false when user switches between fulfillment types to ensure default form behaviour
    !!oldFulfillmentType && setIsEditMode(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfillmentType])

  const showStoreListPickup: boolean =
    isPickup && validStores.length > 1 && !!fulfillmentDate

  /** If ASAP is the first timeslot, and there are more than 1, select timeSlots[1], otherwise take timeSlots[0]  */
  const selectFirstTimeslot = useCallback((): void => {
    if (!!timeSlots.length) {
      // If cart has fulfillment time range, check if it's valid & set it
      if (
        isEditMode &&
        !!cart?.fulfillment_time_range &&
        !!timeSlots &&
        currentStore
      ) {
        // Does the prev. selected timeslot exist in the available timeslots?
        const cartTimeSlot = timeSlots.find(
          (ts) => ts.range === cart.fulfillment_time_range
        )

        if (cartTimeSlot) {
          setFulfillmentTime(cartTimeSlot.value)
          setFulfillmentTimeRange(cartTimeSlot.range)
          setPrevCartId(cart.id)
          return
        }
      }
      const timeslot = timeSlots[0]
      if (timeslot.range === 'ASAP' && timeSlots.length > 1) {
        const nonAsapTimeslot = timeSlots[1]
        setFulfillmentTime(nonAsapTimeslot.value)
        setFulfillmentTimeRange(nonAsapTimeslot.range)
      } else {
        setFulfillmentTime(timeslot.value)
        setFulfillmentTimeRange(timeslot.range)
      }
    }
  }, [
    setFulfillmentTime,
    setFulfillmentTimeRange,
    timeSlots,
    isEditMode,
    currentStore
  ])

  useEffect(() => {
    if (localStorage.getItem('customerId')) {
      const customerId = localStorage.getItem('customerId')

      // If customer is editing cart then set default address to bypass save address modal trigger later
      if (isEditMode) {
        setUseCustomerDefaultAddress(true)
        return
      }

      client
        .query({
          query: QUERY_CUSTOMER_DEFAULT_ADDRESS,
          variables: { customerId }
        })
        .then((results) => {
          if (results.data.addresses.length > 0) {
            setUseCustomerDefaultAddress(true)
            handleLocationSelect(createAddressString(results.data.addresses[0]))
          }
        })
    }
  }, []) // eslint-disable-line

  // Pre-loads address from utmSource
  useEffect(() => {
    if (tracker && tracker.address) {
      handleLocationSelect(tracker.address as string)
    }
  }, []) // eslint-disable-line

  /** Updates deeply nested order day object disabled statuses */
  function selectedOrderDayMappingStatusReducer(
    selectedOrderDayMapping: OrderDayMapping,
    action: { type: string; payload: any }
  ) {
    switch (action.type) {
      case 'update':
        const orderDayField = action.payload.field
        return {
          ...selectedOrderDayMapping,
          [fulfillmentType]: {
            ...selectedOrderDayMapping[fulfillmentType],
            [orderDayField]: {
              ...selectedOrderDayMapping[fulfillmentType][orderDayField],
              disabled: action.payload.value
            }
          }
        }
      default:
        return selectedOrderDayMapping
    }
  }

  const [selectedOrderDayMapping, dispatch] = useReducer(
    selectedOrderDayMappingStatusReducer,
    initialSelectedOrderDayMapping
  )

  /** If order day is disabled return non-disabled order day */
  const findAvailableOrderDay = useCallback(() => {
    if (
      selectedOrderDayMapping[fulfillmentType] &&
      selectedOrderDayMapping[fulfillmentType][selectedOrderDay]?.disabled
    ) {
      return Object.keys(selectedOrderDayMapping[fulfillmentType]).find(
        (orderDay) => {
          return !selectedOrderDayMapping[fulfillmentType][orderDay].disabled
        }
      )
    } else return null
  }, [selectedOrderDayMapping, fulfillmentType, selectedOrderDay])

  useEffect(() => {
    // Sets order day to non-disabled value When order day mapping / fulfilment type changes
    const availableOrderDay = findAvailableOrderDay()
    availableOrderDay && setSelectedOrderDay(availableOrderDay)
  }, [selectedOrderDayMapping, fulfillmentType])

  useEffect(() => {
    if (currentStore && fulfillmentType && stores) {
      const fullCurrentStore = returnFullStore(stores, currentStore)

      if (fullCurrentStore) {
        const { validStores: availableSameDayStores } = getValidSamedayStores(
          stores,
          fulfillmentType,
          new Date(),
          currentLocation
        )

        const { validStores: availableStoresForPreorder } =
          getValidPreorderStores(
            stores,
            fulfillmentType,
            undefined,
            currentLocation
          )

        // purely for readability
        const disabled = true
        const enabled = false

        // Reset order day values
        dispatch({ type: 'update', payload: { field: 'now', value: disabled } })
        dispatch({
          type: 'update',
          payload: { field: 'scheduled', value: enabled }
        })

        // Available same day stores exist and there are available same day asap enabled stores
        if (
          availableSameDayStores.length > 0 &&
          storesAsapEnabled(
            availableSameDayStores,
            fulfillmentType,
            fulfillmentDate
          ) &&
          isAsapValidOperatingHours(fulfillmentType, availableSameDayStores)
        ) {
          const currentStoreIsAsapEnabled = storesAsapEnabled(
            [fullCurrentStore],
            fulfillmentType,
            fulfillmentDate
          )

          // If current store isn't asap enabled, or is asap enabled and out of operating hours, set order day to scheduled
          if (
            !currentStoreIsAsapEnabled ||
            (currentStoreIsAsapEnabled &&
              !isAsapValidOperatingHours(fulfillmentType, [fullCurrentStore]))
          ) {
            setSelectedOrderDay('scheduled')
          }

          // Enable deliver/pickup now as there are same day stores and some are asap enabled
          dispatch({
            type: 'update',
            payload: { field: 'now', value: enabled }
          })

          // If all same day stores are asap only disable scheduled and set order day to now
          if (
            storesAsapOnly(availableSameDayStores) &&
            !availableStoresForPreorder.length
          ) {
            dispatch({
              type: 'update',
              payload: { field: 'scheduled', value: disabled }
            })
            setSelectedOrderDay('now')
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore, fulfillmentType, currentLocation])

  useEffect(() => {
    if (
      ((isEditMode && prevCartId !== cart?.id) ||
        !fulfillmentTime ||
        fulfillmentTime === 'immediately' ||
        !matchTimeSlotToFulfillmentTime(timeSlots, fulfillmentTime)) &&
      selectedOrderDay === 'scheduled'
    ) {
      selectFirstTimeslot()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrderDay, selectFirstTimeslot, timeSlots, fulfillmentTime])

  // Resets fulfillment time if order day is 'now'
  useEffect(() => {
    if (selectedOrderDay === 'now') {
      setFulfillmentTime('immediately')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrderDay])

  // If fulfillment type or fulfillment date is changed show suggested store text (not dropdown)
  useEffect(() => {
    setIsStoreListDropdownEnabled(false)
  }, [fulfillmentType, fulfillmentDate])

  const handleDateChange = useCallback(
    (date: Date): void => {
      // If all stores are pre-order only & date is today don't allow user to select today
      if (isToday(date)) {
        if (preOrderOnly) {
          return
        }
        // if selected date is today and current store is asap only then switch user's order day to 'now'
        currentStore && setOrderDayIfAsapOnly(stores, currentStore)
      }

      setIsOverlayOpen(false)
      if (date) {
        setTimeout(() => {
          setFulfillmentDate(date)
          setIsCalendarOpen(false)
        }, fadeInOutMs)
        trackDateChanged('modal', date)
      }
    },
    [
      setIsOverlayOpen,
      stores,
      currentStore,
      selectedOrderDay,
      preOrderOnly,
      setFulfillmentDate,
      setIsCalendarOpen
    ]
  )

  /** Handles order day value on order day dropdown click */
  const selectedOrderDayHandler = useCallback(
    (orderDayValue: string): void => {
      const itemKeys = Object.keys(selectedOrderDayMapping[fulfillmentType])
      const orderDay = itemKeys.find(
        (itemKey) =>
          selectedOrderDayMapping[fulfillmentType][itemKey] === orderDayValue
      ) as string

      setSelectedOrderDay(orderDay)

      const fullCurrentStore =
        currentStore && returnFullStore(stores, currentStore)
      // If selected order day is 'now'
      if (orderDay === 'now') {
        trackOrderDayChanged('today', 'modal')
        // Set fulfillment date to today when order day is 'now'
        if (!isToday(fulfillmentDate)) {
          setFulfillmentDate(new Date())
        }
        if (fullCurrentStore) {
          const currentStoreIsAsapEnabled = storesAsapEnabled(
            [fullCurrentStore],
            fulfillmentType,
            fulfillmentDate
          )
          // If current store is not asap enabled, or store is asap enabled but not in operating hours, find nearest next available store that is asap enabled & not the current store
          if (
            fullCurrentStore &&
            (!storesAsapEnabled(
              [fullCurrentStore],
              fulfillmentType,
              fulfillmentDate
            ) ||
              (currentStoreIsAsapEnabled &&
                !isAsapValidOperatingHours(fulfillmentType, [
                  fullCurrentStore
                ])))
          ) {
            const { validStores: availableSameDayStores } =
              getValidSamedayStores(
                stores,
                fulfillmentType,
                new Date(),
                currentLocation
              )
            // Find & set next available store that is asap enabled & not the current store
            const nextStoreScheduleToday = findSameDayAsapEnabledStore(
              availableSameDayStores,
              fullCurrentStore,
              fulfillmentType,
              fulfillmentDate
            )

            if (nextStoreScheduleToday) {
              const nextStore = validStores.find(
                (store) => store.name === nextStoreScheduleToday.name
              )
              nextStore && setCurrentStore(nextStore)
            }
          }
        }
      }

      // If selected order day is 'scheduled'
      if (orderDay === 'scheduled') {
        trackOrderDayChanged('future date', 'modal')
        // if current selected store is asap only
        if (fullCurrentStore && storesAsapOnly([fullCurrentStore])) {
          const { validStores: availableSameDayStores } = getValidSamedayStores(
            stores,
            fulfillmentType,
            fulfillmentDate,
            currentLocation
          )
          // if there are no available same day stores or every available same day store is asap only switch to the nearest preorder date
          if (
            !availableSameDayStores.length ||
            storesAsapOnly(availableSameDayStores)
          ) {
            const { validStores: availableStoresForPreorder } =
              getValidPreorderStores(
                stores,
                fulfillmentType,
                undefined,
                currentLocation
              )
            const nearestDate = findNextAvailableDate(
              availableStoresForPreorder,
              fulfillmentType,
              availableDates
            )
            setFulfillmentDate(nearestDate)
          } else {
            // if available same day stores, find & set next available same day non asap only store that is not the current store
            let nextStore: ValidStore | undefined
            const nextStoreScheduleToday =
              findSameDayAsapEnabledNoneAsapOnlyStore(
                availableSameDayStores,
                fullCurrentStore,
                fulfillmentType,
                fulfillmentDate
              )

            // if there is an available same day store that is asap enabled set that as next store
            if (nextStoreScheduleToday) {
              nextStore = validStores.find(
                (store) => store.name === nextStoreScheduleToday.name
              )
              // otherwise set current store as same day valid (same day enabled but none-asap enabled store) as current store
            } else {
              nextStore = validStores.find(
                (store) => store.name !== fullCurrentStore.name
              )
            }
            nextStore && setCurrentStore(nextStore)
          }
        }
      }

      setIsOverlayOpen(false)
      setTimeout(() => {
        setIsOrderDayDropdownOpen(false)
      }, fadeInOutMs)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      fulfillmentType,
      selectedOrderDayMapping,
      stores,
      currentStore,
      validStores
    ]
  )

  const handleLocationChange = useCallback(
    (newAddress: string): void => {
      setUseCustomerDefaultAddress(false)
      setAddress(newAddress)
    },
    [setUseCustomerDefaultAddress]
  )

  const suggestedStoreClickHandler = useCallback(() => {
    if (showStoreList || showStoreListPickup) {
      setIsOverlayOpen(true)
      setIsStoreListDropdownEnabled(true)
      setIsStoreListDropdownOpen(true)
    }
  }, [showStoreList, showStoreListPickup])

  // If order day is scheduled and current store is asap only, set order day to now (deliver/pickup now)
  const setOrderDayIfAsapOnly = (stores: Store[], currentStore: ValidStore) => {
    if (selectedOrderDay === 'scheduled') {
      const fullCurrentStore =
        currentStore && returnFullStore(stores, currentStore)
      if (fullCurrentStore && storesAsapOnly([fullCurrentStore])) {
        setSelectedOrderDay('now')
      }
    }
  }

  // If current store changes & fulfillment date is today, check if store is asap only to change order day to 'now'
  useEffect(() => {
    currentStore &&
      isToday(fulfillmentDate) &&
      setOrderDayIfAsapOnly(stores, currentStore)
  }, [currentStore]) // eslint-disable-line

  const selectedTimeSlotHandler = useCallback(
    (selectedTimeSlotDetails: TimeSlot) => {
      setIsOverlayOpen(false)

      if (selectedTimeSlotDetails) {
        const { range, value, extended } = selectedTimeSlotDetails
        setFulfillmentTime(value)
        setFulfillmentTimeRange(range)
        setIsExtendedHour(!!extended)
        trackTimeslotChanged(range, 'modal')
        // If asap and fulfillment date is today, set order day to 'now' (deliver/pickup now)
        if (value === 'immediately' && isToday(fulfillmentDate)) {
          setSelectedOrderDay('now')
        }
        setTimeout(() => {
          setIsTimeSlotDropdownOpen(false)
        }, fadeInOutMs)
      }
    },
    [
      timeSlots,
      fulfillmentDate,
      setFulfillmentTime,
      setFulfillmentTimeRange,
      setIsExtendedHour
    ]
  )

  const datepicker = (): JSX.Element => {
    return (
      <>
        <DatePickerContainer>
          {isCalendarOpen &&
            (showDatepickerDelivery || showDatepickerPickup) && (
              <FulfillmentDateContainer newFulfillmentModal={true}>
                <FulfillmentDatePicker
                  selected={fulfillmentDate}
                  onChange={handleDateChange}
                  minDate={new Date()}
                  filterDate={(date: Date) =>
                    isDateAvailable(availableDates, date, fulfillmentType)
                  }
                  placeholderText='Date'
                  dateFormat='dd/MM/yyyy'
                  disabled={
                    loadingStores[fulfillmentType] ||
                    loadingDates[fulfillmentType]
                  }
                  newFulfillmentModal={true}
                />
              </FulfillmentDateContainer>
            )}
        </DatePickerContainer>
      </>
    )
  }

  const datesOrStoresLoading = useMemo(
    () => loadingDates[fulfillmentType] || loadingStores[fulfillmentType],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingDates[fulfillmentType], loadingStores[fulfillmentType]]
  )

  const showTimeslotsSection =
    selectedOrderDay === 'scheduled' &&
    timeSlots?.length > 1 &&
    (showDatepickerDelivery || isPickup)

  const showSuggestedStoreDelivery =
    fulfillmentType === 'delivery' &&
    !isStoreListDropdownEnabled &&
    addressLocationCheck

  const showSuggestedStorePickup = isPickup && !isStoreListDropdownEnabled

  const showStoreListDropdownDelivery =
    fulfillmentType === 'delivery' &&
    isStoreListDropdownEnabled &&
    !!currentStore &&
    validStoresList?.length > 1 &&
    address &&
    !datesOrStoresLoading

  const showStoreListDropdownPickup =
    isPickup &&
    isStoreListDropdownEnabled &&
    !!currentStore &&
    validStoresList?.length > 1 &&
    !datesOrStoresLoading

  const deliveryDateTimeSectionLoading =
    (selectedOrderDay === 'scheduled' && datesOrStoresLoading) ||
    !fulfillmentTime

  const showAddressSelection: boolean =
    fulfillmentType === 'delivery' || isPickupAddressEnabled

  const showInvalidStoresMessage =
    currentLocation && !datesOrStoresLoading && validStores.length === 0

  return (
    <>
      {showAddressSelection && (
        <>
          <DeliveryFormHeading>
            <span>Address or postcode</span>
          </DeliveryFormHeading>
          <Dropdown
            testId='address-selection-input'
            textContent={address}
            textFallback={'e.g. N3 1LJ'}
            hasSelected={!!address}
            IconLeft={
              <>
                <PinIcon />
              </>
            }
            isOpen={isAddressSelectionOpen}
            onClick={handleAddressSelectionModal}
            onClear={clearAddressHandler}
            canEdit={true}
          >
            <ModalWrapper
              onClose={handleAddressSelectionModal}
              scrollBehaviour={'overflow'}
              isOpen={isOverlayOpen}
              hideMobileModal={hideMobileModal}
            >
              <AddressSelectionNew
                onChange={handleLocationChange}
                onSelect={handleLocationSelect}
                address={address}
                onClose={handleAddressSelectionModal}
              />
            </ModalWrapper>
          </Dropdown>
        </>
      )}
      {(addressLocationCheck || isPickup) && !showInvalidStoresMessage && (
        <DeliveryOrderDay
          fulfillmentType={fulfillmentType}
          handleClickOutsideOrderDayDropdown={
            handleClickOutsideOrderDayDropdown
          }
          isOrderDayDropdownOpen={isOrderDayDropdownOpen}
          isOverlayOpen={isOverlayOpen}
          loadingStores={
            loadingStores[fulfillmentType] || loadingDates[fulfillmentType]
          }
          orderDayDropdownHandler={orderDayDropdownHandler}
          selectedOrderDay={selectedOrderDay}
          selectedOrderDayHandler={selectedOrderDayHandler}
          selectedOrderDayMapping={selectedOrderDayMapping}
        />
      )}
      {selectedOrderDay === 'scheduled' &&
        (addressLocationCheck || isPickup) &&
        !showInvalidStoresMessage && (
          <CalendarTimeSlotContainer
            timeslotIsEditable={dropdownIsEditable(timeSlots)}
          >
            <DeliveryCalendar
              fulfillmentDate={fulfillmentDate}
              fulfillmentTime={fulfillmentTime}
              fulfillmentType={fulfillmentType}
              isCalendarOpen={isCalendarOpen}
              calendarInputClickHandler={calendarInputClickHandler}
              availableDates={availableDates}
              isOverlayOpen={isOverlayOpen}
              onClickOutsideDatepicker={onClickOutsideDatepicker}
              timeSlots={timeSlots}
              datepicker={datepicker}
              isLoading={deliveryDateTimeSectionLoading}
            />
            {showTimeslotsSection && (
              <div>
                <DeliveryTimeslot
                  fulfillmentTime={fulfillmentTime}
                  isTimeSlotDropdownOpen={isTimeSlotDropdownOpen}
                  onClickTimeSlotDropdownHandler={
                    onClickTimeSlotDropdownHandler
                  }
                  timeSlots={timeSlots}
                  selectedTimeSlotHandler={selectedTimeSlotHandler}
                  isOverlayOpen={isOverlayOpen}
                  handleClickOutsideTimeSlotDropdown={
                    handleClickOutsideTimeSlotDropdown
                  }
                  isExtendedHour={isExtendedHour}
                  isLoading={deliveryDateTimeSectionLoading}
                />
              </div>
            )}
            <InvalidTimeSlot
              showModal={showInvalidTimeslotModal}
              onClose={() => setShowInvalidTimeslotModal(false)}
            />
          </CalendarTimeSlotContainer>
        )}
      {(showSuggestedStoreDelivery || showSuggestedStorePickup) &&
        !showInvalidStoresMessage && (
          <DeliverySuggestedStore
            fulfillmentType={fulfillmentType}
            currentStore={currentStore}
            suggestedStoreClickHandler={suggestedStoreClickHandler}
            validStoresList={validStoresList}
            isLoading={datesOrStoresLoading}
          />
        )}
      {(showStoreListDropdownDelivery || showStoreListDropdownPickup) && (
        <DeliveryStore
          validStoresList={validStoresList}
          currentStore={currentStore}
          storeListDropdownHandler={storeListDropdownHandler}
          isStoreListDropdownOpen={isStoreListDropdownOpen}
          isOverlayOpen={isOverlayOpen}
          handleClickOutsideStoreListDropdown={
            handleClickOutsideStoreListDropdown
          }
          isPickup={isPickup}
          setCurrentStoreHandler={setCurrentStoreHandler}
          validStores={validStores}
          fulfillmentDate={fulfillmentDate}
          fulfillmentTimeStaticTimeStamp={FulfillmentTimeStaticTimeStamp}
        />
      )}

      {showInvalidStoresMessage && (
        <Message>
          {getInvalidStoresMessage(
            fulfillmentDate,
            storeOutOfRange,
            !!hasValidStore(stores, 'pickup'),
            true
          )}
        </Message>
      )}
    </>
  )
}

const Message = styled.p<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  margin: '16px 0 0',
  fontSize: theme.fontSizes[2],
  fontWeight: 500,
  textTransform: 'none',
  color: '#FF4D4F'
}))

const DatePickerContainer = styled.div<StyledHTMLElement>(() => ({
  zIndex: 101
}))

const CalendarTimeSlotContainer = styled.div<
  StyledHTMLElement & { timeslotIsEditable?: boolean },
  Required<Theme>
>(({ timeslotIsEditable = true }) => ({
  display: 'flex',
  height: '50px',
  position: 'relative',
  marginTop: '16px',
  columnGap: '5%',
  ' > div:nth-of-type(1)': {
    width: timeslotIsEditable ? '45%' : '100%'
  },
  '> div:nth-of-type(2)': {
    width: '50%'
  }
}))

export default DeliveryFormNew
