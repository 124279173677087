import {
  UPDATE_CART,
  APPLY_DISCOUNT,
  REMOVE_DISCOUNT,
  APPLY_AUTOMATIC_DISCOUNT,
  FETCH_QUOTE_AND_SAVE_ADDRESS,
  VALIDATE_CART,
  MUTATE_UPDATE_CART_TIMESLOT,
  INIT_SPLIT_BILL,
  QUERY_MINIMUM_ORDER_VALUE,
  SPLIT_PAYMENT_LINKS,
  QUERY_GET_CART_TIPPING_VALUES,
  QUERY_GET_ESTIMATED_FULFILLMENT_WINDOW,
  MUTATE_REFRESH_CART,
  QUERY_GET_DELIVERY_CHARGE_INFO,
  MUTATE_UPDATE_CART_GIFT_WRAP,
  GET_PAY_NEXT_STEP
} from 'shop/client'
import { ApolloClient } from '@apollo/client'
import { DeliveryAddress, SplitBillPayor } from './types'
import { ValidationApiResult } from 'shop/hooks'
import { CustomerDetailsParams } from 'shop/types/cart'

const getPayNextStep = async (client: ApolloClient<object>, cartId: string) => {
  return client.mutate({
    mutation: GET_PAY_NEXT_STEP,
    variables: { cartId }
  })
}

export type CustomerDetails = {
  email: string
  contact_num: string
  first_name: string
  last_name: string
  contact_num_prefix: string
}

export type UpdateDateCartRecipientDetails = Omit<
  CustomerDetails,
  'email' | 'contact_num_prefix'
>

export type UpdateDateCartRecipientDetailsV2 = Omit<
  CustomerDetailsParams,
  'email' | 'contactNumPrefix'
>

type UpdateCartParams = {
  cart_id: string
  customer_details?: Omit<CustomerDetails, 'contact_num_prefix'>
  recipient_details?: UpdateDateCartRecipientDetails
  dropoff_notes?: string
  order_notes?: string
  details_disclosed: boolean
}

const updateCart = (client: ApolloClient<object>, params: UpdateCartParams) => {
  return client.mutate({
    mutation: UPDATE_CART,
    variables: params
  })
}

const updateCartGiftWrap = (
  client: ApolloClient<object>,
  params: {
    cart_id: string
    gift_wrapped: boolean
    gift_wrap_message: string
  }
) => {
  return client.mutate({
    mutation: MUTATE_UPDATE_CART_GIFT_WRAP,
    variables: {
      cartId: params.cart_id,
      giftWrapped: params.gift_wrapped,
      giftWrapMessage: params.gift_wrap_message
    }
  })
}

const refreshCart = (client: ApolloClient<object>, cartId: string) => {
  return client.mutate({
    mutation: MUTATE_REFRESH_CART,
    variables: {
      cartId
    }
  })
}

export type SplitBillParams = {
  cartId: string
  payors: SplitBillPayor[]
}

const initSplitBill = (
  client: ApolloClient<object>,
  params: SplitBillParams
) => {
  return client.mutate({
    mutation: INIT_SPLIT_BILL,
    variables: params
  })
}

export type PaymentLink = {
  id: string
  customer_details: {
    name: string
    email: string
    contact_num: string
  }
  amount: number
  status: string
  internal_reference: string
  provider_link: string
}

const cartPaymentLinks = (
  client: ApolloClient<object>,
  params: { cartId: string }
) => {
  return client.query<{
    payments: PaymentLink[]
  }>({
    query: SPLIT_PAYMENT_LINKS,
    variables: params
  })
}

type ApplyDiscountParams = {
  cartId: string
  discountCode: string
}

const applyDiscount = (
  client: ApolloClient<object>,
  params: ApplyDiscountParams
) => {
  return client.mutate({
    mutation: APPLY_DISCOUNT,
    variables: params
  })
}

type RemoveDiscountParams = {
  cartId: string
}

const removeDiscount = (
  client: ApolloClient<object>,
  params: RemoveDiscountParams
) => {
  return client.mutate({
    mutation: REMOVE_DISCOUNT,
    variables: params
  })
}

const applyAutomaticDiscount = (
  client: ApolloClient<object>,
  params: Omit<ApplyDiscountParams, 'discountCode'>
) => {
  return client.mutate({
    mutation: APPLY_AUTOMATIC_DISCOUNT,
    variables: params
  })
}

const saveAndQuote = (
  client: ApolloClient<object>,
  cartId: string,
  deliveryAddress: DeliveryAddress
) => {
  const variables = {
    deliveryAddress,
    cartId
  }

  return client.mutate({
    mutation: FETCH_QUOTE_AND_SAVE_ADDRESS,
    variables
  })
}

const validateCart = (
  client: ApolloClient<object>,
  cartId: string,
  increaseCutoffMinutes?: number
): Promise<ValidationApiResult> => {
  return client.query({
    query: VALIDATE_CART,
    variables: { cartId, increaseCutoffMinutes },
    fetchPolicy: 'network-only'
  })
}

const updateCartTimeslot = (
  client: ApolloClient<object>,
  cartId: string,
  deliverBy: string | null,
  fulfillmentTimeRange: string | null
) => {
  return client.mutate({
    mutation: MUTATE_UPDATE_CART_TIMESLOT,
    variables: { deliverBy, fulfillmentTimeRange, cartId }
  })
}

const getMinimumOrderValue = (client: ApolloClient<object>, cartId: string) => {
  const variables = {
    cartId
  }

  return client.mutate({
    mutation: QUERY_MINIMUM_ORDER_VALUE,
    variables
  })
}

const getTipValues = (client: ApolloClient<object>, cartId: string) => {
  const variables = {
    id: cartId
  }

  return client.mutate({
    mutation: QUERY_GET_CART_TIPPING_VALUES,
    variables
  })
}

const getEstimatedFulfillmentWindow = (
  client: ApolloClient<object>,
  cartId: string
) => {
  const variables = {
    cartId
  }

  return client.query({
    query: QUERY_GET_ESTIMATED_FULFILLMENT_WINDOW,
    variables
  })
}

const getDeliveryChargeInfo = (
  client: ApolloClient<object>,
  cartId: string
) => {
  const variables = {
    cartId
  }

  return client.query({
    query: QUERY_GET_DELIVERY_CHARGE_INFO,
    variables,
    fetchPolicy: 'network-only'
  })
}

export {
  getMinimumOrderValue,
  getTipValues,
  updateCart,
  refreshCart,
  getPayNextStep,
  applyDiscount,
  removeDiscount,
  applyAutomaticDiscount,
  saveAndQuote,
  validateCart,
  updateCartTimeslot,
  initSplitBill,
  cartPaymentLinks,
  getEstimatedFulfillmentWindow,
  getDeliveryChargeInfo,
  updateCartGiftWrap
}
