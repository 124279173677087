import { format } from 'date-fns'
import _ from 'lodash'
import { parseTimezoneISO } from './date'
import { FulfillmentWindow } from 'shop/types'

export const getErrorMessage = (graphQLErrors: any): string => {
  return (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) || ''
}

export const isNetworkErrorUnauthorized = (networkError: any): boolean => {
  return !!(
    networkError &&
    networkError.statusCode === 401 &&
    (networkError.bodyText === 'unauthorized' ||
      networkError.result === 'unauthorized')
  )
}

/** The 'keysHaveValues' function checks if any of the specified keys in the object have non-empty values.
 * returns true if any of the specified keys have non-empty values, false otherwise. */
export const keysHaveValues = (
  keys: string[],
  obj: Record<string, any>
): boolean => {
  return _.some(keys, (key) => !_.isEmpty(obj[key]))
}

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

/** Returns fulfillment time range. Example output: "14:00 - 14:30" */
export const formatFulfillmentWindow = (window: FulfillmentWindow): string =>
  `${format(
    parseTimezoneISO(window.from),
    'HH:mm'
  )} - ${format(parseTimezoneISO(window.to), 'HH:mm')}`

export const getLogoURL = (domain: string, assetHost: string) =>
  `https://${domain}.${assetHost}/assets/logo/alternate`
