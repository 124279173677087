import isBefore from 'date-fns/isBefore'
import isValid from 'date-fns/isValid'
import { createClient } from 'shop/client'
import { PasswordErrors } from './types'
import parse from 'date-fns/parse'

export const isValidPostcode = (postcode: string) => {
  const regex =
    /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/

  return regex.test(postcode)
}

export const isValidPhoneNumber = (phone: string) => {
  return phone.length === 13 && /^(\+447|\+4420|\+441)/.test(phone)
}

export const removeLeadingZeroAndWhiteSpace = (phone: string) => {
  return phone.replace(/^0|\s/g, '')
}

export const isValidBirthdate = (dateString: string) => {
  // empty string is optional birthday
  if (dateString.length === 0) return true
  const dateToday = new Date()
  const date = parse(dateString, 'yyyy-MM-dd', new Date())

  return isValid(date) && isBefore(date, dateToday)
}

export const customerClient = (apiKey: string, merchantId: string) => {
  // domain isn't being used in create client
  return createClient('merchant', apiKey, merchantId)
}

export const containsPlusInString = (text: string) => {
  return /[+]/.test(text)
}

export const getPasswordSubtextColor = (passwordError: PasswordErrors) => {
  switch (passwordError) {
    case 'NO_PASSWORD':
      return '#8B8B8B'
    case 'PASSWORD_TOO_SHORT':
      return '#CF1322'
    default:
      return '#389E0D'
  }
}
