export const scrollToElement = (elId: string, yOffset: number = 60) => {
  const element = document.getElementById(elId)
  if (element) {
    const newYPos =
      element.getBoundingClientRect().top + window.scrollY - yOffset
    // Timeout needed for mobile scrolling.
    setTimeout(() => {
      window.scrollTo({
        top: newYPos,
        behavior: 'smooth'
      })
    }, 300)
  }
}
