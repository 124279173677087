import styled from '@emotion/styled'
import { IconBaseProps, IconType } from 'react-icons'
import Theme, { StyledHTMLElement } from 'shop/theme/types'

interface Props {
  Icon?: IconType
  note?: string
  noteTitle: string | React.ReactNode
  iconColor?: string | keyof Theme['colors']
  padding?: string
  width?: string
}

const NoteBreakdownItem = ({
  Icon,
  noteTitle,
  note,
  iconColor = 'black',
  padding = '12px',
  width = 'auto'
}: Props) => {
  const isString = typeof noteTitle === 'string'
  const StyledIcon = Icon ? styledIcon(Icon, iconColor) : null

  return (
    <>
      <Content
        data-testid='note-breakdown-item'
        padding={padding}
        width={width}
      >
        <div>
          {!!StyledIcon && <StyledIcon data-testid='note-icon' />}
          {isString ? (
            <NoteTitle data-testid='note-title'>{noteTitle}</NoteTitle>
          ) : (
            noteTitle
          )}
        </div>
        {!!note && <Note data-testid='note'> {note} </Note>}
      </Content>
    </>
  )
}

const styledIcon = (
  Icon: IconType,
  iconColor: string | keyof Theme['colors']
) => {
  return styled(Icon)<IconBaseProps, Required<Theme>>(({ theme }) => ({
    // use theme.colors[iconColor] if it exists, otherwise use iconColor as a literal color
    fill: theme.colors[iconColor] || iconColor,
    width: '24px',
    height: '24px',
    marginRight: '12px',
    flexShrink: 0
  }))
}

const Content = styled.div<
  StyledHTMLElement & { padding: string; width: string }
>(({ padding, width }) => ({
  backgroundColor: '#F5F5F5',
  padding: padding,
  borderRadius: '12px',
  margin: '4px 0',
  '> div': {
    alignItems: 'center',
    display: 'flex'
  },
  width: width
}))

const Note = styled.p<StyledHTMLElement>(() => ({
  color: '#595959',
  margin: '0 0 0 36px'
}))

const NoteTitle = styled.p<StyledHTMLElement>(() => ({
  fontSize: '16px',
  margin: 0
}))

export default NoteBreakdownItem
