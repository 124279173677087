import styled from '@emotion/styled'
import { StyledHTMLElement } from 'shop/theme/types'
import { keyframes } from '@emotion/core'

const Backdrop = styled.div<
  StyledHTMLElement & {
    isOverlay?: boolean
    isOpen?: boolean
    newFulfillmentModal?: boolean
  }
>(({ isOpen, newFulfillmentModal }) => ({
  background: setBackgroundProperty(isOpen),
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 40,
  animation: setBackdropAnimation(isOpen, newFulfillmentModal)
}))

const setBackdropAnimation = (
  isOpen?: boolean,
  newFulfillmentModal?: boolean
) => {
  if (newFulfillmentModal) {
    if (isOpen) {
      return `${opacityFadeIn} 0.3s forwards`
    } else {
      return `${opacityFadeOut} 0.3s forwards`
    }
  } else return 'none'
}

const setBackgroundProperty = (isOpen?: boolean) => {
  if (isOpen) {
    return 'initial'
  } else {
    return 'rgba(0, 0, 0, 0.5)'
  }
}

const opacityFadeIn = keyframes`
  0% { background: rgba(0, 0, 0, 0) }
  100% { background: rgba(0, 0, 0, 0.5) } 
`

const opacityFadeOut = keyframes`
  0% { background: rgba(0, 0, 0, 0.5) }
  100% { background: rgba(0, 0, 0, 0) } 
`

export default Backdrop
