import { useEffect, useState, useContext, useCallback, useMemo } from 'react'
import AlertMessage from '../components/Cart/AlertMessage'
import Spinner from 'shop/components/Loader/Spinner'
import styled from '@emotion/styled'
import {
  Button,
  CheckoutNavbar,
  CustomerDetailsForm,
  OrderSummary,
  GiftWrapping,
  CheckoutContentWrapper,
  StyledHeading,
  Heading,
  ShippingDetails,
  RecipientDetails,
  OrderNotes,
  CustomField,
  CustomerOptIn,
  CartItem,
  Cart
} from 'shop/components'
import { CheckoutActions } from 'shop/components/Checkout/FormElements'
import {
  updateCart,
  getMinimumOrderValue,
  getTipValues,
  saveAndQuote,
  applyAutomaticDiscount,
  getEstimatedFulfillmentWindow,
  updateCartGiftWrap,
  getPayNextStep,
  UpdateDateCartRecipientDetails
} from 'shop/components/Checkout/Network'
import {
  hasCustomField,
  getAmountNeeded,
  validateMOV,
  getQuotationErrorMessage,
  getPrefilledAddress,
  formatDeliveryChargeInfo,
  getFormDefaultValue,
  updateContactNum
} from 'shop/components/Checkout/utils'
import { strippedContactNumPrefix } from 'shop/components/Inputs/utils'
import { CheckoutForm, DeliveryAddress } from 'shop/components/Checkout/types'

import {
  useShop,
  useCart,
  useCheckout,
  useModal,
  FlashContext
} from 'shop/hooks'

import { useForm } from 'react-hook-form'
import { UseFormMethods } from 'react-hook-form'
import { AdditionalStoreFee } from 'shop/components/Landing/types'
import {
  FormFields,
  Discount,
  QuoteResult
} from 'shop/components/Checkout/types'
import isEmpty from 'lodash/isEmpty'
import {
  Date as DateComponent,
  Time
} from 'shop/components/NavBar/OrderDetailsList'
import { buildCartDate, getStore } from 'shop/components/NavBar/utils'
import {
  computeCartTotal,
  computeCartSubtotal,
  formatMoney,
  computeAdditionalStoreFee,
  isTableOrder,
  getTableNumber
} from 'shop/components/Cart/utils'
import { priceWithVat } from 'shop/components/Product/utils'
import { getStoreUrl } from 'shop/utils/store'
import {
  TrackableEvent,
  trackUserActionsFBPixel,
  trackAction,
  trackUserActionsGA4
} from 'tracker'
import { usePageViewTracker } from 'tracker'
import { BackButton } from 'shop/components/Buttons'
import { useRewards } from 'shop/components/Checkout/Loyalty/useRewards'
import MOVWarning from 'shop/components/Checkout/MOVWarning'
import MediaQuery from 'react-responsive'
import { useHistory } from 'react-router-dom'
import CustomizedMessage from 'shop/components/Checkout/CustomizedMessage'
import { ApplyDiscountApiResult } from 'shop/components/Checkout/DiscountForm'
import Tipping from 'shop/components/Tipping'
import { MUTATE_UPDATE_TIP_VALUE } from 'shop/client'
import { EstimatedTimeType } from 'shop/components/EstimatedFulfillmentWindow/EstimatedFulfillmentWindow'
import _ from 'lodash'
import CartProductErrorModal from 'shop/components/Landing/Delivery/CartProductErrorModal'
import { format, parseISO } from 'date-fns'
import { transformOrderItems } from 'shop/utils/cart'
import { ApiError, DeliveryAddress as CartDeliveryAddress } from 'shop/types'
import {
  createEcommEventDataFromCart,
  merchantGA4EcommTrackBeginCheckout,
  slerpGA4EcommTrackBeginCheckout
} from 'tracker/GA/ecommerce'
import { useSessionStorage } from 'shop/hooks/useSessionStorage'
import { keysHaveValues } from 'shop/utils/common'
import DiscountErrorModal from 'shop/components/ErrorModals/DiscountErrorModal'

export const ADDRESS_DEFAULTS = {
  line_1: '',
  line_2: '',
  city: '',
  zip: ''
}

export const FORM_DEFAULTS: CheckoutForm = {
  customer_details: {
    first_name: '',
    last_name: '',
    email: '',
    contact_num: '',
    contact_num_prefix: ''
  },
  recipient_details: {
    first_name: '',
    last_name: '',
    contact_num: '',
    contact_num_prefix: ''
  },
  for_someone_else: false,
  order_notes_enabled: false,
  order_notes: '',
  gift_wrapped: false,
  gift_wrap_message: '',
  gift_wrap_placeholder: '',
  delivery_address: ADDRESS_DEFAULTS,
  dropoff_notes: '',
  custom_field_value: '',
  details_disclosed: false
}

const Checkout = (props: any) => {
  const {
    cartSession,
    useShopClient,
    setIsStoreLoading,
    customerApiKey,
    customerDetails
  } = useShop()
  const { inbox } = useContext(FlashContext)
  const { pushMessage } = useContext(FlashContext)
  const { setCart, cart } = cartSession
  const { loadCart, updateCart: updateSlerpCart } = useCart()
  const client = useShopClient()
  const isLoggedIn = localStorage.getItem('customerId') !== null
  const {
    isDeliveryLoading,
    setIsDeliveryLoading,
    appliedDiscount,
    setAppliedDiscount,
    refresh,
    isValidationFailed,
    isModalOpen,
    cartValidation,
    invalidVariantIds,
    deliveryReduction,
    setDeliveryReduction,
    checkoutApplyDiscount,
    checkoutRemoveDiscount
  } = useCheckout(cart, client)
  const history = useHistory()

  const {
    getSessionStorageItem: getCheckoutFormSessionStorage,
    setSessionStorageItem: setCheckoutFormSessionStorage
  } = useSessionStorage<CheckoutForm>('temp-checkout-form-values')

  const { timeSlotModal, loginModal } = useModal()

  const [invalidItems, setInvalidItems] = useState<CartItem[]>([])
  const [isProductErrorModalOpen, setIsProductErrorModalOpen] =
    useState<boolean>(false)
  const [updateCartInProgress, setUpdateCartInProgress] =
    useState<boolean>(false)

  useEffect(() => {
    if (!cart) return
    if (!!invalidVariantIds?.length) {
      const invalidItems = cart.cart_items.filter((item) =>
        invalidVariantIds.includes(item.product_variant_id)
      )
      setInvalidItems(invalidItems)
      setIsProductErrorModalOpen(true)
    }
  }, [cart, invalidVariantIds])

  // Values from session storage are used to prefill the form if no cart loaded
  const tempSavedCheckoutValues = getCheckoutFormSessionStorage()

  const defaultFormValues = tempSavedCheckoutValues
    ? tempSavedCheckoutValues
    : FORM_DEFAULTS

  const checkoutForm = useForm({
    mode: 'onBlur',
    defaultValues: defaultFormValues
  })

  // Track page view
  usePageViewTracker()

  const {
    getValues,
    register,
    errors,
    watch,
    clearErrors,
    setError,
    reset
  }: UseFormMethods<FormFields> = checkoutForm
  const giftWrapChecked = watch('gift_wrapped')
  const giftWrapMessage = watch('gift_wrap_message')
  const orderNotesEnabled = watch('order_notes_enabled')

  const [isPaying, setIsPaying] = useState<boolean>(false)
  const [paymentError, setPaymentError] = useState<Error | null>(null)
  const [additionalStoreFeeCache, setAdditionalStoreFeeCache] = useState<
    AdditionalStoreFee | undefined
  >()
  const [tippingValues, setTippingValues] = useState<{
    [key: number]: number
  } | null>(null)
  const [isTippingOpen, setIsTippingOpen] = useState<boolean>(false)

  const [paymentMode, setPaymentMode] = useState<'single' | 'split'>('single')
  const [minimumOrderValue, setMinimumOrderValue] = useState<number>(0)
  const [estimatedFulfillmentWindow, setEstimatedFulfillmentWindow] =
    useState<EstimatedTimeType>({})
  let mounted = true

  const [deliveryAddress, setDeliveryAddress] = useState<DeliveryAddress>(
    tempSavedCheckoutValues?.delivery_address || ADDRESS_DEFAULTS
  )

  const startLoad = useCallback(async () => {
    const loadedCart = await loadCart()
    if (!loadedCart || _.isEmpty(loadedCart)) return history.push('/')
    setCart(loadedCart)
    refresh(loadedCart)
    setIsStoreLoading(false)
    setAdditionalStoreFeeCache(loadedCart.additional_store_fee_cache)

    const cartParams = loadedCart.cart_items.map((item) => {
      const p = item.product_variant.product
      const trackParams = {
        product_id: p.id,
        name: p.name,
        category: p.category.name,
        currency: 'gbp', // FIXME: Update this once multi-currency is supported
        price: priceWithVat(item.variant_price, item.variant_vat),
        quantity: item.quantity
      }

      return trackParams
    })
    const body = {
      category: 'Product',
      action: 'Begin Checkout'
    }

    trackUserActionsGA4(body, 'slerpGA4Tracking')

    // legacy tracking
    trackUserActionsFBPixel('InitiateCheckout', cartParams)
    trackUserActionsGA4(body, 'merchantGA4Tracking')

    // ecommerce tracking
    const ecommerceEventData = createEcommEventDataFromCart(loadedCart)
    slerpGA4EcommTrackBeginCheckout(ecommerceEventData)
    merchantGA4EcommTrackBeginCheckout(ecommerceEventData)

    const prefilledAddress = getPrefilledAddress(
      loadedCart,
      tempSavedCheckoutValues,
      ADDRESS_DEFAULTS
    )

    const isForSomeoneElse =
      !!loadedCart.recipient_details ||
      (!!tempSavedCheckoutValues?.recipient_details &&
        keysHaveValues(
          Object.keys(FORM_DEFAULTS['recipient_details']),
          tempSavedCheckoutValues.recipient_details
        )) ||
      FORM_DEFAULTS['for_someone_else']

    const formValuesToSet = {
      ...FORM_DEFAULTS,
      delivery_address: prefilledAddress,
      customer_details: getFormDefaultValue('customer_details', [
        loadedCart,
        tempSavedCheckoutValues
      ]),
      recipient_details: getFormDefaultValue('recipient_details', [
        loadedCart,
        tempSavedCheckoutValues
      ]),
      for_someone_else: isForSomeoneElse,
      order_notes: getFormDefaultValue('order_notes', [
        loadedCart,
        tempSavedCheckoutValues
      ]),
      order_notes_enabled: getFormDefaultValue('order_notes', [
        loadedCart,
        tempSavedCheckoutValues
      ]),
      dropoff_notes: getFormDefaultValue('dropoff_notes', [
        loadedCart,
        tempSavedCheckoutValues
      ]),
      gift_wrap_message: getFormDefaultValue('gift_wrap_message', [
        loadedCart,
        tempSavedCheckoutValues
      ]),
      gift_wrap_placeholder: getFormDefaultValue('gift_wrap_placeholder', [
        loadedCart,
        tempSavedCheckoutValues
      ]),
      gift_wrapped: getFormDefaultValue('gift_wrap_message', [
        loadedCart,
        tempSavedCheckoutValues
      ]),
      details_disclosed:
        tempSavedCheckoutValues?.details_disclosed ??
        (loadedCart?.details_disclosed || true),
      custom_field_value: getFormDefaultValue('custom_field_value', [
        loadedCart,
        tempSavedCheckoutValues
      ])
    }

    let { customer_details, recipient_details } = formValuesToSet

    customer_details = {
      ...customer_details,
      ...updateContactNum(
        customer_details?.contact_num,
        customer_details?.contact_num_prefix
      )
    }
    if (recipient_details) {
      recipient_details = {
        ...recipient_details,
        ...updateContactNum(
          recipient_details?.contact_num,
          recipient_details?.contact_num_prefix
        )
      }
    }

    reset({ ...formValuesToSet, recipient_details, customer_details })

    setDeliveryAddress(prefilledAddress)
  }, [refresh, reset, setCart, setIsStoreLoading])

  const claimAutomaticDiscount = (cartId: string) => {
    return applyAutomaticDiscount(client, { cartId: cartId })
      .then(({ data }: ApplyDiscountApiResult) => {
        if (!data?.discount) return
        const {
          discount: {
            discountId,
            discountCode,
            totalDiscount,
            target,
            value,
            trigger,
            type,
            subtotalInfo,
            storeFeeInfo
          }
        } = data
        const discountObject = {
          discount_amount: +totalDiscount,
          discount_id: discountId,
          discount_code: discountCode,
          discount_target: target,
          discount_value: value,
          discount_trigger: trigger,
          discount_type: type,
          subtotal_discount_amount: subtotalInfo?.reduction || 0,
          store_fee_info: {
            base: storeFeeInfo?.basePrice || '0',
            discounted: storeFeeInfo?.discountedPrice || '0',
            reduction: storeFeeInfo?.reduction || '0'
          }
        }
        setAppliedDiscount(discountObject)

        return discountObject
      })
      .catch((error: ApiError) => {
        const { graphQLErrors } = error
        const errorContent =
          graphQLErrors && graphQLErrors[0]
            ? graphQLErrors[0]['message']
            : 'Automatic Discount could not be applied to cart.'

        if (errorContent === 'You have reached your limit with this discount') {
          pushMessage({
            type: 'error',
            // graphQLErrors is sometimes undefined
            content: errorContent,
            timeout: 3000
          })
        }

        if (errorContent !== 'No eligible automatic discounts for cart')
          console.warn(error.message)
      })
  }

  useEffect(() => {
    startLoad()
  }, [startLoad])

  useEffect(() => {
    window.scrollTo(0, 0)
    return () => {
      mounted = false
    }
  }, [])

  const applyReward = (rewardDiscount: Discount) => {
    setAppliedDiscount(rewardDiscount)
  }

  const { customerRewards, claimReward, isProcessingRewards } = useRewards(
    cart,
    customerApiKey,
    applyReward,
    appliedDiscount?.discount_code || ''
  )

  const isDineIn = cart && isTableOrder(cart.metadata)

  const showCustomField = useMemo(() => {
    if (!cart) return false
    const { merchant, fulfillment_type } = cart
    if (!merchant || !fulfillment_type) return false
    const type = isDineIn ? 'order_at_table' : fulfillment_type
    return hasCustomField(
      { fulfillment_type: type, id: cart.id },
      {
        enabled: merchant.order_custom_field,
        name: merchant.order_custom_field_name,
        fulfillment_types: merchant.order_custom_field_apply_for
      }
    )
  }, [cart, isDineIn])

  // Computes cart items total + gift wrapp (NO discounts)
  const subtotal = useMemo(
    () =>
      computeCartSubtotal(
        cart?.cart_items || [],
        giftWrapChecked ? +(cart?.merchant?.gift_wrap_price || 0) : 0
      ),
    [cart?.cart_items, giftWrapChecked, cart?.merchant?.gift_wrap_price]
  )

  // Computes subtotal as above, but WITH discounts
  const subtotalWithAnyDiscounts = useMemo(
    () =>
      computeCartSubtotal(
        cart?.cart_items || [],
        giftWrapChecked ? +(cart?.merchant?.gift_wrap_price || 0) : 0,
        appliedDiscount?.subtotal_discount_amount
      ),
    [
      cart?.cart_items,
      appliedDiscount?.subtotal_discount_amount,
      giftWrapChecked,
      cart?.merchant?.gift_wrap_price
    ]
  )

  // Computes total of all things
  const total = useMemo(() => {
    if (!cart) return 0
    return computeCartTotal(
      cart.cart_items,
      giftWrapChecked ? +cart.merchant.gift_wrap_price : 0,
      appliedDiscount ? appliedDiscount.discount_amount : 0,
      deliveryReduction,
      appliedDiscount?.discount_target,
      computeAdditionalStoreFee(
        cart,
        additionalStoreFeeCache,
        appliedDiscount?.store_fee_info
      ).base
    )
    // it wants cart, however we only care about the cart_items
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cart?.cart_items,
    giftWrapChecked,
    appliedDiscount,
    additionalStoreFeeCache,
    deliveryReduction?.deliveryChargeReduction,
    deliveryReduction?.deliveryCharge
  ])

  const showOptIn: boolean = useMemo(() => {
    // guest checkout
    if (!isLoggedIn) return true
    // account and not yet opted in
    if (!customerDetails?.marketingOptIn) return true
    return false
  }, [isLoggedIn, customerDetails?.marketingOptIn])

  useEffect(() => {
    if (cart) {
      const emptyDiscount = {
        discount_amount: 0,
        discount_id: '',
        discount_code: null,
        discount_value: 0,
        discount_trigger: null,
        discount_target: null,
        discount_type: null
      }
      const withDiscount = appliedDiscount ? appliedDiscount : emptyDiscount
      setCart({
        ...cart,
        ...withDiscount,
        subtotal,
        subtotalWithAnyDiscounts,
        total
      })
    }
    // will loop if cart is a dependancy.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedDiscount?.discount_id, subtotal])

  useEffect(() => {
    if (cart && !cart.discount_code) {
      claimAutomaticDiscount(cart.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, deliveryReduction?.deliveryCharge])

  useEffect(() => {
    if (!cart?.id) return
    // remove any previously set tips
    if (cart.tip_value) {
      client.mutate({
        mutation: MUTATE_UPDATE_TIP_VALUE,
        variables: {
          id: cart.id,
          tip: 0
        }
      })
    }
    getTipValues(client, cart.id).then((res) => {
      if (res.data.tipsValues) {
        setTippingValues(res.data.tipsValues.tips)
      }
    })
    // we only want to do this when the cart is loaded for the first time.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart?.id])

  useEffect(() => {
    if (!cart) return
    if (cart.fulfillment_type === 'pickup' || subtotalWithAnyDiscounts === 0)
      return
    getMinimumOrderValue(client, cart.id).then((res) => {
      if (res.data.getMinimumOrderValue) {
        const mov = res.data.getMinimumOrderValue.minimumOrderValue || 0
        setMinimumOrderValue(Number(mov))
      }
    })
  }, [cart, client, subtotalWithAnyDiscounts])

  const saveAddressAndQuoteFee = () => {
    if (!cart) return
    const deliveryAddress = watch('delivery_address')
    saveAndQuote(client, cart.id, deliveryAddress)
      .then(({ data }: QuoteResult) => {
        if (!mounted) return
        clearErrors('address_api')

        const formattedDeliveryChargeInfo = formatDeliveryChargeInfo(
          data?.quoteAndUpdateAddress?.deliveryChargeInfo
        )

        setDeliveryReduction(formattedDeliveryChargeInfo)

        const minimumOrderValue = Number(
          data?.quoteAndUpdateAddress.minimum_order_value || 0
        )
        setMinimumOrderValue(minimumOrderValue)
      })
      .catch((error: ApiError) => {
        const errorMessage = getQuotationErrorMessage(error.graphQLErrors)
        setError('address_api', { type: 'manual', message: errorMessage })
      })
      .finally(() => {
        if (mounted) setIsDeliveryLoading(false)
      })
  }

  const shouldRefetchQuote = useMemo(
    () =>
      minimumOrderValue > 0 &&
      !!appliedDiscount &&
      subtotalWithAnyDiscounts === 0,
    [minimumOrderValue, appliedDiscount, subtotalWithAnyDiscounts]
  )

  useEffect(() => {
    if (shouldRefetchQuote) saveAddressAndQuoteFee()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetchQuote])

  // Fetch on address changes or when a expired timeslot is updated.
  useEffect(() => {
    setEstimatedFulfillmentWindow({})
    if (!cart?.id || isDeliveryLoading) return
    getEstimatedFulfillmentWindow(client, cart.id).then(({ data }) => {
      if (data.getEstimatedFulfillmentWindow) {
        setEstimatedFulfillmentWindow({ ...data.getEstimatedFulfillmentWindow })
      }
    })
  }, [cart?.id, client, isDeliveryLoading, cart?.fulfillment_time_range])

  /** Update the Slerp Cart with gift wrapping preference and fetch DeliveryChargeInfo */
  const updateCartGiftWrapping = () => {
    const { cart } = cartSession
    if (!cart) return
    // get most up to date values
    const { gift_wrapped, gift_wrap_message = '' } = checkoutForm.getValues()

    // dont send call if there is no current message to send
    if (gift_wrapped && !gift_wrap_message) return

    // if toggling save the message in the form to use between renders
    if (!gift_wrapped) {
      reset({
        ...checkoutForm.getValues(),
        gift_wrapped: gift_wrapped,
        gift_wrap_message: giftWrapMessage
      })
    }

    updateCartGiftWrap(client, {
      cart_id: cart.id,
      gift_wrapped: gift_wrapped,
      gift_wrap_message: gift_wrapped ? gift_wrap_message : ''
    }).then((res) => {
      const formattedDeliveryChargeInfo = formatDeliveryChargeInfo(
        res.data?.updateSlerpCart?.deliveryChargeInfo
      )
      setDeliveryReduction(formattedDeliveryChargeInfo)
    })
  }

  /** Trigger on Gift Wrap switch change */
  useEffect(() => {
    if (!cart) return
    // get up to date values
    const { gift_wrapped, gift_wrap_message = '' } = checkoutForm.getValues()

    // Enabling gift wrap with no message
    if (gift_wrapped && !gift_wrap_message) return
    // Users toggling the switch before making an "impact" on other functionality
    if (
      !gift_wrapped &&
      !gift_wrap_message &&
      !giftWrapMessage &&
      !deliveryReduction?.deliveryChargeReductionReason
    )
      return

    updateCartGiftWrapping()
    // only want to trigger this on switch toggle
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftWrapChecked])

  if (!cart) return null

  const { fulfillment_type, merchant, additional_store_fee_cache } = cart

  const checkout = (addedTip?: number) => {
    const {
      customer_details: {
        contact_num_prefix,
        contact_num,
        ...otherCustomerDetails
      },
      recipient_details,
      order_notes,
      gift_wrapped,
      gift_wrap_message,
      dropoff_notes,
      details_disclosed,
      custom_field_value
    } = checkoutForm.getValues()

    const customFieldValues =
      custom_field_value && custom_field_value !== ''
        ? {
            custom_field_value,
            custom_field_name: merchant.order_custom_field_name
          }
        : { custom_field_value: null, custom_field_name: null }

    setIsPaying(true)

    const customer_details = {
      ...otherCustomerDetails,
      contact_num: `${strippedContactNumPrefix(
        contact_num_prefix
      )}${contact_num}`
    }

    let amendedRecipientDetails: UpdateDateCartRecipientDetails =
      recipient_details
    if (recipient_details?.first_name) {
      const {
        contact_num_prefix: rec_prefix,
        contact_num: rec_num,
        ...otherRecipientDetails
      } = recipient_details

      amendedRecipientDetails = {
        ...otherRecipientDetails,
        contact_num: `${strippedContactNumPrefix(rec_prefix)}${rec_num}`
      }
    }

    // Ensure customer_details.email is in lowercase
    if (customer_details?.email) {
      customer_details.email = customer_details.email.toLowerCase()
    }

    return updateCart(client, {
      cart_id: cart.id,
      ...{
        customer_details,
        order_notes,
        gift_wrapped,
        gift_wrap_message,
        recipient_details: amendedRecipientDetails || recipient_details,
        dropoff_notes,
        details_disclosed
      },
      ...customFieldValues
    })
      .then(() => {
        // success in updating, update cartSession
        setCart({
          ...cart,
          customer_details,
          order_notes,
          gift_wrapped,
          gift_wrap_message,
          recipient_details,
          dropoff_notes,
          details_disclosed,
          tip_value: addedTip || 0,
          total: total + (addedTip || 0)
        })
        return getPayNextStep(client, cart.id)
      })
      .then(async (result) => {
        if (paymentMode === 'split') {
          return (window.location.href = '/order/split')
        }

        if (result.data.payForCart.nextStep === 'skip') {
          const transactionId = result.data.payForCart.transactionId
          return history.push(`/purchase/${transactionId}`)
        }

        return history.push('/pay')
      })
      .catch((error) => {
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          switch (error.graphQLErrors[0].code) {
            case 'STRIPE_AMOUNT_TOO_SMALL':
              return setPaymentError(
                new Error('Please ensure your total is above £0.30')
              )
            default:
              return setPaymentError(
                new Error(
                  'Unable to process payments at this time. Please try again later.'
                )
              )
          }
        }

        setPaymentError(
          new Error(
            'Unable to process payments at this time. Please try again later.'
          )
        )
        return setIsPaying(false)
      })
  }

  const hasValidMOV = validateMOV(subtotalWithAnyDiscounts, minimumOrderValue)
  const amountNeeded = getAmountNeeded(
    hasValidMOV,
    minimumOrderValue,
    subtotalWithAnyDiscounts
  )
  const showMOVWarning = !hasValidMOV && amountNeeded > 0

  // Temporarily disable Split the Bill until we fully support it again.
  const showSplitTheBill = false
  // isDineIn && total !== 0 && merchant.split_bill_enabled

  const tipping_enabled = () => {
    if (cart.fulfillment_type === 'delivery')
      return cart.store.settings.delivery_tipping_enabled
    if (fulfillment_type === 'pickup') {
      if (cart.metadata?.pickup_type === 'table')
        return cart.store.settings.oat_tipping_enabled
      return cart.store.settings.pickup_tipping_enabled
    }

    return false
  }

  const handleBlur = () => {
    setDeliveryAddress(getValues().delivery_address)
  }

  /** Derive updateSlerpCart params from current cart */
  const createUpdateSlerpCartParams = (cart: Cart) => {
    const {
      id,
      address,
      store_id,
      merchant_id,
      metadata,
      deliver_by,
      cart_items,
      fulfillment_time_range,
      fulfillment_type,
      delivery_address
    } = cart
    const { line_1, line_2, city, country, zip } = delivery_address || {}

    return {
      cartId: id,
      customerId: localStorage.getItem('customerId'),
      storeId: store_id,
      merchantId: merchant_id,
      address: address,
      fulfillmentDate: deliver_by
        ? parseISO(`${deliver_by}Z`).toISOString().split('T')[0]
        : new Date().toISOString().split('T')[0],
      fulfillmentTime:
        fulfillment_time_range === 'ASAP'
          ? 'asap'
          : format(parseISO(`${deliver_by}Z`), 'HH:mm'),
      fulfillmentType: fulfillment_type,
      orderItems: transformOrderItems(cart_items),
      metadata: JSON.stringify(metadata),
      deliveryAddress:
        fulfillment_type === 'delivery'
          ? ({
              flatNumber: '',
              line_1: line_1 || '',
              line_2: line_2 || '',
              city: city || '',
              country: country || '',
              zip: zip || ''
            } as CartDeliveryAddress)
          : null
    }
  }

  /** Remove invalid items from cart when products have become unavaiable at checkout */
  const removeItemsFromCart = async (e: React.MouseEvent) => {
    setUpdateCartInProgress(true)

    const updatedCart = {
      ...cart,
      cart_items: cart.cart_items.filter(
        (item) => !invalidVariantIds.includes(item.product_variant_id)
      )
    }

    const slerpCartUpdatePayload = createUpdateSlerpCartParams(updatedCart)

    try {
      const updatedCart = await updateSlerpCart(slerpCartUpdatePayload)
      setIsProductErrorModalOpen(false)
      setUpdateCartInProgress(false)
      if (updatedCart && !updatedCart.cart_items.length) {
        // If there are no items left in cart, clear the cart and push back to shop page to add items
        history.push(getStoreUrl(cart.store.slug))
      }
    } catch {
      setIsProductErrorModalOpen(false)
      history.push('/')
    }
  }

  const setCheckoutValuesOnBlur = () => {
    setCheckoutFormSessionStorage(getValues())
  }

  return (
    <>
      <CartProductErrorModal
        type={'InvalidItemsCheckout'}
        loading={updateCartInProgress}
        isOpen={isProductErrorModalOpen}
        invalidItems={invalidItems}
        storeName={cart?.store?.name}
        onContinue={removeItemsFromCart}
      ></CartProductErrorModal>
      {isTippingOpen && tipping_enabled() && tippingValues && (
        <Tipping
          tippingValues={tippingValues}
          handleCloseModal={() => setIsTippingOpen(false)}
          proceedCheckout={(addedTip?: number) => checkout(addedTip)}
        />
      )}
      {isModalOpen('timeslot') && timeSlotModal(cartValidation)}
      {isModalOpen('login') && loginModal({ merchantId: merchant.id })}
      {!!cartValidation?.discountWarningsNew.length && (
        <DiscountErrorModal
          errorCode={cartValidation.discountWarningsNew[0].validationType}
          values={{
            minimumOrderValue:
              cartValidation.discountWarningsNew[0].minimumValue
          }}
          origin={'checkout'}
          checkoutRemoveDiscount={checkoutRemoveDiscount}
          storeSlug={cart.store.slug}
        />
      )}
      <Container>
        <CheckoutNavbar />
        {inbox.map((message) => (
          <AlertMessage heading={message.content} flash type={message.type} />
        ))}
        <CheckoutContentWrapper>
          <form
            onBlur={setCheckoutValuesOnBlur}
            onSubmit={checkoutForm.handleSubmit(() =>
              tipping_enabled() ? setIsTippingOpen(true) : checkout()
            )}
            onKeyPress={(e) => {
              const focusedInput: HTMLInputElement | null =
                document.querySelector('input:focus')
              if (e.key === 'Enter' && focusedInput) {
                focusedInput.blur()
                return e.preventDefault()
              }
            }}
          >
            <DesktopBackButton>
              <BackButton
                url={getStoreUrl(cart.store.slug)}
                label='Back'
                size='12px'
              />
            </DesktopBackButton>
            <MainHeading as='h1'>Checkout</MainHeading>
            {paymentError && (
              <AlertMessage type='error' heading={paymentError.message} />
            )}
            {isValidationFailed && (
              <AlertMessage
                type='error'
                heading='Sorry. We were unable to check if your selected time is still available. Please refresh this page, or contact support@slerp.com'
              />
            )}
            <FormSection>
              <Heading as='h2' margin='0 0 24px'>
                Personal details
              </Heading>
              <CustomerDetailsForm
                formHandle={checkoutForm}
                onBlur={handleBlur}
              />
            </FormSection>

            {cart.merchant?.custom_checkout_message &&
            cart.fulfillment_type === 'pickup' &&
            !isDineIn ? (
              <FormSection>
                <CustomizedMessage
                  message={cart.merchant?.custom_checkout_message}
                  icon={cart.merchant?.custom_checkout_icon_code || 'default'}
                />
              </FormSection>
            ) : null}

            {isDineIn && (
              <FormSection>
                <OrderInfo data-testid={'checkoutTableDetails'}>
                  <OrderInfoLabel>Dine-in details</OrderInfoLabel>
                  {getTableNumber(cart.metadata)}
                </OrderInfo>
              </FormSection>
            )}
            {fulfillment_type === 'delivery' && (
              <FormSection>
                <RecipientDetails formHandle={checkoutForm} />
              </FormSection>
            )}
            {fulfillment_type === 'delivery' && (
              <FormSection>
                <Heading as='h2' margin='32px 0 24px'>
                  Delivery details
                </Heading>
                <ShippingDetails
                  onBlur={handleBlur}
                  formHandle={checkoutForm}
                  displayedAddress={deliveryAddress}
                  deliveryNotePlaceholder={merchant.delivery_note_placeholder}
                  setIsDeliveryLoading={setIsDeliveryLoading}
                  saveAddressAndQuoteFee={saveAddressAndQuoteFee}
                />
              </FormSection>
            )}
            {!isDineIn && (
              <FormSection>
                <OrderNotes
                  formHandle={checkoutForm}
                  enabled={orderNotesEnabled}
                  placeholder={merchant.order_note_placeholder}
                  error={errors.order_notes}
                />
              </FormSection>
            )}
            {showCustomField && (
              <FormSection>
                <CustomField
                  fieldName={merchant.order_custom_field_name}
                  required={merchant.order_custom_field_mandatory}
                  placeholder={merchant.order_custom_field_placeholder || ''}
                  formHandle={checkoutForm}
                  error={errors.custom_field_value}
                />
              </FormSection>
            )}
            {merchant.gift_wrap_enabled && (
              <FormSection>
                <GiftWrapping
                  updateCartGiftWrapping={updateCartGiftWrapping}
                  formHandle={checkoutForm}
                  error={errors.gift_wrap_message && errors.gift_wrap_message}
                  giftWrapEnabled={giftWrapChecked}
                  giftWrapText={merchant.gift_wrap_text}
                  giftWrapPrice={merchant.gift_wrap_price}
                  giftWrapPlaceholder={merchant.gift_wrap_placeholder}
                />
              </FormSection>
            )}
            {!isDineIn && (
              <FormSection mobileOnly={true}>
                <OrderInfo>
                  <OrderInfoLabel>{fulfillment_type} schedule</OrderInfoLabel>
                  <PickupScheduleList>
                    <DateComponent
                      date={buildCartDate({ deliver_by: cart?.deliver_by })}
                    />
                    <Time time={cart?.fulfillment_time_range || ''} />
                  </PickupScheduleList>
                </OrderInfo>
              </FormSection>
            )}
            {fulfillment_type === 'pickup' && !isDineIn && (
              <FormSection mobileOnly={true}>
                <OrderInfo>
                  <OrderInfoLabel>Store</OrderInfoLabel>
                  {merchant.name} {getStore(cart)}
                </OrderInfo>
              </FormSection>
            )}
            <FormSection>
              <CustomerOptIn {...{ register, isVisible: showOptIn }} />
            </FormSection>
            <CheckoutActions>
              {showMOVWarning ? (
                <>
                  <MediaQuery maxWidth={768}>
                    <MOVWarning
                      storeUrl={getStoreUrl(cart.store.slug)}
                      amountNeeded={amountNeeded}
                    />
                  </MediaQuery>
                  <MediaQuery minWidth={768.1}>
                    <Button
                      testId='continueShoppingButton'
                      type='button'
                      onClick={() => {
                        const event = TrackableEvent.MOVActioned
                        trackAction(
                          {
                            category: 'Checkout',
                            action: 'Clicked continue shopping'
                          },
                          { event }
                        )
                        history.push(getStoreUrl(cart.store.slug))
                      }}
                    >
                      Continue shopping
                    </Button>
                  </MediaQuery>
                </>
              ) : (
                <>
                  {showSplitTheBill && (
                    <Button
                      type='submit'
                      disabled={!isEmpty(errors) || isPaying}
                      name='pay_via'
                      value='split'
                      variant='secondary'
                      onClick={() => {
                        setPaymentMode('split')
                        checkoutForm.handleSubmit(() =>
                          tipping_enabled()
                            ? setIsTippingOpen(true)
                            : checkout()
                        )
                      }}
                      data-testid='split-the-bill-button'
                    >
                      {isPaying && paymentMode === 'split' ? (
                        <Spinner />
                      ) : (
                        'Split the bill'
                      )}
                    </Button>
                  )}
                  <Button
                    type='submit'
                    disabled={!isEmpty(errors) || isPaying || isDeliveryLoading}
                    name='pay_via'
                    value='standard'
                  >
                    {isPaying && paymentMode === 'single' ? (
                      <Spinner />
                    ) : (
                      `Pay - ${formatMoney(total)}`
                    )}
                  </Button>
                </>
              )}
            </CheckoutActions>
          </form>
          <OrderSummary
            giftWrapped={giftWrapChecked}
            cartItems={cart.cart_items}
            fulfillmentType={cart.fulfillment_type}
            giftWrapPrice={giftWrapChecked ? +cart.merchant.gift_wrap_price : 0}
            discountAmount={
              (appliedDiscount && appliedDiscount.discount_amount) || 0
            }
            discountCode={appliedDiscount && appliedDiscount.discount_code}
            discountTarget={appliedDiscount && appliedDiscount.discount_target}
            discountTrigger={
              appliedDiscount && appliedDiscount.discount_trigger
            }
            discountValue={
              (appliedDiscount && appliedDiscount.discount_value) || 0
            }
            discountType={appliedDiscount && appliedDiscount.discount_type}
            total={total}
            isDeliveryLoading={isDeliveryLoading}
            hasAddressErrors={!!checkoutForm.errors['address']}
            additionalStoreFee={additional_store_fee_cache}
            additionalStoreFeeDiscount={appliedDiscount?.store_fee_info}
            cart={cart}
            customerRewards={customerRewards}
            claimReward={claimReward}
            isProcessingRewards={isProcessingRewards}
            merchantSlug={merchant.slug}
            movWarning={
              showMOVWarning ? (
                <MOVWarning
                  storeUrl={getStoreUrl(cart.store.slug)}
                  amountNeeded={amountNeeded}
                />
              ) : null
            }
            subtotal={subtotal}
            subtotalWithAnyDiscounts={subtotalWithAnyDiscounts}
            minimumOrderValue={minimumOrderValue}
            saveAddressAndQuoteFee={saveAddressAndQuoteFee}
            claimAutomaticDiscount={claimAutomaticDiscount}
            tipValue={cart?.tip_value || 0}
            showTipValue={false}
            estimatedFulfillmentWindow={estimatedFulfillmentWindow}
            deliveryAddress={deliveryAddress}
            deliveryReduction={deliveryReduction}
            checkoutApplyDiscount={checkoutApplyDiscount}
            checkoutRemoveDiscount={checkoutRemoveDiscount}
          />
        </CheckoutContentWrapper>
      </Container>
    </>
  )
}

const FormSection = styled.div(({ theme, mobileOnly }: any) => ({
  marginBottom: '24px',
  padding: '0 16px',
  [theme.mediaQueries.viewport6]: {
    display: mobileOnly ? 'none' : 'block',
    padding: '0'
  },

  '>h2': {
    fontSize: '16px',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  }
}))

const Container = styled.div(() => ({
  minHeight: '100vh',
  backgroundColor: 'white',
  position: 'relative'
}))

const MainHeading = styled(StyledHeading)(({ theme }: any) => ({
  padding: '0 16px',
  margin: '0 0 20px',
  [theme.mediaQueries.viewport6]: { padding: '0' }
}))

const OrderInfo = styled.div(({ theme }: any) => ({
  fontSize: theme.fontSizes[0],
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  '> strong': { marginRight: '8px' }
}))

const OrderInfoLabel = styled.label(() => ({
  '&:first-letter': { textTransform: 'capitalize' },
  fontWeight: 'bold'
}))

const PickupScheduleList = styled.ul(() => ({
  margin: 0,
  padding: 0,
  display: 'flex',
  listStyleType: 'none',
  '> li:first-of-type': { marginRight: '12px' },
  '> li:last-child': { marginRight: 0 }
}))

const DesktopBackButton = styled.div(({ theme }: any) => ({
  display: 'none',
  [theme.mediaQueries.viewport6]: { display: 'inline-flex' }
}))

export default Checkout
