import styled from '@emotion/styled'
import { StyledHTMLElement } from 'shop/theme/types'
import { Theme } from 'styled-system'

export const Subheading = styled.p<StyledHTMLElement, Required<Theme>>(() => ({
  lineHeight: '22px',
  color: '#2A2A2A',
  margin: '12px 0'
}))

export const Content = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    margin: '0 auto',
    gap: '32px',
    padding: '32px 16px 16px 16px',
    [theme.mediaQueries.viewport6]: {
      maxWidth: '530px',
      gap: '16px',
      padding: '82px 32px 32px 32px'
    }
  })
)

export const Container = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    marginTop: '32px',
    gap: '32px',
    flexDirection: 'column',
    display: 'flex',
    whiteSpace: 'pre',
    '> button': {
      flexWrap: 'wrap'
    }
  })
)
