import { useLayoutEffect, useState } from 'react'

export const useLabelOffset = (inputId: string, hasPreText: boolean) => {
  const [labelOffsetLeft, setLabelOffsetLeft] = useState(16)
  const [labelOffsetTop, setLabelOffsetTop] = useState(14)
  const [labelOffsetTopFocused, setLabelOffsetTopFocused] = useState(-6)

  useLayoutEffect(() => {
    if (hasPreText) {
      const preTextComponent =
        document.getElementById(`${inputId}-placeholderPreText`) ||
        document.getElementById(`${inputId}-dropdown`)
      if (preTextComponent) {
        setLabelOffsetLeft(16 + preTextComponent.offsetWidth)
      }
    }

    const inputComponent = document.getElementById(inputId)
    const placeholderComponent = document.getElementById(
      `${inputId}-placeholder`
    )
    const inputHeight = inputComponent?.getBoundingClientRect().height || 0
    const placeholderHeight =
      placeholderComponent?.getBoundingClientRect().height || 0
    const offsetHeight = inputHeight / 2 - placeholderHeight / 2
    setLabelOffsetTop(offsetHeight)
    setLabelOffsetTopFocused(0 - placeholderHeight / 2)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { labelOffsetLeft, labelOffsetTop, labelOffsetTopFocused }
}
