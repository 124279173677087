import { useCallback } from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { getProgressBarSchema } from 'shop/components/Order/utils'

interface Props {
  currentCode: number
  isDelivery: boolean
}

const StatusProgress = ({ currentCode, isDelivery }: Props) => {
  const renderProgressBar = useCallback(() => {
    const progressSchema = getProgressBarSchema(isDelivery)

    return progressSchema.flatMap(({ code: progressCode, elements }, index) => {
      const isActive = currentCode >= progressCode
      const isCurrent = isActive && currentCode === progressCode

      return elements.map((element) => {
        return (
          <ProgressBarWrapper size={element[0]} key={`${index}-${element[0]}`}>
            <ProgressBar isActive={isActive} isCurrent={isCurrent} />
          </ProgressBarWrapper>
        )
      })
    })
  }, [currentCode, isDelivery])

  return <Root>{renderProgressBar()}</Root>
}

const Root = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  width: '100%',
  margin: '0 auto 8px'
}))

const ProgressBarWrapper = styled.div(({ size }: { size: string }) => {
  const sizes = {
    xs: 1,
    sm: 2,
    md: 4,
    lg: 6
  }

  return {
    flexGrow: sizes[size],
    height: '5px',
    borderRadius: '4px',
    background: '#CECDD3'
  }
})

const loadingAnimation = keyframes({
  '0%': { width: '0%' },
  '100%': { width: '100%' }
})

const gradientAnimation = keyframes({
  '0%': { backgroundPosition: '0 0' },
  '100%': { backgroundPosition: '-200% 0' }
})

const ProgressBar = styled.div(
  ({ isActive, isCurrent }: { isActive: boolean; isCurrent: boolean }) => {
    return {
      margin: '0',
      height: '5px',
      borderRadius: '4px',
      background: isCurrent
        ? 'repeating-linear-gradient(to right, black 0%, #CECDD3 50%, black 100%)'
        : 'black',
      backgroundSize: '200% auto',
      backgroundPosition: '0 100%',
      display: isActive ? 'block' : 'none',
      opacity: isActive ? 1 : 0,
      animation: isCurrent
        ? `${loadingAnimation} 6s forwards, ${gradientAnimation} 2s ease-in infinite`
        : 'none'
    }
  }
)

export default StatusProgress
