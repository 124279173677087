import React, { useState, useEffect } from 'react'
import CenterModal from 'shop/components/Modal/CenterModal'
import styled from '@emotion/styled'

type Props = {
  showModal?: boolean
  onClose?: () => void
}

const InvalidTimeSlot = ({ showModal, onClose }: Props) => {
  const [showForm, setShowForm] = useState(!!showModal)

  useEffect(() => {
    const timeSlotUrl = window.location.search.substr(1)

    setShowForm(timeSlotUrl === 'invalid_timeslot=true' || !!showModal)
  }, [showModal])

  return (
    <>
      {showForm && (
        <CenterModal
          handleCloseModal={() => {
            setShowForm(false)
            if (!!onClose) onClose()
          }}
        >
          <Container>
            <h3>
              Unfortunately, the time slot you selected is no longer available.
            </h3>
            <p>Please choose a new time slot. </p>
          </Container>
        </CenterModal>
      )}
    </>
  )
}

const Container = styled.div(({ theme }: any) => ({
  backgroundColor: 'white',
  padding: '64px 40px 40px',
  lineHeight: '1.6em',
  p: {
    color: 'black'
  },
  button: {
    width: '20%'
  }
}))

export default InvalidTimeSlot
