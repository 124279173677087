import styled from '@emotion/styled'
import { IconType } from 'react-icons'

interface Props {
  type?: string
  value?: string
  inputName?: string
  icon?: React.ReactNode
  name?: string
  inputProps?: any
  hasValue?: boolean
  HasValueIcon: IconType
  onContainerClick?: () => void
}

const Input = styled.input((props: any) => ({
  outline: 0,
  border: 0,
  padding: 0,
  flexGrow: 1,
  fontSize: props.theme.fontSizes[1],
  fontWeight: props.theme.fontWeights.light,
  '&.-hasIcon': {
    paddingLeft: '10px'
  }
}))

const Container = styled.div((props: any) => ({
  display: 'flex',
  outline: 0,
  borderRadius: '12px',
  border: `1px solid ${props.theme.colors.lineColor}`,
  padding: '9px 20px 9px 10px',
  alignItems: 'center',
  backgroundColor: 'white',
  cursor: props.hasValue ? 'pointer' : 'unset',
  lineHeight: '30px'
}))

const IconContainer = styled.div((props: any) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: props.theme.colors.primary,
  height: '30px',
  aspectRatio: '1/1',
  borderRadius: '50%',

  '& svg': {
    color: '#fff',
    height: '15px',
    width: '15px'
  }
}))

const MobileLinedInputField = ({
  type,
  value,
  inputName,
  icon,
  name,
  inputProps,
  hasValue,
  HasValueIcon,
  onContainerClick
}: Props) => {
  return (
    <Container
      className={icon ? '-hasIcon' : ''}
      onClick={onContainerClick}
      {...{ hasValue }}
    >
      {icon && <IconContainer>{icon}</IconContainer>}
      <Input
        type={type}
        // defaultValue={value}
        className={icon ? '-hasIcon' : ''}
        data-testid={`${name || ''}Field`}
        onBeforeInput={inputProps && inputProps.onFocus}
        {...inputProps}
      />
      {hasValue && <HasValueIcon />}
    </Container>
  )
}

MobileLinedInputField.defaultProps = {
  type: 'text',
  value: 'Today (09/07/2019)'
}

export default MobileLinedInputField
