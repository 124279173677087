import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { useState, useEffect, useCallback, useRef } from 'react'
import { throttle } from 'lodash'

interface StickyCardProps {
  id?: string
  header?: React.ReactNode
  content?: React.ReactNode
  children?: React.ReactNode
  hasHeaderOffset?: boolean
}

export const StickyCard = ({
  id,
  header,
  content,
  children,
  hasHeaderOffset
}: StickyCardProps) => {
  const [hasShadow, setHasShadow] = useState(false)
  const headerRef = useRef<null | HTMLDivElement>(null)

  const handleScroll = useCallback(() => {
    if (!headerRef?.current) {
      return
    }

    const offsetY = 48
    const marginTop = headerRef.current.getBoundingClientRect().top - offsetY

    if (!hasShadow && marginTop <= 0) {
      setHasShadow(true)
      return
    }

    if (hasShadow && marginTop > 0) {
      setHasShadow(false)
    }
  }, [hasShadow])

  useEffect(() => {
    const onScroll = throttle(handleScroll, 300)

    document.addEventListener('scroll', onScroll)

    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [handleScroll])

  return (
    <Root id={id}>
      <StickyHeader ref={headerRef} hasHeaderOffset={hasHeaderOffset}>
        <HeaderWrapper hasHeaderOffset={hasHeaderOffset} hasShadow={hasShadow}>
          {header}
        </HeaderWrapper>
      </StickyHeader>
      <ContentContainer hasHeaderOffset={hasHeaderOffset}>
        {content || children}
      </ContentContainer>
    </Root>
  )
}

const Root = styled.div<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  position: 'relative',
  marginTop: '16px',

  [theme.mediaQueries.viewport7]: {
    marginTop: 0
  }
}))

const ContentContainer = styled.div<
  StyledHTMLElement & { hasHeaderOffset?: boolean },
  Required<Theme>
>(({ theme, hasHeaderOffset = false }) => ({
  backgroundColor: 'white',
  padding: hasHeaderOffset ? '16px' : '0 16px 16px 16px',

  [theme.mediaQueries.viewport7]: {
    padding: hasHeaderOffset ? '24px' : '0 24px 24px 24px',
    borderRadius: hasHeaderOffset ? '12px' : '0 0 12px 12px',
    overflow: 'hidden'
  }
}))

const StickyHeader = styled.div<
  StyledHTMLElement & { hasHeaderOffset?: boolean },
  Required<Theme>
>(({ theme, hasHeaderOffset = false }: any) => ({
  position: 'sticky',
  top: '64px',
  left: 0,
  zIndex: 10,

  backgroundColor: '#f5f5f5',

  [theme.mediaQueries.viewport7]: {
    paddingTop: '24px',
    marginBottom: hasHeaderOffset ? '24px' : 0,
    borderRadius: '0 0 12px 12px'
  }
}))

const HeaderWrapper = styled.div<
  StyledHTMLElement & { hasShadow: boolean; hasHeaderOffset?: boolean },
  Required<Theme>
>(({ theme, hasShadow, hasHeaderOffset = false }: any) => ({
  backgroundColor: 'white',
  minHeight: '70px',
  padding: '16px',
  boxShadow: hasShadow && '0px 5px 32px rgba(0, 0, 0, 0.07)',

  [theme.mediaQueries.viewport7]: {
    boxShadow:
      (hasHeaderOffset || hasShadow) && '0px 5px 32px rgba(0, 0, 0, 0.07)',
    padding: '16px 24px',
    borderRadius: hasHeaderOffset ? '12px' : '12px 12px 0 0'
  }
}))
