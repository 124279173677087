import { Dispatch, SetStateAction, useMemo } from 'react'
import { useCart } from 'shop/hooks'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import CenterModal from './CenterModal'
import { Button } from '../Controls'
import useRouter from 'use-react-router'
import ProductInfoItem from './ProductInfoItem'
import { itemScollListShadows, thinScrollbar } from '../common'
import { defaultTheme } from 'shop/theme'
import {
  trackGA4CustomDeeplinkClickBackToOldCart,
  trackGA4CustomDeeplinkClickCreateNewCart
} from 'tracker/GA/custom'

type Props = {
  setShowCartSwitchModal: Dispatch<SetStateAction<boolean>>
  switchCartStore: () => void
  clearCart: () => void
  storeName: string
  cartSwitchError: string | null
}

type CartSwitchGlossary = {
  cartSwitchError: string | null
  switchCartStore: () => void
  clearCart: () => void
  oldStoreName: string
  newStoreName: string
}

type CartSwitchGlossaryResponse = {
  title: string
  message: string
  primaryButton: {
    message: string
    onClick?: () => void
  }
}

const cartSwitchGlossary = ({
  cartSwitchError,
  clearCart,
  switchCartStore,
  oldStoreName,
  newStoreName
}: CartSwitchGlossary): CartSwitchGlossaryResponse => {
  if (!!cartSwitchError) {
    switch (cartSwitchError) {
      default:
        return {
          title: `${newStoreName} is not taking orders`,
          message: `Looks like our ${newStoreName} store is currently unavailable. You many continue browsing at ${newStoreName}, but the following items will still be removed from your previous cart:`,
          primaryButton: {
            message: 'Continue browsing',
            onClick: () => {
              clearCart()
              window.location.reload()
            }
          }
        }
    }
  }

  return {
    title: `Create a new cart?`,
    message: `You already have an active cart on our ${oldStoreName} store. If you continue shopping at ${newStoreName}, the following items will be removed from your previous cart:`,
    primaryButton: {
      message: 'Create new cart',
      onClick: () => {
        trackGA4CustomDeeplinkClickCreateNewCart('slerpGA4Tracker')
        trackGA4CustomDeeplinkClickCreateNewCart('merchantGA4Tracker')
        switchCartStore()
      }
    }
  }
}

const CartSwitchModal = ({
  setShowCartSwitchModal,
  switchCartStore,
  clearCart,
  storeName,
  cartSwitchError
}: Props) => {
  const { cartSession } = useCart()
  const { history } = useRouter()

  const cartItems = useMemo(
    () => cartSession.cart?.cart_items,
    [cartSession.cart?.cart_items]
  )
  const oldStoreName = useMemo(
    () => cartSession.cart?.store.name || '',
    [cartSession.cart?.store.name]
  )

  const handleGoBackClick = () => {
    trackGA4CustomDeeplinkClickBackToOldCart('slerpGA4Tracker')
    trackGA4CustomDeeplinkClickBackToOldCart('merchantGA4Tracker')
    setShowCartSwitchModal(false)
    if (cartSession?.cart?.store.slug) {
      return history.replace(`/store/${cartSession?.cart?.store.slug}`)
    }
    history.goBack()
  }

  if (!cartItems?.length) {
    return <></>
  }

  const cartSwitchContent = cartSwitchGlossary({
    cartSwitchError,
    clearCart,
    switchCartStore,
    oldStoreName,
    newStoreName: storeName
  })

  return (
    <CenterModal
      handleCloseModal={handleGoBackClick}
      zIndex={(defaultTheme.zIndex?.['productModal'] || 200) + 1}
    >
      <Container>
        <Header>{cartSwitchContent.title}</Header>
        <Content>{cartSwitchContent.message}</Content>
        <ItemsContainer isScrollable={cartItems.length > 2}>
          {cartItems?.map((item) => (
            <ProductInfoItem item={item} key={item.id} />
          ))}
        </ItemsContainer>
        <ButtonContainer>
          <Button
            fontWeight={'bold'}
            onClick={cartSwitchContent.primaryButton.onClick}
          >
            {cartSwitchContent.primaryButton.message}
          </Button>
          <Button
            variant='secondary'
            fontWeight={'bold'}
            onClick={handleGoBackClick}
          >
            Go back
          </Button>
        </ButtonContainer>
      </Container>
    </CenterModal>
  )
}

const Container = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    padding: '32px'
  })
)

const Header = styled.h1<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  fontSize: '20px',
  marginBottom: '24px'
}))

const Content = styled.div<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  color: theme.colors['textMute'],
  marginBottom: '40px'
}))

const ItemsContainer = styled.ul<
  StyledHTMLElement & { isScrollable: boolean },
  Required<Theme>
>(({ theme, isScrollable }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '40px 0',
  maxHeight: '175px', // ~70px per item - 2.5 items
  ...(isScrollable
    ? {
        overflowY: 'auto',
        marginRight: '-10px',
        paddingRight: '10px',
        ...(itemScollListShadows(theme) as any),

        [theme['mediaQueries']['viewport7']]: {
          marginRight: '-15px',
          paddingRight: '5px'
        },
        ...(thinScrollbar(theme) as any)
      }
    : {})
}))

const ButtonContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '15px'
  })
)

export default CartSwitchModal
