import Tracker from 'tracker'
import ReactGA4 from 'react-ga4'
import { trackUserActionsFBPixel } from 'tracker'
import { useEffect } from 'react'

/** Check to see if any Legacy style Tracking is available */
const includesLegacyTracking = (): boolean => {
  const legacy =
    Tracker.getInstance().fbPixelEnabled ||
    Tracker.getInstance().slerpGA4Enabled ||
    Tracker.getInstance().merchantGA4Enabled ||
    !!Tracker.getInstance().googleConversionId

  return legacy
}

const PageViewTracking = () => {
  if (includesLegacyTracking()) {
    const pathname = window.location.pathname

    // Slightly delay for analytics to load; this is not a foolproof
    // solution though because some pages might take a while to load.
    setTimeout(() => {
      // legacy tracking
      // FB Pixel
      if (Tracker.getInstance().fbPixelEnabled) {
        trackUserActionsFBPixel('PageView')
      }

      // Merchant GA4
      if (Tracker.getInstance().merchantGA4Enabled) {
        ReactGA4.send({
          hitType: 'pageview',
          page: pathname,
          trackerNames: ['merchantGA4Tracking']
        })
      }

      // SLERP GA4
      if (Tracker.getInstance().slerpGA4Enabled) {
        ReactGA4.send({
          hitType: 'pageview',
          page: pathname,
          trackerNames: ['slerpGA4Tracking']
        })
      }
    }, 2000)
  }
}

export const usePageViewTracker = () => {
  // PageView Tracking on load
  useEffect(() => {
    PageViewTracking()
  }, [])
}
