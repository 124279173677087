import styled from '@emotion/styled'
import Spinner from './Spinner'
import Backdrop from 'shop/components/Modal/Backdrop'

interface Props {
  message?: string
}

const SpinnerModal = ({ message }: Props) => {
  return (
    <ModalContainer>
      <Backdrop />
      <Content>
        <Spinner size='32px' color='white' />
        <Message>{message ? message : 'Please wait'}</Message>
      </Content>
    </ModalContainer>
  )
}

const ModalContainer = styled.div(({ theme }: any) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

const Content = styled.div(({ theme }: any) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  position: 'relative',
  zIndex: 50
}))

const Message = styled.p(() => ({
  fontWeight: 'bold',
  color: 'white'
}))

export default SpinnerModal
