import ContentLoader from 'react-content-loader'
import { Header, Row } from './DeliverySummary'

const HeaderLoader = () => (
  <Header>
    <ContentLoader
      speed={2}
      style={{
        width: '50%',
        height: '50px',
        borderRadius: '12px',
        marginTop: '10px'
      }}
    />
  </Header>
)

const RowLoader = () => (
  <Row>
    <ContentLoader
      speed={2}
      style={{
        width: '80%',
        height: '50px',
        borderRadius: '12px',
        marginTop: '10px'
      }}
    />
  </Row>
)

export { HeaderLoader, RowLoader }
