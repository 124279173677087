import styled from '@emotion/styled'
import CenterModal from 'shop/components/Modal/CenterModal'
import { useShop, useModal, useCart } from 'shop/hooks'
import Logo from 'shop/components/NavBar/Logo'
import { Button } from 'shop/components'
import { CartValidation } from 'shop/components/Checkout/types'
import { updateCartTimeslot } from 'shop/components/Checkout/Network'
import { TrackableEvent, trackAction } from 'tracker'
import useRouter from 'use-react-router'
import { slideUp } from '../Shop/commonStyles'

const TimeSlotModal = ({
  nextAvailable,
  isStoreOpen,
  isPreorder
}: CartValidation) => {
  const { config, useShopClient, cartSession } = useShop()
  const { closeModal } = useModal()
  const { loadCart } = useCart()
  const client = useShopClient()
  const { history } = useRouter()

  const { cart, setCart } = cartSession

  const logoUrl = `https://${config.domain}.${config.assetHost}/assets/logo/alternate`
  const returnToShop = () => {
    const event = TrackableEvent.ReturnFromTimeslot
    trackAction(
      {
        category: pageNameHandler(),
        action: event,
        label: 'Timeslot Expiration Modal'
      },
      { event }
    )

    history.push(`/store/${cart?.store?.slug}`)
  }
  const updateTimeSlot = () => {
    if (!cart?.id) return
    const proposedTime = (nextAvailable && nextAvailable.datetime) || null
    const rangeDisplay = (nextAvailable && nextAvailable.range) || null

    updateCartTimeslot(client, cart.id, proposedTime, rangeDisplay)
      .then(async () => {
        const cart = await loadCart()
        setCart(cart)
        const event = TrackableEvent.UpdateTimeslot
        trackAction(
          {
            category: pageNameHandler(),
            action: event,
            label: 'Timeslot Expiration Modal'
          },
          { event }
        )

        closeModal('timeslot')
      })
      .catch(() => {
        // Don't know what to do here - but this looks safe I guess?
        returnToShop()
      })
  }

  const pageNameHandler = () => {
    const pathname = window.location.pathname
    const page = pathname.substring(7, pathname.indexOf('/', 7))

    switch (page) {
      case 'pay':
        return 'Payment Page'

      case 'checkout':
        return 'Checkout Page'

      default:
        return 'Shop Page'
    }
  }

  return (
    <CenterModal canClose={false}>
      <Prompt>
        <LogoContainer>
          <Logo imageUrl={logoUrl} url={'#'} />
        </LogoContainer>
        {!isPreorder &&
        nextAvailable &&
        nextAvailable.datetime &&
        isStoreOpen ? (
          <p data-testid='expired-for-the-day'>
            Unfortunately the timeslot you selected has expired. Would you like
            to order for {nextAvailable.range} instead?
          </p>
        ) : isPreorder ? (
          <p data-testid='expired-indefinitely'>
            Unfortunately the timeslot you selected has expired and there are no
            timeslots available on your selected date. Please return and order
            for another day or when we are open.
          </p>
        ) : (
          <p data-testid='expired-indefinitely'>
            Unfortunately the timeslot you selected has expired and there are no
            timeslots available today. Please return and order for another day
            or when we are open.
          </p>
        )}
        <Actions>
          {!isPreorder &&
          nextAvailable &&
          nextAvailable.datetime &&
          isStoreOpen ? (
            <>
              <Button
                onClick={updateTimeSlot}
                testId='change-timeslot-and-continue'
              >
                {' '}
                Change timeslot and continue{' '}
              </Button>
              <DividingOr> or </DividingOr>
            </>
          ) : null}
          <Button
            variant={'secondary'}
            onClick={returnToShop}
            testId='back-to-shop'
          >
            {' '}
            Return{' '}
          </Button>
        </Actions>
      </Prompt>
    </CenterModal>
  )
}

const Actions = styled.div(() => ({
  marginTop: '16px',
  width: '100%'
}))

const LogoContainer = styled.div(({ theme }: any) => ({
  height: '96px',
  width: 'auto',

  [theme.mediaQueries.viewport7]: {
    height: '64px',
    width: '64px',
    marginBottom: '16px'
  }
}))

const DividingOr = styled.p(({ theme }: any) => ({
  overflow: 'hidden',
  textAlign: 'center',
  margin: '16px 0',
  color: theme.colors.lightgray,

  '&:before, &:after': {
    backgroundColor: theme.colors.lightgray,
    content: '""',
    display: 'inline-block',
    height: '1px',
    position: 'relative',
    verticalAlign: 'middle',
    width: '50%',
    opacity: 0.4
  },

  '&:before': {
    left: '0.2em',
    marginLeft: '-50%'
  },

  '&:after': {
    right: '0.2em',
    marginRight: '-50%'
  }
}))

const Prompt = styled.div(({ theme }: any) => ({
  animation: `${slideUp} 100ms ease-out`,
  position: 'relative',
  borderRadius: '4px',
  overflowY: 'auto',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  fontFamily: theme.fonts.normal,
  margin: '24px auto',
  maxWidth: '480px',
  width: '100%',
  padding: '16px',
  alignItems: 'center',
  '> p': {
    marginBottom: '16px'
  },
  [theme.mediaQueries.viewport7]: {
    maxWidth: '960px',
    width: '100%',
    height: 'auto',
    padding: '32px',
    marginBottom: '32px'
  }
}))

export default TimeSlotModal
