import { format, isToday, isTomorrow } from 'date-fns'
import { TimeProps } from './commonTypes'
import { Semibold } from '../../common'

export const ScheduledTime = ({ from, to, variant }: TimeProps) => {
  const fromTime = format(from, 'HH:mm')
  const toTime = format(to, 'HH:mm')

  let fulfillmentParticle = ''
  let fulfillmentDay

  if (isToday(from)) {
    fulfillmentDay = 'Today'
  } else if (isTomorrow(from)) {
    fulfillmentDay = 'Tomorrow'
  } else {
    fulfillmentParticle = 'on '
    fulfillmentDay = format(from, 'EEEE do MMMM')
  }

  return (
    <>
      {variant === 'list' ? (
        <span style={{ color: '#595959' }}>
          {fulfillmentParticle}
          {fulfillmentDay}
          <> from </>
          {fromTime} - {toTime}
        </span>
      ) : (
        <>
          {fulfillmentParticle}
          <Semibold>{fulfillmentDay}</Semibold>
          <> from </>
          <Semibold>
            {fromTime} - {toTime}
          </Semibold>
        </>
      )}
    </>
  )
}
