import gql from 'graphql-tag'

// FIXME: This is used for product validations on the cart, we can fix this once
//        the Consumer Cart is being used on the Shop page / Checkout flow.
const buildAvailableStoreProductsQuery = (isPreorder?: boolean) => {
  const storeKeyFilter = isPreorder ? 'storeByPreorderStoreId' : 'store'
  return gql`
    query getAllAvailableStoreProducts($slug: String!) {
      store_variants(
        where: {
          ${storeKeyFilter}: { slug: { _eq: $slug } }
          published_at: { _is_null: false }
          product_variant: {
            product: { archived_at: { _is_null: true } }
            archived_at: { _is_null: true }
            published: { _eq: true }
          }
        }
      ) {
        variant_id
        in_stock
        published_at
        stock_count
        stock_sold
        stock_type
      }
    }
  `
}

const QUERY_GET_PRODUCT = gql`
  query getProduct(
    $fulfillmentType: FulfillmentType
    $fulfillmentDatetime: utc_timestamp
    $productId: uuid
    $productSlug: String
    $storeSlug: String
  ) {
    getProduct(
      fulfillmentType: $fulfillmentType
      fulfillmentDatetime: $fulfillmentDatetime
      productId: $productId
      productSlug: $productSlug
      storeSlug: $storeSlug
    ) {
      additionalImages {
        original
        standard
        thumb
      }
      allergens
      minPrice {
        basePrice
      }
      startPrice {
        basePrice
      }
      calorieData {
        caloriesPerServing
      }
      defaultVariantId
      description
      seoDescription
      id
      image {
        original
        standard
        thumb
      }
      inStock
      limit
      modifierGroups {
        id
        minimum
        modifiers {
          id
          inStock
          name
          allergens
          price {
            basePrice
          }
          restrictions {
            alcoholic
          }
          image {
            original
            standard
            thumb
          }
        }
        maximum
        name
      }
      name
      options {
        name
        values
      }
      variants {
        id
        inStock
        name
        options_allergens
        extraPrice {
          basePrice
        }
        options {
          name
          values
        }
        price {
          basePrice
        }
        restrictions {
          alcoholic
        }
        limit
      }
    }
  }
`

const QUERY_GET_ALL_AVAILABLE_STORE_PRODUCTS =
  buildAvailableStoreProductsQuery()

export {
  QUERY_GET_ALL_AVAILABLE_STORE_PRODUCTS,
  buildAvailableStoreProductsQuery,
  QUERY_GET_PRODUCT
}
