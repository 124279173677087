import { useState, useEffect } from 'react'
import { QUERY_CUSTOMER } from 'shop/client/queries/CustomerQueries'
import {
  QUERY_CUSTOMER_CARDS,
  QUERY_LOYALTY_CARDS
} from 'shop/client/queries/LoyaltyQueries'
import Spinner from 'shop/components/Loader/Spinner'
import AccountLinks from './../components/Account/AccountLinks'
import OrderTable from './../components/Account/OrderTable'
//TODO: hide according to [https://slerpy.atlassian.net/jira/software/c/projects/CHK/boards/31?modal=detail&selectedIssue=CHK-120]
//import UserDetails from './../components/Account/UserDetails'
import CustomerAddresses from './../components/Account/CustomerAddresses'
import { useShop, useReactRouter } from 'shop/hooks'
import styled from '@emotion/styled'
import PersonalInfo from 'shop/components/Account/PersonalInfo'
import { customerClient } from 'shop/components/Account/utils'
import { LoyaltySection } from 'shop/components/Account/Loyalty'

import { usePageViewTracker } from 'tracker'
import {
  LoyaltyCard,
  StampCard
} from 'shop/components/Account/Loyalty/StampList'
import { hideLoyaltyCardSection } from 'shop/components/Account/Loyalty/utils'

const Account = () => {
  const { customerApiKey, setCustomerId, setCustomerApiKey, merchant } =
    useShop()
  const { history } = useReactRouter()
  const client = customerClient(`${customerApiKey}`, merchant?.id || '')
  const customerId = localStorage.getItem('customerId') || ''
  // Track page view
  usePageViewTracker()

  const [customer, setCustomer] = useState({
    first_name: '',
    last_name: '',
    email: '',
    contact_num: '',
    merchant_id: '',
    birthdate: '',
    birthday_update_count: 0,
    marketing_opt_in: false,
    orders: [],
    addresses: [],
    merchant_settings: {
      slug: ''
    }
  })
  const [isLoading, setIsLoading] = useState(true)
  const [customerLoyaltyCards, setCustomerLoyaltyCards] =
    useState<StampCard[]>()
  const [availableLoyaltyCards, setAvailableLoyaltyCards] =
    useState<StampCard[]>()

  const {
    first_name,
    last_name,
    email,
    addresses,
    merchant_id,
    merchant_settings,
    contact_num,
    birthdate,
    birthday_update_count,
    marketing_opt_in
  } = customer

  // Temporarily disable reordering until consumer API adds support for it
  const reordering_enabled = false // merchant_settings.reordering_enabled

  useEffect(() => {
    if (!customerId || customerId === '' || customerApiKey === '') {
      localStorage.removeItem('customerId')
      localStorage.removeItem('customerApiKey')
      localStorage.removeItem('customerName')
      setCustomerId('')
      setCustomerApiKey('')
      history.push('/')
    } else {
      client
        .query({
          query: QUERY_CUSTOMER,
          variables: { customerId }
        })
        .then((results) => {
          const { first_name, last_name } = results.data.customers_by_pk
          setCustomer(results.data.customers_by_pk)
          localStorage.setItem('customerName', `${first_name} ${last_name}`)
          setIsLoading(false)
        })
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    // ref: Loyalty Card flag
    if (merchant_id) {
      client
        .query({
          query: QUERY_LOYALTY_CARDS,
          variables: { merchantId: merchant_id },
          fetchPolicy: 'network-only'
        })
        .then((results) => {
          setAvailableLoyaltyCards(
            results.data.loyalty_cards.map((lc: LoyaltyCard) => ({
              loyalty_card: lc
            }))
          )
        })

      if (customerId) {
        client
          .query({
            query: QUERY_CUSTOMER_CARDS,
            variables: { customerId: customerId, merchantId: merchant_id },
            fetchPolicy: 'network-only'
          })
          .then((results) => {
            setCustomerLoyaltyCards(results.data.customer_loyalty_cards)
          })
      }
    }
  }, [customerId, merchant_id])

  return (
    <>
      {isLoading ? (
        <SpinnerContainer>
          <Spinner size={'72px'} color={'lightgrey'} />
          {'Please wait'}
        </SpinnerContainer>
      ) : (
        <Container>
          <AccountLinks />
          <PersonalInfo
            {...{
              first_name,
              last_name,
              email,
              contact_num,
              merchant_id,
              customerId,
              birthdate,
              birthday_update_count,
              marketing_opt_in
            }}
          />
          <OrderTable
            orders={customer.orders}
            reorderingEnabled={reordering_enabled}
          />
          {/* TODO: hide according to [https://slerpy.atlassian.net/jira/software/c/projects/CHK/boards/31?modal=detail&selectedIssue=CHK-120] */}
          {/* <UserDetails
            {...{
              first_name,
              last_name,
              email,
              merchant_id,
              customerId
            }}
          /> */}
          <CustomerAddresses
            {...{
              customerId,
              addressList: addresses
            }}
          />
          {(!hideLoyaltyCardSection(customerLoyaltyCards) ||
            !hideLoyaltyCardSection(availableLoyaltyCards)) && (
            <LoyaltySection
              customerLoyaltyCards={
                customerLoyaltyCards?.length ? customerLoyaltyCards : []
              }
              loyaltyCards={availableLoyaltyCards || []}
              merchantSlug={merchant_settings.slug}
            />
          )}
        </Container>
      )}
    </>
  )
}

const Container = styled.div(({ theme }: any) => ({
  backgroundColor: theme.colors.white,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  fontFamily: theme.fonts.body.family,
  fontWeight: theme.fonts.body.weight,
  padding: '0 24px',
  maxWidth: '1040px',
  margin: '0 auto 24px',
  width: '100%',
  '> h1': {
    margin: '24px 0',
    fontWeight: theme.fontWeights.bold
  }
}))

const SpinnerContainer = styled.div(({ theme }: any) => ({
  paddingTop: '72px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontWeight: theme.fontWeights.bold,
  color: 'lightgrey'
}))

export default Account
