import { useState, useEffect } from 'react'
import { usePrevious } from 'shop/hooks'
import { differenceInMinutes, isEqual } from 'date-fns'
import { TimeProps } from './commonTypes'
import { timezoneNow } from 'shop/utils'
import { Semibold } from '../../common'

export const AsapTime = ({
  from,
  to,
  variant,
  dateTimeOverride
}: TimeProps) => {
  const oldFrom = usePrevious<Date>(from)
  const oldTo = usePrevious<Date>(to)

  const [fromTime, setFromTime] = useState<number | null>(null)
  const [toTime, setToTime] = useState<number | null>(null)

  useEffect(() => {
    const now = dateTimeOverride || timezoneNow()

    if (!fromTime || (oldFrom && !isEqual(oldFrom, from))) {
      const fromUpdate = differenceInMinutes(from, now, {
        roundingMethod: 'round'
      })

      setFromTime(fromUpdate)
    }

    if (!toTime || (oldTo && !isEqual(oldTo, to))) {
      const toUpdate = differenceInMinutes(to, now, {
        roundingMethod: 'round'
      })

      setToTime(toUpdate)
    }
  }, [from, to, toTime, fromTime, oldFrom, oldTo])

  if (toTime === 0) {
    return (
      <>
        <Semibold>Today ASAP</Semibold>
      </>
    )
  }

  if ((!fromTime && fromTime !== 0) || !toTime) {
    return <></>
  }

  return (
    <>
      {variant === 'list' ? (
        <>
          <span style={{ color: '#595959' }}>
            in {fromTime} - {toTime} mins
          </span>
        </>
      ) : (
        <>
          in{' '}
          <Semibold>
            {fromTime} - {toTime} mins
          </Semibold>
        </>
      )}
    </>
  )
}
