import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import LoginIcon from './LoginIcon'
import { useCart, useModal, useShop } from 'shop/hooks'
import CheckoutButton from './CheckoutButton'
import ViewCartButton from './ViewCartButton'
import { CartItem as CartItemProps } from 'shop/components/Landing/types'
import { computeCartTotal } from 'shop/components/Cart/utils'
import { sum } from 'lodash'
import CartTotal from './CartTotal'

interface Props {
  merchantId: string
}

const CartActions = ({ merchantId }: Props) => {
  const { isStoreLoading } = useShop()
  const { isModalOpen, openModal, portalModal } = useModal()
  const { cartSession } = useCart()
  const { isCartLoading } = cartSession

  const [cartTotal, setCartTotal] = useState<string>('0.00')
  const [totalItems, setTotalItems] = useState(0)

  useEffect(() => {
    let mounted = true
    const { cart, cartId } = cartSession
    if (!cartId || !cart) return

    let cartTotalValue = 0
    if (!!cart.subtotal) {
      cartTotalValue = cart.subtotal
    } else {
      cartTotalValue = computeCartTotal(cart.cart_items)
    }

    if (!mounted) return
    setCartTotal(cartTotalValue.toFixed(2))
    if (cart && cart.cart_items) {
      setTotalItems(
        sum(cart.cart_items.map((item: CartItemProps) => item.quantity))
      )
    }
    const hasInvalidItems: boolean =
      cart &&
      !!cart.cart_items.filter((item: CartItemProps) => !item.is_valid).length

    if (hasInvalidItems) openModal('cart')

    return () => {
      mounted = false
    }
  }, [cartSession, openModal])

  cartSession.onCartLoad = ({ cart }) => {
    if (cart && cart.cart_items) {
      if (!!cart.subtotal) {
        setCartTotal(cart.subtotal.toFixed(2))
      } else {
        setCartTotal(computeCartTotal(cart.cart_items).toFixed(2))
      }
      setTotalItems(cart.cart_items.length)
    }
  }

  const handleOpenCartModal = () => {
    if (isCartLoading) return
    if (!isModalOpen('checkout')) {
      openModal('cart')
    }
  }

  const handleViewCart = () => {
    if (isCartLoading) return
    openModal('cart')
  }

  return !isStoreLoading ? (
    <Actions>
      <CartTotal
        text={cartTotal ? cartTotal : '...'}
        itemCount={totalItems}
        totalAmount={cartTotal || 0}
        handleOpenCartModal={handleOpenCartModal}
      />
      <LoginIcon merchantId={merchantId} />
      {!isModalOpen('checkout') && (
        <ViewCartButton
          onClick={handleViewCart}
          isCartLoading={isCartLoading}
        />
      )}
      {!isModalOpen('checkout') && (
        <CheckoutButton
          cartTotal={parseFloat(cartTotal)}
          isCartLoading={isCartLoading}
        />
      )}
      {isModalOpen('cart') && portalModal('cart')}
      {isModalOpen('checkout') && portalModal('checkout')}
    </Actions>
  ) : null
}

const Actions = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  gap: '12px'
}))

export default CartActions
