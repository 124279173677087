import { useContext } from 'react'
import { FlashContext } from 'shop/hooks'
import AlertMessage from 'shop/components/Cart/AlertMessage'

export const ShopFlashMessages = () => {
  const { inbox } = useContext(FlashContext)
  return (
    <>
      {inbox.map((message) => (
        <AlertMessage
          heading={message.content}
          flash
          type={message.type}
          position={{ desktop: 'top', mobile: 'bottom' }}
        />
      ))}
    </>
  )
}
