import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { commonButton } from './styleMixins'

export const ThemedButton = styled.button<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    ...commonButton(theme),
    cursor: 'pointer'
  })
)
