import { useCallback } from 'react'

import Tracker from './Tracker'
import { TrackableEvent } from './types'

/**
 * React hook for analytics and tracking.
 *
 * Currently, this uses Segment's analytics.js under the hood but the goal is
 * for us to be able to easily port this to other analytics platforms.
 */
const useTracker = () => {
  // NOTE: Might be better to pass the domain, store, and merchant as params
  // to useTracker for reusability but we will have to pass them every single
  // time that we want to track things.
  let domain: string | null = null
  let store: string | null = null

  const root = document.getElementById('root')

  if (root) {
    domain = root.getAttribute('domain')
    store = root.getAttribute('store')
  }

  /**
   * Sends an event to Segment.
   *
   * See https://segment.com/docs/connections/spec/track/ for the spec.
   */
  const trackEvent = useCallback(
    (
      event: TrackableEvent,
      eventProperties?: object,
      options?: object,
      callback?: () => void
    ) => {
      if (domain && store) {
        Tracker.debug(`Sending \`${event}\` event to Segment.`)
        Tracker.track(
          'track',
          event,
          {
            domain,
            store,
            ...eventProperties
          },
          options,
          callback
        )
      } else {
        Tracker.warn(
          'Merchant-related config not set in React root. Event tracking will not work.'
        )
      }
    },
    [domain, store]
  )

  /**
   * Sends a page view to Segment.
   *
   * See https://segment.com/docs/connections/spec/page/ for the spec.
   */
  const trackPageView = useCallback(
    (page: string, pageProperties?: object) => {
      if (domain && store) {
        Tracker.debug(`Sending page view for ${page} to Segment.`)
        Tracker.track('page', page, {
          domain,
          store,
          ...pageProperties
        })
      } else {
        Tracker.warn(
          'Merchant-related config not set in React root. Event tracking will not work.'
        )
      }
    },
    [domain, store]
  )

  /**
   * Sends a user's identity to Segment.
   *
   * See https://segment.com/docs/connections/spec/identify/ for the spec.
   */
  const trackUser = useCallback((id: string, traits?: object) => {
    Tracker.debug(`Sending analytics identity to Segment.`)
    Tracker.track('identify', id, traits)
  }, [])

  return { trackEvent, trackPageView, trackUser }
}

export default useTracker
