import { useHistory } from 'react-router-dom'
import { ReactComponent as MapMarkerInfo } from 'shop/assets/icons/map-marker-info.svg'
import { Button } from '../Controls'
import styled from '@emotion/styled'
import { useEffect } from 'react'
import { TrackableEvent, trackAction } from 'tracker'

const MOVWarning = ({
  amountNeeded,
  storeUrl
}: {
  amountNeeded: number
  storeUrl: string
}) => {
  const history = useHistory()
  const amount = amountNeeded.toLocaleString('en-GB', {
    style: 'currency',
    currency: 'GBP'
  })

  useEffect(() => {
    const action = TrackableEvent.MOVDisplayed
    trackAction(
      { category: 'Checkout', action, label: `${amount}` },
      { event: action }
    )
  }, [])

  return (
    <Container>
      <Text>
        <MapMarkerInfo />
        <span data-testid='mov-warning-text'>
          You need to add <strong>{amount}</strong> to your cart to reach the
          minimum order value
        </span>
      </Text>
      <Button
        testId='continueShoppingButton'
        onClick={() => {
          const event = TrackableEvent.MOVActioned
          trackAction(
            { category: 'Checkout', action: 'Clicked continue shopping' },
            { event }
          )
          history.push(storeUrl)
        }}
      >
        Continue shopping
      </Button>
    </Container>
  )
}

const Container = styled.div(({ theme }: any) => ({
  textAlign: 'center',
  width: '100%',
  padding: 0,
  '> button': {
    width: '100%'
  },
  [theme.mediaQueries.viewport7]: {
    textAlign: 'left',
    borderRadius: '4px',
    border: '0.5px solid #ddd',
    backgroundColor: '#fafafa',
    padding: '24px'
  },
  [theme.mediaQueries.viewport8]: {
    padding: '24px 36px'
  },
  [theme.mediaQueries.viewport10]: {
    padding: '24px 56px'
  }
}))

const Text = styled.p(({ theme }: any) => ({
  fontSize: '14px',
  lineHeight: '16px',
  margin: '0 0 8px',
  '> svg': {
    height: '18px',
    display: 'inline-block',
    verticalAlign: 'text-top',
    marginRight: '4px',
    [theme.mediaQueries.viewport7]: {
      display: 'block'
    }
  },
  [theme.mediaQueries.viewport7]: {
    display: 'flex',
    margin: '0 0 16px',
    fontSize: theme.fontSizes[1],
    '> svg': {
      height: '18px',
      marginRight: '8px'
    }
  }
}))

export default MOVWarning
