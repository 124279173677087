import styled from '@emotion/styled'
import { StyledHTMLElement } from 'shop/theme/types'
import {
  FaChevronDown as DownArrowIcon,
  FaChevronRight as RightArrowIcon
} from 'react-icons/fa'

interface Props {
  children: React.ReactNode
  hasExpanded: boolean
  isSeeMoreEnabled: boolean
  maxHeight: number | undefined
  handleSeeMoreClick: () => void
  containerRef: React.RefObject<HTMLDivElement>
}

const ExpandableContainer = ({
  children,
  hasExpanded,
  isSeeMoreEnabled,
  maxHeight,
  handleSeeMoreClick,
  containerRef
}: Props) => {
  return (
    <>
      <Container
        data-testid='expandable-container'
        ref={containerRef}
        {...{
          hasExpanded,
          isSeeMoreEnabled,
          maxHeight
        }}
      >
        {children}
      </Container>

      {isSeeMoreEnabled && (
        <SeeMoreControl
          data-testid='expand-control'
          onClick={handleSeeMoreClick}
        >
          {hasExpanded ? 'See less' : 'See more'}
          {hasExpanded ? <DownArrowIcon /> : <RightArrowIcon />}
        </SeeMoreControl>
      )}
    </>
  )
}

const Container = styled.div<
  StyledHTMLElement & {
    hasExpanded: boolean
    isSeeMoreEnabled: boolean
    maxHeight?: number
  }
>(({ theme, hasExpanded, isSeeMoreEnabled, maxHeight }) => ({
  position: 'relative',
  transition: 'max-height 0.5s ease-in-out',
  fontSize: '14px',
  // maxHeight values of "fit-content" and "100%" don't animate properly
  maxHeight: hasExpanded ? `${maxHeight || 2000}px` : '100px',
  marginBottom: '12px',
  overflow: 'hidden',

  // Gradient effect to better display overflow of text
  '::after': {
    content: "''",
    position: 'absolute',
    zIndex: 1,
    bottom: 0,
    left: 0,
    pointerEvents: 'none',
    backgroundImage:
      hasExpanded || !isSeeMoreEnabled
        ? ''
        : 'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 46.35%, #FFF 100%)',
    width: '100%',
    height: '4em'
  }
}))

const SeeMoreControl = styled.div<StyledHTMLElement>(() => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 600,
  fontSize: '14px',
  cursor: 'pointer',
  margin: '0',
  gap: '3px',

  '& svg': {
    height: '10px',
    width: '10px'
  }
}))

export default ExpandableContainer
