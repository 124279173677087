import { useState, createContext, useContext, useEffect } from 'react'
import { Fulfillment } from 'shop/types'
import { SlerpCartQueryResponse } from './types'

type SlerpCartState = {
  isSlerpCartLoading: boolean
  setIsSlerpCartLoading: React.Dispatch<React.SetStateAction<boolean>>
  fulfillment: Fulfillment | null
  setSlerpCart: (store: SlerpCartQueryResponse) => void
}

export const SetupSlerpCartStore = (): SlerpCartState => {
  const [fulfillment, setFulfillment] = useState<Fulfillment | null>(null)
  const [isSlerpCartLoading, setIsSlerpCartLoading] = useState<boolean>(true)

  const setSlerpCart = (cart: SlerpCartQueryResponse) => {
    setFulfillment(cart?.fulfillment || null)
  }

  useEffect(() => {
    if (!!fulfillment) setIsSlerpCartLoading(false)
  }, [fulfillment, setIsSlerpCartLoading])

  return {
    isSlerpCartLoading,
    setIsSlerpCartLoading,
    fulfillment,
    setSlerpCart
  }
}

const initialState: SlerpCartState = {
  isSlerpCartLoading: true,
  setIsSlerpCartLoading: () => {},
  fulfillment: null,
  setSlerpCart: () => {}
}

export const SlerpCartContext = createContext<SlerpCartState>(initialState)

export const useSlerpCart = () => {
  return useContext(SlerpCartContext)
}
