import Dropdown from 'shop/components/Inputs/Dropdown'
import ModalWrapper from './ModalWrapper'
import {
  createReadableTimeSlotRangeInput,
  dropdownIsEditable,
  isTimeslotSelected
} from '../utils'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import styled from '@emotion/styled'
import { TimeSlot } from '../types'
import { BsChevronRight as ForwardArrow } from 'react-icons/bs'
import SelectInput from 'shop/components/Inputs/SelectInput'
import SimpleLoader from 'shop/components/Loader/SimpleLoader'
import { CommonOverlayInputHeader, ListItem } from './commonStyles'
import { FiClock as DropdownClock } from 'react-icons/fi'

interface DeliveryTimeSlotProps {
  fulfillmentTime: string | null
  isTimeSlotDropdownOpen: boolean
  isOverlayOpen: boolean
  onClickTimeSlotDropdownHandler: () => void
  handleClickOutsideTimeSlotDropdown: () => void
  selectedTimeSlotHandler: (timeSlot: TimeSlot) => void
  timeSlots: TimeSlot[]
  isExtendedHour: boolean
  isLoading: boolean
}

const DeliveryTimeslot = ({
  fulfillmentTime,
  selectedTimeSlotHandler,
  isLoading,
  timeSlots,
  isTimeSlotDropdownOpen,
  onClickTimeSlotDropdownHandler,
  isOverlayOpen,
  handleClickOutsideTimeSlotDropdown,
  isExtendedHour
}: DeliveryTimeSlotProps) => {
  const timeslotsInput = (
    <SelectInput
      testId='change-timeslot'
      onClick={() => null}
      ToSelectIcon={DropdownClock}
      newFulfillmentModal={true}
      hasSelected={!!fulfillmentTime}
      textFallback={'Time Slot'}
      textContent={createReadableTimeSlotRangeInput(
        timeSlots,
        fulfillmentTime,
        false
      )}
    />
  )

  const availableTimeSlotsDropdownList = () => (
    <TimeSlotItemContainer>
      {timeSlots.map((timeSlot, i) => (
        <TimeSlotListItem
          key={`item-${timeSlot.range}-${i}`}
          onMouseDown={() => selectedTimeSlotHandler(timeSlot)}
          isSelected={
            fulfillmentTime
              ? isTimeslotSelected(timeSlot, fulfillmentTime, isExtendedHour)
              : false
          }
        >
          {timeSlot.range}
        </TimeSlotListItem>
      ))}
    </TimeSlotItemContainer>
  )

  return (
    <SimpleLoader
      isLoading={isLoading}
      additionalStyles={{ borderRadius: '12px' }}
    >
      <Dropdown
        testId='select-timeslot-dropdown'
        textContent={
          createReadableTimeSlotRangeInput(timeSlots, fulfillmentTime, false) ||
          'Time Slot'
        }
        hasSelected={!!fulfillmentTime}
        IconLeft={<DropdownClock />}
        isOpen={isTimeSlotDropdownOpen}
        onClick={onClickTimeSlotDropdownHandler}
        IconRight={<ForwardArrow />}
        canEdit={dropdownIsEditable(timeSlots)}
      >
        <ModalWrapper isOpen={isOverlayOpen}>
          <CommonOverlayInputHeader
            onBackClick={handleClickOutsideTimeSlotDropdown}
            children={timeslotsInput}
          />
          {availableTimeSlotsDropdownList()}
        </ModalWrapper>
      </Dropdown>
    </SimpleLoader>
  )
}

const TimeSlotItemContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    flexWrap: 'wrap',
    margin: '16px 16px 20px',
    gap: '16px',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    [theme.mediaQueries.viewport7]: {
      margin: '32px 32px 36px'
    }
  })
)

const TimeSlotListItem = styled(ListItem)<
  StyledHTMLElement & { isSelected: boolean },
  Required<Theme>
>(({ theme, isSelected }) => ({
  borderRadius: '12px',
  justifyContent: 'center',
  height: 'auto',
  background: isSelected ? theme.colors['primary'] : '#F5F5F5',
  '&:hover': {
    background: isSelected ? theme.colors['primary'] : '#F0F0F0',
    opacity: isSelected ? 0.8 : 1
  },
  // TODO: Move this to ListItem's commonStyles when the "blue" is removed for other inputs.
  '&:active': {
    color: 'black'
  },
  padding: '16px 5px',
  fontSize: '14px',
  color: isSelected ? '#FFF' : 'black',
  [theme.mediaQueries.viewport7]: {
    padding: '16px 20px',
    fontSize: '16px'
  }
}))

export default DeliveryTimeslot
