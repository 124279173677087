import { Helmet } from 'react-helmet'
import { useSlerpMerchant } from 'shop/hooks'

interface Props {
  url: string
}

export const Canonical = (props: Props) => {
  const { isSeoEnabled } = useSlerpMerchant()

  if (!isSeoEnabled) {
    return <></>
  }

  return (
    <Helmet>
      <link rel='canonical' href={props.url} />
    </Helmet>
  )
}
