import { ModifierOptionName } from '../commonStyles'

interface Props {
  values: string[]
}

const OptionV2 = ({ values }: Props) => {
  return (
    <>
      {values.map((value, index) => (
        <ModifierOptionName
          key={`${value}-${index}`}
          data-testid='option-name'
          title={value}
        >
          {(!!index ? ', ' : ' ') + value}
        </ModifierOptionName>
      ))}
    </>
  )
}

export default OptionV2
