import styled from '@emotion/styled'

export interface HeroProps {
  title?: string
  subTitle?: string
  logo?: string
  background?: object
}

const Container = styled.div((props: any) => ({
  backgroundColor: props.theme.colors.primary,
  color: 'white'
}))

const Hero = (props: HeroProps) => {
  return (
    <Container>
      <h1>{props.title}</h1>
      <hr />
      <p>This div should be red on theme change</p>
    </Container>
  )
}

export default Hero
