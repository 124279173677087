import { HTMLAttributes, useEffect } from 'react'
import styled from '@emotion/styled'
import Theme from 'shop/theme/types'
import { isMobile, scrollToMissingOption } from '../ProductInfo/utils'

type StyledHTMLElement = React.DetailedHTMLProps<
  HTMLAttributes<HTMLElement>,
  HTMLElement
>

export interface TabListItem {
  id: string
  display: string
  show?: boolean
}

export interface TabBarProps {
  tabs?: TabListItem[]
  currentTab: string
  setCurrentTab: React.Dispatch<string>
  hasFailedAddToCart: boolean
  productScrollRef: React.RefObject<HTMLDivElement>
  missingRequiredSections?: string[]
}

const TabBar = ({
  tabs = [],
  currentTab,
  setCurrentTab,
  hasFailedAddToCart,
  productScrollRef,
  missingRequiredSections
}: TabBarProps) => {
  // When tab values change across different renders, set selected tab to the first
  useEffect(() => {
    // set to first tab showing
    setCurrentTab(tabs.find((tab) => tab.show === true)?.id || tabs[0].id)

    return () => {
      // reset back to the first tab for other renders
      setCurrentTab(tabs[0].id)
    }
  }, [tabs, setCurrentTab])

  useEffect(() => {
    // on tab change scroll to any missing option
    // else scroll to top of tab page
    if (
      hasFailedAddToCart &&
      currentTab === 'select' &&
      missingRequiredSections
    ) {
      scrollToMissingOption(productScrollRef, missingRequiredSections[0], 16)
    } else {
      const mobile = isMobile()
      const modalContainer = document.getElementById('product-modal-container')
      const imagesContainer = document.getElementById('product-modal-images')
      const scrollableContainer = mobile
        ? modalContainer
        : productScrollRef?.current
      const scrollPosition = mobile ? imagesContainer?.clientHeight || 0 : 0
      if (
        typeof scrollableContainer?.scrollTo === 'function' &&
        scrollableContainer?.scrollTop > scrollPosition
      )
        scrollableContainer?.scrollTo({
          behavior: 'smooth',
          top: scrollPosition
        })
    }
  }, [currentTab])

  const onlyOneTabToShow = tabs.filter((tab) => tab.show).length === 1

  return !onlyOneTabToShow ? (
    <nav>
      <TabBarContainer>
        {tabs.map(
          (tab: TabListItem) =>
            tab.show && (
              <li
                className={currentTab === tab.id ? 'active' : ''}
                key={tab.id}
              >
                <button onClick={() => setCurrentTab(tab.id)}>
                  {tab.display}
                </button>
              </li>
            )
        )}
      </TabBarContainer>
    </nav>
  ) : (
    <></>
  )
}

const TabBarContainer = styled.ul<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    width: '100%',
    listStyle: 'none',
    padding: '0 0 5px 0',
    overflow: 'auto',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    marginBottom: 0,

    '& li': {
      display: 'inline-block',
      position: 'relative',
      padding: '10px 20px',

      '& button': {
        background: 'none',
        border: 'none',
        font: 'inherit',
        display: 'block',
        textDecoration: 'none',
        color: '#8C8CA1',
        cursor: 'pointer'
      },

      '&.active': {
        '& button': {
          color: 'black'
        },
        // Black line for active tab
        '&:after': {
          content: '""',
          width: '100%',
          height: '3px',
          backgroundColor: '#000',
          display: 'inline-block',
          position: 'absolute',
          bottom: '-2px',
          zIndex: 2,
          left: 0
        }
      }
    },
    // Grey line that spans across the page as a line break
    // set to sit behind any scrollbars that might appear
    '&:after': {
      content: '""',
      width: '100%',
      height: '1px',
      backgroundColor: '#D2D2D9',
      display: 'inline-block',
      position: 'absolute',
      bottom: '4px',
      zIndex: -1,
      left: 0
    }
  })
)

export default TabBar
