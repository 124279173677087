import { HTMLAttributes } from 'react'
import styled from '@emotion/styled'
import Theme from 'shop/theme/types'

type StyledHTMLElement = React.DetailedHTMLProps<
  HTMLAttributes<HTMLElement>,
  HTMLElement
>
type Props = {
  currentTab: string
  optionSelectComponent: JSX.Element
  descriptionComponent?: JSX.Element
  allergensComponent?: JSX.Element
}

const Content = ({
  currentTab,
  optionSelectComponent,
  descriptionComponent,
  allergensComponent
}: Props) => {
  const tabContent = {
    select: optionSelectComponent,
    description: descriptionComponent,
    allergens: allergensComponent
  }
  return (
    <ContentContainer>
      {Object.entries(tabContent).map(([key, content]) => (
        <TabContentItem show={currentTab === key} key={key}>
          {content}
        </TabContentItem>
      ))}
    </ContentContainer>
  )
}

const ContentContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    marginBottom: 'auto',
    marginRight: `-${theme.space[3] / 2}px`,
    [theme.mediaQueries.viewport7]: {
      marginRight: `-${theme.space[4] / 2}px`
    }
  })
)

const TabContentItem = styled.div<
  StyledHTMLElement & { show: boolean },
  Required<Theme>
>(({ theme, show }) => ({
  display: show ? 'unset' : 'none'
}))

export default Content
