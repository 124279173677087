import gql from 'graphql-tag'

export const QUERY_GET_AVAILABLE_DATES = gql`
  query getAvailableDates(
    $merchantId: uuid!
    $fulfillmentType: String!
    $startDate: String!
    $lat: Float
    $lng: Float
  ) {
    dates: availableDays(
      merchantId: $merchantId
      fulfillmentType: $fulfillmentType
      startDate: $startDate
      lat: $lat
      lng: $lng
    )
  }
`
