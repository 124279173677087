import { useState, createContext, useContext } from 'react'
import { SlerpMerchantQueryResponse } from './types'

type SlerpMerchantState = {
  isSeoEnabled: boolean
  tagline: string | null
  setSlerpMerchant: (merchant: SlerpMerchantQueryResponse) => void
}

export const SetupSlerpMerchant = (): SlerpMerchantState => {
  const [isSeoEnabled, setIsSeoEnabled] = useState<boolean>(false)
  const [tagline, setTagline] = useState<string | null>(null)
  const [seoDescription, setSeoDescription] = useState<string | null>(null)

  const setSlerpMerchant = (merchant: SlerpMerchantQueryResponse) => {
    setIsSeoEnabled(Boolean(merchant?.isSeoEnabled))
    setTagline(merchant?.tagline)
    setSeoDescription(merchant?.seoDescription)
  }

  return {
    tagline,
    isSeoEnabled,
    seoDescription,
    setSlerpMerchant
  }
}

const initialState: SlerpMerchantState = {
  isSeoEnabled: false,
  tagline: null,
  seoDescription: null,
  setSlerpMerchant: () => {}
}

export const SlerpMerchantContext =
  createContext<SlerpMerchantState>(initialState)

export const useSlerpMerchant = () => {
  return useContext(SlerpMerchantContext)
}
