import React, { useEffect, useContext, useState } from 'react'
import { useModal, useConsumerCart } from 'shop/hooks'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { FulfillmentProps } from '../Landing/types'
import { LandingContext } from '../Landing/LandingContext'
import { Button } from 'shop/components'
import posed from 'react-pose'
import { dateString } from '../Landing/utils'
import { ConsumerCartPayload } from 'shop/types/cart'
import { fixFulfillmentTime } from 'shop/utils'
import { formatTableName } from './utils'

const FulfillmentOptions = (props: FulfillmentProps) => {
  const landingContext = useContext(LandingContext)
  const history = useHistory()
  const [buttonText, setButtonText] = useState('Continue')

  const {
    fulfillmentDate,
    setFulfillmentType,
    fulfillmentTime,
    currentStore,
    inModal,
    orderAgainCartId
  } = landingContext

  const [selectedTable, setSelectedTable] = useState<number | string | null>(
    null
  )

  const { closeModal } = useModal()
  const { initConsumerCart } = useConsumerCart()

  useEffect(() => {
    const defaultFulfillment = 'pickup'
    setFulfillmentType(defaultFulfillment)
  }, [setFulfillmentType])

  // It's fairly impossible to select options with stores
  // therefore render an empty form
  if (!currentStore) return <></>

  const createConsumerCartParams = (): ConsumerCartPayload => {
    const tableSelected = hasTableChoices
      ? selectedTable
      : formatTableName(selectedTable)

    return {
      storeId: currentStore && currentStore.id,
      fulfillmentDate: fulfillmentDate && dateString(fulfillmentDate),
      fulfillmentTime:
        fulfillmentTime &&
        fixFulfillmentTime(fulfillmentTime, { consumerApi: true }),
      fulfillmentType: 'ORDER_AT_TABLE',
      ...(!!tableSelected && { tableNumber: tableSelected.toString() })
    }
  }

  const cartParamsValid = (): boolean => {
    const params = createConsumerCartParams()
    if (
      params.storeId &&
      (params.fulfillmentTime || params.fulfillmentTime === null) &&
      selectedTable !== null
    ) {
      return true
    }
    return false
  }

  const handleInitCart = async () => {
    setButtonText('Please wait...')
    initConsumerCart({ variables: createConsumerCartParams() })
      .then(() => {
        history.push(`/store/${currentStore.slug}`)
      })
      .finally(() => orderAgainCartId && closeModal('fulfillment'))
  }

  const handleTableItemClick = (key: number | string) => {
    setSelectedTable(key)
  }

  const tableCount = currentStore.settings?.table_count || 0
  const tableChoices =
    (currentStore.settings?.table_choices &&
      currentStore.settings?.table_choices?.split(',')) ||
    []
  const hasTableChoices = tableChoices.length > 0 ? true : false
  const allowCounterPickup =
    currentStore.settings?.table_allow_counter_pickup || false

  const PredefinedTables = () => {
    return (
      <>
        {Array.from(Array(Number(tableCount)).keys()).map((key) => (
          <TableItem
            key={key}
            data-testid={`tableNumber${key + 1}`}
            onClick={() => {
              handleTableItemClick(key)
            }}
            className={selectedTable === key ? '-active' : ''}
          >
            <h4>{key + 1}</h4>
          </TableItem>
        ))}
      </>
    )
  }

  const DefinedTables = () => {
    return (
      <>
        {tableChoices.map((key) => (
          <TableItem
            key={key}
            data-testid={`tableNumber-${key}`}
            onClick={() => {
              handleTableItemClick(key)
            }}
            className={selectedTable === key ? '-active' : ''}
          >
            <h4>{key}</h4>
          </TableItem>
        ))}
      </>
    )
  }

  const TableChoices = () => {
    return hasTableChoices ? <DefinedTables /> : <PredefinedTables />
  }

  return (
    <>
      <TableList pose={true}>
        {allowCounterPickup && (
          <TableItem
            key={100000}
            onClick={() => {
              handleTableItemClick(100000)
            }}
            className={selectedTable === 100000 ? '-active' : ''}
          >
            <h4>
              {currentStore?.settings?.table_counter_pickup_message ||
                'Pick-up at counter'}
            </h4>
          </TableItem>
        )}
        <TableChoices />
      </TableList>

      {cartParamsValid() && currentStore && (
        <Actions {...{ inModal }}>
          <Button
            onClick={handleInitCart}
            testId={'continueButton'}
            disabled={!cartParamsValid()}
          >
            {buttonText}
          </Button>
        </Actions>
      )}
    </>
  )
}

const Actions = styled.div(({ theme, inModal }: any) => ({
  position: inModal ? 'absolute' : 'fixed',
  width: inModal ? '100%' : 'unset',
  bottom: '0',
  left: '0',
  right: '0',
  background: 'white',
  padding: '16px 24px',
  display: 'flex',
  zIndex: 100,
  margin: '24px 0 0',
  boxShadow: '0px -4px 8px rgba(0, 0, 0, 0.08)',
  [theme.mediaQueries.viewport7]: {
    width: inModal ? '100%' : '45%',
    padding: '16px 48px'
  },
  [theme.mediaQueries.viewport9]: {
    width: inModal ? '100%' : '40%',
    padding: '16px 56px'
  }
}))

const AnimatedList = posed.ul({
  open: {
    opacity: 1,
    delay: 500,
    delayChildren: 200,
    staggerChildren: 100
  },
  closed: {
    opacity: 0,
    delaty: 500,
    transition: { duration: 500 }
  }
})

const AnimatedItem = posed.li({
  open: { y: 0, opacity: 1 },
  closed: { y: 20, opacity: 0 }
})

const TableList = styled(AnimatedList)(({ theme }: any) => ({
  marginTop: `${theme.space[4]}px`,
  marginBottom: '36px',
  maxHeight: '560px',
  overflowY: 'auto',
  a: {
    color: theme.colors.primary,
    textDecoration: 'none'
  },
  '& > li:last-child': {
    marginBottom: '24px'
  }
}))

const TableItem = styled(AnimatedItem)(({ theme }: any) => ({
  display: 'flex',
  justifyContent: 'space-between',
  border: `1px dashed transparent`,
  borderBottomColor: `${theme.colors.lineColor}`,
  alignItems: 'center',
  padding: '16px',
  cursor: 'pointer',
  textAlign: 'left',
  transition: 'background 150ms ease-in',
  p: {
    marginBottom: 0
  },
  h4: {
    textAlign: 'left'
  },
  '& + li': {
    marginTop: '8px'
  },
  '&.-active, &:hover': {
    border: '1px solid #cecece',
    borderRadius: '4px',
    background: '#f7f7f7'
  },
  [theme.mediaQueries.viewport7]: {
    padding: '16px',
    borderRadius: '8px'
  }
}))

export default FulfillmentOptions
