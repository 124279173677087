import React, { useCallback, useEffect, useState } from 'react'

import {
  useCart,
  useMerchantStore,
  useShop,
  useQueryParams,
  useReactRouter
} from 'shop/hooks'
import { LoaderProps } from './types'
import {
  DELIVERY_FULFILLMENT,
  FulfillmentType,
  ORDER_AT_TABLE_FULFILLMENT
} from 'shop/types'
import { createDefaultSlerpCartParams } from 'shop/utils'
import { useConsumerCart } from 'shop/hooks/useConsumerCart'
import CartSwitchModal from 'shop/components/Modal/CartSwitchModal'
import { isStoreSwitched } from 'shop/components/Cart/utils'

export const CartLoader = ({
  isParentLoaded = true,
  children
}: LoaderProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const { loadCart, cartId, updateCartId } = useCart()
  const {
    initConsumerCart,
    cartError,
    setCartError,
    switchConsumerCartStore,
    clearConsumerCart
  } = useConsumerCart()
  const { cartSession, allStores, merchant, config } = useShop()
  const { isCartLoading, setIsCartLoading } = cartSession
  const {
    storeName,
    defaultFulfillmentType,
    deliveryFulfillment,
    pickupFulfillment,
    hasMerchantStoreLoaded
  } = useMerchantStore()
  const [showCartSwitchModal, setShowCartSwitchModal] = useState(false)
  const [cartSwitchError, setCartSwitchError] = useState<string | null>(null)

  const { getQueryParam, deleteQueryParam } = useQueryParams()

  const queryParamFulfillmentType = getQueryParam('fulfillment_type')

  const { match } = useReactRouter()

  const switchCartStore = useCallback(() => {
    const store = allStores.find((store) => store.name === storeName)
    if (!!store) {
      setIsCartLoading(true)
      setIsLoading(true)
      switchConsumerCartStore(store.id)
        .then(async ({ createCart }) => {
          if (!!createCart.errors.length) {
            setCartSwitchError(createCart.errors[0].message)
            return
          }
          const newCartId = createCart.cart.id
          await updateCartId(newCartId, merchant.id, merchant.slug)
          await loadCart()
          setShowCartSwitchModal(false)
        })
        .catch(() => {
          console.error('failed to switch carts')
        })
        .finally(() => {
          setIsLoading(false)
          setIsCartLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStores, storeName, merchant?.id, merchant?.slug])

  const clearCart = () => {
    clearConsumerCart()
    localStorage.removeItem('cartId')
    localStorage.removeItem(config.domain)
  }

  /** Gets cart on init if there is a cart id */
  useEffect(() => {
    // Can't switch cart store without store details
    if (cartId && !!allStores.length && hasMerchantStoreLoaded) {
      const loadCartFromId = async () => {
        if (isLoading) return
        setIsLoading(true)
        try {
          await loadCart().then((cart) => {
            // Deeplink change store and cart
            if (isStoreSwitched(match.params['slug'], cart)) {
              // just switch carts if the user has no items in their old cart
              if (!cart?.cart_items.length) {
                switchCartStore()
              } else {
                setShowCartSwitchModal(true)
                return
              }
            }

            // If our loaded cart does not match the query params, remove them to avoid user confusion.
            if (
              cart?.fulfillment_type.toUpperCase() !==
              queryParamFulfillmentType.toUpperCase()
            ) {
              deleteQueryParam('fulfillment_type')
            }
          })
          setIsLoading(false)
        } catch (err) {
          setIsLoading(false)
          console.log(err)
        }
      }
      loadCartFromId()
    }

    // [loadCart] causes endless api calls
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartId, allStores.length, hasMerchantStoreLoaded])

  /** Creates cart on init with default parameters if there is no cart id or previous cart error */
  useEffect(() => {
    if (cartId || cartError) return

    if (!!allStores.length && hasMerchantStoreLoaded) {
      if (isLoading) return
      const initFulfillmentType: FulfillmentType =
        (queryParamFulfillmentType.toUpperCase() as FulfillmentType) ||
        defaultFulfillmentType

      // Table orders should redirect to landing page so return early,
      // they should ignore any query params too.
      if (defaultFulfillmentType === ORDER_AT_TABLE_FULFILLMENT) {
        setIsCartLoading(false)
        return
      }

      const store = allStores.find((store) => store.name === storeName)

      // if store exists but is not open, then do not create cart
      if (!store || !store?.is_open) {
        setIsCartLoading(false)
        return
      }

      const storeId = store.id

      const defaultFulfillmentInfo =
        initFulfillmentType === DELIVERY_FULFILLMENT
          ? deliveryFulfillment
          : pickupFulfillment

      // If there is neither delivery or pickup default fulfillments show generic error to take user back to landing
      if (!deliveryFulfillment && !pickupFulfillment) {
        setCartError('no_default_fulfillments')
        return
      }

      // If fulfillment info is null then store is not accepting orders
      if (!defaultFulfillmentInfo) {
        setCartError(
          `invalid_fulfillment_type_${initFulfillmentType.toLowerCase()}`
        )
        setIsCartLoading(false)
        return
      }

      const slerpCartParams = createDefaultSlerpCartParams(
        storeId,
        initFulfillmentType,
        defaultFulfillmentInfo
      )
      if (!slerpCartParams) {
        setIsCartLoading(false)
        return
      }

      // Create cart using consumer API
      initConsumerCart({ variables: slerpCartParams })
        .then((res) => {
          if (!res) {
            return
          }

          const { cart } = res || {}

          if (!cart) {
            return
          }

          return loadCart()
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartId, allStores, hasMerchantStoreLoaded])

  return (
    <React.Fragment>
      {showCartSwitchModal && (
        <CartSwitchModal
          setShowCartSwitchModal={setShowCartSwitchModal}
          switchCartStore={switchCartStore}
          clearCart={clearCart}
          storeName={storeName || ''}
          cartSwitchError={cartSwitchError}
        />
      )}
      {children(!isCartLoading && !isLoading && isParentLoaded)}
    </React.Fragment>
  )
}
