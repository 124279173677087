import { ShopConfig } from 'shop/types'

const root = document.getElementById('root')

const config: ShopConfig = {
  apiKey: (root && root.getAttribute('apiKey')) || '',
  domain: (root && root.getAttribute('domain')) || '',
  store: (root && root.getAttribute('store')) || '',
  path: (root && root.getAttribute('path')) || '/order',
  theme: JSON.parse((root && root.getAttribute('theme')) || '{}'),
  assetHost:
    (root && root.getAttribute('asset-host')) ||
    process.env.REACT_APP_ASSET_HOST ||
    'slerp.com',
  consumerApiHost: (root && root.getAttribute('consumer-api-host')) || ''
}

export default config
