import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { Allergens, Description } from '../'

interface Props {
  description?: string
  calorieData?: number
  allergens?: string[]
  forceShowDescription?: boolean
}

const ProductDetails = ({
  description = '',
  calorieData,
  allergens,
  forceShowDescription = false
}: Props) => {
  const noDetails = !description && !calorieData && !allergens?.length
  if (noDetails) return <></>

  return (
    <Container data-testid='product-details'>
      {!!calorieData && (
        <CalorieContainer>
          {calorieData}kcal<span>*Adults need around 2000kcal a day</span>
        </CalorieContainer>
      )}
      {!!description && (
        <Description
          description={description}
          forceShowDescription={forceShowDescription}
        />
      )}
      {!!allergens?.length && <Allergens allergens={allergens} />}
      {/* when we force show, it means nothing below, so no need for a divider */}
      {!forceShowDescription && <Divider />}
    </Container>
  )
}

const Divider = styled.hr<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  border: 0,
  margin: 0,
  padding: 0,
  borderBottom: '2px solid #F0F0F0'
}))

const Container = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    padding: '0 0 24px'
  })
)

const CalorieContainer = styled.p<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    margin: '0 0 24px',
    color: theme.colors['textMute'],
    fontSize: '14px',

    '& span': {
      fontSize: '12px',
      fontStyle: 'italic',
      marginLeft: '8px'
    }
  })
)

export default ProductDetails
