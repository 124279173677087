import { createContext, useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

type QueryParamsState = {
  searchQueryParams: URLSearchParams
}

export const SetupQueryParamsContext = (): QueryParamsState => {
  const { search } = useLocation()

  const searchQueryParams = useMemo(() => new URLSearchParams(search), [search])

  return {
    searchQueryParams
  }
}

const initialState: QueryParamsState = {
  searchQueryParams: new URLSearchParams('')
}

export const QueryParamsContext = createContext<QueryParamsState>(initialState)

export const useQueryParamsContext = () => {
  return useContext(QueryParamsContext)
}
