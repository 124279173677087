import styled from '@emotion/styled'

type Props = {
  isOptedIn: boolean
  onChange: () => void
}

const CustomerOptIn = ({ isOptedIn, onChange }: Props) => {
  return (
    <Container>
      <input
        type='checkbox'
        id='customer-details-disclosure'
        data-testid='customer-details-disclosure'
        defaultChecked={isOptedIn}
        onChange={onChange}
        name='details_disclosed'
      />
      <Label htmlFor='customer-details-disclosure'>
        I would like to opt in to receive exclusive offers, discounts and news.
      </Label>
    </Container>
  )
}

const Container = styled.div(() => ({
  display: 'flex',
  gap: '5px',
  marginTop: '8px',
  marginBottom: '16px',
  alignItems: 'center',

  '& >input': {
    cursor: 'pointer'
  }
}))

const Label = styled.label(({ theme }: any) => ({
  fontWeight: theme.fontWeights.bold,
  fontSize: theme.fontSizes[0],
  marginLeft: '4px'
}))

export default CustomerOptIn
