import styled from '@emotion/styled'
import StampList, { StampCard } from './StampList'

type Props = {
  loyaltyCards: StampCard[]
  customerLoyaltyCards: StampCard[]
  merchantSlug: string
}

const LoyaltySection = ({
  customerLoyaltyCards,
  loyaltyCards,
  merchantSlug
}: Props) => {
  const ownedLoyaltyCardIds = customerLoyaltyCards
    .filter(({ loyalty_card, redeemed }) => !redeemed)
    .map(({ loyalty_card }) => loyalty_card.loyalty_card_id)
  const inactiveLoyaltyCards = loyaltyCards.filter(({ loyalty_card }) => {
    return !ownedLoyaltyCardIds.includes(loyalty_card.loyalty_card_id)
  })

  return (
    <Container>
      <h3>Earn Stamps</h3>
      <StampList
        loyaltyCards={[...customerLoyaltyCards, ...inactiveLoyaltyCards]}
        merchantSlug={merchantSlug}
      />
    </Container>
  )
}

const Container = styled.div(({ theme }: any) => ({
  '& > h3': {
    paddingBottom: '16px'
  }
}))

export default LoyaltySection
