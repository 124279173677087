import styled from '@emotion/styled'
import { useState } from 'react'
import { Button } from 'shop/components'
import FormInput from 'shop/components/Inputs/FormInput'
import { Medium } from 'shop/components/common'
import { StyledHTMLElement } from 'shop/theme/types'
import { SendAgain, StyledMailIcon } from 'shop/components/Shop/commonStyles'
import { isValidEmail } from 'shop/utils'
import { useHistory, useLocation } from 'react-router-dom'
import { Container, Content, Subheading } from './commonStyles'
import { useMutation } from '@apollo/react-hooks'
import { MUTATE_REQUEST_PASSWORD_RESET } from 'shop/client'
import { usePageViewTracker } from 'tracker'
import { useModal } from 'shop/hooks'

export const ForgotPassword = () => {
  const [email, setEmail] = useState<string>('')
  const [submitted, setSubmitted] = useState<boolean>(false)
  const label: string = 'Email'
  const history = useHistory()
  const location = useLocation()
  const { openModal } = useModal()

  usePageViewTracker()

  const validEmail = isValidEmail(email)

  const [forgotPassword] = useMutation(MUTATE_REQUEST_PASSWORD_RESET, {
    context: { clientName: 'consumerApi' }
  })

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value.toLowerCase())
  }

  const requestPasswordReset = () => {
    // Show success message regardless of outcome
    forgotPassword({ variables: { email } })
      .then(() => {
        setSubmitted(true)
      })
      .catch(() => {
        setSubmitted(true)
      })
  }

  const onSubmit = (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault()
    requestPasswordReset()
  }

  const handleNavigateToLogin = () => {
    if (location?.state?.from) {
      const { from } = location.state
      openModal('login')
      history.replace(from, {})
    } else {
      openModal('login')
      history.push('/')
    }
  }

  return (
    <>
      <Content>
        {submitted ? (
          <>
            <h2>Check your email</h2>
            <Subheading>
              If an account is linked to <Medium>{email}</Medium>, we have sent
              a password reset link to that address. Please check your inbox and
              follow the instructions in the email. Don't forget to check your
              spam folder!
            </Subheading>
            <Container>
              <StyledMailIcon />
              <Button
                fontColor={'#8B8B8B'}
                fontWeight={'normal'}
                variant={'secondary'}
                onClick={requestPasswordReset}
              >
                Didn't receive the email? <SendAgain>Send again</SendAgain>
              </Button>
            </Container>
          </>
        ) : (
          <>
            <h2>Forgot Password</h2>
            <Subheading>
              Please enter your email address and we will send you an email with
              a link to reset your password
            </Subheading>
            <form onSubmit={onSubmit}>
              <Container>
                <FormInput
                  showError={false}
                  value={email}
                  labelText={label}
                  onChange={onChange}
                  autoComplete='email'
                  type='email'
                  inputMode='email'
                />
                <Button
                  data-testid='continue'
                  type='submit'
                  disabled={!email || !validEmail}
                >
                  Continue
                </Button>
              </Container>
              <BackToLogin onClick={handleNavigateToLogin}>
                Back to Log in
              </BackToLogin>
            </form>
          </>
        )}
      </Content>
    </>
  )
}

const BackToLogin = styled.div<StyledHTMLElement>(() => ({
  width: 'auto',
  margin: '0 auto',
  cursor: 'pointer',
  fontSize: '16px',
  justifyContent: 'center',
  marginTop: '16px',
  display: 'flex',
  fontWeight: 500
}))
