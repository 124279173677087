import React from 'react'
import styled from '@emotion/styled'

interface Props
  extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  label: string
  span?: string
  showError?: boolean
  important?: boolean
  testId?: string
}

const Container = styled.div(() => ({
  position: 'relative',
  marginBottom: '16px'
}))

const Input = styled.input((props: any) => ({
  width: '100%',
  height: '40px',
  fontSize: props.theme.fontSizes[1],
  fontWeight: props.theme.fontWeights.light,
  padding: '8px',
  outline: 0,
  border: props.showError
    ? `1px solid ${props.theme.colors.state.error[5]}`
    : '1px solid lightgrey'
}))

const Label = styled.label(({ theme }: any) => ({
  fontSize: theme.fontSizes[1],
  color: theme.colors.textBody,
  marginBottom: '8px',
  display: 'block'
}))

const Span = styled.span(({ theme }: any) => ({
  fontSize: theme.fontSizes[0],
  color: theme.colors.textMute
}))

const ImportantMarker = styled.span(({ theme }: any) => ({
  fontSize: theme.fontSizes[1],
  color: theme.colors.state.error[5]
}))

const AddressInputFields = (props: Props) => {
  const { label, span, important, testId } = props
  return (
    <Container>
      <Label>
        {label} {important && <ImportantMarker>*</ImportantMarker>}{' '}
        <Span>{span}</Span>
      </Label>
      <Input data-testid={testId} {...props} />
    </Container>
  )
}

export default AddressInputFields
