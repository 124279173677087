import { isSameDay, isYesterday } from 'date-fns'
import { AccountCreationType } from './types'
import { Order } from 'shop/types/cart'
import { parseTimezoneISO } from 'shop/utils'
import { format, isToday, isTomorrow } from 'date-fns'
import { getFulfillmentFlags } from '../Checkout'

export const buildAsapMessage = (order: Order) => {
  const {
    fulfilledAt,
    fulfillment: { orderType }
  } = order

  const isAsap = orderType === 'ASAP'

  if (fulfilledAt || !isAsap) {
    return ''
  }

  return ' (ASAP)'
}

export const buildBannerMessage = (order: Order) => {
  const isFulfilled = Boolean(order.fulfilledAt)
  const { isDelivery } = getFulfillmentFlags(order.fulfillment.type)

  const displayDate = buildCartDate(order)

  if (isFulfilled) {
    return `Your order was ${isDelivery ? 'delivered' : 'picked up'}`
  }

  if (
    !isDelivery &&
    displayDate &&
    !['Today', 'Tomorrow', 'Yesterday'].includes(displayDate)
  ) {
    return 'Pickup on'
  }

  return isDelivery ? 'Estimated arrival' : 'Pickup time'
}

export const buildCartDate = (order: Order): string | null => {
  const {
    fulfilledAt,
    fulfillment: {
      window: { from }
    }
  } = order

  if (!fulfilledAt && !from) return null

  const date = parseTimezoneISO(fulfilledAt || from)

  if (isToday(date)) {
    return 'Today'
  }

  if (isTomorrow(date)) {
    return 'Tomorrow'
  }

  if (isYesterday(date)) {
    return 'Yesterday'
  }

  return format(date, 'dd/MM/yyyy')
}

export const getTimeToDisplay = (order: Order) => {
  const {
    fulfilledAt,
    fulfillment: {
      window: { from, to }
    }
  } = order

  if (fulfilledAt) {
    return format(parseTimezoneISO(fulfilledAt), 'HH:mm')
  }

  if (!from) {
    return null
  }

  if (!to) {
    return format(parseTimezoneISO(from), 'HH:mm')
  }

  return `${format(parseTimezoneISO(from), 'HH:mm')} - ${format(
    parseTimezoneISO(to),
    'HH:mm'
  )}`
}

export const getOrderHeading = (status: string) => {
  if (status.toLowerCase() === 'pending') return 'Your order has been placed'
  return status
    ? `Your order has been ${status.toLowerCase()}`
    : `Your order is pending payment`
}

// Why :c
export const isPreorder = (inserted_at: Date, fulfillment_date: Date | null) =>
  !!fulfillment_date && !isSameDay(fulfillment_date, inserted_at)

export const getOrderProgress = (
  order: Order,
  merchantName: string | null
): { message: string; code: number } => {
  const { status } = order

  const orderStatus = orderStatusProgress(status, merchantName)
  const currentDelivery = order.delivery
  const deliveryStatus = Boolean(currentDelivery)
    ? deliveryStatusProgress(currentDelivery?.status, merchantName)
    : null

  if (!orderStatus) {
    return {
      code: -1,
      message: getOrderHeading(status)
    }
  }

  if (!deliveryStatus || deliveryStatus.code < orderStatus.code) {
    return orderStatus
  }

  return deliveryStatus
}

// Progress bar has only 4 steps
const statusCodeMap = {
  pending: 0,
  accepted: 1,
  searching: 1,
  scheduled: 1,
  picking: 2,
  almost_picking: 2,
  delivering: 3,
  almost_delivering: 3,
  fulfilled: 4
}

const orderStatusProgress = (
  status: string,
  merchantName: string | null
): { message: string; code: number; title: string } => {
  const progressBarStatusMap = {
    pending: {
      code: statusCodeMap.pending,
      message: `Please wait for ${
        merchantName || 'the store'
      } to approve your order`
    },
    accepted: {
      code: statusCodeMap.accepted,
      message: `${merchantName || 'The store'} is preparing your order`
    },
    fulfilled: {
      code: statusCodeMap.fulfilled,
      message: `Enjoy your food!`
    }
  }

  return progressBarStatusMap[status.toLowerCase()] || null
}

const deliveryStatusProgress = (
  status: string | undefined | null,
  merchantName: string | null
): { message: string; code: number } | null => {
  if (!status) {
    return null
  }

  const progressBarStatusMap = {
    searching: {
      code: statusCodeMap.searching,
      message: `${merchantName || 'The store'} is preparing your order`
    },
    scheduled: {
      code: statusCodeMap.scheduled,
      message: `${merchantName || 'The store'} is preparing your order`
    },
    picking: {
      code: statusCodeMap.picking,
      message: 'Your driver is on their way to collect your order'
    },
    almost_picking: {
      code: statusCodeMap.almost_picking,
      message: 'Your driver is on their way to collect your order'
    },
    delivering: {
      code: statusCodeMap.delivering,
      message: 'Your driver is on the way'
    },
    almost_delivering: {
      code: statusCodeMap.almost_delivering,
      message: 'Your driver is nearly there!'
    }
  }

  return progressBarStatusMap[status] || null
}

type ProgressSchema = {
  code: number
  elements: string[][]
}

export const getProgressBarSchema = (isDelivery: boolean): ProgressSchema[] => {
  const deliverySchema = [
    {
      code: statusCodeMap.pending,
      elements: [['md']]
    },
    {
      code: statusCodeMap.accepted,
      elements: [['lg']]
    },
    {
      code: statusCodeMap.picking,
      elements: [['md']]
    },
    {
      code: statusCodeMap.delivering,
      elements: [['sm']]
    }
  ]

  const pickupSchema = [
    { code: statusCodeMap.pending, elements: [['sm']] },
    { code: statusCodeMap.accepted, elements: [['lg']] }
  ]

  return isDelivery ? deliverySchema : pickupSchema
}

export const getAccountCreationType = (
  merchantHasLoyaltyEnabled: boolean
): AccountCreationType => {
  return merchantHasLoyaltyEnabled
    ? AccountCreationType.Loyalty
    : AccountCreationType.Account
}
