import { Fragment, useState } from 'react'
import SpinnerModal from 'shop/components/Loader/SpinnerModal'
import styled from '@emotion/styled'
import { IoIosArrowRoundForward as ArrowIcon } from 'react-icons/io'
import { useCart, useModal, useShop, useCartValidation } from 'shop/hooks'
import useRouter from 'use-react-router'
import { CartItem } from './../Landing/types'
import { isEmpty } from 'lodash'
import { Button as GenericButton } from 'shop/components/Controls'

export interface Props {
  cartTotal: number
  isCartLoading: boolean
}

type ValidationResults = {
  isCartValid: boolean
  validCartItems: CartItem[]
}

const CheckoutButton = ({ cartTotal, isCartLoading }: Props) => {
  const { cartSession } = useCart()
  const { history } = useRouter()
  const { config, useShopClient } = useShop()
  const client = useShopClient()
  const { openModal, closeModal } = useModal()
  const { validateCart } = useCartValidation()
  const [loadingMessage, setLoadingMessage] = useState('')
  const { cart } = cartSession

  const handleButtonClick = async () => {
    if (isCartLoading) return

    setLoadingMessage('Validating cart')

    if (isEmpty(cart) || !cart) {
      setLoadingMessage('')
      openModal('cart')
      return
    }

    const cartValidation = await validateCart(
      cart,
      cartTotal,
      config,
      client
    ).then((validationResults: ValidationResults) => validationResults)

    const { isCartValid, validCartItems } = cartValidation

    if (!isCartValid) {
      setLoadingMessage('')
      openModal('cart')
    } else {
      checkout(validCartItems)
    }
  }

  const checkout = (cartItems: CartItem[]) => {
    if (isEmpty(cart) || !cart) return

    closeModal('cart')
    return history.push('/checkout')
  }
  return (
    <Fragment>
      {loadingMessage !== '' && <SpinnerModal message={loadingMessage} />}
      <Button
        data-testid='navbarCheckoutButton'
        variant='secondary'
        type='button'
        onClick={handleButtonClick}
        isLoading={isCartLoading}
      >
        <DesktopContent>
          <span>Checkout</span>
          <ArrowIcon />
        </DesktopContent>
        <MobileContent>Pay Now</MobileContent>
      </Button>
    </Fragment>
  )
}

const MobileContent = styled.div(({ theme }: any) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  [theme.mediaQueries.viewport7]: {
    display: 'none'
  }
}))

const DesktopContent = styled.div(({ theme }: any) => ({
  display: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.mediaQueries.viewport7]: {
    display: 'flex'
  }
}))

const Button = styled(GenericButton)(({ theme }: any) => ({
  fontFamily: theme.fonts.heading.family,
  fontSize: theme.fontSizes[0],
  height: '100%',
  color: theme.colors.textBody,
  padding: '0 8px',
  '> div > div > svg': {
    marginRight: '0'
  }
}))

export default CheckoutButton
