import styled from '@emotion/styled'
import { useAppContent, useSlerpMerchant } from 'shop/hooks'

const SplashImage = () => {
  const { splashImage, merchantName } = useAppContent()
  const { tagline } = useSlerpMerchant()

  const altText = tagline ? `${merchantName} - ${tagline}` : merchantName

  return (
    <Container data-testid='splash-image'>
      <Image src={splashImage} alt={altText || ''} />
    </Container>
  )
}

const Image = styled.img(() => ({
  height: '100%',
  width: '100%',
  objectFit: 'cover'
}))

const Container = styled.div(() => ({
  height: '100%'
}))

export default SplashImage
