import { Appearance } from '@stripe/stripe-js'
import { TermsOption } from '@stripe/stripe-js'

const appearance: Appearance = {
  rules: {
    '.Block': { borderRadius: '16px' },
    '.Input': {
      borderColor: '#E7E5E9',
      backgroundColor: '#F9F9F9',
      boxShadow: 'none',
      padding: '14px'
    },
    '.Input:focus': { border: '1px solid #59595A', boxShadow: 'none' },
    '.Tab': { borderRadius: '16px' }
  },
  variables: {
    colorTextPlaceholder: '#A4A4B3',
    spacingTab: '6px',
    colorIconCardCvc: 'transparent',
    spacingGridRow: '14px',
    borderRadius: '12px',
    colorPrimary: 'black'
  }
}

export const termsOptions: TermsOption = {
  bancontact: 'never',
  card: 'never',
  ideal: 'never',
  sepaDebit: 'never',
  sofort: 'never',
  auBecsDebit: 'never',
  usBankAccount: 'never'
}

export default appearance
