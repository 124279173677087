import { isToday } from 'date-fns'
import { CurrentLocationState, ValidStore } from '.'
import env from 'shop/env'

// https://www.geodatasource.com/developers/javascript
export const getDistanceFromLocation = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  unit: string
) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0
  } else {
    const radlat1 = (Math.PI * lat1) / 180
    const radlat2 = (Math.PI * lat2) / 180
    const theta = lon1 - lon2
    const radtheta = (Math.PI * theta) / 180
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
    if (dist > 1) {
      dist = 1
    }
    dist = Math.acos(dist)
    dist = (dist * 180) / Math.PI
    dist = dist * 60 * 1.1515 // distance in miles
    if (unit === 'K') {
      dist = dist * 1.609344 // distance in km
    }
    return dist
  }
}

export const locationInPolygon = (x: number, y: number, vs: any) => {
  let inside = false
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    const xi = vs[i][0]
    const yi = vs[i][1]
    const xj = vs[j][0]
    const yj = vs[j][1]

    const intersect =
      // eslint-disable-next-line no-mixed-operators
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
    if (intersect) inside = !inside
  }

  return inside
}

export const getDeliveryRadius = (
  fulfillmentDate: Date,
  sameDayRadius: number | null,
  preOrderRadius: number | null
) => {
  if (isToday(fulfillmentDate)) return sameDayRadius || 0
  return preOrderRadius || 0
}

export const sortStoresByLocation = (
  storeList: ValidStore[],
  currentLocation: CurrentLocationState
) => {
  return storeList.sort(
    (a, b) =>
      calculateDistanceFromCurrentLocation(a, currentLocation) -
      calculateDistanceFromCurrentLocation(b, currentLocation)
  )
}

export const calculateDistanceFromCurrentLocation = (
  store: ValidStore,
  currentLocation: CurrentLocationState
) => {
  const userLocation =
    currentLocation && currentLocation.latLng
      ? currentLocation.latLng
      : env.DEFAULT_MAP_CENTER
  return (
    getDistanceFromLocation(
      store.address.geom.coordinates[0],
      store.address.geom.coordinates[1],
      userLocation.lat,
      userLocation.lng,
      'M'
    ) || 0
  )
}
