import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

export const useUTMTracker = () => {
  const location = useLocation()

  const parsedParams = queryString.parse(location.search)

  let tracker = null

  // Check if we have the right params then store it
  if (parsedParams) {
    localStorage.setItem('utm', JSON.stringify(parsedParams))
    tracker = parsedParams
    // Check if we have a previously saved utm
  }

  return {
    tracker,
    location
  }
}
