import { RouteComponentProps } from 'react-router-dom'
import ConfirmationPage from 'shop/pages/Order'
import { AppContentLoader, LoaderSwitcher } from 'router/loaders'

export const ConfirmationWrapper = ({
  match
}: RouteComponentProps<{ cart_id: string; payment_id: string }>) => {
  return (
    <AppContentLoader>
      {(isParentLoaded) => (
        <LoaderSwitcher isParentLoaded={isParentLoaded}>
          <ConfirmationPage
            entityId={match.params.cart_id}
            paymentId={match.params.payment_id}
            mode='cart'
          />
        </LoaderSwitcher>
      )}
    </AppContentLoader>
  )
}
