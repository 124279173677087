import { RouteComponentProps } from 'react-router'
import { AppContentLoader, LoaderSwitcher } from 'router/loaders'
import { Purchase } from '../../shop/pages'

export const PurchaseWrapper = ({
  match
}: RouteComponentProps<{ id: string }>) => {
  return (
    <AppContentLoader>
      {(isParentLoaded) => (
        <LoaderSwitcher isParentLoaded={isParentLoaded}>
          <Purchase transactionId={match.params.id} />
        </LoaderSwitcher>
      )}
    </AppContentLoader>
  )
}
