import { useLayoutEffect, useState, RefObject } from 'react'
import useResizeObserver from '@react-hook/resize-observer'

export const useResize = (target: RefObject<HTMLElement>) => {
  const [size, setSize] = useState<DOMRectReadOnly | null>(null)

  useLayoutEffect(() => {
    if (target?.current) {
      setSize(target?.current?.getBoundingClientRect())
    }
  }, [target])

  useResizeObserver(target, (entry) => setSize(entry.contentRect as any))
  return size
}
