import styled from '@emotion/styled'
import { Clear, LineBreak } from '../commonStyles'
import { useState } from 'react'
import { StyledHTMLElement } from 'shop/theme/types'
import { Discount as DiscountType } from 'shop/types/cart'
import { TextInput } from 'shop/components/Inputs/common'
import { Button } from 'shop/components/Controls'
import { useConsumerCart } from 'shop/hooks'
import { DiscountBreakdownItem } from '../CartBreakdown'
import { MdErrorOutline as ErrorIcon } from 'react-icons/md'
import { ErrorMessage } from 'shop/components/Shop/commonStyles'
import { errorGlossary } from './utils'
import {
  DiscountErrorCode,
  findErrorWarning,
  isDiscountErrorCode
} from 'shop/components/Checkout'
import { trackAddDiscountCodeConsumerCart } from 'shop/components/Checkout/tracking/helpers'

interface Props {
  isEditable?: boolean
  discount: DiscountType | null
  hasRewards: boolean
}

const Discount = ({ isEditable = false, discount, hasRewards }: Props) => {
  const { applyDiscountConsumerCart } = useConsumerCart()
  const [isInputFocused, setIsInputFocused] = useState(false)
  const [discountInputValue, setDiscountInputValue] = useState('')
  const [error, setError] = useState<string | null>(null)

  const lineBreakHeight = hasRewards ? '1px' : '2px'

  const onFocus = () => setIsInputFocused(true)
  const onBlur = () => setIsInputFocused(false)
  const onInputClearClick = () => {
    setDiscountInputValue('')
    setError(null)
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (error) setError(null)
    setDiscountInputValue(e.target.value)
  }

  /** Handles discount input on success & hard validation errors */
  const handleApplyDiscount = () => {
    applyDiscountConsumerCart({
      variables: { discountCode: discountInputValue }
    }).then((res) => {
      const { errors } = res || {}
      setDiscountInputValue('')
      if (errors?.length) {
        const errorCode = findErrorWarning(errors, isDiscountErrorCode)
          ?.message as DiscountErrorCode
        setError(errorGlossary(errorCode))
        return
      }
      // Track discount code if successful
      trackAddDiscountCodeConsumerCart(discountInputValue)
    })
  }

  return (
    <Container data-testid='discount-container'>
      <LineBreak height={lineBreakHeight} />
      <Content>
        {isEditable && !discount && (
          <>
            <InputContainer>
              <InputContent>
                <TextInput
                  value={discountInputValue}
                  hasPlaceholderPreText={false}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  isPassword={false}
                  isInputFocused={isInputFocused}
                  onChange={onChange}
                  placeholder='Enter discount code'
                  showError={!!error}
                  autoCapitalize='off'
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleApplyDiscount()
                    }
                  }}
                />
                {discountInputValue && (
                  <Clear top='7px' onClick={onInputClearClick} />
                )}
              </InputContent>
              <Button
                variant='primary'
                width='92px'
                onClick={handleApplyDiscount}
              >
                Apply
              </Button>
            </InputContainer>
            {!!error && (
              <ErrorMessage>
                <ErrorIcon size='14px' />
                {error}
              </ErrorMessage>
            )}
          </>
        )}
        {!!discount && (
          <DiscountBreakdownItem discount={discount} isEditable={isEditable} />
        )}
      </Content>
    </Container>
  )
}

const Container = styled.div<StyledHTMLElement>(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

const Content = styled.div<StyledHTMLElement>(() => ({
  margin: '24px 0'
}))

const InputContainer = styled.div<StyledHTMLElement>(() => ({
  display: 'flex',
  gap: '12px',

  '> button': {
    minWidth: '92px',
    marginLeft: 'auto',
    height: 'unset',
    backgroundColor: '#fff',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#000',
    border: '1px solid #000',
    padding: '7px 0'
  }
}))

const InputContent = styled.div<StyledHTMLElement>(() => ({
  display: 'flex',
  gap: '12px',
  width: '100%',
  position: 'relative',

  '> input': {
    paddingTop: '7px',
    paddingBottom: '7px',
    fontSize: '14px',
    lineHeight: '22px'
  }
}))

export default Discount
