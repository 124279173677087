import React, {
  useState,
  useEffect,
  useCallback,
  HTMLAttributes,
  useMemo,
  SetStateAction
} from 'react'
import styled from '@emotion/styled'
import { PlusIcon, MinusIcon } from 'shop/assets/icons'
import Theme from 'shop/theme/types'
import {
  TrackableEvent,
  trackUserActionsFBPixel,
  trackUserActionsGA4
} from 'tracker'

type StyledHTMLElement = React.DetailedHTMLProps<
  HTMLAttributes<HTMLElement>,
  HTMLElement
>
interface VariantControlType {
  productId: string
  productVariantName: string
  productVariantLimit: number | null
  inStock: boolean
  title?: string
  nextSection?: string
  variantQuantity: number
  setVariantQuantity: React.Dispatch<SetStateAction<number>>
}

const VariantControl = ({
  productId,
  productVariantName,
  productVariantLimit,
  inStock,
  title,
  nextSection,
  variantQuantity,
  setVariantQuantity
}: VariantControlType) => {
  const atMaxQuanity = useMemo(() => {
    if (variantQuantity >= 50) return true // Be reasonable, who orders more than 50 doughnuts at once?
    if (productVariantLimit === null) return false
    if (productVariantLimit > 0 && variantQuantity >= productVariantLimit)
      return true
    return false
  }, [productVariantLimit, variantQuantity])

  // Track Product Quantity changes
  const track = useCallback(
    (newQuantity: number) => {
      const body = {
        category: productVariantName,
        action: TrackableEvent.ProductCustomized,
        label: productVariantName,
        value: newQuantity
      }

      trackUserActionsGA4(body, 'slerpGA4Tracking')

      // legacy tracking
      trackUserActionsFBPixel(TrackableEvent.ProductCustomized, {
        content_name: productVariantName,
        content_type: 'product',
        contents: [{ id: productId, quantity: newQuantity }]
      })
      trackUserActionsGA4(body, 'merchantGA4Tracking')
    },
    [productId, productVariantName]
  )

  //  TODO: Check order limit
  const incrementQuantity = () => {
    if (atMaxQuanity) return

    const newQuantity = variantQuantity + 1
    setVariantQuantity(newQuantity)
    track(newQuantity)
  }

  const decrementQuantity = () => {
    if (variantQuantity === 0) return
    const newQuantity = variantQuantity - 1
    if (newQuantity <= 0) {
      setVariantQuantity(0)
    } else {
      setVariantQuantity(newQuantity)
    }
    track(newQuantity)
  }

  const [active, setActive] = useState('')
  useEffect(() => {
    if (variantQuantity > 0) {
      setActive('active')
    } else {
      setActive('')
    }
  }, [variantQuantity])

  return (
    <Container className={active}>
      <div>
        {title && <Name>{title}</Name>}
        <Quantity>
          <QuantityButton
            onClick={decrementQuantity}
            disabled={variantQuantity === 1 || !inStock || !!nextSection}
            data-testid='decrementButton'
          >
            <MinusIcon />
          </QuantityButton>
          <QuantityLabel data-testid='quantity' {...{ inStock }}>
            {variantQuantity}
          </QuantityLabel>
          <QuantityButton
            data-testid='incrementButton'
            onClick={incrementQuantity}
            disabled={!inStock || atMaxQuanity || !!nextSection}
          >
            <PlusIcon />
          </QuantityButton>
        </Quantity>
      </div>
    </Container>
  )
}

const Container = styled.a(({ theme }: any) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}))

const Quantity = styled.div(({ theme }: any) => ({
  display: 'flex',
  fontWeight: theme.fontWeights.bold,
  fontSize: theme.fontSizes[1]
}))

const QuantityButton = styled.button<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
    backgroundColor: theme.colors['primary'],
    color: theme.colors['white'],
    border: 0,
    cursor: 'pointer',
    padding: 0,
    width: '38px',
    height: '38px',
    borderRadius: '100%',
    '&:disabled': {
      cursor: 'not-allowed',
      opacity: 0.2
    },
    '& > svg': {
      height: '20px',
      width: '20px'
    }
  })
)

const QuantityLabel = styled.h2(({ theme, inStock }: any) => ({
  fontFamily: theme.fonts['heading'].family,
  fontWeight: 'bold',
  fontSize: theme.fontSizes[3],
  padding: `0 ${theme.space[3]}px`,
  margin: 'auto',
  textAlign: 'center',
  opacity: inStock ? 1 : 0.2,
  minWidth: `${theme.fontSizes[3] * 1.5 + theme.space[3] * 2}px`
}))

const Name = styled.h4(({ theme }: any) => ({}))

export default VariantControl
