import { useQuery, useMutation } from '@apollo/react-hooks'
import {
  MUTATION_APPLY_REWARD_DISCOUNT,
  QUERY_GET_CUSTOMER_REWARDS
} from './LoyaltyQueries'
import { Cart } from 'shop/components/Landing/types'
import { FlashContext } from 'shop/hooks'
import { useContext } from 'react'
import { Discount } from 'shop/components/Checkout/types'

export const useRewards = (
  cart: Cart | null,
  customerApiKey: string,
  onClaim: (discount: Discount) => void,
  appliedDiscountCode: string
) => {
  const clientContext = {
    headers: {
      authorization: `Bearer ${customerApiKey}`
    }
  }

  const { pushMessage } = useContext(FlashContext)

  const { data: rewardData, loading: isRetrieving } = useQuery(
    QUERY_GET_CUSTOMER_REWARDS,
    {
      skip: !cart || !customerApiKey,
      variables: {
        customerId: localStorage.getItem('customerId'),
        merchantId: cart?.merchant_id,
        appliedDiscountCode: appliedDiscountCode
      },
      fetchPolicy: 'network-only',
      context: clientContext
    }
  )

  const [mutate, { loading: isMutating }] = useMutation(
    MUTATION_APPLY_REWARD_DISCOUNT,
    {
      context: clientContext
    }
  )

  const claimReward = (customerRewardId: string) => {
    mutate({
      variables: { cartId: cart?.id, customerRewardId: customerRewardId }
    })
      .then((result) => {
        let discountResponse = result.data.applyRewardDiscount
        const { subtotalInfo } = discountResponse
        discountResponse = {
          ...discountResponse,
          subtotal_discount_amount: subtotalInfo?.reduction || 0
        }
        onClaim(discountResponse)
        pushMessage({
          content: 'Succesfully claimed reward',
          timeout: 1000,
          type: 'success'
        })
      })
      .catch((error) => {
        const { graphQLErrors } = error
        const message = graphQLErrors[0]?.message || error.message
        pushMessage({ content: message, timeout: 1000 })
      })
  }

  return {
    customerRewards: rewardData?.customer_rewards || [],
    isProcessingRewards: isRetrieving || isMutating,
    claimReward
  }
}
