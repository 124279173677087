import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'

const RadioInput = styled.input<
  StyledHTMLElement & { disabled: boolean; inputPressed?: boolean },
  Required<Theme>
>(({ theme, disabled, inputPressed }) => ({
  cursor: !disabled ? 'pointer' : 'not-allowed',

  '&[type="radio"]': {
    /* Start of removing native styles */
    webkitAppearance: 'none',
    appearance: 'none',
    // For iOS < 15 to remove gradient background
    backgroundColor: '#fff',
    margin: 0,
    /* End of removing native styles */

    WebkitTapHighlightColor: 'transparent',

    font: 'inherit',
    color: theme.colors?.['primary'],
    width: '20px',
    height: '20px',
    border: `2px solid ${theme.colors?.['primary']}`,
    opacity: !disabled ? 1 : 0.5,
    borderRadius: '50%',
    display: 'grid',
    placeContent: 'center',

    '&:checked::before': {
      transform: 'scale(1)',
      opacity: !disabled ? 1 : 0.5
    },

    // no hover style for Mobile
    [theme.mediaQueries.viewport6]: {
      ...(disabled
        ? {}
        : {
            '&:hover': {
              ':not(:checked)': {
                backgroundColor: '#F0F0F0'
              },
              '&::after': {
                opacity: 1
              }
            }
          })
    },

    // inner shadow for Radio selection
    '&::before': {
      content: '""',
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      transform: 'scale(0)',
      transition: '120ms transform ease-in-out',
      backgroundColor: theme.colors?.['primary'],
      boxShadow: `inset 10px 10px ${theme.colors?.['primary']}`,
      opacity: !disabled ? 0 : 0.5
    },

    // outer shadow for hover
    '&::after': {
      content: '""',
      // onClick stylings
      opacity: inputPressed ? 1 : 0,
      width: '40px',
      height: '40px',
      transform: 'translate(-12px, -12px)',
      borderRadius: '50%',
      // onClick stylings
      backgroundColor: inputPressed ? '#D9D9D9' : '#F0F0F0',
      position: 'absolute',
      zIndex: -1
    }
  }
}))

export default RadioInput
