import gql from 'graphql-tag'

const contentFiledsFragment = `
  promotionalBanner
  merchantName
  altLogoImage
  logoImage
  splashImage
  shop {
    header {
      bannerImage
    }
  }
`

const QUERY_GET_CONTENT_BY_SLUG = gql`
  query getContent($merchantSlug: String!) {
    getContent(merchantSlug: $merchantSlug) {
      ${contentFiledsFragment}
    }
  }
`

export { QUERY_GET_CONTENT_BY_SLUG }
