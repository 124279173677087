import styled from '@emotion/styled'
import { Icon } from '../commonStyles'

interface IconTitleProps {
  children: React.ReactNode
  icon: React.ReactNode
}

export const IconTitle = ({ children, icon }: IconTitleProps) => {
  return (
    <Root>
      <Icon>{icon}</Icon>
      <Title>{children}</Title>
    </Root>
  )
}

const Root = styled.div(() => ({
  display: 'flex',
  gap: '6px'
}))

const Title = styled.h5(() => ({
  margin: '0',
  fontSize: '16px',
  fontWeight: 600
}))
