import { useEffect } from 'react'

const useInputKeyEnterHandler = ({
  handleKeyPress
}: {
  handleKeyPress: () => void
}) => {
  const handleEnterPress = (event: KeyboardEvent) =>
    event.key === 'Enter' && handleKeyPress && handleKeyPress()

  useEffect(() => {
    document.addEventListener('keydown', handleEnterPress)

    return () => {
      document.removeEventListener('keydown', handleEnterPress)
    }
  }, [])
}

export default useInputKeyEnterHandler
