import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { ALLERGENS } from '../../Product/types'
import { InfoHeader } from './commonStyles'

interface AllergensProps {
  allergens?: string[]
}

const Allergens = ({ allergens = [] }: AllergensProps) => {
  if (!allergens.length) return <></>
  return (
    <Container>
      <section data-testid='product-allergens'>
        <InfoHeader> Allergen info</InfoHeader>
        <Disclaimer>This product contains the following allergens:</Disclaimer>
        <CardContainer>
          {allergens.map((allergen: string) => (
            <Card key={allergen}> {ALLERGENS[allergen]} </Card>
          ))}
        </CardContainer>
      </section>
    </Container>
  )
}

const Container = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '24px',
    fontSize: theme.fontSizes[0],
    fontWeight: theme.fontWeights['normal']
  })
)

const Disclaimer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    fontSize: theme.fontSizes[1],
    marginBottom: '12px'
  })
)

const CardContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px'
  })
)

const Card = styled.span<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  display: 'flex',
  padding: '4px 8px',
  justifyContent: 'center',
  alignItems: 'center',

  borderRadius: '12px',
  border: '1px solid #D9D9D9',
  backgroundColor: '#F5F5F5',

  color: '#8C8C8C',
  fontSize: theme.fontSizes[0],
  lineHeight: '18px',
  fontWeight: theme.fontWeights['bold'],

  whiteSpace: 'nowrap',
  width: 'fit-content'
}))

export default Allergens
