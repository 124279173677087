import styled from '@emotion/styled'
import {
  IoIosCheckbox as CheckedBox,
  IoIosSquareOutline as UncheckedBox
} from 'react-icons/io'
interface Props {
  toggleChecked: () => void
  isChecked: boolean
  label: string
}

const AddressCheckbox = ({ toggleChecked, isChecked, label }: Props) => {
  return (
    <CheckboxContainer onClick={toggleChecked}>
      {isChecked ? <CheckedBox /> : <UncheckedBox />}
      <Label>{label}</Label>
    </CheckboxContainer>
  )
}

const Label = styled.label(({ theme }: any) => ({
  color: theme.colors.textBody
}))

const CheckboxContainer = styled.div(({ theme }: any) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: theme.fontSizes[1],
  fontWeight: theme.fontWeights.normal,
  marginBottom: '8px',
  '& > label': {
    marginLeft: '4px'
  }
}))

export default AddressCheckbox
