/** Generate title of Loyalty Card progress
 * @returns string
 */
export const getLoyaltyCardProgressTitle = (
  stampsToGo: number,
  stampLabel: string, // "Points" | "Stamps"
  rewardCount: number
): string => {
  let stampLabelFormatted = stampLabel.toLowerCase()
  if (stampsToGo <= 1)
    stampLabelFormatted = stampLabelFormatted.substring(
      0,
      stampLabel.length - 1
    )

  switch (rewardCount) {
    case 0:
      return `Just ${stampsToGo} ${stampLabelFormatted} away from your next reward`
    default:
      return 'Congratulations!'
  }
}

/** Generate description of Loyalty Card progress
 * @returns JSX.Element (for bold content)
 */
export const getLoyaltyCardProgressText = (
  currentStamps: number,
  loyaltyCardName: string,
  stampLabel: string, // "Points" | "Stamps"
  rewardCount: number
): JSX.Element => {
  let stampLabelFormatted = stampLabel.toLowerCase()
  if (currentStamps <= 1)
    stampLabelFormatted = stampLabelFormatted.substring(
      0,
      stampLabel.length - 1
    )

  switch (rewardCount) {
    case 0:
      return (
        <>
          You have collected{' '}
          <b>
            {currentStamps} {stampLabelFormatted}
          </b>{' '}
          on your <b>{loyaltyCardName}</b>. Keep saving for your next reward!
        </>
      )
    case 1:
      return (
        <>
          You have <b>earned a reward</b> by collecting{' '}
          <b>
            {currentStamps} {stampLabelFormatted}
          </b>{' '}
          on your <b>{loyaltyCardName}</b>. Enjoy your reward!
        </>
      )
    // default is 2 or more rewards
    default:
      return (
        <>
          You have <b>earned {rewardCount} rewards</b> by collecting{' '}
          <b>
            {currentStamps} {stampLabelFormatted}
          </b>{' '}
          on your <b>{loyaltyCardName}</b>. Enjoy your rewards!
        </>
      )
  }
}
