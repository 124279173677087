import { useEffect } from 'react'

const useEscModalHandler = ({ onClose }: { onClose?: () => void }) => {
  const handleEscPress = (event: KeyboardEvent) =>
    event.key === 'Escape' && onClose && onClose()

  useEffect(() => {
    document.addEventListener('keydown', handleEscPress)

    return () => {
      document.removeEventListener('keydown', handleEscPress)
    }
  }, [])
}

export default useEscModalHandler
