import styled from '@emotion/styled'

const InputGroup = ({
  children,
  templateColumns,
  gridGap = '12px'
}: {
  children: React.ReactNode | React.ReactNode[]
  templateColumns?: string
  gridGap?: string
}) => {
  return (
    <StyledInputGroup templateColumns={templateColumns} gridGap={gridGap}>
      {children}
    </StyledInputGroup>
  )
}

const StyledInputGroup = styled.div(
  ({
    templateColumns,
    gridGap
  }: {
    templateColumns?: string
    gridGap: string
  }) => ({
    display: 'grid',
    gridGap: gridGap,
    '&:not(:last-of-type)': { marginBottom: '12px' },
    gridTemplateColumns: templateColumns || 'repeat(2, 1fr)'
  })
)

export default InputGroup
