import { Order } from 'shop/types/cart'
import AllTrackers from './AllTrackers'
import { EnrichedEventData } from './GA/types'
import Tracker from './TrackerNew'
import { TrackableProduct, TrackableProductProps } from './types'

/**
 * Converts a `Product` to a `TrackableProduct`
 */
export const trackableProduct = (
  props: TrackableProductProps
): TrackableProduct => {
  const { id, name, category, price, currency, imageUrl, variant, url } = props

  const tp: TrackableProduct = {
    product_id: id,
    name,
    category,
    price, // price should be VAT inclusive
    currency: currency ? currency : 'gbp',
    image_url: imageUrl,
    variant,
    url
  }

  return tp
}

/** Helper function to merge previous enrichedEventData with new enrichedEventData ready to be set on the Tracker itself. */
export const expandEnrichedEventData = (
  tracker: Tracker,
  newData: Partial<EnrichedEventData>
) => {
  if (tracker.isTrackingEnabled) {
    const prevData = tracker.enrichedEventData
    tracker.setEnrichedEventData({
      ...prevData,
      ...newData
    })
  }
}

/** Enrich trackers from order data - covers order page first loads */
export const enrichOrderEventData = (order: Order) => {
  if (!!order?.store?.id) {
    const additionalEventData = {
      fulfillment_type: order.fulfillment.type,
      order_type: order.fulfillment.orderType
    }
    expandEnrichedEventData(AllTrackers.getInstance().slerpGA4Tracker, {
      store_id: order.store.id,
      store_slug: order.store.slug,
      ...additionalEventData
    })
    expandEnrichedEventData(AllTrackers.getInstance().merchantGA4Tracker, {
      store_id: order.store.id,
      store_slug: order.store.slug,
      ...additionalEventData
    })
  }
}
