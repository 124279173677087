import { HTMLAttributes } from 'react'
import { AccountIcon } from 'shop/assets/icons'
import styled from '@emotion/styled'
import Theme from 'shop/theme/types'
import AccountMenu from '../Landing/AccountMenu'
import { useModal } from 'shop/hooks'

type StyledHTMLElement = React.DetailedHTMLProps<
  HTMLAttributes<HTMLElement>,
  HTMLElement
>

interface Props {
  merchantId: string
}

const LoginIcon = ({ merchantId }: Props) => {
  const { isModalOpen, openModal, loginModal } = useModal()
  const isLoggedIn = localStorage.getItem('customerId') !== null

  const handleIconClick = () => {
    if (!isLoggedIn) {
      openModal('login')
    }
  }

  return (
    <>
      {isModalOpen('login') &&
        !isModalOpen('fulfillment') &&
        loginModal({ merchantId })}
      <IconContainer onClick={() => handleIconClick()}>
        {isLoggedIn && <AccountMenu inverted />}
        {!isLoggedIn && (
          <Icon>
            <AccountIcon height='18px' width='18px' />
          </Icon>
        )}
      </IconContainer>
    </>
  )
}

const IconContainer = styled.div(({ theme }: any) => ({
  position: 'relative',
  cursor: 'pointer',
  height: '32px',
  width: '32px',
  WebkitTapHighlightColor: 'transparent',

  [theme.mediaQueries.viewport7]: {
    display: 'flex',
    paddingTop: '0'
  }
}))

const Icon = styled.span<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  width: '32px',
  height: '32px',
  borderRadius: '12px',
  marginLeft: 'auto',
  backgroundColor: 'black',
  display: 'flex',
  cursor: 'pointer',
  filter: 'invert(1)',

  '&:hover': {
    opacity: 0.9
  },

  '>svg': {
    margin: 'auto'
  }
}))

export default LoginIcon
