import CenterModal from 'shop/components/Modal/CenterModal'
import FullscreenModal from 'shop/components/Modal/FullscreenModal'
import { useMediaQueries } from 'shop/hooks'
import { overlayOpenAnim } from 'shop/components/Shop/commonStyles'

const ModalWrapper = (props: {
  onClose?: () => void
  children: React.ReactNode
  scrollBehaviour?: 'overflow' | 'auto'
  isOpen?: boolean
  hideMobileModal?: boolean
}) => {
  const { isMobile } = useMediaQueries()
  const {
    children,
    onClose,
    scrollBehaviour = 'auto',
    isOpen = true,
    hideMobileModal = false
  } = props

  return !isMobile ? (
    <CenterModal
      {...(onClose && { handleCloseModal: onClose })}
      styles={{
        minHeight: '200px',
        overflowY: scrollBehaviour,
        width: '100%',
        maxWidth: '530px',
        borderRadius: '16px',
        ...overlayOpenAnim(isOpen)
      }}
      inModal={true}
      canClose={false}
      isOverlay={true}
      isOpen={isOpen}
    >
      {children}
    </CenterModal>
  ) : (
    <FullscreenModal
      {...(onClose && { onClose: onClose })}
      newFulfillmentModal={true}
      hide={hideMobileModal}
    >
      {children}
    </FullscreenModal>
  )
}

export default ModalWrapper
