import styled from '@emotion/styled'
import { StyledHTMLElement } from 'shop/theme/types'
import { Theme } from 'styled-system'

export const SpinnerContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '100px 0'
  })
)
