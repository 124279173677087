import styled from '@emotion/styled'
import { Switch } from './FormElements'
import { inputCharactersLimitValidator } from 'shop/components/Checkout'
import { UseFormMethods, FieldError } from 'react-hook-form'
import FormInput from '../Inputs/FormInput'
import { FormFields } from './types'

type GiftWrappingProps = {
  formHandle: UseFormMethods<FormFields>
  giftWrapEnabled: boolean
  giftWrapText: string
  giftWrapPrice: string
  giftWrapPlaceholder: string
  error?: FieldError
  updateCartGiftWrapping: () => void
}

const GiftWrapping = ({
  formHandle,
  giftWrapEnabled,
  giftWrapText,
  giftWrapPrice,
  giftWrapPlaceholder,
  error,
  updateCartGiftWrapping
}: GiftWrappingProps) => {
  const { watch, register } = formHandle
  const giftWrapMessage = watch('gift_wrap_message')
  const giftWrapPriceNumeric: number = +giftWrapPrice
  const pricingDisplay: string =
    giftWrapPriceNumeric === 0
      ? 'For free'
      : `For only ${giftWrapPriceNumeric.toLocaleString('en-GB', {
          style: 'currency',
          currency: 'GBP'
        })}`

  return (
    <>
      <HeaderWithSwitch enabled={!!giftWrapEnabled}>
        <Switch
          formRef={register}
          name={'gift_wrapped'}
          labelText={`${
            giftWrapText ? giftWrapText : 'Add a customized note'
          } (${pricingDisplay})`}
        />
      </HeaderWithSwitch>
      {giftWrapEnabled && (
        <FormInput
          variant='textarea'
          id={'gift_wrap_message'}
          data-testid='gift-wrap-message-textarea'
          value={giftWrapMessage || ''}
          onBlur={updateCartGiftWrapping}
          name='gift_wrap_message'
          labelText='Gift Wrap Message'
          formRef={register({
            required: 'Please enter a message for gift wrapping',
            validate: inputCharactersLimitValidator('gift wrap', 400)
          })}
          placeholder={
            giftWrapPlaceholder ||
            'What would you like us to write on the note?'
          }
          showError={!!error}
        />
      )}
    </>
  )
}

const HeaderWithSwitch = styled.div(({ enabled }: { enabled: boolean }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: enabled ? '16px' : '0'
}))

export default GiftWrapping
