import React, { useState } from 'react'
import CenterModal from 'shop/components/Modal/CenterModal'
import styled from '@emotion/styled'
import Spinner from 'shop/components/Loader/Spinner'
import { Button } from 'shop/components'
import { MUTATE_EDIT_CUSTOMER } from 'shop/client/queries/CustomerQueries'
import { useMediaQueries, useShop } from 'shop/hooks'
import { customerClient, getPasswordSubtextColor } from './utils'
import FullscreenModal from '../Modal/FullscreenModal'
import { slideUp } from '../Shop/commonStyles'
import FormInput from '../Inputs/FormInput'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { IoAlertCircleOutline } from 'react-icons/io5'
import { PasswordErrors } from './types'
import { usePassword } from '../../hooks/usePassword'
import { ApiError } from 'shop/types'

interface Props {
  onClose: () => void
  first_name: string
  last_name: string
  email: string
  merchant_id: string
  customerId: string
}

type CustomerVariables = {
  customerId: string
  password: string
  passwordConfirmation: string
  currentPassword: string
  email: string
  merchantId: string
  firstName: string
  lastName: string
}

const ChangePasswordForm = ({
  first_name,
  last_name,
  email,
  merchant_id,
  customerId,
  onClose
}: Props) => {
  const { config, customerApiKey, merchant } = useShop()
  const [newPassword, setNewPassword] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [showPasswordError, setShowPasswordError] = useState(false)

  const { passwordError: newPasswordError } = usePassword(newPassword)
  const { isMobile } = useMediaQueries()

  const client = customerClient(
    config.domain,
    customerApiKey,
    merchant?.id || ''
  )

  const updateCustomer = (variables: CustomerVariables) => {
    return client.mutate({
      mutation: MUTATE_EDIT_CUSTOMER,
      variables: variables
    })
  }

  const handleSubmit = async (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault()
    setIsLoading(true)

    const variables: CustomerVariables = {
      customerId,
      password: newPassword,
      passwordConfirmation: newPassword,
      currentPassword,
      email,
      merchantId: merchant_id,
      firstName: first_name,
      lastName: last_name
    }

    updateCustomer(variables)
      .then(() => {
        onClose()
      })
      .catch((error: ApiError) => {
        const { graphQLErrors } = error
        if (!!graphQLErrors?.length) {
          if (graphQLErrors[0].message === 'Current password is invalid.') {
            setShowPasswordError(true)
          }
        }
        setIsLoading(false)
      })
  }

  const setCurrentPasswordCallback = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCurrentPassword(e.target.value)
  }

  const setNewPasswordCallback = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value)
  }

  const ChangePasswordForm = (
    <Form onSubmit={handleSubmit} data-testid='change-password-form'>
      <h2>Change Password</h2>
      <CustomerFields>
        <div>
          <FormInput
            id='current-password'
            data-testid='current-password-input'
            labelText='Current password'
            type='password'
            value={currentPassword}
            onChange={setCurrentPasswordCallback}
            showError={showPasswordError}
          />
          {showPasswordError && (
            <CurrentPasswordError>
              <IoAlertCircleOutline />
              <ErrorMessage>
                The password you entered is incorrect. Please try again
              </ErrorMessage>
            </CurrentPasswordError>
          )}
        </div>
        <div>
          <FormInput
            id='new-password'
            data-testid='new-password-input'
            labelText='New password'
            type='password'
            value={newPassword}
            onChange={setNewPasswordCallback}
            showError={false}
          />
          <InputSubtext passwordError={newPasswordError}>
            Password requires at least 8 characters
          </InputSubtext>
        </div>
      </CustomerFields>
      <Button
        data-testid='update-password-button'
        type='submit'
        disabled={isLoading || !!newPasswordError || !currentPassword}
      >
        {isLoading && <Spinner />}
        Update
      </Button>
    </Form>
  )

  return isMobile ? (
    <FullscreenModal
      {...(onClose && { onClose: onClose })}
      withCloseButton={true}
      styles={mobileStyles}
    >
      {ChangePasswordForm}
    </FullscreenModal>
  ) : (
    <CenterModal handleCloseModal={onClose} styles={desktopStyles}>
      {ChangePasswordForm}
    </CenterModal>
  )
}

export const Form = styled.form<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    animation: `${slideUp} 100ms ease-out`,
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    zIndex: theme.zIndex.accountModal,
    width: '100%',
    gap: '32px',
    marginTop: '32px',
    padding: '0 16px',
    [theme.mediaQueries.viewport6]: {
      marginTop: 0,
      width: '100%',
      padding: '40px 32px 32px'
    }
  })
)

const CurrentPasswordError = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '12px',
  '> svg': {
    color: '#CF1322',
    marginRight: '4px'
  }
}))

const CustomerFields = styled.div(() => ({
  justifyContent: 'center',
  display: 'flex',
  gap: '24px',
  flexDirection: 'column'
}))

const ErrorMessage = styled.p<StyledHTMLElement, Required<Theme>>(
  ({ theme }: any) => ({
    color: '#CF1322',
    fontSize: '12px',
    fontWeight: theme.fontWeights.normal,
    margin: 0
  })
)

const desktopStyles = {
  maxWidth: '530px'
}

const mobileStyles = {
  top: 0,
  padding: 0,
  '> div': {
    top: '19px'
  }
}

const InputSubtext = styled.p<
  StyledHTMLElement & { passwordError: PasswordErrors }
>(({ passwordError }) => ({
  fontSize: '14px',
  color: getPasswordSubtextColor(passwordError),
  fontWeight: 500,
  margin: '8px 0 0'
}))

export default ChangePasswordForm
