import styled from '@emotion/styled'

export const SpinnerContainer = styled.div(({ theme }: any) => ({
  padding: '72px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontWeight: theme.fontWeights.bold,
  color: 'lightgrey'
}))
