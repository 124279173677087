import { FieldError, UseFormMethods } from 'react-hook-form'
import { FormFieldsV2 } from 'shop/components/Checkout/types'
import { inputCharactersLimitValidator } from '.'
import FormInput from '../Inputs/FormInput'
import styled from '@emotion/styled'

const CustomFieldV2 = ({
  fieldName,
  required,
  placeholder,
  formHandle,
  error
}: {
  fieldName: string
  required: boolean
  placeholder: string
  formHandle: UseFormMethods<FormFieldsV2>
  error?: FieldError
}) => {
  const { watch, register } = formHandle
  const customFieldValue = watch('customFieldValue')

  return (
    <Content data-testid='custom-field'>
      <Label data-testid='custom-field-label'>
        <Title>{fieldName} </Title>
        <OptionalityText>{required ? 'Required' : 'Optional'}</OptionalityText>
      </Label>
      <FormInput
        variant='textarea'
        id='customFieldValue'
        data-testid='custom-field-textarea'
        value={customFieldValue || ''}
        name='customFieldValue'
        labelText='Custom Field'
        showError={!!error}
        placeholder={placeholder || ''}
        formRef={register({
          required: required ? 'Please enter a value' : undefined,
          validate: inputCharactersLimitValidator('field')
        })}
      />
    </Content>
  )
}

const Content = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '24px'
}))

const Label = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  '> p': {
    margin: '0 0 16px 0'
  }
}))

const Title = styled.p(() => ({
  fontSize: '16px',
  fontWeight: 500,
  whiteSpace: 'pre-wrap'
}))

const OptionalityText = styled.p(() => ({
  fontSize: '14px',
  fontStyle: 'italic',
  color: '#262626'
}))

export default CustomFieldV2
