import {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  HTMLAttributes
} from 'react'
import styled from '@emotion/styled'
import { CurrentLocationState } from './types'
import { useShop } from 'shop/hooks'
import { buildAddressFromComponents } from './addressUtils'
import { MUTATE_CREATE_ADDRESS } from 'shop/client/queries/CustomerQueries'
import { stateColors } from 'shop/theme/defaultTheme'
import CenterModal from '../Modal/CenterModal'
import { Button } from 'shop/components'
import { TiWarningOutline as WarningIcon } from 'react-icons/ti'
import Theme from 'shop/theme/types'

type StyledHTMLElement = React.DetailedHTMLProps<
  HTMLAttributes<HTMLElement>,
  HTMLElement
>
interface Props {
  handleInitCart: () => void
  setShowSaveAddressModal: Dispatch<SetStateAction<boolean>>
  currentLocation: CurrentLocationState
}

const SaveAddressModal = ({
  handleInitCart,
  setShowSaveAddressModal,
  currentLocation
}: Props) => {
  const [saveAddressError, setSaveAddressError] = useState<boolean>(false)
  const { useShopClient } = useShop()
  const client = useShopClient()
  const customerId = localStorage.getItem('customerId')

  // Clear the modal error on failing to save an address if the address changes
  useEffect(() => {
    setSaveAddressError(false)
  }, [currentLocation])

  const saveAddressParams = () => {
    if (currentLocation) {
      const address = buildAddressFromComponents(
        currentLocation.addressComponents
      )
      return {
        line1: address.line_1,
        line2: address.line_2,
        city: address.city,
        country: address.country,
        zip: address.zip,
        flatNumber: address.flatNumber,
        customerId,
        defaultBillingAddress: true,
        defaultShippingAddress: true
      }
    }
    return null
  }

  /**
   * Handles saving of a user's address
   * @param isSavingAddress Does the user want to save their address?
   */
  const handleSaveAddress = (
    isSavingAddress: boolean
  ): void | Promise<void> => {
    const variables = saveAddressParams()
    if (isSavingAddress && variables) {
      client
        .mutate({
          mutation: MUTATE_CREATE_ADDRESS,
          variables: {
            ...variables
          }
        })
        .then(() => {
          setShowSaveAddressModal(false)
          return handleInitCart()
        })
        .catch(() => {
          setSaveAddressError(true)
        })
    } else {
      setShowSaveAddressModal(false)
      return handleInitCart()
    }
  }

  return (
    <CenterModal handleCloseModal={() => setShowSaveAddressModal(false)}>
      <SaveAddressContainer>
        <h2>Save Address?</h2>
        <p>
          By saving your address you will be able to select your desired store
          quicker next time.
        </p>
        <AddressNote>
          You can access and change your saved addresses at any time by viewing
          your Account.
        </AddressNote>
        {saveAddressError && (
          <ErrorMessage>
            <Icon>
              <WarningIcon />
            </Icon>
            <p>
              Oh no, we are struggling to save your address at this time, please
              try again later.
            </p>
          </ErrorMessage>
        )}
        <ButtonContainer>
          <Button variant='secondary' onClick={() => handleSaveAddress(false)}>
            No thanks
          </Button>
          <Button onClick={() => handleSaveAddress(true)}>Yes please</Button>
        </ButtonContainer>
      </SaveAddressContainer>
    </CenterModal>
  )
}

const SaveAddressContainer = styled.div(({ theme }: any) => ({
  position: 'relative',
  borderRadius: '4px',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  zIndex: theme.zIndex.accountModal,
  fontFamily: theme.fonts.normal,
  margin: '16px auto',
  maxWidth: '480px',
  width: '100%',
  padding: '24px',
  '> h2': {
    lineHeight: 1,
    marginBottom: '24px',
    textAlign: 'center',
    fontWeight: theme.fontWeights.bold
  },
  [theme.mediaQueries.viewport7]: {
    minWidth: '100%',
    height: 'auto',
    padding: '48px 48px 16px',
    marginBottom: '32px',
    '> h2': {
      lineHeight: 0
    }
  }
}))

const ButtonContainer = styled.div<StyledHTMLElement, Required<Theme>>(() => ({
  display: 'flex',
  marginTop: '24px',
  '> Button:first-of-type': {
    marginRight: '10px'
  },
  '> Button:last-of-type': {
    marginLeft: '10px'
  }
}))

const AddressNote = styled.p<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    color: 'grey',
    fontSize: theme.fontSizes[0],
    marginTop: 0
  })
)

const ErrorMessage = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    color: stateColors['error'][7],
    marginTop: 0,
    display: 'flex',

    '> p': {
      fontSize: theme.fontSizes[0],
      marginLeft: '1ch'
    }
  })
)

const Icon = styled.span<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  fontSize: theme.fontSizes[0] * 2,
  alignSelf: 'center'
}))

export default SaveAddressModal
