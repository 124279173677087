import {
  CartItem as CartItemType,
  AdditionalStoreFee
} from 'shop/components/Landing/types'
import { FauxCartItem } from './../Cart/CartItem'
import DiscountForm from './DiscountForm'
import styled from '@emotion/styled'
import Heading from '../Heading'
import CartItems from './CartItems'
import {
  DeliveryAddress,
  DeliveryReduction,
  Discount,
  RedeemableReward
} from './types'
import {
  formatMoney,
  computeAdditionalStoreFee,
  isTableOrder
} from '../Cart/utils'
import Spinner from '../Loader/Spinner'
import { MdErrorOutline as ErrorIcon } from 'react-icons/md'
import { Cart } from 'shop/components/Landing/types'
import { useState } from 'react'
import ClaimingSection, {
  ClaimingSectionLoader
} from 'shop/components/Checkout/Loyalty/ClaimingSection'
import MediaQuery from 'react-responsive'
import { HiOutlineShoppingCart } from 'react-icons/hi'
import { IoChevronForward } from 'react-icons/io5'
import EstimatedFulfillmentWindow, {
  EstimatedTimeType
} from '../EstimatedFulfillmentWindow/EstimatedFulfillmentWindow'
import { createAddressString } from 'shop/utils/address'
import { getBeforeDeliveryDiscountValue, getTooltipMessage } from './utils'
import { Price } from 'shop/types/cart'

type Props = {
  isDiscountRemovable?: boolean
  giftWrapped: boolean
  cartItems: CartItemType[]
  giftWrapPrice: number
  discountAmount: number
  discountCode: string | null
  discountTarget:
    | 'all_charges'
    | 'all_products'
    | 'subtotal_delivery_fee'
    | 'delivery_fee'
    | 'product_category'
    | null
  discountTrigger?: 'manual' | 'automatic' | null
  discountValue: number
  discountType: 'percentage' | 'fixed' | null
  fulfillmentType: string
  total: number
  isDeliveryLoading: boolean
  hasAddressErrors: boolean
  additionalStoreFee?: AdditionalStoreFee
  additionalStoreFeeDiscount?: Price | null
  cart: Cart
  customerRewards: RedeemableReward[]
  claimReward: (customerRewardId: string) => void
  isProcessingRewards: boolean
  movWarning: React.ReactNode | null
  merchantSlug: string
  isPaying?: boolean
  subtotal: number
  subtotalWithAnyDiscounts: number
  minimumOrderValue: number
  saveAddressAndQuoteFee: () => void
  claimAutomaticDiscount?: (cartId: string) => Promise<void | Discount>
  tipValue: number
  showTipValue?: boolean
  estimatedFulfillmentWindow?: EstimatedTimeType
  deliveryAddress: DeliveryAddress
  deliveryReduction?: DeliveryReduction
  checkoutApplyDiscount: (discountCode: string) => Promise<void>
  checkoutRemoveDiscount: () => Promise<void>
}

const OrderSummary = ({
  isDiscountRemovable = true,
  giftWrapped,
  cartItems,
  giftWrapPrice,
  discountAmount,
  discountCode,
  discountTarget,
  discountTrigger,
  discountValue,
  discountType,
  fulfillmentType,
  total,
  isDeliveryLoading,
  hasAddressErrors,
  additionalStoreFee,
  additionalStoreFeeDiscount,
  cart,
  customerRewards,
  claimReward,
  isProcessingRewards,
  movWarning,
  isPaying,
  minimumOrderValue,
  subtotal,
  subtotalWithAnyDiscounts,
  saveAddressAndQuoteFee,
  claimAutomaticDiscount,
  merchantSlug,
  tipValue,
  showTipValue = true,
  estimatedFulfillmentWindow,
  deliveryAddress,
  deliveryReduction,
  checkoutApplyDiscount,
  checkoutRemoveDiscount
}: Props) => {
  const [displayed, setDisplayed] = useState(window.innerWidth > 640.1)
  const storeFeeAmount = computeAdditionalStoreFee(
    cart,
    additionalStoreFee,
    additionalStoreFeeDiscount
  )

  const deliveryChargeData = {
    deliveryChargeBeforeDiscount:
      deliveryReduction?.deliveryChargeBeforeDiscount,
    deliveryCharge: deliveryReduction?.deliveryCharge,
    deliveryChargeReduction: deliveryReduction?.deliveryChargeReduction || 0,
    discountAmount: discountAmount || 0,
    discountTarget: discountTarget || ''
  }

  const getDeliveryItemState = () => {
    if (hasAddressErrors)
      return (
        <>
          <ErrorIcon size='14px' style={{ verticalAlign: 'top' }} />
          <span>Cannot be calculated</span>
        </>
      )
    if (isDeliveryLoading)
      return (
        <div>
          <Spinner size='10px' />
          <span>Calculating</span>
        </div>
      )
    if (
      deliveryChargeData.deliveryCharge ||
      deliveryChargeData.deliveryCharge === 0
    ) {
      return formatMoney(deliveryChargeData.deliveryCharge)
    }
    return <i>To be calculated</i>
  }

  const isDineIn = cart && isTableOrder(cart.metadata)

  return (
    <>
      <OrderSummaryContainer data-testid='order_summary'>
        <ReviewSection>
          <DesktopCartHeading>
            <HeadingWrapper>
              <Heading as='h4' margin='0'>
                Your cart
              </Heading>
            </HeadingWrapper>
          </DesktopCartHeading>
          <MobileCartHeading
            onClick={() => setDisplayed(!displayed)}
            data-testid='mobile-order-summary-header'
          >
            <HeadingWrapper>
              <CartIcon size='24px' />
              <Heading as='h5' margin='0' fontWeight={'normal'}>
                <>
                  Show order summary or enter discount code
                  <CaretIcon rotated={displayed.toString()} />
                </>
              </Heading>
              <CartTotal>
                {discountAmount > 0 && discountTarget !== 'delivery_fee' && (
                  <DiscountedSubTotal>
                    {formatMoney(Number(total) + Number(discountAmount))}
                  </DiscountedSubTotal>
                )}
                <TotalAmount>{formatMoney(total)}</TotalAmount>
              </CartTotal>
            </HeadingWrapper>
          </MobileCartHeading>
          {displayed && (
            <Items data-testid='cart-items'>
              {!isDineIn && (
                <EstimatedFulfillmentWindow
                  estimatedTime={estimatedFulfillmentWindow}
                  fulfillmentType={fulfillmentType}
                  storeName={cart.store?.name}
                  address={
                    deliveryAddress && createAddressString(deliveryAddress)
                  }
                />
              )}
              <CartItems cartItems={cartItems} />
              {giftWrapped && (
                <Item>
                  <FauxCartItem
                    label='Gift wrapping'
                    testId='gift_wrap_item'
                    value={formatMoney(giftWrapPrice)}
                  />
                </Item>
              )}
              {isPaying && !discountCode ? null : (
                <Item>
                  <DiscountForm
                    isDiscountRemovable={isDiscountRemovable}
                    discountCode={discountCode}
                    discountAmount={discountAmount}
                    deliveryCharge={deliveryChargeData.deliveryCharge || 0}
                    discountTarget={discountTarget}
                    discountValue={discountValue}
                    discountType={discountType}
                    discountTrigger={discountTrigger}
                    subtotal={subtotalWithAnyDiscounts}
                    minimumOrderValue={minimumOrderValue}
                    saveAddressAndQuoteFee={saveAddressAndQuoteFee}
                    claimAutomaticDiscount={claimAutomaticDiscount}
                    checkoutApplyDiscount={checkoutApplyDiscount}
                    checkoutRemoveDiscount={checkoutRemoveDiscount}
                  />
                </Item>
              )}
              {(!!subtotal || subtotal === 0) && (
                <Item>
                  <FauxCartItem
                    label={'Subtotal'}
                    value={formatMoney(subtotalWithAnyDiscounts || 0)}
                    beforeDiscountValue={
                      discountAmount && discountTarget !== 'delivery_fee'
                        ? formatMoney(subtotal)
                        : ''
                    }
                  />
                </Item>
              )}
              {!!storeFeeAmount.base && (
                <Item>
                  <FauxCartItem
                    withTooltip={true}
                    tooltipMessage='This helps us provide a direct ordering solution for our customers. Thank you for your support.'
                    label={additionalStoreFee?.name || 'Additional Store Fee'}
                    value={formatMoney(
                      !!storeFeeAmount?.reduction
                        ? storeFeeAmount?.discounted
                        : storeFeeAmount.base
                    )}
                    beforeDiscountValue={
                      !!storeFeeAmount?.reduction
                        ? formatMoney(storeFeeAmount?.base)
                        : ''
                    }
                  />
                </Item>
              )}
              {fulfillmentType === 'delivery' && (
                <Item>
                  <FauxCartItem
                    label='Delivery fee'
                    testId='delivery_fee'
                    value={getDeliveryItemState()}
                    beforeDiscountValue={getBeforeDeliveryDiscountValue(
                      deliveryChargeData
                    )}
                    withTooltip={
                      !!deliveryReduction?.deliveryChargeReduction &&
                      (!discountAmount ||
                        (!!discountAmount && discountTarget !== 'delivery_fee'))
                    }
                    tooltipMessage={getTooltipMessage(deliveryReduction)}
                  />
                </Item>
              )}
              {tipValue > 0 && showTipValue && (
                <Item>
                  <FauxCartItem
                    label='Tip Value'
                    testId='tip_value'
                    value={formatMoney(cart.tip_value)}
                  />
                </Item>
              )}
              {movWarning && (
                <MediaQuery minWidth={768.1}>{movWarning}</MediaQuery>
              )}
              {customerRewards && customerRewards.length > 0 && (
                <Item>
                  {isProcessingRewards ? (
                    <ClaimingSectionLoader />
                  ) : (
                    <ClaimingSection
                      {...{
                        customerRewards,
                        claimReward,
                        isProcessingRewards,
                        merchantSlug: merchantSlug
                      }}
                    />
                  )}
                </Item>
              )}
              <Item>
                <FauxCartItem
                  label='Total'
                  value={
                    <TotalAmount size='md'>{formatMoney(total)}</TotalAmount>
                  }
                  beforeDiscountValue={
                    discountAmount && discountTarget !== 'delivery_fee'
                      ? formatMoney(Number(total) + Number(discountAmount))
                      : ''
                  }
                  emphasize
                />
              </Item>
            </Items>
          )}
        </ReviewSection>
      </OrderSummaryContainer>
    </>
  )
}

const CartIcon = styled(HiOutlineShoppingCart)(() => ({
  flex: '0 0 auto',
  marginRight: '16px'
}))

const TotalAmount = styled.h4(({ theme, size = 'lg' }: any) => ({
  fontFamily: theme.fonts.heading.family,
  fontSize: theme.fontSizes[size === 'lg' ? 4 : 3],
  display: 'inline-block',
  fontWeight: 'bold',
  '&::first-letter': {
    fontWeight: 'normal',
    fontSize: theme.fontSizes[size === 'lg' ? 2 : 1]
  },
  [theme.mediaQueries.viewport6]: {
    fontSize: theme.fontSizes[2],
    '&::first-letter': {
      fontWeight: 'bold',
      fontSize: theme.fontSizes[2]
    }
  }
}))

const CaretIcon = styled(IoChevronForward)(
  ({ rotated }: { rotated: string }) => ({
    transition: 'all 150ms ease-in',
    transform: rotated === 'true' ? 'rotate(90deg)' : 'none',
    fontSize: '18px',
    verticalAlign: 'text-bottom'
  })
)

const Items = styled.div(({ theme }: any) => ({
  padding: '4px 16px 0',
  backgroundColor: '#FAFAFB',
  '> div': {
    paddingTop: '16px'
  },
  borderBottom: '1px solid #E7E5E9',
  margin: '0 -16px',
  [theme.mediaQueries.viewport6]: {
    padding: 0,
    backgroundColor: 'transparent',
    borderBottom: 'none',
    margin: 0
  }
}))

const HeadingWrapper = styled.header(() => ({
  display: 'flex',
  alignItems: 'center'
}))

export const containerPaddingTop = '110px'

const OrderSummaryContainer = styled.div(({ theme }: any) => ({
  marginBottom: '8px',
  height: '100%',
  padding: '0 16px 0',
  [theme.mediaQueries.viewport6]: {
    paddingTop: containerPaddingTop
  }
}))

const Item = styled.div(({ theme, desktopOnly }: any) => ({
  margin: '16px 0',
  padding: 0,
  display: desktopOnly ? 'none' : 'block',
  borderTop: '1px solid #E7E5E9',
  [theme.mediaQueries.viewport6]: {
    display: 'block',
    margin: '0',
    padding: '16px 0',
    '&:first-of-type': {
      marginBottom: '16px',
      border: '0'
    }
  }
}))

const ReviewSection = styled.div(() => ({
  marginBottom: '16px'
}))

const DesktopCartHeading = styled.div(({ theme }: any) => ({
  display: 'none',
  [theme.mediaQueries.viewport6]: {
    marginBottom: '8px',
    display: 'block'
  }
}))

const MobileCartHeading = styled.div(({ theme }: any) => ({
  margin: '0 -16px',
  padding: '16px',
  display: 'block',
  backgroundColor: '#F3F2F4',
  borderTop: '1px solid #E7E5E9',
  borderBottom: '1px solid #E7E5E9',
  [theme.mediaQueries.viewport6]: {
    display: 'none'
  }
}))

const CartTotal = styled.div(({ theme }: any) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: theme.fonts.heading.family,
  fontSize: theme.fontSizes[4],
  width: '40%',
  justifyContent: 'flex-end',
  marginLeft: 'auto'
}))

const DiscountedSubTotal = styled.s(() => ({
  marginRight: '1ch',
  color: '#666',
  fontSize: '90%'
}))

export const CartItemsList = styled.div(
  ({ visible, theme, hasBottomBorder, lastPadding }: any) => ({
    gridGap: '16px',
    margin: '0 -16px',
    padding: '16px',
    backgroundColor: '#fafafa',
    borderBottom: hasBottomBorder ? '0.5px solid #ddd' : '0',
    display: visible ? 'grid' : 'none',
    [theme.mediaQueries.viewport6]: {
      borderBottom: 0,
      padding: '0',
      backgroundColor: 'white',
      position: 'relative',
      margin: '0',
      minHeight: '72px',
      // Handles long cart item list in desktop
      // 56px * 4  accounts for discount, delivery, gift wrap and total height
      // ${theme.fontSizes[4]}px is the <h4>Your cart</h4> height
      // 16px is the marginTop of this component
      // there might be a better way to make this flexible
      // doing this for now 😬
      maxHeight: `calc(100vh - ${
        theme.components.navbar.height
      } - ${containerPaddingTop} - ${56 * 4}px - ${
        theme.fontSizes[4]
      }px - 16px)`,
      overflowY: 'auto',
      scrollBehavior: 'smooth',
      '& > div:last-child': {
        paddingBottom: lastPadding ? lastPadding : '16px'
      }
    }
  })
)

export default OrderSummary
