import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

interface Props {
  size?: string
  color?: string
}

const Spinner = ({ size, color }: Props) => {
  return (
    <Container viewBox='0 0 50 50' {...{ size }}>
      <Circle
        cx='25'
        cy='25'
        r='20'
        fill='none'
        strokeWidth='8'
        {...{ color }}
      />
    </Container>
  )
}

const Container = styled.svg(({ theme, size }: any) => ({
  animation: `${rotate} 2s linear infinite`,
  width: size ? size : '18px',
  height: size ? size : '18px',
  marginRight: '8px'
}))

const Circle = styled.circle(({ theme, color }: any) => ({
  stroke: color ? color : 'grey',
  strokeLinecap: 'round',
  animation: `${dash} 1.5s ease-in-out infinite`
}))

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0,
  }
    50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`

export default Spinner
