import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { Button, PriceChangeItem } from 'shop/components'
import CenterModal from 'shop/components/Modal/CenterModal'
import { CartItem } from 'shop/components/Cart/types'
import CloseButton from 'shop/components/Modal/CloseButton'
import { overlayOpenAnim } from 'shop/components/Shop/commonStyles'
import Spinner from 'shop/components/Loader/Spinner'
import ProductInfoItem from 'shop/components/Modal/ProductInfoItem'
import { itemScollListShadows, thinScrollbar } from 'shop/components/common'

export type ExtendedCartItem = CartItem & PriceChangeItem

type Props = {
  onClose?: () => void
  invalidItems: CartItem[] | ExtendedCartItem[]
  onContinue: (e: React.MouseEvent) => void
  storeName: string | undefined
  isOpen: boolean
  type: 'InvalidItems' | 'InvalidItemsCheckout' | 'PriceChange' | null
  loading?: boolean
}

const CartProductErrorModal = ({
  onClose,
  invalidItems,
  onContinue,
  storeName,
  isOpen,
  type,
  loading = false
}: Props) => {
  if (!invalidItems.length || !storeName) return <></>

  const Cta = () => (
    <>
      <Button
        uppercase={false}
        onMouseDown={(e) => e.preventDefault()}
        onClick={onContinue}
      >
        {type === 'PriceChange' || type === 'InvalidItemsCheckout'
          ? 'Continue'
          : 'Continue anyway'}
      </Button>
      {type !== 'InvalidItemsCheckout' && (
        <BackContainer onClick={onClose}>
          <Back>Go back</Back>
        </BackContainer>
      )}
    </>
  )

  const UnavailableProducts = () => (
    <Container>
      <Header>Unavailable products</Header>
      <Text>
        Unfortunately, the following product(s) are unavailable in the{' '}
        {storeName} store. Continuing will remove them from your cart
      </Text>
      <ItemContainer isScrollable={invalidItems.length > 2}>
        {(invalidItems as CartItem[]).map((item) => (
          <ProductInfoItem item={item} key={`${item.id}`} withPrice={false} />
        ))}
      </ItemContainer>
      <Cta />
    </Container>
  )

  const LoadingState = () => (
    <SpinnerContainer>
      <Spinner size='48px' />
    </SpinnerContainer>
  )

  if (type === 'InvalidItemsCheckout')
    return (
      <CenterModal styles={overlayOpenAnim(isOpen)} canClose={false}>
        <>{loading ? <LoadingState /> : <UnavailableProducts />}</>
      </CenterModal>
    )

  return (
    <CenterModal
      styles={overlayOpenAnim(isOpen)}
      canClose={false}
      inModal={true}
      isOverlay={true}
      isOpen={isOpen}
    >
      <>
        {loading ? (
          <LoadingState />
        ) : (
          <>
            <CloseContainer>
              <CloseButton
                handleCloseModal={onClose}
                variant='newFulfillmentModal'
              />
            </CloseContainer>
            {type === 'PriceChange' ? (
              <Container>
                <Header>Prices have changed</Header>
                <Text>
                  Unfortunately, the following product(s) have changed as a
                  result of changes to your cart. If you accept the price
                  changes, click continue
                </Text>
                <ItemContainer isScrollable={invalidItems.length > 2}>
                  {(invalidItems as ExtendedCartItem[]).map((item) => (
                    <ProductInfoItem
                      item={item}
                      key={item.id}
                      withPrice={true}
                      isExtendedCartItem={true}
                    />
                  ))}
                </ItemContainer>
                <Cta />
              </Container>
            ) : (
              <UnavailableProducts />
            )}
          </>
        )}
      </>
    </CenterModal>
  )
}

const Container = styled.div<StyledHTMLElement>(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '32px',
  gap: '24px',
  justifyContent: 'center',
  '& >button': {
    borderRadius: '12px'
  },

  '> p': { margin: 0 }
}))

const CloseContainer = styled.div<StyledHTMLElement>(() => ({
  '> div': {
    position: 'absolute',
    top: '24px',
    right: '24px'
  }
}))

const Text = styled.p<StyledHTMLElement>(() => ({
  fontSize: '16px',
  lineHeight: '24px',
  color: '#595959'
}))

const Header = styled.h1<StyledHTMLElement>(() => ({
  fontSize: '24px',
  alignSelf: 'left'
}))

const ItemContainer = styled.ul<
  StyledHTMLElement & { isScrollable: boolean },
  Required<Theme>
>(({ theme, isScrollable }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '8px',
  flexDirection: 'column',
  maxHeight: '175px', // ~70px per item - 2.5 items
  ...(isScrollable
    ? {
        overflowY: 'auto',
        marginRight: '-10px',
        paddingRight: '10px',
        ...(itemScollListShadows(theme) as any),

        [theme['mediaQueries']['viewport7']]: {
          marginRight: '-15px',
          paddingRight: '5px'
        },
        ...(thinScrollbar(theme) as any)
      }
    : {})
}))

const Back = styled.span<StyledHTMLElement>(() => ({
  fontWeight: 500,
  color: '#595959',
  margin: 0
}))

const BackContainer = styled.div<StyledHTMLElement>(() => ({
  width: 'auto',
  margin: '0 auto',
  padding: '0px 10px',
  cursor: 'pointer'
}))

const SpinnerContainer = styled.div(({ theme }: any) => ({
  height: '100%',
  padding: '152px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontWeight: theme.fontWeights.bold,
  color: 'lightgrey'
}))

export default CartProductErrorModal
