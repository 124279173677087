import styled from '@emotion/styled'
import { RefCallback } from 'react'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { hideScrollbar } from '../common'
interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  id: string
  isInputFocused: boolean
  showError: boolean
  isOpen: boolean
  type: string
  children: React.ReactNode
  additionalStyles?: React.CSSProperties
  formRef?: RefCallback<HTMLInputElement>
  dropdownInputRef?: React.MutableRefObject<HTMLInputElement | null>
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void
}

const SearchableDropdown = ({
  id,
  showError,
  isOpen,
  formRef,
  children,
  isInputFocused,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  onClick,
  type,
  name,
  dropdownInputRef,
  additionalStyles,
  ...inputProps
}: Props) => {
  const assignRefs = (el: HTMLInputElement) => {
    if (formRef) formRef(el)
    if (dropdownInputRef) dropdownInputRef.current = el
  }

  return (
    <Container
      showError={showError}
      isInputFocused={isInputFocused}
      additionalStyles={additionalStyles}
    >
      <div>
        <Input
          data-testid={id}
          id={id}
          ref={assignRefs}
          type={type}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onClick={onClick}
          {...inputProps}
        />
      </div>
      {isOpen && <DropdownOptions>{children}</DropdownOptions>}
    </Container>
  )
}

const Container = styled.div<
  StyledHTMLElement & {
    isInputFocused: boolean
    showError: boolean
    additionalStyles: React.CSSProperties | undefined
  },
  Required<Theme>
>(({ theme, isInputFocused, showError, additionalStyles }) => ({
  display: 'flex',
  maxWidth: '135px',
  color: '#000',
  border: '1px solid',
  cursor: 'pointer',
  borderRadius: '12px',
  borderColor: isInputFocused
    ? '#000'
    : showError
      ? theme.colors['state'].error[5]
      : '#8B8B8B',
  '> input': {
    font: 'inherit'
  },
  ...(additionalStyles as any)
}))

const DropdownOptions = styled.div<StyledHTMLElement>(() => ({
  position: 'absolute',
  width: '100%',
  top: '100%',
  left: '0',
  marginTop: '4px',
  zIndex: 2,
  backgroundColor: '#fff',
  border: '1px solid #000',
  borderRadius: '12px',
  overflowY: 'auto',
  maxHeight: '200px',
  display: 'flex',
  flexDirection: 'column',
  borderColor: '#D9D9D9',
  '& > span': {
    justifySelf: 'flex-end'
  },
  ...(hideScrollbar as any)
}))

const Input = styled.input<StyledHTMLElement, Required<Theme>>({
  width: '100%',
  padding: '13px',
  border: 'none',
  borderRadius: '12px',
  backgroundColor: 'transparent',
  color: 'inherit',
  fontSize: '16px',
  outline: 'none',
  cursor: 'pointer',
  '&::placeholder': {
    color: '#8B8B8B'
  },
  font: 'inherit'
})

export default SearchableDropdown
