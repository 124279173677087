import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { useQueryParamAccountModal, useShop } from 'shop/hooks'

import { ShopWrapper } from './wrappers'
import { usePageViewTracker } from 'tracker'
import { Product } from '../shop/pages'

/**
 * NOTE:
 *
 * This component allows us to render ProductModal components as real modals.
 * This utilizes React Router's <Switch /> component to prevent re-renders.
 * The old method where we use `loadStore()` within SlerpSpace routes causes
 * multiple re-renders of both the product list and the product page.
 */

export const StoreV1 = () => {
  const { path, params } = useRouteMatch()

  // Track page view
  usePageViewTracker()
  // Load login modal from query params
  useQueryParamAccountModal()

  const { config } = useShop()

  return (
    <>
      <Switch>
        <Route path={`${path}/:product`} component={Product} />
      </Switch>
      <ShopWrapper slug={params.slug} merchant={config.domain} />
    </>
  )
}
