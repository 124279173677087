import {
  getTimeToDisplay,
  buildCartDate,
  buildBannerMessage,
  buildAsapMessage
} from '../utils'
import { Semibold } from 'shop/components/common'
import { OrderV2 } from 'shop/types/cart'
import { Eta } from './commonStyles'

const EtaDisplay = ({ order }: { order: OrderV2 }) => {
  const asapMessage = buildAsapMessage(order)
  const fulfillmentMessage = buildBannerMessage(order)
  const displayTime = getTimeToDisplay(order)
  const displayDate = buildCartDate(order)

  if (!displayDate && !displayTime) return <></>

  return (
    <Eta>
      {fulfillmentMessage} <Semibold>{displayDate}</Semibold> at{' '}
      <Semibold>{displayTime}</Semibold>
      {asapMessage}
    </Eta>
  )
}

export default EtaDisplay
