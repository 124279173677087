import { EditText } from 'shop/components/Inputs/Dropdown'
import { dropdownIsEditable } from '../utils'
import { StyledHTMLElement } from 'shop/theme/types'
import styled from '@emotion/styled'
import { ValidStore } from '../types'
import SimpleLoader from 'shop/components/Loader/SimpleLoader'
import { BsShop as DropdownStore } from 'react-icons/bs'

interface DeliverySuggestedStoreProps {
  isLoading: boolean
  validStoresList: string[]
  suggestedStoreClickHandler: () => void
  fulfillmentType: string
  currentStore: ValidStore | undefined
}

const DeliverySuggestedStore = ({
  validStoresList,
  suggestedStoreClickHandler,
  fulfillmentType,
  currentStore,
  isLoading
}: DeliverySuggestedStoreProps) => {
  return (
    <SimpleLoader
      isLoading={isLoading}
      additionalStyles={{ borderRadius: '12px' }}
    >
      <SuggestedStoreContainer
        showPointerCursor={dropdownIsEditable(validStoresList)}
        onClick={suggestedStoreClickHandler}
      >
        <DropdownStore />
        <p>
          {fulfillmentType === 'delivery' ? 'Deliver from ' : 'Pickup from '}
          {currentStore?.name}
        </p>
        {dropdownIsEditable(validStoresList) && <EditText> Change </EditText>}
      </SuggestedStoreContainer>
    </SimpleLoader>
  )
}

const SuggestedStoreContainer = styled.div<
  StyledHTMLElement & { showPointerCursor?: boolean }
>(({ showPointerCursor = true }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0 14px',
  marginTop: '16px',
  height: '46px',
  cursor: showPointerCursor ? 'pointer' : 'default',
  '> svg': {
    marginRight: '9px',
    width: '18px',
    height: '18px'
  },
  'p:first-of-type': {
    cursor: showPointerCursor ? 'pointer' : 'default',
    fontSize: '16px'
  }
}))

export default DeliverySuggestedStore
