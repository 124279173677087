import {
  setHours,
  setMinutes,
  isBefore,
  getDay,
  subDays,
  format,
  startOfMonth
} from 'date-fns'
import { includes, keyBy } from 'lodash'
import { Cutoffs, SpecialCutoffs, SpecialCutoff, AvailableDates } from './types'

/*
  The react-datepicker getDay(date) function
  returns a number (ex. 0 for 'sunday') instead of
  the actual day of the week in string (ex. 'sunday').
*/
export const dayReference = {
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday'
}

/*
  Accepts cutoffs and returns an array of enabled days
  ex. ['sunday', 'monday', 'tuesday']
*/
export const getEnabledPreorderDays = (cutoffs: Cutoffs) => {
  return Object.keys(cutoffs).filter((key) => cutoffs[key]['enabled'])
}

const preOrderDayIsEnabled = (date: Date, cutoffs: Cutoffs) => {
  const enabledPreorderDays = getEnabledPreorderDays(cutoffs)
  const dayOfTheWeek = dayReference[getDay(date)]
  return includes(enabledPreorderDays, dayOfTheWeek)
}

const buildCutoffDate = (date: Date, time: string, dayDifference: number) => {
  const [cutoffHour, cutoffMinutes] = time.split(':')
  return setMinutes(
    setHours(subDays(date, dayDifference), Number(cutoffHour)),
    Number(cutoffMinutes)
  )
}

const withinCutoff = (
  date: Date,
  cutoffs: Cutoffs,
  currentDate: Date = new Date() // for testing
) => {
  const dayOfTheWeek = dayReference[getDay(date)]
  const cutoff = cutoffs[dayOfTheWeek]

  if (cutoff && cutoff.time && cutoff.day_diff) {
    const cutoffDate = buildCutoffDate(date, cutoff.time, cutoff.day_diff)

    return isBefore(currentDate, cutoffDate)
  }

  return false
}

export const storeIsOperating = (date: Date, schedules: any) => {
  const dayOfTheWeek = dayReference[getDay(date)]
  return schedules[dayOfTheWeek]['enabled']
}

export const withinSpecialCutoff = (cutoff: SpecialCutoff) => {
  // Only runs if it has the necessary data
  if (cutoff.enabled && cutoff.time && cutoff.day_diff) {
    const cutoffDate = buildCutoffDate(
      new Date(cutoff.cutoff_date),
      cutoff.time,
      cutoff.day_diff
    )
    const currentDate = new Date()

    return isBefore(currentDate, cutoffDate)
  }

  return false
}

export const dateAllowedForPreorder = (
  date: Date,
  cutoffs: any,
  specialCutoffs?: SpecialCutoffs | null,
  currentDate: Date = new Date() // for testing
) => {
  if (!cutoffs) return false
  const specialCutoff =
    specialCutoffs && specialCutoffs[format(date, 'yyyy-MM-dd')]

  return specialCutoff
    ? withinSpecialCutoff(specialCutoff)
    : preOrderDayIsEnabled(date, cutoffs) &&
        withinCutoff(date, cutoffs, currentDate)
}

export const formatSpecialCutoffs = (cutoffs: SpecialCutoff[]) => {
  return keyBy(cutoffs, (cutoff: SpecialCutoff) => cutoff.cutoff_date)
}

export const getCalendarStartAndEndDates = (baseDate: Date) => {
  const formatDate = (date: Date) => format(date, 'yyyy-MM-dd')

  const start = (() => {
    const startDate = startOfMonth(baseDate)
    const startDay = getDay(startDate)
    const today = new Date()

    // If today is after the calendar start date, return today
    if (today > subDays(startDate, startDay)) {
      return today
    }

    if (startDay > 0) {
      return subDays(startDate, startDay)
    }

    if (startDay > 0) return subDays(startDate, startDay)
    return startDate
  })()

  return { startDate: formatDate(start) }
}

/** Gets new available dates and replaces previous available dates with new values */
export const formatReplaceAvailableDates = (
  availableDates: AvailableDates,
  newDates: string[],
  fulfillmentType: string
): AvailableDates => {
  return {
    ...availableDates,
    [fulfillmentType]: newDates.reduce((acc: Date[], date: string) => {
      const key = format(new Date(date), 'yyyy-MM')
      return { ...acc, [key]: [...(acc[key] || []), date] }
    }, [])
  }
}

export const isDateAvailable = (
  availableDates: AvailableDates,
  date: Date,
  fulfillmentType: string
) => {
  const key = format(date, 'yyyy-MM')
  const formattedDate = format(date, 'yyyy-MM-dd')
  const dates = Object.values(availableDates[fulfillmentType][key] || {}) || []

  return !!dates.find((date) => date === formattedDate)
}

export const getEarliestAvailableDate = (
  availableDates: AvailableDates,
  fulfillmentType: string
) => {
  const earliestMonth = Object.keys(availableDates[fulfillmentType])[0]
  const earliestDate = availableDates[fulfillmentType][earliestMonth]?.[0]
  return new Date(earliestDate)
}
