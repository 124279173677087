import { ChangeEvent, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { TipType } from '.'
import { AiOutlinePound as PoundSignIcon } from 'react-icons/ai'
import { AlertIcon } from 'shop/assets/icons'

type Props = {
  setTipAmount: (value: number) => void
  tipType: TipType
  setTipType: (type: TipType) => void
}

const CustomTip = ({ setTipAmount, tipType, setTipType }: Props) => {
  const [tipInputString, setTipInputString] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')

  // Tip type switching clears input
  useEffect(() => {
    if (tipType === 'standard') {
      setTipInputString('')
      setErrorMessage('')
    }
  }, [tipType])

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('')
    const inputValue = e.currentTarget.value
    setTipInputString(inputValue)
    if (isNaN(Number(inputValue)) || Number(inputValue) < 0) {
      setTipAmount(0)
      setErrorMessage('please enter a positive numeric value')
      return
    }
    setTipAmount(Number(inputValue))
  }

  const handleBlurConvertToCurrency = () => {
    if (isNaN(Number(tipInputString)) || Number(tipInputString) < 0) {
      setTipInputString('')
      setTipAmount(0)
      setErrorMessage('please enter a positive numeric value')
      return
    }
    if (Number(tipInputString) === 0) {
      setTipInputString('')
      setTipAmount(0)
      return
    }
    const convertedToCurrencyStirng = Number(tipInputString)?.toFixed(2)
    setTipInputString(convertedToCurrencyStirng)
    setTipAmount(Number(convertedToCurrencyStirng))
    setTipType('custom')
  }

  const handleFocus = () => {
    setTipInputString('')
    setTipAmount(0)
    setTipType('custom')
  }

  return (
    <CustomTipContainer>
      <h2>add a custom tip</h2>
      <CustomTipInputContainer
        data-testid='custom-tip'
        isSelected={tipType === 'custom'}
        hasError={!!errorMessage}
      >
        {errorMessage ? <AlertIcon /> : <PoundSignIcon />}
        <CustomTipInput
          data-testid='custom-tip-input'
          placeholder={'0.00'}
          inputMode='numeric'
          onChange={handleInput}
          onBlur={handleBlurConvertToCurrency}
          onFocus={handleFocus}
          value={tipInputString}
        />
      </CustomTipInputContainer>
    </CustomTipContainer>
  )
}

const CustomTipContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '50%',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',

    '& h2': {
      fontSize: '14px',
      fontWeight: 'normal',
      color: theme.colors['textMute']
    }
  })
)

const CustomTipInputContainer = styled.div<
  StyledHTMLElement & { isSelected: Boolean; hasError: Boolean },
  Required<Theme>
>(({ theme, isSelected, hasError }) => ({
  display: 'flex',
  borderRadius: '5px',
  border: `${isSelected ? '2px solid' : '1px solid'}`,
  borderColor: `${
    hasError
      ? theme.colors['state'].error[2]
      : isSelected
        ? theme.colors['primary']
        : theme.colors['lineColor']
  }`,
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  padding: '0 10px',
  // add margin so border width changes don't shift UI
  margin: isSelected ? '1px 0' : '2px 0',

  '& svg': {
    height: '24px',
    width: '24px',
    padding: '2px',
    color: hasError ? theme.colors['state'].error[2] : '#000',

    '& path': {
      fill: hasError ? theme.colors['state'].error[2] : '#000'
    }
  }
}))

const CustomTipInput = styled.input<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flex: 1,
    lineHeight: '30px',
    outline: 0,
    border: 0,
    width: '100%',
    boxSizing: 'border-box'
  })
)

export default CustomTip
