import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'

const OptionalLabel = () => {
  return <Label>Optional</Label>
}

const Label = styled.div<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#F5F5F5',
  gap: '8px',
  borderRadius: '12px',
  padding: '4px 12px',
  lineHeight: '22px',
  fontWeight: 600,

  '> svg': {
    fontSize: '18px'
  }
}))

export default OptionalLabel
