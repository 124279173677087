import gql from 'graphql-tag'

export const QUERY_VALID_STORES = gql`
  query getValidStores(
    $merchantId: ID!
    $fulfillmentType: String!
    $fulfillmentDate: String!
    $deliveryAddress: DeliveryAddressParams
  ) {
    getValidStores(
      merchantId: $merchantId
      fulfillmentType: $fulfillmentType
      fulfillmentDate: $fulfillmentDate
      deliveryAddress: $deliveryAddress
    ) {
      stores {
        id
        name
        earliestPickupFulfillment {
          minimumOrderValue
          orderType
          orderableAt
          fulfillmentWindow {
            from
            to
          }
        }
        earliestDeliveryFulfillment {
          minimumOrderValue
          orderType
          orderableAt
          fulfillmentWindow {
            from
            to
          }
        }
        address {
          geom {
            coordinates
          }
        }
        fulfillmentTimeslots(
          fulfillmentDate: $fulfillmentDate
          fulfillmentType: $fulfillmentType
        ) {
          startTime
          endTime
          value
        }
        slug
      }
      errors {
        message
        store {
          id
          slug
          name
        }
      }
    }
  }
`

const storeFieldsFragment = `
  storeInformation {
    welcomeMessage
    name
    contactNumber
    timezone
    description
    seoDescription
    address {
      line1
      line2
      zip
      city
      geom {
        coordinates
      }
    }
  }
  earliestPickupFulfillment {
    fulfillmentWindow {
      from
      to
    }
    orderType
    orderableAt
    minimumOrderValue
  }
  earliestDeliveryFulfillment {
    fulfillmentWindow {
      from
      to
    }
    orderType
    orderableAt
    minimumOrderValue
  }
  openingTimes {
    monday {
      closed
      startTime
      endTime
    }
    tuesday {
      closed
      startTime
      endTime
    }
    wednesday {
      closed
      startTime
      endTime
    }
    thursday {
      closed
      startTime
      endTime
    }
    friday {
      closed
      startTime
      endTime
    }
    saturday {
      closed
      startTime
      endTime
    }
    sunday {
      closed
      startTime
      endTime
    }
  }
  defaultOption {
    fulfillmentType
  }
  messages {
    title
    description
    messageType
    orderType
  }
`

export const QUERY_GET_STORE_BY_ID = gql`
  query getStore($storeId: ID!) {
    getStore(storeId: $storeId) {
      ${storeFieldsFragment}
    }
  }
`

export const QUERY_GET_STORE_BY_SLUG = gql`
  query getStore($storeSlug: String!) {
    getStore(storeSlug: $storeSlug) {
      ${storeFieldsFragment}
    }
  }
`
