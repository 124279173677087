import gql from 'graphql-tag'

export const QUERY_GET_CUSTOMER_REWARDS = gql`
  query GetRewards(
    $customerId: uuid!
    $merchantId: uuid!
    $appliedDiscountCode: String!
  ) {
    customer_rewards(
      where: {
        _or: [
          { end_datetime: { _is_null: true } }
          { end_datetime: { _gte: "NOW()" } }
        ]
        _not: { discounts: { code: { _eq: $appliedDiscountCode } } }
        customer_id: { _eq: $customerId }
        customer: { merchant: { id: { _eq: $merchantId } } }
        archived_at: { _is_null: true }
        reward: { archived_at: { _is_null: true } }
        redeemed_at: { _is_null: true }
        customer_loyalty_card: {
          loyalty_card: { archived_at: { _is_null: true } }
        }
      }
    ) {
      id
      redeemed_at
      reward {
        description
        name
        loyalty_card {
          id
          name
          image
          description
          ends_at
        }
      }
    }
  }
`

export const MUTATION_APPLY_REWARD_DISCOUNT = gql`
  mutation ApplyRewardDiscount($customerRewardId: ID!, $cartId: ID!) {
    applyRewardDiscount(customerRewardId: $customerRewardId, cartId: $cartId) {
      discount_code: discountCode
      discount_id: discountId
      discount_amount: totalDiscount
      discount_target: target
      discount_trigger: trigger
      discount_value: value
      discount_type: type
      subtotalInfo {
        reduction
      }
    }
  }
`
