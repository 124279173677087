import React from 'react'
import { ValidStore } from '../types'
import { Fulfillment } from 'shop/types'
import { isToday } from 'date-fns'
import {
  CommonOverlayTitleHeader,
  DeliveryFormHeading,
  LineBreak,
  ListItem,
  ListItemIconContainer,
  ListItemWrapper
} from './commonStyles'
import { BsChevronRight as ForwardArrow } from 'react-icons/bs'
import { FulfillmentTime } from 'shop/components/shared/FulfillmentTime/FulfillmentTime'
import Dropdown from 'shop/components/Inputs/Dropdown'
import { dropdownIsEditable } from '../utils'
import { BsShop as DropdownStore } from 'react-icons/bs'
import ModalWrapper from './ModalWrapper'
import { Theme } from 'styled-system'
import styled from '@emotion/styled'
import { StyledHTMLElement } from 'shop/theme/types'

interface DeliveryStoreProps {
  validStoresList: string[]
  currentStore: ValidStore | undefined
  storeListDropdownHandler: () => void
  isStoreListDropdownOpen: boolean
  isOverlayOpen: boolean
  handleClickOutsideStoreListDropdown: () => void
  isPickup: boolean
  setCurrentStoreHandler: (store: string) => void
  validStores: ValidStore[]
  fulfillmentDate: Date
  fulfillmentTimeStaticTimeStamp: Date
}

const DeliveryStore = ({
  validStoresList,
  currentStore,
  storeListDropdownHandler,
  isStoreListDropdownOpen,
  isOverlayOpen,
  handleClickOutsideStoreListDropdown,
  isPickup,
  setCurrentStoreHandler,
  validStores,
  fulfillmentDate,
  fulfillmentTimeStaticTimeStamp
}: DeliveryStoreProps) => {
  const validStoresListDropdown = () =>
    validStores.map((item, i) => {
      const earliestFulfillment = (
        isPickup
          ? item.earliestPickupFulfillment
          : item.earliestDeliveryFulfillment
      ) as Fulfillment
      const { orderType, fulfillmentWindow } = earliestFulfillment || {}
      const showFulfillmentWindow = !!(
        isToday(fulfillmentDate) && fulfillmentWindow
      )
      return (
        <React.Fragment key={`fragment-${item.name}-${i}`}>
          <ListItemWrapper
            onMouseDown={() => setCurrentStoreHandler(item.name)}
          >
            <ListItem
              data-testid={`store-list-item-${item.name}`}
              key={`item-${item.name}-${i}`}
              showFulfillmentWindow={showFulfillmentWindow}
              isStoreList={true}
            >
              {item.name}
              <ListItemIconContainer
                showFulfillmentWindow={showFulfillmentWindow}
              >
                <ForwardArrow />
              </ListItemIconContainer>
            </ListItem>
            <FulfillmentWindow>
              {showFulfillmentWindow && (
                <p>
                  <span style={{ color: '#595959' }}>
                    {isPickup ? 'Pickup ' : 'Deliver '}
                  </span>
                  <FulfillmentTime
                    fulfillmentWindow={fulfillmentWindow}
                    orderType={orderType}
                    variant={'list'}
                    dateTimeOverride={fulfillmentTimeStaticTimeStamp}
                  />
                </p>
              )}
            </FulfillmentWindow>
            <LineBreak />
          </ListItemWrapper>
        </React.Fragment>
      )
    })

  return (
    <>
      <DeliveryFormHeading>
        <span>Choose a store near you</span>
      </DeliveryFormHeading>
      <Dropdown
        testId='select-store-dropdown'
        textContent={currentStore?.name}
        hasSelected={!!currentStore}
        IconLeft={<DropdownStore />}
        isOpen={isStoreListDropdownOpen}
        onClick={storeListDropdownHandler}
        canEdit={dropdownIsEditable(validStoresList)}
        showEditText={true}
      >
        <ModalWrapper isOpen={isOverlayOpen}>
          <CommonOverlayTitleHeader
            onBackClick={handleClickOutsideStoreListDropdown}
            modalTitle={'Select Restaurant'}
          />
          <StoreListContainer>{validStoresListDropdown()}</StoreListContainer>
        </ModalWrapper>
      </Dropdown>
    </>
  )
}

const FulfillmentWindow = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    p: {
      margin: 0
    },
    display: 'flex',
    padding: '0 20px 16px',
    margin: '0',
    fontSize: '14px',
    alignItems: 'center',
    [theme.mediaQueries.viewport7]: {
      padding: '0 32px 16px'
    }
  })
)

const StoreListContainer = styled.div<StyledHTMLElement>(() => ({
  paddingBottom: '18px'
}))

export default DeliveryStore
