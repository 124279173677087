import { useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { ProductInfo } from '../ProductInfo'
import ProductImages from './ProductImages'
import { useReactRouter, useModal } from 'shop/hooks'
import { CategoryProduct } from '../Shop/Categories'
import CloseButton from '../ProductInfo/Header/CloseButton'
import { ProductImageURLs } from './imageUtils'
import useEscModalHandler from 'shop/hooks/useEscModalHandler'
import { slideUp } from '../Shop/commonStyles'

interface ProductModalProps extends CategoryProduct {
  closeModal?: () => void
  path?: string
}

const ProductModal = (props: ProductModalProps) => {
  const [imageUrls, setImageUrls] = useState<ProductImageURLs[]>([])
  const [productName, setProductName] = useState<string>(props.name || '')
  const { match, history } = useReactRouter()
  const mobileScrollRef = useRef<HTMLDivElement>(null)
  const { closeModal } = useModal()

  const parentUrl = match.params['slug']
    ? `/store/${match.params['slug']}`
    : `/`

  const handleCloseModal = (e?: React.MouseEvent) => {
    closeModal('fulfillment')
    e?.preventDefault()
    e?.stopPropagation()
    history.push(parentUrl)
  }

  useEffect(() => {
    // onMount lock scroll behind ProductModal
    document.body.style.overflow = 'hidden'
    return () => {
      // onDismount unlock scroll that was behind ProductModal
      document.body.style.overflow = 'visible'
    }
  }, [])

  useEscModalHandler({ onClose: handleCloseModal })

  return (
    <>
      <ContainerOverlay data-testid='productModal' onClick={handleCloseModal} />
      <Container ref={mobileScrollRef} id='product-modal-container'>
        <CloseButton parentUrl={parentUrl} />
        {imageUrls && (
          <ProductImages imageUrls={imageUrls} productName={productName} />
        )}
        <ProductInfo
          product={props}
          closeModal={handleCloseModal}
          setImageUrls={setImageUrls}
          setProductName={setProductName}
          mobileScrollRef={mobileScrollRef}
        />
      </Container>
    </>
  )
}

const ContainerOverlay = styled.section(({ theme }: any) => ({
  position: 'relative',
  background: 'white',
  [theme.mediaQueries.viewport7]: {
    background: 'rgba(0, 0, 0, 0.8)',
    height: '100%',
    maxHeight: '100vh',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 100,
    overflow: 'hidden'
  }
}))

export const Container = styled.section(({ theme }: any) => ({
  animation: `${slideUp} 50ms ease - out`,
  zIndex: theme.zIndex.productModal,
  maxWidth: theme.designMode ? '1220px' : '100%',
  margin: '0 auto',
  display: 'block',
  flexDirection: 'column',
  position: 'fixed',
  height: '100%',
  top: '0',
  width: '100%',
  overflow: 'scroll',
  background: 'white',
  // minHeight: `calc(100vh - ${ theme.defaults.navbarHeight })`,
  [theme.mediaQueries.viewport7]: {
    display: 'flex',
    border: '0 0 24px #2626268c',
    margin: '48px auto',
    maxHeight: `calc(100vh - ${theme.defaults.navbarHeight})`,
    borderRadius: '8px',
    boxShadow: '12px 24px 24px #262626b0', // sample
    overflow: 'hidden',
    flexDirection: 'row',
    position: 'fixed',
    minHeight: '90%',
    height: '90%',
    width: '90%',
    left: '5%'
  }
}))

ProductModal.defaultProps = {
  name: '',
  description: '',
  price: '0',
  imageUrls: [],
  variants: [],
  additionalImages: [],
  options: []
}

export default ProductModal
