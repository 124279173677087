import React, { useState, useEffect, SetStateAction } from 'react'
import { Modifier } from '.'
import styled from '@emotion/styled'
import { AppliedModifiers, ProductModifierGroup } from '../Product/types'
import { SelectedModifiers } from 'shop/components/Landing/types'
import {
  getInputType,
  getSubheading,
  enableIncrement,
  isValid,
  calculateGroupQuantity
} from './utils'
import { includes } from 'lodash'
import { RequiredLabel, OptionalLabel } from '../Forms'
import { MissingRequiredSection } from 'shop/types'

interface Props {
  modifiers: ProductModifierGroup[]
  invalidModifierGroups: string[]
  removeFromInvalidModifierGroups: (arg0: string) => void
  addToInvalidModifierGroups: (arg0: string) => void
  errorMessage?: React.ReactElement
  missingRequiredSections?: MissingRequiredSection[]
  setAppliedModifiers: React.Dispatch<
    SetStateAction<AppliedModifiers | undefined>
  >
  disabled?: boolean
}

interface ModifierGroupProps {
  removeFromInvalidModifierGroups: (arg0: string) => void
  addToInvalidModifierGroups: (arg0: string) => void
  invalidModifierGroups: string[]
  group: ProductModifierGroup
  errorMessage?: React.ReactElement
  missingRequiredSections?: MissingRequiredSection[]
  setAppliedModifiers: React.Dispatch<
    SetStateAction<AppliedModifiers | undefined>
  >
  disabled?: boolean
}

const Modifiers = ({
  modifiers,
  invalidModifierGroups,
  removeFromInvalidModifierGroups,
  addToInvalidModifierGroups,
  errorMessage,
  missingRequiredSections,
  setAppliedModifiers,
  disabled = false
}: Props) => {
  return (
    <>
      {modifiers.map((modifier) => (
        <ModifierGroup
          {...{
            group: modifier,
            invalidModifierGroups,
            removeFromInvalidModifierGroups,
            addToInvalidModifierGroups,
            errorMessage,
            missingRequiredSections,
            setAppliedModifiers,
            disabled
          }}
          key={modifier.id}
        />
      ))}
    </>
  )
}

const ModifierGroup = ({
  removeFromInvalidModifierGroups,
  addToInvalidModifierGroups,
  invalidModifierGroups,
  group,
  errorMessage,
  missingRequiredSections,
  setAppliedModifiers,
  disabled = false
}: ModifierGroupProps) => {
  const [selectedItems, setSelectedItems] = useState<SelectedModifiers>({})

  const minimumEnabled = !!group.minimum && group.minimum > 0
  const maximumEnabled = !!group.maximum && group.maximum > 0

  const modifierGroup = {
    name: group.name,
    id: group.id,
    minimum: group.minimum || 0,
    maximum: group.maximum || 0,
    minimum_enabled: minimumEnabled,
    maximum_enabled: maximumEnabled
  }

  const modifierItems = group.modifiers

  const inputType = getInputType(modifierGroup)
  const groupQuantity = calculateGroupQuantity(selectedItems)
  const valid = isValid(modifierGroup, groupQuantity)
  const hasInStockItem = modifierItems.some((item) => item.inStock)

  useEffect(() => {
    setAppliedModifiers((prevState) => {
      const updatedAppliedModifiers = prevState
        ? { ...prevState, [group.id]: { ...selectedItems } }
        : { [group.id]: selectedItems }

      return { ...prevState, ...updatedAppliedModifiers }
    })
  }, [selectedItems, setAppliedModifiers, group.id])

  useEffect(() => {
    if (!hasInStockItem) return

    const { id } = group
    const inInvalidModifiersGroup = includes(invalidModifierGroups, id)

    if (valid && inInvalidModifiersGroup) removeFromInvalidModifierGroups(id)
    if (!valid && !inInvalidModifiersGroup) addToInvalidModifierGroups(id)
  }, [
    invalidModifierGroups,
    addToInvalidModifierGroups,
    group,
    hasInStockItem,
    removeFromInvalidModifierGroups,
    valid
  ])

  const hasError = missingRequiredSections?.some(
    (missingSection) => missingSection.id === group.id
  )

  const modifierGroupDisabled = !hasInStockItem || disabled

  return (
    <Container data-testid='modifiers' id={group.id}>
      <Header>
        <div>
          <Heading
            {...{
              disabled: modifierGroupDisabled,
              error: hasError
            }}
          >
            {group.name}
          </Heading>
          {!modifierGroupDisabled && getSubheading(modifierGroup) && (
            <Subheading>{getSubheading(modifierGroup)}</Subheading>
          )}
          {!valid && !modifierGroupDisabled && errorMessage}
        </div>
        {!modifierGroupDisabled &&
          (modifierGroup.minimum_enabled && modifierGroup.minimum > 0 ? (
            <RequiredLabel
              state={(() => {
                if (hasError) return 'error'
                if (valid) return 'success'
                return 'default'
              })()}
            />
          ) : (
            <OptionalLabel />
          ))}
      </Header>

      <List>
        {modifierItems.map((item) => {
          if (!item) return null

          // if there is only 1 inStock Modifier to choose and it's this one
          const defaultChecked =
            modifierItems.filter((modItem) => modItem.inStock).length === 1 &&
            item.inStock

          return (
            <li key={item.id}>
              <Modifier
                selectedItems={selectedItems}
                modifier={{ ...item }}
                inputType={inputType}
                setSelectedItems={setSelectedItems}
                enableIncrement={enableIncrement(
                  modifierGroup,
                  groupQuantity + 1
                )}
                max={modifierGroup.maximum_enabled ? modifierGroup.maximum : 0}
                groupId={group.id}
                disabled={disabled}
                min={modifierGroup.minimum_enabled ? modifierGroup.minimum : 0}
                defaultChecked={defaultChecked}
              />
            </li>
          )
        })}
      </List>
    </Container>
  )
}

const Header = styled.div(({ theme }: any) => ({
  fontSize: theme.fontSizes[0],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}))

const Container = styled.div(() => ({
  margin: '0 0 8px'
}))

const List = styled.ul(() => ({
  marginBottom: '32px',
  'li + li': {
    marginTop: '8px'
  }
}))

const Subheading = styled.p(({ theme }: any) => ({
  fontSize: theme.fontSizes[0],
  margin: '4px 0 0',
  color: theme.colors.textMute
}))

const Heading = styled.h2(({ theme, disabled, error }: any) => ({
  fontSize: '16px',
  opacity: !disabled ? 1 : 0.5,
  cursor: !disabled ? 'auto' : 'not-allowed',
  color: error ? theme.colors['state']['failure'] : theme.fonts.heading.color
}))

export default Modifiers
