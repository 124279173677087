import React, { useContext, useCallback, useState } from 'react'
import { createPortal } from 'react-dom'
import { CartModal } from 'shop/components/Cart'
import { LoginModal } from 'shop/components/Login'
import { TimeSlotModal, TimeSlotModalV2 } from 'shop/components/Checkout'
import { TimeSlotValidation } from '../../components/Checkout/types'
import { FulfillmentFormModalProps } from 'shop/components/FulfillmentFormModal'
import FulfillmentFormModalNew from 'shop/components/FulfillmentFormModalNew'
import { InvalidTimeslotWarning } from 'shop/types/cart'

export type ModalState = {
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<any>
}

type HookState = {
  [modalName: string]: ModalState
}

export interface LoginModalProps {
  merchantId: string
  onClose?: () => void
  inModal?: boolean
  isOverlay?: boolean
  isOpen?: boolean
  inFulfillmentModal?: boolean
}

const modalInitialState = {
  isModalOpen: false,
  setIsModalOpen: () => {}
}

const hookInitialState: HookState = {
  cart: modalInitialState,
  checkout: modalInitialState,
  login: modalInitialState,
  timeslot: modalInitialState,
  timeslotV2: modalInitialState,
  fulfillment: modalInitialState
}

export const ModalManagerContext =
  React.createContext<HookState>(hookInitialState)

type Hook = () => {
  isModalOpen: (selectedModal?: string) => boolean
  openModal: (selectedModal?: string) => void
  closeModal: (selectModal?: string) => void
  portalModal: (selectedModal?: string) => React.ReactPortal
  loginModal: (props: LoginModalProps) => React.ReactPortal
  timeSlotModal: (props: TimeSlotValidation) => React.ReactPortal
  timeSlotModalV2: (props: InvalidTimeslotWarning | null) => React.ReactPortal
  fulfillmentModal: (props: FulfillmentFormModalProps) => React.ReactPortal
}

export const useModal: Hook = () => {
  const managedModals = useContext(ModalManagerContext)

  const cartPortal = createPortal(
    <CartModal {...{ closeCartModal: () => closeModal('cart') }} />,
    document.body
  )

  const loginModal = ({
    merchantId,
    onClose = () => closeModal('login'),
    inModal = false,
    isOverlay = false,
    isOpen = false,
    inFulfillmentModal = false
  }: LoginModalProps) => {
    const loginPortal = createPortal(
      <LoginModal
        {...{
          merchantId,
          onClose: onClose,
          inModal,
          isOverlay,
          isOpen,
          inFulfillmentModal
        }}
      />,
      document.body
    )
    return loginPortal
  }

  const timeSlotModal = (timeSlotValidation: TimeSlotValidation) => {
    return createPortal(
      <TimeSlotModal {...timeSlotValidation} />,
      document.body
    )
  }

  // Uses consumer cart validations / mutations
  const timeSlotModalV2 = (
    timeSlotValidation: InvalidTimeslotWarning | null
  ) => {
    if (!timeSlotValidation) {
      closeModal('timeslotV2')
      return null
    }
    return createPortal(
      <TimeSlotModalV2 {...timeSlotValidation} />,
      document.body
    )
  }

  const fulfillmentModal = (props: FulfillmentFormModalProps) => {
    return createPortal(
      <FulfillmentFormModalNew
        {...props}
        onClose={() => {
          closeModal('fulfillment')
          props.onClose && props.onClose()
        }}
      />,
      document.body
    )
  }

  const portalModal = useCallback(
    (selectedModal = 'cart') => {
      const portals = { cart: cartPortal }

      return portals[selectedModal]
    },
    [cartPortal]
  )

  const isModalOpen = useCallback(
    (selectedModal = 'cart'): boolean => {
      return managedModals[selectedModal].isModalOpen
    },
    [managedModals]
  )

  const openModal = useCallback(
    (selectedModal = 'cart') => {
      document.body.style.overflow = 'hidden'
      managedModals[selectedModal].setIsModalOpen(true)
    },
    [managedModals]
  )

  const closeModal = useCallback(
    (selectedModal = 'cart') => {
      // fulfillmentModal handles own scroll locking/unlocking
      if (selectedModal !== 'fulfillment') document.body.style.overflow = ''
      managedModals[selectedModal].setIsModalOpen(false)
    },
    [managedModals]
  )

  return {
    isModalOpen,
    openModal,
    closeModal,
    portalModal,
    loginModal,
    timeSlotModal,
    timeSlotModalV2,
    fulfillmentModal
  }
}

export const SetupModal = () => {
  const [isCartModalOpen, setIsCartModalOpen] = useState<boolean>(false)
  const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState<boolean>(false)
  const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false)
  const [isTimeSlotModalOpen, setIsTimeSlotModalOpen] = useState<boolean>(false)
  const [isTimeSlotModalV2Open, setIsTimeSlotModalV2Open] =
    useState<boolean>(false)
  const [isFulfillmentModalOpen, setIsFulfillmentModalOpen] =
    useState<boolean>(false)

  return {
    cart: {
      isModalOpen: isCartModalOpen,
      setIsModalOpen: setIsCartModalOpen
    },
    checkout: {
      isModalOpen: isCheckoutModalOpen,
      setIsModalOpen: setIsCheckoutModalOpen
    },
    login: {
      isModalOpen: isLoginModalOpen,
      setIsModalOpen: setIsLoginModalOpen
    },
    timeslot: {
      isModalOpen: isTimeSlotModalOpen,
      setIsModalOpen: setIsTimeSlotModalOpen
    },
    timeslotV2: {
      isModalOpen: isTimeSlotModalV2Open,
      setIsModalOpen: setIsTimeSlotModalV2Open
    },
    fulfillment: {
      isModalOpen: isFulfillmentModalOpen,
      setIsModalOpen: setIsFulfillmentModalOpen
    }
  }
}
