import { ProductCard } from './index'
import { CategoryProduct } from '../Shop/Categories'

export const Products = ({
  products,
  productCategory
}: {
  products: CategoryProduct[]
  productCategory: string
}) => {
  return (
    <>
      {products.map((product) => {
        return (
          product && (
            <li key={product.id}>
              <ProductCard
                product={product}
                productCategory={productCategory}
              />
            </li>
          )
        )
      })}
    </>
  )
}
