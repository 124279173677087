const errorGlossary = (
  errorCode: string
): {
  title: string
  messages: string[]
} => {
  switch (errorCode) {
    case 'DISCOUNT_REQUIRES_CUSTOMER_LOGIN': {
      return {
        title: 'Login Required',
        messages: [
          'For the discount to apply, you need to have an Account and to be logged in.'
        ]
      }
    }
    case 'ORDER_FAILURE': {
      return {
        title: 'Unable to place the order',
        messages: [
          'Sorry - it looks like something went wrong trying to place your order.',
          'If a payment has been taken, the funds will be released back to you as soon as possible.'
        ]
      }
    }
    default: {
      return {
        title: 'Oops, something has gone wrong',
        messages: [
          'We have encountered an unexpected problem. Please go back and try again.'
        ]
      }
    }
  }
}

export const getErrorTitle = (errorCode: string): string => {
  return errorGlossary(errorCode).title
}

export const getErrorMessage = (errorCode: string): string[] => {
  return errorGlossary(errorCode).messages
}
