import React, { HTMLAttributes, useRef } from 'react'
import styled from '@emotion/styled'
import { useShop, useCart } from 'shop/hooks'
import { CartItem as CartItemType } from 'shop/components/Landing/types'
import { priceWithVat } from '../Product/utils'
import Options from './Options'
import { MdErrorOutline as ErrorIcon } from 'react-icons/md'
import { TrackableEvent } from 'tracker'
import { trackUserActionsFBPixel, trackUserActionsGA4 } from 'tracker'
import Theme from 'shop/theme/types'
import { useMediaQueries } from 'shop/hooks'
import { AiFillPlusCircle, AiOutlineMinusCircle } from 'react-icons/ai'
import {
  findVariantName,
  merchantGA4EcommTrackAddToCart,
  merchantGA4EcommTrackRemoveFromCart,
  slerpGA4EcommTrackAddToCart,
  slerpGA4EcommTrackRemoveFromCart
} from 'tracker/GA/ecommerce'
import IconTooltip from '../Tooltip/Tooltip'

type StyledHTMLElement = React.DetailedHTMLProps<
  HTMLAttributes<HTMLElement>,
  HTMLElement
>

interface ProductImageProps {
  backgroundImage: string
}

interface Props {
  cartItem: CartItemType
  canEditQuantity: boolean
  index: number
  testLabel: string
}

type FauxCartItemProps = {
  label: string | React.ReactElement
  value: string | React.ReactElement
  emphasize?: boolean
  testId?: string
  beforeDiscountValue?: string
  withTooltip?: boolean
  tooltipMessage?: string
}

export const FauxCartItem = ({
  label,
  value,
  emphasize,
  testId,
  beforeDiscountValue,
  withTooltip = false,
  tooltipMessage = ''
}: FauxCartItemProps) => {
  const hasDiscount = !!beforeDiscountValue
  const cartItemTestId = testId ? testId : 'cartItem'

  // e.g. not the JSX.Element warnings from getDeliveryItemValue()
  const isPrice = typeof value === 'string'

  const getPriceComponents = () => {
    if (hasDiscount && isPrice) {
      return (
        <>
          <BeforeDiscountPrice>{beforeDiscountValue}</BeforeDiscountPrice>
          <AfterDiscountedPrice>{value}</AfterDiscountedPrice>
        </>
      )
    }
    if (emphasize && isPrice) {
      return (
        <EmphasizedPrice hasDiscount={!!beforeDiscountValue}>
          {value}
        </EmphasizedPrice>
      )
    }
    return <span>{value}</span>
  }
  return (
    <Container data-testid={cartItemTestId}>
      <ProductDetails>
        <Info>
          <Name>
            {emphasize ? <strong> {label} </strong> : label}
            {withTooltip && (
              <IconTooltip tooltipMessage={tooltipMessage} iconSize='15px' />
            )}
          </Name>
          <Price>{getPriceComponents()}</Price>
        </Info>
      </ProductDetails>
    </Container>
  )
}

const CartItem = ({ cartItem, canEditQuantity, testLabel, index }: Props) => {
  const itemContainer = useRef<HTMLDivElement>(null)
  const { config, merchant, cartSession } = useShop()
  const { removeProductVariantFromCart, updateLineItemInCart } = useCart()
  const { isTablet, isMobile } = useMediaQueries()

  const {
    product_variant,
    quantity,
    id,
    product_variant_id,
    applied_modifiers,
    variant_price,
    variant_vat,
    amount,
    is_valid
  } = cartItem

  const imageUrl = `https://${config.domain}.${config.assetHost}/assets/variant/${product_variant_id}`

  const handleCartIncrement = (): void => {
    updateLineItemInCart(id, quantity + 1)
      .then(() => {
        trackProductQuantityChange(TrackableEvent.ProductAdded)
      })
      .catch((err) => console.error('UPDATE CART QUANTITY ERROR: ', err))
  }

  const handleCartDecrement = (): void => {
    if (quantity === 1) {
      removeCartItem()
    } else {
      updateLineItemInCart(id, quantity - 1)
        .then(() => {
          trackProductQuantityChange(TrackableEvent.ProductRemoved)
        })
        .catch((err) => console.error('UPDATE CART QUANTITY ERROR: ', err))
    }
  }

  const removeCartItem = (): void => {
    removeProductVariantFromCart(id)
      .then(() => {
        trackProductQuantityChange(TrackableEvent.ProductRemoved)
      })
      .catch((err) => console.log('UPDATE CART QUANTITY ERROR: ', err))
  }

  const trackProductQuantityChange = (action: TrackableEvent): void => {
    const product = product_variant.product
    const eventData = {
      product_id: product.id,
      name: product.name,
      category: product.category.name,
      currency: 'gbp',
      price: priceWithVat(variant_price, variant_vat),
      quantity: quantity
    }
    const body = {
      category: product.category.name,
      action,
      label: product.name,
      value: quantity
    }

    // eccommerce tracking
    const variantName = findVariantName(
      product.product_variants || [],
      product_variant_id
    )

    const cartItemPrice =
      cartItem.amount > 0
        ? cartItem.amount / cartItem.quantity
        : priceWithVat(variant_price, variant_vat)

    const trackParams = {
      currency: 'gbp',
      // we can only ever shift the quantity by 1 each time.
      // so value is the price of the singular item changing in the cart.
      // variant price does not include modifiers, so take amount divided by quantity.
      value: cartItemPrice,
      items: [
        {
          item_id: product.id,
          item_name: product.name,
          affiliation: cartSession.cart?.store.name || '',
          item_brand: merchant.name,
          item_variant: variantName || product.name,
          price: priceWithVat(variant_price, variant_vat),
          // we can only ever shift the quantity by 1 each time.
          quantity: 1
        }
      ]
    }

    if (action === TrackableEvent.ProductAdded) {
      slerpGA4EcommTrackAddToCart(trackParams)
      merchantGA4EcommTrackAddToCart(trackParams)
    }
    if (action === TrackableEvent.ProductRemoved) {
      slerpGA4EcommTrackRemoveFromCart(trackParams)
      merchantGA4EcommTrackRemoveFromCart(trackParams)
    }

    trackUserActionsGA4(body, 'slerpGA4Tracking')

    // legacy tracking
    trackUserActionsFBPixel(action, eventData)
    trackUserActionsGA4(body, 'merchantGA4Tracking')
  }

  const ItemPrice = () => {
    return (
      <Price data-testid={`${testLabel}ItemPrice[${index}]`}>
        £{amount.toFixed(2)}
      </Price>
    )
  }

  /** Shows quantity controls based on canEditQuantity prop */
  const QuantityControls = (): JSX.Element => {
    if (canEditQuantity) {
      return (
        <QuantityPriceContainer>
          <Minus
            data-testid={'decrement-button'}
            onClick={handleCartDecrement}
          />
          <Quantity>{quantity}</Quantity>
          <Plus
            data-testid={'increment-button'}
            onClick={handleCartIncrement}
          />
          {isTablet && <ItemPrice />}
        </QuantityPriceContainer>
      )
    } else {
      return (
        <QuantityPriceContainer>
          <Quantity>{quantity}</Quantity>
          {isTablet && <ItemPrice />}
        </QuantityPriceContainer>
      )
    }
  }

  return (
    <Container data-testid='cartItem' key={id} ref={itemContainer}>
      <ProductImage backgroundImage={imageUrl} />
      <ProductDetails>
        <Info>
          <Name>
            {product_variant.product.name}
            {!is_valid && (
              <ErrorIconContainer>
                <ErrorIcon />
              </ErrorIconContainer>
            )}
          </Name>
          <QuantityControls />
        </Info>
        {(!!applied_modifiers?.length ||
          !!product_variant?.options?.length) && (
          <Options
            modifiers={applied_modifiers || []}
            options={product_variant?.options || []}
          />
        )}
        {isMobile && <ItemPrice />}
      </ProductDetails>
    </Container>
  )
}

const ErrorIconContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    color: theme.colors['state']['error'][5],
    margin: 'auto 5px',
    display: 'inline-flex',
    alignContent: 'center'
  })
)

const Info = styled.div<StyledHTMLElement, Required<Theme>>(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  lineHeight: '1.4em',
  alignItems: 'center'
}))

const Container = styled.div<StyledHTMLElement, Required<Theme>>(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center'
}))

const commonIconProperties = {
  width: '20px',
  height: '20px',
  cursor: 'pointer',
  '&:disabled': {
    cursor: 'auto',
    color: '#E5E5E5'
  }
}

const Plus = styled(AiFillPlusCircle)(() => ({
  ...commonIconProperties,
  '&:hover': {
    color: '#2A2A2A'
  },
  '&:active': {
    color: '#414141'
  }
}))

const Minus = styled(AiOutlineMinusCircle)(() => ({
  ...commonIconProperties,
  '&:hover': {
    background: '#F2F2F2',
    borderRadius: '20px'
  },
  '&:active': {
    background: '#E5E5E5',
    borderRadius: '20px'
  }
}))

const QuantityPriceContainer = styled.div<StyledHTMLElement>(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '60%',
  alignItems: 'center'
}))

const EmphasizedPrice = styled.strong<
  StyledHTMLElement & { hasDiscount: boolean },
  Required<Theme>
>(({ hasDiscount, theme }) => ({
  color: hasDiscount ? theme.colors['discounted'] : theme.colors['textBold']
}))

const AfterDiscountedPrice = styled.p<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    margin: 0,
    color: theme.colors['discounted']
  })
)
const BeforeDiscountPrice = styled.s<StyledHTMLElement, Required<Theme>>(
  () => ({
    margin: '0 1ch 0 9px',
    fontWeight: 'normal',
    display: 'inline-flex',
    color: '#666'
  })
)

const Price = styled.div<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  flexShrink: 0,
  fontSize: theme.fontSizes[2],
  margin: '0',
  fontWeight: 'normal',
  alignItems: 'flex-start',
  justifyContent: 'normal',
  display: 'flex',
  height: '100%',
  [theme['mediaQueries']['viewport7']]: {
    justifyContent: 'flex-end',
    margin: '0 0 0 16px',
    // for warning and loading icons
    alignItems: 'center',
    gap: '3px'
  }
}))

export const Name = styled.h4<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    gap: '3px',
    height: '100%',
    wordBreak: 'break-word',
    fontSize: theme.fontSizes[2],
    fontWeight: 500,
    flexBasis: '60%',
    [theme['mediaQueries']['viewport7']]: {
      alignItems: 'flex-start',
      flexBasis: '40%'
    }
  })
)

const Quantity = styled.strong<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    fontSize: theme.fontSizes[2],
    fontWeight: 400,
    margin: '0 6px'
  })
)

const ProductImage = styled.div<ProductImageProps, Required<Theme>>(
  ({ theme, backgroundImage }) => ({
    [theme['mediaQueries']['viewport6']]: {
      background: `lightgray url(${backgroundImage})`,
      alignSelf: 'flex-start',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      marginRight: '12px',
      borderRadius: '18px',
      flex: '0 0 60px',
      height: '60px'
    },
    [theme['mediaQueries']['viewport7']]: {
      flex: '0 0 45px',
      height: '45px'
    }
  })
)

const ProductDetails = styled.div<StyledHTMLElement, Required<Theme>>(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 'auto',
  '> strong': {
    margin: 0
  }
}))

export default CartItem
