import { HTMLAttributes } from 'react'
import styled from '@emotion/styled'
import Theme from 'shop/theme/types'

type StyledHTMLElement = React.DetailedHTMLProps<
  HTMLAttributes<HTMLElement>,
  HTMLElement
>

interface StepStyles extends StyledHTMLElement {
  stepCount: number
  stepCompleted?: boolean
  splitSteps?: boolean
  withColor?: boolean
}

interface Props {
  stepCount: number
  stepsCompleted: number
  splitSteps?: boolean
  roundCorners?: boolean
  withColor?: boolean
}

const ProgressBar = ({
  stepCount,
  stepsCompleted,
  splitSteps = true,
  roundCorners = false,
  withColor = false
}: Props) => {
  const steps = Array.from(Array(stepCount).keys())
  return (
    <Container {...{ roundCorners }} data-testid='progress-bar'>
      {steps.map((step: number) => (
        <Step
          key={`step-${step}`}
          {...{
            stepCount: steps.length,
            stepCompleted: step < stepsCompleted,
            splitSteps,
            roundCorners,
            withColor
          }}
        />
      ))}
    </Container>
  )
}

const Container = styled.div<
  StyledHTMLElement & { roundCorners: boolean },
  Required<Theme>
>(({ theme, roundCorners }) => ({
  height: '10px',

  '& div:first-of-type': {
    borderTopLeftRadius:
      roundCorners && theme.radii ? `${theme.radii[2]}px` : 0,
    borderBottomLeftRadius:
      roundCorners && theme.radii ? `${theme.radii[2]}px` : 0
  },
  '& div:last-of-type': {
    borderRight: 'none',
    borderTopRightRadius:
      roundCorners && theme.radii ? `${theme.radii[2]}px` : 0,
    borderBottomRightRadius:
      roundCorners && theme.radii ? `${theme.radii[2]}px` : 0
  }
}))

const calculateStepColor = (
  withColor: boolean = false,
  stepCompleted: boolean = false,
  primaryColor: string
) => {
  if (stepCompleted) {
    return withColor ? primaryColor : 'rgba(0,0,0,0.2)'
  }
  return 'rgba(0,0,0,0.1)'
}
const Step = styled.div<StepStyles, Required<Theme>>(
  ({ theme, stepCount, stepCompleted, splitSteps, withColor }) => ({
    height: '10px',
    width: `${100 / stepCount}%`,
    display: 'inline-block',
    backgroundColor: calculateStepColor(
      withColor,
      stepCompleted,
      theme.colors['primary']
    ),
    verticalAlign: 'top',
    borderRight: splitSteps ? '1px solid white' : ''
  })
)

export default ProgressBar
