import React, { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useShop } from 'shop/hooks'
import { grantPixelConsent } from 'tracker'

const Cookies = () => {
  const [isCookieNoticeClosed, setIsCookieNoticeClosed] = useState(false)
  const { config } = useShop()

  // TODO: Save this to controls v2. This is only a temporary fix for
  // PlanetOrganic to go live this 16 July 2020
  const privacyUrl =
    config.domain === 'planetorganic'
      ? 'https://www.planetorganic.com/privacy-policy/'
      : 'https://www.slerp.com/privacy-policy/'

  const allowCookies = useCallback(() => {
    try {
      if (window.localStorage) {
        // localStorage.setItem only accepts string as value :(
        window.localStorage.setItem('cookiesAllowed', 'true')
        grantPixelConsent()
      }
    } catch (err) {
      // iOS Safari Private Browsing mode may throw an error when using
      // localStorage. Ignore it.
    }
  }, [])

  useEffect(() => {
    allowCookies()
  }, [allowCookies])

  const closeNotice = () => {
    try {
      if (window.localStorage) {
        window.localStorage.setItem('closeNotice', 'true')
        setIsCookieNoticeClosed(true)
      }
    } catch (err) {
      // iOS Safari Private Browsing mode may throw an error when using
      // localStorage. Ignore it.
    }
  }

  const CookieDisclaimer = () => {
    return (
      <Container>
        <p>
          By using our site you are consenting to the use of cookies as
          described in our <a href={privacyUrl}>privacy policy</a>. You can
          change your cookie settings at any time, but this may impact your
          experience on our site.
        </p>
        <Button onClick={closeNotice}>Ok</Button>
      </Container>
    )
  }

  return isCookieNoticeClosed ? <></> : <CookieDisclaimer />
}

const Button = styled.button(({ theme }: any) => ({
  background: '#fff',
  color: '#000',
  cursor: 'pointer',
  alignSelf: 'flex-start',
  fontSize: theme.fontSizes[0],
  height: '25px',
  width: '50px',
  borderRadius: '12px',
  border: '0px',
  [theme.mediaQueries.viewport7]: {
    width: '55px',
    height: '30px',
    borderRadius: '7px'
  }
}))

const Container = styled.div(({ theme }: any) => ({
  backgroundColor: theme.colors.primary,
  fontSize: theme.fontSizes[0],
  padding: '12px 16px',
  lineHeight: '1.6em',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  p: {
    width: '95%',
    margin: '0 8px 0 0'
  },
  a: {
    fontWeight: 'bold',
    color: 'white',
    textDecoration: 'none'
  }
}))

export default Cookies
