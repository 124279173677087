import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import ContentLoader from 'react-content-loader'

const OrderPageLoader = () => {
  return (
    <Container>
      <DesktopSection>
        <LeftSection>
          <ContentLoader speed={2}>
            <rect x='0' y='0' width='100%' height='40%' border-radius='12px' />
            <rect
              x='0'
              y='45%'
              width='100%'
              height='55%'
              border-radius='12px'
            />
          </ContentLoader>
        </LeftSection>
        <RightSection>
          <ContentLoader speed={2}>
            <rect x='0' y='0' width='100%' height='100%' border-radius='12px' />
          </ContentLoader>
        </RightSection>
      </DesktopSection>

      <MobileSection>
        <ContentLoader speed={2}>
          <rect x='0' y='0' width='100%' height='40%' border-radius='12px' />
          <rect x='0' y='42%' width='100%' height='40%' border-radius='12px' />
          <rect x='0' y='84%' width='100%' height='50%' border-radius='12px' />
        </ContentLoader>
      </MobileSection>
    </Container>
  )
}

const Container = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexGrow: 1,
    minHeight: '95vh',
    justifyContent: 'center',

    [theme.mediaQueries.viewport5]: {
      padding: '40px'
    },

    [theme.mediaQueries.viewport12]: {
      padding: '32px 64px 32px 64px'
    }
  })
)

const LeftSection = styled.div<StyledHTMLElement, Required<Theme>>(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '550px',

  '& svg': {
    height: '100%',
    borderRadius: '12px'
  }
}))

const RightSection = styled.div<StyledHTMLElement, Required<Theme>>(() => ({
  display: 'flex',
  flexGrow: 1,

  '& svg': {
    borderRadius: '12px'
  }
}))

const DesktopSection = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'none',

    [theme.mediaQueries.viewport12]: {
      display: 'flex',
      flexGrow: 1,
      gap: '64px'
    }
  })
)

const MobileSection = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    width: '650px',

    '& svg': {
      borderRadius: '12px'
    },

    [theme.mediaQueries.viewport12]: {
      display: 'none'
    }
  })
)

export default OrderPageLoader
