import styled from '@emotion/styled'
import CenterModal from 'shop/components/Modal/CenterModal'
import { useShop, useModal, useAppContent, useConsumerCart } from 'shop/hooks'
import Logo from 'shop/components/NavBar/Logo'
import { Button } from 'shop/components'
import { TrackableEvent, trackAction } from 'tracker'
import useRouter from 'use-react-router'
import { slideUp } from '../Shop/commonStyles'
import { getLogoURL } from 'shop/utils/common'
import { InvalidTimeslotWarning } from 'shop/types/cart'

const TimeSlotModalV2 = ({
  nextAvailableTimeslot,
  isStoreOpen
}: InvalidTimeslotWarning) => {
  const { config } = useShop()
  const { domain, assetHost } = config
  const { closeModal } = useModal()
  const { history } = useRouter()
  const { cart, updateFulfillmentConsumerCart } = useConsumerCart()
  const { logoImage } = useAppContent()
  const logoUrl = logoImage || getLogoURL(domain, assetHost)

  const isPreorder = cart?.fulfillment.orderType === 'PREORDER'

  const trackTimeslotEvent = (event: TrackableEvent): void => {
    trackAction(
      {
        category: pageNameHandler(),
        action: event,
        label: 'Timeslot Expiration Modal'
      },
      { event }
    )
  }

  const returnToShop = (): void => {
    trackTimeslotEvent(TrackableEvent.ReturnFromTimeslot)
    history.push(`/store/${cart?.store?.slug}`)
  }

  const updateTimeSlot = (): void => {
    if (!cart?.id) return

    updateFulfillmentConsumerCart({
      fulfillmentTime: nextAvailableTimeslot.value
    })
      .then(() => {
        trackTimeslotEvent(TrackableEvent.UpdateTimeslot)
        closeModal('timeslotV2')
      })
      .catch(() => {
        returnToShop()
      })
  }

  const pageNameHandler = (): string => {
    const pathname = new URL(window.location.href).pathname
    const page = pathname.split('/')[2]

    const pageNames: { [key: string]: string } = {
      pay: 'Payment Page',
      checkout: 'Checkout Page'
    }

    return pageNames[page] || 'Shop Page'
  }

  const shouldRenderNextAvailableTimeslot =
    !isPreorder && nextAvailableTimeslot?.datetime && isStoreOpen

  const renderMessage = (): JSX.Element => {
    if (shouldRenderNextAvailableTimeslot) {
      return (
        <p data-testid='expired-for-the-day'>
          Unfortunately the timeslot you selected has expired. Would you like to
          order for {nextAvailableTimeslot.range} instead?
        </p>
      )
    }

    const testId = 'expired-indefinitely'
    const storeOpenMsg = isStoreOpen
      ? 'Please return and order for another day.'
      : 'Please return and order for a day we are open.'
    const message = isPreorder
      ? `Unfortunately the timeslot you selected has expired and there are no timeslots available on your selected date. ${storeOpenMsg}`
      : `Unfortunately the timeslot you selected has expired and there are no timeslots available today. ${storeOpenMsg}`

    return <p data-testid={testId}>{message}</p>
  }

  const renderActions = (): JSX.Element | null => {
    if (shouldRenderNextAvailableTimeslot) {
      return (
        <>
          <Button
            onClick={updateTimeSlot}
            testId='change-timeslot-and-continue'
          >
            Change timeslot and continue
          </Button>
          <DividingOr> or </DividingOr>
        </>
      )
    }
    return null
  }

  return (
    <CenterModal canClose={false}>
      <Prompt>
        <LogoContainer>
          <Logo imageUrl={logoUrl} url={'#'} />
        </LogoContainer>
        {renderMessage()}
        <Actions>
          {renderActions()}
          <Button
            variant={'secondary'}
            onClick={returnToShop}
            testId='back-to-shop'
          >
            Return
          </Button>
        </Actions>
      </Prompt>
    </CenterModal>
  )
}

const Actions = styled.div(() => ({
  marginTop: '16px',
  width: '100%'
}))

const LogoContainer = styled.div(({ theme }: any) => ({
  height: '96px',
  width: 'auto',

  [theme.mediaQueries.viewport7]: {
    height: '64px',
    width: '64px',
    marginBottom: '16px'
  }
}))

const DividingOr = styled.p(({ theme }: any) => ({
  overflow: 'hidden',
  textAlign: 'center',
  margin: '16px 0',
  color: theme.colors.lightgray,

  '&:before, &:after': {
    backgroundColor: theme.colors.lightgray,
    content: '""',
    display: 'inline-block',
    height: '1px',
    position: 'relative',
    verticalAlign: 'middle',
    width: '50%',
    opacity: 0.4
  },

  '&:before': {
    left: '0.2em',
    marginLeft: '-50%'
  },

  '&:after': {
    right: '0.2em',
    marginRight: '-50%'
  }
}))

const Prompt = styled.div(({ theme }: any) => ({
  animation: `${slideUp} 100ms ease-out`,
  position: 'relative',
  borderRadius: '4px',
  overflowY: 'auto',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  fontFamily: theme.fonts.normal,
  margin: '24px auto',
  maxWidth: '480px',
  width: '100%',
  padding: '16px',
  alignItems: 'center',
  '> p': {
    marginBottom: '16px'
  },
  [theme.mediaQueries.viewport7]: {
    maxWidth: '960px',
    width: '100%',
    height: 'auto',
    padding: '32px',
    marginBottom: '32px'
  }
}))

export default TimeSlotModalV2
