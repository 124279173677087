import React, { useEffect } from 'react'
import { ProductModal } from 'shop/components'
import { CategoryProduct } from 'shop/components/Shop/Categories'
import { useShop } from 'shop/hooks'
import { isCartDateExpired } from 'shop/components/Cart/utils'

interface LocationProps {
  state: CategoryProduct
}
interface ProductProps {
  location?: LocationProps | any
}

const Product = ({ location }: ProductProps) => {
  let product
  const { cartSession } = useShop()

  // location.state only has a value if the
  // user came from the products page.
  // it's always empty if it was accessed directly
  if (isCartDateExpired(cartSession)) {
    localStorage.removeItem('cartId')
  }

  useEffect(() => {
    if (location && !location.state) {
      localStorage.removeItem('cartId')
    }
  }, [location.state])

  if (location.state) {
    // location.state comes from react-router
    // which we use to pass a full Product record from the previous query
    // to this page for faster loading
    // See ProductCard.tsx for more details

    product = location.state.product
  }

  return (
    <>
      <ProductModal {...product} />
    </>
  )
}

export default Product
