import {
  useState,
  useEffect,
  Fragment,
  useContext,
  HTMLAttributes,
  useCallback
} from 'react'
import styled from '@emotion/styled'
import { useShop, FlashContext } from 'shop/hooks'
import {
  Container as StyledButton,
  StyledButtonType
} from 'shop/components/Controls/Button'
import { MdClose as RemoveIcon } from 'react-icons/md'
import { AiOutlineTag as TagIcon } from 'react-icons/ai'
import { useForm } from 'react-hook-form'
import { Discount } from './types'
import { formatMoney } from '../Cart/utils'
import Spinner from '../Loader/Spinner'
import { getTargetDescription } from './utils'
import Theme from 'shop/theme/types'
import { capitalize } from 'lodash'
import { FetchResult } from '@apollo/client'

type StyledHTMLElement = React.DetailedHTMLProps<
  HTMLAttributes<HTMLElement>,
  HTMLElement
>

type DeliveryChargeData = {
  deliveryChargeInfo: {
    deliveryCharge: string
    deliveryChargeBeforeDiscount: string
    deliveryChargeReduction: string
    deliveryChargeReductionReason: string
    deliveryPricingBand: {
      lowerThreshold: string
      percentageDiscount: number
      upperThreshold: string
    }
  }
}

export type ApplyDiscountApiResult = FetchResult<{
  discount: AutomaticDiscount & DeliveryChargeData
}>

export type RemoveDiscountApiResult = FetchResult<{
  cart: {
    id: string
  } & DeliveryChargeData
}>

export type AutomaticDiscount = {
  discountId: string
  totalDiscount: number
  discountCode: string
  target: 'all_products' | 'delivery_fee' | 'product_category' | null
  trigger?: 'manual' | 'automatic' | null
  value: number
  type: 'percentage' | 'fixed' | null
  subtotalInfo?: {
    reduction: number
  }
  storeFeeInfo: {
    basePrice: string | null
    reduction: string | null
    discountedPrice: string | null
  } | null
}

export type DiscountFormProps = {
  isDiscountRemovable?: boolean
  discountCode: string | null
  discountAmount: number
  deliveryCharge: number
  discountTarget:
    | 'all_charges'
    | 'all_products'
    | 'subtotal_delivery_fee'
    | 'delivery_fee'
    | 'product_category'
    | null
  discountTrigger?: 'manual' | 'automatic' | null
  discountValue: number
  discountType: 'percentage' | 'fixed' | null
  subtotal: number
  minimumOrderValue: number
  saveAddressAndQuoteFee: () => void
  testMode?: boolean
  claimAutomaticDiscount?: (cartId: string) => Promise<void | Discount>
  checkoutApplyDiscount: (discountCode: string) => Promise<void>
  checkoutRemoveDiscount: () => Promise<void>
}

const DiscountForm = ({
  isDiscountRemovable = true,
  discountCode,
  discountAmount,
  discountTarget,
  discountTrigger,
  discountValue,
  discountType,
  subtotal,
  minimumOrderValue,
  saveAddressAndQuoteFee,
  claimAutomaticDiscount,
  testMode,
  checkoutApplyDiscount,
  checkoutRemoveDiscount
}: DiscountFormProps) => {
  const [isDiscountLoading, setIsDiscountLoading] = useState(false)
  const [isRemoveDiscountLoading, setIsRemoveDiscountLoading] = useState(false)
  const { cartSession } = useShop()
  const { cart } = cartSession
  const cartId = cart?.id || ''
  const { handleSubmit, register } = useForm()
  const { pushMessage } = useContext(FlashContext)

  type SubmitData = {
    discount_code: string
  }

  const handleApplyDiscount = useCallback(
    async (discount_code: string) => {
      setIsDiscountLoading(true)
      return checkoutApplyDiscount(discount_code)
    },
    // [client] causes endless api calls
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cartId, checkoutApplyDiscount]
  )

  const onApplyDiscount = ({ discount_code }: SubmitData) =>
    handleApplyDiscount(discount_code)
      .catch((e: Error) => {
        if (e.message !== 'test') console.warn(e.message)
      })
      .finally(() => {
        setIsDiscountLoading(false)
      })

  const onRemoveDiscount = useCallback(
    async () => {
      const requoteDeliveryFee = subtotal === 0 && minimumOrderValue > 0
      setIsRemoveDiscountLoading(true)
      return checkoutRemoveDiscount().finally(() => {
        if (requoteDeliveryFee) saveAddressAndQuoteFee()
        setIsRemoveDiscountLoading(false)
      })
    },
    // [saveAddressAndQuoteFee, client] causes endless api calls
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cartId, minimumOrderValue, subtotal]
  )

  // Attempt to reapply discount to trigger validations against cart
  useEffect(() => {
    if (discountCode && !testMode) {
      handleApplyDiscount(discountCode)
        .then(() => {
          // The discount is still valid but apply a
          // better autodiscount if there's any applicable
          if (discountTrigger === 'automatic' && claimAutomaticDiscount) {
            claimAutomaticDiscount(cartId).then((discountObj) => {
              if (discountObj && discountObj.discount_code !== discountCode) {
                pushMessage({
                  type: 'success',
                  timeout: 3000,
                  content: 'Your discount was automatically upgraded!'
                })
              }
            })
          }
        })
        .catch((e: Error) => {
          // The discount is no longer applicable
          onRemoveDiscount().then(() => {
            // Attempt to assign the next applicable autodiscount, if any
            if (discountTrigger === 'automatic' && claimAutomaticDiscount) {
              claimAutomaticDiscount(cartId)
            }
          })
        })
    }
    // [claimAutomaticDiscount] causes endless api calls
    // [onRemoveDiscount, discountCode, discountTrigger, pushMessage] causes tests to fail
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartId, testMode])

  if (discountCode && discountAmount)
    return (
      <Fragment>
        <DiscountWidget
          onSubmit={handleSubmit(onRemoveDiscount)}
          data-testid='removeDiscountForm'
        >
          <RemovableTag
            isDiscountRemovable={isDiscountRemovable}
            tag={discountCode || ''}
            isLoading={isRemoveDiscountLoading}
          />
          <DiscountDetails
            totalDiscount={formatMoney(-discountAmount)}
            discountType={discountType}
            discountTarget={discountTarget}
            discountValue={+discountValue}
          />
        </DiscountWidget>
      </Fragment>
    )

  return (
    <DiscountSection>
      <DiscountWidget
        onSubmit={handleSubmit(onApplyDiscount)}
        data-testid='discountForm'
      >
        <DiscountInput
          type='text'
          placeholder='Enter discount code'
          name='discount_code'
          ref={register}
          data-testid='discountInput'
          {...{ roundedEdge: 'left' }}
        />
        <Button
          size='md'
          data-testid='applyDiscountButton'
          {...{ roundedEdge: 'right' }}
        >
          {isDiscountLoading ? (
            <>
              <Spinner size='10px' />
              Applying
            </>
          ) : (
            'Apply'
          )}
        </Button>
      </DiscountWidget>
    </DiscountSection>
  )
}

type RemovableTagProps = {
  isDiscountRemovable: boolean
  tag: string
  isLoading: boolean
}

export type DiscountTarget =
  | 'all_charges'
  | 'all_products'
  | 'delivery_fee'
  | 'product_category'
  | 'product_variant'
  | 'subtotal_delivery_fee'
  | null

type DiscountDetailsProps = {
  totalDiscount: string | Element
  discountTarget: DiscountTarget
  discountValue: number
  discountType: 'percentage' | 'fixed' | null
}

export const DiscountDetails = ({
  totalDiscount,
  discountTarget,
  discountValue,
  discountType
}: DiscountDetailsProps) => {
  const discount =
    discountType === 'percentage'
      ? `${discountValue}%`
      : formatMoney(discountValue)
  return (
    <DiscountDetailsContainer>
      <DiscountDescription>
        {`${discount} off on ${getTargetDescription(discountTarget)} `}
      </DiscountDescription>
    </DiscountDetailsContainer>
  )
}

export const DiscountTag = ({ code }: { code: string }) => {
  return (
    <Tag data-testid='discountCode'>
      <Icon>
        <TagIcon />
      </Icon>
      <Code>{code}</Code>
    </Tag>
  )
}

const RemovableTag = ({
  tag,
  isLoading,
  isDiscountRemovable
}: RemovableTagProps) => {
  return (
    <TagContainer>
      <DiscountTag code={tag} />
      {isDiscountRemovable && (
        <Remove data-testid='removeDiscountButton' size='md'>
          {isLoading ? (
            <>
              <Spinner size='10px' />
            </>
          ) : (
            <RemoveIcon />
          )}
        </Remove>
      )}
    </TagContainer>
  )
}

const DiscountDetailsContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  () => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'right',
    fontSize: '12px'
  })
)

const DiscountDescription = styled.p<StyledHTMLElement, Required<Theme>>(
  () => ({
    display: 'inline-block',
    alignSelf: 'flex-end',
    paddingLeft: '5px',
    margin: '0',

    '& span': {
      display: 'inline-block',
      margin: 0,
      padding: 0
    }
  })
)

const TagContainer = styled.div<StyledHTMLElement, Required<Theme>>(() => ({
  height: '40px',
  display: 'flex'
}))

const Remove = styled.button<StyledButtonType, Required<Theme>>(({ theme }) => {
  return {
    fontSize: theme.fontSizes[2],
    margin: 0,
    padding: '10px 8px',
    backgroundColor: '#fafafa',
    border: '0.5px solid #ddd',
    borderLeft: 'none',
    color: '#acacac',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    height: '100%',
    '> svg': {
      margin: '0 4px',
      display: 'block'
    },
    '&:hover': {
      color: 'firebrick',
      cursor: 'pointer',
      transition: 'color 200ms ease-in'
    }
  }
})

const Icon = styled.i<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  display: 'flex',
  fontSize: theme.fontSizes[1],
  lineHeight: '1em',
  paddingRight: '4px',
  '> svg': {
    display: 'inline-block'
  }
}))

const Code = styled.p<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  display: 'block',
  lineHeight: '1em',
  margin: '0',
  fontWeight: 'bold',
  fontSize: theme.fontSizes[0]
}))

const Tag = styled.div<StyledHTMLElement, Required<Theme>>(() => ({
  display: 'flex',
  alignItems: 'center',
  flex: '1 0 auto',
  padding: '8px 16px',
  backgroundColor: '#fafafa',
  border: '0.5px solid #ddd',
  borderTopLeftRadius: '4px',
  borderBottomLeftRadius: '4px',
  '&:only-child': { borderRadius: '4px' }
}))

// retarded interaction between typescript and emotion
const DiscountWidget = styled.form<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    height: '40px',
    justifyContent: 'space-between',
    // alignItems: 'center',
    width: '100%'
  })
)

const DiscountInput = styled.input<
  StyledHTMLElement & { roundedEdge: 'right' | 'left' },
  Required<Theme>
>(({ theme, roundedEdge }) => ({
  flex: '1 1 auto',
  fontSize: theme.fontSizes[1],
  border: '1px solid lightgrey',
  padding: '8px 15px',
  [`borderTop${capitalize(roundedEdge)}Radius`]: `50px`,
  [`borderBottom${capitalize(roundedEdge)}Radius`]: `50px`,

  '&:focus': {
    outline: 'none',
    border: '1px solid black'
  }
}))

// retarded interaction between typescript and emotion
const Button = styled(StyledButton)<
  StyledButtonType & { roundedEdge: 'right' | 'left' },
  Required<Theme>
>(({ theme, roundedEdge }) => ({
  padding: '0 16px',
  width: 'auto',
  flex: '0 1 30%',
  fontFamily: theme['fonts']['heading']['family'],
  cursor: 'pointer',
  fontSize: theme.fontSizes[0],
  color: theme['colors']['white'],
  borderRadius: 'unset',
  height: '40px',
  [`borderTop${capitalize(roundedEdge)}Radius`]: `50px`,
  [`borderBottom${capitalize(roundedEdge)}Radius`]: `50px`
}))

const DiscountSection = styled.div<StyledHTMLElement, Required<Theme>>(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%'
}))

export default DiscountForm
