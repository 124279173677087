import { ValidStore } from 'shop/components/Landing/types'
import keyBy from 'lodash/keyBy'

export const getStoreById = (storeList: ValidStore[], storeId?: string) => {
  if (!storeId) return storeList[0]

  const keyedStores = keyBy(storeList, (store) => store.id)

  // returns first option when valid store does not contain store with the store id
  return keyedStores[storeId] || storeList[0]
}

export const getStoreUrl = (storeSlug: string) => {
  return `/store/${storeSlug}`
}
