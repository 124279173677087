import React, { useCallback, useEffect, useState } from 'react'

import { useLoader, useShop, useSlerpCart } from 'shop/hooks'
import { LoaderProps } from './types'

export const SlerpCartLoader = ({
  isParentLoaded = true,
  children
}: LoaderProps) => {
  const { setSlerpCart, setIsSlerpCartLoading } = useSlerpCart()
  const { fetchSlerpCart } = useLoader()
  const { cartSession } = useShop()
  const { cart } = cartSession

  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const fetchData = useCallback(async () => {
    const slerpCartResult = await fetchSlerpCart(cart)

    if (slerpCartResult) {
      setSlerpCart(slerpCartResult)
    }

    setIsSlerpCartLoading(false)
    setIsLoaded(Boolean(slerpCartResult && isParentLoaded))
  }, [fetchSlerpCart, setSlerpCart, isParentLoaded, cart])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return <React.Fragment>{children(isLoaded)}</React.Fragment>
}
