import gql from 'graphql-tag'

const slerpCartFragment = `
  fulfillment {
    fulfillmentType
    orderType
    fulfillmentWindow {
      from
      to
    }
  }
`

export const QUERY_GET_SLERP_CART = gql`
  query getSlerpCart($cartId: uuid!) {
    getSlerpCart(cartId: $cartId) {
      ${slerpCartFragment}
    }
  }
`
