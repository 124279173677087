import gql from 'graphql-tag'

// Non hasura order query fields
const ORDER_FIELDS = `
  reference
  additionalItems {
    giftWrapping {
      message
      price {
        base
      }
    }
  }
  customField {
    name
    value
  }
  customerDetails {
    email
  }
  deliveryAddress {
    city
    coordinates {
      lat
      lng  
    }
    flatNumber
    lineTwo
    lineOne
    zip
  }
  delivery {
    driver {
      contactNumber
      coordinates {
        lat
        lng
      }
      firstName
      lastName
      transportType
    }
    dropoffTime
    pincode
    service
    status
  }
  dropoffNotes
  fulfilledAt
  fulfillment {
    type
    orderType
    tableNumber
    window {
      from
      to
    }
  }
  isEligibleForAccountCreation
  isTrackable
  loyalty {
    cards {
      availableRewards {
        id
        isApplicable
        details {
            description
            name
        }
        status
        isNew
      }
      description
      id
      name
      stampsFromOrder {
        isEarned
        total
      }
      nextRewards {
        stampsForNextReward
        rewardDetails {
            description
            name
        }
    }
      stamps {
          currentStamps
          maximumStamps
          stampLabel
          stampSource
      }
    }
  }
  orderItems {
    id
    modifierGroups {
      name
      modifiers {
        name
        price {
          base
          }
        quantity
      }
    }
    name
    options {
      name
      values
    }
    product {
      id
      name
    }
    quantity
    total { 
      base
    }
    variantId
    variantPrice {
      base
    }
  }
  orderNotes
  status
  store {
    id
    address {
      city
      coordinates {
        lat
        lng
      }
      lineOne
      lineTwo
      zip
    }
    slug
  }
  summary {
    delivery {
      base
      discounted
    }
    deliveryChargeInfo {
      reductionReason,
      pricingBand {
        id
        lowerThreshold
        percentageDiscount
        upperThreshold
      }
    }
    discount {
      amount
      code
      description
    }
    storeFee {
      price { 
        base
        discounted
      }
    }
    subtotal {
      base
      discounted
    }
    tip
    total {
      base
      discounted
    }
  }
  trackingToken
`

export const QUERY_GET_ORDER = gql`
  query order($reference: ID!) {
    order(reference: $reference) {
        ${ORDER_FIELDS}
      }
    }
  `

export const QUERY_GET_ORDER_BY_CART = gql`
  query order($reference: ID!) {
    order(reference: $reference) {
        ${ORDER_FIELDS}
      }
    }
`

export const MUTATE_CREATE_CUSTOMER_FROM_ORDER = gql`
  mutation CreateCustomerFromOrder($reference: ID!) {
    createCustomerFromOrder(reference: $reference) {
      customer {
        id
      }
      warnings {
        message
      }
      errors {
        message
      }
    }
  }
`
