import Theme, { StyledHTMLElement } from 'shop/theme/types'
import CenterModal from '../Modal/CenterModal'
import styled from '@emotion/styled'
import { AccountCreationType } from './types'
import { useShop } from 'shop/hooks'
import {
  IoTimerOutline,
  IoBagHandleOutline,
  IoDiamondOutline as DiamondIcon,
  IoClose as CloseIcon
} from 'react-icons/io5'
import { Button } from '../Controls'
import { useEffect, useState } from 'react'
import { Medium } from '../common'
import { SendAgain, StyledMailIcon } from '../Shop/commonStyles'

interface AccountCreationModalProps {
  type: AccountCreationType
  email: string
  handleCloseModal: () => void
  handleCreateAccount: () => void
}

const AccountCreationModal = ({
  type,
  email,
  handleCloseModal,
  handleCreateAccount
}: AccountCreationModalProps) => {
  const { merchant } = useShop()
  const { name: merchantName } = merchant
  const loyaltyEnabled = type === AccountCreationType.Loyalty
  const [accountCreationTriggered, setAccountCreationTriggered] =
    useState<boolean>(false)
  const [hasResentEmail, setHasResentEmail] = useState(false)
  const [emailSentCounter, setEmailSentCounter] = useState(60)

  const accountCreationData = [
    ...(loyaltyEnabled
      ? [
          {
            icon: DiamondIcon,
            text: 'Join our loyalty programme and earn exclusive rewards'
          }
        ]
      : []),
    {
      icon: IoTimerOutline,
      text: 'Save your order preferences'
    },
    {
      icon: IoBagHandleOutline,
      text: 'Reorder previous favourites'
    }
  ]

  const triggerCreateAccount = () => {
    setAccountCreationTriggered(true)
    handleCreateAccount()
  }

  const handleSendAgain = () => {
    setHasResentEmail(true)
    triggerCreateAccount()
  }

  useEffect(() => {
    if (!hasResentEmail) return
    const countdown = setTimeout(
      () => setEmailSentCounter(emailSentCounter - 1),
      1000
    )
    if (emailSentCounter <= 0) {
      if (countdown) clearTimeout(countdown)
      setHasResentEmail(false)
      setEmailSentCounter(60)
    }

    return () => {
      if (countdown) clearTimeout(countdown)
    }
  }, [hasResentEmail, emailSentCounter])

  return (
    <CenterModal canClose={false} styles={{ maxWidth: '530px' }}>
      <Container data-testid='account-creation-modal'>
        {accountCreationTriggered ? (
          <>
            <CloseButtonContainer
              data-testid='close-button'
              onClick={handleCloseModal}
            >
              <CloseIcon />
            </CloseButtonContainer>
            <HeaderContent>
              <Header>Check Your Email</Header>
              <Subtext>
                We sent an email to{' '}
                <span>
                  <Medium>{email}</Medium>
                </span>
                . Please use the link in this email to set up your password. To
                keep your account secure, this link will expire in{' '}
                <span>
                  <Medium>15 minutes</Medium>.
                </span>
              </Subtext>
            </HeaderContent>
            <StyledMailIcon />
            <ButtonVariant
              disabled={!!hasResentEmail}
              variant={'secondary'}
              onClick={handleSendAgain}
            >
              {hasResentEmail ? (
                <>
                  The email has been resent.{' '}
                  <SendAgain>Send again in ({emailSentCounter})</SendAgain>
                </>
              ) : (
                <>
                  Didn't receive the email? <SendAgain>Send again</SendAgain>
                </>
              )}
            </ButtonVariant>
          </>
        ) : (
          <>
            <HeaderContent>
              <Header>
                {loyaltyEnabled
                  ? `Earn rewards with a ${merchantName} account`
                  : `Create your ${merchantName} account`}
              </Header>
              {loyaltyEnabled && (
                <Subtext>
                  Create your {merchantName} account now and start collecting
                  loyalty points today.
                </Subtext>
              )}
            </HeaderContent>
            <div>
              {accountCreationData.map(({ icon: Icon, text }, index) => (
                <Detail key={`${index}-${text}`}>
                  <IconContainer>
                    <Icon />
                  </IconContainer>
                  <Text>{text}</Text>
                </Detail>
              ))}
            </div>
            <CtaSection>
              <Button onClick={handleCloseModal} variant={'link'}>
                No, thanks
              </Button>
              <Button onClick={triggerCreateAccount}>
                {loyaltyEnabled ? `Sign me up!` : `Create Account`}
              </Button>
            </CtaSection>
          </>
        )}
      </Container>
    </CenterModal>
  )
}

const Container = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 16px 16px',
    gap: '32px',
    justifyContent: 'center',

    [theme.mediaQueries.viewport7]: {
      padding: ' 40px 32px 32px'
    },

    '& > button': {
      borderRadius: '12px',
      whiteSpace: 'pre-wrap',
      flexWrap: 'wrap'
    }
  })
)

const Header = styled.h1<StyledHTMLElement>(() => ({
  fontSize: '24px',
  alignSelf: 'left'
}))

const Subtext = styled.p<StyledHTMLElement>(() => ({
  fontSize: '16px',
  color: '#2A2A2A',
  lineHeight: '22px',
  margin: 0
}))

const Detail = styled.div<StyledHTMLElement>(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '4px'
}))

const IconContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }: any) => ({
    backgroundColor: '#F5F5F5',
    borderRadius: '12px',
    padding: '8px',
    lineHeight: 0,
    '> svg': {
      width: '20px',
      height: '20px',
      color: theme.colors.primary
    }
  })
)

const Text = styled.p<StyledHTMLElement>(() => ({
  fontWeight: 500,
  fontSize: '14px',
  margin: 0
}))

const CtaSection = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    gap: '8px',
    '> button:first-of-type': {
      flexBasis: '50%',
      padding: '0 0 0 12px',
      justifyContent: 'flex-start',
      [theme.mediaQueries.viewport5]: {
        flexBasis: '35%'
      }
    }
  })
)

const HeaderContent = styled.div<StyledHTMLElement>(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px'
}))

const CloseButtonContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    position: 'absolute',
    padding: '4px',
    top: '16px',
    right: '16px',
    borderRadius: '50%',
    background: '#FFFFFF66',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.mediaQueries.viewport7]: {
      top: '24px',
      right: '24px'
    },
    '&:hover': {
      background: '#EFEFF099'
    },
    '&:active': {
      background: '#D8D8D8CC'
    },
    '> svg': {
      height: '25px',
      width: '25px'
    }
  })
)

// TODO: Move button styles (colors etc.) to global theme.
const ButtonVariant = styled(Button)<Partial<typeof Button>, Required<Theme>>(
  ({ theme }) => ({
    fontWeight: 400,
    color: '#8B8B8B',
    backgroundColor: '#F5F5F5',

    '&:hover': {
      backgroundColor: '#EFEFF0'
    },

    '&:active': {
      backgroundColor: '#D8D8D8'
    },

    '&:disabled': {
      color: '#8B8B8B',
      fontWeight: 400,
      backgroundColor: '#F5F5F5',
      opacity: 1
    }
  })
)

export default AccountCreationModal
