export const buildTitle = (
  merchantName: string | null,
  storeName: string | null,
  productName: string | null,
  tagline: string | null
) => {
  const updatedTagline = tagline ? `| ${tagline}` : ''

  if (merchantName && storeName && productName) {
    return [
      merchantName,
      '|',
      productName,
      '- Order Online',
      '|',
      storeName
    ].join(' ')
  }

  if (merchantName && storeName) {
    return [merchantName, storeName, updatedTagline, '- Order Online'].join(' ')
  }

  if (merchantName) {
    return [merchantName, updatedTagline, '- Order Online'].join(' ')
  }

  return ''
}
