import React, { useCallback, useEffect, useState } from 'react'

import { useLoader, useSlerpMerchant } from 'shop/hooks'
import { LoaderProps } from './types'

export const SlerpMerchantLoader = ({
  isParentLoaded = true,
  children
}: LoaderProps) => {
  const { fetchSlerpMerchant } = useLoader()
  const { setSlerpMerchant } = useSlerpMerchant()

  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const fetchData = useCallback(async () => {
    const slerpCartResult = await fetchSlerpMerchant()

    if (slerpCartResult) {
      setSlerpMerchant(slerpCartResult)
    }

    setIsLoaded(Boolean(slerpCartResult && isParentLoaded))
  }, [fetchSlerpMerchant, setSlerpMerchant, isParentLoaded])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return <React.Fragment>{children(isLoaded)}</React.Fragment>
}
