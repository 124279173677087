import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import styled from '@emotion/styled'

export const FulfillmentDatePicker = (
  props: ReactDatePickerProps & { newFulfillmentModal?: boolean }
) => {
  return <StyledDatePicker {...props} inline />
}

export const FulfillmentDateContainer = styled.div(
  ({ theme, newFulfillmentModal }: any) => ({
    display: 'flex',
    marginTop: '20px',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    zIndex: 8,
    [theme.mediaQueries.viewport4]: {
      flexDirection: 'row'
    },
    '.react-datepicker-wrapper': {
      flexGrow: 1,
      margin: '0 8px'
    },
    '.react-datepicker': {
      border: 'none',

      // disable blue hightlight of user clicks
      WebkitTapHighlightColor: 'transparent',
      WebkitTouchCallout: 'none',
      WebkitUserSelect: 'none',
      KhtmlUserSelect: 'none',
      MozUserSelect: 'none',
      msUserSelect: 'none',
      userSelect: 'none'
    },
    '.react-datepicker__header': {
      borderBottom: 0,
      backgroundColor: '#f9f9f9'
    },
    '.react-datepicker, .react-datepicker-popper, .react-datepicker react-datepicker--time-only':
      {
        zIndex: 9
      },
    '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled':
      {
        display: 'none'
      },
    '.react-datepicker__day': {
      ':focus': {
        outline: 'none'
      },
      ':hover': {
        borderRadius: '50%'
      }
    },

    '.react-datepicker__day--selected': {
      borderRadius: '50%',
      backgroundColor: theme.colors.primary,
      color: '#fff'
    },

    '.react-datepicker__navigation--next': {
      height: '10px',
      width: '10px',
      transform: 'rotate(45deg)',
      border: 'none',
      borderTop: '2px solid',
      borderRight: '2px solid',
      borderColor: theme.colors.primary
    },
    '.react-datepicker__navigation--previous': {
      height: '10px',
      width: '10px',
      transform: 'rotate(-45deg)',
      border: 'none',
      borderTop: '2px solid',
      borderLeft: '2px solid',
      borderColor: theme.colors.primary
    },
    ...returnnewFulfillmentModalStyles(theme, newFulfillmentModal)
  })
)

const commonFulfillmentModalNavigationStyles = {
  height: '32px',
  width: '32px'
}

const commonPseudoElementChevronStyles = {
  borderLeft: '2px solid',
  borderStyle: 'solid',
  content: '""',
  display: 'block',
  height: '10px',
  position: 'absolute',
  top: '6px',
  width: '10px',
  borderWidth: '2px 2px 0 0',
  borderColor: 'black'
}

const variantStyles = {
  newFulfillmentModal: (theme: any) => ({
    marginTop: '12px',
    '*': {
      backgroundColor: 'white'
    },
    '> div': {
      width: '100%',
      borderBottomLeftRadius: '16px',
      borderBottomRightRadius: '16px'
    },
    '.react-datepicker': {
      width: '100%',
      padding: '0 16px',
      backgroundColor: 'white',
      border: 'none',
      borderBottomLeftRadius: '16px',
      borderBottomRightRadius: '16px',
      WebkitTapHighlightColor: 'transparent',
      WebkitTouchCallout: 'none',
      WebkitUserSelect: 'none',
      KhtmlUserSelect: 'none',
      MozUserSelect: 'none',
      msUserSelect: 'none',
      userSelect: 'none'
    },
    '.react-datepicker__navigation': {
      background: 'none',
      justifyContent: 'center',
      cursor: 'pointer',
      position: 'absolute',
      top: '0',
      padding: '0',
      border: 'none',
      zIndex: '1',
      height: '32px',
      width: '32px',
      textIndent: '-9999px',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    '.react-datepicker__navigation--next': {
      ...commonFulfillmentModalNavigationStyles,
      right: '8px',
      [theme.mediaQueries.viewport7]: {
        right: '20px'
      },
      '&:before': {
        ...commonPseudoElementChevronStyles,
        WebkitTransform: 'rotate(45deg)',
        MsTransform: 'rotate(45deg)',
        transform: 'rotate(45deg)',
        right: '13px'
      }
    },
    '.react-datepicker__navigation--previous': {
      ...commonFulfillmentModalNavigationStyles,
      left: '11px',
      [theme.mediaQueries.viewport7]: {
        left: '20px'
      },
      '&::before': {
        ...commonPseudoElementChevronStyles,
        WebkitTransform: 'rotate(225deg)',
        MsTransform: 'rotate(225deg)',
        transform: 'rotate(225deg)',
        left: '13px'
      }
    },
    '.react-datepicker__day--selected': {
      borderRadius: '25%',
      backgroundColor: '#096DD9',
      color: 'white',
      fontWeight: 400,
      fontSize: '16px'
    },
    '.react-datepicker__current-month': {
      fontWeight: 500,
      fontSize: '16px',
      paddingBottom: '12px',
      borderBottom: 'solid 1px #F0F0F0'
    },
    '.react-datepicker__day-names': {
      padding: '12px 10px 0',
      display: 'flex',
      justifyContent: 'space-between',
      '.react-datepicker__day-name': {
        color: '#666666',
        fontWeight: 600
      }
    },
    '.react-datepicker__day--disabled': {
      color: '#AEB4C2'
    },
    '.react-datepicker__day--today': {
      fontWeight: 400
    },
    '.react-datepicker__month-container': {
      borderTopLeftRadius: '16px',
      borderTopRightRadius: '16px',
      width: '100%',
      [theme.mediaQueries.viewport7]: {
        padding: '0 18px'
      }
    },
    '.react-datepicker__month': {
      padding: '0 1%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly'
    },
    '.react-datepicker__week': {
      justifyContent: 'space-between',
      display: 'flex',
      width: '100%',
      padding: '2px 0',
      '> div': {
        fontSize: '16px'
      }
    },
    '.react-datepicker__today-button': {
      padding: '12px 0 32px',
      borderTop: 'solid 1px #F0F0F0',
      fontSize: '16px'
    },
    '.react-datepicker__header': {
      borderBottom: 0,
      paddingTop: 0
    }
  })
}

/** Returns new modal styles based on value of boolean prop */
const returnnewFulfillmentModalStyles = (
  theme: any,
  newFulfillmentModal: boolean
): any =>
  newFulfillmentModal ? variantStyles['newFulfillmentModal'](theme) : {}

export const StyledDatePicker = styled(DatePicker)(
  ({ theme, disabled }: any) => ({
    color: '#2c2c2c',
    outline: 0,
    border: 0,
    borderBottom: `1px solid ${theme.colors.lineColor}`,
    width: '100%',
    height: '40px',
    fontSize: theme.fontSizes[1],
    fontWeight: theme.fontWeights.light,
    '&.-hasIcon': {
      paddingLeft: '24px'
    },
    backgroundColor: '#FFFFFF',
    cursor: disabled ? 'not-allowed' : 'auto'
  })
)
