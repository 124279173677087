import { useContext, useState, useEffect, useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { IoIosCalendar as CalendarIcon } from 'react-icons/io'
import { BiEditAlt as EditIcon } from 'react-icons/bi'
import { CurrentLocationState, FulfillmentProps } from '../types'
import { InvalidTimeSlot, ValidStore } from 'shop/components'
import {
  FulfillmentDateContainer,
  FulfillmentDatePicker
} from '../FulfillmentDatePicker'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { LandingContext } from '../LandingContext'
import TimeSlotSelector from './../TimeSlot'
import {
  calculateStepNumberText,
  getInvalidStoresMessage,
  hasValidStore,
  trackDateChanged,
  trackOrderDayChanged,
  trackPostCodeChange,
  trackStoreChanged,
  trackTimeslotChanged
} from './../utils'
import { isDateAvailable } from './../datePickerUtils'
import { QUERY_CUSTOMER_DEFAULT_ADDRESS } from 'shop/client/queries/CustomerQueries'
import { useShop } from 'shop/hooks'

import { useUTMTracker } from '../trackingUtils'
import AddressSelection from '../AddressSelection'
import { StoreSelector } from '../Stores'
import { formatStoreTimeslots } from '../TimeSlot/utils'
import { format, isToday } from 'date-fns'
import { findNextAvailablePreOrderDate } from '../preOrderUtils'
import SelectInput from 'shop/components/Inputs/SelectInput'
import { RequiredTag } from '../FulfillmentOptions'
import { Divider } from '../../common'
import SelectablePill from '../SelectablePill'
import { SpinnerContainer } from '../commonStyles'
import Spinner from 'shop/components/Loader/Spinner'
import { createAddressString } from 'shop/utils/address'

const isUKLocation = (location: CurrentLocationState) => {
  const addressComponent = location.addressComponents.find((cmp) =>
    cmp.types.includes('country')
  )
  return (
    addressComponent &&
    (addressComponent.short_name === 'UK' ||
      addressComponent.short_name === 'GB')
  )
}

const DeliveryForm = (props: FulfillmentProps) => {
  const {
    stores,
    currentStore,
    currentLocation,
    setCurrentLocation,
    fulfillmentType,
    fulfillmentTime,
    fulfillmentDate,
    setFulfillmentDate,
    setFulfillmentTime,
    setFulfillmentTimeRange,
    merchant,
    setUseCustomerDefaultAddress,
    validStores,
    setCurrentStore,
    loadingStores,
    loadingDates,
    setIsExtendedHour,
    storeOutOfRange,
    availableDates,
    sameDayOnly,
    preOrderOnly,
    hasFailedSubmit,
    showFulfillmentOptions,
    inModal
  } = useContext(LandingContext)

  useEffect(() => {
    if (preOrderOnly && !sameDayOnly) setHideCalendar(false)
  }, [preOrderOnly, sameDayOnly, currentLocation])

  const { tracker } = useUTMTracker()

  /*===== Address =====*/
  const defaultAddress = currentLocation
    ? currentLocation.address
    : tracker && tracker.address
      ? (tracker.address as string)
      : ''

  const datesOrStoresLoading = useMemo(
    () => loadingDates[fulfillmentType] || loadingStores[fulfillmentType],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingDates[fulfillmentType], loadingStores[fulfillmentType]]
  )

  const [address, setAddress] = useState(defaultAddress)
  const [showInvalidTimeslotModal, setShowInvalidTimeslotModal] =
    useState(false)
  const [selectedOrderDay, setSelectedOrderDay] = useState('')
  const [hideCalendar, setHideCalendar] = useState(true)

  useEffect(() => {
    if (isToday(fulfillmentDate) && selectedOrderDay !== 'today') {
      setSelectedOrderDay('today')
    }

    if (!isToday(fulfillmentDate) && selectedOrderDay !== 'future date') {
      setSelectedOrderDay('future date')
    }
  }, [fulfillmentDate, selectedOrderDay])

  const handleLocationChange = (newAddress: string) => {
    setUseCustomerDefaultAddress(false)
    setAddress(newAddress)
  }

  const handleLocationSelect = useCallback(
    (newAddress: string) => {
      geocodeByAddress(newAddress)
        .then(async (results) => {
          const latLng = await getLatLng(results[0])
          const formattedAddress = results[0].formatted_address
          const addressComponents = results[0].address_components
          setAddress(formattedAddress)
          setCurrentLocation({
            address: formattedAddress,
            addressComponents,
            latLng
          })
          trackPostCodeChange('landing')
        })
        .catch((error) => console.error('Error', error))
    },
    [setCurrentLocation]
  )

  /*==== Autofill ====*/
  const { useShopClient } = useShop()
  const client = useShopClient()

  useEffect(() => {
    if (localStorage.getItem('customerId')) {
      const customerId = localStorage.getItem('customerId')

      client
        .query({
          query: QUERY_CUSTOMER_DEFAULT_ADDRESS,
          variables: { customerId }
        })
        .then((results) => {
          if (results.data.addresses.length > 0) {
            setUseCustomerDefaultAddress(true)
            handleLocationSelect(createAddressString(results.data.addresses[0]))
          }
        })
    }
  }, []) // eslint-disable-line
  /*===== End Address =====*/

  // Pre-loads address from utmSource
  useEffect(() => {
    if (tracker && tracker.address) {
      handleLocationSelect(tracker.address as string)
    }
  }, []) // eslint-disable-line

  const handleDateChange = (date: Date) => {
    if (datesOrStoresLoading) return
    // do not allow same day selection on the calendar for pre-orders
    if (preOrderOnly && isToday(date)) {
      return
    }
    if (date) {
      setFulfillmentDate(date)
      trackDateChanged('landing', date)
      if (isToday(date)) {
        setSelectedOrderDay('today')
      }
      setHideCalendar(true)
    }
  }

  const showDateField = !!currentLocation

  const showStoreList =
    showDateField &&
    !datesOrStoresLoading &&
    !!fulfillmentDate &&
    !!validStores &&
    validStores.length > 0

  const timeSlots = formatStoreTimeslots(
    currentStore?.fulfillmentTimeslots || [],
    fulfillmentDate
  )

  const selectFirstTimeslot = useCallback(() => {
    const timeslot = timeSlots[0]
    setFulfillmentTime(timeslot.value)
    setFulfillmentTimeRange(timeslot.range)
  }, [setFulfillmentTime, setFulfillmentTimeRange, timeSlots])

  useEffect(() => {
    if (timeSlots.length === 1) selectFirstTimeslot()
  }, [timeSlots, selectFirstTimeslot])

  const showTimeslots =
    showStoreList && !!(currentLocation && isUKLocation(currentLocation))

  const sameDayAndPreOrderAvailable = !preOrderOnly && !sameDayOnly

  const handleSetCurrentStore = (store: ValidStore) => {
    trackStoreChanged(store, 'landing')
    setCurrentStore(store)
  }

  return (
    <>
      <h5>
        {calculateStepNumberText(1, { showFulfillmentOptions })}
        <span>Enter your postcode</span>
        <RequiredTag
          id={`${!!address ? '' : 'invalid-'}required-tag-postcode`}
          {...{ isValid: !!address, hasFailed: hasFailedSubmit }}
        >
          *required
        </RequiredTag>
      </h5>
      <AddressSelection
        onChange={handleLocationChange}
        onSelect={handleLocationSelect}
        address={address}
        inModal={inModal}
      />
      {!!address && <Divider />}
      {showDateField && (
        <>
          <h5>
            {calculateStepNumberText(2, { showFulfillmentOptions })}
            <span>Confirm delivery date </span>
            <RequiredTag
              id={`${
                selectedOrderDay === 'today' ||
                (!!fulfillmentDate && hideCalendar)
                  ? ''
                  : 'invalid-'
              }required-tag-date`}
              {...{
                isValid:
                  selectedOrderDay === 'today' ||
                  (!!fulfillmentDate && hideCalendar),
                hasFailed: hasFailedSubmit
              }}
            >
              *required
            </RequiredTag>
          </h5>
          {sameDayAndPreOrderAvailable && (
            <SelectablePill
              options={[
                {
                  title: 'today',
                  type: 'today',
                  selected: selectedOrderDay === 'today',
                  onClick: () => {
                    handleDateChange(new Date())
                    setSelectedOrderDay('today')
                    trackOrderDayChanged('today', 'landing')
                  }
                },
                {
                  title: 'future date',
                  type: 'future-date',
                  selected: selectedOrderDay === 'future date',
                  onClick: () => {
                    const nextDate = findNextAvailablePreOrderDate(
                      stores,
                      fulfillmentType,
                      availableDates
                    )
                    handleDateChange(nextDate)
                    setSelectedOrderDay('future date')
                    setHideCalendar(false)
                    trackOrderDayChanged('future date', 'landing')
                  }
                }
              ]}
            />
          )}
          {sameDayOnly && selectedOrderDay === 'today' && (
            <>
              <SelectInput
                testId='change-calendar-date'
                disabled={true}
                onClick={() => {}}
                ToSelectIcon={CalendarIcon}
                HasSelectedIcon={EditIcon}
                hasSelected={!!fulfillmentDate}
                textFallback={`${format(fulfillmentDate, 'EEEE')} ${format(
                  fulfillmentDate,
                  'dd/MM/yyyy'
                )}`}
              />
            </>
          )}
          {selectedOrderDay === 'future date' && (
            <>
              {hideCalendar && (
                <>
                  <SelectInput
                    testId='change-calendar-date'
                    onClick={() => setHideCalendar(false)}
                    ToSelectIcon={CalendarIcon}
                    HasSelectedIcon={EditIcon}
                    hasSelected={!!fulfillmentDate}
                    textFallback={`${format(fulfillmentDate, 'EEEE')} ${format(
                      fulfillmentDate,
                      'dd/MM/yyyy'
                    )}`}
                  />
                </>
              )}

              {!hideCalendar && (
                <FulfillmentDateSection>
                  <FulfillmentDateContainer>
                    <FulfillmentDatePicker
                      selected={fulfillmentDate}
                      onChange={handleDateChange}
                      onSelect={handleDateChange}
                      minDate={new Date()}
                      filterDate={(date: Date) =>
                        isDateAvailable(availableDates, date, 'delivery')
                      }
                      placeholderText='dd/mm/yyyy'
                      autoFocus={fulfillmentDate === null}
                      dateFormat='dd/MM/yyyy'
                      disabled={datesOrStoresLoading}
                    />
                  </FulfillmentDateContainer>
                </FulfillmentDateSection>
              )}
            </>
          )}
          <Divider />
        </>
      )}
      {showStoreList && (
        <>
          <h5>
            {calculateStepNumberText(3, { showFulfillmentOptions })}
            <span>Pick a store near you</span>
            <RequiredTag
              id={`${!!currentStore ? '' : 'invalid-'}required-tag-store`}
              {...{ isValid: !!currentStore, hasFailed: hasFailedSubmit }}
            >
              *required
            </RequiredTag>
          </h5>
          <StoreSelector
            validStores={validStores}
            setCurrentStore={handleSetCurrentStore}
            currentStore={currentStore}
            inModal={inModal}
          />
          <Divider />
        </>
      )}

      {currentLocation && !datesOrStoresLoading && validStores.length === 0 && (
        <Message>
          {getInvalidStoresMessage(
            fulfillmentDate,
            storeOutOfRange,
            !!hasValidStore(stores, 'pickup')
          )}
        </Message>
      )}
      {showTimeslots && (
        <>
          <h5>
            {calculateStepNumberText(4, { showFulfillmentOptions })}
            <span>Choose delivery timeslot</span>
            <RequiredTag
              id={`${!!fulfillmentTime ? '' : 'invalid-'}required-tag-time`}
              {...{ isValid: !!fulfillmentTime, hasFailed: hasFailedSubmit }}
            >
              *required
            </RequiredTag>
          </h5>
          <TimeSlotContainer>
            <TimeSlotSelector
              timeSlots={timeSlots}
              value={fulfillmentTime}
              handleUpdate={(time, range, extended) => {
                setFulfillmentTime(time)
                setFulfillmentTimeRange(range)
                setIsExtendedHour(extended)
                if (range) {
                  trackTimeslotChanged(range, 'landing')
                }
              }}
              inModal={inModal}
            />
          </TimeSlotContainer>
        </>
      )}
      {datesOrStoresLoading && (
        <SpinnerContainer>
          <Spinner size='40px' />
        </SpinnerContainer>
      )}
      <InvalidTimeSlot
        showModal={showInvalidTimeslotModal}
        onClose={() => setShowInvalidTimeslotModal(false)}
      />
    </>
  )
}

const Message = styled.p<StyledHTMLElement, Required<Theme>>(
  ({ theme }: any) => ({
    margin: '32px 0 8px',
    fontSize: theme.fontSizes[2],
    fontWeight: 'normal',
    textTransform: 'none',
    color: theme.colors.textMute
  })
)

const FulfillmentDateSection = styled.div<StyledHTMLElement, Required<Theme>>(
  () => ({
    marginBottom: 0,
    '& > h5': {
      display: 'block'
    }
  })
)

const TimeSlotContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  () => ({
    marginBottom: '36px'
  })
)

export default DeliveryForm
