import { StampCard, CustomerReward } from './StampList'
import { isNull } from 'lodash'

export const loyaltyCardImageUrl = (
  imageName: string,
  loyaltyCardId: string
) => {
  const imageUrl = `https://assets.${
    process.env.REACT_APP_ASSET_HOST
  }/uploads/images/loyalty_card/${loyaltyCardId}/${loyaltyCardId}_${
    imageName.split('?')[0]
  }_logo.png?v=${imageName.split('?')[1] ?? 'no-version'}`
  return imageUrl
}

export const hideLoyaltyCardSection = (
  loyaltyCards: StampCard[] | undefined
) => {
  if (loyaltyCards?.length) {
    let hiddenCount = 0
    loyaltyCards.forEach((card) => {
      if (hideLoyaltyCard(card)) {
        hiddenCount += 1
      }
    })
    if (hiddenCount === loyaltyCards.length) return true
    return false
  }
  return true
}

export const hideLoyaltyCard = (stampCard: StampCard): boolean => {
  const {
    stamps_aggregate,
    customer_rewards,
    loyalty_card: loyaltyCard
  } = stampCard

  const amountCollected = stamps_aggregate?.aggregate.count || 0
  const alreadyRedeemed: boolean =
    (customer_rewards?.length && !!customer_rewards[0].redeemed_at) || false
  const isArchived: boolean =
    (customer_rewards?.length && !!customer_rewards[0].archived_at) || false
  const isPublished: boolean = (loyaltyCard && !!loyaltyCard.published) || false
  const isRedeemable: boolean =
    loyaltyCard && loyaltyCard.rewards[0].required_quantity === amountCollected

  const today = new Date(Date.now())
  // if no start date, default the card to not be active yet.
  const hasStarted: boolean = loyaltyCard.starts_at
    ? new Date(loyaltyCard.starts_at) < today
    : false
  // if no end date, default the card to be active indefinitely.
  const hasEnded: boolean = loyaltyCard.ends_at
    ? new Date(loyaltyCard.ends_at) < today
    : false

  const allRewardsExpired: boolean = allExpired(today, customer_rewards)

  if (
    alreadyRedeemed ||
    isArchived ||
    allRewardsExpired ||
    (!isPublished && !isRedeemable) ||
    (!hasStarted && !isRedeemable) ||
    (hasEnded && !isRedeemable)
  )
    return true

  return false
}

const allExpired = (today: Date, customer_rewards?: CustomerReward[]) => {
  if (customer_rewards && customer_rewards?.length > 0) {
    const expiredRewards = customer_rewards.filter((reward) => {
      if (isNull(reward.end_datetime)) return false

      return new Date(reward.end_datetime) < today
    })

    return expiredRewards.length === customer_rewards.length
  }

  return false
}
