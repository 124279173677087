import { useCallback, useEffect, useRef, useState } from 'react'
import { useResize, useShop } from 'shop/hooks'
import styled from '@emotion/styled'
import { Category } from './types'
import {
  FaChevronLeft as LeftArrowIcon,
  FaChevronRight as RightArrowIcon
} from 'react-icons/fa'
import { CategoriesList } from './CategoriesList'
import Theme, { StyledHTMLElement } from 'shop/theme/types'

interface Props {
  selectedCategory: string
  categories: Category[]
  onClick: (categoryId: string) => void
}

export const Categories = ({
  categories,
  selectedCategory,
  onClick
}: Props) => {
  const { isStoreLoading } = useShop()
  const scrollContainer = useRef<HTMLDivElement>(null)
  const containerSize = useResize(scrollContainer)

  const [scrollable, setScrollable] = useState(false)
  const [showLeftArrow, setShowLeftArrow] = useState(false)
  const [showRightArrow, setShowRightArrow] = useState(false)

  const onScroll = useCallback(() => {
    if (!scrollContainer.current) {
      return
    }

    const { scrollLeft, scrollWidth, clientWidth } = scrollContainer.current

    setShowLeftArrow(scrollable && scrollLeft > 0)
    setShowRightArrow(
      scrollable && Math.round(scrollLeft + clientWidth) < scrollWidth
    )
  }, [scrollable])

  useEffect(() => {
    let containerWidth = 0

    if (containerSize?.width && containerSize?.x) {
      containerWidth = containerSize?.width + containerSize?.x * 2
    }

    setScrollable((scrollContainer.current?.scrollWidth || 0) > containerWidth)
    onScroll()
  }, [categories, onScroll, containerSize])

  const handleLeftArrowClick = () => {
    scrollContainer.current?.scrollTo(
      scrollContainer.current?.scrollLeft - 200,
      0
    )
  }

  const handleRightArrowClick = () => {
    scrollContainer.current?.scrollTo(
      scrollContainer.current?.scrollLeft + 200,
      0
    )
  }

  const handleScrollTo = useCallback(
    (offsetLeft: number, elementWidth: number) => {
      const element = scrollContainer.current

      if (!element) {
        return
      }

      const rightMargin = offsetLeft + elementWidth
      const leftMargin = offsetLeft

      if (element.scrollLeft >= leftMargin) {
        element.scrollTo({ left: leftMargin, behavior: 'smooth' })
        return
      }

      if (
        element.offsetWidth <= rightMargin &&
        element.offsetWidth <= element.scrollWidth - leftMargin
      ) {
        element.scrollTo({ left: leftMargin, behavior: 'smooth' })
        return
      }

      if (element.offsetWidth <= rightMargin) {
        element.scrollTo({ left: rightMargin, behavior: 'smooth' })
        return
      }
    },
    []
  )

  return (
    <Root>
      {showLeftArrow && (
        <LeftMargin>
          <ArrowContainer onClick={handleLeftArrowClick}>
            <LeftArrowIcon />
          </ArrowContainer>
        </LeftMargin>
      )}

      <ScrollWrapper ref={scrollContainer} onScroll={onScroll}>
        {!isStoreLoading && !!categories.length && (
          <CategoriesList
            scrollTo={handleScrollTo}
            categories={categories}
            selectedCategory={selectedCategory}
            onClick={onClick}
          />
        )}
      </ScrollWrapper>
      {showRightArrow && (
        <RightMargin>
          <ArrowContainer onClick={handleRightArrowClick}>
            <RightArrowIcon />
          </ArrowContainer>
        </RightMargin>
      )}
    </Root>
  )
}

const Root = styled.div(() => ({
  position: 'relative',
  width: '100%',
  overflow: 'hidden',
  backgroundColor: '#fff'
}))

const ScrollWrapper = styled.div(() => ({
  scrollBehavior: 'smooth',
  overflowX: 'scroll',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  width: '100%',

  '&::-webkit-scrollbar': {
    display: 'none'
  }
}))

const ArrowContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    height: '38px',
    width: '28px',
    display: 'none',
    cursor: 'pointer',

    [theme.mediaQueries.viewport7]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
)

const Margin = styled.div<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  position: 'absolute',
  zIndex: 20,
  top: 0,

  minHeight: '38px',
  minWidth: '24px',

  [theme.mediaQueries.viewport7]: {
    background: '#fff'
  }
}))

const LeftMargin = styled(Margin)(() => ({
  paddingRight: '8px',
  left: '0',
  background:
    'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255, 0) 100%)'
}))

const RightMargin = styled(Margin)(() => ({
  paddingLeft: '8px',
  right: '0',
  background:
    'linear-gradient(90deg, rgba(255,255,255, 0) 0%, rgba(255,255,255,1) 100%)'
}))
