import { FieldError, UseFormMethods } from 'react-hook-form'
import { Heading } from 'shop/components'
import { FormFields } from 'shop/components/Checkout/types'
import { inputCharactersLimitValidator } from '.'
import FormInput from '../Inputs/FormInput'

const CustomField = ({
  fieldName,
  required,
  placeholder,
  formHandle,
  error
}: {
  fieldName: string
  required: boolean
  placeholder: string
  formHandle: UseFormMethods<FormFields>
  error?: FieldError
}) => {
  const { watch, register } = formHandle
  const customFieldValue = watch('custom_field_value') || ''

  return (
    <>
      <Heading as='h4' margin='32px 0 8px'>
        {fieldName}
      </Heading>
      <FormInput
        id={'custom_field_value'}
        name='custom_field_value'
        value={customFieldValue}
        labelText={placeholder || ''}
        showError={!!error}
        formRef={register({
          required: required ? 'Please enter a value' : undefined,
          validate: inputCharactersLimitValidator('field')
        })}
      />
    </>
  )
}

export default CustomField
