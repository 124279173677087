import styled from '@emotion/styled'
import { isTableOrder } from 'shop/components/Cart/utils'
import { Payor } from 'shop/components/Order/types'
import { OrderAgainButton, OrderNowButton } from 'shop/components/Order/Buttons'
import PayorList from 'shop/components/Checkout/PayorList'
import { Content } from './orderCommonStyles'
import { Order as OrderType } from 'shop/types/cart'

// FIXME: Creating a temp order interface to get around lack of old Order type that was previously removed
interface Order {
  cart_id: string
  payments: Payor[]
  metadata: {
    pickup_type: string
  }
}

// FIXME: DeliveryActions is used for Split the Bill and Order Again which are both currently disabled.
// If using this component ensure order object is up to date.
const DeliveryActions = ({
  order,
  orderV2,
  paymentId,
  isLoggedIn
}: {
  order: Order
  orderV2: OrderType
  paymentId?: string
  isLoggedIn?: boolean
}) => {
  const { metadata } = order
  const isDineIn = isTableOrder(metadata)

  // Temporarily disable reordering until consumer API adds support for it
  const reorderingEnabled = false

  const currentPayment = order.payments.find(
    ({ internal_reference }: { internal_reference: string }) => {
      return internal_reference === paymentId
    }
  )

  const isOrderAgain = isLoggedIn && reorderingEnabled
  const isPayorList = order.payments && order.payments.length > 1
  const isPayNow = currentPayment?.status === 'pending'

  if (!isOrderAgain && !isPayorList && !isPayNow) {
    return <></>
  }

  return (
    <Root buttonsOnly={!isPayorList}>
      <Content>
        {isPayorList && (
          <PayorList
            payments={[...order.payments]}
            currentPayment={currentPayment}
          />
        )}
        {(isPayNow || isOrderAgain) && (
          <ButtonWrapper>
            {isOrderAgain && (
              <OrderAgainButton
                {...{
                  cartId: order.cart_id,
                  storeId: orderV2.store.id,
                  storeSlug: orderV2.store.slug,
                  isDineIn: isDineIn
                }}
              />
            )}
            {isPayNow && (
              <OrderNowButton currentPayment={currentPayment}></OrderNowButton>
            )}
          </ButtonWrapper>
        )}
      </Content>
    </Root>
  )
}

const Root = styled.div(({ buttonsOnly }: { buttonsOnly: boolean }) => ({
  padding: buttonsOnly ? '16px' : '32px'
}))

const ButtonWrapper = styled.div(() => ({
  '&:not(:first-of-type)': {
    marginTop: '12px'
  },
  display: 'flex',
  gap: '10px',
  justifyContent: 'center'
}))

export default DeliveryActions
