import { useEffect, useContext, useCallback } from 'react'
import AlertMessage from '../Cart/AlertMessage'
import styled from '@emotion/styled'
import { CheckoutNavbar, StyledHeading } from 'shop/components'
import {
  useShop,
  useCart,
  useCheckout,
  useModal,
  FlashContext
} from 'shop/hooks'
import {} from 'shop/components/Cart/utils'
import { getStoreUrl } from 'shop/utils/store'
import { BackButton } from 'shop/components/Buttons'
import { Cart } from 'shop/components/Landing/types'
import { Route, Switch } from 'react-router-dom'
import { formatMoney } from 'shop/components/Cart/utils'
import SplitPayorInputGroup from './SplitPayorInputGroup'
import PaymentLinks, { Price } from './PaymentLinks'

const SplitSetup = () => {
  const { cartSession, useShopClient, setIsStoreLoading } = useShop()
  const { inbox } = useContext(FlashContext)
  const { setCart } = cartSession
  const { loadCart } = useCart()
  const cart = cartSession.cart as Cart
  const client = useShopClient()
  const { isModalOpen, cartValidation } = useCheckout(cart, client)
  const { timeSlotModal } = useModal()

  const startLoad = useCallback(async () => {
    const cart = await loadCart()
    setCart(cart)
    setIsStoreLoading(false)
  }, [loadCart, setIsStoreLoading])

  useEffect(() => {
    startLoad()
  }, [])

  if (!cart) return null

  const computeTotal = () => cart.total

  return (
    <>
      {isModalOpen('timeslot') && timeSlotModal(cartValidation)}
      <Container data-testid='splitContainer'>
        <CheckoutNavbar />
        <ContentWrapper>
          {inbox.map((message) => (
            <AlertMessage heading={message.content} flash type={message.type} />
          ))}
          <DesktopBackButton>
            <BackButton
              url={getStoreUrl(cart.store.slug)}
              label='Back'
              size='12px'
            />
          </DesktopBackButton>
          <Header>
            <Heading>{cart.store.name}</Heading>
            <Price>{formatMoney(computeTotal())}</Price>
          </Header>
          <MainHeading as='h5'>Split among</MainHeading>
          <SplitPayorInputGroup cart={cart} total={computeTotal()} />
        </ContentWrapper>
      </Container>
    </>
  )
}

const Split = () => {
  return (
    <Switch>
      <Route
        path='/split/payment'
        component={PaymentLinks}
        key='split-payment'
      />
      <Route path='/split' component={SplitSetup} key='split-setup' />
    </Switch>
  )
}

const Container = styled.div(() => ({
  minHeight: '100vh',
  backgroundColor: '#F3F2F4',
  position: 'relative'
}))

export const ContentWrapper = styled.div(({ theme }: any) => ({
  minHeight: `calc(100vh - ${theme.components.navbar.height})`,
  position: 'relative',
  margin: '0 auto',
  padding: '16px',
  height: '100%',

  backgroundColor: '#F3F2F4',

  maxWidth: '560px',

  [theme.mediaQueries.viewport4]: {
    padding: '24px'
  }
}))

export const Header = styled.div(({ theme }: any) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px',
  background: '#FFFFFF',
  borderRadius: '4px',
  boxShadow: '0px 1px 2px rgba(74, 74, 104, 0.1)',
  fontSize: theme.fontSizes[1],
  [theme.mediaQueries.viewport4]: {
    padding: '24px',
    fontSize: theme.fontSizes[2]
  }
}))

const Heading = styled(StyledHeading)(({ theme }: any) => ({
  fontWeight: 'bold',
  margin: 0
}))

const MainHeading = styled(StyledHeading)(({ theme }: any) => ({
  fontWeight: 'normal',
  padding: '0',
  margin: '24px 0 16px',
  opacity: 0.8
}))

const DesktopBackButton = styled.div(({ theme }: any) => ({
  display: 'none'
}))

export default Split
