import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { IconType } from 'react-icons'

type Props = {
  onClick: () => void
  textFallback: string
  textContent?: string
  hasSelected?: boolean
  disabled?: boolean
  ToSelectIcon: IconType
  HasSelectedIcon?: IconType
  testId?: string
  newFulfillmentModal?: boolean
}

const SelectInput = ({
  onClick,
  textFallback,
  textContent,
  hasSelected = false,
  disabled = false,
  ToSelectIcon,
  HasSelectedIcon,
  testId,
  newFulfillmentModal = false
}: Props) => {
  const SelectInputContent = (): JSX.Element => {
    if (newFulfillmentModal) {
      if (!disabled && ToSelectIcon) {
        return (
          <>
            <ToSelectIcon />
            <h1>{textContent ? textContent : textFallback}</h1>
          </>
        )
      }
    } else {
      if (HasSelectedIcon) {
        return (
          <>
            <h1>{textContent ? textContent : textFallback}</h1>
            {!disabled &&
              (hasSelected ? <HasSelectedIcon /> : <ToSelectIcon />)}
          </>
        )
      }
    }
    return <></>
  }

  return (
    <SelectInputContainer
      disabled={disabled}
      onClick={onClick}
      data-testid={testId}
      newFulfillmentModal={newFulfillmentModal}
      hasSelected={hasSelected}
    >
      <SelectInputContent />
    </SelectInputContainer>
  )
}

const SelectInputContainer = styled.button<
  StyledHTMLElement & { newFulfillmentModal: boolean; hasSelected: boolean },
  Required<Theme>
>(({ newFulfillmentModal, hasSelected, theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '9px 20px',
  marginTop: theme.space[3],
  lineHeight: '30px',

  backgroundColor: 'transparent',
  color: '#2c2c2c',
  borderRadius: '12px',
  border: `1px solid ${theme.colors['lineColor']}`,

  '&:disabled': { opacity: 1 },

  '& h1': {
    fontWeight: 'normal',
    fontSize: '14px',
    whiteSpace: 'unset',
    textOverflow: 'unset',
    overflow: 'auto'
  },

  '& svg': {
    height: '16px',
    width: '16px',
    maxWidth: '16px',
    marginRight: 0,
    color: 'black'
  },

  ':hover': {
    cursor: 'pointer'
  },

  ...setnewFulfillmentModalStyles(newFulfillmentModal, hasSelected)
}))

/** Returns correct styles for selectInput use in new and old modals */
const setnewFulfillmentModalStyles = (
  newFulfillmentModal: boolean,
  hasSelected: boolean
) =>
  newFulfillmentModal ? variantStyles['newFulfillmentModal'](hasSelected) : {}

const variantStyles = {
  newFulfillmentModal: (hasSelected: boolean) => {
    return {
      border: 'solid 1px #096DD9',
      '&:focus, &:active': {
        '& svg ': {
          height: '16px',
          width: '16px',
          color: 'black'
        }
      },

      '& svg': {
        height: '16px',
        width: '16px',
        maxWidth: '16px',
        marginRight: '9px',
        color: hasSelected ? 'black' : '#757575'
      },

      '& h1': {
        fontWeight: 'normal',
        fontSize: '16px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      },

      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '7px 20px',
      marginTop: 0,
      lineHeight: '29px',
      borderRadius: '12px'
    }
  }
}

export default SelectInput
