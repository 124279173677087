import { ConsumerCart } from 'shop/types/cart'
import {
  createGA4EcommPurchaseParams,
  merchantGA4EcommTrackPurchase,
  slerpGA4EcommTrackPurchase
} from './GA/ecommerce'
import { trackUserActionsGA4 } from './GAtools'
import { trackUserPurchaseFBPixel } from './PixelTools'
import Tracker from './Tracker'
import { TrackableEvent } from './types'

interface BasicPurchaseEventData {
  consumerCart: ConsumerCart
  transactionId: string
  customerId?: string
}

interface FullPurchaseEventData extends BasicPurchaseEventData {
  merchantName: string | null
}

/** Google conversion event */
const trackGoogleConversionEvent = (
  orderTotal: number,
  transactionId: string
) => {
  if (!!Tracker.getInstance().googleConversionId) {
    window['gtag']('event', 'conversion', {
      send_to: Tracker.getInstance().googleConversionId,
      value: orderTotal,
      currency: 'GBP',
      transaction_id: transactionId
    })
  }
}

const sendCustomGA4PurchaseEvents = async () => {
  try {
    const body = {
      category: 'Checkout',
      action: TrackableEvent.OrderCompleted
    }
    trackUserActionsGA4(body, 'slerpGA4Tracking')
    trackUserActionsGA4(body, 'merchantGA4Tracking')
    return 'Success'
  } catch (error) {
    let errorMessage = `Failed to send custom GA4 event - ${TrackableEvent.OrderCompleted}`
    if (error) errorMessage += `: ${error}`
    console.error(errorMessage)
    return errorMessage
  }
}
const sendGoogleConversionEvents = async ({
  consumerCart,
  transactionId
}: BasicPurchaseEventData) => {
  try {
    const conversionRevenue =
      consumerCart.summary.subtotal.discounted ||
      consumerCart.summary.subtotal.base
    trackGoogleConversionEvent(Number(conversionRevenue), transactionId)
    return 'Success'
  } catch (error) {
    let errorMessage = `Failed to send Google conversion event.`
    if (error) errorMessage += `: ${error}`
    console.error(errorMessage)
    return errorMessage
  }
}

const sendFBPixelPurchaseEvents = async ({
  transactionId,
  consumerCart,
  customerId
}: BasicPurchaseEventData) => {
  try {
    const trackParams = {
      id: customerId ? customerId : null,
      value:
        consumerCart.summary.total.discounted ||
        consumerCart.summary.total.base,
      order_id: transactionId,
      store_id: consumerCart.store.id,
      total:
        consumerCart.summary.total.discounted ||
        consumerCart.summary.total.base,
      currency: 'GBP',
      fulfillment_type: consumerCart.fulfillment.type,
      products: consumerCart.orderItems.map((item) => ({
        product_id: item.variantId,
        name: item.name,
        quantity: item.quantity,
        price: item.total.discounted || item.total.base
      }))
    }
    trackUserPurchaseFBPixel(trackParams)
    return 'Success'
  } catch (error) {
    let errorMessage = `Failed to send FB Pixel "Purchase" event.`
    if (error) errorMessage += `: ${error}`
    console.error(errorMessage)
    return errorMessage
  }
}

const sendGA4EcommercePurchaseEvents = async ({
  consumerCart,
  transactionId,
  merchantName
}: FullPurchaseEventData) => {
  try {
    if (merchantName) {
      const purchaseParams = createGA4EcommPurchaseParams(consumerCart, {
        transactionId,
        merchantName
      })
      slerpGA4EcommTrackPurchase(purchaseParams)
      merchantGA4EcommTrackPurchase(purchaseParams)
      return 'Success'
    }
    return 'No merchantName or storeName'
  } catch (error) {
    let errorMessage = `Failed to send GA4 ecommerce "Purchase" events.`
    if (error) errorMessage += `: ${error}`
    console.error(errorMessage)
    return errorMessage
  }
}

/** Helper function to send all purchase events to all available trackers.
 *
 *  We want to await each tracker to ensure we are not navigating before data has been sent.
 */
export const sendPurchaseTracking = async ({
  consumerCart,
  transactionId,
  merchantName,
  customerId
}: FullPurchaseEventData) => {
  // Custom GA4 Events
  await sendCustomGA4PurchaseEvents()
  // Google conversion Event
  await sendGoogleConversionEvents({ consumerCart, transactionId })
  // FB Pixel Event
  await sendFBPixelPurchaseEvents({ consumerCart, transactionId, customerId })
  // GA4 Ecommerce Events
  await sendGA4EcommercePurchaseEvents({
    consumerCart,
    transactionId,
    merchantName
  })

  return 'Purchase tracking finished'
}
