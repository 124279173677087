import styled from '@emotion/styled'
import { IoIosUnlock as LockIcon } from 'react-icons/io'

type Props = {
  message: string
  icon: string
}

const POSSIBLE_ICONS = {
  default: LockIcon,
  lock: LockIcon
}

const CustomizedMessage = ({ message, icon }: Props) => {
  const DynamicIcon = POSSIBLE_ICONS[icon] || POSSIBLE_ICONS['default']

  return (
    <Container>
      <Content>
        <DynamicIcon />
        <p className='message'>{message}</p>
      </Content>
    </Container>
  )
}

const Container = styled.div(({ theme }: any) => ({
  width: '100%',
  backgroundColor: '#fafafa',
  border: '0.5px solid #ddd',
  borderRadius: '4px',
  marginTop: '12px'
}))

const Content = styled.div(({ theme }: any) => ({
  textAlign: 'center',
  margin: 'auto',
  fontSize: '12px',
  padding: '12px 16px',
  lineHeight: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',

  '>svg': {
    fill: '#000',
    height: '30px',
    minWidth: '30px'
  }
}))

export default CustomizedMessage
