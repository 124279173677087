import { eachDayOfInterval, addDays, isAfter, parseISO } from 'date-fns'
import { dateAllowedForPreorder, isDateAvailable } from './datePickerUtils'
import {
  Store,
  OperatingSchedules,
  SpecialCutoffs,
  AvailableDates
} from './types'
import { isStoreOperatingForDate } from './utils'

export const getStorePreOrderSettings = (store: Store) =>
  store.pre_order_settings && store.pre_order_settings.length > 0
    ? store.pre_order_settings[0]
    : null

const DEFAULT_ALLOWANCE = 28

export const getMaxDaysAllowance = (stores: Store[]) =>
  stores.length > 0
    ? Math.max(
        ...stores.map((store) => {
          const settings = getStorePreOrderSettings(store)
          if (settings && typeof settings.pre_order_day_allowance === 'number')
            return settings.pre_order_day_allowance
          return DEFAULT_ALLOWANCE
        })
      )
    : DEFAULT_ALLOWANCE

export const findNextAvailableDate = (
  stores: Store[],
  fulfillmentType: string,
  availableDates: AvailableDates
) => {
  const today = new Date()
  const maxDaysAllowance = getMaxDaysAllowance(stores)

  return (
    eachDayOfInterval({
      start: today,
      end: addDays(today, maxDaysAllowance)
    }).find((day: Date) =>
      stores.find(
        (store) =>
          doesStoreAcceptPreordersForDate(day, store, fulfillmentType) &&
          isDateAvailable(availableDates, day, fulfillmentType)
      )
    ) || today
  )
}

export const findNextAvailablePreOrderDate = (
  stores: Store[],
  fulfillmentType: string,
  availableDates: AvailableDates
) => {
  const today = new Date()
  const tomorrow = addDays(today, 1)
  const maxDaysAllowance = getMaxDaysAllowance(stores)

  return (
    eachDayOfInterval({
      start: tomorrow,
      end: addDays(tomorrow, maxDaysAllowance)
    }).find((day: Date) =>
      stores.find(
        (store) =>
          doesStoreAcceptPreordersForDate(day, store, fulfillmentType) &&
          isDateAvailable(availableDates, day, fulfillmentType)
      )
    ) || tomorrow
  )
}

export const getStorePreOrderSchedule = (
  orderHours: 'custom_hours' | 'opening_hours',
  sameDaySchedule?: OperatingSchedules | null,
  preOrderSchedule?: OperatingSchedules | null
) => {
  if (orderHours === 'custom_hours') return preOrderSchedule
  return sameDaySchedule
}

export const doesStoreAcceptPreordersForDate = (
  date: Date,
  store: Store,
  fulfillmentType: string,
  currentDate: Date = new Date() // for testing
) => {
  const preOrderSettings = getStorePreOrderSettings(store)
  if (!preOrderSettings) return false
  if (!preOrderSettings[`${fulfillmentType}_enabled`]) return false

  const dayAllowance =
    preOrderSettings.pre_order_day_allowance || DEFAULT_ALLOWANCE
  const lastDay = addDays(new Date(), dayAllowance)
  if (dayAllowance && isAfter(date, lastDay)) return false

  const cutoffs = preOrderSettings.cutoffs

  const specialCutoffs =
    preOrderSettings && preOrderSettings.special_cutoffs_map

  return (
    isStoreOperatingForDate(date, store.split_hours) &&
    dateAllowedForPreorder(date, cutoffs, specialCutoffs, currentDate)
  )
}

export const getUpcomingSpecialCutoffs = (
  cutoffs: SpecialCutoffs,
  enabledValue?: boolean | null
) =>
  Object.keys(cutoffs || {}).filter((key) => {
    const cutoff = cutoffs ? cutoffs[key] : null
    if (!cutoff) return false
    return (
      isAfter(parseISO(cutoff?.cutoff_date), new Date()) &&
      (enabledValue ? cutoff.enabled === enabledValue : true)
    )
  })
