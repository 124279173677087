import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'

const DeliveryPin = ({ pincode }: { pincode: string }) => {
  return (
    <Root>
      <Pin>{pincode}</Pin>
      <Instruction>
        You will need to provide this code to receive your order.
      </Instruction>
    </Root>
  )
}

const Root = styled.div<StyledHTMLElement, Required<Theme>>(() => ({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
  backgroundColor: '#f5f5f5',
  padding: '12px',
  borderRadius: '12px'
}))

const Pin = styled.p<StyledHTMLElement, Required<Theme>>(() => ({
  display: 'block',
  margin: '0',
  padding: '12px 6px 12px 12px',
  borderRadius: '12px',
  backgroundColor: '#262626',
  textAlign: 'center',
  color: '#fff',
  fontWeight: 700,
  fontSize: '18px',
  letterSpacing: '6px'
}))

const Instruction = styled.p<StyledHTMLElement, Required<Theme>>(() => ({
  margin: '0',
  color: '#262626',
  fontWeight: 700,
  fontSize: '16px'
}))

export default DeliveryPin
