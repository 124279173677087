import { BrowserRouter, Switch, Route, useLocation } from 'react-router-dom'
import { Cart, Account } from '../shop/pages'
import { useQueryParams, useShop } from 'shop/hooks'

import { StoreV1 } from './StoreV1'
import { LoadDineIn } from './LoadDineIn'
import {
  CheckoutWrapper,
  ConfirmationWrapper,
  TrackWrapper,
  SplitWrapper,
  PayWrapper,
  LandingWrapper,
  PasswordWrapper,
  PurchaseWrapper
} from './wrappers'
import { useRwg } from 'shop/hooks/useRwg'
import { useEffect } from 'react'

export const Router = () => {
  const { config } = useShop()
  const { manageRwgToken } = useRwg()
  const { getQueryParam, deleteQueryParam } = useQueryParams()
  const location = useLocation()

  useEffect(() => {
    manageRwgToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.domain])

  /** Gets query param as feature flag to enable checkout page with cartv2 & set value locally */
  useEffect(() => {
    const checkoutV2Param = getQueryParam('checkoutv2')
    if (checkoutV2Param) {
      localStorage.setItem('checkoutv2', 'true')
      deleteQueryParam('checkoutv2')
    }
  }, [getQueryParam, deleteQueryParam, location.search])

  return (
    <BrowserRouter basename={config.path}>
      <Switch>
        <Route exact={true} path='/' component={LandingWrapper} key='home' />
        <Route
          exact={true}
          path='/table/:slug'
          component={LoadDineIn}
          key='home'
        />
        <Route
          exact={true}
          path={['/forgot-password', '/reset-password/:token']}
          component={PasswordWrapper}
        />
      </Switch>
      <Route path='/store/:slug' component={StoreV1} key='store' />
      <Route path='/cart' component={Cart} exact />
      <Route path='/account' component={Account} />
      <Route path='/checkout' component={CheckoutWrapper} />
      <Route path='/pay' component={PayWrapper} />
      <Route path='/split' component={SplitWrapper} />
      <Route path='/purchase/:id' component={PurchaseWrapper} />
      <Route path='/track/:id' component={TrackWrapper} />
      <Route
        path='/confirmation/:cart_id/:payment_id?'
        component={ConfirmationWrapper}
      />
    </BrowserRouter>
  )
}
