import styled from '@emotion/styled'
import {
  IoIosPin as LocationIcon,
  IoIosCalendar as CalendarIcon,
  IoIosTime as ClockIcon
} from 'react-icons/io'
import OrderDetail from './OrderDetail'
import CartActions from './CartActions'

interface Props {
  date: string
  time: string
  address: string
  isDelivery?: boolean
  merchantId: string
}

export const CheckoutOrderDetailsList = ({
  date,
  time,
  address,
  isDelivery
}: Omit<Props, 'merchantId'>) => {
  return (
    <CheckoutOrderList>
      <Date date={date} />
      <Time time={time} />
      {!isDelivery && <Address address={address} />}
    </CheckoutOrderList>
  )
}

const OrderDetailsList = ({ date, time, address, merchantId }: Props) => {
  return (
    <List>
      <Date date={date} />
      <Time time={time} />
      <Address address={address} />
      <CartActions merchantId={merchantId} />
    </List>
  )
}

export const Date = (props: { date: string }) => {
  return (
    <ListItem>
      <OrderDetail icon={<CalendarIcon />} text={props.date} />
    </ListItem>
  )
}

export const Time = (props: { time: string }) => {
  return (
    <ListItem>
      <OrderDetail icon={<ClockIcon />} text={props.time} />
    </ListItem>
  )
}

export const Address = (props: { address: string }) => {
  return (
    <ListItem>
      <OrderDetail icon={<LocationIcon />} text={props.address} />
    </ListItem>
  )
}

export const List = styled.ul(({ theme }: any) => ({
  fontSize: theme.fontSizes[0],
  listStyleType: 'none',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  justifyContent: 'center',
  '& > li:not(:last-of-type)': {
    display: 'none',
    [theme.mediaQueries.viewport7]: {
      display: 'initial'
    }
  }
}))

const CheckoutOrderList = styled(List)(({ theme }: any) => ({
  fontSize: theme.fontSizes[0],
  listStyleType: 'none',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  justifyContent: 'center',
  '& > li:not(:last-of-type), & > li:last-of-type': {
    display: 'none',
    [theme.mediaQueries.viewport6]: {
      display: 'initial'
    }
  },
  '& > li:last-child': { marginRight: 0 }
}))

const ListItem = styled.li(({ theme }: any) => ({
  flexShrink: 0,
  marginRight: '12px',
  fontSize: '10px',
  [theme.mediaQueries.viewport10]: {
    marginRight: '24px',
    fontSize: '12px'
  }
}))

export default OrderDetailsList
