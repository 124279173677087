import React from 'react'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { ReactComponent as DeliveryIcon } from 'shop/assets/icons/delivery.svg'
import { ReactComponent as PickupIcon } from 'shop/assets/icons/pickup.svg'
import { ReactComponent as HomeIcon } from 'shop/assets/icons/home.svg'
import Spinner from '../Loader/Spinner'

export type EstimatedTimeType = {
  range?: string
  formattedDatetime?: string
}

export type EstimatedFulfillmentWindowProps = {
  fulfillmentType: 'pickup' | 'delivery' | string
  estimatedTime?: EstimatedTimeType
  storeName: string
  address: string
}

const EstimatedFulfillmentWindow = ({
  fulfillmentType,
  estimatedTime,
  storeName,
  address
}: EstimatedFulfillmentWindowProps) => {
  return (
    <Container data-testid='estimated-fulfillment-window'>
      {fulfillmentType === 'delivery' && (
        <>
          <EstimatedFulfillmentWindowItem icon={<DeliveryIcon />}>
            <div>
              <h1>Estimated arrival:</h1>
              <span>
                {estimatedTime?.range ? estimatedTime.range : <Spinner />}
              </span>
            </div>
            <div>
              <h1>From:</h1>
              <span>{storeName}</span>
            </div>
          </EstimatedFulfillmentWindowItem>
          <IconConnector />
          <EstimatedFulfillmentWindowItem icon={<HomeIcon />}>
            <div>
              <h1>Deliver to:</h1>
            </div>
            <div>
              <span>{address}</span>
            </div>
          </EstimatedFulfillmentWindowItem>
        </>
      )}
      {fulfillmentType === 'pickup' && (
        <EstimatedFulfillmentWindowItem icon={<PickupIcon />}>
          <div>
            <h1>Pickup at:</h1>
            <span>
              {estimatedTime?.formattedDatetime ? (
                estimatedTime?.formattedDatetime
              ) : (
                <Spinner />
              )}
            </span>
          </div>
          <div>
            <h1>From:</h1>
            <span>{storeName}</span>
          </div>
        </EstimatedFulfillmentWindowItem>
      )}
    </Container>
  )
}

const EstimatedFulfillmentWindowItem = ({
  icon,
  children
}: {
  icon: React.ReactNode
  children: React.ReactNode
}) => {
  return (
    <ItemContainer>
      <IconContainer>{icon}</IconContainer>
      <ContentContainer>{children}</ContentContainer>
    </ItemContainer>
  )
}

const Container = styled.div<StyledHTMLElement>(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 0',
  borderBottom: '1px solid #E7E5E9'
}))

const ItemContainer = styled.div<StyledHTMLElement>(() => ({
  display: 'flex',
  gap: '20px'
}))

const IconContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    padding: '0 10px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    border: '2px solid',
    height: '100%',
    aspectRatio: '1/1',
    borderColor: '#f2f2f2',

    '& svg': {
      margin: 0,
      width: '34px',
      '& path': {
        fill: theme.colors['primary']
      }
    }
  })
)
const ContentContainer = styled.div<StyledHTMLElement>(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '5px',

  '& div': {
    display: 'flex',
    gap: '1ch',
    '& h1': {
      fontSize: '14px',
      fontWeight: 300
    },

    '& span:first-of-type': {
      fontSize: '14px',
      fontWeight: 'bold',
      textTransform: 'capitalize'
    },
    '& span': {
      fontWeight: 300,
      textTransform: 'uppercase'
    }
  }
}))
const IconConnector = styled.div<StyledHTMLElement, Required<Theme>>(() => ({
  height: '40px',
  marginLeft: '28px',
  borderLeft: '2px dotted',
  borderColor: '#f2f2f2'
}))

export default EstimatedFulfillmentWindow
