import { ProductLoader } from '../Loader'
import { ProductsContainer, List } from './commonStyles'

export const LoadingContainer = () => (
  <ProductsContainer>
    <List>
      <li key='product-loader-1'>
        <ProductLoader />
      </li>
      <li key='product-loader-2'>
        <ProductLoader />
      </li>
      <li key='product-loader-3'>
        <ProductLoader />
      </li>
      <li key='product-loader-4'>
        <ProductLoader />
      </li>
    </List>
  </ProductsContainer>
)
