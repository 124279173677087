import styled from '@emotion/styled'
import { StyledHTMLElement } from 'shop/theme/types'

const DeliverySignIn = () => {
  return (
    <SignInCTAContainer>
      <SignInCTA data-testid='signin-cta'>Sign in</SignInCTA>
      <span> to get access to your previous addresses</span>
    </SignInCTAContainer>
  )
}

const SignInCTA = styled.p<StyledHTMLElement>(() => ({
  fontWeight: 'bold',
  textDecoration: 'underline',
  cursor: 'pointer',
  whiteSpace: 'pre-wrap',
  ...commonCTAStyles
}))

const SignInCTAContainer = styled.div<StyledHTMLElement>(() => ({
  '> span': {
    color: '#595959',
    fontWeight: 'normal',
    whiteSpace: 'pre-wrap',
    ...commonCTAStyles
  }
}))

const commonCTAStyles = {
  display: 'inline',
  margin: 0,
  fontSize: '16px'
}

export default DeliverySignIn
