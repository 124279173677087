import { useState, createContext, useContext } from 'react'
import {
  Address,
  Fulfillment,
  FulfillmentType,
  DELIVERY_FULFILLMENT,
  OpeningTimes,
  Message
} from 'shop/types'
import { MerchantStoreQueryResponse } from './types'
import { timezoneNow } from 'shop/utils'

type MerchantStoreState = {
  welcomeMessage: string | null
  storeTimezone: string | null
  storeName: string | null
  storeContactNumber: string | null
  storeAddress: Address | null
  defaultFulfillmentType: FulfillmentType
  deliveryFulfillment: Fulfillment | null
  pickupFulfillment: Fulfillment | null
  openingTimes: OpeningTimes | null
  FulfillmentTimeStaticTimeStamp: Date
  hasMerchantStoreLoaded: boolean
  setMerchantStore: (store: MerchantStoreQueryResponse) => void
  storeDescription: string | null
  storeSeoDescription: string | null
  messages: Array<Message> | null
}

export const SetupMerchantStore = (): MerchantStoreState => {
  const [welcomeMessage, setWelcomeMessage] = useState<string | null>(null)
  const [storeName, setStoreName] = useState<string | null>(null)
  const [storeTimezone, setStoreTimezone] = useState<string | null>(null)
  const [storeContactNumber, setStoreContactNumber] = useState<string | null>(
    null
  )
  const [storeAddress, setStoreAddress] = useState<Address | null>(null)
  const [defaultFulfillmentType, setDefaultFulfillmentType] =
    useState<FulfillmentType>(DELIVERY_FULFILLMENT)
  const [pickupFulfillment, setPickupFulfillment] =
    useState<Fulfillment | null>(null)
  const [deliveryFulfillment, setDeliveryFulfillment] =
    useState<Fulfillment | null>(null)
  const [openingTimes, setOpeningTimes] = useState<OpeningTimes | null>(null)
  const [hasMerchantStoreLoaded, setHasMerchantStoreLoaded] = useState(false)
  const [storeDescription, setStoreDescription] = useState<string | null>(null)
  const [storeSeoDescription, setStoreSeoDescription] = useState<string | null>(
    null
  )
  const [messages, setMessages] = useState<Array<Message> | null>(null)

  const FulfillmentTimeStaticTimeStamp =
    initialState.FulfillmentTimeStaticTimeStamp

  const setMerchantStore = (store: MerchantStoreQueryResponse) => {
    setStoreTimezone(store?.storeInformation?.timezone || null)
    setStoreName(store?.storeInformation?.name || null)
    setStoreContactNumber(store?.storeInformation?.contactNumber || null)
    setStoreAddress(store?.storeInformation?.address || null)
    setDefaultFulfillmentType(
      store?.defaultOption?.fulfillmentType || DELIVERY_FULFILLMENT
    )
    setPickupFulfillment(store?.earliestPickupFulfillment || null)
    setDeliveryFulfillment(store?.earliestDeliveryFulfillment || null)
    setOpeningTimes(store?.openingTimes || null)
    setWelcomeMessage(store?.storeInformation?.welcomeMessage || null)
    setHasMerchantStoreLoaded(true)
    setStoreDescription(store?.storeInformation?.description || null)
    setStoreSeoDescription(store?.storeInformation?.seoDescription || null)
    setMessages(store?.messages ?? null)
  }

  return {
    storeTimezone,
    welcomeMessage,
    storeName,
    storeAddress,
    storeContactNumber,
    defaultFulfillmentType,
    pickupFulfillment,
    deliveryFulfillment,
    openingTimes,
    setMerchantStore,
    FulfillmentTimeStaticTimeStamp,
    hasMerchantStoreLoaded,
    storeDescription,
    storeSeoDescription,
    messages
  }
}

const initialState: MerchantStoreState = {
  storeName: null,
  welcomeMessage: null,
  storeTimezone: null,
  storeAddress: null,
  storeContactNumber: null,
  defaultFulfillmentType: DELIVERY_FULFILLMENT,
  deliveryFulfillment: null,
  pickupFulfillment: null,
  openingTimes: null,
  FulfillmentTimeStaticTimeStamp: timezoneNow(),
  hasMerchantStoreLoaded: false,
  setMerchantStore: () => {},
  storeDescription: null,
  storeSeoDescription: null,
  messages: null
}

export const MerchantStoreContext =
  createContext<MerchantStoreState>(initialState)

export const useMerchantStore = () => {
  return useContext(MerchantStoreContext)
}
