import styled from '@emotion/styled'
import { CartModal } from './../components/Cart'
import { usePageViewTracker } from 'tracker'

interface Props {
  cart?: object
  path?: string
}

const Container = styled.div(() => ({
  backgroundColor: 'grey',
  minHeight: '100vh',
  position: 'relative'
}))

const Cart = ({ cart }: Props) => {
  // Track page view
  usePageViewTracker()

  return (
    <Container>
      <CartModal />
    </Container>
  )
}

export default Cart
