import React from 'react'
import styled from '@emotion/styled'
import {
  IoMdRadioButtonOff as RadioOff,
  IoMdRadioButtonOn as RadioOn
} from 'react-icons/io'
import {
  MUTATE_FALSIFY_AND_SET_DEFAULT_BILLING_ADDRESS_FLAGS,
  MUTATE_FALSIFY_AND_SET_DEFAULT_SHIPPING_ADDRESS_FLAGS,
  MUTATE_FALSIFY_DEFAULT_SHIPPING_ADDRESS_FLAG,
  MUTATE_FALSIFY_DEFAULT_BILLING_ADDRESS_FLAG
} from 'shop/client/queries/CustomerQueries'
import { useShop } from 'shop/hooks'

interface Props {
  index: number
  default_shipping_address: boolean
  default_billing_address: boolean
  addressId: string
  customerId: string
  toggleLoading: (arg0: boolean) => void
  requeryAddresses: () => void
}

const AddressRadioButtons = ({
  index,
  default_shipping_address,
  default_billing_address,
  customerId,
  addressId,
  toggleLoading,
  requeryAddresses
}: Props) => {
  const { useShopClient } = useShop()
  const client = useShopClient()

  const onShippingClick = () => {
    toggleLoading(true)

    const mutation = default_shipping_address
      ? MUTATE_FALSIFY_DEFAULT_SHIPPING_ADDRESS_FLAG
      : MUTATE_FALSIFY_AND_SET_DEFAULT_SHIPPING_ADDRESS_FLAGS

    const variables = default_shipping_address
      ? { customerId }
      : { customerId, addressId }

    client
      .mutate({
        mutation: mutation,
        variables: variables
      })
      .then((results) => requeryAddresses())
  }

  const onBillingClick = () => {
    toggleLoading(true)

    const mutation = default_billing_address
      ? MUTATE_FALSIFY_DEFAULT_BILLING_ADDRESS_FLAG
      : MUTATE_FALSIFY_AND_SET_DEFAULT_BILLING_ADDRESS_FLAGS

    const variables = default_billing_address
      ? { customerId }
      : { customerId, addressId }

    client
      .mutate({
        mutation: mutation,
        variables: variables
      })
      .then(() => requeryAddresses())
  }

  return (
    <RadioContainer data-testid={`addressRadioButton-${addressId}`}>
      <Radio
        onClick={() => onShippingClick()}
        data-testid={`shippingButton-${index}`}
      >
        {default_shipping_address ? <RadioOn /> : <RadioOff />}
        <label> Default shipping address</label>
      </Radio>
      <Radio
        onClick={() => onBillingClick()}
        data-testid={`billingButton-${index}`}
      >
        {default_billing_address ? <RadioOn /> : <RadioOff />}
        <label> Default billing address</label>
      </Radio>
    </RadioContainer>
  )
}

const RadioContainer = styled.div(({ theme }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start'
}))

const Radio = styled.div(({ theme }: any) => ({
  fontSize: theme.fontSizes[0],
  padding: '2px 0',
  '&:hover': {
    cursor: 'pointer'
  },
  '& > label:hover': {
    cursor: 'pointer'
  }
}))

export default AddressRadioButtons
