export enum TrackableEvent {
  LandingPageViewed = 'Landing Page Viewed',

  UserLoggedIn = 'User Logged In',
  UserLoggedOut = 'User Logged Out',
  UserRegistered = 'User Registered',

  SelectedDelivery = 'Selected Delivery Option',
  SelectedPickUp = 'Selected Pick-up Option',

  // Following Segment's E-commerce related events at
  // https://segment.com/docs/connections/spec/ecommerce/v2/
  // Browsing
  ProductsSearched = 'Products Searched',
  ProductListViewed = 'Product List Viewed',
  ProductListFiltered = 'Product List Filtered',

  // Promotions
  PromotionViewed = 'Promotion Viewed',
  PromotionClicked = 'Promotion Clicked',

  // Core Ordering
  ProductClicked = 'Product Clicked',
  ProductViewed = 'Product Viewed',
  ProductAdded = 'Product Added',
  ProductRemoved = 'Product Removed',
  ProductCustomized = 'Product Customized', // not included in segment ecommerce v2
  CartViewed = 'Cart Viewed',
  CartCleared = 'Cart Cleared', // not included in segment ecommerce v2
  CheckoutStarted = 'Checkout Started',
  CheckoutStepViewed = 'Checkout Step Viewed',
  CheckoutStepCompleted = 'Checkout Step Completed',
  PaymentInfoEntered = 'Payment Info Entered',
  OrderCompleted = 'Order Completed',
  OrderPending = 'Order Pending',
  OrderUpdated = 'Order Updated',
  OrderRefunded = 'Order Refunded',
  OrderCancelled = 'Order Cancelled',

  // Payment
  PaymentBackButtonClicked = 'Payment Back Button Clicked',
  PaymentSelectPaymentMethod = 'Payment Method Selected',
  PaymentPayButtonClicked = 'Pay Button Clicked',

  // Minimum Order Value
  MOVDisplayed = 'MOV Displayed',
  MOVActioned = 'MOV Actioned',

  // Coupons
  CouponEntered = 'Coupon Entered',
  CouponApplied = 'Coupon Applied',
  CouponDenied = 'Coupon Denied',
  CouponRemoved = 'Coupon Removed',

  // Wishlisting
  ProductAddedToWishlist = 'Product Added to Wishlist',
  ProductRemovedFromWishlist = 'Product Removed from Wishlist',
  WishlistProductAddedToCart = 'Wishlist Product Added to Cart',

  // Sharing
  ProductShared = 'Product Shared',
  CartShared = 'Cart Shared',

  // Reviews
  ProductReviewed = 'Product Reviewed',

  // Timeslot Modal
  UpdateTimeslot = 'Clicked Update Time Slot',
  ReturnFromTimeslot = 'Clicked Return'
}

export enum TrackerLogLevel {
  DEBUG = 'debug',
  INFO = 'info',
  WARNING = 'warn',
  ERROR = 'error'
}

export interface TrackerConfig {
  apiKey: string
  trackingEnabled: boolean
  loggingEnabled: boolean
}

export interface TrackableProduct {
  product_id: string
  name: string
  category: string
  price?: number
  currency?: string
  image_url?: string
  url?: string
  variant?: string
}

export interface TrackableProductProps {
  id: string
  name: string
  category: string
  price?: number
  currency?: string
  imageUrl?: string
  variant?: string
  url?: string
}

export interface TrackableCartItem {
  cart_id: string
  product_id: string
  name: string
  category: string
  price: number
  currency: string
  quantity: number
}
