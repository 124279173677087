import styled from '@emotion/styled'

interface Props
  extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  label: string
  variant?: 'countryCode' | 'textbox' | 'password'
  type: string
  showError?: boolean
  testId?: string
  labelSize?: string
}

const Container = styled.div(() => ({
  position: 'relative',
  marginBottom: '16px',
  width: '100%'
}))

const Input = styled.input((props: any) => ({
  width: '100%',
  height: '40px',
  fontSize: props.theme.fontSizes[2],
  fontWeight: props.theme.fontWeights.light,
  padding: '8px 15px',
  outline: 0,
  border: props.showError
    ? `1px solid ${props.theme.colors.state.error[5]}`
    : '1px solid lightgrey',
  borderRadius: '12px',

  '&:focus': {
    border: `2px solid ${props.theme.colors.primary}`
  }
}))

const Textarea = styled.textarea((props: any) => ({
  width: '100%',
  height: '80px',
  fontSize: props.theme.fontSizes[2],
  fontWeight: props.theme.fontWeights.light,
  padding: '8px 15px',
  outline: 0,
  border: '1px solid lightgrey',
  borderRadius: '20px',
  '&:focus': {
    border: `2px solid ${props.theme.colors.primary}`
  }
}))

const Label = styled.label((props: any) => ({
  marginBottom: props.labelSize === 'small' ? '4px' : '8px',
  display: 'block',
  fontFamily: props.theme.fonts.body.family,
  fontSize:
    props.labelSize === 'small'
      ? props.theme.fontSizes[0]
      : props.theme.fontSizes[1],
  color: props.theme.colors.textBody
}))

const InputField = (props: Props) => {
  const { variant, label, testId } = props
  return (
    <Container>
      <Label {...props}>{label}</Label>
      {variant === 'textbox' ? (
        <Textarea {...props} datatestid={testId} />
      ) : (
        <Input {...props} data-testid={testId} />
      )}
    </Container>
  )
}

InputField.defaultProps = {
  label: '',
  variant: '',
  placeholder: '',
  readOnly: false,
  type: ''
}

export default InputField
