import { StyledHTMLElement } from 'shop/theme/types'
import CenterModal from '../Modal/CenterModal'
import { Button } from '../Controls'
import { useAccount } from 'shop/hooks/useAccount'
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'
import { useShop } from 'shop/hooks/useGlobalContext'

const OrderAuthModal = () => {
  const history = useHistory()
  const { handleLogout } = useAccount()
  const { allStores } = useShop()

  const storeSlug = allStores[0]?.slug
  const onClose = () => history.replace(`/store/${storeSlug}`)

  return (
    <CenterModal canClose={false}>
      <Container data-testid='order-auth-modal'>
        <Header>Hmm, it seems you do not have access to this order</Header>
        <p>
          Unfortunately, you are not authorised to track this order. We keep all
          information confidential for your safety. You can login with a
          different account to track this order or go back to the shop page
        </p>
        <Button data-testid='logout-button' onClick={handleLogout}>
          Login with a different account
        </Button>
        <Button
          variant='secondary'
          testId='continue-shop-text'
          onClick={onClose}
        >
          Continue to the shop page
        </Button>
      </Container>
    </CenterModal>
  )
}

const Container = styled.div<StyledHTMLElement>(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '32px',
  gap: '24px',
  justifyContent: 'center',
  marginTop: '20px',

  '& >button': {
    borderRadius: '12px'
  }
}))

const Header = styled.h1<StyledHTMLElement>(() => ({
  fontSize: '24px',
  alignSelf: 'left'
}))

export default OrderAuthModal
