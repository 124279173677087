import styled from '@emotion/styled'
import ContentLoader from 'react-content-loader'
import { StoreContainer } from '../Landing/Stores/Store'

export const ProductImageLoader = () => (
  <ContentLoader>
    <rect x='0' y='0' rx='0' ry='0' width='100%' height='100%' />
  </ContentLoader>
)

export const CategoryLoader = ({ ...props }) => (
  <ContentLoader width={140} height={1} {...props}>
    <rect x='62' y='0' rx='0' ry='0' width='8' height={4} />
    <rect x='72' y='0' rx='0' ry='0' width='8' height={4} />
  </ContentLoader>
)

export const ProductLoader = () => (
  <ContentLoader speed={2} width={400} height={400}>
    <rect x='0' y='0' width={400} height={300} />
    <rect x='0' y='320' width={300} height={24} />
    <rect x='320' y='320' width={80} height={24} />
  </ContentLoader>
)

export const CartLoader = () => (
  <ContentLoader speed={1.5} width={10} height={6}>
    <rect x='30' y='0' rx='0' ry='0' width={80} height={6} />
  </ContentLoader>
)

export const StoreLoader = () => (
  <Store>
    <ContentLoader
      speed={2}
      width={400}
      height={44}
      primaryOpacity={0.3}
      secondaryOpacity={0.5}
    />
  </Store>
)

const Store = styled(StoreContainer)(({ theme }: any) => ({
  borderColor: 'transparent',
  cursor: 'auto',
  background: '#f7f7f7',
  padding: 0,
  '&:hover': {
    borderColor: 'transparent'
  },
  [theme.mediaQueries.viewport7]: {
    borderColor: 'transparent',
    padding: 0
  }
}))
