import { addDays, isPast, parseISO } from 'date-fns'
import { RWGToken } from 'shop/types'
import AllTrackers from 'tracker/AllTrackers'
import { expandEnrichedEventData } from 'tracker/utils'
import { useShop } from './useGlobalContext'
import { useQueryParams } from './useQueryParams'

export const useRwg = () => {
  const { config } = useShop()
  const { domain } = config
  const { getQueryParam, deleteQueryParam } = useQueryParams()

  const rwgTokenKey = `${domain}_rwg`

  /** Gets/sets rwg token for conversion tracking later on app load
   * https://developers.google.com/actions-center/verticals/ordering/redirect/integration-steps/direct-conversion-tracking
   */
  const manageRwgToken = () => {
    if (!domain) return

    // Check and remove expired token
    const existingRwgToken = localStorage.getItem(`${domain}_rwg`)
    if (existingRwgToken) {
      try {
        const parsedToken: RWGToken = JSON.parse(existingRwgToken)
        const expiresAt = parsedToken.expires_at
        if (expiresAt && isPast(parseISO(expiresAt))) {
          localStorage.removeItem(`${domain}_rwg`)
        }
      } catch (error) {
        localStorage.removeItem(`${domain}_rwg`)
      }
    }

    // Set new token
    const newRwgToken = getQueryParam('rwg_token')
    if (newRwgToken) {
      localStorage.setItem(
        `${domain}_rwg`,
        JSON.stringify({
          token: newRwgToken,
          expires_at: addDays(new Date(), 30)
        })
      )
      deleteQueryParam('rwg_token')
    }
  }

  const getParsedRwgToken = (): RWGToken | null => {
    const rwgToken = localStorage.getItem(rwgTokenKey)
    if (!rwgToken) return null

    return JSON.parse(rwgToken)
  }

  const getRwgTokenKey = (): string => {
    return rwgTokenKey
  }

  /** Enrich GA4 Event Data with the RWG Token */
  const setRWGOnEnrichedEventData = () => {
    const rwgToken = getParsedRwgToken()?.token || ''
    expandEnrichedEventData(AllTrackers.getInstance().slerpGA4Tracker, {
      rwg_token: rwgToken
    })
    expandEnrichedEventData(AllTrackers.getInstance().merchantGA4Tracker, {
      rwg_token: rwgToken
    })
  }

  return {
    manageRwgToken,
    getParsedRwgToken,
    getRwgTokenKey,
    setRWGOnEnrichedEventData
  }
}
