import { useEffect, useState } from 'react'
import styled from '@emotion/styled'

import { usePageViewTracker } from 'tracker'

import {
  LandingContext,
  InvalidTimeSlot,
  SplashImage
} from 'shop/components/Landing'

import {
  Store,
  ValidStore,
  CurrentLocationState
} from 'shop/components/Landing/types'

import { DineInForm } from 'shop/components/DineIn'
import Footer from 'shop/components/Footer'
import { useQueryParamAccountModal, useShop } from 'shop/hooks'
import { QUERY_GET_MERCHANT_STORE } from 'shop/client'
import { FulfillmentTypeLoadingStates } from 'shop/components/Landing/LandingContext'

interface DineInProps {
  slug: string
  modal?: any
  addToCart?: any
  cartId?: string
  orderAgainCartId?: string
}

const DineIn = (props: DineInProps) => {
  const { config, useShopClient, merchant, setMerchant } = useShop()
  const client = useShopClient()

  const [stores, setStores] = useState<Store[]>([])
  const [currentStore, setCurrentStore] = useState<ValidStore>()
  const [validStores, setValidStores] = useState<ValidStore[]>([])
  const [loadingStores, setLoadingStores] =
    useState<FulfillmentTypeLoadingStates>({
      delivery: false,
      pickup: false
    })
  const [loadingDates, setLoadingDates] =
    useState<FulfillmentTypeLoadingStates>({
      delivery: false,
      pickup: false
    })
  const [fulfillmentType, setFulfillmentType] = useState<string>('')
  const [currentLocation, setCurrentLocation] = useState<CurrentLocationState>()
  const [fulfillmentDate, setFulfillmentDate] = useState<Date>(new Date())
  const [fulfillmentTime, setFulfillmentTime] = useState<string | null>(
    'immediately'
  )
  const [fulfillmentTimeRange, setFulfillmentTimeRange] = useState<
    string | null
  >('')
  const hasFooter = !currentLocation && fulfillmentType !== 'pickup'
  const [useCustomerDefaultAddress, setUseCustomerDefaultAddress] =
    useState(false)
  const [hasFailedSubmit, setHasFailedSubmit] = useState(false)
  const [showFulfillmentOptions, setShowFulfillmentOptions] = useState(false)

  const landingState = {
    merchant,
    setMerchant,
    stores,
    setStores,
    fulfillmentType,
    setFulfillmentType,
    fulfillmentDate,
    setFulfillmentDate,
    fulfillmentTime,
    setFulfillmentTime,
    fulfillmentTimeRange,
    setFulfillmentTimeRange,
    currentLocation,
    setCurrentLocation,
    currentStore,
    setCurrentStore,
    useCustomerDefaultAddress,
    setUseCustomerDefaultAddress,
    inModal: props.modal,
    addToCart: props.addToCart,
    cartId: props.cartId,
    validStores,
    setValidStores,
    orderAgainCartId: props.orderAgainCartId,
    loadingStores,
    setLoadingStores,
    loadingDates,
    setLoadingDates,
    isExtendedHour: false,
    setIsExtendedHour: () => {},
    availableDates: { delivery: {}, pickup: {} },
    getAvailableDates: () => {},
    storeOutOfRange: false,
    sameDayOnly: true,
    preOrderOnly: false,
    hasFailedSubmit,
    setHasFailedSubmit,
    showFulfillmentOptions,
    setShowFulfillmentOptions
  }

  // Always clear cartId when user visits the landing page
  useEffect(() => {
    localStorage.removeItem('cartId')
  }, [])

  // Load login modal from query params
  useQueryParamAccountModal()

  useEffect(() => {
    setLoadingStores({ ...loadingStores, [fulfillmentType]: true })
    client
      .query({
        query: QUERY_GET_MERCHANT_STORE,
        variables: { merchant: config.domain, slug: props.slug }
      })
      .then((result) => {
        const [newMerchant] = result.data.merchants
        setMerchant(newMerchant)
        setStores(newMerchant.stores)
        setLoadingStores({ ...loadingStores, [fulfillmentType]: false })
        const firstStore = newMerchant.stores[0]
        if (firstStore) {
          const { settings } = firstStore
          setCurrentStore({
            id: firstStore.id,
            name: firstStore.name,
            address: firstStore.address,
            fulfillmentTimeslots: [],
            slug: firstStore.slug,
            settings: {
              table_enabled: settings.table_enabled,
              table_welcome_message: settings.table_welcome_message,
              table_count: settings.table_count,
              table_choices: settings.table_choices,
              table_allow_counter_pickup: settings.table_allow_counter_pickup,
              table_counter_pickup_message:
                settings.table_counter_pickup_message
            }
          })
        }
      })
  }, [config.domain, setMerchant]) // eslint-disable-line

  // Use fulfillmentDate if empty
  useEffect(() => {
    if (!fulfillmentDate) {
      setFulfillmentDate(new Date())
    }
  }, [fulfillmentDate])

  // Track page view
  usePageViewTracker()

  return (
    <LandingContext.Provider value={landingState}>
      <Container>
        <FormContainer>
          <DineInForm />
          <Footer {...{ hasFooter }} />
        </FormContainer>
        {!props.modal && (
          <SplashContainer>
            <SplashImage />
          </SplashContainer>
        )}
      </Container>
      <InvalidTimeSlot />
    </LandingContext.Provider>
  )
}

const Container = styled.div(({ theme }: any) => ({
  position: 'relative',
  height: '100%',
  backgroundColor: theme.colors.lineColor,
  minHeight: '100vh',
  '& > *:only-child': {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 48px)',
    width: '100%',
    margin: 0
  },
  [theme.mediaQueries.viewport7]: {
    minHeight: 'auto'
  }
}))

const FormContainer = styled.div(({ theme }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: 'white',
  padding: '48px 16px 0px',
  minHeight: '100vh',
  [theme.mediaQueries.viewport7]: {
    padding: '0',
    overflowY: 'hidden',
    width: '45%'
  },
  [theme.mediaQueries.viewport9]: {
    width: '40%'
  }
}))

const SplashContainer = styled.div(({ theme }: any) => ({
  position: theme.designMode ? 'absolute' : 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  minHeight: '360px',
  height: '40vh',
  [theme.mediaQueries.viewport7]: {
    left: '45%',
    bottom: 0,
    height: '100vh'
  },
  [theme.mediaQueries.viewport9]: {
    left: '40%'
  }
}))

export default DineIn
