import { useState, useCallback, useEffect, useContext } from 'react'
import { Cart } from 'shop/components/Landing/types'
import {
  Discount,
  CartValidation,
  DeliveryReduction
} from './../components/Checkout/types'
import {
  validateCart,
  applyDiscount,
  removeDiscount
} from 'shop/components/Checkout/Network'
import { ApolloClient } from '@apollo/client'
import { FlashContext, useModal } from 'shop/hooks'
import {
  ApplyDiscountApiResult,
  RemoveDiscountApiResult
} from 'shop/components/Checkout/DiscountForm'
import { formatDeliveryChargeInfo } from 'shop/components'
import { isEmpty } from 'lodash'
import {
  TrackableEvent,
  trackUserActionsFBPixel,
  trackUserActionsGA4
} from 'tracker'
import { ApiError } from 'shop/types'

export interface ValidationApiResult {
  data: {
    validateCart: CartValidation
  }
}

export const useCheckout = (
  cart: Cart | null,
  client: ApolloClient<object>
) => {
  const { pushMessage } = useContext(FlashContext)

  const deliveryFeeDiscount = cart?.discount_breakdown?.find(
    ({ item }) => item === 'delivery_fee'
  )
  const storeFeeDiscount = cart?.discount_breakdown?.find(
    ({ item }) => item === 'store_fee'
  )

  const existingDiscount =
    cart && cart.discount_code
      ? {
          discount_code: cart.discount_code,
          discount_id: cart.discount_id,
          discount_amount: +cart.discount_amount,
          discount_target: cart.discount_target,
          discount_value: cart.discount_value,
          discount_type: cart.discount_type,
          discount_trigger: cart.discount_trigger,
          subtotal_discount_amount: parseFloat(
            deliveryFeeDiscount?.discount || '0'
          ),
          store_fee_info: storeFeeDiscount
            ? {
                base: storeFeeDiscount.amount,
                discounted: (
                  +storeFeeDiscount.amount - +storeFeeDiscount.discount
                ).toFixed(2),
                reduction: storeFeeDiscount.discount
              }
            : null
        }
      : null

  const [appliedDiscount, setAppliedDiscount] = useState<Discount | null>(
    existingDiscount
  )
  const [deliveryCharge, setDeliveryCharge] = useState<number>(
    (cart && cart.delivery_charge) || 0
  )
  const [isDeliveryLoading, setIsDeliveryLoading] = useState(false)
  const [isValidationFailed, setIsValidationFailed] = useState(false)
  const [cartValidation, setCartValidation] = useState<CartValidation>({
    valid: true,
    isStoreOpen: true,
    isPreorder: false,
    unavailableVariantIds: [],
    discountWarningsNew: []
  })
  const [invalidVariantIds, setInvalidVariantIds] = useState<string[]>([])
  const [deliveryReduction, setDeliveryReduction] = useState<
    DeliveryReduction | undefined
  >(undefined)

  const { isModalOpen, openModal } = useModal()

  const refresh = useCallback((cart: Cart) => {
    const deliveryFeeDiscount = cart?.discount_breakdown?.find(
      ({ item }) => item === 'delivery_fee'
    )
    const storeFeeDiscount = cart?.discount_breakdown?.find(
      ({ item }) => item === 'store_fee'
    )

    const existingDiscount =
      cart && cart.discount_code
        ? {
            discount_code: cart.discount_code,
            discount_id: cart.discount_id,
            discount_amount: +cart.discount_amount,
            discount_target: cart.discount_target,
            discount_value: cart.discount_value,
            discount_type: cart.discount_type,
            subtotal_discount_amount: parseFloat(
              deliveryFeeDiscount?.discount || '0'
            ),
            store_fee_info: storeFeeDiscount
              ? {
                  base: storeFeeDiscount.amount,
                  discounted: (
                    +storeFeeDiscount.amount - +storeFeeDiscount.discount
                  ).toFixed(2),
                  reduction: storeFeeDiscount.discount
                }
              : null
          }
        : null
    setAppliedDiscount(existingDiscount)
    setDeliveryCharge((cart && cart.delivery_charge) || 0)
  }, [])

  const checkoutValidateCart = useCallback(() => {
    if (!cart?.id) return
    // Network validation
    validateCart(client, cart.id)
      .then((result: ValidationApiResult) => {
        const { valid, unavailableVariantIds } = result.data.validateCart

        setCartValidation({
          ...result.data.validateCart,
          isPreorder: cart.is_preorder
        })

        if (!valid && !unavailableVariantIds?.length) openModal('timeslot')

        if (!!unavailableVariantIds?.length) {
          setInvalidVariantIds(unavailableVariantIds)
        }
      })
      .catch(() => {
        setIsValidationFailed(true)
      })
  }, [cart])

  useEffect(() => {
    if (!cart) return
    checkoutValidateCart()
  }, [cart])

  /** Apply discount and update delivery reductions */
  const checkoutApplyDiscount = (discountCode: string) => {
    if (!cart?.id) return new Promise<void>((resolve) => resolve())
    return applyDiscount(client, {
      discountCode: discountCode,
      cartId: cart.id
    })
      .then(({ data }: ApplyDiscountApiResult) => {
        if (!data?.discount) return
        const {
          discountId,
          discountCode,
          totalDiscount,
          target,
          trigger,
          value,
          type,
          deliveryChargeInfo,
          subtotalInfo,
          storeFeeInfo
        } = data?.discount
        setAppliedDiscount({
          discount_amount: +totalDiscount,
          discount_id: discountId,
          discount_code: discountCode,
          discount_value: value,
          discount_trigger: trigger,
          discount_target: target,
          discount_type: type,
          subtotal_discount_amount: subtotalInfo?.reduction || 0,
          store_fee_info: {
            base: storeFeeInfo?.basePrice || '0',
            discounted: storeFeeInfo?.discountedPrice || '0',
            reduction: storeFeeInfo?.reduction || '0'
          }
        })

        const formattedDeliveryChargeInfo =
          formatDeliveryChargeInfo(deliveryChargeInfo)
        setDeliveryReduction(formattedDeliveryChargeInfo)
        const trackable = {
          content_name: discountCode,
          content_type: 'product'
        }
        const body = {
          category: 'Checkout',
          action: TrackableEvent.CouponApplied,
          label: discountCode
        }

        trackUserActionsGA4(body, 'slerpGA4Tracking')

        // legacy tracking
        trackUserActionsFBPixel(TrackableEvent.CouponApplied, trackable)
        trackUserActionsGA4(body, 'merchantGA4Tracking')
      })
      .catch((error: ApiError) => {
        const { graphQLErrors } = error
        const errorContent =
          graphQLErrors && graphQLErrors[0]
            ? graphQLErrors[0]['message']
            : 'Discount could not be applied to cart.'

        pushMessage({
          type: 'error',
          // graphQLErrors is sometimes undefined
          content: errorContent,
          timeout: 3000
        })

        const trackable = {
          content_name: discountCode,
          content_type: 'product'
        }
        const body = {
          category: 'Checkout',
          action: TrackableEvent.CouponDenied,
          label: discountCode
        }

        trackUserActionsGA4(body, 'slerpGA4Tracking')

        // legacy tracking
        trackUserActionsFBPixel(TrackableEvent.CouponDenied, trackable)
        trackUserActionsGA4(body, 'merchantGA4Tracking')
      })
  }

  /** Remove discount and update delivery reductions */
  const checkoutRemoveDiscount = () => {
    if (!cart?.id) return new Promise<void>((resolve) => resolve())
    return removeDiscount(client, { cartId: cart.id }).then(
      ({ data }: RemoveDiscountApiResult) => {
        setAppliedDiscount(null)
        if (!isEmpty(data?.cart.deliveryChargeInfo) && data) {
          const {
            deliveryCharge,
            deliveryChargeBeforeDiscount,
            deliveryChargeReduction,
            deliveryChargeReductionReason,
            deliveryPricingBand
          } = data.cart.deliveryChargeInfo
          setDeliveryReduction({
            deliveryCharge: Number(deliveryCharge),
            deliveryChargeBeforeDiscount: Number(deliveryChargeBeforeDiscount),
            deliveryChargeReduction: Number(deliveryChargeReduction),
            deliveryChargeReductionReason,
            deliveryPricingBand
          })
          const trackable = {
            content_name: cart.discount_code || '',
            content_type: 'checkout'
          }
          const body = {
            category: 'Checkout',
            action: TrackableEvent.CouponRemoved,
            label: cart.discount_code || ''
          }

          trackUserActionsGA4(body, 'slerpGA4Tracking')

          // legacy tracking
          trackUserActionsFBPixel(TrackableEvent.CouponRemoved, trackable)
          trackUserActionsGA4(body, 'merchantGA4Tracking')
        }
      }
    )
  }

  return {
    appliedDiscount,
    setAppliedDiscount,
    deliveryCharge,
    setDeliveryCharge,
    isDeliveryLoading,
    setIsDeliveryLoading,
    refresh,
    isModalOpen,
    isValidationFailed,
    cartValidation,
    invalidVariantIds,
    deliveryReduction,
    setDeliveryReduction,
    checkoutApplyDiscount,
    checkoutRemoveDiscount
  }
}
