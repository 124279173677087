import React from 'react'
import styled from '@emotion/styled'
import { CustomerAddress } from './types'
import { MUTATE_DELETE_ADDRESS } from 'shop/client/queries/CustomerQueries'
import { useShop } from 'shop/hooks'
import { customerClient } from './utils'

interface Props {
  index: number
  openForm: (arg0: CustomerAddress, arg1: string) => void
  address: CustomerAddress
  toggleLoading: (arg0: boolean) => void
  requeryAddresses: () => void
}

const AddressActions = ({
  index,
  openForm,
  address,
  toggleLoading,
  requeryAddresses
}: Props) => {
  const { customerApiKey, merchant } = useShop()
  const client = customerClient(customerApiKey, merchant?.id || '')

  const handleDelete = () => {
    const confirmationMessage = window.confirm(
      'Are you sure you want to delete this address?'
    )

    if (confirmationMessage) {
      toggleLoading(true)
      client
        .mutate({
          mutation: MUTATE_DELETE_ADDRESS,
          variables: { addressId: address.id }
        })
        .then(() => requeryAddresses())
    }
  }

  return (
    <>
      <Link
        data-testid={`editLink-${index}`}
        onClick={() => openForm(address, 'edit')}
      >
        Edit
      </Link>
      <Link data-testid={`deleteLink-${index}`} onClick={() => handleDelete()}>
        Delete
      </Link>
    </>
  )
}

const Link = styled.a(({ theme }: any) => ({
  fontSize: theme.fontSizes[0],
  color: theme.colors.textMute,
  marginRight: '12px',
  '&:hover': {
    cursor: 'pointer'
  }
}))

export default AddressActions
