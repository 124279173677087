import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import React from 'react'

export enum IconPosition {
  Left = 'left',
  Right = 'right',
  Both = 'both'
}

type Props = {
  onClick?: () => void
  textFallback?: string
  textContent?: string
  hasSelected: boolean
  disabled?: boolean
  Icon?: JSX.Element
  IconLeft?: JSX.Element
  IconRight?: JSX.Element
  testId?: string
  children: React.ReactNode
  isOpen: boolean
  onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void
  onClear?: (e: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => void
  canEdit?: boolean
  showEditText?: boolean
}

const Dropdown = ({
  onClick,
  textFallback,
  textContent,
  disabled = false,
  IconLeft,
  IconRight,
  testId,
  children,
  isOpen,
  onBlur,
  hasSelected,
  onClear,
  canEdit = false,
  showEditText = false
}: Props) => {
  // Conditionally enables clear text functionality
  const clearText = onClear && textContent && IconLeft && (
    <ClearText onClick={onClear} data-testid='dropdown-clear-text'>
      Clear
    </ClearText>
  )

  const editText = canEdit && showEditText && IconLeft && !IconRight && (
    <EditText> Change </EditText>
  )

  const renderInputContent = () => {
    return (
      <>
        {IconLeft}
        <InputText textContent={textContent}>
          {textContent ? textContent : textFallback}
        </InputText>
        {canEdit && IconRight}
      </>
    )
  }

  /** Determine icon position from props for styling */
  const iconPosition = () => {
    if (IconLeft && IconRight && canEdit) {
      return IconPosition.Both
    } else if (IconLeft) {
      return IconPosition.Left
    } else {
      return IconPosition.Right
    }
  }

  return (
    <DropdownContainer disabled={disabled} canEdit={canEdit} onBlur={onBlur}>
      <SelectInputContainer
        disabled={disabled}
        onClick={onClick}
        data-testid={testId}
        iconPosition={iconPosition()}
        {...{ hasSelected }}
      >
        {renderInputContent()}
        {clearText}
        {editText}
      </SelectInputContainer>
      {isOpen ? (
        <ListContainer data-testid={'dropdown-list-container'}>
          {children}
        </ListContainer>
      ) : null}
    </DropdownContainer>
  )
}

/** Sets icon styles based on icon position */
const setIconStyles = (iconPosition: IconPosition, hasSelected: boolean) => {
  if (iconPosition === IconPosition.Left) {
    return commonIconStyles(hasSelected)
  } else if (iconPosition === IconPosition.Both) {
    return {
      ...commonIconStyles(hasSelected),
      '& svg:last-of-type': {
        marginRight: 0,
        marginLeft: 'auto'
      }
    }
  } else {
    return {
      justifyContent: 'space-between'
    }
  }
}

const DropdownContainer = styled.div<
  StyledHTMLElement & { disabled: boolean; canEdit: boolean },
  Required<Theme>
>(({ disabled, canEdit }) => ({
  position: 'relative',
  opacity: disabled ? 0.3 : 1,
  pointerEvents: canEdit ? 'auto' : 'none'
}))

const ListContainer = styled.ul<StyledHTMLElement>(() => ({
  marginTop: '4px',
  position: 'absolute',
  width: '100%',
  zIndex: 101,
  borderRadius: '20px',
  overflow: 'auto',
  maxHeight: '160px'
}))

const commonIconStyles = (hasSelected: boolean) => ({
  '& svg': {
    height: '18px',
    width: '18px',
    flexShrink: 0,
    marginRight: '8px',
    color: !hasSelected ? '#757575' : 'black'
  }
})

const SelectInputContainer = styled.button<
  StyledHTMLElement & { iconPosition: IconPosition; hasSelected: boolean },
  Required<Theme>
>(({ iconPosition, hasSelected, theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '13px 12px',
  position: 'relative',
  backgroundColor: 'white',
  color: '#2c2c2c',
  borderRadius: '12px',
  border: `1px solid #8C8C8C`,

  '&:disabled': { opacity: 1 },

  '& h1': {
    fontWeight: 'normal',
    fontSize: '16px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },

  '& svg': {
    height: '18px',
    width: '18px',
    flexShrink: 0,
    marginRight: 0,
    color: 'black'
  },

  '&:focus-within': {
    border: `1px solid #096DD9`
  },

  ':hover': {
    cursor: 'pointer'
  },
  ...setIconStyles(iconPosition, hasSelected)
}))

const ClearText = styled.p<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  display: 'flex',
  margin: 0,
  paddingLeft: '14px',
  fontSize: theme.fontSizes[1],
  cursor: 'pointer',
  marginLeft: 'auto'
}))

export const EditText = styled(ClearText)()

const InputText = styled.h1<
  StyledHTMLElement & { textContent: string | undefined }
>(({ textContent }) => ({
  color: textContent ? 'black' : '#757575'
}))

export default Dropdown
