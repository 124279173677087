import { ListItemProps, Props } from './types'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { FaArrowLeft as BackArrow } from 'react-icons/fa'

export const MobileTimeSlotList = ({
  timeSlots,
  value,
  handleUpdate,
  onClick
}: Props & { onClick: () => void }) => {
  return (
    <Container>
      <FullScreenModalHeader onClick={onClick}>
        <BackArrow />
        <h1>Choose time slot</h1>
      </FullScreenModalHeader>
      <ListContainer>
        {timeSlots.map((timeslot) => (
          <TimeSlotItem
            key={timeslot.value}
            {...timeslot}
            handleUpdate={handleUpdate}
            isActive={timeslot.value === value}
          />
        ))}
      </ListContainer>
    </Container>
  )
}

const TimeSlotItem = ({
  range,
  value,
  handleUpdate,
  extended,
  isActive
}: ListItemProps) => {
  return (
    <ListItem
      onClick={() => handleUpdate(value, range, extended)}
      className={isActive ? '-active' : ''}
      data-testid={`timeslot-(${range})`}
    >
      {range}
    </ListItem>
  )
}

const Container = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({})
)

const ListContainer = styled.ul<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'grid',
    overflowY: 'auto',
    listStyleType: 'none',
    maxHeight: '80vh'
  })
)
const ListItem = styled.li<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  textAlign: 'left',
  background: 'none',
  cursor: 'pointer',
  fontSize: '14px',
  display: 'flex',
  justifyContent: 'space-between',
  border: `1px solid transparent`,
  alignItems: 'center',
  padding: '18px',
  marginRight: '16px',
  '&.-active, &:hover': {
    border: '1px solid #cecece',
    borderRadius: '12px',
    background: '#f7f7f7'
  },
  [theme.mediaQueries.viewport7]: {
    padding: '16px',
    marginRight: 0
  }
}))

const FullScreenModalHeader = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    borderBottom: `1px solid ${theme.colors['lineColor']}`,
    padding: '10px 36px 20px 36px',
    margin: '0 -36px',
    alignItems: 'center',
    gap: '15px',
    cursor: 'pointer',

    '& h1': {
      fontSize: '12px',
      textTransform: 'uppercase'
    }
  })
)
