import SlerpLogo from '../logo.svg'

export const Loading = () => {
  let Logo = SlerpLogo

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw'
      }}
    >
      <img
        data-testid='slerp-brand-logo-loader'
        src={Logo}
        style={{ maxWidth: 200 }}
        alt='Slerp'
      />
    </div>
  )
}
