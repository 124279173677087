import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { TipType } from '.'
import { ReactComponent as CloseIcon } from 'shop/assets/icons/close.svg'
import { useShop } from 'shop/hooks'

type TippingListProps = {
  tippingValues: { [key: number]: number }
  setTipAmount: (value: number) => void
  tipType: TipType
  setTipType: (type: TipType) => void
}

type TippingItemProps = {
  isSelected: boolean
  tippingPercentage: number
  tippingValue: number
  setSelectedTipItem: (value: string) => void
  setTipAmount: (value: number) => void
  setTipType: (type: TipType) => void
}

const TippingList = ({
  tippingValues,
  setTipAmount,
  tipType,
  setTipType
}: TippingListProps) => {
  const { cartSession } = useShop()
  const { cart } = cartSession
  const tippingPercentages = cart?.store.settings.tip_percentages || []
  const [selectedTip, setSelectedTipItem] = useState<String>(
    `tip-${tippingPercentages[1]}`
  )

  useEffect(() => {
    if (tipType === 'custom') {
      setSelectedTipItem('')
    } else {
      setSelectedTipItem(`tip-${tippingPercentages[1]}`)
      setTipAmount(Number(tippingValues[tippingPercentages[1]]))
    }
  }, [tipType])

  return (
    <TippingListContainer>
      {tippingPercentages.map((tp: number) => (
        <TippingItem
          key={`tip-${tp}`}
          isSelected={selectedTip === `tip-${tp}`}
          setSelectedTipItem={setSelectedTipItem}
          setTipAmount={setTipAmount}
          tippingPercentage={tp}
          tippingValue={Number(tippingValues[tp])}
          setTipType={setTipType}
        />
      ))}
    </TippingListContainer>
  )
}

const TippingItem = ({
  isSelected,
  tippingPercentage,
  tippingValue,
  setSelectedTipItem,
  setTipAmount,
  setTipType
}: TippingItemProps) => {
  const handleClick = () => {
    if (isSelected) {
      setSelectedTipItem('')
      setTipAmount(0)
      return
    }
    setTipAmount(Number(tippingValue.toFixed(2)))
    setSelectedTipItem(`tip-${tippingPercentage}`)
    setTipType('standard')
  }
  return (
    <TippingItemContainer isSelected={isSelected} onClick={handleClick}>
      {isSelected && (
        <CancelTipButton>
          <CloseIcon />
        </CancelTipButton>
      )}
      <h1>{tippingPercentage}%</h1>
      <h2>£{tippingValue.toFixed(2)}</h2>
    </TippingItemContainer>
  )
}

const TippingListContainer = styled.ul<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-evenly',
    gap: '5px'
  })
)

const TippingItemContainer = styled.li<
  StyledHTMLElement & { isSelected: boolean },
  Required<Theme>
>(({ theme, isSelected }) => ({
  display: 'flex',
  position: 'relative',
  flexGrow: 1,
  maxHeight: '80px',
  maxWidth: '80px',
  aspectRatio: '1/1',
  flexDirection: 'column',
  border: isSelected
    ? '2px solid black'
    : `1px solid ${theme.colors['lineColor']}`,
  borderRadius: '5px',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '5px',
  cursor: 'pointer',

  '& h1': {
    fontSize: '16px'
  },
  '& h2': {
    fontSize: '14px',
    fontWeight: 'lighter'
  }
}))

const CancelTipButton = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    position: 'absolute',
    top: -7,
    right: -7,
    height: '23px',
    width: '23px',
    backgroundColor: theme.colors['primary'],
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      height: '15px',
      width: '15px',
      '> path': {
        fill: '#fff'
      }
    }
  })
)

export default TippingList
