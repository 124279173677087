import Tracker from './Tracker'
export { default as useTracker } from './useTracker'
export {
  initializeMerchantGA4,
  initializeTagManager,
  initializeSlerpGA4,
  trackUserActionsGA4,
  trackGA4ProductListViewed,
  initializeGoogleConversion
} from './GAtools'
export {
  initializePixel,
  grantPixelConsent,
  revokePixelConsent,
  trackFBPixeltrackProductListViewed,
  trackUserActionsFBPixel,
  trackUserPurchaseFBPixel
} from './PixelTools'
export { usePageViewTracker } from './PageViewTracking'
export * from './types'
export default Tracker
export { default as trackAction } from './trackAction'
