import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { ProductImageURLs } from './imageUtils'
import { ProductImageLoader } from '../Loader'

interface Props {
  imageUrls: ProductImageURLs[]
  productName: string
}

const ProductImages = ({ imageUrls, productName }: Props) => {
  const [activeImage, setActiveImage] = useState(imageUrls[0])

  const thumbClickHandler = (imageUrl: ProductImageURLs) => () => {
    setActiveImage(imageUrl)
  }

  const fallbackImage = `https://placehold.co/601x400/random/random?text=${(
    productName.toUpperCase() || 'Slerp'
  ).substring(0, 1)}`

  useEffect(() => {
    if (!!imageUrls.length) {
      const activeImageWithFallback: ProductImageURLs = {
        original: imageUrls[0].original || fallbackImage,
        standard: imageUrls[0].standard || fallbackImage,
        thumb: imageUrls[0].thumb || fallbackImage
      }
      setActiveImage(activeImageWithFallback)
    }
  }, [imageUrls, fallbackImage])

  return (
    <Container data-testid='productImages' id='product-modal-images'>
      <ImageContainer>
        {activeImage ? (
          <picture>
            <source media='(max-width: 768px)' srcSet={activeImage.standard} />
            <source
              media='(min-width: 768.1px)'
              srcSet={activeImage.original}
            />
            <ActiveImage
              src={activeImage.original}
              alt={`Order ${productName} Online`}
            />
          </picture>
        ) : (
          <ProductImageLoader />
        )}
      </ImageContainer>
      {imageUrls.length > 1 && (
        <List>
          {imageUrls.map((imageUrl, index) => {
            return (
              <li key={index}>
                <ProductImage
                  src={imageUrl.thumb || fallbackImage}
                  data-testid='productImage'
                  onClick={thumbClickHandler(imageUrl)}
                  alt={`Order ${productName} Online`}
                />
              </li>
            )
          })}
        </List>
      )}
    </Container>
  )
}

const Container = styled.div(({ theme }: any) => ({
  position: 'sticky',
  top: 0,
  flex: '0 0 100%',
  height: '32vh',
  [theme.mediaQueries.viewport7]: {
    position: 'relative',
    flex: '0 0 50%',
    height: 'auto',
    maxHeight: '100%'
  },
  [theme.mediaQueries.viewport10]: {
    flex: '0 0 50%',
    height: 'auto',
    maxHeight: '100%'
  },
  [theme.mediaQueries.viewport12]: {
    flex: '0 0 60%',
    height: 'auto',
    maxHeight: '100%'
  }
}))

const ImageContainer = styled.div(({ theme }: any) => ({
  height: 'inherit',
  width: '100%',
  objectFit: 'cover',
  [theme.mediaQueries.viewport7]: {
    height: '100vh'
  },

  '& >svg': {
    width: '100%',
    height: 'inherit'
  }
}))

const ActiveImage = styled.img(() => ({
  height: '100%',
  width: '100%',
  objectFit: 'cover',
  background: 'white'
}))

const List = styled.ul(({ theme }: any) => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  bottom: 0,
  margin: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  padding: theme.space[2],
  width: '100%',
  overflowX: 'auto',

  [theme.mediaQueries.viewport7]: {
    padding: '12px',
    position: 'sticky',
    left: 0,
    right: 0,
    bottom: 0
  }
}))

const ProductImage = styled.img(({ theme }: any) => ({
  display: 'block',
  height: '48px',
  width: '48px',
  objectFit: 'cover',
  border: '2px solid white',
  borderRadius: '4px',

  [theme.mediaQueries.viewport7]: {
    height: '64px',
    width: '64px'
  },
  '&:hover': {
    cursor: 'pointer'
  }
}))

export default ProductImages
