export const formatTableName = (selectedTable: number | string | null) => {
  if (selectedTable === null) return 'No table'
  // 0 should never happen, just needed to make sure the code compiles because it kept telling me that object could be null
  const tableNumber =
    selectedTable !== null
      ? typeof selectedTable === 'string'
        ? selectedTable
        : selectedTable + 1
      : 0

  return selectedTable === 100000 ? 'Counter' : `Table ${tableNumber}`
}
