import { useState, createContext, useContext } from 'react'
import { AppContentQueryResponse } from './types'

type AppContentState = {
  merchantName: string | null
  promotionalBanner: string | null
  logoImage: string
  altLogoImage: string
  bannerImage: string
  splashImage: string
  setAppContent: (content: AppContentQueryResponse) => void
}

export const SetupAppContent = (): AppContentState => {
  const [merchantName, setMerchantName] = useState<string | null>(null)
  const [promotionalBanner, setPromotionalBanner] = useState<string | null>(
    null
  )
  const [logoImage, setLogoImage] = useState<string>('')
  const [altLogoImage, setAltLogoImage] = useState<string>('')
  const [bannerImage, setBannerImage] = useState<string>('')
  const [splashImage, setSplashImage] = useState<string>('')

  const setAppContent = (content: AppContentQueryResponse) => {
    setMerchantName(content?.merchantName || null)
    setPromotionalBanner(content?.promotionalBanner || null)
    setLogoImage(content?.altLogoImage || content?.logoImage)
    setAltLogoImage(content?.logoImage || content?.altLogoImage)
    setBannerImage(content?.shop?.header?.bannerImage)
    setSplashImage(content?.splashImage)
  }

  return {
    merchantName,
    promotionalBanner,
    logoImage,
    altLogoImage,
    bannerImage,
    splashImage,
    setAppContent
  }
}

const initialState: AppContentState = {
  merchantName: null,
  promotionalBanner: null,
  logoImage: '',
  altLogoImage: '',
  bannerImage: '',
  splashImage: '',
  setAppContent: () => {}
}

export const AppContentContext = createContext<AppContentState>(initialState)

export const useAppContent = () => {
  return useContext(AppContentContext)
}
