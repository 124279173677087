import {
  TrackableEvent,
  trackUserActionsFBPixel,
  trackUserActionsGA4,
  TrackableProduct
} from 'tracker'

export const trackViewContentCallback = (
  trackableProduct: TrackableProduct
) => {
  try {
    const body = {
      category: trackableProduct.category,
      action: TrackableEvent.ProductViewed,
      label: trackableProduct.name
    }

    trackUserActionsGA4(body, 'slerpGA4Tracking')

    // legacy tracking
    trackUserActionsFBPixel('ViewContent', {
      value: trackableProduct.price,
      ...trackableProduct
    })
    trackUserActionsGA4(body, 'merchantGA4Tracking')
  } catch (e) {
    console.warn(e)
  }
}
