import AddressAutoComplete from '../AddressAutoComplete'
import { BsChevronLeft as BackArrow } from 'react-icons/bs'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'

const AddressSelectionNew = ({
  address,
  onChange,
  onSelect,
  onClose
}: {
  address: string
  onChange: (newAddress: string) => void
  onSelect: (newAddress: string) => void
  onClose: () => void
}) => {
  return (
    <AddressContainer>
      <AddressInputWrapper>
        <IconContainer onClick={onClose}>
          <BackArrow />
        </IconContainer>
        <AddressAutoComplete
          withIcon={true}
          isEditing={true}
          value={address}
          onChange={onChange}
          newFulfillmentModal={true}
          onSelect={onSelect}
        />
      </AddressInputWrapper>
    </AddressContainer>
  )
}

const AddressInputWrapper = styled.div<StyledHTMLElement>(() => ({
  display: 'flex',
  alignItems: 'center'
}))

const AddressContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    padding: '12px 12px 0',
    [theme.mediaQueries.viewport5]: {
      padding: '28px 28px 0'
    }
  })
)

const IconContainer = styled.div<StyledHTMLElement>(() => ({
  marginRight: '8px',
  cursor: 'pointer',
  padding: '4px',
  '> svg': {
    width: '14px'
  }
}))

export default AddressSelectionNew
