import styled from '@emotion/styled'
import { HTMLAttributes } from 'react'
import Theme from 'shop/theme/types'
import TabBar, { TabBarProps } from '../../TabBar'

// TODO: Remove TabBar from Header once ProductInfoV2 is standard.
interface HeaderProps {
  name: string
  parentUrl: string
}
interface Props extends TabBarProps, HeaderProps {}

type StyledHTMLElement = React.DetailedHTMLProps<
  HTMLAttributes<HTMLElement>,
  HTMLElement
>

const Header: React.FC<Props> = ({ ...props }) => {
  const { name, tabs } = props
  return (
    <>
      <Container id='product-modal-header'>
        <Name>{name}</Name>
        {tabs?.length && <TabBar {...props} />}
      </Container>
    </>
  )
}

export const HeaderV2: React.FC<HeaderProps> = ({ ...props }) => {
  const { name } = props
  return (
    <>
      <Container id='product-modal-header' paddingBottom='8px'>
        <Name>{name}</Name>
      </Container>
    </>
  )
}

const Name = styled.h1<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  fontSize: theme.fontSizes[3],
  fontFamily: theme.fonts['heading']['family'],
  color: theme.colors['textBold'],
  flex: '1 0 80%',
  padding: `${theme.space[4]}px ${theme.space[7]}px 0 0`,
  [theme.mediaQueries.viewport7]: {
    fontSize: theme.fontSizes[5]
  }
}))

const Container = styled.div<
  StyledHTMLElement & { paddingBottom?: string },
  Required<Theme>
>(({ theme, paddingBottom }) => ({
  top: 0,
  paddingTop: '8px',
  paddingBottom: paddingBottom,
  zIndex: 5,
  backgroundColor: 'white',
  position: 'sticky'
}))

export default Header
