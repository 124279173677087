import gql from 'graphql-tag'

const AddressFragment = `
  id
  line_1
  line_2
  city
  country
  zip
  contact_num
  default_shipping_address
  default_billing_address
  flat_number
  label
`

export const QUERY_CUSTOMER = gql`
  query GetCustomer($customerId: uuid!) {
    customers_by_pk(id: $customerId) {
      first_name
      last_name
      email
      merchant_id
      contact_num
      birthdate
      birthday_update_count
      marketing_opt_in
      orders(limit: 5, order_by: { inserted_at: desc }) {
        fulfillment_type
        transaction_id
        inserted_at
        total
        status
        cart_id
        store_id
        metadata
        cart {
          address
        }
        store {
          slug
        }
      }
      merchant_settings: merchant {
        slug
      }
      addresses {
        ${AddressFragment}
      }
    }
  }
`

export const QUERY_CUSTOMER_ADDRESSES = gql`
  query GetCustomerAddresses($customerId: uuid!) {
    addresses(where: { customer_id: { _eq: $customerId } }) {
      ${AddressFragment}
    }
  }
`

// add default flag later
export const QUERY_CUSTOMER_DEFAULT_ADDRESS = gql`
  query GetCustomerAddress($customerId: uuid!) {
    addresses(
      where: {
        customer_id: { _eq: $customerId }
        default_shipping_address: { _eq: true }
      }
    ) {
      line_1
      line_2
      city
      country
      zip
      contact_num
    }
  }
`

// Consumer API login mutation
export const MUTATE_LOGIN = gql`
  mutation Login($email: EmailAddress!, $password: String!) {
    login(email: $email, password: $password) {
      customer {
        id
        firstName
        lastName
        email
        apiKey
      }
      errors {
        message
      }
      warnings {
        message
      }
    }
  }
`

// Consumer API logout mutation
export const MUTATE_LOGOUT = gql`
  mutation Logout {
    logout
  }
`

// Consumer API Create Customer mutation
export const MUTATE_CREATE_CUSTOMER = gql`
  mutation CreateCustomer(
    $firstName: String!
    $lastName: String!
    $email: EmailAddress!
    $password: String!
    $contactNum: PhoneNumber!
    $marketingOptIn: Boolean!
  ) {
    createCustomer(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      contactNumber: $contactNum
      marketingOptIn: $marketingOptIn
    ) {
      customer {
        id
        firstName
        lastName
        email
        apiKey
      }
      errors {
        message
      }
      warnings {
        message
      }
    }
  }
`

/** Consumer API request password reset mutation */
export const MUTATE_REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordReset($email: EmailAddress!) {
    requestPasswordReset(email: $email)
  }
`
/**  Consumer API update password mutation */
export const MUTATE_UPDATE_PASSWORD = gql`
  mutation UpdatePassword(
    $password: String!
    $passwordConfirmation: String!
    $token: UUID!
  ) {
    updatePassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
      token: $token
    ) {
      customer {
        apiKey
        email
        firstName
        id
        lastName
      }
      warnings {
        message
      }
      errors {
        message
      }
    }
  }
`

export const MUTATE_EDIT_CUSTOMER = gql`
  mutation EditCustomer(
    $customerId: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $merchantId: ID!
    $currentPassword: String
    $password: String
    $passwordConfirmation: String
    $contactNum: String
    $birthdate: Date
    $marketingOptIn: Boolean
  ) {
    editCustomer(
      customerId: $customerId
      firstName: $firstName
      lastName: $lastName
      email: $email
      merchantId: $merchantId
      currentPassword: $currentPassword
      password: $password
      passwordConfirmation: $passwordConfirmation
      contactNum: $contactNum
      birthdate: $birthdate
      marketingOptIn: $marketingOptIn
    ) {
      id
      first_name
      last_name
      email
      contact_num
      birthdate
      birthday_update_count
      marketing_opt_in
    }
  }
`

export const MUTATE_CREATE_ADDRESS = gql`
  mutation CreateAddress(
    $customerId: ID!
    $flatNumber: String
    $line1: String
    $line2: String!
    $zip: String!
    $country: String!
    $city: String!
    $defaultBillingAddress: Boolean
    $defaultShippingAddress: Boolean
    $label: String
  ) {
    createAddress(
      customer_id: $customerId
      flatNumber: $flatNumber
      line_1: $line1
      line_2: $line2
      zip: $zip
      country: $country
      city: $city
      defaultBillingAddress: $defaultBillingAddress
      defaultShippingAddress: $defaultShippingAddress
      label: $label
    ) {
      id
    }
  }
`

export const MUTATE_EDIT_ADDRESS = gql`
  mutation EditAddress(
    $id: ID!
    $flatNumber: String
    $line1: String
    $line2: String!
    $zip: String!
    $country: String!
    $city: String!
    $label: String
  ) {
    editAddress(
      addressId: $id
      flatNumber: $flatNumber
      line_1: $line1
      line_2: $line2
      zip: $zip
      country: $country
      city: $city
      label: $label
    ) {
      id
    }
  }
`

export const MUTATE_FALSIFY_DEFAULT_SHIPPING_ADDRESS_FLAG = gql`
  mutation SetDefaultAddressFlags($customerId: uuid!) {
    old_shipping_addresses: update_addresses(
      where: {
        customer_id: { _eq: $customerId }
        default_shipping_address: { _eq: true }
      }
      _set: { default_shipping_address: false }
    ) {
      returning {
        id
      }
    }
  }
`

export const MUTATE_FALSIFY_AND_SET_DEFAULT_SHIPPING_ADDRESS_FLAGS = gql`
  mutation SetDefaultAddressFlags($customerId: uuid!, $addressId: uuid!) {
    old_shipping_addresses: update_addresses(
      where: {
        customer_id: { _eq: $customerId }
        default_shipping_address: { _eq: true }
      }
      _set: { default_shipping_address: false }
    ) {
      returning {
        id
      }
    }
    new_shipping_address: update_addresses(
      where: { id: { _eq: $addressId } }
      _set: { default_shipping_address: true }
    ) {
      returning {
        id
      }
    }
  }
`

export const MUTATE_FALSIFY_DEFAULT_BILLING_ADDRESS_FLAG = gql`
  mutation SetDefaultAddressFlags($customerId: uuid!) {
    old_billing_addresses: update_addresses(
      where: {
        customer_id: { _eq: $customerId }
        default_billing_address: { _eq: true }
      }
      _set: { default_billing_address: false }
    ) {
      returning {
        id
      }
    }
  }
`

export const MUTATE_FALSIFY_AND_SET_DEFAULT_BILLING_ADDRESS_FLAGS = gql`
  mutation SetDefaultAddressFlags($customerId: uuid!, $addressId: uuid!) {
    old_billing_address: update_addresses(
      where: {
        customer_id: { _eq: $customerId }
        default_billing_address: { _eq: true }
      }
      _set: { default_billing_address: false }
    ) {
      returning {
        id
      }
    }
    new_billing_address: update_addresses(
      where: { id: { _eq: $addressId } }
      _set: { default_billing_address: true }
    ) {
      returning {
        id
      }
    }
  }
`

export const MUTATE_DELETE_ADDRESS = gql`
  mutation DeleteAddress($addressId: uuid!) {
    delete_addresses_by_pk(id: $addressId) {
      id
    }
  }
`
