import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import CloseButton from './CloseButton'
import Backdrop from './Backdrop'
import useEscModalHandler from 'shop/hooks/useEscModalHandler'

interface Props {
  children: React.ReactNode
  handleCloseModal?: () => void
  size?: string
}

const SideModal = ({ children, handleCloseModal, size }: Props) => {
  useEscModalHandler({ onClose: handleCloseModal })

  return (
    <ModalContainer data-testid='sideModal'>
      <Dialog>
        <CloseButton
          variant={'sideModal'}
          handleCloseModal={handleCloseModal}
        />
        {children}
      </Dialog>
      <Backdrop onClick={handleCloseModal} />
    </ModalContainer>
  )
}

const ModalContainer = styled.div(({ theme }: any) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 30,
  display: 'flex',
  [theme.mediaQueries.viewport7]: {
    top: theme.components.navbar.height
  }
}))

const slideRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(560px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
`

const slideUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(360px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const Dialog = styled.div(({ theme }: any) => ({
  overflowY: 'auto',
  backgroundColor: 'white',
  height: '100%',
  maxWidth: '576px',
  width: '100%',
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  top: 0,
  right: 0,
  zIndex: theme.zIndex.genericModal,
  fontFamily: theme.fonts.normal,
  padding: '30px 24px 0px 16px',
  animation: `${slideUp} 200ms ease-out`,
  '> h1': {
    marginBottom: '36px'
  },
  [theme.mediaQueries.viewport5]: {
    padding: '24px 24px 0px'
  },
  [theme.mediaQueries.viewport7]: {
    animation: `${slideRight} 200ms ease-out`,
    padding: '30px 35px 0px'
  }
}))

export default SideModal
