import { useState } from 'react'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import Store from './Store'
import { StoreSelectorProps } from './types'
import FullscreenModal from 'shop/components/Modal/FullscreenModal'
import { FaAngleUp as UpArrow, FaArrowLeft as BackArrow } from 'react-icons/fa'
import { BiEditAlt as EditIcon } from 'react-icons/bi'
import SelectInput from 'shop/components/Inputs/SelectInput'
import { useMediaQueries } from 'shop/hooks'
import CenterModal from 'shop/components/Modal/CenterModal'

export { Store }

export const StoreSelector = ({
  validStores,
  setCurrentStore,
  currentStore,
  inModal
}: StoreSelectorProps) => {
  const [isStoreSelectorOpen, setIsStoreSelectorOpen] = useState<boolean>(false)
  const { isMobile } = useMediaQueries()

  const content = (
    <>
      <FullScreenModalHeader onClick={() => setIsStoreSelectorOpen(false)}>
        <BackArrow />
        <h1>Choose a store</h1>
      </FullScreenModalHeader>
      <StoreList>
        {validStores?.map((store) => (
          <Store
            key={`store-${store.id}`}
            id={store.id}
            name={store.name}
            handleClick={() => {
              setCurrentStore(store)
              setTimeout(() => setIsStoreSelectorOpen(false), 200)
            }}
            isActive={currentStore?.id === store.id}
          />
        ))}
      </StoreList>
    </>
  )
  return (
    <>
      <SelectInput
        testId='change-store-select-input'
        textContent={currentStore && currentStore.name}
        textFallback={'Select a store'}
        onClick={() => setIsStoreSelectorOpen(true)}
        hasSelected={!!currentStore}
        ToSelectIcon={UpArrow}
        HasSelectedIcon={EditIcon}
        disabled={validStores.length === 1}
      />
      {isStoreSelectorOpen &&
        (inModal && !isMobile ? (
          <CenterModal
            handleCloseModal={() => setIsStoreSelectorOpen(false)}
            styles={{ minHeight: '50%', overflowY: 'hidden' }}
            inModal={inModal}
          >
            <CenterMain>{content}</CenterMain>
          </CenterModal>
        ) : (
          <FullscreenModal
            onClose={() => setIsStoreSelectorOpen(false)}
            isLandingColumn={!isMobile}
          >
            <FullscreenMain>{content}</FullscreenMain>
          </FullscreenModal>
        ))}
    </>
  )
}

export const CenterMain = styled.main(() => ({
  padding: '24px 36px'
}))

export const FullscreenMain = styled.main(() => ({
  padding: '0 36px'
}))

export const StoreList = styled.ul<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    margin: `${theme?.space[4]}px 0 ${theme?.space[6]}px`,
    maxHeight: '80vh',
    overflowY: 'auto',
    display: 'grid',
    gridGap: '8px'
  })
)

const FullScreenModalHeader = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    borderBottom: `1px solid ${theme.colors['lineColor']}`,
    padding: '10px 36px 20px 36px',
    margin: '0 -36px',
    alignItems: 'center',
    gap: '15px',
    cursor: 'pointer',

    '& h1': {
      fontSize: '12px',
      textTransform: 'uppercase'
    }
  })
)
