import ReactDOM from 'react-dom'
import './index.css'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import { Loading } from './router'

const App = Loadable({
  loader: () => import('./App'),
  loading: () => <Loading />
})

ReactDOM.render(
  <Router basename='/order'>
    <Route path='/' component={App} />
  </Router>,
  document.getElementById('root')
)
