import { TrackerConfig, TrackerLogLevel } from './types'

class Tracker {
  private static log(
    message: string,
    level: TrackerLogLevel = TrackerLogLevel.INFO
  ) {
    Tracker.getInstance().loggingEnabled &&
      console[level](`[Tracker][${level}]: ${message}`)
  }

  static debug(message: string) {
    Tracker.log(message, TrackerLogLevel.DEBUG)
  }

  static info(message: string) {
    Tracker.log(message, TrackerLogLevel.DEBUG)
  }

  static warn(message: string) {
    Tracker.log(message, TrackerLogLevel.WARNING)
  }

  static error(message: string) {
    Tracker.log(message, TrackerLogLevel.ERROR)
  }

  /** Deprecated Segment Tracking */
  static track(...args: any[]): boolean {
    // @ts-ignore
    const analytics = window.analytics

    if (analytics && args.length > 0) {
      const api = args[0]
      args.shift()

      if (
        Tracker.getInstance().trackingEnabled &&
        analytics[api] &&
        typeof api === 'string' &&
        typeof analytics[api] === 'function'
      ) {
        analytics[api](...args)
        return true
      }

      Tracker.warn(
        'Unsupported Segment API `' +
          api +
          '` See https://segment.com/docs/connections/spec/ for supported APIs.'
      )
    }

    return false
  }

  /** Deprecated Segment Tracking */
  static initialize(config: TrackerConfig) {
    if (config.apiKey === null || config.apiKey === undefined) return
    if (config.trackingEnabled !== true) return

    Tracker.getInstance().setTrackingEnabled(true)
    Tracker.getInstance().setLoggingEnabled(config.loggingEnabled)

    // NOTE: This is pretty much a copy-paste of Segment's tracking script
    // @ts-ignore
    // prettier-ignore
    var analytics = window.analytics = window.analytics || []

    if (!analytics.initialize) {
      if (analytics.invoked)
        window.console &&
          console.error &&
          console.error('Segment snippet included twice.')
      else {
        analytics.invoked = !0
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on',
          'addSourceMiddleware',
          'addIntegrationMiddleware',
          'setAnonymousId',
          'addDestinationMiddleware'
        ]

        // @ts-ignore
        analytics.factory = function (e) {
          return function () {
            var t = Array.prototype.slice.call(arguments)
            t.unshift(e)
            analytics.push(t)
            return analytics
          }
        }

        for (var e = 0; e < analytics.methods.length; e++) {
          var key = analytics.methods[e]
          analytics[key] = analytics.factory(key)
        }

        // @ts-ignore
        analytics.load = function (key, e) {
          var t = document.createElement('script')
          t.type = 'text/javascript'
          t.async = !0
          t.src =
            'https://cdn.segment.com/analytics.js/v1/' +
            key +
            '/analytics.min.js'

          var n = document.getElementsByTagName('script')[0]
          // @ts-ignore
          n.parentNode.insertBefore(t, n)
          analytics._loadOptions = e
        }

        analytics.SNIPPET_VERSION = '4.13.1'
        analytics.load(config.apiKey)
      }
    }
  }

  static getInstance(): Tracker {
    if (!Tracker.instance) {
      Tracker.instance = new Tracker()
    }

    return Tracker.instance
  }

  private loggingEnabled: boolean

  trackingEnabled: boolean
  slerpGA4Enabled: boolean
  merchantGA4Enabled: boolean
  fbPixelEnabled: boolean
  googleConversionId: string | null

  private static instance: Tracker

  private constructor() {
    this.loggingEnabled = false
    this.trackingEnabled = false
    this.slerpGA4Enabled = false
    this.merchantGA4Enabled = false
    this.fbPixelEnabled = false
    this.googleConversionId = null
  }

  setLoggingEnabled(enabled: boolean) {
    this.loggingEnabled = enabled
  }

  setTrackingEnabled(enabled: boolean) {
    this.trackingEnabled = enabled
  }

  setSlerpGA4Enabled(enabled: boolean) {
    this.slerpGA4Enabled = enabled
  }

  setMerchantGA4Enabled(enabled: boolean) {
    this.merchantGA4Enabled = enabled
  }

  setFbPixelEnabled(enabled: boolean) {
    this.fbPixelEnabled = enabled
  }

  setGoogleConversionId(id: string) {
    this.googleConversionId = id
  }
}

export default Tracker
