import styled from '@emotion/styled'
import LogoImage from '../../../logo.svg'
interface FooterProps {
  hasFooter: boolean
}
const Footer = ({ hasFooter }: FooterProps) => {
  return (
    <Container {...{ hasFooter }}>
      <Logo src={LogoImage} />
      Powered by <a href='https://slerp.com'> Slerp </a>.
    </Container>
  )
}

const Container = styled.footer(({ theme, hasFooter }: any) => ({
  maxWidth: theme.components.footer.width,
  backgroundColor: 'none',
  fontSize: theme.fontSizes[0],
  padding: '8px 16px',
  color: theme.colors.black,
  height: theme.components.footer.height,
  fontWeight: theme.fontWeights.light,
  display: hasFooter ? 'flex' : 'none',
  alignItems: 'center',
  zIndex: 50,
  position: 'fixed',
  bottom: '0',
  left: '0',
  right: '0',
  a: {
    color: theme.colors.primary,
    marginLeft: '4px'
  }
}))

const Logo = styled.img({
  height: '32px',
  marginRight: '8px'
})

export default Footer
