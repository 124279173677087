import LandingNew from 'shop/pages/LandingNew'
import CenterModal from 'shop/components/Modal/CenterModal'
import theme from 'shop/theme/defaultTheme'
import { useEffect, useState } from 'react'
import { AddToCartParams } from '../Landing'
import { trackGA4CustomFulfillmentModalOpened } from 'tracker/GA/custom'

export interface FulfillmentFormModalProps {
  onClose: () => void
  addToCart?: (args: AddToCartParams) => void
  cartId?: string
  storeId?: string
  isDineIn?: boolean
  orderAgainCartId?: string
  inModal?: boolean
}

const FulfillmentFormModalNew = ({
  onClose = () => {},
  addToCart,
  cartId = '',
  storeId = '',
  isDineIn = false,
  orderAgainCartId = '',
  inModal = false
}: FulfillmentFormModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState(true)

  useEffect(() => {
    trackGA4CustomFulfillmentModalOpened('slerpGA4Tracker')
    trackGA4CustomFulfillmentModalOpened('merchantGA4Tracker')
  }, [])

  return (
    <div data-testid='fulfillmentFormModal'>
      <CenterModal
        handleCloseModal={onClose}
        canClose={isDineIn}
        zIndex={theme?.zIndex?.landingFormModal}
        inModal={inModal}
        newFulfillmentModal={true}
        isOpen={isModalOpen}
      >
        <LandingNew
          {...{
            addToCart,
            modal: true,
            orderAgainCartId,
            cartId,
            storeId,
            setIsModalOpen,
            onClose
          }}
        />
      </CenterModal>
    </div>
  )
}

export default FulfillmentFormModalNew
