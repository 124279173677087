import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { useConsumerCart } from './useConsumerCart'
import {
  CartMutationResponse,
  DiscountWarning,
  InvalidTimeslotWarning
} from 'shop/types/cart'
import { useAppContent, useModal } from './useGlobalContext'
import { trackAddShippingInfoConsumerCart } from 'shop/components/Checkout/tracking/helpers'
import {
  findErrorWarning,
  isDiscountWarningCode,
  isTimeSlotError
} from 'shop/components'

interface CheckoutV2State {
  giftWrapped: boolean
  setGiftWrapped: (value: boolean) => void
  giftWrapMessage: string | null
  setGiftWrapMessage: (message: string | null) => void
  trackAddShippingInfo: () => void
  updateCartGiftWrapping: (message: string | null) => void
  timeSlotValidation: InvalidTimeslotWarning | null
  discountWarningValidations: DiscountWarning[]
}

const CheckoutContextV2 = createContext<CheckoutV2State>({
  giftWrapped: false,
  setGiftWrapped: () => null,
  giftWrapMessage: null,
  setGiftWrapMessage: () => null,
  trackAddShippingInfo: () => null,
  updateCartGiftWrapping: () => null,
  timeSlotValidation: null,
  discountWarningValidations: []
})

export const CheckoutContextV2Provider: React.FC = ({ children }) => {
  const {
    cart: consumerCart,
    updateConsumerCart,
    setCart,
    warnings: consumerCartWarnings
  } = useConsumerCart()
  const { merchantName } = useAppContent()
  const { openModal } = useModal()
  const storeName = consumerCart?.store?.name

  const [giftWrapped, setGiftWrapped] = useState<boolean>(false)
  const [giftWrapMessage, setGiftWrapMessage] = useState<string | null>(null)
  const [timeSlotValidation, setTimeSlotValidation] =
    useState<InvalidTimeslotWarning | null>(null)
  const [discountWarningValidations, setDiscountWarningValidation] = useState<
    DiscountWarning[]
  >([])

  const getGiftWrapState = () => {
    const { additionalItems } = consumerCart || {}
    const { giftWrapping } = additionalItems || {}
    const message = giftWrapping?.message || null
    return {
      giftWrapped: !!message,
      giftWrapMessage: message
    }
  }

  /** Gets & sets gift wrap state values on consumer cart load */
  useEffect(() => {
    const { giftWrapped, giftWrapMessage } = getGiftWrapState()
    setGiftWrapped(giftWrapped)
    setGiftWrapMessage(giftWrapMessage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerCart?.id])

  /** Updates consumer cart on gift wrapping change & sets cart to show cost changes */
  const updateCartGiftWrapping = (message: string | null) => {
    // If gift wrapping is disabled, remove the message from the cart
    return updateConsumerCart({
      giftWrapMessage: message ? message : null
    }).then((cartResponse: CartMutationResponse | null) => {
      const { cart } = cartResponse || {}
      if (cart) {
        setCart(cart)
      }
    })
  }

  const trackAddShippingInfo = useCallback(() => {
    if (consumerCart && merchantName && storeName) {
      trackAddShippingInfoConsumerCart(consumerCart, merchantName, storeName)
    }
  }, [consumerCart, merchantName, storeName])

  /** Handles consumer cart timeslot warning */
  useEffect(() => {
    if (!consumerCartWarnings.length) return

    const timeslotWarning = findErrorWarning(
      consumerCartWarnings,
      isTimeSlotError
    ) as InvalidTimeslotWarning

    if (timeslotWarning) {
      setTimeSlotValidation(timeslotWarning)
      openModal('timeslotV2')
    } else setTimeSlotValidation(null)

    const discountWarnings = consumerCartWarnings.filter((warning) =>
      findErrorWarning([warning], isDiscountWarningCode)
    ) as DiscountWarning[]

    if (discountWarnings.length) {
      setDiscountWarningValidation(discountWarnings)
    } else setDiscountWarningValidation([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerCartWarnings])

  return (
    <CheckoutContextV2.Provider
      value={{
        giftWrapped,
        setGiftWrapped,
        giftWrapMessage,
        setGiftWrapMessage,
        trackAddShippingInfo,
        updateCartGiftWrapping,
        timeSlotValidation,
        discountWarningValidations
      }}
    >
      {children}
    </CheckoutContextV2.Provider>
  )
}

export const useCheckoutV2 = () => useContext(CheckoutContextV2)
