/** Coverts any hex, rgb or rgba color to their counterpart with a set opacity
 * @param color Any css color - hex, hex shorthand, hex with opacity, rgb, rgba
 * @param opacity Opacity percentage as number from 0 - 100 e.g. 0% - 100%
 */
export const cssSetColorOpacity = (color: string, opacity: number): string => {
  if (opacity >= 100 || opacity < 0) return color
  const formattedColor = color.replace(/ /g, '') // remove all white space

  // handle hex
  if (formattedColor.charAt(0) === '#') {
    // e.g. Aqua #0ff -> #00ffff
    if (formattedColor.length === 4) {
      const [hash, red, green, blue] = formattedColor
      return `${hash}${red}${red}${green}${green}${blue}${blue}${opacity}`
    }
    // e.g. already has opacity values #00ffff20
    if (formattedColor.length > 7) {
      const trimmedHexColor = formattedColor.slice(0, 7)
      return `${trimmedHexColor}${opacity}`
    }
    return `${formattedColor}${opacity}`
  }

  // handle rgba and rgb
  if (formattedColor.startsWith('rgb')) {
    const colorValues = formattedColor.replace(/[\+\(\)\-'rgba']/g, '')
    const [red, green, blue] = colorValues.split(',')
    return `rgba(${red},${green},${blue},${(opacity / 100).toFixed(2)})`
  }

  return formattedColor
}
