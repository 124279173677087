import styled from '@emotion/styled'
import { Clear, ellipsis } from '../commonStyles'
import { CartWarningMessage, Discount, DiscountWarning } from 'shop/types/cart'
import { useCheckoutV2, useConsumerCart } from 'shop/hooks'
import { StyledHTMLElement } from 'shop/theme/types'
import { formatMoney } from 'shop/components/Cart/utils'
import { IoInformationCircleSharp as InfoIcon } from 'react-icons/io5'
import { useState } from 'react'
import { trackAddDiscountCodeConsumerCart } from 'shop/components/Checkout/tracking/helpers'

interface Props {
  discount: Discount
  isEditable: boolean
}

interface WarningMessageProps {
  text: string
}

/** Renders soft validation in discount UI */
const WarningMessage: React.FC<WarningMessageProps> = ({ text }) => (
  <div>
    <StyledCheckmark />
    <DiscountText>{text}</DiscountText>
  </div>
)

const DiscountBreakdownItem = ({
  discount: { code, description },
  isEditable = false
}: Props) => {
  const [isTagExpanded, setIsTagExpanded] = useState(false)
  const { removeDiscountConsumerCart } = useConsumerCart()
  const { discountWarningValidations } = useCheckoutV2()
  const onDiscountRemoveClick = () => {
    removeDiscountConsumerCart()
    // Track discount code when removed
    trackAddDiscountCodeConsumerCart(code)
  }

  const hasValidationWarnings = !!discountWarningValidations?.length

  const generateMessages = (warning: DiscountWarning) => {
    const messages = []
    switch (warning.message) {
      case CartWarningMessage.MINIMUM_VALUE:
        messages.push(
          `Minimum spend ${formatMoney(parseFloat(warning.minimumValue))}`
        )
        break
      case CartWarningMessage.CUSTOMER_LOGIN_REQUIRED:
        messages.push('Log in required')
        break
      case CartWarningMessage.NO_TARGET_CATEGORIES_IN_CART:
        messages.push('Add a product from an eligible category to your basket')
        break
      case CartWarningMessage.NO_TARGET_PRODUCTS_IN_CART:
        messages.push('Add eligible product(s) to your basket')
        break
      case CartWarningMessage.NO_TARGET_VARIANTS_IN_CART:
        messages.push('Add an eligible product variant to your basket')
        break
      case CartWarningMessage.DISCOUNT_DELIVERY_ONLY:
        messages.push('Switch to Delivery')
        break
      case CartWarningMessage.DISCOUNT_NOT_APPLICABLE_TO_STORE:
        messages.push('Choose valid store')
        break
      default:
        break
    }
    return messages
  }

  return (
    <>
      <Container>
        <DiscountContent
          hasValidationWarnings={!!hasValidationWarnings}
          data-testid='discount'
        >
          <DiscountInfo
            onClick={() => setIsTagExpanded(!isTagExpanded)}
            hasValidationWarnings={!!hasValidationWarnings}
          >
            <DiscountCode>{code}</DiscountCode>
            {!!description && (
              <DiscountText isTagExpanded={isTagExpanded}>
                {description}
              </DiscountText>
            )}
            {isEditable && (
              <Clear
                top='7px'
                data-testid='remove-discount-button'
                onClick={onDiscountRemoveClick}
              />
            )}
          </DiscountInfo>
          {!!hasValidationWarnings && (
            <SoftValidationInfo>
              <DiscountText fontColor={'black'} fontWeight={500}>
                You need to meet the following requirements to use this
                discount.
              </DiscountText>
              <ValidationWarningsContent>
                {discountWarningValidations.map((warning) =>
                  generateMessages(warning).map((text, index) => (
                    <WarningMessage
                      key={`${warning.message}-${index}`}
                      text={text}
                    />
                  ))
                )}
              </ValidationWarningsContent>
            </SoftValidationInfo>
          )}
        </DiscountContent>
      </Container>
    </>
  )
}

const Container = styled.div(() => ({
  width: '100%'
}))

const DiscountContent = styled.div<
  StyledHTMLElement & { hasValidationWarnings?: boolean }
>(({ hasValidationWarnings }) => ({
  position: 'relative',
  fontSize: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  borderRadius: '12px',
  backgroundColor: hasValidationWarnings ? '#D46B081A' : '#389E0D1A',
  border: hasValidationWarnings ? '1px solid transparent' : '1px solid #389E0D'
}))

const DiscountInfo = styled.div<
  StyledHTMLElement & { hasValidationWarnings?: boolean }
>(({ hasValidationWarnings }) => ({
  whiteSpace: 'pre',
  display: 'flex',
  alignItems: 'center',
  padding: '7px 12px'
}))

const SoftValidationInfo = styled.div(() => ({
  padding: '0 12px 7px'
}))

const ValidationWarningsContent = styled.div(() => ({
  '> div': {
    display: 'flex',
    alignItems: 'center',
    padding: '2px 0 0'
  }
}))

const DiscountCode = styled.p(() => ({
  fontWeight: 500,
  ...ellipsis,
  margin: 0,
  flexShrink: 0,
  marginRight: '6px',
  lineHeight: '22px'
}))

const StyledCheckmark = styled(InfoIcon)(() => ({
  color: '#D46B08',
  margin: '1px 4px 0 0',
  flexShrink: 0,
  alignSelf: 'flex-start'
}))

const DiscountText = styled.p<
  StyledHTMLElement & {
    fontWeight?: number
    fontColor?: string
    padding?: string
    isTagExpanded?: boolean
  }
>(({ fontWeight = 400, fontColor = '#59595A', padding, isTagExpanded }) => ({
  color: fontColor,
  fontWeight: fontWeight,
  padding: padding || 'initial',
  fontSize: '14px',
  lineHeight: '22px',
  ...(isTagExpanded ? { textWrap: 'wrap' } : ellipsis),
  margin: 0,
  cursor: 'pointer'
}))

export default DiscountBreakdownItem
