import gql from 'graphql-tag'

const DeliveryChargeFragment = `
  deliveryCharge
  deliveryChargeBeforeDiscount
  deliveryChargeReduction
  deliveryChargeReductionReason
  deliveryPricingBand {
    lowerThreshold
    upperThreshold
    percentageDiscount
  }
`

const UPDATE_CART = gql`
  mutation updateCart(
    $customer_details: jsonb!
    $recipient_details: jsonb
    $order_notes: String
    $dropoff_notes: String
    $cart_id: uuid!
    $gift_wrapped: Boolean
    $gift_wrap_message: String
    $details_disclosed: Boolean
    $custom_field_value: String
    $custom_field_name: String
  ) {
    update_carts_by_pk(
      pk_columns: { id: $cart_id }
      _set: {
        order_notes: $order_notes
        dropoff_notes: $dropoff_notes
        customer_details: $customer_details
        recipient_details: $recipient_details
        gift_wrapped: $gift_wrapped
        gift_wrap_message: $gift_wrap_message
        details_disclosed: $details_disclosed
        custom_field_value: $custom_field_value
        custom_field_name: $custom_field_name
      }
    ) {
      id
      customer_details
      order_notes
      gift_wrapped
      gift_wrap_message
      details_disclosed
      custom_field_value
      custom_field_name
    }
  }
`

const GET_PAY_NEXT_STEP = gql`
  mutation payForCart($cartId: ID!) {
    payForCart(cartId: $cartId) {
      nextStep
      transactionId
    }
  }
`

const INTEND_PAYMENT_FOR_CART = gql`
  mutation payForCart(
    $cartId: ID!
    $savePaymentMethod: Boolean
    $paymentMethodId: ID
  ) {
    payForCart(
      cartId: $cartId
      savePaymentMethod: $savePaymentMethod
      paymentMethodId: $paymentMethodId
    ) {
      clientSecret
      nextStep
      connectAccountId
      transactionId
    }
  }
`

const APPLY_DISCOUNT = gql`
  mutation applyDiscount($cartId: ID!, $discountCode: String!) {
    discount: applyDiscount(cartId: $cartId, discountCode: $discountCode) {
      discountCode
      totalDiscount
      discountId
      value
      target
      trigger
      type
      subtotalInfo {
        reduction
      }
      deliveryChargeInfo {
        ${DeliveryChargeFragment}
      }
      storeFeeInfo {
        basePrice
        discountedPrice
        reduction
      }
    }
  }
`

const REMOVE_DISCOUNT = gql`
  mutation removeDiscount($cartId: ID!) {
    cart: removeDiscount(cartId: $cartId) {
      id
      deliveryChargeInfo {
        ${DeliveryChargeFragment}
      }
    }
  }
`

const APPLY_AUTOMATIC_DISCOUNT = gql`
  mutation applyAutomaticDiscount($cartId: ID!) {
    discount: applyAutomaticDiscount(cartId: $cartId) {
      discountCode
      totalDiscount
      discountId
      value
      target
      trigger
      type
      subtotalInfo {
        reduction
      }
      storeFeeInfo {
        basePrice
        discountedPrice
        reduction
      }
    }
  }
`

const FETCH_QUOTE_AND_SAVE_ADDRESS = gql`
  mutation fetchQuoteAndUpdateCart(
    $cartId: ID!
    $deliveryAddress: DeliveryAddressParams
  ) {
    quoteAndUpdateAddress(cartId: $cartId, deliveryAddress: $deliveryAddress) {
      id
      delivery_charge
      minimum_order_value
      deliveryChargeInfo {
        ${DeliveryChargeFragment}
      }
    }
  }
`

const INIT_SPLIT_BILL = gql`
  mutation Pay($cartId: ID!, $payors: [PayorParams]) {
    payNextgen(cartId: $cartId, payors: $payors) {
      url
      id
      contactNum
      email
    }
  }
`

const SPLIT_PAYMENT_LINKS = gql`
  query getCartPaymentLinks($cartId: uuid!) {
    payments(
      where: { cart_id: { _eq: $cartId }, status: { _nin: ["expired"] } }
    ) {
      customer_details
      amount
      status
      internal_reference
      id
      provider_link
    }
    orders(where: { cart_id: { _eq: $cartId } }) {
      transaction_id
    }
  }
`

const QUERY_GET_ESTIMATED_FULFILLMENT_WINDOW = gql`
  query GetEstimatedFulfillmentWindow($cartId: uuid!) {
    getEstimatedFulfillmentWindow(cartId: $cartId) {
      range
      formattedDatetime
    }
  }
`

export {
  UPDATE_CART,
  GET_PAY_NEXT_STEP,
  APPLY_DISCOUNT,
  REMOVE_DISCOUNT,
  APPLY_AUTOMATIC_DISCOUNT,
  FETCH_QUOTE_AND_SAVE_ADDRESS,
  INIT_SPLIT_BILL,
  SPLIT_PAYMENT_LINKS,
  INTEND_PAYMENT_FOR_CART,
  QUERY_GET_ESTIMATED_FULFILLMENT_WINDOW
}
