import { RedeemableReward } from '../types'
import styled from '@emotion/styled'
import { HTMLAttributes } from 'react'
import Theme from 'shop/theme/types'
import Button from 'shop/components/Controls/Button'
import Spinner from 'shop/components/Loader/Spinner'

interface ClaimingSectionProps {
  customerRewards: RedeemableReward[]
  claimReward: (claimableRewardId: string) => void
  merchantSlug: string
}

interface RewardCardProps {
  customerReward: RedeemableReward
  claimReward: (claimableRewardId: string) => void
  merchantSlug: string
}

type StyledHTMLElement = React.DetailedHTMLProps<
  HTMLAttributes<HTMLElement>,
  HTMLElement
>

const CenteredLoader = styled.div(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

export const ClaimingSectionLoader = () => (
  <CenteredLoader>
    <Spinner size={'64px'} />
  </CenteredLoader>
)

const RewardCard = ({
  customerReward,
  claimReward,
  merchantSlug
}: RewardCardProps) => {
  const imageUrl = `https://${merchantSlug}.${process.env.REACT_APP_ASSET_HOST}/assets/loyalty_card/${customerReward.reward.loyalty_card.id}?size=thumb`

  return (
    <CardContainer data-testid={customerReward.id}>
      <RewardCardInfo>
        <RewardCardPhoto src={imageUrl} alt={customerReward.reward.name} />
        <RewardDescriptors>
          <span> {customerReward.reward.name} </span>
          <span> {customerReward.reward.loyalty_card.name} </span>
        </RewardDescriptors>
      </RewardCardInfo>
      <RedeemButton
        onClick={() => claimReward(customerReward.id)}
        width='120px'
        size='sm'
        data-testid={`${customerReward.id}-redeem-button`}
      >
        Redeem card
      </RedeemButton>
    </CardContainer>
  )
}

const ClaimingSection = ({
  customerRewards,
  claimReward,
  merchantSlug
}: ClaimingSectionProps) => {
  return (
    <>
      {customerRewards.map((customerReward) => {
        return (
          <RewardCard
            {...{ customerReward, claimReward, merchantSlug }}
            key={customerReward.id}
          />
        )
      })}
    </>
  )
}

const RewardDescriptors = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    '> span:first-of-type': {
      fontSize: theme.fontSizes[1],
      textTransform: 'uppercase',
      // weird type issue when using direct prop
      fontWeight: theme.fontWeights['bold']
    },
    '> span:last-of-type': {
      fontSize: theme.fontSizes[0],
      fontWeight: theme.fontWeights['light']
    },
    height: '72px',
    padding: '12px',
    justifyContent: 'center'
  })
)

const RewardCardPhoto = styled.img(() => ({
  backgroundColor: 'lightgray',
  width: '72px',
  height: '72px'
}))

const CardContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '8px'
}))

const RewardCardInfo = styled.div(() => ({
  display: 'flex'
}))

const RedeemButton = styled(Button)(({ theme }: any) => ({
  fontSize: theme.fontSizes[0],
  padding: '8px 4px'
}))

export default ClaimingSection
