import { FauxCartItem } from '../Cart/CartItem'
import { formatMoney } from 'shop/components/Cart/utils'
import { Payor } from 'shop/pages/Order'
import { Heading } from 'shop/components'
import CollapsibleList from 'shop/components/Checkout/CollapsibleList'
import { IoPersonOutline } from 'react-icons/io5'
import { DetailCard } from '../Order'
import styled from '@emotion/styled'

interface PayorListProps {
  payments: Payor[]
  currentPayment?: Payor
}

const PayorText = styled.span(() => ({
  fontSize: '14px'
}))

const Row = styled.div(() => ({
  padding: '0 0 16px',
  borderBottom: '1px solid rgba(221, 221, 221, 0.4)',
  '&:last-of-type': {
    borderBottom: 0,
    paddingBottom: '0'
  }
}))

const PayorList = ({ payments, currentPayment }: PayorListProps) => (
  <>
    {payments
      .sort((a, b) => (a === currentPayment ? -1 : 1))
      .map((payment: Payor, index: number) => (
        <Row key={`payor-${payment.name}-${index}`}>
          <FauxCartItem
            label={
              <DetailCard
                icon={<IoPersonOutline />}
                text={
                  <PayorText>
                    {payment.internal_reference ===
                    currentPayment?.internal_reference
                      ? 'You'
                      : payment.name}
                  </PayorText>
                }
                size='small'
              />
            }
            value={<PayorText> {formatMoney(payment.amount)} </PayorText>}
          />
        </Row>
      ))}
  </>
)

const CollapsiblePayorList = ({ payments, currentPayment }: PayorListProps) => {
  if (payments.length === 0) return <> </>
  return (
    <div>
      <Heading as='h4' margin='0 0 16px'>
        Split summary
      </Heading>
      <CollapsibleList
        showLabel={`Split among (${payments.length})`}
        hideLabel={`Split among (${payments.length})`}
        hasBottomBorder={false}
        lastPadding={'0'}
      >
        <PayorList payments={payments} currentPayment={currentPayment} />
      </CollapsibleList>
    </div>
  )
}

export default CollapsiblePayorList
