import { useCallback, useEffect, useState } from 'react'
import {
  fadeInOutMs,
  trackPostCodeChange,
  trackStoreChanged
} from 'shop/components/Landing/utils'
import { useMediaQueries } from '../useMediaQueries'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { CurrentLocationState, ValidStore } from 'shop/components/Landing/types'
import { useUTMTracker } from 'shop/components/Landing/trackingUtils'
import { useModal } from '../useGlobalContext'

interface useDeliveryProps {
  setCurrentLocation: (address?: CurrentLocationState | undefined) => void
  currentLocation: CurrentLocationState | undefined
  setCurrentStore: (store: ValidStore | undefined) => void
  validStores: ValidStore[]
  address: string
  setAddress: (address: string) => void
  selectedOrderDay: string
  setSelectedOrderDay: (value: string) => void
  setIsEditMode: (value: boolean) => void
  setFulfillmentTime: (value: string | null) => void
  setFulfillmentTimeRange: (value: string | null) => void
  isOverlayOpen: boolean
  setIsOverlayOpen: (value: boolean) => void
}

export const useDelivery = ({
  setCurrentLocation,
  currentLocation,
  setCurrentStore,
  validStores,
  address,
  setAddress,
  selectedOrderDay,
  setSelectedOrderDay,
  setIsEditMode,
  setFulfillmentTime,
  setFulfillmentTimeRange,
  isOverlayOpen,
  setIsOverlayOpen
}: useDeliveryProps) => {
  const { isMobile } = useMediaQueries()
  const { tracker } = useUTMTracker()

  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [isOrderDayDropdownOpen, setIsOrderDayDropdownOpen] =
    useState<boolean>(false)
  const [isStoreListDropdownEnabled, setIsStoreListDropdownEnabled] =
    useState<boolean>(false)
  const [isStoreListDropdownOpen, setIsStoreListDropdownOpen] =
    useState<boolean>(false)

  const [isAddressSelectionOpen, setIsAddressSelectionOpen] = useState(false)

  const [isTimeSlotDropdownOpen, setIsTimeSlotDropdownOpen] =
    useState<boolean>(false)

  const [showInvalidTimeslotModal, setShowInvalidTimeslotModal] =
    useState(false)

  const { isModalOpen } = useModal()
  const [hideMobileModal, setHideMobileModal] = useState<boolean>(false)

  useEffect(() => {
    isModalOpen('login') ? setHideMobileModal(true) : setHideMobileModal(false)
  }, [isModalOpen])

  /** List of store display name values for UI */
  const validStoresList: string[] = validStores.map(
    (validStore) => validStore.name
  )

  const orderDayDropdownHandler = useCallback(() => {
    !isOrderDayDropdownOpen ? setIsOverlayOpen(true) : setIsOverlayOpen(false)
    setIsOverlayOpen(true)
    setTimeout(() => {
      setIsOrderDayDropdownOpen(!isOrderDayDropdownOpen)
    }, fadeInOutMs)
  }, [isOrderDayDropdownOpen])

  const storeListDropdownHandler = useCallback(() => {
    !isStoreListDropdownOpen ? setIsOverlayOpen(true) : setIsOverlayOpen(false)
    setIsStoreListDropdownOpen(!isStoreListDropdownOpen)
  }, [isStoreListDropdownOpen])

  const calendarInputClickHandler = useCallback(() => {
    !isCalendarOpen ? setIsOverlayOpen(true) : setIsOverlayOpen(false)
    setIsCalendarOpen(!isCalendarOpen)
  }, [isCalendarOpen])

  const handleAddressSelectionModal = useCallback(() => {
    !isAddressSelectionOpen ? setIsOverlayOpen(true) : setIsOverlayOpen(false)

    /** Added check to prevent setTimeout on mobile as it prevents iOS Safari from autofocusing
         address overlay input which is needed to show keyboard on mobile when address search is focussed */
    !isMobile
      ? setTimeout(() => {
          setIsAddressSelectionOpen(!isAddressSelectionOpen)
        }, fadeInOutMs)
      : setIsAddressSelectionOpen(!isAddressSelectionOpen)
  }, [
    setIsOverlayOpen,
    setIsAddressSelectionOpen,
    isAddressSelectionOpen,
    isMobile
  ])

  const onClickOutsideDatepicker = (): void => {
    setIsOverlayOpen(false)
    setTimeout(() => {
      setIsCalendarOpen(false)
    }, fadeInOutMs)
  }

  const handleClickOutsideOrderDayDropdown = useCallback(() => {
    if (isOrderDayDropdownOpen) {
      setIsOverlayOpen(false)
      setTimeout(() => {
        setIsOrderDayDropdownOpen(false)
      }, fadeInOutMs)
    }
  }, [isOrderDayDropdownOpen])

  const handleClickOutsideTimeSlotDropdown = () => {
    setIsOverlayOpen(false)
    setTimeout(() => {
      setIsTimeSlotDropdownOpen(false)
    }, fadeInOutMs)
  }

  const handleClickOutsideStoreListDropdown = useCallback(() => {
    setIsOverlayOpen(false)
    if (isStoreListDropdownOpen) {
      setTimeout(() => {
        setIsStoreListDropdownOpen(false)
      }, fadeInOutMs)
    }
  }, [isStoreListDropdownOpen])

  const defaultAddress: string = currentLocation
    ? currentLocation.address
    : tracker && tracker.address
      ? (tracker.address as string)
      : ''

  // Sets default address on page load
  useEffect(() => {
    if (!address) setAddress(defaultAddress)
  }, [])

  /** On user click processes and sets address */
  const handleLocationSelect = useCallback(
    (newAddress: string): void => {
      setIsEditMode(false)
      geocodeByAddress(newAddress)
        .then(async (results) => {
          const latLng = await getLatLng(results[0])
          const formattedAddress = results[0].formatted_address
          const addressComponents = results[0].address_components
          setAddress(formattedAddress)
          setCurrentLocation({
            address: formattedAddress,
            addressComponents,
            latLng
          })
          setIsOverlayOpen(false)
          setTimeout(() => {
            setIsAddressSelectionOpen(false)
          }, fadeInOutMs)
          trackPostCodeChange('modal')
        })
        .catch((error) => console.error('Error', error))
    },
    [setCurrentLocation]
  )

  const onClickTimeSlotDropdownHandler = useCallback(() => {
    !isTimeSlotDropdownOpen ? setIsOverlayOpen(true) : setIsOverlayOpen(false)
    setIsTimeSlotDropdownOpen(!isTimeSlotDropdownOpen)
  }, [isTimeSlotDropdownOpen])

  /** On user click clears address from state as well as derived store & location */
  const clearAddressHandler = (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>
  ): void => {
    // effectively resetting the form
    e.stopPropagation()
    setAddress('')
    setCurrentStore(undefined)
    setCurrentLocation(undefined)
    setIsEditMode(false)
    setFulfillmentTime(null)
    setFulfillmentTimeRange(null)
    setSelectedOrderDay('now')
    setIsStoreListDropdownEnabled(false)
  }

  const setCurrentStoreHandler = (value: string): void => {
    const newStore = validStores.find((validStore) => validStore.name === value)
    if (newStore) {
      newStore && setCurrentStore(newStore)
      trackStoreChanged(newStore, 'modal')
    }
    setIsOverlayOpen(false)
    setTimeout(() => {
      setIsStoreListDropdownOpen(false)
    }, fadeInOutMs)
  }

  return {
    orderDayDropdownHandler,
    storeListDropdownHandler,
    calendarInputClickHandler,
    isOrderDayDropdownOpen,
    isStoreListDropdownOpen,
    isCalendarOpen,
    isOverlayOpen,
    setIsOverlayOpen,
    isStoreListDropdownEnabled,
    setIsStoreListDropdownEnabled,
    setIsCalendarOpen,
    setIsAddressSelectionOpen,
    handleAddressSelectionModal,
    isAddressSelectionOpen,
    selectedOrderDay,
    setSelectedOrderDay,
    setIsOrderDayDropdownOpen,
    setIsStoreListDropdownOpen,
    onClickOutsideDatepicker,
    handleClickOutsideOrderDayDropdown,
    handleClickOutsideTimeSlotDropdown,
    isTimeSlotDropdownOpen,
    setIsTimeSlotDropdownOpen,
    handleClickOutsideStoreListDropdown,
    setShowInvalidTimeslotModal,
    showInvalidTimeslotModal,
    setAddress,
    address,
    handleLocationSelect,
    onClickTimeSlotDropdownHandler,
    clearAddressHandler,
    setCurrentStoreHandler,
    validStoresList,
    hideMobileModal
  }
}
