import gql from 'graphql-tag'

// Use strings rather than fragment to prevent apollo-client heuristic issue.
// They work the same way seems like
// Old: fragment LoadCartFragment on carts {}
// New: ${CART_FRAGMENT}
const CART_FRAGMENT = `
  id
  address
  customer_details
  recipient_details
  delivery_address
  deliver_by
  delivery_distance
  delivery_eta
  delivery_charge
  details_disclosed
  discount_id
  inserted_at
  merchant_id
  order_coordinates
  fulfillment_type
  fulfillment_time_range
  pickup_store_id
  discount_code: discounts_cache(path: "code")
  discount_amount: discounts_cache(path: "total_discount")
  discount_target: discounts_cache(path: "target")
  discount_value: discounts_cache(path: "value")
  discount_type: discounts_cache(path: "type")
  discount_trigger: discounts_cache(path: "trigger")
  discount_breakdown: discounts_cache(path: "discount_breakdown")
  custom_field_name
  custom_field_value
  pickup_store: storeByPickupStoreId {
    name
    settings
    address {
      line_2
      city
      zip
    }
  }
  cart_items: order_items {
    id
    is_valid
    product_variant_id
    order_id
    quantity
    vat
    variant_price
    variant_vat
    amount
    applied_modifiers {
      id
      amount
      quantity
      modifier_id
      modifier {
        id
        name
        restrictions
      }
      modifier_group_id
      modifier_group {
        id
        name
      }
    }
    product_variant {
      restrictions
      options
      product {
        id
        name
        category {
          id
          name
        }
        product_variants {
          id
          name
        }
      }
    }
  }
  pickup_date
  postcode
  store_id
  store {
    name
    slug
    delivery_interval: settings(path: "delivery_interval")
    pickup_interval: settings(path: "pickup_interval")    
    settings
    store_variants {
      variant_id
      in_stock
      published_at
    }
    sameday_delivery_minimum_order_value
    preorder_delivery_minimum_order_value
    sameday_pickup_minimum_order_value
    preorder_pickup_minimum_order_value
  }
  updated_at
  is_preorder
  metadata
  merchant {
    name
    slug
    id
    order_note_placeholder: setting(path: "order_note_placeholder")
    gift_wrap_text: setting(path: "gift_wrap_text")
    gift_wrap_placeholder: setting(path: "gift_wrap_placeholder")
    gift_wrap_price: setting(path: "gift_wrap_price")
    gift_wrap_enabled: setting(path: "gift_wrap_enabled")
    delivery_note_placeholder: setting(path: "delivery_note_placeholder")
    order_custom_field: setting(path: "order_custom_field")
    order_custom_field_name: setting(path: "order_custom_field_name")
    order_custom_field_placeholder: setting(path: "order_custom_field_placeholder")
    order_custom_field_mandatory: setting(path: "order_custom_field_mandatory")
    order_custom_field_apply_for: setting(path: "order_custom_field_apply_for")
    custom_checkout_message: setting(path: "custom_checkout_message")
    custom_checkout_icon_code: setting(path: "custom_checkout_icon_code")
  }
  additional_store_fee_cache
  order_notes
  dropoff_notes
  gift_wrapped
  gift_wrap_price
  gift_wrap_message
  subtotal
  total
  tip_value
`

const QUERY_GET_CART = gql`
  query getCartById($id: uuid!) {
    cart: carts_by_pk(id: $id) {
      ${CART_FRAGMENT}
    }
  }

`

const QUERY_GET_CART_TIPPING_VALUES = gql`
  query getTipValues($id: ID!) {
    tipsValues: getTipValues(cartId: $id) {
      id
      tips
    }
  }
`

const MUTATE_UPDATE_TIP_VALUE = gql`
  mutation updateCart($id: uuid!, $tip: numeric!) {
    update_carts(where: { id: { _eq: $id } }, _set: { tip_value: $tip }) {
      returning {
        id
        tip_value
      }
    }
  }
`

const MUTATE_CREATE_SLERP_CART = gql`
  mutation CreateSlerpCart(
    $address: String
    $customerId: ID
    $deliveryAddress: DeliveryAddressParams
    $discountCode: String
    $fulfillmentDate: Date
    $fulfillmentTime: String
    $fulfillmentType: String!
    $merchantId: ID!
    $metadata: Json
    $orderItems: [OrderItemParams]
    $platformType: String
    $platformVendor: String
    $storeId: ID!
  ) {
    createSlerpCart(
      address: $address
      customerId: $customerId
      deliveryAddress: $deliveryAddress
      discountCode: $discountCode
      fulfillmentDate: $fulfillmentDate
      fulfillmentTime: $fulfillmentTime
      fulfillmentType: $fulfillmentType
      merchantId: $merchantId
      metadata: $metadata
      orderItems: $orderItems
      platformType: $platformType
      platformVendor: $platformVendor
      storeId: $storeId
    ) {
      id
      __typename
    }
  }
`

const MUTATE_UPDATE_CART_ITEM = gql`
  mutation updateCart(
    $cartId: uuid!
    $amount: numeric!
    $variantId: uuid!
    $quantity: Int!
    $id: uuid!
    $vat: numeric!
    $variantPrice: numeric!
    $variantVat: numeric
  ) {
    update_order_items(
      where: { id: { _eq: $id }, product_variant_id: { _eq: $variantId } }
      _set: {
        amount: $amount
        quantity: $quantity
        vat: $vat
        updated_at: "NOW()"
      }
    ) {
      returning {
        cart {
          ${CART_FRAGMENT}
        }
      }
    }
  }
`

const MUTATE_ADD_TO_CART = gql`
  mutation addToCart(
    $cartId: uuid!
    $amount: numeric
    $variantId: uuid!
    $quantity: Int
    $id: uuid!
    $vat: numeric!
    $variantVat: numeric
    $variantPrice: numeric
    $appliedModifiers: applied_modifiers_arr_rel_insert_input
  ) {
    insert_order_items(
      objects: {
        id: $id
        cart_id: $cartId
        amount: $amount
        product_variant_id: $variantId
        quantity: $quantity
        inserted_at: "NOW()"
        updated_at: "NOW()"
        vat: $vat
        variant_price: $variantPrice
        variant_vat: $variantVat
        applied_modifiers: $appliedModifiers
      }
    ) {
      returning {
        cart {
          ${CART_FRAGMENT}
        }
      }
    }
  }
`

/**
 * Elixir AddToCart Mutation
 */
const MUTATE_ADD_TO_CARTV2 = gql`
  mutation AddToCart(
    $cartId: uuid!
    $quantity: Int!
    $variantId: uuid!
    $modifiers: [AppliedModifiersParams!]
  ) {
    addToCart(
      cartId: $cartId
      quantity: $quantity
      variantId: $variantId
      modifiers: $modifiers
    ) {
      id
      orderItems {
        id
        image
        modifierGroups {
          name
          modifiers {
            price {
              basePrice
            }
            name
            quantity
          }
        }
        name
        total {
          basePrice
        }
        quantity
        variantId
        variantPrice {
          basePrice
        }
      }
      subtotal
    }
  }
`

const VALIDATE_CART = gql`
  query ValidateCart($cartId: ID!, $increaseCutoffMinutes: Int) {
    validateCart(
      cartId: $cartId
      increaseCutoffMinutes: $increaseCutoffMinutes
    ) {
      __typename
      nextAvailable {
        datetime
        range
        value
      }
      valid
      isStoreOpen
      unavailableVariantIds
      discountWarningsNew {
        validationType
        ... on MinimumValueDiscountWarning {
          minimumValue
        }
        __typename
      }
    }
  }
`

const MUTATE_UPDATE_CART_TIMESLOT = gql`
  mutation UpdateCartTimeslot(
    $cartId: uuid!
    $deliverBy: timestamp
    $fulfillmentTimeRange: String
  ) {
    update_carts_by_pk(
      pk_columns: { id: $cartId }
      _set: {
        deliver_by: $deliverBy
        fulfillment_time_range: $fulfillmentTimeRange
      }
    ) {
      id
      deliver_by
      fulfillment_time_range
    }
  }
`

const QUERY_MINIMUM_ORDER_VALUE = gql`
  query getMinimumOrderValue($cartId: ID!) {
    getMinimumOrderValue(cartId: $cartId) {
      minimumOrderValue
    }
  }
`

const MUTATE_UPDATE_CART_ITEM_QUANTITY = gql`
  mutation UpdateOrderItemQuantity($id: uuid!, $quantity: Int!) {
    updateOrderItemQuantity(id: $id, quantity: $quantity) {
      id
      quantity
      price_with_vat
    }
  }
`

const MUTATE_DELETE_CART_ITEM = gql`
  mutation DeleteOrderItem($id: uuid!) {
    deleteOrderItem(id: $id) {
      id
    }
  }
`

const MUTATE_UPDATE_CUSTOMER_ID = gql`
  mutation UpdateSlerpCart($cartId: ID!, $customerId: ID!) {
    updateSlerpCart(cartId: $cartId, customerId: $customerId) {
      id
    }
  }
`

/** :( used to refresh the cart of any direct-to-hasura mutations */
const MUTATE_REFRESH_CART = gql`
  mutation UpdateSlerpCart($cartId: ID!) {
    updateSlerpCart(cartId: $cartId) {
      status
    }
  }
`

const DeliveryChargeFragment = `
  deliveryCharge
  deliveryChargeBeforeDiscount
  deliveryChargeReduction
  deliveryChargeReductionReason
  deliveryPricingBand {
    lowerThreshold
    upperThreshold
    percentageDiscount
  }
`

const MUTATE_UPDATE_CART_GIFT_WRAP = gql`
  mutation UpdateSlerpCart(
    $cartId: ID!
    $giftWrapped: Boolean
    $giftWrapMessage: String
  ) {
    updateSlerpCart(
      cartId: $cartId
      giftWrapped: $giftWrapped
      giftWrapMessage: $giftWrapMessage
    ) {
      status
      deliveryChargeInfo {
        ${DeliveryChargeFragment}
      }
    }
  }
`

const QUERY_GET_DELIVERY_CHARGE_INFO = gql`
  query GetSlerpCart($cartId: uuid!) {
    getSlerpCart(cartId: $cartId) {
      deliveryChargeInfo {
        ${DeliveryChargeFragment}
      }
    }
  }
`

const MUTATE_UPDATE_SLERP_CART = gql`
  mutation UpdateSlerpCart(
    $cartId: ID!
    $customerId: ID
    $deliveryAddress: DeliveryAddressParams
    $fulfillmentDate: Date
    $fulfillmentTime: String
    $fulfillmentType: String!
    $orderItems: [OrderItemParams]
    $proceedWithNewPrices: Boolean
    $storeId: uuid
  ) {
    updateSlerpCart(
      cartId: $cartId
      customerId: $customerId
      deliveryAddress: $deliveryAddress
      fulfillmentDate: $fulfillmentDate
      fulfillmentTime: $fulfillmentTime
      fulfillmentType: $fulfillmentType
      orderItems: $orderItems
      proceedWithNewPrices: $proceedWithNewPrices
      storeId: $storeId
    ) {
      id
      __typename
    }
  }
`

const CONSUMER_CART_FRAGMENT = `
id 
additionalItems {
  giftWrapping {
    message
    price {
      base
    }
  }
}
customField {
  name
  value
}
customerDetails {
  contactNumber
  email
  firstName
  lastName
}
deliveryAddress {
  city
  country
  flatNumber
  coordinates {
    lat
    lng
  }
  lineOne
  lineTwo
  zip
}
dropoffNotes
fulfillment {
  type
  orderType
  tableNumber
  window {
    from
    to
  }
}
loyaltyCards {
    description
    id
    name
    status
    availableRewards {
      id
      isApplicable
      details {
          description
          name
      }
      status
    }
}
marketingOptIn
minimumOrderValue
orderItems {
  id
  image {
    original
    standard
    thumb
  }
  modifierGroups {
    name
    modifiers {
      name
      price {
        base
        discounted
        reduction
      }
      quantity
    }
  }
  name
  options {
    name
    values
  }
  product {
    id
    name
  }
  quantity
  restrictions {
    alcoholic
  }
  total {
    base
    discounted
    reduction
  }
  variantId
  variantPrice {
    base
    discounted
    reduction
  }
}
orderNotes
recipientDetails {
  contactNumber
  firstName
  lastName
}
store {
  address {
    city
    country
    lineOne
    lineTwo
    zip
  }
  name
  slug
}
summary {
  delivery {
    base
    discounted
    reduction
  }
  deliveryChargeInfo {
    reductionReason
    pricingBand {
      id
      lowerThreshold
      percentageDiscount
      upperThreshold
    }
  }
  discount {
      amount
      code
      description
  }
  storeFee {
    price { 
      base
      discounted
      reduction
    }
  }
  subtotal {
    base
    discounted
    reduction
  }
  tip
  total {
    base
    discounted
    reduction
  }
}`

const CONSUMER_CART_ERRORS_FRAGMENT = `
__typename
message
... on GenericCartError {
  message
}
... on PriceChangedError {
  message
  priceComparison {
      newPriceWithVat
      oldPriceWithVat
      orderItemId
  }
}
`

const CONSUMER_CART_WARNINGS_FRAGMENT = `
__typename
message
... on GenericCartWarning {
  message
}
... on InvalidTimeslotWarning {
message
isStoreOpen
nextAvailableTimeslot {
  datetime
  range
  value
}
}
... on MinimumValueWarning {
    message
    minimumValue
}
... on TargetCategoriesDiscountWarning {
    message
    categories {
        id
        name
    }
}
... on TargetProductsDiscountWarning {
    message
    products {
        id
        name
    }
}
... on TargetVariantsDiscountWarning {
    message
    variants {
        id
        name
    }
}
`

const MUTATE_CREATE_CART_CONSUMER = gql`
  mutation createCart(
    $customerDetails: CustomerDetailsParams
    $deliveryAddress: DeliveryAddressParams
    $fulfillmentDate: Date!
    $fulfillmentTime: HourMinute
    $fulfillmentType: FulfillmentType!
    $storeId: UUID!
    $tableNumber: String
  ) {
    createCart(
      customerDetails: $customerDetails
      deliveryAddress: $deliveryAddress
      fulfillmentDate: $fulfillmentDate
      fulfillmentTime: $fulfillmentTime
      fulfillmentType: $fulfillmentType
      storeId: $storeId
      tableNumber: $tableNumber
    ) {
      cart {
        id
        fulfillment {
          type
          orderType
          tableNumber
        }
      }
      errors {
        ${CONSUMER_CART_ERRORS_FRAGMENT}
      }
      warnings {
        ${CONSUMER_CART_WARNINGS_FRAGMENT}
      }

    }
  }
`

/** Consumer API get cart query */
const QUERY_GET_CART_CONSUMER = gql`
  query cart {
    cart {
      ${CONSUMER_CART_FRAGMENT}
    }
  }
`

/** Consume API switch cart mutation through createCart */
const MUTATE_SWITCH_CART_STORE_CONSUMER = gql`
  mutation createCart($storeId: UUID!) {
    createCart(storeId: $storeId) {
      cart {
        id
        fulfillment {
          type
          orderType
          tableNumber
        }
      }
      errors {
        ${CONSUMER_CART_ERRORS_FRAGMENT}
      }
      warnings {
        ${CONSUMER_CART_WARNINGS_FRAGMENT}
      }
    }
  }
`

/** Consumer API update cart mutation */
const MUTATE_UPDATE_CART_CONSUMER = gql`
  mutation updateCart(
        $customerDetails: CustomerDetailsParams
        $dropoffNotes: String
        $customFieldValue: String
        $giftWrapMessage: String
        $marketingOptIn: Boolean
        $orderNotes: String 
        $tipValue: Decimal
        $recipientDetails: RecipientDetailsParams
    ) {
    updateCart(
        customerDetails: $customerDetails
        dropoffNotes: $dropoffNotes
        customFieldValue: $customFieldValue
        giftWrapMessage: $giftWrapMessage
        marketingOptIn: $marketingOptIn
        orderNotes: $orderNotes
        tipValue: $tipValue
        recipientDetails: $recipientDetails
    ) {
      cart {
        ${CONSUMER_CART_FRAGMENT}
      }
      errors {
        ${CONSUMER_CART_ERRORS_FRAGMENT}
      }
      warnings {
        ${CONSUMER_CART_WARNINGS_FRAGMENT}
      }
    }
  }
`

/** Consumer API update cart fulfillment mutation */
const MUTATE_UPDATE_FULFILLMENT_CART_CONSUMER = gql`
  mutation updateCartFulfillment(
        $deliveryAddress: DeliveryAddressParams
        $fulfillmentDate: Date
        $fulfillmentTime: HourMinute
        $fulfillmentType: FulfillmentType
        $proceedWithNewPrices: Boolean
        $storeId: UUID
        $tableNumber: String 
    ) {
    updateCartFulfillment(
        deliveryAddress: $deliveryAddress
        fulfillmentDate: $fulfillmentDate
        fulfillmentTime: $fulfillmentTime
        fulfillmentType: $fulfillmentType
        proceedWithNewPrices: $proceedWithNewPrices
        storeId: $storeId
        tableNumber: $tableNumber
    ) {
      cart {
        ${CONSUMER_CART_FRAGMENT}
      }
      errors {
        ${CONSUMER_CART_ERRORS_FRAGMENT}
      }
      warnings {
        ${CONSUMER_CART_WARNINGS_FRAGMENT}
      }
    }
  }
`

const MUTATE_APPLY_DISCOUNT_CART_CONSUMER = gql`
  mutation applyDiscount(
        $discountCode: String!
    ) {
    applyDiscount(
        discountCode: $discountCode
    ) {
      cart {
        ${CONSUMER_CART_FRAGMENT}
      }
      errors {
        ${CONSUMER_CART_ERRORS_FRAGMENT}
      }
      warnings {
        ${CONSUMER_CART_WARNINGS_FRAGMENT}
      }
    }
  }
`

const MUTATE_REMOVE_DISCOUNT_CART_CONSUMER = gql`
  mutation removeDiscount {
    removeDiscount {
      cart {
        ${CONSUMER_CART_FRAGMENT}
      }
      errors {
        ${CONSUMER_CART_ERRORS_FRAGMENT}
      }
      warnings {
        ${CONSUMER_CART_WARNINGS_FRAGMENT}
      }
    }
  }
`

const MUTATE_APPLY_REWARD_CART_CONSUMER = gql`
  mutation applyReward(
        $rewardId: UUID!
    ) {
    applyReward(
        rewardId: $rewardId
    ) {
      cart {
        ${CONSUMER_CART_FRAGMENT}
      }
      errors {
        ${CONSUMER_CART_ERRORS_FRAGMENT}
      }
      warnings {
        ${CONSUMER_CART_WARNINGS_FRAGMENT}
      }
    }
  }
`

const QUERY_VALIDATE_CART_CONSUMER = gql`
  query validateCart {
    validateCart {
      errors {
        ${CONSUMER_CART_ERRORS_FRAGMENT}
      }
      warnings {
        ${CONSUMER_CART_WARNINGS_FRAGMENT}
      }
    }
  }
`

export {
  MUTATE_CREATE_SLERP_CART,
  MUTATE_UPDATE_SLERP_CART,
  MUTATE_ADD_TO_CART,
  MUTATE_ADD_TO_CARTV2,
  MUTATE_DELETE_CART_ITEM,
  MUTATE_UPDATE_CART_ITEM,
  MUTATE_UPDATE_CART_TIMESLOT,
  MUTATE_UPDATE_CART_ITEM_QUANTITY,
  MUTATE_UPDATE_TIP_VALUE,
  QUERY_GET_CART_TIPPING_VALUES,
  QUERY_GET_CART,
  QUERY_MINIMUM_ORDER_VALUE,
  VALIDATE_CART,
  MUTATE_REFRESH_CART,
  QUERY_GET_DELIVERY_CHARGE_INFO,
  MUTATE_UPDATE_CART_GIFT_WRAP,
  MUTATE_CREATE_CART_CONSUMER,
  MUTATE_UPDATE_CART_CONSUMER,
  MUTATE_UPDATE_FULFILLMENT_CART_CONSUMER,
  MUTATE_UPDATE_CUSTOMER_ID,
  QUERY_GET_CART_CONSUMER,
  MUTATE_APPLY_DISCOUNT_CART_CONSUMER,
  MUTATE_REMOVE_DISCOUNT_CART_CONSUMER,
  MUTATE_APPLY_REWARD_CART_CONSUMER,
  MUTATE_SWITCH_CART_STORE_CONSUMER,
  QUERY_VALIDATE_CART_CONSUMER
}
