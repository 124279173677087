import styled from '@emotion/styled'

export const Divider = styled.hr(({ theme }: any) => ({
  margin: `${theme.space[5]}px 0`,
  border: 0,
  borderBottom: '1px solid #f5f5f5',

  [theme.mediaQueries.viewport7]: {
    display: 'none'
  }
}))
