import styled from '@emotion/styled'
import CartBreakdownItem from './CartBreakdownItem'
import { IoIosBicycle as Bicycle } from 'react-icons/io'
import { BsShop as Store } from 'react-icons/bs'
import { AiOutlineHeart as Heart } from 'react-icons/ai'
import { Price } from 'shop/types/cart'
import { LineBreak } from '../commonStyles'

interface Props {
  deliveryFee: Price | null
  isDelivery: boolean
  tip: string | null
  storeFee: Price | null | undefined
  isCartLoading: boolean
}

const FeeBreakdown: React.FC<Props> = ({
  storeFee,
  isDelivery,
  deliveryFee,
  tip,
  isCartLoading
}) => {
  const feeList = [
    !!storeFee?.base && (
      <CartBreakdownItem
        key='storeFee'
        LeftIcon={Store}
        label={'Restaurant Fee'}
        price={storeFee}
        isCartLoading={isCartLoading}
        withLineBreak={false}
        padding={'0px'}
      />
    ),
    isDelivery && (
      <CartBreakdownItem
        key='deliveryFee'
        LeftIcon={Bicycle}
        label={'Delivery Fee'}
        price={deliveryFee}
        pricePlaceholder={'To be calculated'}
        isCartLoading={isCartLoading}
        withLineBreak={false}
        padding={'0px'}
      />
    ),
    !!tip && (
      <CartBreakdownItem
        key='tip'
        LeftIcon={Heart}
        label={'Tip'}
        price={{ base: tip }}
        isCartLoading={isCartLoading}
        withLineBreak={false}
        padding={'0px'}
      />
    )
  ].filter((element) => !!element) as JSX.Element[]

  if (!feeList.length) return <></>
  return (
    <>
      <LineBreak height={'1px'} data-testid='cart-breakdown-line' />
      <FeeBreakdownContainer>
        {feeList.map((item) => item)}
      </FeeBreakdownContainer>
    </>
  )
}

const FeeBreakdownContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 0'
}))

export default FeeBreakdown
