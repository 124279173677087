/* global google */

import styled from '@emotion/styled'
import { useContext, useEffect, useState, useRef } from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import uuidv4 from 'uuid/v4'
import AddressInputField from '../Landing/Delivery/AddressInputField'
import InputFieldNew from 'shop/components/Controls/InputFieldNew'
import { RiMapPinLine as PinIcon } from 'react-icons/ri'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { DeliverySignIn } from '../Landing/Delivery'
import { useMediaQueries, useModal } from 'shop/hooks'
import { LandingContextNew } from '../Landing'
// Please see if we need to extend further:
// ./node_modules/@types/react-places-autocomplete/index.d.ts

type PlacesAutocompleteRef = PlacesAutocomplete & {
  fetchPredictions: () => void
  handleInputOnBlur: () => void
}

type AddressAutoCompleteProps = {
  value: string
  onChange: (value: string) => void
  onSelect: (value: string) => void
  onFocus?: () => void
  onBlur?: () => void
  withIcon?: boolean
  isEditing?: boolean
  newFulfillmentModal?: boolean
  onClear?: () => void
}

const AddressAutoComplete = (props: AddressAutoCompleteProps) => {
  const [shouldFetchSuggestions, setShouldFetchSuggestions] = useState(false)

  const isLoggedIn = localStorage.getItem('customerId') !== null
  const { openModal } = useModal()
  const { isMobile } = useMediaQueries()
  const { setIsOverlayOpen, setIsLoginOpen } = useContext(LandingContextNew)
  const placesAutocompleteRef = useRef<PlacesAutocompleteRef>(null)

  const handleOpenLoginModal = () => {
    openModal('login')

    isMobile && setIsOverlayOpen(true)
    // Differences because FullscreenModal on mobile & CenterModal on desktop
    !isMobile && setIsOverlayOpen(false)
    setIsLoginOpen(true)
  }

  const searchOptions = {
    location: new google.maps.LatLng({ lat: 51.5137137, lng: -0.1285204 }),
    radius: 30,
    sessiontoken: uuidv4()
  }

  useEffect(() => {
    const parent = document.getElementById('landing-form')
    if (!parent) return

    const suggestions = document.querySelectorAll('.suggestion-item')
    if (suggestions.length === 0) return

    const lastSuggestion = suggestions[suggestions.length - 1]
    if (!lastSuggestion) return

    const offset = lastSuggestion.getBoundingClientRect().top
    parent.scrollTo({ top: offset, behavior: 'smooth' })
  })

  useEffect(() => {
    const strippedAddressLength = props.value.replace(/\s/g, '')
    const isAddressEligible = strippedAddressLength.length >= 3

    if (isAddressEligible) {
      setShouldFetchSuggestions(true)
    } else {
      setShouldFetchSuggestions(false)
    }
  }, [props.value])

  useEffect(() => {
    // manually trigger fetching the suggestions as the component won't do it in time
    if (
      shouldFetchSuggestions &&
      placesAutocompleteRef.current?.fetchPredictions
    ) {
      placesAutocompleteRef.current.fetchPredictions()
    }
  }, [shouldFetchSuggestions])

  useEffect(() => {
    if (
      props.newFulfillmentModal &&
      placesAutocompleteRef.current?.handleInputOnBlur
    ) {
      placesAutocompleteRef.current.handleInputOnBlur = () => {}
    }
  }, [props.newFulfillmentModal])

  return (
    <PlacesAutocomplete
      searchOptions={searchOptions}
      debounce={500}
      shouldFetchSuggestions={shouldFetchSuggestions}
      highlightFirstSuggestion
      ref={placesAutocompleteRef}
      {...props}
    >
      {(placesProps) => {
        const { getInputProps, getSuggestionItemProps } = placesProps
        const { value, ...inputProps } = getInputProps({
          onBlur: props.onBlur,
          onFocus: props.onFocus
        })
        const {
          withIcon = true,
          isEditing,
          newFulfillmentModal = false
        } = props
        return (
          <AutoCompleteInputGroup
            newFulfillmentModal={newFulfillmentModal}
            data-testid='address-autocomplete'
          >
            <>
              {newFulfillmentModal ? (
                <>
                  <InputFieldNew
                    name='input-field'
                    icon={withIcon ? <PinIcon /> : null}
                    hasValue={!!value}
                    value={value}
                    inputProps={{
                      ...inputProps,
                      autoComplete: 'chrome-off',
                      placeholder: 'e.g. N3 1LJ',
                      value,
                      autoFocus: true
                    }}
                  />
                  <LineBreak />
                </>
              ) : (
                <AddressInputField
                  withIcon={withIcon}
                  isEditing={isEditing}
                  autoFocus={true}
                  value={props.value}
                  inputProps={{ ...inputProps }}
                />
              )}
            </>
            <div className='autocomplete-dropdown'>
              {placesProps.suggestions.map((suggestion, i) => {
                const className = suggestion.active
                  ? 'suggestion-item suggestion-item-active'
                  : 'suggestion-item'

                if (newFulfillmentModal) {
                  return (
                    <div
                      data-testid={`addressSuggestion`}
                      {...{
                        ...getSuggestionItemProps(suggestion, { className }),
                        key: `address-suggestion-${i}`
                      }}
                    >
                      <SuggestionDescriptionContainer>
                        <PinIcon />
                        <span>{suggestion.description}</span>
                      </SuggestionDescriptionContainer>
                    </div>
                  )
                } else {
                  return (
                    <div
                      data-testid={`addressSuggestion`}
                      {...{
                        ...getSuggestionItemProps(suggestion, { className }),
                        key: `address-suggestion-${i}`
                      }}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  )
                }
              })}
              {newFulfillmentModal && !isLoggedIn && (
                <DeliverySignInCTAContainer onClick={handleOpenLoginModal}>
                  <DeliverySignIn />
                </DeliverySignInCTAContainer>
              )}
            </div>
          </AutoCompleteInputGroup>
        )
      }}
    </PlacesAutocomplete>
  )
}

const LineBreak = styled.hr<StyledHTMLElement>(() => ({
  position: 'absolute',
  right: '0',
  left: '0',
  height: '2px',
  backgroundColor: '#F0F0F0',
  marginTop: '24px',
  border: 'none'
}))

/** Sets auto complete styles for newFulfillmentModal */
const variantStyles = {
  newFulfillmentModal: (theme: any) => ({
    position: 'unset',
    width: '100%',
    '.autocomplete-dropdown': {
      marginTop: '25px',
      fontSize: theme.fontSizes[1],
      color: theme.colors.textBody,
      width: '100%',
      zIndex: 101,
      position: 'absolute',
      left: 0,
      background: 'white',
      paddingBottom: '32px',
      borderRadius: '16px'
    },
    '.suggestion-item': {
      background: '#FFFFFF',
      cursor: 'pointer',
      padding: '12px 18px',
      margin: 0,
      color: theme.colors.body,
      [theme.mediaQueries.viewport5]: {
        padding: '12px 34px'
      }
    },
    '.suggestion-item:last-child': {
      borderBottom: '2px solid #F0F0F0'
    },
    '.suggestion-item-active': {
      background: '#F2F2F2'
    },
    '.suggestion-item:active': {
      span: {
        color: '#4975E9'
      }
    }
  })
}

/** Returns newFulfillmentModal styles based on boolean prop */
const returnNewFulfillmentModalStyle = (
  theme: any,
  newFulfillmentModal: boolean
) => (newFulfillmentModal ? variantStyles['newFulfillmentModal'](theme) : {})

const AutoCompleteInputGroup = styled.div<
  StyledHTMLElement & { newFulfillmentModal: boolean },
  Required<Theme>
>(({ theme, newFulfillmentModal }: any) => ({
  position: 'relative',
  '.autocomplete-dropdown': {
    position: 'absolute',
    marginTop: '8px',
    fontSize: theme.fontSizes[1],
    color: theme.colors.textBody,
    width: '100%',
    zIndex: 10
  },
  '.suggestion-item': {
    background: '#ffffff',
    cursor: 'pointer',
    padding: '12px 24px',
    margin: 0,
    color: theme.colors.body
  },
  '.suggestion-item:last-child': {
    marginBottom: '100px'
  },
  '.suggestion-item-active': {
    background: '#f1f1f1',
    borderRadius: '12px'
  },
  ...returnNewFulfillmentModalStyle(theme, newFulfillmentModal)
}))

const SuggestionDescriptionContainer = styled.div<StyledHTMLElement>(() => ({
  display: 'flex',
  alignItems: 'center',
  svg: {
    fill: '#595959',
    width: '15px',
    height: '15px',
    marginRight: '8px',
    flexShrink: 0
  }
}))

const DeliverySignInCTAContainer = styled.div<StyledHTMLElement>(() => ({
  marginTop: '32px',
  display: 'flex',
  justifyContent: 'center'
}))

export default AddressAutoComplete
