import ReactGA4, { ReactGAImplementation } from 'react-ga4'
import { EnrichedEventData } from './GA/types'

class Tracker {
  static getInstance(): Tracker {
    if (!Tracker.instance) {
      Tracker.instance = new Tracker()
    }

    return Tracker.instance
  }

  trackingId: string
  trackerName: string
  isTrackingEnabled: boolean
  customerId: string
  enrichedEventData: EnrichedEventData

  reactga4: typeof ReactGA4

  private static instance: Tracker

  constructor() {
    this.trackingId = ''
    this.trackerName = ''
    this.isTrackingEnabled = false
    this.customerId = ''
    this.enrichedEventData = {
      merchant_id: '',
      merchant_slug: '',
      store_id: '',
      store_slug: '',
      rwg_token: ''
    }
    /** Despite having a private instance of ReactGA4,
     * calling this with an event will trigger all initialized trackers.
     * e.g. both Slerp's and Merchant's.
     */
    this.reactga4 = new ReactGAImplementation()
  }

  setTrackingId(trackingId: string) {
    this.trackingId = trackingId
  }

  setTrackerName(trackerName: string) {
    this.trackerName = trackerName
  }

  setIsTrackingEnabled(enabled: boolean) {
    this.isTrackingEnabled = enabled
  }

  setTrackingCustomerId(customerId?: string) {
    this.customerId = customerId || ''
  }

  /** Set's "enriched event data" at a config level of the Tracker.
   * Using the window.dataLayer - https://developers.google.com/tag-platform/devguides/datalayer
   */
  setEnrichedEventData(enrichedEventData: EnrichedEventData) {
    this.enrichedEventData = enrichedEventData
    const dataLayer: any[] = (window as any)?.dataLayer
    if (!!dataLayer?.length) {
      // find the first set config - we don't want to miss any default set ones
      const prevConfig: any[] = dataLayer.find((entry) => {
        // the dataLayer we want looks something like:
        // "Arguments['config', trackingId, gtagOptions]"
        if (entry?.length >= 3) {
          return (
            entry[0] === 'config' &&
            entry[1] === this.trackingId &&
            entry[2]?.name === this.trackerName
          )
        }
        return false
      })

      if (!!prevConfig) {
        const newGtagOptions = {
          ...prevConfig[2],
          ...enrichedEventData
        }
        this.reactga4.gtag('config', this.trackingId, newGtagOptions)
      }
    }
  }

  initializeGA4 = (for_testing: boolean) => {
    const isDevEnv: boolean = !!process.env.REACT_APP_TEST_ENV
    if (!for_testing) {
      if (this.trackingId) {
        const userOptions = this.customerId
          ? {
              user_id: this.customerId
            }
          : {}
        const debugOptions = isDevEnv
          ? {
              debug_mode: isDevEnv
            }
          : {}
        this.reactga4.initialize(this.trackingId, {
          gaOptions: { name: this.trackerName },
          gtagOptions: {
            ...userOptions,
            ...debugOptions
          }
        })

        this.setIsTrackingEnabled(true)
      }
    }
  }
}

export default Tracker
