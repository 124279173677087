import styled from '@emotion/styled'
import { UseFormMethods } from 'react-hook-form'
import { MdErrorOutline as ErrorIcon } from 'react-icons/md'

type SwitchButtonProps = {
  name: string
  formRef: UseFormMethods['register']
  labelText?: string
  color?: string
}

type CheckoutFormProps = {
  hidden?: boolean
}

const Divider = styled.hr(() => ({
  margin: '40px 0'
}))

const Fields = styled.div(() => ({
  display: 'block'
}))

const SectionTitle = styled.h6(() => ({
  fontWeight: 'normal',
  textTransform: 'uppercase',
  letterSpacing: '0.05em',
  marginBottom: '12px'
}))

const Switch = ({ formRef, name, labelText, color }: SwitchButtonProps) => {
  return (
    <>
      <SwitchInput name={name} id={name} ref={formRef} type={'checkbox'} />
      <SwitchOutline
        htmlFor={name}
        data-testid={`${name}_switch`}
        color={color}
      >
        <SwitchButton />
      </SwitchOutline>
      {labelText && <SwitchLabel htmlFor={name}> {labelText} </SwitchLabel>}
    </>
  )
}

type FlashProps = {
  message: string
}

const ErrorFlash = ({ message }: FlashProps) => {
  return (
    <ErrorContainer>
      <ErrorIcon />
      <p> {message} </p>
    </ErrorContainer>
  )
}

const SwitchOutline = styled.label(({ color }: any) => ({
  flexShrink: 0,
  display: 'inline-block',
  verticalAlign: 'top',
  width: '32px',
  height: '16px',
  backgroundColor: color || '#ddd',
  borderRadius: '48px',
  position: 'relative',
  cursor: 'pointer',
  ...(!color && {
    transition: 'background-color 300ms linear',
    '&:hover': {
      background: '#ccc'
    }
  })
}))

const SwitchButton = styled.div(() => ({
  content: '""',
  display: 'block',
  position: 'absolute',
  top: '2px',
  left: '2px',
  height: '12px',
  width: '12px',
  background: 'white',
  borderRadius: '50%',
  transition: 'transform 300ms ease-in'
}))

const SwitchInput = styled.input(({ theme }: any) => ({
  display: 'none',
  '&:checked + label': {
    background: theme.colors.black,
    '> div': {
      transform: 'translate3d(16px, 0, 0)'
    }
  }
}))

const CheckoutActions = styled.div(({ theme }: any) => ({
  zIndex: 2,
  position: 'fixed',
  bottom: '0',
  left: '0',
  right: '0',
  display: 'flex',
  '> button:not(:last-of-type)': { margin: '0 0 10px' },
  padding: '16px',
  backgroundColor: 'white',
  boxShadow: '0 -1px 8px 8px rgba(0, 0, 0, 0.05)',
  flexDirection: 'column',
  [theme.mediaQueries.viewport6]: {
    boxShadow: 'none',
    position: 'relative',
    padding: '0',
    marginTop: '48px'
  }
}))

const Crumb = styled.a(() => ({
  display: 'block',
  border: 'none',
  background: 'none',
  margin: '8px',
  textAlign: 'center',
  cursor: 'pointer'
}))

const CheckoutForm = styled.form(({ hidden }: CheckoutFormProps) => ({
  display: hidden ? 'none' : 'flex',
  height: '100%',
  flexDirection: 'column'
}))

// This is a bug in emotion typescript. It will crash if you declare 'theme'
const SwitchLabel = styled.label((props: any) => ({
  fontWeight: props.theme.fontWeights.bold,
  fontSize: props.theme.fontSizes[0],
  marginLeft: '8px'
}))

const ErrorContainer = styled.div((props: any) => ({
  backgroundColor: props.theme.colors.state.error[1] || 'red',
  display: 'flex',
  justifyContent: 'space-among',
  alignItems: 'center',
  borderRadius: '4px',
  fontWeight: 500,
  padding: '8px 8px 8px 16px',
  border: `0.5px solid ${props.theme.colors.state.error[5]}`,
  color: props.theme.colors.state.error[9],
  '> p': {
    margin: '0 0 0 8px',
    fontSize: '12px'
  }
}))

export {
  Divider,
  Fields,
  SectionTitle,
  Switch,
  CheckoutActions,
  Crumb,
  CheckoutForm,
  ErrorFlash
}
