/*
A drop-in proxy ThemeProvider.

This makes it easy in case we need to swap ThemeProviders

Shop supports system-ui compatible themes (https://system-ui.com/).
See ./theme/types.ts for reference

This currently uses Emotion but may potentionally be replaced
by styled-components or our own ThemeProvider (./ThemeProvider)
*/

import React, { useState } from 'react'
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming'
import merge from 'deepmerge'
import defaultTheme from './defaultTheme'
import Theme from './types'
import WebFont from 'webfontloader'

export interface ThemeProps {
  theme: Theme
  children: any
}

/** The ThemeProvider. This wraps EmotionThemeProvider */
const ThemeProvider = (props: ThemeProps) => {
  return (
    <EmotionThemeProvider theme={props.theme}>
      {props.children}
    </EmotionThemeProvider>
  )
}

/** Loads the initial theme from cache or from defaults  */
const initialTheme = () => {
  const cachedTheme = '{}' // localStorage.getItem('theme')

  // Don't let broken themes break the site
  try {
    if (cachedTheme) {
      return merge(defaultTheme, JSON.parse(cachedTheme))
    } else {
      return defaultTheme
    }
  } catch (error) {
    console.error(error)
    return defaultTheme
  }
}

/** Loads theme fonts */
const preloadFonts = (fonts: any) => {
  WebFont.load({
    google: {
      families: formatFonts(fonts)
    }
  })
}

const formatFonts = ({ heading, body }: any) => {
  const formattedFonts = [heading, body].map((font) => {
    const family = font.family.substring(0, font.family.indexOf(','))
    return `${family}:[100,300,400,500,600,700]`
  })

  // removes font duplicates
  return [...new Set(formattedFonts)]
}

/** A hook to start using themes
 *
 *  Load the initial theme and merge overrides
 *
 *  Sequence of theme loading is as follows:
 *  1. Default theme
 *  2. Persisted theme
 *  3. config.theme = {} overrides
 */
const useTheme = (
  overrides: Partial<Theme> = {}
): [Theme, React.Dispatch<React.SetStateAction<Theme>>] => {
  // TODO: Write tests for this.
  const themePlusOverrides = merge(initialTheme(), overrides)

  const [theme, setTheme] = useState(themePlusOverrides)

  const setNewTheme = (e: object) => {
    const newTheme = merge(theme, e)
    setTheme(newTheme)
  }

  preloadFonts(theme.fonts)

  return [theme, setNewTheme]
}

export { useTheme, defaultTheme }

export default ThemeProvider

ThemeProvider.defaultProps = {
  theme: defaultTheme
}
