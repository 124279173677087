import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'

interface SimpleLoaderProps {
  backgroundColor?: string
  foregroundColor?: string
  isLoading: boolean
  additionalStyles?: React.CSSProperties
  children: React.ReactNode
}

const SimpleLoader = ({
  children,
  backgroundColor = '#e6e6e6',
  foregroundColor = '#f0f0f0',
  isLoading = true,
  additionalStyles
}: SimpleLoaderProps) => {
  return (
    <SimpleLoaderWrapper
      {...{ backgroundColor, foregroundColor, isLoading, additionalStyles }}
    >
      {children}
    </SimpleLoaderWrapper>
  )
}

const animation = keyframes`
  0% { 
    background-position: -500px 0;
  }
  100% {
    background-position: 500px 0;
  }
`

type SimpleLoaderWrapperProps = {
  backgroundColor?: string
  foregroundColor?: string
  isLoading: boolean
  additionalStyles?: React.CSSProperties
}

const returnStyles = {
  isLoading: (
    animation?: Keyframe,
    backgroundColor?: string,
    foregroundColor?: string,
    additionalStyles?: React.CSSProperties
  ) => {
    return {
      // Targets the immediate child element of the SimpleLoaderWrapper
      '> *': {
        height: '100%',
        display: 'block',
        opacity: 1,
        animationDuration: '2.25s',
        animationFillMode: 'forwards',
        animationIterationCount: 'infinite',
        animationName: `${animation}`,
        animationTimingFunction: 'linear',
        background: `linear-gradient(
        to right,
        ${backgroundColor} 8%,
        ${foregroundColor} 18%,
        ${backgroundColor} 33%
        )`,
        color: 'transparent !important',
        position: 'relative',
        userSelect: 'none',
        // Targets all children of the component wrapped by SimpleLoaderWrapper
        '*': {
          display: 'none',
          opacity: 0
        },
        ...additionalStyles
      }
    }
  }
}

const setStyles = (
  isLoading: boolean,
  animation: any,
  backgroundColor?: string,
  foregroundColor?: string,
  additionalStyles?: React.CSSProperties
) =>
  isLoading
    ? returnStyles['isLoading'](
        animation,
        backgroundColor,
        foregroundColor,
        additionalStyles
      )
    : {}

const SimpleLoaderWrapper = styled.span<SimpleLoaderWrapperProps>(
  ({ backgroundColor, foregroundColor, isLoading, additionalStyles }) => ({
    ...setStyles(
      isLoading,
      animation,
      backgroundColor,
      foregroundColor,
      additionalStyles
    )
  })
)

export default SimpleLoader
