import { useShop } from '.'
import { CartManager } from 'shop/client/cart'
import { isPast, parseISO } from 'date-fns'

/**
 * Returns the current cart id
 *
 * Fetched via cookie.
 *
 * @returns string
 */
const getCurrentCartId = (domain: string) => {
  // FIXME: use localStorage for now until we find a replacement
  // TODO: Fix this and mock it inside the tests itself
  if (process.env.NODE_ENV === 'test') {
    return '32c53f95-4698-44f9-8906-02bb50615e78'
  }

  const cartDetails = localStorage.getItem(domain)
  if (!cartDetails || typeof cartDetails !== 'string') {
    // For backwards compatibility. Should be irrelevant in the future.
    const prevCartId = localStorage.getItem('cartId')
    if (prevCartId) return prevCartId
    return null
  }

  try {
    const parsedDetails = JSON.parse(cartDetails)

    const expiresAt = parsedDetails['expires_at']
    if (expiresAt && isPast(parseISO(expiresAt))) {
      localStorage.removeItem(domain)
      return null
    }

    if (parsedDetails['cart_id']) return parsedDetails.cart_id

    return null
  } catch {
    localStorage.removeItem(domain)
    return null
  }
}

/**
 * useCart hook allows us to connect to a CartManager
 *
 * Every call to the useCart() creates an instance of the CartManager
 * which exposes utility functions to manage the cart.
 *
 * @returns CartManager
 */

const useCart = (): CartManager => {
  const { cartSession, useShopClient, config } = useShop()
  cartSession.cartId = getCurrentCartId(config.domain)
  return new CartManager(cartSession, useShopClient())
}

export { useCart, CartManager }
