import ReactGA4 from 'react-ga4'
import TagManager from 'react-gtm-module'
import Tracker from 'tracker'
import AllTrackers from './AllTrackers'

interface MerchantProps {
  tag_manager_id: string
  google_ads_id: string
  google_conversion_id: string
  google_analytics_4_id: string
  for_testing?: boolean
  slug: string
}

export interface UserParams {
  /** Typically the object that was interacted with (e.g. 'Video') */
  category: string
  /** The type of interaction (e.g. 'play') */
  action: string
  /** Useful for categorizing events (e.g. 'Fall Campaign') */
  label?: string
  /** A numeric value associated with the event (e.g. 42) */
  value?: number
}

export const initializeMerchantGA4 = (
  { google_analytics_4_id: ga4Id, for_testing }: MerchantProps,
  customerId: string
) => {
  AllTrackers.getInstance().initMerchantGA4Tracker(
    ga4Id,
    'merchantGA4Tracking',
    !!for_testing,
    customerId
  )
}

export const initializeTagManager = ({
  tag_manager_id: gtmId
}: MerchantProps) => {
  if (gtmId) {
    TagManager.initialize({ gtmId })
  }
}

export const initializeSlerpGA4 = (
  { for_testing }: MerchantProps,
  customerId: string
) => {
  AllTrackers.getInstance().initSlerpGA4Tracker(
    process.env.REACT_APP_GA4_ID || '',
    'slerpGA4Tracker',
    !!for_testing,
    customerId
  )
}

export const initializeGoogleConversion = ({
  google_ads_id,
  google_conversion_id
}: MerchantProps) => {
  if (google_ads_id && google_conversion_id) {
    // Google ads initialization is on slerp's v2.html.eex
    Tracker.getInstance().setGoogleConversionId(google_conversion_id)
  }
}

export const trackUserActionsGA4 = (
  userParams: UserParams,
  trackerName: string
) => {
  if (checkTracker(trackerName)) {
    ReactGA4.event(userParams, [trackerName])
  }
}

export const trackGA4ProductListViewed = (trackerName: string) => {
  if (checkTracker(trackerName)) {
    const { domain, store } = setup()

    ReactGA4.event(
      {
        category: 'Product List',
        action: 'Product List Viewed',
        label: `${domain} / ${store}`
      },
      [trackerName]
    )
  }
}

const setup = () => {
  let domain: string | null = null
  let store: string | null = null

  const root = document.getElementById('root')

  if (root) {
    domain = root.getAttribute('domain')
    store = root.getAttribute('store')
  }

  return { domain, store }
}

const checkTracker = (trackerName: string) => {
  switch (trackerName) {
    case 'slerpGA4Tracking':
      // TEMP: do not track custom events
      return false
    // return (
    //   Tracker.getInstance().slerpGA4Enabled ||
    //   AllTrackers.getInstance().slerpGA4Tracker.isTrackingEnabled
    // )

    case 'merchantGA4Tracking':
      // TEMP: do not track custom events
      return false
    // return (
    //   Tracker.getInstance().merchantGA4Enabled ||
    //   AllTrackers.getInstance().merchantGA4Tracker.isTrackingEnabled
    // )

    default:
      return false
  }
}
