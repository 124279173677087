import { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useQueryParamsContext } from './useGlobalContext'

export const useQueryParams = () => {
  const history = useHistory()
  const location = useLocation()
  const { searchQueryParams } = useQueryParamsContext()

  /** Get matching location.search value. */
  const getQueryParam = useCallback(
    (param: string): string => {
      if (!searchQueryParams) return ''
      const queryParamValue = searchQueryParams.get(param)
      if (!!queryParamValue) {
        return queryParamValue
      }

      return ''
    },
    [searchQueryParams]
  )

  /** Checks whether the query param exists in the location.search, even if the value is empty. */
  const hasQueryParam = useCallback(
    (param: string): boolean => {
      if (!searchQueryParams) return false
      const queryParamValue = searchQueryParams.get(param)
      if (queryParamValue !== null) {
        return true
      }

      return false
    },
    [searchQueryParams]
  )

  /** Delete matching location.search value. */
  const deleteQueryParam = useCallback(
    (paramToRemove: string) => {
      if (!searchQueryParams) return
      searchQueryParams.delete(paramToRemove)

      history.replace({
        pathname: location.pathname,
        search: searchQueryParams.toString()
      })
    },
    [searchQueryParams]
  )

  return {
    getQueryParam,
    hasQueryParam,
    deleteQueryParam
  }
}
