import Tracker from './TrackerNew'

class AllTrackers {
  static getInstance(): AllTrackers {
    if (!AllTrackers.instance) {
      AllTrackers.instance = new AllTrackers()
    }

    return AllTrackers.instance
  }

  slerpGA4Tracker: Tracker
  merchantGA4Tracker: Tracker
  // TODO: implement the same Tracker logic for the following:
  //   fbPixelTracker: Tracker
  //   googleConversionId: string | null

  private static instance: AllTrackers

  private constructor() {
    this.slerpGA4Tracker = new Tracker()
    this.merchantGA4Tracker = new Tracker()

    // TODO: implement the same Tracker logic for the following:
    // this.fbPixelTracker = new Tracker()
    // this.googleConversionId = null
  }

  initSlerpGA4Tracker(
    trackingId: string,
    trackerName: string,
    for_testing: boolean,
    customerId?: string
  ) {
    this.slerpGA4Tracker.setTrackingId(trackingId)
    this.slerpGA4Tracker.setTrackingCustomerId(customerId)
    this.slerpGA4Tracker.setTrackerName(trackerName)
    this.slerpGA4Tracker.initializeGA4(for_testing)
  }

  initMerchantGA4Tracker(
    trackingId: string,
    trackerName: string,
    for_testing: boolean,
    customerId?: string
  ) {
    this.merchantGA4Tracker.setTrackingId(trackingId)
    this.merchantGA4Tracker.setTrackingCustomerId(customerId)
    this.merchantGA4Tracker.setTrackerName(trackerName)
    this.merchantGA4Tracker.initializeGA4(for_testing)
  }
}

export default AllTrackers
