import { isTableOrder, Metadata } from 'shop/components/Cart/utils'
import { FulfillmentType } from 'shop/components/Product/types'

export const getFulfillmentType = (
  cartDetails: {
    fulfillment_type: 'delivery' | 'pickup' | 'order_at_table'
    metadata?: Metadata
  } | null
): FulfillmentType => {
  if (!cartDetails) return null
  if (isTableOrder(cartDetails.metadata)) return 'order_at_table'
  return cartDetails.fulfillment_type
}
