import styled from '@emotion/styled'
import { ModifierGroup, Price as PriceType, Option } from 'shop/types/cart'
import { Quantity, Price, Modifiers, OptionsV2 } from '../index'
import { Name } from '../commonStyles'

interface Props {
  name: string
  quantity: number
  itemTotal: PriceType
  modifierGroups: ModifierGroup[]
  options: Option[]
}

const CartItemV2 = ({
  name,
  quantity,
  itemTotal,
  modifierGroups,
  options
}: Props) => {
  return (
    <ContentWrapper>
      <BasicContent data-testid='cart-item-basic'>
        <ProductName>{name}</ProductName>
        <Quantity quantity={quantity} />
        <Price price={itemTotal}></Price>
      </BasicContent>
      <DetailedContent data-testid='cart-item-detailed'>
        {!!options && <OptionsV2 options={options} />}
        {!!modifierGroups.length && (
          <Modifiers modifierGroups={modifierGroups} />
        )}
      </DetailedContent>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div(() => ({
  marginBottom: '24px'
}))

const BasicContent = styled.div(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 0fr 75px',
  gap: '16px',
  marginBottom: '6px',
  width: '100%'
}))

const DetailedContent = styled.div(() => ({
  'p, span': {
    fontSize: '14px'
  },
  maxWidth: '82%'
}))

const ProductName = styled(Name)(() => ({
  fontSize: '16px',
  margin: '0',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
}))

export default CartItemV2
