import styled from '@emotion/styled'
import { IconTitle } from './IconTitle'
import { FaRegClock } from 'react-icons/fa'
import { useMerchantStore } from 'shop/hooks'
import { WorkTime } from './WorkTime'
import { timeToDatetime } from 'shop/utils'

export const ShopSchedule = () => {
  const { openingTimes } = useMerchantStore()

  if (!openingTimes) {
    return <></>
  }

  const daysOfWeek = Object.keys(openingTimes).map((key, index) => {
    const { closed } = openingTimes[key][0]

    if (closed) {
      return <WorkTime key={index} day={key} closed />
    }

    const times = openingTimes[key].reduce(
      (
        acc: { from: Date; to: Date }[],
        { startTime, endTime }: { startTime: string; endTime: string }
      ) => {
        const from = timeToDatetime(startTime)
        const to = timeToDatetime(endTime)

        return [...acc, { from, to }]
      },
      []
    )

    return <WorkTime key={index} day={key} times={times} />
  })

  return (
    <Root data-testid='shop-schedule-root'>
      <IconTitle icon={<FaRegClock />}>Opening hours</IconTitle>
      <Schedule>{daysOfWeek}</Schedule>
    </Root>
  )
}

const Root = styled.div(() => ({
  display: 'flex',
  flexFlow: 'column',
  gap: '16px',
  width: '100%',
  maxWidth: '360px'
}))

const Schedule = styled.div(() => ({
  marginLeft: '24px',
  display: 'flex',
  flexFlow: 'column',
  gap: '12px'
}))
