import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { RequiredLabel } from '../Forms'
import { PriceLabel } from '../Modifiers/Modifier'
import {
  ProductOptions,
  ProductVariant,
  SelectedOption
} from '../Product/types'
import { OptionsExtraPrices } from '../ProductInfo/ProductInfo'
import { RadioInput } from '../Inputs'
import { matchVariantToSelectedOptions } from '../ProductInfo/utils'
import { MissingRequiredSection } from 'shop/types'

interface OptionsProps {
  options: ProductOptions[]
  disabled?: boolean
  selectOption: (name: string, type: string) => void
  errorMessage?: React.ReactElement
  missingRequiredSections?: MissingRequiredSection[]
  selectedOptions?: SelectedOption[]
  optionsExtraPrices?: OptionsExtraPrices
  variants: ProductVariant[]
}

const VariantOptions = ({
  options,
  selectOption,
  disabled = false,
  errorMessage,
  missingRequiredSections,
  selectedOptions,
  optionsExtraPrices,
  variants
}: OptionsProps) => {
  // state to handle CSS onClick style changes.
  const [inputPressed, setInputPressed] = useState<string>('')
  const isOptionChecked = (
    name: string,
    value: string
  ): boolean | undefined => {
    // ProductModalV1 has no selectedOptions values and should ignore this additional check
    // as functionality existed without it
    if (!selectedOptions?.length) {
      return undefined
    }
    const isChecked = selectedOptions?.some(
      (option) => option.name === name && option.value === value
    )
    return !!isChecked
  }

  // If only one option to select, preselect it.
  useEffect(() => {
    options?.forEach((option) => {
      // if there is only 1 value, just preselect it
      if (option.values.length === 1) {
        return selectOption(option.name, option.values[0])
      }

      // otherwise, check inStock values
      const inStockOptionValues = option.values.filter((value) => {
        const matchedVariant = matchVariantToSelectedOptions(variants, [
          { name: option.name, value }
        ])
        return matchedVariant?.inStock
      })

      // if there is only 1 inStock value, preselect it
      if (inStockOptionValues.length === 1) {
        selectOption(option.name, inStockOptionValues[0])
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, variants])

  return (
    <>
      {options.map(({ name, values }: ProductOptions, optionIndex) => {
        const hasError = missingRequiredSections?.some(
          (missingOption) => missingOption.name === name
        )
        const hasSelectedOption = values.find((value) =>
          isOptionChecked(name, value)
        )

        return (
          <div data-testid={'options'} key={name} id={name}>
            <Header {...{ error: hasError }}>
              <Title {...{ disabled: disabled }}>{name}</Title>
              {!disabled && (
                <RequiredLabel
                  state={(() => {
                    if (hasError) return 'error'
                    if (hasSelectedOption) return 'success'
                    return 'default'
                  })()}
                />
              )}
            </Header>
            {errorMessage}
            <VariantOptionsList>
              {values.map((value: string, itemIndex) => {
                const priceDiff =
                  optionsExtraPrices && optionsExtraPrices[value]
                const inputId = `option-${name}-${optionIndex}-${value}-${itemIndex}`
                return (
                  <VariantOptionItem
                    key={itemIndex}
                    {...{ disabled: disabled }}
                  >
                    <RadioInput
                      id={inputId}
                      key={value}
                      type='radio'
                      name={name}
                      value={value}
                      onChange={() => {
                        // to handle CSS onClick style changes.
                        setInputPressed(inputId)
                        selectOption(name, value)
                        setTimeout(() => setInputPressed(''), 100)
                      }}
                      disabled={disabled}
                      checked={!!isOptionChecked(name, value)}
                      inputPressed={inputPressed === inputId}
                    />
                    <VariantOptionLabel
                      data-testid={'option'}
                      key={`label-${value}`}
                    >
                      {value}
                    </VariantOptionLabel>
                    {priceDiff ? (
                      <PriceLabel price={Number(priceDiff)} inStock />
                    ) : null}
                  </VariantOptionItem>
                )
              })}
            </VariantOptionsList>
          </div>
        )
      })}
    </>
  )
}

const Header = styled.div(({ theme, error }: any) => ({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: theme.fontSizes[0],
  fontWeight: 'normal',
  color: error ? theme.colors['state']['failure'] : theme.colors['textBody'],
  alignItems: 'center'
}))

const Title = styled.h2(({ disabled }: any) => ({
  fontSize: '16px',
  opacity: !disabled ? 1 : 0.5,
  cursor: !disabled ? 'auto' : 'not-allowed'
}))

const VariantOptionLabel = styled.span(({ theme }: any) => ({
  marginRight: 'auto',
  paddingLeft: '18px',
  overflow: 'hidden',
  fontSize: theme.fontSizes[1],
  color: theme.colors.textBody,
  caretColor: 'transparent',
  textOverflow: 'ellipsis',
  'input:disabled + &': {
    opacity: 0.5,
    cursor: 'not-allowed'
  }
}))

const VariantOptionItem = styled.label(({ disabled, theme }: any) => ({
  WebkitTapHighlightColor: 'transparent',
  cursor: !disabled ? 'pointer' : 'not-allowed',
  display: 'flex',
  alignItems: 'center',
  padding: '8px 0 16px',
  marginBottom: '8px',
  borderBottom: '1px solid #F3F2F4'
}))

const VariantOptionsList = styled.div(({ theme }: any) => ({
  marginTop: '16px',
  marginBottom: '24px'
}))

export default VariantOptions
