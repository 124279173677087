import React, { useState } from 'react'
import CenterModal from 'shop/components/Modal/CenterModal'
import styled from '@emotion/styled'
import AddressInputFields from './Address/AddressInputFields'
import AddressCheckbox from './Address/AddressCheckbox'
import Spinner from 'shop/components/Loader/Spinner'
import { Button } from 'shop/components'
import {
  MUTATE_CREATE_ADDRESS,
  MUTATE_EDIT_ADDRESS
} from 'shop/client/queries/CustomerQueries'
import uuidv4 from 'uuid/v4'
import { useShop } from 'shop/hooks'
import { customerClient } from './utils'
import { slideUp } from '../Shop/commonStyles'

interface Props {
  onClose: () => void
  customerId: string
  address?: {
    id?: string
    line_1?: string
    line_2?: string
    city?: string
    zip?: string
    country?: string
    contact_num?: string
    flat_number?: string
    label?: string
  }
  mode: string
  requeryAddresses: () => void
}

const CustomerAddressForm = ({
  onClose,
  customerId,
  address = {},
  mode,
  requeryAddresses
}: Props) => {
  const { customerApiKey, merchant } = useShop()
  const client = customerClient(customerApiKey, merchant?.id || '')
  const [label, setLabel] = useState(address.label || '')
  const [line1, setLine1] = useState(address.line_1)
  const [line2, setLine2] = useState(address.line_2 || '')
  const [city, setCity] = useState(address.city || '')
  const [zip, setZip] = useState(address.zip || '')
  const [country, setCountry] = useState(address.country || '')
  const [defaultBillingAddress, setDefaultBillingAddress] = useState(false)
  const [defaultShippingAddress, setDefaultShippingAddress] = useState(false)
  const [flatNumber, setFlatNumber] = useState(address.flat_number || '')
  const [showError, setShowError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showGeocodeError, setShowGeocodeError] = useState(false)

  const emptyFieldsExist =
    line1 === '' || city === '' || zip === '' || country === ''

  // based on ODF
  const validZip = zip.length > 4

  const isLine1Changed = address.line_1 ? address.line_1 !== line1 : false
  const isFlatNumberChanged = address.flat_number
    ? address.flat_number !== flatNumber
    : false

  const unsavedChangesExist =
    isFlatNumberChanged ||
    isLine1Changed ||
    address.line_2 !== line2 ||
    address.city !== city ||
    address.zip !== zip ||
    address.country !== country

  const handleSubmit = (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault()
    setIsLoading(true)

    if (emptyFieldsExist || !validZip) {
      setShowError(true)
      setIsLoading(false)
    } else {
      const addressId = address && address.id ? address.id : uuidv4()

      const addressMutation =
        mode === 'create' ? MUTATE_CREATE_ADDRESS : MUTATE_EDIT_ADDRESS

      const variables = {
        label,
        line1,
        line2,
        city,
        zip,
        country,
        flatNumber,
        id: addressId
      }

      client
        .mutate({
          mutation: addressMutation,
          variables:
            mode === 'create'
              ? {
                  ...variables,
                  customerId,
                  defaultBillingAddress,
                  defaultShippingAddress
                }
              : { ...variables, id: addressId }
        })
        .then(() => {
          requeryAddresses()
          onClose()
        })
        .catch(() => {
          setIsLoading(false)
          setShowGeocodeError(true)
        })
    }
  }

  const handleCloseModal = () => {
    if (unsavedChangesExist) {
      confirmModalClose()
    } else {
      onClose()
    }
  }

  const confirmModalClose = () => {
    const confirmationMessage = window.confirm(
      'Your unsaved changes will be lost. Continue?'
    )

    if (confirmationMessage) {
      onClose()
    } else {
      return
    }
  }

  const formHeader = mode === 'create' ? 'Add new address' : 'Edit address'

  return (
    <CenterModal handleCloseModal={handleCloseModal} type='wide'>
      <Form onSubmit={handleSubmit} data-testid='customerAddressForm'>
        <h2>{formHeader}</h2>
        <AddressFields>
          <AddressInputFields
            label='Nickname'
            span='(e.g. Home, Work)'
            onChange={(e) => setLabel(e.target.value)}
            defaultValue={label}
            testId='addressLabel'
          />
          <Row>
            <RowField>
              <AddressInputFields
                label='Flat or unit, if applicable'
                defaultValue={flatNumber || ''}
                onChange={(e) => setFlatNumber(e.target.value)}
                testId='addressFlat'
              />
            </RowField>
            <RowDivider />
            <RowField>
              <AddressInputFields
                label='Building name, if applicable'
                defaultValue={line2 || ''}
                onChange={(e) => setLine2(e.target.value)}
                testId='addressLine2'
              />
            </RowField>
          </Row>
          <AddressInputFields
            label='Street address *'
            span='(Includes street number, if applicable)'
            testId='addressLine1'
            defaultValue={line1}
            onChange={(e) => setLine1(e.target.value)}
            showError={showError && line1 === ''}
          />
          <AddressInputFields
            label='Town or City *'
            defaultValue={city}
            testId='addressCity'
            onChange={(e) => setCity(e.target.value)}
            showError={showError && city === ''}
          />
          <Row>
            <RowField>
              <AddressInputFields
                label='Postcode *'
                testId='addressPostcode'
                defaultValue={zip}
                onChange={(e) => setZip(e.target.value)}
                showError={showError && (zip === '' || !validZip)}
              />
              {showError && !validZip && (
                <ErrorMessage>Please enter a valid zip code</ErrorMessage>
              )}
            </RowField>
            <RowDivider />
            <RowField>
              <AddressInputFields
                label='Country *'
                testId='addressCountry'
                defaultValue={country}
                onChange={(e) => setCountry(e.target.value)}
                showError={showError && country === ''}
              />
            </RowField>
          </Row>
          {showError && emptyFieldsExist && (
            <ErrorMessage>Please fill in the required fields</ErrorMessage>
          )}
          {showGeocodeError && (
            <ErrorMessage>This address cannot be geocoded</ErrorMessage>
          )}
          {mode === 'create' && (
            <>
              <AddressCheckbox
                toggleChecked={() =>
                  setDefaultShippingAddress(!defaultShippingAddress)
                }
                isChecked={defaultShippingAddress}
                label='Default to this shipping address for future purchases'
              />
              <AddressCheckbox
                toggleChecked={() => {
                  setDefaultBillingAddress(!defaultBillingAddress)
                }}
                isChecked={defaultBillingAddress}
                label='Default to this billing address for future purchases'
              />
            </>
          )}
          <ButtonContainer>
            <Button disabled={isLoading} testId='saveAddressButton'>
              {isLoading && <Spinner />}
              Save address
            </Button>
          </ButtonContainer>
        </AddressFields>
      </Form>
    </CenterModal>
  )
}

const Form = styled.form(({ theme }: any) => ({
  animation: `${slideUp} 100ms ease-out`,
  position: 'relative',
  borderRadius: '4px',
  overflowY: 'scroll',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  zIndex: theme.zIndex.accountModal,
  fontFamily: theme.fonts.normal,
  margin: '16px auto',
  maxWidth: '480px',
  width: '100%',
  padding: '24px',
  '> h2': {
    lineHeight: 1,
    marginBottom: '24px',
    textAlign: 'center',
    fontWeight: theme.fontWeights.bold
  },
  [theme.mediaQueries.viewport7]: {
    minWidth: '100%',
    height: 'auto',
    padding: '48px 48px 16px',
    marginBottom: '32px',
    '> h2': {
      lineHeight: 0
    }
  }
}))

const AddressFields = styled.div(() => ({
  padding: '16px 0',
  justifyContent: 'center'
}))

const Row = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around'
}))

const RowField = styled.div((props: any) => ({
  width: props.width ? props.width : '50%'
}))

const RowDivider = styled.div(() => ({
  margin: '0 8px'
}))

const ButtonContainer = styled.div(() => ({
  textAlign: 'center',
  paddingTop: '16px'
}))

const ErrorMessage = styled.p(({ theme }: any) => ({
  color: theme.colors.state.error[5],
  fontSize: theme.fontSizes[1],
  fontWeight: theme.fontWeights.normal
}))

export default CustomerAddressForm
