// PasswordWrapper.tsx
import { Route, Switch } from 'react-router-dom'
import { AppContentLoader, LoaderSwitcher } from 'router/loaders'
import { NavBar } from 'shop/components'
import { ForgotPassword, ResetPassword } from 'shop/components/Password'

export const PasswordWrapper = () => {
  return (
    <AppContentLoader>
      {(isParentLoaded) => (
        <LoaderSwitcher isParentLoaded={isParentLoaded}>
          <>
            <NavBar transparent={false} />
            <Switch>
              <Route path='/forgot-password' component={ForgotPassword} />
              <Route path='/reset-password/:token' component={ResetPassword} />
            </Switch>
          </>
        </LoaderSwitcher>
      )}
    </AppContentLoader>
  )
}
