import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { DeliverySummary } from 'shop/components'

export const ShopHeader = () => {
  return (
    <Root>
      <DeliverySummary />
    </Root>
  )
}

const Root = styled.div<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  padding: '0px 16px',

  [theme.mediaQueries.viewport7]: {
    paddingBottom: '24px',
    marginBottom: 0,
    padding: '0'
  }
}))
