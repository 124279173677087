import { useState, createContext, useContext } from 'react'
import { useReactRouter } from '..'

type ShopPageState = {
  isSearching: boolean
  searchTerm: string
  isAutoScrolling: boolean
  handleAutoScrolling: () => void
  handleSearch: (input: string) => void
}

export const SetupShopPageStore = (): ShopPageState => {
  const [isAutoScrolling, setIsAutoScrolling] = useState(false)
  const [autoScrollTimeout, setAutoScrollTimeout] = useState<null | ReturnType<
    typeof setTimeout
  >>(null)

  const handleAutoScrolling = () => {
    if (autoScrollTimeout) {
      clearTimeout(autoScrollTimeout)
    }

    setIsAutoScrolling(true)

    const scrollDuration = 700
    const timeout = setTimeout(() => setIsAutoScrolling(false), scrollDuration)
    setAutoScrollTimeout(timeout)
  }

  const { history } = useReactRouter()

  const [isSearching, setIsSearching] = useState(
    history.location.state ? history.location.state.isSearching || false : false
  )
  const [searchTerm, setSearchTerm] = useState(
    history.location.state ? history.location.state.searchTerm || '' : ''
  )

  const handleSearch = (input: string) => {
    if (input.length >= 3) {
      setIsSearching(input !== '')
      setSearchTerm(input)
      return
    }
    setIsSearching(false)
    setSearchTerm('')
  }

  return {
    isSearching,
    searchTerm,
    isAutoScrolling,
    handleAutoScrolling,
    handleSearch
  }
}

const initialState: ShopPageState = {
  isSearching: false,
  searchTerm: '',
  isAutoScrolling: false,
  handleAutoScrolling: () => {},
  handleSearch: () => {}
}

export const ShopPageContext = createContext<ShopPageState>(initialState)

export const useShopPage = () => {
  return useContext(ShopPageContext)
}
