import styled from '@emotion/styled'

export const Medium = styled.b(() => ({
  fontWeight: 500
}))

export const Semibold = styled.b(() => ({
  fontWeight: 600
}))

export const Bold = styled.b(() => ({
  fontWeight: 700
}))
