import styled from '@emotion/styled'
import { Fragment } from 'react'
import { UseFormMethods } from 'react-hook-form'

type Props = {
  register: UseFormMethods['register']
  isVisible: boolean
}

const CustomerOptIn = ({ register, isVisible }: Props) => {
  return (
    <Fragment>
      <input
        type='checkbox'
        id='customer_details_disclosure'
        ref={register}
        name='details_disclosed'
        hidden={!isVisible}
      />
      <Container hidden={!isVisible}>
        <Label htmlFor='customer_details_disclosure'>
          I would like to opt in to receive exclusive offers, discounts and
          news.
        </Label>
      </Container>
    </Fragment>
  )
}

const Label = styled.label(({ theme }: any) => ({
  fontWeight: theme.fontWeights.bold,
  fontSize: theme.fontSizes[0],
  marginLeft: '4px'
}))

const Container = styled.div<
  React.HTMLAttributes<HTMLDivElement> & { hidden: boolean }
>(({ hidden }) => ({
  visibility: hidden ? 'hidden' : 'visible',
  height: hidden ? '0' : 'auto',
  display: hidden ? 'none' : 'initial'
}))

export default CustomerOptIn
