import styled from '@emotion/styled'
import {
  MdErrorOutline as ErrorIcon,
  MdInfoOutline as InfoIcon,
  MdCheckCircle as CheckIcon
} from 'react-icons/md'
import { TiWarningOutline as WarningIcon } from 'react-icons/ti'
import { stateColors } from 'shop/theme/defaultTheme'

type Positions = 'top' | 'bottom'

interface Props {
  heading: string
  subheading?: string
  skin?: 'regular' | 'mild'
  type?: 'warning' | 'error' | 'info' | 'success'
  flash?: boolean
  position?: Positions | { desktop: Positions; mobile: Positions }
}

const AlertMessage = ({
  heading,
  subheading,
  type = 'warning',
  skin = 'regular',
  flash,
  position = 'top'
}: Props) => {
  return (
    <Container {...{ type, flash, position, skin }} data-testid='alertMessage'>
      <Icon {...{ type, skin }}>
        {type === 'warning' && <WarningIcon />}
        {type === 'error' && <ErrorIcon />}
        {type === 'success' && <CheckIcon />}
        {type === 'info' && <InfoIcon />}
      </Icon>

      <Text {...{ skin }}>
        <strong>{heading}</strong>
        {subheading && <p>{subheading}</p>}
      </Text>
    </Container>
  )
}

// Cant make it work :c
// @ts-ignore-next-line
const Container = styled.div(({ theme, type, flash, position, skin }: any) => {
  const props = {
    lineHeight: '1em',
    marginBottom: '16px',
    display: 'flex'
  }

  const skinStyles = {
    regular: {
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
      borderRadius: '4px',
      padding: '16px',
      borderLeft: `6px solid ${stateColors[type][5]}`
    },
    mild: {
      backgroundColor: '#fafafa',
      border: '1px solid #f0f0f0',
      borderRadius: '12px',
      padding: '8px 16px'
    }
  }

  const flashStyles = {
    boxShadow: '0px 2px 24px rgba(0, 0, 0, 0.2)',
    padding: '8px',
    color: 'white',
    zIndex: 5000,
    position: 'fixed',
    background: 'white',
    top: 'unset',
    bottom: 'unset',
    [position?.mobile || position]: '24px',
    marginBottom: 0,
    left: '8px',
    right: '8px',
    [theme.mediaQueries.viewport7]: {
      top: 'unset',
      bottom: 'unset',
      right: 'unset',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '400px',
      [position?.desktop || position]: '16px',
      padding: '16px'
    }
  }

  return {
    ...props,
    ...skinStyles[skin],
    ...(flash ? flashStyles : {})
  }
})

const Icon = styled.div(({ theme, type, skin }: any) => {
  const skinStyles = {
    regular: {
      color: stateColors[type] ? stateColors[type][7] : 'black'
    },
    mild: {
      color: '#262626'
    }
  }

  const props = {
    fontSize: theme.fontSizes[5],
    marginRight: '12px'
  }

  return {
    ...props,
    ...skinStyles[skin]
  }
})

const Text = styled.div(({ theme, skin }: any) => ({
  color: skin === 'regular' ? theme.colors.textBody : '#4a4a68',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  fontSize: theme.fontSizes[0],
  '> p': {
    marginTop: '4px',
    marginBottom: 0
  },
  '*:only-child': {
    margin: 0
  }
}))

export default AlertMessage
