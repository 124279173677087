import { SplitBillPayor } from '../Checkout/types'
import { drop } from 'lodash'
import Decimal from 'decimal.js'
import { parsePhoneNumber } from 'libphonenumber-js'

export const totalSplitAmount = (payors: SplitBillPayor[]) => {
  return payors.reduce((a, b) => {
    const aD = new Decimal(a)
    const bD = new Decimal(b.amount)
    return aD.add(bD).toDecimalPlaces(2).toNumber()
  }, 0)
}

export const rebalanceAmount = (
  originalAmount: number,
  payors: SplitBillPayor[],
  index: number,
  amount: number
) => {
  const newPayors = payors
  const newAmount = new Decimal(amount).toDecimalPlaces(2).toNumber()

  // Make sure we're only assigning valid amounts
  if (newAmount > 0) {
    newPayors[index]['amount'] = newAmount
  } else {
    return { rebalancedPayors: payors, updated: false }
  }
  // Compute non-creator total so we can retrieve the difference
  const payorsWithoutCreator = drop(newPayors)

  const originalAmountAsDecimal = new Decimal(originalAmount)

  const totalSplitAmountAsDecimal = new Decimal(
    totalSplitAmount(payorsWithoutCreator)
  )

  // Get the difference
  const difference = originalAmountAsDecimal.minus(totalSplitAmountAsDecimal)

  const newCreatorAmount = difference.toDecimalPlaces(2).toNumber()

  // Reassign the difference to the creator
  // The new total should be higher than 0 otherwise just return the previous array
  if (newCreatorAmount > 0) {
    newPayors[0]['amount'] = newCreatorAmount
    return { rebalancedPayors: newPayors, updated: true }
  } else {
    return { rebalancedPayors: payors, updated: false }
  }
}

const formatContactNumber = (contactNum: string) => {
  try {
    const parsedNumber = parsePhoneNumber(contactNum, 'GB')
    if (!parsedNumber || !parsedNumber.number) throw Error
    return parsedNumber.number
  } catch (error) {
    console.error(error, { contactNum })
    return contactNum
  }
}

export const buildWhatsAppLink = (contactNum: string, body: string) => {
  const formattedContactNumber = formatContactNumber(contactNum)

  return `https://wa.me/${formattedContactNumber}?text=${body}`
}
