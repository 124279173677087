import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { BsChevronLeft as BackArrow } from 'react-icons/bs'

const OverlayHeader = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    padding: '20px 12px 20px',
    alignItems: 'center',
    borderBottom: 'solid 2px #F0F0F0',
    position: 'sticky',
    zIndex: 1,
    top: 0,
    backgroundColor: 'white',
    [theme.mediaQueries.viewport7]: {
      padding: '20px 28px 20px'
    }
  })
)

const OverlayTitle = styled.h1<StyledHTMLElement>(() => ({
  fontSize: '20px',
  fontWeight: 600,
  margin: 'auto'
}))

const OverlayInputContainer = styled.div<StyledHTMLElement>(() => ({
  marginLeft: '24px',
  width: '100%'
}))

/** Common JSX for overlays with title in overlay header */
export const CommonOverlayTitleHeader = ({
  modalTitle,
  onBackClick
}: {
  modalTitle: string
  onBackClick: (event: any) => void
}): JSX.Element => (
  <OverlayHeader>
    <OverlayHeaderIconContainer onClick={onBackClick}>
      <BackArrow />
    </OverlayHeaderIconContainer>
    <OverlayTitle>{modalTitle}</OverlayTitle>
  </OverlayHeader>
)

/** Common JSX for overlays with an input in overlay header */
export const CommonOverlayInputHeader = ({
  children,
  onBackClick
}: {
  children: JSX.Element
  onBackClick: (event: any) => void
}): JSX.Element => (
  <OverlayHeader>
    <IconContainer onClick={onBackClick}>
      <BackArrow />
    </IconContainer>
    <OverlayInputContainer>{children}</OverlayInputContainer>
  </OverlayHeader>
)
export const IconContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
    padding: '4px',
    '> svg': {
      width: '16px',
      [theme.mediaQueries.viewport7]: {
        width: '14px'
      }
    }
  })
)

const OverlayHeaderIconContainer = styled(IconContainer)<StyledHTMLElement>(
  () => ({
    position: 'absolute'
  })
)

export const ListItem = styled.li<
  StyledHTMLElement & {
    disabled?: boolean
    showFulfillmentWindow?: boolean
    isStoreList?: boolean
  },
  Required<Theme>
>(({ disabled, showFulfillmentWindow, isStoreList, theme }) => ({
  listStyleType: 'none',
  position: showFulfillmentWindow ? 'relative' : 'initial',
  cursor: 'pointer',
  padding: showFulfillmentWindow ? '16px 20px 4px' : '16px 20px',
  margin: 0,
  fontSize: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '&:hover': {
    background: '#f1f1f1'
  },
  '&:active': {
    fontWeight: 500,
    color: '#096DD9'
  },
  color: disabled ? '#AEB4C2' : 'black',
  pointerEvents: disabled ? 'none' : 'auto',
  [theme.mediaQueries.viewport7]: {
    padding: showFulfillmentWindow
      ? '16px 32px 4px'
      : isStoreList
        ? '16px 32px 0'
        : '16px 32px'
  }
}))

export const LineBreak = styled.hr<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    backgroundColor: '#F0F0F0',
    border: '0',
    height: '1px',
    margin: '0 20px',
    [theme.mediaQueries.viewport7]: {
      margin: '0px 32px'
    }
  })
)

export const ListItemWrapper = styled.div<StyledHTMLElement>(() => ({
  cursor: 'pointer',
  '&:hover': {
    background: '#f1f1f1'
  },
  '&:active': {
    fontWeight: 500,
    color: '#096DD9'
  },
  '&:last-of-type': {
    '> hr': {
      display: 'none'
    }
  }
}))

export const DeliveryFormHeading = styled.h2<
  StyledHTMLElement,
  Required<Theme>
>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: '16px 0 8px',
  fontWeight: 'normal',
  fontSize: '20px',

  '> span': {
    flexGrow: 1,
    fontSize: '16px',
    color: '#666666',
    fontWeight: 500
  },

  [theme.mediaQueries.viewport7]: {
    fontWeight: 'bold'
  }
}))

export const ListItemIconContainer = styled(IconContainer)<
  StyledHTMLElement & { showFulfillmentWindow?: boolean },
  Required<Theme>
>(({ showFulfillmentWindow, theme }) => ({
  position: showFulfillmentWindow ? 'absolute' : 'initial',
  top: showFulfillmentWindow ? '60%' : 'unset',
  right: showFulfillmentWindow ? '16px' : 'initial',
  display: 'flex',
  cursor: 'pointer',
  alignSelf: 'end',
  [theme.mediaQueries.viewport7]: {
    right: showFulfillmentWindow ? '28px' : 'initial'
  },
  '> svg': {
    width: '16px',
    [theme.mediaQueries.viewport7]: {
      width: '14px'
    }
  }
}))
