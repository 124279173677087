import React, { useState, useEffect, useCallback, forwardRef } from 'react'
import styled from '@emotion/styled'
import { IoIosSearch, IoIosCloseCircleOutline } from 'react-icons/io'
import {
  TrackableEvent,
  trackUserActionsFBPixel,
  trackUserActionsGA4
} from 'tracker'
import { useShopPage } from 'shop/hooks'

//ability to override context for backwards compatibility
//should go away as soon as the Menu page is deprecated
interface SearchBarProps {
  isSearching?: boolean
  handleSearch?: (input: string) => void
}

const SearchBar = forwardRef<HTMLInputElement, SearchBarProps>(
  (
    { isSearching: isParentSearching, handleSearch: handleParentSearch },
    ref
  ) => {
    const { handleSearch, isSearching: isContextSearching } = useShopPage()

    const isSearching =
      typeof isParentSearching === 'undefined'
        ? isContextSearching
        : isParentSearching

    const onSearch = (e: React.ChangeEvent<HTMLInputElement> | null) => {
      const input = e?.target?.value || ''

      setSearchValue(input)

      if (handleParentSearch) {
        return handleParentSearch(input)
      }

      handleSearch(input)
    }

    const [searchValue, setSearchValue] = useState('')

    const track = useCallback(() => {
      setTimeout(() => {
        if (!!searchValue.length) {
          const trackable = {
            content_name: searchValue,
            content_type: 'product'
          }
          const body = {
            category: 'Product',
            action: TrackableEvent.ProductsSearched,
            label: searchValue
          }

          trackUserActionsGA4(body, 'slerpGA4Tracking')

          // legacy tracking
          trackUserActionsFBPixel('Search', trackable)
          trackUserActionsGA4(body, 'merchantGA4Tracking')
        }
      }, 2000)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue])

    useEffect(() => {
      track()
    }, [track])

    return (
      <InputContainer id='searchBar'>
        <SearchBarInput
          value={searchValue}
          placeholder='Search for an item'
          onChange={onSearch}
          ref={ref}
        />
        <IconContainer>
          <IoIosSearch />
        </IconContainer>
        {isSearching && (
          <IconContainer position='right'>
            <IoIosCloseCircleOutline
              cursor={'pointer'}
              onClick={() => {
                onSearch(null)
              }}
            />
          </IconContainer>
        )}
      </InputContainer>
    )
  }
)

const SearchBarInput = styled.input(({ theme }: any) => ({
  width: '100%',
  height: '36px',
  fontSize: theme.fontSizes[2],
  fontWeight: theme.fontWeights.light,
  outline: 0,
  border: `1px solid #D9D9D9`,
  borderRadius: `10px`,
  paddingLeft: '40px',
  paddingRight: '24px',
  '&:focus': {
    border: '1px solid #59595A'
  }
}))

const InputContainer = styled.div(() => ({
  position: 'relative',
  height: 'fit-content',
  width: '100%',

  svg: {
    fill: '#59595A'
  },

  '&:focus-within': {
    svg: {
      fill: '#59595A'
    }
  }
}))

const IconContainer = styled.div(
  ({ position = 'left' }: { position?: 'left' | 'right' }) => ({
    margin: 0,
    width: '20px',
    height: '20px',
    position: 'absolute',
    right: position === 'right' ? '12px' : 'auto',
    left: position === 'right' ? 'auto' : '12px',
    top: '50%',
    bottom: 0,
    transform: 'translateY(-50%)',

    '>svg': {
      width: '100%',
      height: '100%'
    }
  })
)

export default SearchBar
