import styled from '@emotion/styled'
export const ProductsContainer = styled.ul(({ theme }: any) => ({
  margin: '0',
  fontSize: theme.fontSizes[0],
  listStyleType: 'none',
  backgroundColor: 'white',
  width: '100%',
  ':not(:last-of-type)': {
    marginBottom: '32px'
  }
}))

export const List = styled.ul(({ theme }: any) => ({
  fontSize: theme.fontSizes[0],
  listStyleType: 'none',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '16px',
  marginTop: '16px',
  marginBottom: '48px',

  [theme.mediaQueries.viewport5]: {
    fontSize: theme.fontSizes[0]
  },
  [theme.mediaQueries.viewport7]: {
    marginBottom: 0
  },
  [theme.mediaQueries.viewport10]: {
    gridTemplateColumns: 'repeat(3, 1fr)'
  },
  [theme.mediaQueries.viewport14]: {
    gridTemplateColumns: 'repeat(4, 1fr)'
  },
  [theme.mediaQueries.viewport16]: {
    gap: '32px'
  }
}))

export const CategoryTitle = styled.div(({ theme }: any) => {
  return {
    fontWeight: 'bold',
    fontFamily: theme.fonts.heading.family,
    fontSize: theme.fontSizes[3],
    marginBottom: '4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',

    [theme.mediaQueries.viewport7]: {
      fontSize: theme.fontSizes[5]
    }
  }
})

export const CategoryDescription = styled.div(({ theme }: any) => {
  return {
    fontFamily: theme.fonts.heading.family,
    fontSize: theme.fontSizes[1]
  }
})
