import styled from '@emotion/styled'

const Action = ({
  icon,
  label,
  color,
  variant
}: {
  icon: React.ReactElement
  label: string
  color?: string
  variant?: string
}) => {
  return (
    <Container variant={variant}>
      <Icon color='white'>{icon}</Icon>
      <Label>{label}</Label>
    </Container>
  )
}

const Icon = styled.i(({ color }: { color?: string }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: color || '#f1f1f1',
  fontSize: '22px',
  borderRadius: '4px',
  height: '44px',
  width: '44px',
  transition: 'all 100ms ease-out'
}))

const Label = styled.p(() => ({
  margin: '4px 0 0'
}))

const Container = styled.div(({ theme, variant }: any) => ({
  textAlign: 'center',
  color: 'black',
  fontDecoration: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '40px',
  transition: 'all 100ms ease-out',
  fontSize: '10px',
  '&:hover > i': { backgroundColor: theme.colors.primary, color: 'white' },
  '&': variant === 'large' && {
    '> i': {
      backgroundColor: 'transparent',
      width: 'auto',
      fontSize: theme.fontSizes[2]
    },
    '&:hover': {
      backgroundColor: theme.colors.primary,
      color: 'white',
      '> i': { backgroundColor: 'transparent' }
    },
    '> p': { margin: '0 0 0 8px' },
    fontSize: theme.fontSizes[0],
    padding: '0 16px',
    borderRadius: '4px',
    flexDirection: 'row',
    backgroundColor: '#f1f1f1'
  }
}))

export default Action
