import gql from 'graphql-tag'
import { FulfillmentType } from 'shop/types'

const CategoryFragment = `
    id
    name
    description
`

const ProductFragment = `
    id
    defaultVariantId
    image {
        original
        standard
        thumb
    }
    inStock
    name
    minPrice {
        basePrice
    }
    maxPrice {
        basePrice
    }
    quickAddAllowed
    limit
    description
    restrictions {
        alcoholic
    }
    slug
    variants {
      id
      inStock
      name
      options_allergens
      extraPrice {
        basePrice
      }
      price {
        basePrice
      }
      restrictions {
        alcoholic
      }
      limit
      options {
        name
        values
      }
    }
`

export type GetCategoriesVariables = {
  fulfillmentDatetime?: string | null
  fulfillmentType?: FulfillmentType
  storeSlug?: String
  storeId?: String
}

export const QUERY_GET_STORE_CATEGORIES = gql`
    query getCategories(
        $fulfillmentDatetime: utc_timestamp,
        $fulfillmentType: FulfillmentType,
        $storeSlug: String,
        $storeId: uuid
    ) {
        getCategories(
            fulfillmentDatetime: $fulfillmentDatetime,
            fulfillmentType: $fulfillmentType,
            storeSlug: $storeSlug,
            storeId: $storeId
        ) {
            ${CategoryFragment}
            products {
                ${ProductFragment}
            }
        }
    }
`
