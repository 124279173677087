import { Fragment, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import ProgressBar from 'shop/components/ProgressBar/ProgressBar'
import { loyaltyCardImageUrl, hideLoyaltyCard } from './utils'
import { MdCheckCircle as CheckIcon } from 'react-icons/md'
import { IoMdClose as CloseIcon } from 'react-icons/io'
import { format } from 'date-fns'

type Props = {
  loyaltyCards: StampCard[]
  merchantSlug: string
}

export interface CustomerReward {
  archived_at: string | null
  redeemed_at: string | null
  end_datetime: string | null
  id: string
}

export interface StampCard {
  customer_card_id?: string
  redeemed?: boolean | null
  stamps_aggregate?: {
    aggregate: {
      count: number
    }
  }
  customer_rewards?: CustomerReward[]
  loyalty_card: LoyaltyCard
}
export interface LoyaltyCard {
  loyalty_card_id: string
  store_ids: string[]
  name: string
  description: string
  image: string
  theme: string
  stamp_label: string
  starts_at: string
  ends_at: string
  rewards: {
    description: string
    name: string
    required_quantity: number
  }[]
  published: boolean | null
}

const StampCardList = ({ loyaltyCards, merchantSlug }: Props) => {
  const [enlargeCard, setEnlargeCard] = useState('')

  // Lock screen scroll whilst card is enlarged
  useEffect(() => {
    if (enlargeCard) {
      document.body.style.overflow = 'hidden'
      return
    }
    document.body.style.overflow = 'visible'
  }, [enlargeCard])

  const handleCardClick = (cardId: string) => {
    setEnlargeCard(enlargeCard === cardId ? '' : cardId)
  }

  const StampCardProgressBar = ({
    amountCollected,
    requiredQuantity
  }: {
    amountCollected: number
    requiredQuantity: number
  }) => (
    <>
      <ProgressBar
        stepCount={requiredQuantity}
        stepsCompleted={amountCollected}
        splitSteps={false}
        roundCorners={true}
        withColor={true}
      />
    </>
  )

  const RedeemAtCheckoutLabel = () => (
    <RedeemLabel>
      <CheckIcon />
      <h1>Redeem on checkout</h1>
    </RedeemLabel>
  )

  return (
    <Container data-testid='loyalty-card-grid'>
      <GridContainer>
        {loyaltyCards.map(
          ({
            stamps_aggregate,
            customer_rewards,
            loyalty_card: loyaltyCard,
            customer_card_id
          }) => {
            // if it's an existing card it will have a customer_card_id
            const cardId = customer_card_id || loyaltyCard.loyalty_card_id
            const amountCollected = stamps_aggregate?.aggregate.count || 0
            const rewards = [...loyaltyCard.rewards].sort(
              (rewardA, rewardB) =>
                rewardA.required_quantity - rewardB.required_quantity
            )
            const totalStamps = rewards[rewards.length - 1].required_quantity
            if (
              hideLoyaltyCard({
                stamps_aggregate,
                customer_rewards,
                loyalty_card: loyaltyCard
              })
            )
              return <Fragment key={cardId} />
            const isEnlargedCard: boolean = enlargeCard === cardId
            return (
              <StampCardContainer
                id={cardId}
                key={cardId}
                onClick={() => handleCardClick(cardId)}
                {...{
                  enlargeCard: isEnlargedCard,
                  cardId: cardId
                }}
              >
                <StampCardSummary {...{ enlargeCard: isEnlargedCard }}>
                  <StampCardIcon>
                    <img
                      src={loyaltyCardImageUrl(
                        loyaltyCard.image,
                        loyaltyCard.loyalty_card_id
                      )}
                      alt={`${loyaltyCard.name}`}
                      loading='lazy'
                    />
                  </StampCardIcon>
                  <StampCardHeading>{loyaltyCard.name}</StampCardHeading>
                  <StampCardSubHeading>
                    {rewards.length > 1 ? 'View all rewards' : rewards[0].name}
                  </StampCardSubHeading>
                  <StampCardProgressContainer>
                    {rewards.length === 1 &&
                    amountCollected >= rewards[0].required_quantity ? (
                      <RedeemAtCheckoutLabel />
                    ) : (
                      <ProgressBarContainer
                        columns={totalStamps}
                        templateColumns={rewards.reduce(
                          (acc, reward, index) => {
                            return `${acc} ${
                              reward.required_quantity -
                              (rewards[index - 1]?.required_quantity || 0)
                            }fr`
                          },
                          ''
                        )}
                      >
                        {rewards.map((reward, index) => {
                          const prevQuantity =
                            rewards[index - 1]?.required_quantity || 0
                          return (
                            <StampCardProgressBar
                              key={`${reward.name}-progress-bar-${index}`}
                              amountCollected={amountCollected - prevQuantity}
                              requiredQuantity={
                                reward.required_quantity - prevQuantity
                              }
                            />
                          )
                        })}
                      </ProgressBarContainer>
                    )}
                    <p className='more-details'>
                      {amountCollected} out of {totalStamps} stamps
                    </p>
                  </StampCardProgressContainer>
                </StampCardSummary>
                <StampCardInfo {...{ enlargeCard: isEnlargedCard }}>
                  <StampCardHeading>{loyaltyCard.name}</StampCardHeading>
                  <CloseIcon />
                  <p>
                    {loyaltyCard.description}
                    {loyaltyCard.ends_at && (
                      <span>
                        {`exp: ${format(
                          new Date(loyaltyCard.ends_at),
                          'dd.MM.yyyy'
                        )}`}
                      </span>
                    )}
                  </p>
                </StampCardInfo>
              </StampCardContainer>
            )
          }
        )}
      </GridContainer>
    </Container>
  )
}

const ProgressBarContainer = styled.div(
  ({
    columns,
    templateColumns
  }: {
    columns: number
    templateColumns: string
  }) => ({
    display: 'grid',
    gridColumn: columns,
    gridTemplateColumns: templateColumns,
    gridGap: '8px'
  })
)

const Container = styled.div(({ theme }: any) => ({
  display: 'flex',
  width: '100%'
}))

const GridContainer = styled.div(({ theme }: any) => ({
  backgroundColor: '#f5f5f5',
  padding: '16px',
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridAutoRows: 'auto',
  gap: '16px',

  [theme.mediaQueries.viewport7]: {
    gridTemplateColumns: 'repeat(3, 1fr)'
  }
}))

// CSS logic: enlarged cards to be centralised in the window
const calculateMiddleScreenTranslate = (cardId: string): string => {
  const domRect = document.getElementById(cardId)?.getBoundingClientRect()
  if (domRect) {
    const { top, left, height, width } = domRect
    const { innerHeight, innerWidth } = window
    const translateY = innerHeight / 2 - top - height / 2
    const translateX = innerWidth / 2 - left - width / 2
    return `${translateX}px, ${translateY}px`
  }
  return ''
}
const StampCardContainer = styled.div(
  ({ theme, enlargeCard, cardId }: any) => ({
    height: '100%',
    width: '100%',
    backgroundColor: '#fff',
    transition: 'all .2s ease-in-out',

    '&:hover': {
      boxShadow: !enlargeCard && theme.shadows.large,
      cursor: 'pointer'
    },

    // Enlarge the card
    boxShadow: enlargeCard ? '0 0 0 999999px rgba(0,0,0, 0.5)' : 'unset',
    transform: enlargeCard
      ? `translate(${calculateMiddleScreenTranslate(cardId)})`
      : 'unset'
  })
)

const StampCardSummary = styled.div(({ theme, enlargeCard }: any) => ({
  display: enlargeCard ? 'none' : 'flex',
  flexDirection: 'column'
}))

const StampCardInfo = styled.div(({ theme, enlargeCard }: any) => ({
  display: enlargeCard ? 'flex' : 'none',
  flexDirection: 'column',
  margin: '14px 15px',
  minHeight: '200px',

  '& h1': {
    paddingBottom: '12px',
    paddingRight: '30px',
    alignSelf: 'start',
    width: '100%',
    borderBottom: '1px solid #F5F5F5'
  },

  '& p': {
    marginTop: '12px',
    fontSize: '14px',
    fontWeight: 400,
    alignSelf: 'start',

    '& >span': {
      display: 'block',
      marginTop: '10px',
      color: '#8C8C8C'
    }
  },

  '& svg': {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: '10px',
    height: '20px',
    width: '20px'
  }
}))

const StampCardIcon = styled.div(({ theme }: any) => ({
  padding: '16px 0',
  alignSelf: 'center',

  '& >img': {
    height: '50px',
    width: '50px',
    backgroundColor: '#f5f5f5',
    objectFit: 'cover',
    borderRadius: '100%'
  }
}))

const StampCardHeading = styled.h1(({ theme }: any) => ({
  fontSize: '14px',
  alignSelf: 'center',
  textTransform: 'uppercase',
  fontWeight: 500,
  textAlign: 'center',
  padding: '0 10px'
}))

const StampCardSubHeading = styled.h1(({ theme }: any) => ({
  marginTop: '6px',
  marginBottom: '10px',
  fontSize: '14px',
  alignSelf: 'center',
  fontWeight: 300,
  textAlign: 'center',
  padding: '0 10px'
}))

const RedeemLabel = styled.div(({ theme }: any) => ({
  width: '100%',
  marginTop: '6px',
  marginBottom: '10px',
  display: 'inline-flex',
  verticalAlign: 'middle',
  alignItems: 'center',
  justifyContent: 'center',

  '& >h1': {
    fontSize: '10px',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: theme.colors['primary']
  },

  '& >svg': {
    width: '20px',
    height: '20px',
    fill: theme.colors['primary'],
    marginRight: '10px'
  }
}))

const StampCardProgressContainer = styled.div(({ theme }: any) => ({
  width: '100%',
  padding: '16px 10px',
  fontSize: '12px',
  '& >p': {
    textAlign: 'center'
  },
  '& .more-details': {
    textAlign: 'center'
  },
  '& >button': {
    height: '36px',
    textTransform: 'uppercase'
  }
}))

export default StampCardList
