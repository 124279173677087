import gql from 'graphql-tag'

export const QUERY_GET_MERCHANT = gql`
  query getMerchantBySlug($slug: String!) {
    merchants(where: { slug: { _eq: $slug } }) {
      id
      name
      description
      slug
      currency
      for_testing
      welcome_message: setting(path: "welcome_message")
      welcome_message_header: setting(path: "welcome_message_header")
      promotional_banner: setting(path: "promotional_banner")
      closed_store_message: setting(path: "closed_store_message")
      custom_checkout_icon_code: setting(path: "custom_checkout_icon_code")
      custom_checkout_message: setting(path: "custom_checkout_message")
      delivery_note_placeholder: setting(path: "delivery_note_placeholder")
      gift_wrap_enabled: setting(path: "gift_wrap_enabled")
      gift_wrap_text: setting(path: "gift_wrap_text")
      gift_wrap_price: setting(path: "gift_wrap_price")
      gift_wrap_placeholder: setting(path: "gift_wrap_placeholder")
      order_custom_field: setting(path: "order_custom_field")
      order_custom_field_mandatory: setting(
        path: "order_custom_field_mandatory"
      )
      order_custom_field_apply_for: setting(
        path: "order_custom_field_apply_for"
      )
      order_custom_field_name: setting(path: "order_custom_field_name")
      order_custom_field_placeholder: setting(
        path: "order_custom_field_placeholder"
      )
      order_note_placeholder: setting(path: "order_note_placeholder")
      tag_manager_id: setting(path: "tag_manager_id")
      google_analytics_4_id: setting(path: "google_analytics_4_id")
      pixel_id: setting(path: "pixel_id")
      pickup_address_enabled: setting(path: "pickup_address_enabled")
      google_ads_id: setting(path: "google_ads_id")
      google_conversion_id: setting(path: "google_conversion_id")
    }
    stores(
      where: {
        archived_at: { _is_null: true }
        merchant: { slug: { _eq: $slug } }
      }
    ) {
      id
      name
      slug
      is_open
      address {
        line_1
        line_2
        city
        zip
        geom
      }
      archived_at
      cutoffs
      special_cutoffs
      settings
      split_hours
      pre_order_settings {
        cutoffs
        delivery_courier_partner
        delivery_enabled
        delivery_interval
        delivery_radius
        delivery_schedule
        delivery_times
        delivery_zone
        fixed_courier_fee_enabled
        fixed_delivery_times_enabled
        fixed_delivery_times_order_limit
        delivery_timeslots_order_limit
        pre_order_day_allowance
        pickup_times
        pickup_schedule
        pickup_interval
        pickup_enabled
        pickup_timeslots_order_limit
        special_cutoffs_map
        delivery_area_setting
      }
    }
  }
`
export const QUERY_GET_MERCHANT_STORE = gql`
  query getMerchantAndStoreBySlug($merchant: String!, $slug: String!) {
    merchants(where: { slug: { _eq: $merchant } }) {
      id
      name
      description
      slug
      welcome_message: setting(path: "welcome_message")
      welcome_message_header: setting(path: "welcome_message_header")
      promotional_banner: setting(path: "promotional_banner")
      closed_store_message: setting(path: "closed_store_message")
      order_note_placeholder: setting(path: "order_note_placeholder")
      delivery_note_placeholder: setting(path: "delivery_note_placeholder")
      gift_wrap_enabled: setting(path: "gift_wrap_enabled")
      gift_wrap_text: setting(path: "gift_wrap_text")
      gift_wrap_price: setting(path: "gift_wrap_price")
      gift_wrap_placeholder: setting(path: "gift_wrap_placeholder")
      order_custom_field: setting(path: "order_custom_field")
      order_custom_field_mandatory: setting(
        path: "order_custom_field_mandatory"
      )
      order_custom_field_apply_for: setting(
        path: "order_custom_field_apply_for"
      )
      order_custom_field_name: setting(path: "order_custom_field_name")
      order_custom_field_placeholder: setting(
        path: "order_custom_field_placeholder"
      )
      tag_manager_id: setting(path: "tag_manager_id")
      pixel_id: setting(path: "pixel_id")
      stores(where: { slug: { _eq: $slug }, archived_at: { _is_null: true } }) {
        id
        name
        slug
        is_open
        address {
          line_1
          line_2
          city
          zip
          geom
        }
        archived_at
        cutoffs
        special_cutoffs
        settings
        split_hours
      }
    }
  }
`
