import {
  TrackableEvent,
  trackUserActionsFBPixel,
  trackUserActionsGA4
} from 'tracker'
import { useModal, useShop } from './useGlobalContext'
import { useCallback, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import {
  MUTATE_CREATE_CUSTOMER,
  MUTATE_LOGIN,
  MUTATE_LOGOUT
} from 'shop/client'
import { createClient, QUERY_CUSTOMER } from 'shop/client'
import { removeLoginCredentials } from 'shop/utils'
import {
  merchantGA4EcommTrackLogin,
  merchantGA4EcommTrackSignUp,
  slerpGA4EcommTrackLogin,
  slerpGA4EcommTrackSignUp
} from 'tracker/GA/ecommerce'
import { useSessionStorage } from './useSessionStorage'
import { CheckoutForm } from 'shop/components/Checkout/types'
import { useQueryParams } from './useQueryParams'
import { getErrorMessage, isNetworkErrorUnauthorized } from 'shop/utils/common'
interface LoginParams {
  email: string
  password: string
}

export interface CustomerErrors {
  message: string
}

export interface CustomerWarnings {
  message: string
}

export interface LoginResponse {
  login: {
    customer: Login
    errors: CustomerErrors[]
    warnings: CustomerWarnings[]
  }
}

export interface Login {
  apiKey: string
  email: string
  firstName: string
  id: string
  lastName: string
}

export interface LogoutResponse {
  logout: boolean
}

interface CreateCustomerParams {
  merchantId?: string
  firstName: string
  lastName: string
  email: string
  password: string
  contactNum: string
  marketingOptIn: boolean
}
export interface CreateCustomerResponse {
  createCustomer: {
    customer: {
      id: string
      firstName: string
      lastName: string
      email: string
      apiKey: string
    }
    errors: CustomerErrors[]
    warnings: CustomerWarnings[]
  }
}

export type CustomerDetails = {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  marketingOptIn: boolean
}

/** hook for handling common account related tasks such as login/logout */
export const useAccount = () => {
  const {
    setCustomerId,
    setCustomerApiKey,
    setCustomerDetails,
    config,
    merchant
  } = useShop()

  const { removeSessionStorageItem: removeCheckoutFormSessionStorage } =
    useSessionStorage<CheckoutForm>('temp-checkout-form-values')

  const fetchCustomerDetails = (
    customerId: string,
    customerApiKey: string
  ): Promise<CustomerDetails | null> | null => {
    if (!customerApiKey || !customerId) return null
    const customerClient = createClient(
      'merchant',
      customerApiKey,
      merchant?.id || ''
    )
    return customerClient
      .query({
        query: QUERY_CUSTOMER,
        variables: { customerId }
      })
      .then((results) => {
        const { first_name, last_name, email, contact_num, marketing_opt_in } =
          results.data.customers_by_pk
        return {
          firstName: first_name,
          lastName: last_name,
          email,
          phoneNumber: contact_num,
          marketingOptIn: marketing_opt_in
        } as CustomerDetails
      })
      .catch((error) => {
        console.error(error)
        return null
      })
  }

  const [login] = useMutation<LoginResponse>(MUTATE_LOGIN, {
    context: { clientName: 'consumerApi' }
  })
  const [logout] = useMutation<LogoutResponse>(MUTATE_LOGOUT, {
    context: { clientName: 'consumerApi' }
  })

  const [createCustomer] = useMutation<CreateCustomerResponse>(
    MUTATE_CREATE_CUSTOMER,
    {
      context: { clientName: 'consumerApi' }
    }
  )

  const handleLogin = useCallback(
    (params: LoginParams) => {
      return login({ variables: params }).then((res) => {
        if (res.data?.login.customer) {
          const {
            id: customerId,
            firstName,
            lastName,
            email,
            apiKey
          } = res.data?.login.customer
          const trackParams = {
            email,
            firstName,
            lastName
          }
          const body = {
            category: 'User',
            action: TrackableEvent.UserLoggedIn
          }

          slerpGA4EcommTrackLogin({
            method: 'email'
          })

          merchantGA4EcommTrackLogin({
            method: 'email'
          })

          trackUserActionsGA4(body, 'slerpGA4Tracking')

          // legacy tracking
          trackUserActionsFBPixel(TrackableEvent.UserLoggedIn, {
            id: customerId,
            ...trackParams
          })
          trackUserActionsGA4(body, 'merchantGA4Tracking')
          // Set authVersion for tracking purposes
          localStorage.setItem('authVersion', 'consumer')
          localStorage.setItem('customerId', customerId)
          localStorage.setItem('customerApiKey', apiKey)
          setCustomerId(customerId)
          setCustomerApiKey(apiKey)

          // Removes any temp saved checkout form values on user login
          removeCheckoutFormSessionStorage()
        }
        return res
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleLogout = useCallback(() => {
    logout()
      .then((res) => {
        const body = {
          category: 'User',
          action: TrackableEvent.UserLoggedOut
        }
        trackUserActionsGA4(body, 'slerpGA4Tracking')

        // legacy tracking
        trackUserActionsFBPixel(TrackableEvent.UserLoggedOut)
        trackUserActionsGA4(body, 'merchantGA4Tracking')

        removeLoginCredentials(config.domain)
        setCustomerId('')
        setCustomerApiKey('')
        setCustomerDetails(null)

        // Removes any temp saved checkout form values on user logout
        removeCheckoutFormSessionStorage()

        window.location.reload()
        return res
      })
      .catch((error) => {
        const { graphQLErrors, networkError } = error || {}
        const errorMessage = getErrorMessage(graphQLErrors)
        // Temporary: remove customer id/api key/csrf token from local storage even if unauthorized

        if (
          errorMessage === 'unauthorized' ||
          isNetworkErrorUnauthorized(networkError)
        ) {
          clearLoginState()
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSignUp = useCallback((params: CreateCustomerParams) => {
    return createCustomer({ variables: params })
      .then((res) => {
        if (!res.data) {
          return { success: null, error: null }
        }
        if (res.data.createCustomer.errors.length)
          return { success: null, error: res.data.createCustomer.errors }

        const {
          id: customerId,
          firstName,
          lastName,
          email,
          apiKey
        } = res.data.createCustomer.customer

        const trackParams = {
          email,
          firstName,
          lastName
        }
        const body = {
          category: 'User',
          action: TrackableEvent.UserRegistered
        }

        slerpGA4EcommTrackSignUp({
          method: 'email'
        })

        merchantGA4EcommTrackSignUp({
          method: 'email'
        })

        trackUserActionsGA4(body, 'slerpGA4Tracking')

        // legacy tracking
        trackUserActionsFBPixel('CompleteRegistration', {
          id: customerId,
          ...trackParams
        })
        trackUserActionsGA4(body, 'merchantGA4Tracking')

        setCustomerId(customerId)
        setCustomerApiKey(apiKey)
        // Set authVersion for tracking purposes
        localStorage.setItem('authVersion', 'consumer')
        localStorage.setItem('customerId', customerId)
        localStorage.setItem('customerApiKey', apiKey)
        return {
          success: res,
          error: null
        }
      })
      .catch((error) => ({
        success: null,
        error: error
      }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const clearLoginState = () => {
    setCustomerId('')
    setCustomerApiKey('')
    setCustomerDetails(null)
  }

  /** Check whether the user is logged in */
  const isUserLoggedIn = (): boolean => {
    const isLoggedIn = localStorage.getItem('customerId') !== null

    return isLoggedIn
  }

  return {
    handleLogout,
    handleLogin,
    handleSignUp,
    fetchCustomerDetails,
    isUserLoggedIn
  }
}

/** Read Query Params on load and open the appropriate Login Modal. */
export const useQueryParamAccountModal = () => {
  const { hasQueryParam, deleteQueryParam } = useQueryParams()
  const { openModal } = useModal()
  const { isUserLoggedIn } = useAccount()
  const isLoggedIn = isUserLoggedIn()

  // onLoad, check whether to open the LoginModal
  useEffect(() => {
    const openLoginModal = hasQueryParam('sign-up') || hasQueryParam('login')
    if (openLoginModal) {
      // if user is logged in, quietly remove the relevant query params
      if (isLoggedIn) {
        if (hasQueryParam('sign-up')) deleteQueryParam('sign-up')
        if (hasQueryParam('login')) deleteQueryParam('login')
        return
      }
      openModal('login')
    }
  }, [])
}
