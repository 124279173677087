import { CartPayload } from 'shop/components/Cart/types'
import { flatten, toArray } from 'lodash'
import { computeModifiersTotal } from 'shop/components/Cart/utils'
import { priceWithVat } from 'shop/components/Product/utils'
import {
  AppliedModifiers,
  ProductModifierGroup,
  ProductVariant,
  SelectedOption
} from 'shop/components/Product/types'
import { Decimal } from 'decimal.js'

/*
  Computes total for an item
  before adding it to cart
 */
export const total = (cartParams?: CartPayload) => {
  if (!cartParams || cartParams.quantity === 0) return 0

  const { variantPrice, variantVat, quantity } = cartParams
  const modifiers = formatModifiersParams(cartParams.appliedModifiers)
  const variantTotal = priceWithVat(variantPrice, variantVat)
  const modifiersTotal = new Decimal(computeModifiersTotal(modifiers))
  const itemTotal = new Decimal(variantTotal)
    .plus(modifiersTotal)
    .times(quantity)

  return itemTotal.toDecimalPlaces(2, Decimal.ROUND_HALF_DOWN).toNumber()
}

export const totalV2 = (
  initialPrice: number,
  quantity: number,
  appliedModifiers?: AppliedModifiers
) => {
  if (quantity === 0) return 0

  const modifiers = formatModifiersParams(appliedModifiers || {})
  const modifiersTotal = new Decimal(computeModifiersTotal(modifiers))
  const itemTotal = new Decimal(initialPrice)
    .plus(modifiersTotal)
    .times(quantity)

  return itemTotal.toDecimalPlaces(2, Decimal.ROUND_HALF_DOWN).toNumber()
}

export const inCart = (cartParams: CartPayload | undefined) =>
  cartParams && cartParams.id

/*
  transforms
  {
   [group1.id]: {
     [item1.id]: item1
     [item2.id]: item2
   },
   [group2.id]: {
      [item3.id]: item3
    }
  }

  to [item1, item2, item3]
*/
export const formatModifiersParams = (modifiers: any) => {
  return flatten(Object.keys(modifiers).map((key) => toArray(modifiers[key])))
}

export const flattenModifierItemsV2 = (
  modifierGroups?: ProductModifierGroup[]
) => {
  if (!modifierGroups) return []
  return modifierGroups.flatMap(({ modifiers }) => modifiers)
}

export const isMobile = () => window?.innerWidth <= 768

/**
 *
 * @param {React.RefObject<HTMLDivElement>} scrollRef Container of elemement to scroll on
 * @param {string} id Element identifier to scroll to
 * @param {number} offset Shift the scroll by set amount
 */
export const scrollToMissingOption = (
  scrollRef: React.RefObject<HTMLDivElement>,
  id: string,
  offset?: number
) => {
  const element = document.getElementById(id)
  const modalContainer = document.getElementById('product-modal-container')
  const mobile = isMobile()
  const scrollableContainer = mobile ? modalContainer : scrollRef?.current
  if (element && scrollableContainer) {
    const header = document.getElementById('product-modal-header')
    const headerHeight = header?.clientHeight || 0
    const elementPos = element.getBoundingClientRect().top
    const containerScrollPos = scrollableContainer?.scrollTop || 0
    const containerHeight =
      scrollableContainer?.getBoundingClientRect().top || 0
    // set scroll pos withint scrollable container so element is top minus 16px margin.
    const newScrollPos =
      elementPos +
      containerScrollPos -
      containerHeight -
      (offset || 0) -
      headerHeight

    scrollableContainer?.scrollTo({ behavior: 'smooth', top: newScrollPos })
  }
}

export const matchVariantToSelectedOptions = (
  variants: ProductVariant[],
  selectedOptions: SelectedOption[]
): ProductVariant | null => {
  // yet to select enough required options
  let matchedVariant: ProductVariant | null = null

  // loop through each variant and match up it's options with the selected options
  variants.forEach((variant) => {
    // no need to loop once we have found the variant
    if (matchedVariant) return
    let matchedOptionCount = 0
    selectedOptions.forEach((selectedOption) => {
      variant.options.forEach((variantOption) => {
        if (
          selectedOption.name === variantOption.name &&
          selectedOption.value === variantOption.values[0]
        ) {
          matchedOptionCount += 1
        }
      })
      if (matchedOptionCount === selectedOptions.length) {
        matchedVariant = variant
      }
    })
  })
  return matchedVariant
}
