import { useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import {
  LandingForm as Form,
  LandingContext,
  Cookies,
  InvalidTimeSlot,
  SplashImage
} from 'shop/components/Landing'
import {
  useQueryParamAccountModal,
  useShop,
  useSlerpMerchant
} from 'shop/hooks'
import { QUERY_GET_MERCHANT } from 'shop/client'
import { format, isToday, parseISO } from 'date-fns'
import {
  getValidSamedayStores,
  getValidPreorderStores
} from 'shop/components/Landing/utils'
import { getStoreById } from '../utils'

import {
  ValidStore,
  Store,
  CurrentLocationState,
  AvailableDates
} from 'shop/components/Landing/types'
import { Canonical, MetaDescription, PageTitle } from 'shop/components/SEO'
import { QUERY_VALID_STORES } from 'shop/client/queries/StoreQueries'
import { sortStoresByLocation } from 'shop/components/Landing/locationUtils'
import MediaQuery from 'react-responsive'
import { QUERY_GET_AVAILABLE_DATES } from 'shop/client/queries/DateQueries'
import {
  formatReplaceAvailableDates,
  getCalendarStartAndEndDates,
  getEarliestAvailableDate
} from 'shop/components/Landing/datePickerUtils'
import { usePageViewTracker } from 'tracker'
import { FulfillmentTypeLoadingStates } from 'shop/components/Landing/LandingContext'

interface LandingProps {
  modal?: any
  addToCart?: any
  cartId?: string
  storeId?: string
  orderAgainCartId?: string
  onClose: () => void
}

const Landing = (props: LandingProps) => {
  const { config, useShopClient, merchant, setMerchant } = useShop()
  const { seoDescription } = useSlerpMerchant()
  const client = useShopClient()

  const [stores, setStores] = useState<Store[]>([])
  const [currentStore, setCurrentStore] = useState<ValidStore>()
  const [validStores, setValidStores] = useState<ValidStore[]>([])
  const [loadingStores, setLoadingStores] =
    useState<FulfillmentTypeLoadingStates>({
      delivery: false,
      pickup: false
    })
  const [loadingDates, setLoadingDates] =
    useState<FulfillmentTypeLoadingStates>({
      delivery: false,
      pickup: false
    })
  const [fulfillmentType, setFulfillmentType] = useState<string>('')
  const [currentLocation, setCurrentLocation] = useState<CurrentLocationState>()
  const [fulfillmentDate, setFulfillmentDate] = useState<Date>(new Date())
  const [fulfillmentTime, setFulfillmentTime] = useState<string | null>(null)
  const [fulfillmentTimeRange, setFulfillmentTimeRange] = useState<
    string | null
  >(null)
  const [useCustomerDefaultAddress, setUseCustomerDefaultAddress] =
    useState(false)
  const [storeOutOfRange, setStoreOutOfRange] = useState<boolean>(false)
  const [sameDayOnly, setSameDayOnly] = useState<boolean>(false)
  const [preOrderOnly, setPreOrderOnly] = useState<boolean>(false)
  const [availableDates, setAvailableDates] = useState<AvailableDates>({
    pickup: {},
    delivery: {}
  })
  const [isExtendedHour, setIsExtendedHour] = useState(false)
  const [hasFailedSubmit, setHasFailedSubmit] = useState(false)
  const [showFulfillmentOptions, setShowFulfillmentOptions] = useState(false)

  const hasAvailableDates: boolean = useMemo(() => {
    const availableMonths = Object.keys(availableDates[fulfillmentType] || {})
    if (!availableMonths.length) return false
    return true
  }, [availableDates, fulfillmentType])

  const includePickupAddress =
    fulfillmentType === 'pickup' && !!merchant.pickup_address_enabled
  const includeAddressInVars =
    fulfillmentType === 'delivery' || includePickupAddress

  const getAvailableDates = (baseDate: Date = new Date()) => {
    if (!merchant || fulfillmentType === '') return
    setLoadingDates({ ...loadingDates, [fulfillmentType]: true })

    const { startDate } = getCalendarStartAndEndDates(baseDate)

    return client
      .query({
        query: QUERY_GET_AVAILABLE_DATES,
        fetchPolicy: 'cache-first',
        variables: {
          merchantId: merchant.id,
          fulfillmentType,
          startDate,
          lat: includeAddressInVars ? currentLocation?.latLng.lat : undefined,
          lng: includeAddressInVars ? currentLocation?.latLng.lng : undefined
        }
      })
      .then((res) => {
        setAvailableDates(
          formatReplaceAvailableDates(
            availableDates,
            res?.data?.dates || [],
            fulfillmentType
          )
        )
        setLoadingDates({ ...loadingDates, [fulfillmentType]: false })
      })
  }

  const landingState = {
    merchant,
    setMerchant,
    stores,
    setStores,
    fulfillmentType,
    setFulfillmentType,
    fulfillmentDate,
    setFulfillmentDate,
    fulfillmentTime,
    setFulfillmentTime,
    fulfillmentTimeRange,
    setFulfillmentTimeRange,
    currentLocation,
    setCurrentLocation,
    currentStore,
    setCurrentStore,
    useCustomerDefaultAddress,
    setUseCustomerDefaultAddress,
    inModal: props.modal,
    addToCart: props.addToCart,
    cartId: props.cartId,
    orderAgainCartId: props.orderAgainCartId,
    validStores,
    loadingStores,
    setLoadingStores,
    loadingDates,
    setLoadingDates,
    storeOutOfRange,
    availableDates,
    getAvailableDates,
    isExtendedHour,
    setIsExtendedHour,
    sameDayOnly,
    preOrderOnly,
    hasFailedSubmit,
    setHasFailedSubmit,
    showFulfillmentOptions,
    setShowFulfillmentOptions
  }

  // Track page view
  usePageViewTracker()

  // Always clear cartId when user visits the landing page
  useEffect(() => {
    localStorage.removeItem('cartId')
    localStorage.removeItem(config.domain)
  }, [])

  // Load login modal from query params
  useQueryParamAccountModal()

  useEffect(() => {
    setLoadingStores({ ...loadingStores, [fulfillmentType]: true })
    client
      .query({
        query: QUERY_GET_MERCHANT,
        fetchPolicy: 'no-cache',
        variables: { slug: config.domain }
      })
      .then((result) => {
        const [newMerchant] = result.data.merchants
        const newStores: Store[] = result.data.stores

        setMerchant(newMerchant)
        setStores(newStores)
        setLoadingStores({ ...loadingStores, [fulfillmentType]: false })
      })
  }, [config.domain]) // eslint-disable-line

  const clearSelectedTime = () => {
    setFulfillmentTime(null)
    setFulfillmentTimeRange(null)
  }

  const getValidStores = () => {
    const deliveryAddress =
      fulfillmentType === 'delivery' && currentLocation?.latLng
        ? {
            geom: {
              lat: currentLocation.latLng.lat.toString(),
              long: currentLocation.latLng.lng.toString()
            },
            type: 'GEOCODE'
          }
        : null
    return client
      .query({
        query: QUERY_VALID_STORES,
        variables: {
          merchantId: merchant.id,
          fulfillmentType,
          fulfillmentDate: format(fulfillmentDate, 'yyyy-MM-dd'),
          deliveryAddress
        }
      })
      .then(({ data: { getValidStores } }) => {
        const { stores } = getValidStores[0]
        // Doing this for now
        const filteredStores = stores.filter(
          (store: ValidStore) => store.fulfillmentTimeslots.length > 0
        )
        if (currentLocation)
          return sortStoresByLocation(filteredStores, currentLocation)
        return filteredStores
      })
  }

  /**
   * When switching fulfillmentType set to earliest available date,
   * if no available date, call getAvailableDates
   */
  useEffect(() => {
    // set to earliest available date of already fetched available dates.
    if (hasAvailableDates) {
      const earliestDate = getEarliestAvailableDate(
        availableDates,
        fulfillmentType
      )
      setFulfillmentDate(earliestDate)
      return
    }
    // otherwise call getAvailableDates
    if (fulfillmentType === 'delivery' && currentLocation) getAvailableDates()
    if (fulfillmentType === 'pickup') getAvailableDates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfillmentType])

  /**
   * Whenever a user changes location, fetch new available dates
   */
  useEffect(() => {
    if (currentLocation) getAvailableDates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation])

  // whenever we finish fetching available dates, set to earliest fulfillment date
  useEffect(() => {
    // no dates mean no stores
    if (!hasAvailableDates) setValidStores([])
    if (!loadingDates.delivery && !loadingDates.pickup && hasAvailableDates) {
      const earliestDate = getEarliestAvailableDate(
        availableDates,
        fulfillmentType
      )
      if (earliestDate) {
        setFulfillmentDate(earliestDate)
        return
      }
      setFulfillmentDate(new Date())
    }
  }, [loadingDates])

  useEffect(() => {
    const isDelivery = fulfillmentType === 'delivery'
    const hasAddress = isDelivery ? !!currentLocation?.address : true
    if (
      merchant &&
      fulfillmentDate &&
      fulfillmentType &&
      hasAddress &&
      hasAvailableDates
    ) {
      setLoadingStores({ ...loadingStores, [fulfillmentType]: true })
      setValidStores([])
      getValidStores()
        .then((stores) => {
          setValidStores(stores || [])
          setDefaultStore(stores)
        })
        .catch(console.error)
        .finally(() => {
          setLoadingStores({ ...loadingStores, [fulfillmentType]: false })
          clearSelectedTime()
        })
    }
  }, [fulfillmentDate]) // eslint-disable-line

  const setDefaultStore = (stores: ValidStore[]) => {
    const { orderAgainCartId, storeId } = props
    if (orderAgainCartId) {
      const storeById = getStoreById(stores, storeId)
      setCurrentStore(storeById)
    } else {
      const [firstStore] = stores
      if (firstStore) setCurrentStore(firstStore)
    }
  }

  useEffect(() => {
    if (merchant && fulfillmentType) {
      const formattedDate = parseISO(fulfillmentDate.toISOString())

      if (isToday(formattedDate)) {
        const { storeOutOfRange: sameDayStoreOutOfRange } =
          getValidSamedayStores(
            stores,
            fulfillmentType,
            formattedDate,
            currentLocation
          )
        setStoreOutOfRange(sameDayStoreOutOfRange)
      } else {
        const { storeOutOfRange: preOrderStoreOutOfRange } =
          getValidPreorderStores(
            stores,
            fulfillmentType,
            formattedDate,
            currentLocation
          )
        setStoreOutOfRange(preOrderStoreOutOfRange)
      }
    }
  }, [currentLocation, fulfillmentType, fulfillmentDate, stores, merchant])

  useEffect(() => {
    if (merchant && stores) {
      const { validStores: availableSameDayStores } = getValidSamedayStores(
        stores,
        fulfillmentType,
        new Date(),
        currentLocation
      )
      const { validStores: availableStoresForPreorder } =
        getValidPreorderStores(
          stores,
          fulfillmentType,
          undefined,
          currentLocation
        )

      setSameDayOnly(false)
      setPreOrderOnly(false)

      if (
        availableStoresForPreorder.length > 0 &&
        availableSameDayStores.length === 0
      ) {
        setPreOrderOnly(true)
      }
      if (
        availableStoresForPreorder.length === 0 &&
        availableSameDayStores.length > 0
      ) {
        setSameDayOnly(true)
      }
    }
  }, [currentLocation, stores, merchant, fulfillmentType])

  // Attempts to clear timeslot upon store change
  useEffect(() => {
    if (fulfillmentTime) clearSelectedTime()
  }, [currentStore])

  return (
    <LandingContext.Provider value={landingState}>
      <Canonical url={window.location.origin + window.location.pathname} />
      <PageTitle />
      <MetaDescription seoDescription={seoDescription} type='merchant' />
      <Container>
        {!props.modal && !window.localStorage.getItem('closeNotice') && (
          <CookiesContainer>
            <Cookies />
          </CookiesContainer>
        )}
        <FormContainer>
          <Form onClose={props.onClose} />
        </FormContainer>
        {!props.modal && (
          <MediaQuery minWidth={768.1}>
            <SplashContainer>
              <SplashImage />
            </SplashContainer>
          </MediaQuery>
        )}
      </Container>
      <InvalidTimeSlot />
    </LandingContext.Provider>
  )
}

const Container = styled.div(({ theme }: any) => ({
  position: 'relative',
  height: '100%',
  minHeight: '100vh',
  '& > *:only-child': {
    minHeight: '100%',
    width: '100%',
    margin: 0
  },
  [theme.mediaQueries.viewport7]: {
    minHeight: 'auto',
    backgroundColor: 'white'
  }
}))

const CookiesContainer = styled.div(({ theme }: any) => ({
  zIndex: 15,
  left: 0,
  right: 0,
  top: 0,
  position: 'relative',
  [theme.mediaQueries.viewport7]: {
    padding: '24px',
    left: '45%',
    width: '50%',
    top: 'auto',
    position: 'fixed',
    bottom: 0
  },
  [theme.mediaQueries.viewport9]: {
    left: '40%'
  }
}))

const FormContainer = styled.div(({ theme }: any) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  padding: '0',
  minHeight: '100vh',
  [theme.mediaQueries.viewport7]: {
    padding: '0',
    width: '45%',
    backgroundColor: 'white'
  },
  [theme.mediaQueries.viewport9]: {
    width: '40%'
  }
}))

const SplashContainer = styled.div(({ theme }: any) => ({
  position: theme.designMode ? 'absolute' : 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  minHeight: '360px',
  height: '40vh',
  [theme.mediaQueries.viewport7]: {
    left: '45%',
    bottom: 0,
    height: '100vh'
  },
  [theme.mediaQueries.viewport9]: {
    left: '40%'
  }
}))

export default Landing
