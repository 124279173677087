import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { useShop } from 'shop/hooks'
import { CartItem } from '../Cart/types'
import { ExtendedCartItem } from '../Landing/Delivery/CartProductErrorModal'

type Props = {
  item: CartItem | ExtendedCartItem
  withPrice?: boolean
  isExtendedCartItem?: boolean
}

const ProductInfoItem = ({
  item,
  withPrice = true,
  isExtendedCartItem = false
}: Props) => {
  const { config } = useShop()
  const imageUrlPrefix = `https://${config.domain}.${config.assetHost}/assets/variant/`

  return (
    <Item>
      <ProductImage
        backgroundImage={imageUrlPrefix + `${item.product_variant_id}`}
      ></ProductImage>
      <ItemInfo>
        {item.quantity > 1 && `${item.quantity}x `}
        {item?.product_variant?.product?.name}
      </ItemInfo>
      {withPrice && (
        <>
          {isExtendedCartItem ? (
            <ItemPrice>
              <Price>
                £
                {Number((item as ExtendedCartItem).old_price_with_vat).toFixed(
                  2
                )}
                <GreyText>Old Price</GreyText>
              </Price>{' '}
              <Price>
                £
                {Number((item as ExtendedCartItem).new_price_with_vat).toFixed(
                  2
                )}
                <GreyText>New Price</GreyText>
              </Price>
            </ItemPrice>
          ) : (
            <ItemPrice>
              <Price>£{Number(item.amount).toFixed(2)}</Price>
            </ItemPrice>
          )}
        </>
      )}
    </Item>
  )
}

const Item = styled.li<StyledHTMLElement>(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '12px 0',

  '&:first-of-type': {
    paddingTop: '0'
  },
  '&:last-of-type': {
    paddingBottom: '0'
  },
  '&:not(:last-of-type)': {
    borderBottom: '1px solid #F0F0F0'
  }
}))

const ItemInfo = styled.span<StyledHTMLElement>(() => ({
  display: 'flex',
  alignItems: 'center',
  paddingRight: '10px',
  flexGrow: 1
}))

const ItemPrice = styled.div<StyledHTMLElement>(() => ({
  marginLeft: 'auto',
  alignItems: 'center',
  gap: '16px',
  display: 'flex'
}))

const Price = styled.div<StyledHTMLElement>(() => ({
  fontSize: '16px'
}))

const GreyText = styled.div<StyledHTMLElement>(() => ({
  fontSize: '10px',
  display: 'flex',
  justifyContent: 'center',
  color: '#8C8C8C'
}))

const ProductImage = styled.div<
  StyledHTMLElement & { backgroundImage: string },
  Required<Theme>
>(({ theme, backgroundImage }) => ({
  background: `lightgray url(${backgroundImage})`,
  alignSelf: 'center',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  marginRight: '12px',
  borderRadius: '8px',
  flex: '0 0 30px',
  height: '30px',
  [theme['mediaQueries']['viewport6']]: {
    flex: '0 0 40px',
    height: '40px'
  },
  [theme['mediaQueries']['viewport7']]: {
    flex: '0 0 45px',
    height: '45px'
  }
}))

export default ProductInfoItem
