import styled from '@emotion/styled'

const Store = ({
  id,
  name,
  handleClick,
  isActive
}: {
  id: string
  name: string
  handleClick: () => void
  isActive: boolean
}) => (
  <StoreContainer
    key={id}
    onClick={handleClick}
    className={isActive ? '-active' : ''}
    data-testid={`store-${name}`}
  >
    {name}
  </StoreContainer>
)

export const StoreContainer = styled.li(({ theme }: any) => ({
  textAlign: 'left',
  background: 'none',
  cursor: 'pointer',
  fontSize: '14px',
  display: 'flex',
  justifyContent: 'space-between',
  border: '1px solid transparent',
  alignItems: 'center',
  marginRight: '16px',
  padding: '18px',
  '&.-active, &:hover': {
    border: '1px solid #cecece',
    borderRadius: '12px',
    background: '#f7f7f7'
  },
  [theme.mediaQueries.viewport7]: {
    padding: '16px',
    marginRight: 0,
    cursor: 'pointer',
    textAlign: 'left'
  }
}))

export default Store
