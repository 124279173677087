import styled from '@emotion/styled'
import { useAppContent, useMerchantStore } from 'shop/hooks'

interface Props {
  backgroundImage?: string
  heading?: string
  subheading?: string
}

const Banner = ({ backgroundImage, heading }: Props) => {
  const { bannerImage, merchantName } = useAppContent()
  const { storeName } = useMerchantStore()

  const img = backgroundImage || bannerImage
  const alt =
    merchantName && storeName ? `${merchantName} ${storeName}` : heading

  return (
    <Root>
      <ImageContainer>
        {img && (
          <Background
            src={img}
            alt={alt}
            data-testid='banner-image'
          ></Background>
        )}
      </ImageContainer>
    </Root>
  )
}

const Root = styled.div(({ theme }: any) => ({
  position: 'relative',
  fontWeight: theme.fonts.body.weight,
  fontFamily: theme.fonts.body.family,
  transition: 'all 100ms ease-in-out',
  margin: '0 auto',
  maxWidth: '1920px',
  [theme.mediaQueries.viewport2]: {
    padding: '16px'
  },
  [theme.mediaQueries.viewport7]: {
    maxWidth: '1332px',
    padding: '24px 16px'
  },
  [theme.mediaQueries.viewport14]: {
    maxWidth: '2064px',
    padding: '24px 75px'
  }
}))

const ImageContainer = styled.div(({ theme }: any) => ({
  aspectRatio: '3/1',
  height: 'auto',
  width: 'auto',
  [theme.mediaQueries.viewport7]: {
    aspectRatio: '5/1'
  }
}))

const Background = styled.img(() => ({
  height: '100%',
  width: '100%',
  objectFit: 'cover',
  borderRadius: '16px'
}))

export default Banner
