import React from 'react'
import Dropdown from 'shop/components/Inputs/Dropdown'
import SimpleLoader from 'shop/components/Loader/SimpleLoader'
import { BsChevronRight as ForwardArrow } from 'react-icons/bs'
import {
  CommonOverlayTitleHeader,
  LineBreak,
  ListItem,
  ListItemIconContainer,
  ListItemWrapper,
  DeliveryFormHeading
} from './commonStyles'
import { FiClock as DropdownClock } from 'react-icons/fi'
import {
  OrderDayMapping,
  orderDayFieldIsEditable,
  selectedOrderDayOptions
} from '../utils'
import ModalWrapper from './ModalWrapper'
import { StyledHTMLElement } from 'shop/theme/types'
import styled from '@emotion/styled'

interface DeliveryOrderDayProps {
  fulfillmentType: string
  handleClickOutsideOrderDayDropdown: () => void
  isOrderDayDropdownOpen: boolean
  isOverlayOpen: boolean
  loadingStores: boolean
  orderDayDropdownHandler: () => void
  selectedOrderDay: string
  selectedOrderDayHandler: (orderDay: string) => void
  selectedOrderDayMapping: OrderDayMapping
}

const DeliveryOrderDay = ({
  loadingStores,
  selectedOrderDay,
  selectedOrderDayMapping,
  fulfillmentType,
  isOrderDayDropdownOpen,
  orderDayDropdownHandler,
  isOverlayOpen,
  handleClickOutsideOrderDayDropdown,
  selectedOrderDayHandler
}: DeliveryOrderDayProps) => {
  const orderDayDropdownList = () =>
    selectedOrderDayOptions(selectedOrderDayMapping, fulfillmentType).map(
      (item, i) => {
        const itemKeys = Object.keys(item)
        return itemKeys.map((itemKey) => {
          return (
            <React.Fragment key={`fragment-${item[itemKey].value}-${i}`}>
              <ListItemWrapper>
                <ListItem
                  disabled={item[itemKey].disabled}
                  key={`item-${item[itemKey].value}-${i}`}
                  onMouseDown={() => selectedOrderDayHandler(item[itemKey])}
                >
                  {item[itemKey].value}
                  <ListItemIconContainer>
                    <ForwardArrow />
                  </ListItemIconContainer>
                </ListItem>
                <LineBreak />
              </ListItemWrapper>
            </React.Fragment>
          )
        })
      }
    )

  return (
    <>
      <DeliveryFormHeading>
        <span>When</span>
      </DeliveryFormHeading>
      <SimpleLoader
        isLoading={loadingStores}
        additionalStyles={{ borderRadius: '12px' }}
      >
        <OrderDayContainer>
          <Dropdown
            testId='selected-delivery-order-day-dropdown'
            textContent={
              selectedOrderDayMapping[fulfillmentType][selectedOrderDay].value
            }
            hasSelected={!!selectedOrderDay}
            IconLeft={<DropdownClock />}
            isOpen={isOrderDayDropdownOpen}
            onClick={orderDayDropdownHandler}
            canEdit={orderDayFieldIsEditable(
              selectedOrderDayMapping,
              fulfillmentType
            )}
            showEditText={true}
          >
            <ModalWrapper isOpen={isOverlayOpen}>
              <CommonOverlayTitleHeader
                onBackClick={handleClickOutsideOrderDayDropdown}
                modalTitle={'When'}
              />
              {orderDayDropdownList()}
            </ModalWrapper>
          </Dropdown>
        </OrderDayContainer>
      </SimpleLoader>
    </>
  )
}

const OrderDayContainer = styled.div<StyledHTMLElement>(() => ({
  height: '50px'
}))

export default DeliveryOrderDay
