import { Category } from './types'

export const includesCurrentCategory = (
  categories: Category[],
  currentCategory?: string
) => {
  if (!currentCategory || currentCategory === '') return false

  return categories
    .map(({ id, name }) => id || name.toLowerCase())
    .includes(currentCategory)
}
