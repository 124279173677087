export type UseSessionStorage<T> = {
  getSessionStorageItem: () => T | null
  setSessionStorageItem: (value: T) => void
  removeSessionStorageItem: () => void
}

export function useSessionStorage<T>(key: string): UseSessionStorage<T> {
  const setSessionStorageItem = (value: T): void => {
    try {
      sessionStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      console.log(error)
    }
  }

  const getSessionStorageItem = (): T | null => {
    try {
      const item = sessionStorage.getItem(key)
      if (item === null) return null
      return JSON.parse(item)
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const removeSessionStorageItem = (): void => {
    try {
      sessionStorage.removeItem(key)
    } catch (error) {
      console.log(error)
    }
  }

  return {
    getSessionStorageItem,
    setSessionStorageItem,
    removeSessionStorageItem
  }
}
