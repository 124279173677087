import { AppContentLoader, LoaderSwitcher } from 'router/loaders'
import Split from 'shop/components/SplitCheckout/Split'

export const SplitWrapper = () => {
  return (
    <AppContentLoader>
      {(isParentLoaded) => (
        <LoaderSwitcher isParentLoaded={isParentLoaded}>
          <Split />
        </LoaderSwitcher>
      )}
    </AppContentLoader>
  )
}
