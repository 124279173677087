import styled from '@emotion/styled'

const Container = styled.div(({ theme }: any) => ({
  position: 'relative',
  background: 'white',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  fontWeight: theme.fonts.body.weight,
  fontFamily: theme.fonts.body.family,
  color: theme.colors.textBody,
  flexGrow: 1,
  '> button': {
    margin: 'auto 0 0'
  },
  padding: `0 ${theme.space[3]}px ${theme.space[7]}px`,
  // smaller width viewport needs set height to fill screen
  [theme.mediaQueries.viewport7]: {
    overflowY: 'auto',
    minHeight: '100%',
    flex: '1 1 auto',
    padding: `0 ${theme.space[4]}px 0 ${theme.space[4]}px`,
    height: 'unset'
  },
  [theme.mediaQueries.viewport10]: {
    flex: '1 1 auto',
    padding: `0 ${theme.space[4]}px 0 ${theme.space[4]}px`,
    overflowX: 'hidden'
  }
}))

export default Container
