import { ShopConfig, UpdateSlerpCartProps } from 'shop/types'
import { ApolloClient, FetchResult } from '@apollo/client'
import uuidv4 from 'uuid/v4'
import {
  QUERY_GET_CART,
  MUTATE_ADD_TO_CART,
  MUTATE_UPDATE_CART_ITEM,
  MUTATE_UPDATE_CART_ITEM_QUANTITY,
  MUTATE_DELETE_CART_ITEM,
  MUTATE_CREATE_SLERP_CART,
  MUTATE_UPDATE_SLERP_CART,
  MUTATE_ADD_TO_CARTV2
} from 'shop/client'
import { AddToCartResult, CartSession } from './types'
import { computeCartTotal, getDeviceType } from 'shop/components/Cart/utils'
import { SelectedModifier, Cart } from 'shop/components/Landing/types'
import { addDays } from 'date-fns'
import { isDesktop, isMobile } from 'react-device-detect'
import { refreshCart } from 'shop/components/Checkout/Network'
import { InitSlerpCartProps } from 'shop/types'
import { AppliedModifiersParams } from 'shop/types/cart'
import { transformModifiers } from 'shop/utils/cart'

class CartManager {
  cartSession: CartSession
  config: ShopConfig
  cart: Cart | null
  cartId: string | null
  client: ApolloClient<object>
  requestId: number

  constructor(cartSession: CartSession, client: ApolloClient<object>) {
    this.client = client
    this.cart = null
    this.cartSession = cartSession
    this.config = cartSession.config
    this.cartId = cartSession.cartId
    this.requestId = 0
  }

  initCart = async (initSlerpCartParams: InitSlerpCartProps) => {
    if (this.cartSession.onCartLoading) this.cartSession.onCartLoading(this)

    const cartParams = {
      ...initSlerpCartParams,
      platformType: getDeviceType(isMobile, isDesktop),
      platformVendor: 'web'
    }

    return this.client
      .mutate({
        mutation: MUTATE_CREATE_SLERP_CART,
        variables: cartParams
      })
      .then((results) => {
        this.cartId = results.data['createSlerpCart']?.id

        return results.data['createSlerpCart']
      })
  }

  updateCart = async (updateSlerpCartParams: UpdateSlerpCartProps) => {
    if (this.cartSession.onCartLoading) this.cartSession.onCartLoading(this)

    const cartParams = {
      ...updateSlerpCartParams,
      platformType: getDeviceType(isMobile, isDesktop),
      platformVendor: 'web'
    }

    return this.client
      .mutate({
        mutation: MUTATE_UPDATE_SLERP_CART,
        variables: cartParams
      })
      .then(() => {
        return this.loadCart()
      })
  }

  updateCartId = async (
    newCartId: string,
    merchantId: string,
    merchantSlug: string
  ) => {
    this.cartId = newCartId
    this.cartSession.cartId = newCartId
    if (merchantId && merchantSlug) {
      this.saveCartToLocalStorage(newCartId, {
        id: merchantId,
        slug: merchantSlug
      })
    }
  }

  /**
   *  This loads the current cart for the current session
   */
  loadCart = async () => {
    // Send an onCartLoading event
    if (this.cartSession.onCartLoading) this.cartSession.onCartLoading(this)

    if (!this.cartId) {
      this.cartSession.setCart(null)
      this.cartSession.setIsCartLoading(false)

      return null
    }

    return this.client
      .query({
        query: QUERY_GET_CART,
        variables: {
          id: this.cartId
        },
        fetchPolicy: 'no-cache'
      })
      .then((results) => {
        // NOTE: no error handling when results.data.cart is null
        this.cart = results.data.cart as Cart
        this.cartSession.setCart(this.cart)
        if (this.cartSession.onCartLoad) this.cartSession.onCartLoad(this)

        // generate a requestId for our callbacks, for use on useEffect() or similar.
        this.requestId = new Date().getTime()

        return this.cart
      })
  }

  /** Returns a cart when provided a cart id */
  getCartById = async (cartId: string) => {
    return this.client
      .query({
        query: QUERY_GET_CART,
        variables: {
          id: cartId
        },
        fetchPolicy: 'no-cache'
      })
      .then((results) => {
        return results.data.cart as Cart
      })
  }

  /**
   *
   */
  // const persistCart = () => {
  // this.cartSession.setCart
  // }

  /**
   *  This returns the total cost of the cart
   *
   * @returns total
   */
  total = () => {
    let total = 0
    if (this.cart && this.cart.cart_items) {
      total = computeCartTotal(this.cart.cart_items)
    }
    return total
  }

  /**
   * Compute vat for the cart item.
   *
   * Take note that vat and variantVat are different.
   *
   * vat is for the computed vat amount
   * variantVat is for the variant's vat
   *
   * @returns vat amount
   */
  computeVat = (cartItem: ProductVariantCartProps) => {
    return cartItem.amount * (cartItem.variantVat / 100)
  }

  /**
   *  This adds a product item to the cart
   * @param productVariant
   */
  addProductVariantToCart = (
    cartItem: ProductVariantCartProps
  ): Promise<ProductVariantCartProps & StoreAndMerchantNames> => {
    // Send an onCartLoading event
    if (this.cartSession.onCartLoading) this.cartSession.onCartLoading(this)

    if (cartItem.id === undefined) {
      cartItem.id = uuidv4()
    }

    cartItem.cartId = cartItem.cartId
      ? cartItem.cartId
      : this.cartId && this.cartId

    /*
     * Commenting this now because cartItem.vat isn't
     * used in slerp v1 checkout will return 0 for now.
     */
    // cartItem.vat = this.computeVat(cartItem)
    cartItem.vat = 0

    const updatedModifiers =
      cartItem &&
      cartItem.appliedModifiers &&
      cartItem.appliedModifiers.data.map((item) => {
        const {
          id,
          modifier_id,
          modifier_group_id,
          amount,
          price,
          quantity,
          vat
        } = item
        return {
          modifier_id,
          modifier_group_id,
          amount,
          price,
          quantity,
          vat,
          id: id !== '' ? id : uuidv4()
        }
      })

    return this.client
      .mutate({
        mutation: MUTATE_ADD_TO_CART,
        variables: {
          ...cartItem,
          appliedModifiers: {
            data: updatedModifiers
          }
        }
      })
      .then((results) => {
        this.cart = results.data.insert_order_items.returning[0].cart as Cart
        this.cartSession.setCart(this.cart)
        if (this.cartSession.onCartLoad) this.cartSession.onCartLoad(this)
        this.requestId = new Date().getTime()
        if (this.cartSession.onAddCartItem) this.cartSession.onAddCartItem(this)

        // update cart for non-elixir mutations :(
        // TODO: Replace MUTATE_ADD_TO_CART with Elixir Mutation
        refreshCart(this.client, this.cart.id)

        return {
          ...cartItem,
          merchantName: this.cart.merchant.name,
          storeName: this.cart.store.name
        }
      })
  }

  /**
   *  This updates a product inside the cart.
   * @param productVariant
   */
  updateProductVariantInCart = (
    cartItem: ProductVariantCartProps
  ): Promise<ProductVariantCartProps & StoreAndMerchantNames> => {
    if (this.cartSession.onCartLoading) this.cartSession.onCartLoading(this)

    cartItem.cartId = this.cartId
    cartItem.vat = 0

    return this.client
      .mutate({
        mutation: MUTATE_UPDATE_CART_ITEM,
        variables: cartItem
      })
      .then((results) => {
        this.cart = results.data.update_order_items.returning[0].cart as Cart
        this.cartSession.setCart(this.cart)
        if (this.cartSession.onCartLoad) this.cartSession.onCartLoad(this)
        this.requestId = new Date().getTime()

        // update cart for non-elixir mutations :(
        // TODO: Replace MUTATE_UPDATE_CART_ITEM with Elixir Mutation
        refreshCart(this.client, this.cart.id)

        return {
          ...cartItem,
          merchantName: this.cart.merchant.name,
          storeName: this.cart.store.name
        }
      })
  }

  /**
   *  Removes a product from the cart
   * @param cartItemId
   */

  removeProductVariantFromCart = async (cartItemId: string) => {
    if (this.cartSession.onCartLoading) this.cartSession.onCartLoading(this)
    if (this.cartSession.onCartUpdating) this.cartSession.onCartUpdating()

    return this.client
      .mutate({
        mutation: MUTATE_DELETE_CART_ITEM,
        variables: {
          id: cartItemId
        }
      })
      .then((results) => {
        if (this.cartSession.onCartUpdate) this.cartSession.onCartUpdate()
        if (this.cartSession.onRemoveCartItem)
          this.cartSession.onRemoveCartItem()
        return results
      })
      .catch(() => {
        if (this.cartSession.onCartUpdate) this.cartSession.onCartUpdate()
        if (this.cartSession.onRemoveCartItem)
          this.cartSession.onRemoveCartItem()
      })
  }

  updateLineItemInCart = async (cartItemId: string, quantity: number) => {
    if (this.cartSession.onCartLoading) this.cartSession.onCartLoading(this)
    if (this.cartSession.onCartUpdating) this.cartSession.onCartUpdating()

    return this.client
      .mutate({
        mutation: MUTATE_UPDATE_CART_ITEM_QUANTITY,
        variables: {
          id: cartItemId,
          quantity
        }
      })
      .then((results) => {
        if (this.cartSession.onCartUpdate) this.cartSession.onCartUpdate()
        if (this.cartSession.onAddCartItem) this.cartSession.onAddCartItem(this)
        return results
      })
      .catch(() => {
        if (this.cartSession.onCartUpdate) this.cartSession.onCartUpdate()
        if (this.cartSession.onAddCartItem) this.cartSession.onAddCartItem(this)
      })
  }

  /**
   * ELIXIR ENDPOINT
   *  This adds a product item to the cart
   * @param cartItem
   */
  addProductToCart = async (cartItem: ProductVariantCartProps) => {
    // Send an onCartLoading event
    if (this.cartSession.onCartLoading) this.cartSession.onCartLoading(this)

    this.cartSession.setIsCartLoading(true)

    const cartIdToSend = cartItem.cartId
      ? cartItem.cartId
      : this.cartId && this.cartId

    let variablesToSend: AddToCartVariables = {
      cartId: cartIdToSend || '',
      quantity: cartItem.quantity || 1,
      variantId: cartItem.variantId
    }

    // append Modifiers to variables, if any.
    if (!!cartItem.appliedModifiers?.data.length) {
      const formattedModifiers = transformModifiers(
        cartItem.appliedModifiers.data
      )
      variablesToSend = {
        ...variablesToSend,
        modifiers: formattedModifiers
      }
    }

    return this.client
      .mutate({
        mutation: MUTATE_ADD_TO_CARTV2,
        variables: variablesToSend
      })
      .then(({ data }: FetchResult<{ addToCart: AddToCartResult }>) => {
        if (!data) return
        const result = data.addToCart
        // set or update the cartId to match
        if (this.cartId !== result.id) this.cartId = result.id

        // TODO: Not have to reload the cart on every addToCart to satisfy Hasura crossover.
        this.loadCart()

        if (this.cartSession.onCartLoad) this.cartSession.onCartLoad(this)
        this.requestId = new Date().getTime()
        if (this.cartSession.onAddCartItem) this.cartSession.onAddCartItem(this)
        this.cartSession.setIsCartLoading(false)
        return result
      })
      .catch((error) => {
        this.cartSession.setIsCartLoading(false)
        console.error(error)
      })
  }

  saveCartToLocalStorage = (
    id: string,
    merchant: { slug: string; id?: string }
  ) =>
    localStorage.setItem(
      merchant.slug,
      JSON.stringify({
        cart_id: id,
        merchant_id: merchant.id,
        expires_at: addDays(new Date(), 1)
      })
    )
}

export interface ProductVariantCartProps {
  id?: string

  cartId?: string | null

  /** The product variant to add in  the cart  */
  variantId: string

  /** The quantity of products to add */
  quantity: number

  /** The subamount of the product amount * quantity */
  amount: number

  /** The vat amount */
  vat?: number

  /** The product variantVat */
  variantVat: number

  /** The product variantPrice */
  variantPrice: number

  /** Selected modifiers */
  appliedModifiers?: {
    data: SelectedModifier[]
  }
}

export interface StoreAndMerchantNames {
  storeName: string
  merchantName: string
}

type AddToCartVariables = {
  cartId: string
  quantity: number
  variantId: string
  modifiers?: AppliedModifiersParams[]
}

export default CartManager
