import { format, isToday, parseISO } from 'date-fns'
import { Cart } from '../Landing/types'
import { createAddressString } from 'shop/utils/address'
import { ConsumerCart } from 'shop/types/cart'
import { DELIVERY_FULFILLMENT } from 'shop/types'
import { parseTimezoneISO } from 'shop/utils'

export const ISOtoUTC = (date: string) => {
  if (isValidDateTime(date)) {
    const [year, month, day, hour, minute] = date
      .split(/\D+/)
      .map((el) => parseInt(el, 10))
    const trueMonth = month - 1

    return new Date(Date.UTC(year, trueMonth, day, hour, minute))
  }

  return new Date()
}

const isValidDateTime = (date: string) => {
  const dateRegEx = new RegExp(
    /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])$/
  )

  return dateRegEx.test(date)
}

export const buildCartDate = (cart?: { deliver_by?: Date | null }) => {
  if (!cart) return '...'

  const today = `Today (${format(new Date(), 'dd/MM/yyyy')})`

  if (!cart.deliver_by) return today

  // I had to add a Z at the end because it wont work without it
  const date = parseISO(`${cart.deliver_by}Z`)
  if (!isToday(date)) return format(date, 'dd/MM/yyy')

  return today
}

export const getStore = (cart: Cart | null) => {
  if (!cart) return '...'
  if (cart.pickup_store) return cart.pickup_store.name
  return cart && cart.store && cart.store.name
}

export const location = (cart: Cart | null) => {
  if (cart) {
    if (cart.fulfillment_type === 'delivery')
      return createAddressString(cart.delivery_address)
    return getStore(cart)
  }

  return '...'
}

// NavbarV2 utils
export const getStoreName = (cart: ConsumerCart | null): string => {
  return cart?.store?.name ?? '...'
}

/** Returns store address if delivery fulfillment, otherwise uses store name */
export const getStoreAddressOrName = (cart: ConsumerCart | null): string => {
  if (cart) {
    if (cart.fulfillment.type === DELIVERY_FULFILLMENT)
      return createAddressString(cart.deliveryAddress)
    return getStoreName(cart)
  }

  return '...'
}

export const buildCartDateV2 = (
  fulfillmentFromString: string | undefined
): string => {
  if (!fulfillmentFromString) return '...'

  const fulfillmentFromDate = parseTimezoneISO(fulfillmentFromString)
  return isToday(fulfillmentFromDate)
    ? `Today (${format(new Date(), 'dd/MM/yyyy')})`
    : format(fulfillmentFromDate, 'dd/MM/yyyy')
}

export const PAGE_CHECKOUT = 'checkout'
export const PAGE_SHOP = 'shop'
export const PAGE_ORDER = 'order'

export type PageType =
  | typeof PAGE_CHECKOUT
  | typeof PAGE_SHOP
  | typeof PAGE_ORDER
