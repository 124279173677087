import { FiMap as MapIcon } from 'react-icons/fi'
import { BiEditAlt as EditIcon } from 'react-icons/bi'
import MobileLinedInputField from 'shop/components/Controls/MobileLinedInputField'

type Props = {
  withIcon: boolean
  isEditing?: boolean
  value?: string
  onFocus?: () => void
  autoFocus?: boolean
  onBlur?: () => void
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
  onContainerClick?: () => void
}

const AddressInputField = ({
  withIcon,
  value,
  onFocus,
  autoFocus,
  onBlur,
  inputProps,
  isEditing = false,
  onContainerClick
}: Props) => {
  const valueProps = inputProps?.readOnly ? { value } : { defaultValue: value }

  return (
    <>
      <MobileLinedInputField
        name='mobile-address'
        icon={withIcon ? <MapIcon /> : null}
        HasValueIcon={EditIcon}
        hasValue={!!value && !isEditing}
        onContainerClick={onContainerClick}
        inputProps={{
          ...inputProps,
          ...valueProps,
          autoComplete: 'chrome-off',
          placeholder: 'Type your postcode here',
          autoFocus,
          onBlur,
          onFocus
        }}
      />
    </>
  )
}

export default AddressInputField
