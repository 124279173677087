export const errorGlossary = (errorMessage: string) => {
  switch (errorMessage) {
    case 'DISCOUNT_INVALID':
      return 'Invalid discount code'
    case 'DISCOUNT_EXPIRED':
      return 'Discount code has expired'
    case 'DISCOUNT_LIMIT_REACHED':
      return 'You have reached your limit with this discount'
    default:
      return 'Invalid discount code'
  }
}
