import styled from '@emotion/styled'
import { PlusIcon, MinusIcon } from 'shop/assets/icons'

interface Props {
  quantity: number
  setQuantity: (arg0: number) => void
  enableIncrement: boolean
}

const ModifierQuantity = ({
  quantity,
  setQuantity,
  enableIncrement
}: Props) => {
  return (
    <Container data-testid='modifierQuantity'>
      <Button
        data-testid='modifierDecrementButton'
        onClick={() => setQuantity(quantity - 1)}
      >
        <MinusIcon />
      </Button>
      <Quantity>{quantity}</Quantity>
      <Button
        disabled={!enableIncrement}
        data-testid='modifierIncrementButton'
        onClick={() => setQuantity(quantity + 1)}
      >
        <PlusIcon />
      </Button>
    </Container>
  )
}

const Container = styled.section(({ theme }: any) => ({
  alignSelf: 'center',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  zIndex: 5
}))

const Quantity = styled.p(({ theme }: any) => ({
  fontWeight: theme.fonts.heading.weight,
  color: theme.fonts.heading.color,
  fontSize: theme.fontSizes[2],
  minWidth: '40px',
  textAlign: 'center',
  padding: `0 ${theme.space[2]}px`
}))

const Button = styled.button(({ theme }: any) => ({
  pointerEvents: 'auto',
  cursor: 'pointer',
  padding: '6px',
  backgroundColor: theme.colors.primary,
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  outline: 'none',
  border: 0,
  borderRadius: '100%',
  fontFamily: theme.fonts.heading.family,
  fontWeight: 'bold',
  '&:disabled': {
    opacity: 0.2
  },

  '& > svg': {
    height: '12px',
    width: '12px'
  }
}))

export default ModifierQuantity
