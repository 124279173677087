import React, { useCallback, useEffect, useState } from 'react'

import { useLoader } from 'shop/hooks'
import { LoaderProps } from './types'
import { useLocation, useHistory } from 'react-router-dom'

export const UpdateSlerpCartLoader = ({
  isParentLoaded = true,
  children
}: LoaderProps) => {
  const location = useLocation()
  const history = useHistory()

  const { updateCustomerId } = useLoader()
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const handleUpdate = useCallback(async () => {
    const customerId = localStorage.getItem('customerId')

    if (customerId) {
      await updateCustomerId(customerId)
    }

    history.replace({
      ...history.location,
      state: { ...history.location.state, updateCart: false }
    })

    setIsLoaded(Boolean(isParentLoaded))
  }, [isParentLoaded, updateCustomerId, history])

  useEffect(() => {
    if (location.state?.updateCart) {
      handleUpdate()
    } else {
      setIsLoaded(true)
    }
  }, [handleUpdate, location.state])

  return <React.Fragment>{children(isLoaded)}</React.Fragment>
}
