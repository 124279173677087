import { createContext } from 'react'
import {
  Store,
  Merchant,
  ValidStore,
  CurrentLocationState,
  AvailableDates,
  AddToCartParams
} from 'shop/components/Landing/types'

export type LandingState = {
  merchant: Merchant
  stores: Store[]
  fulfillmentType: string
  fulfillmentDate: Date
  fulfillmentTime: string | null
  currentLocation?: CurrentLocationState
  currentStore?: ValidStore
  setMerchant: (merchant: Merchant) => void
  setFulfillmentType: (value: string) => void
  setStores: (stores: Store[]) => void
  setCurrentLocation: (address?: CurrentLocationState) => void
  setFulfillmentDate: (date: Date) => void
  setFulfillmentTime: (time: string | null) => void
  setCurrentStore: (store: ValidStore | undefined) => void
  inModal: boolean
  addToCart?: ({ cartId, fulfillmentType, storeSlug }: AddToCartParams) => void
  fulfillmentTimeRange: string | null
  setFulfillmentTimeRange: (range: string | null) => void
  useCustomerDefaultAddress: boolean
  setUseCustomerDefaultAddress: (value: boolean) => void
  cartId?: string
  orderAgainCartId?: string
  storeOutOfRange: boolean
  setValidStores?: (stores: ValidStore[]) => void
  validStores: ValidStore[]
  loadingStores: FulfillmentTypeLoadingStates
  setLoadingStores: (value: FulfillmentTypeLoadingStates) => void
  loadingDates: FulfillmentTypeLoadingStates
  setLoadingDates: (value: FulfillmentTypeLoadingStates) => void
  availableDates: AvailableDates
  getAvailableDates: (baseDate?: Date) => void
  isExtendedHour: boolean
  setIsExtendedHour: (value: boolean) => void
  sameDayOnly: boolean
  preOrderOnly: boolean
  hasFailedSubmit: boolean
  setHasFailedSubmit: (value: boolean) => void
  showFulfillmentOptions: boolean
  setShowFulfillmentOptions: (value: boolean) => void
}

export type LandingStateNew = LandingState & {
  isOverlayOpen: boolean
  setIsOverlayOpen: (value: boolean) => void
  setAddress: (address: string) => void
  address: string
  isEditMode: boolean
  setIsEditMode: (value: boolean) => void
  selectedOrderDay: string
  setSelectedOrderDay: (value: string) => void
  prevCartId: string | null
  setPrevCartId: (value: string | null) => void
  isLoginOpen: boolean
  setIsLoginOpen: (value: boolean) => void
}

export type FulfillmentTypeLoadingStates = {
  delivery: boolean
  pickup: boolean
}

const baseLandingContext: LandingState = {
  merchant: {} as Merchant,
  stores: [],
  fulfillmentType: '',
  fulfillmentDate: new Date(),
  fulfillmentTime: 'immediately',
  fulfillmentTimeRange: '',
  setFulfillmentTimeRange: () => {},
  currentLocation: {
    address: '',
    addressComponents: [],
    latLng: { lat: 0, lng: 0 }
  },
  currentStore: {
    id: '',
    name: '',
    slug: '',
    address: {
      line_1: '',
      line_2: '',
      city: '',
      zip: '',
      geom: { coordinates: [0, 0] }
    },
    fulfillmentTimeslots: [],
    earliestDeliveryFulfillment: {
      fulfillmentWindow: { from: '', to: '' },
      orderType: 'ASAP',
      minimumOrderValue: '',
      orderableAt: ''
    },
    earliestPickupFulfillment: {
      fulfillmentWindow: { from: '', to: '' },
      orderType: 'ASAP',
      minimumOrderValue: '',
      orderableAt: ''
    }
  },
  setFulfillmentType: () => {},
  setMerchant: () => {},
  setStores: () => {},
  setCurrentLocation: () => {},
  setFulfillmentDate: () => {},
  setFulfillmentTime: () => {},
  setCurrentStore: () => {},
  inModal: false,
  useCustomerDefaultAddress: false,
  setUseCustomerDefaultAddress: () => {},
  cartId: '',
  orderAgainCartId: '',
  setValidStores: (stores: ValidStore[]) => {},
  validStores: [],
  setLoadingStores: () => {},
  loadingStores: {
    delivery: false,
    pickup: false
  },
  setLoadingDates: () => {},
  loadingDates: {
    delivery: false,
    pickup: false
  },
  storeOutOfRange: false,
  availableDates: { delivery: {}, pickup: {} },
  getAvailableDates: () => {},
  isExtendedHour: false,
  setIsExtendedHour: (value: boolean) => {},
  sameDayOnly: false,
  preOrderOnly: false,
  hasFailedSubmit: false,
  setHasFailedSubmit: (value: boolean) => {},
  showFulfillmentOptions: false,
  setShowFulfillmentOptions: (value: boolean) => {}
}

export const LandingContext = createContext<LandingState>(baseLandingContext)

export const LandingContextNew = createContext<LandingStateNew>({
  ...baseLandingContext,
  isOverlayOpen: false,
  setIsOverlayOpen: (value: boolean) => {},
  address: '',
  setAddress: (address: string) => {},
  isEditMode: false,
  setIsEditMode: (value: boolean) => {},
  selectedOrderDay: 'now',
  setSelectedOrderDay: () => {},
  prevCartId: null,
  setPrevCartId: () => {},
  isLoginOpen: false,
  setIsLoginOpen: () => {}
})
