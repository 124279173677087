import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useMerchantStore, useAppContent, useSlerpMerchant } from 'shop/hooks'
import { buildTitle } from './utils'

interface Props {
  productName?: string
}

export const PageTitle = ({ productName }: Props) => {
  const { storeName } = useMerchantStore()
  const { tagline } = useSlerpMerchant()
  const { merchantName } = useAppContent()

  const [seoTitle, setSeoTitle] = useState('')

  const title = useMemo(() => {
    return buildTitle(merchantName, storeName, productName, tagline)
  }, [merchantName, storeName, tagline, productName])

  useEffect(() => {
    setSeoTitle(title)
  }, [title])

  if (!title) {
    return <></>
  }

  return (
    <Helmet>
      <title>{seoTitle}</title>
    </Helmet>
  )
}
