import React from 'react'
import styled from '@emotion/styled'
import {
  IoIosArrowRoundBack as ArrowIcon,
  IoIosLogOut as LogoutIcon
} from 'react-icons/io'
import { useReactRouter, useAccount } from 'shop/hooks'

const AccountLinks = () => {
  const { history } = useReactRouter()
  const { handleLogout } = useAccount()
  return (
    <Container>
      <Link onClick={() => history.goBack()}>
        <ArrowIcon />
        <span>Back</span>
      </Link>
      <Link onClick={handleLogout}>
        <span>Logout</span>
        <LogoutIcon />
      </Link>
    </Container>
  )
}

const Link = styled.a(({ theme }: any) => ({
  fontSize: theme.fontSizes[2],
  color: theme.colors.textMute,
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  '& > span': {
    margin: '0 8px'
  }
}))

const Container = styled.div(() => ({
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  position: 'sticky',
  top: 0,
  paddingTop: '24px',
  paddingBottom: '16px'
}))

export default AccountLinks
