import AddressAutoComplete from '../AddressAutoComplete'
import styled from '@emotion/styled'
import { useState } from 'react'
import { FaArrowLeft as BackArrow } from 'react-icons/fa'
import AddressInputField from './Delivery/AddressInputField'
import FullscreenModal from '../Modal/FullscreenModal'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { useMediaQueries } from 'shop/hooks'
import CenterModal from '../Modal/CenterModal'

const AddressSelection = ({
  address,
  onChange,
  onSelect,
  label,
  inModal
}: {
  address: string
  onChange: (newAddress: string) => void
  onSelect: (newAddress: string) => void
  label?: string
  inModal?: boolean
}) => {
  const [isAddressSelectionOpen, setIsAddressSelectionOpen] = useState(false)
  const handleSelect = (address: string) => {
    onSelect(address)
    setTimeout(() => setIsAddressSelectionOpen(false), 200)
  }
  return (
    <>
      <AddressInputField
        withIcon
        value={address}
        onFocus={() => setIsAddressSelectionOpen(true)}
        onContainerClick={() => setIsAddressSelectionOpen(true)}
        inputProps={{ readOnly: true }}
      />
      {isAddressSelectionOpen && (
        <MobileAddressSelectionModal
          onClose={() => setIsAddressSelectionOpen(false)}
          onChange={onChange}
          onSelect={handleSelect}
          label={label}
          address={address}
          inModal={inModal}
        />
      )}
    </>
  )
}

const MobileAddressSelectionModal = (props: {
  address: string
  onChange: (newAddress: string) => void
  onSelect: (newAddress: string) => void
  onClose: () => void
  label?: string
  inModal?: boolean
}) => {
  const { isMobile } = useMediaQueries()
  const { inModal } = props

  const content = (
    <>
      <FullScreenModalHeader
        onClick={props.onClose}
        isCenterModal={inModal && !isMobile}
      >
        <BackArrow />
        <Label>
          <h1>{props.label || 'Enter your postcode'}</h1>
        </Label>
      </FullScreenModalHeader>
      <Main>
        <AddressAutoComplete
          withIcon={true}
          isEditing={true}
          value={props.address}
          onChange={props.onChange}
          onSelect={props.onSelect}
        />
      </Main>
    </>
  )

  return inModal && !isMobile ? (
    <CenterModal
      handleCloseModal={props.onClose}
      styles={{ minHeight: '50%', overflowY: 'hidden' }}
      inModal={inModal}
    >
      {content}
    </CenterModal>
  ) : (
    <FullscreenModal onClose={props.onClose} isLandingColumn={!isMobile}>
      {content}
    </FullscreenModal>
  )
}

const Main = styled.main`
  padding: 0 36px;
`

const Label = styled.label(({ theme }: any) => ({
  display: 'block',
  lineHeight: '1em',
  cursor: 'pointer',
  '& h1': {
    fontSize: theme.fontSizes[0],
    textTransform: 'uppercase'
  }
}))

const FullScreenModalHeader = styled.div<
  StyledHTMLElement & { isCenterModal?: boolean },
  Required<Theme>
>(({ theme, isCenterModal }) => ({
  display: 'flex',
  padding: isCenterModal ? '36px 36px 20px 46px' : '10px 36px 20px 36px',
  alignItems: 'center',
  gap: '15px',
  cursor: 'pointer',

  '& h1': {
    fontSize: '12px',
    textTransform: 'uppercase'
  }
}))

export default AddressSelection
