import { OrderItem } from './cart'

export const PICKUP_FULFILLMENT = 'PICKUP'
export const DELIVERY_FULFILLMENT = 'DELIVERY'
export const ORDER_AT_TABLE_FULFILLMENT = 'ORDER_AT_TABLE'

export type FulfillmentType =
  | typeof PICKUP_FULFILLMENT
  | typeof DELIVERY_FULFILLMENT
  | typeof ORDER_AT_TABLE_FULFILLMENT

export const ASAP_ORDER = 'ASAP'
export const SCHEDULED_ORDER = 'SAMEDAY'
export const PREORDER_ORDER = 'PREORDER'

export type OrderType =
  | typeof ASAP_ORDER
  | typeof SCHEDULED_ORDER
  | typeof PREORDER_ORDER

export type FulfillmentTime = {
  from: Date
  to: Date
}

interface BaseSlerpCartProps {
  customerId: string | null
  storeId: string | undefined
  fulfillmentDate: string
  fulfillmentTime: string | null
  fulfillmentType: string
  orderItems: OrderItem[]
  deliveryAddress?: DeliveryAddress | null
}

export interface InitSlerpCartProps extends BaseSlerpCartProps {
  merchantId: string
  address: string | null
  metadata: string
}

export interface UpdateSlerpCartProps extends BaseSlerpCartProps {
  cartId: string
  giftWrapMessage?: string | null
  giftWrapped?: boolean
}

export interface InitSlerpCartProps {
  customerId: string | null
  storeId: string | undefined
  merchantId: string
  address: string | null
  fulfillmentDate: string
  fulfillmentTime: string | null
  fulfillmentType: string
  orderItems: OrderItem[]
  metadata: string
  deliveryAddress?: DeliveryAddress | null
}

export interface DeliveryAddress {
  flatNumber: string | null
  line_1: string
  line_2: string
  city: string
  country: string
  zip: string
}

export interface ConsumerDeliveryAddress {
  lineOne: string
  lineTwo: string
  city: string
  country: string
  zip: string
}

export type LatLngArray = [number, number]
export type LatLng = { lat: number; lng: number }

export type DayOfWeek =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'

export interface RWGToken {
  token: string
  expires_at: string
}

type GraphQLError = {
  graphQLErrors: AbsintheError[]
}

export type AbsintheError = {
  error: string
  message: string
}

export type ApiError = Error & GraphQLError

export interface CountryCode {
  name: string
  code: string
  dial_code: string
  preferred?: boolean
  flag: string
}
