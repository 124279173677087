import { isEqual } from 'lodash'
import { useEffect, useRef, useCallback } from 'react'
import { InputGroup, InputField } from '../Controls'
import { Fields } from './FormElements'
import { UseFormMethods, FieldError } from 'react-hook-form'
import { FormFields, DeliveryAddress } from './types'
import {
  focusNextInput,
  onlySpaces,
  validateAddressParams,
  getAddressErrorMessage,
  hasAddressErrors,
  SHIPPING_INPUTS,
  SHIPPING_PREFIX
} from './utils'
import AlertMessage from '../Cart/AlertMessage'
import styled from '@emotion/styled'
import { inputCharactersLimitValidator } from 'shop/components/Checkout'
import useInputKeyEnterHandler from 'shop/hooks/useInputKeyEnterHandler'
import { useShop } from 'shop/hooks'
import { trackAddShippingInfo } from './tracking/helpers'
import FormInput from '../Inputs/FormInput'
import { HalfWidthFlexContainer } from './commonStyles'

type Props = {
  formHandle: UseFormMethods<FormFields>
  deliveryNotePlaceholder: string
  setIsDeliveryLoading: (value: boolean) => void
  saveAddressAndQuoteFee: () => void
  onBlur: () => void
  displayedAddress: DeliveryAddress
}

const ShippingDetails = ({
  formHandle,
  deliveryNotePlaceholder,
  setIsDeliveryLoading,
  saveAddressAndQuoteFee,
  onBlur,
  displayedAddress
}: Props) => {
  const { register, errors, watch } = formHandle
  const { cartSession } = useShop()
  const { cart } = cartSession

  const addressErrors: { [key: string]: FieldError } | undefined = errors[
    'delivery_address'
  ] as { [key: string]: FieldError }
  const addressApiError: FieldError | undefined = errors[
    'address_api'
  ] as FieldError

  const lastSubmitted = useRef<DeliveryAddress | undefined>()

  const deliveryAddress = watch('delivery_address')
  const dropoffNotes = watch('dropoff_notes')

  const handleQuoting = useCallback(
    (values: DeliveryAddress) => {
      if (
        hasAddressErrors(addressErrors) ||
        isEqual(values, lastSubmitted.current)
      ) {
        return
      }

      setIsDeliveryLoading(true)
      lastSubmitted.current = { ...values }
      const addressValid = validateAddressParams(values)

      if (!addressValid) {
        return setIsDeliveryLoading(false)
      }

      trackAddShippingInfo(cart)
      saveAddressAndQuoteFee()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addressErrors, setIsDeliveryLoading, saveAddressAndQuoteFee]
  )

  useEffect(() => {
    handleQuoting(displayedAddress)
  }, [handleQuoting, displayedAddress, errors])

  const handleBlur = () => {
    onBlur()
  }

  const {
    line1InputId,
    line2InputId,
    zipInputId,
    cityInputId,
    dropoffNotesInputId
  } = SHIPPING_INPUTS
  const inputList = [
    line1InputId,
    line2InputId,
    zipInputId,
    cityInputId,
    dropoffNotesInputId
  ]

  useInputKeyEnterHandler({ handleKeyPress: () => focusNextInput(inputList) })

  const currentError = ((): string | null => {
    if (hasAddressErrors(addressErrors)) {
      return getAddressErrorMessage(addressErrors)
    }

    if (Boolean(addressApiError)) {
      return addressApiError.message || ''
    }

    return null
  })()

  return (
    <div>
      {currentError && (
        <AlertMessageWrapper>
          <AlertMessage skin='mild' type='error' heading={currentError} />
        </AlertMessageWrapper>
      )}

      {/*
        AutoComplete weirdness. To turn off properly in browser, must give an invalid value. ¯\_(ツ)_/¯
        Ref: https://stackoverflow.com/a/54041517
      */}
      <Fields>
        <InputField
          hidden={true}
          formRef={register()}
          name={SHIPPING_PREFIX}
          inputType='checkbox'
        />

        <InputGroup templateColumns={'auto'} gridGap='24px'>
          <FormInput
            id={line1InputId}
            name={line1InputId}
            labelText={'Street Address'}
            value={deliveryAddress?.line_1 || ''}
            formRef={register({
              validate: {
                isRequired: (value) =>
                  Boolean(value.length) && !onlySpaces(value)
              }
            })}
            showError={!!errors.delivery_address?.line_1}
            autoComplete={'street-address'}
            onBlur={handleBlur}
          />
          <FormInput
            id={line2InputId}
            name={line2InputId}
            labelText={'Apartment or building name (optional)'}
            value={deliveryAddress?.line_2 || ''}
            formRef={register()}
            showError={!!errors.delivery_address?.line_2}
            autoComplete={'not-this-one'}
            onBlur={handleBlur}
          />
          <HalfWidthFlexContainer>
            <FormInput
              id={zipInputId}
              name={zipInputId}
              labelText={'Postcode'}
              value={deliveryAddress?.zip || ''}
              formRef={register({
                validate: {
                  isRequired: (value) =>
                    Boolean(value.length) && !onlySpaces(value),
                  length_5: (value) => value.length > 4
                }
              })}
              showError={!!errors.delivery_address?.zip}
              autoComplete={'postal-code'}
              onBlur={handleBlur}
            />
            <FormInput
              id={cityInputId}
              name={cityInputId}
              labelText={'City'}
              value={deliveryAddress?.city || ''}
              formRef={register({
                validate: {
                  isRequired: (value) =>
                    Boolean(value.length) && !onlySpaces(value)
                }
              })}
              autoComplete={'address-level2'}
              onBlur={handleBlur}
              showError={!!errors.delivery_address?.city}
            />
          </HalfWidthFlexContainer>
          <FormInput
            id={dropoffNotesInputId}
            name={dropoffNotesInputId}
            labelText='Delivery instructions'
            variant='textarea'
            placeholder={deliveryNotePlaceholder}
            value={dropoffNotes || ''}
            formRef={register({
              validate: inputCharactersLimitValidator('Delivery instructions')
            })}
            showError={!!errors[dropoffNotesInputId]}
          />
        </InputGroup>
      </Fields>
    </div>
  )
}

const AlertMessageWrapper = styled.div(() => ({
  margin: '16px 0'
}))

export default ShippingDetails
