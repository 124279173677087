import styled from '@emotion/styled'

const DetailCard = ({
  icon,
  text,
  actions,
  withLine,
  size,
  color
}: {
  icon: React.ReactElement
  text: React.ReactElement
  actions?: React.ReactElement
  withLine?: boolean
  size?: 'small' | 'semi-regular' | 'regular'
  color?: string
}) => {
  return (
    <Card>
      <Icon size={size ? size : 'regular'} color={color}>
        {icon}
      </Icon>
      <Text>{text}</Text>
      {actions && <Actions>{actions}</Actions>}
      {withLine && <DeliveryLine color={color} />}
    </Card>
  )
}

const Icon = styled.div(({ size, color }: { size: string; color?: string }) => {
  const sizes = {
    small: ['32px', '12px'],
    'semi-regular': ['36px', '20px'],
    regular: ['56px', '24px']
  }

  return {
    zIndex: 1,
    flex: '0 0 auto',
    backgroundColor: color ? color : '#f1f1f1',
    borderRadius: '50%',
    height: sizes[size][0],
    width: sizes[size][0],
    marginRight: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '> svg': {
      height: sizes[size][1],
      width: sizes[size][1]
    },
    '>div>svg': {
      height: sizes[size][1],
      width: sizes[size][1]
    }
  }
})

const Text = styled.div(({ theme }: any) => ({
  alignSelf: 'center',
  flex: '1 1 auto',
  padding: '2px 0',
  fontSize: theme.fontSizes[0],
  '> strong': { fontSize: theme.fontSizes[1] },
  '> *': { marginBottom: '2px', display: 'block', lineHeight: '1.4em' }
}))

const Card = styled.div(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'flex-start'
}))

const Actions = styled.div(({ theme }: any) => ({
  flex: '0 1 auto',
  display: 'flex',
  justifySelf: 'flex-end',
  marginLeft: '8px',
  '> a': {
    textDecoration: 'none',
    display: 'block',
    flex: '1 0 auto',
    marginLeft: '8px'
  },
  [theme.mediaQueries.viewport6]: {
    marginLeft: '36px'
  }
}))

const DeliveryLine = styled.div(({ color }: { color?: string }) => ({
  position: 'absolute',
  top: '4px',
  bottom: '-24px',
  backgroundColor: color ? color : '#f4f4f4',
  width: '2px',
  marginLeft: '28px'
}))

export default DetailCard
