import styled from '@emotion/styled'
import React from 'react'
import { Button } from 'shop/components'
import { useConsumerCart } from 'shop/hooks'
import { StyledHTMLElement } from 'shop/theme/types'
import { AvailableReward, RewardDetail } from 'shop/types/cart'
import { Label, LineBreak } from '../commonStyles'

interface Props {
  rewards: AvailableReward[]
}

const RewardItem = ({
  details,
  id,
  isLast,
  handleApplyReward
}: {
  details: RewardDetail
  id: string
  isLast: boolean
  handleApplyReward: (id: string) => void
}) => (
  <React.Fragment key={id}>
    <RewardItemContainer isLast={isLast}>
      <RewardDetails>
        <RewardTitle>{details.name}</RewardTitle>
        <RewardDesc>{details.description}</RewardDesc>
      </RewardDetails>
      <Button size='md' width='94px' onClick={() => handleApplyReward(id)}>
        Redeem
      </Button>
    </RewardItemContainer>
  </React.Fragment>
)

const Rewards = ({ rewards }: Props) => {
  const { applyRewardConsumerCart } = useConsumerCart()

  const handleApplyReward = (id: string) => {
    applyRewardConsumerCart({
      variables: { rewardId: id }
    })
  }

  return (
    <Container data-testid='rewards-container'>
      <LineBreak height={'2px'} />
      <LabelContainer>
        <Label withEllipsis={true} fontWeight={600}>
          Rewards
        </Label>
      </LabelContainer>
      <RewardItemList>
        {rewards.map(({ details, id }, index) => (
          <RewardItem
            key={id}
            details={details}
            id={id}
            isLast={index === rewards.length - 1}
            handleApplyReward={handleApplyReward}
          />
        ))}
      </RewardItemList>
    </Container>
  )
}

export default Rewards

const Container = styled.div<StyledHTMLElement>(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

const LabelContainer = styled.div<StyledHTMLElement>(() => ({
  margin: '24px 0 8px',
  '> p': {
    margin: '0'
  }
}))

const RewardItemList = styled.ul<StyledHTMLElement>({ margin: 0 })

const RewardItemContainer = styled.li<StyledHTMLElement & { isLast: boolean }>(
  ({ isLast }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: isLast ? '16px 0 24px' : '16px 0'
  })
)

const RewardDetails = styled.div<StyledHTMLElement>(() => ({
  display: 'flex',
  flexDirection: 'column',
  '> p': {
    margin: '0'
  }
}))

const RewardTitle = styled.p<StyledHTMLElement>(() => ({
  display: 'flex',
  fontSize: '16px',
  fontWeight: 500
}))

const RewardDesc = styled.p<StyledHTMLElement>(() => ({
  display: 'flex',
  fontSize: '14px'
}))
