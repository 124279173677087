import styled from '@emotion/styled'
import { ReactComponent as CloseIcon } from 'shop/assets/icons/close.svg'

interface Props {
  handleCloseModal?: () => void
  variant?: 'sideModal' | 'newFulfillmentModal' | 'newModal'
}

const CloseButton = ({ variant, handleCloseModal }: Props) => {
  return (
    <Button
      variant={variant}
      data-testid='closeButton'
      onClick={handleCloseModal}
    >
      <CloseIcon />
    </Button>
  )
}

const sharedButtonStyles = {
  '&:hover': {
    outline: '1px solid white',
    backgroundColor: '#0000000d',
    borderRadius: '28px'
  },
  '&:active': {
    outline: '1px solid white',
    backgroundColor: '#0000001a',
    borderRadius: '28px'
  }
}

/** Store of style variants */
const variantStyles = {
  newFulfillmentModal: (theme: any) => ({
    height: '24px',
    width: '24px',
    position: 'absolute',
    top: '23px',
    right: '12px',
    ...sharedButtonStyles,
    '> svg': {
      width: '24px',
      height: '24px'
    },
    [theme.mediaQueries.viewport5]: {
      position: 'unset',
      top: '12px',
      right: '12px',
      height: '24px',
      width: '24px'
    }
  }),
  sideModal: (theme: any) => ({
    top: '28px',
    right: '9px',
    alignItems: 'center',
    ...sharedButtonStyles,
    [theme.mediaQueries.viewport5]: {
      top: '25px',
      right: '10px'
    },
    [theme.mediaQueries.viewport7]: {
      right: '24px'
    },
    '> svg': {
      [theme.mediaQueries.viewport7]: {
        width: '24px',
        height: '24px'
      },
      '> path': {
        fill: theme.colors.primary
      }
    }
  }),
  newModal: (theme: any) => ({
    height: '24px',
    width: '24px',
    top: '16px',
    right: '16px',
    ...sharedButtonStyles,

    '> svg': {
      width: '24px',
      height: '24px'
    },

    [theme.mediaQueries.viewport5]: {
      top: '24px',
      right: '24px'
    }
  })
}

/** Accesses store of style variants and returns variant based on variant name provided by prop */
const returnVariantStyle = (theme: any, variant: string): any =>
  (variant && variantStyles[variant](theme)) || {}

const Button = styled.div(({ variant, theme }: any) => ({
  zIndex: 1000,
  position: 'absolute',
  height: '36px',
  width: '36px',
  top: '8px',
  right: '8px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    '> svg': {
      opacity: 0.4,
      transition: 'opacity 200ms ease-in'
    }
  },
  '> svg': {
    height: '18px',
    width: '18px',
    '> path': {
      fill: theme.colors.primary
    }
  },
  [theme.mediaQueries.viewport5]: {
    right: '16px',
    top: '16px'
  },
  [theme.mediaQueries.viewport7]: {
    top: '24px',
    right: '24px'
  },
  ...returnVariantStyle(theme, variant)
}))

export default CloseButton
