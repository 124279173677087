import { format, isSameDay, parseISO, setHours, setMinutes } from 'date-fns'
import { parseTimezoneISO } from 'shop/utils'
import { ValidStoreTimeSlot, TimeSlot } from '../types'

/*
  This function converts time string into a datetime value.
  It combines the passed time string with the current date
  to form a datetime value.
  Ex. '10:00am' --> '2022-02-08 10:00:00'
*/
const convertTimeToDateTime = (time: string) => {
  const [hour, minute] = time.split(':')
  return setMinutes(setHours(new Date(), Number(hour)), Number(minute))
}

/*
  This function turns the start time and end time values of the timeslot
  that came from the getValidStores API into a more human-friendly format.
  
  The formatted value is then displayed in the timeslot selector
  and saved as the fulfillmentTimeRange field of the cart.

  Ex. startTime = '15:00:00.000000' | endTime = '16:00:00.000000'
  --> '3:00 PM - 4:00 PM'
*/
const formatTimeRange = (startTime: string, endTime: string) => {
  const formatTime = (time: string) => {
    // The time string is converted to a date time value so it can be formatted.
    const datetime = convertTimeToDateTime(time)
    return format(datetime, 'h:mm a')
  }

  return `${formatTime(startTime)} - ${formatTime(endTime)}`
}

/*
  This function formats the timeslot datetime value that came from
  getValidStores into a time string format that is accepted by the cart creation API.

  Ex. '2022-02-09T15:00:00.000000+00:00'
  --> '15:00:00 GMT+0000 (Greenwich Mean Time)'
*/
const formatTimeValue = (datetime: string) =>
  parseTimezoneISO(datetime).toTimeString()

/*
  This function formats the store timeslots that come from getValidStores
  into something that can be accepted by TimeSlot component
  
  Ex. from [
    { value: 'asap', startTime: null, endTime: null },
    {
      value: '2022-02-09T15:00:00.000000+00:00',
      startTime: '15:00:00.000000',
      endTime: '16:00:00.000000'
    }
  ]
  into --> [
    { value: 'immediately', range: 'ASAP' },
    { value: '15:00:00 GMT+0000 (Greenwich Mean Time)', range: '3:00 PM - 4:00 PM' }
  ]
*/
export const formatStoreTimeslots = (
  timeslots: ValidStoreTimeSlot[],
  fulfillmentDate: Date | null
): TimeSlot[] => {
  if (!timeslots || !timeslots.reduce) return []

  return timeslots.reduce((acc, timeslot) => {
    const { startTime, endTime, value } = timeslot
    const formattedTimeslot = (() => {
      if (value === 'asap')
        return { range: 'ASAP', value: 'immediately', extended: false }
      if (!value || !startTime || !endTime) return null
      return {
        value: formatTimeValue(value),
        range: formatTimeRange(startTime, endTime),
        extended: fulfillmentDate
          ? !isSameDay(fulfillmentDate, parseISO(value))
          : false
      }
    })()

    if (!formattedTimeslot) return acc
    return [...acc, formattedTimeslot]
  }, [] as TimeSlot[])
}
