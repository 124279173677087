import styled from '@emotion/styled'
import React, { useState, useMemo, RefCallback } from 'react'
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { MdErrorOutline as ErrorIcon } from 'react-icons/md'
import { thinScrollbar } from '../common'
import { scrollToElement } from '../Controls/utils'
import { useLabelOffset } from 'shop/hooks/useLabelOffset'
import {
  InputLine,
  PlaceholderPreText,
  PlaceholderToLabelText,
  TextInput
} from './common'
import { ErrorMessage } from '../Shop/commonStyles'

interface FormInputProps
  extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  value: string
  labelText: string
  showError: boolean
  placeholderPreText?: string
  errorMessage?: string | React.ReactNode
  formRef?: RefCallback<HTMLInputElement | HTMLTextAreaElement>
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  name?: string
  variant?: 'input' | 'textarea'
  autoFocus?: boolean
  placeholder?: string
  hidePlaceholder?: boolean
}

const FormInput = (props: FormInputProps) => {
  const {
    id,
    name,
    value,
    onChange,
    labelText,
    showError,
    placeholderPreText,
    type,
    errorMessage,
    formRef,
    onBlur,
    variant = 'input',
    autoFocus,
    placeholder,
    hidePlaceholder,
    ...inputProps
  } = props
  const [isInputFocused, setIsInputFocused] = useState(false)
  const inputId = `${id}-input`
  const inputName = name || inputId
  const hasPlaceholderPreText = !!placeholderPreText

  const [revealPassword, setRevealPassword] = useState(false)

  const { labelOffsetLeft, labelOffsetTop, labelOffsetTopFocused } =
    useLabelOffset(inputId, hasPlaceholderPreText)

  const inputType = useMemo(
    () => (revealPassword ? 'text' : type),
    [revealPassword, type]
  )

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (onBlur) {
      onBlur(event)
    }
    setIsInputFocused(false)
  }

  const onFocus = () => {
    !autoFocus && name && scrollToElement(`${name}-input`, 80)
    setIsInputFocused(true)
  }

  return (
    <InputContainer>
      {variant === 'textarea' ? (
        <TextAreaWrapper>
          <TextAreaInput
            id={inputId}
            name={inputName}
            data-testid={inputId}
            onChange={onChange}
            onFocus={onFocus}
            autoFocus={autoFocus}
            onBlur={handleBlur}
            showError={showError}
            placeholder={hidePlaceholder && !isInputFocused ? '' : placeholder}
            // If an onChange handler is provided, the component is treated as a controlled component with the 'value' prop.
            // If no onChange handler is provided, the component is treated as an uncontrolled component with the 'defaultValue' prop.
            // This is in line with react-hook-form's approach of using uncontrolled components by default.
            {...(onChange ? { value } : { defaultValue: value })}
            ref={formRef}
            {...inputProps}
          />
        </TextAreaWrapper>
      ) : (
        <InputLine>
          {!!hasPlaceholderPreText && (
            <PreTextContainer
              id={`${inputId}-placeholderPreText`}
              isInputFocused={isInputFocused}
              showError={showError}
              hasValue={!!value?.length}
            >
              <PlaceholderPreText>{placeholderPreText}</PlaceholderPreText>
              <Divider showError={showError} isInputFocused={isInputFocused} />
            </PreTextContainer>
          )}
          <TextInput
            id={inputId}
            name={inputName}
            data-testid={inputId}
            isInputFocused={isInputFocused}
            type={inputType}
            onChange={onChange}
            onFocus={onFocus}
            autoFocus={autoFocus}
            onBlur={handleBlur}
            placeholder={hidePlaceholder && !isInputFocused ? '' : placeholder}
            hasPlaceholderPreText={hasPlaceholderPreText}
            isPassword={type === 'password'}
            showError={showError}
            defaultValue={value}
            ref={formRef}
            {...(revealPassword && { autoCapitalize: 'none' })}
            {...inputProps}
          />
          {labelText && (
            <PlaceholderToLabelText
              id={`${inputId}-placeholder`}
              htmlFor={inputId}
              isInputFocused={isInputFocused}
              hasValue={!!value?.length}
              labelOffsetLeft={labelOffsetLeft}
              labelOffsetTop={labelOffsetTop}
              labelOffsetTopFocused={labelOffsetTopFocused}
              showError={showError}
            >
              {labelText}
            </PlaceholderToLabelText>
          )}
          {type === 'password' && (
            <Icon
              data-testid='password-reveal-icon'
              onClick={() => setRevealPassword(!revealPassword)}
            >
              {revealPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
            </Icon>
          )}
        </InputLine>
      )}
      {showError && errorMessage && (
        <ErrorMessage>
          <ErrorIcon size='14px' />
          {errorMessage}
        </ErrorMessage>
      )}
    </InputContainer>
  )
}

const InputContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    fontFamily: theme.fonts['body'].family
  })
)

const PreTextContainer = styled.div<
  StyledHTMLElement & {
    isInputFocused: boolean
    showError: boolean
    hasValue: boolean
  },
  Required<Theme>
>(({ theme, isInputFocused, showError, hasValue }) => ({
  display: 'flex',
  gap: '12px',
  color:
    isInputFocused || hasValue
      ? '#000'
      : showError
        ? theme.colors['state'].error[5]
        : '#8B8B8B',
  border: '1px solid',
  borderRadius: '12px 0 0 12px',
  borderRight: 'none',
  borderColor: isInputFocused
    ? '#000'
    : showError
      ? theme.colors['state'].error[5]
      : '#8B8B8B'
}))

const Divider = styled.div<
  StyledHTMLElement & { isInputFocused: boolean; showError: boolean },
  Required<Theme>
>(({ theme, isInputFocused, showError }) => ({
  height: '50%',
  alignSelf: 'center',
  width: '1px',
  backgroundColor: isInputFocused
    ? '#000'
    : showError
      ? theme.colors['state'].error[5]
      : '#8B8B8B'
}))

const TextAreaWrapper = styled.div<StyledHTMLElement>(() => ({
  borderRadius: '12px',
  overflow: 'hidden'
}))

const TextAreaInput = styled.textarea<
  StyledHTMLElement & {
    showError: boolean
  },
  Required<Theme>
>(({ theme, showError }) => ({
  border: '1px solid',
  borderColor: showError ? theme.colors['state'].error[5] : '#8B8B8B',
  borderLeft: '',
  borderRadius: '12px',
  width: '100%',
  padding: '13px 15px',
  fontSize: '16px',
  height: 'auto',
  fontFamily: 'unset',
  lineHeight: '1.4em',
  fontWeight: 300,
  userSelect: 'text',
  resize: 'none',
  overflow: 'auto',
  minHeight: '110px',

  '&:focus': {
    borderColor: '#000',
    borderLeft: '',
    outline: 'none'
  },

  ...(thinScrollbar(theme) as any)
}))

const Icon = styled.div<StyledHTMLElement, Required<Theme>>(({ theme }) => ({
  position: 'absolute',
  top: '14px',
  right: '16px',
  cursor: 'pointer'
}))

export default FormInput
