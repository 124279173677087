import styled from '@emotion/styled'
import { Title } from '../commonStyles'

interface Props {
  type: 'Cart' | 'OrderSummary'
  reference?: string
}

const CartTitle = ({ type, reference }: Props) => {
  const title = type === 'Cart' ? 'Your Cart' : 'Your Order'

  return (
    <Content data-testid='cart-title'>
      <Title>{title}</Title>
      {reference && (
        <p>
          Order ID: <span>{reference}</span>
        </p>
      )}
    </Content>
  )
}

const Content = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  'p > span': {
    fontWeight: 500
  }
}))

export default CartTitle
