import React from 'react'
import { CategoryWithProducts } from '../Shop/Categories/types'
import { Products } from './Products'
import {
  List,
  CategoryTitle,
  ProductsContainer,
  CategoryDescription
} from './commonStyles'

interface CategoryGroupingProps {
  categories: CategoryWithProducts[]
}

export const CategoryGrouping = ({ categories }: CategoryGroupingProps) => {
  return (
    <>
      {categories.map((category) => {
        return (
          category && (
            <React.Fragment key={category.id || category.name}>
              {category.products.length > 0 && (
                <ProductsContainer
                  id={`product-category-${category.id || category.name}`}
                >
                  <CategoryTitle>{category.name}</CategoryTitle>
                  {category.description && (
                    <CategoryDescription>
                      {category.description}
                    </CategoryDescription>
                  )}
                  <List>
                    <Products
                      products={category.products}
                      productCategory={category.name}
                    />
                  </List>
                </ProductsContainer>
              )}
            </React.Fragment>
          )
        )
      })}
    </>
  )
}
