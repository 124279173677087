import React, { useEffect, useState, useRef, useMemo } from 'react'
import {
  ProductOptions,
  SelectedOption,
  CalorieData,
  ProductVariant,
  Product,
  AppliedModifiers,
  ProductModifierGroup,
  Allergens
} from '../Product/types'
import { CartPayload } from 'shop/components/Cart/types'
import { useShop, useReactRouter } from 'shop/hooks'
import { QUERY_GET_PRODUCT } from 'shop/client'
import { Container } from './index'
import { ControlsBar, OptionSelect } from '.'

import { matchVariantToSelectedOptions, totalV2 } from './utils'
import { getFulfillmentType } from 'shop/utils/fulfillment_type'
import { ProductImageURLs } from '../Product/imageUtils'
import {
  formatProductForTrackParamsV2,
  merchantGA4EcommTrackItemView,
  slerpGA4EcommTrackItemView
} from 'tracker/GA/ecommerce'
import ProductInfoLoader from './ProductInfoLoader'
import { trackViewContentCallback } from './tracking'
import ProductDetails from './Content/ProductDetails'
import { HeaderV2 } from './Header/Header'
import { MissingRequiredSection } from 'shop/types'
import { CategoryProduct } from '../Shop/Categories'
import {
  Canonical,
  ProductMeta,
  MetaDescription,
  PageTitle
} from 'shop/components'

interface ProductModalProps {
  product: CategoryProduct
  closeModal?: () => void
  setImageUrls: (arg0: ProductImageURLs[]) => void
  setProductName: (name: string) => void
  mobileScrollRef: React.RefObject<HTMLElement>
}

// Only used for single sets of Options
export type OptionsExtraPrices = {
  // e.g. ["Small": "0.00", "Medium": "1.00", "Large": "2.00"]
  [key: string]: string
}

const ProductInfo = (initProduct: ProductModalProps) => {
  const { setImageUrls, setProductName } = initProduct
  const { match, history } = useReactRouter()
  const { useShopClient, config, cartSession, merchant } = useShop()
  const client = useShopClient()
  const [loadingProduct, setLoadingProduct] = useState(true)
  const { cart } = cartSession
  const productScrollRef = useRef<HTMLDivElement>(null)

  // TODO: Move this route generator to a better and centralised location
  const getParentUrl = () => {
    if (!!cart?.store.slug) return `/store/${cart?.store.slug}`
    if (!!match.params['slug']) return `/store/${match.params['slug']}`
    return `/`
  }
  const parentUrl = getParentUrl()

  // temp state placeholder whilst we chip away at the Hasura product.
  const [productState, setProductState] = useState<Product>()

  const [isProductInStock, setIsProductInStock] = useState(true)
  const [isSelectedVariantInStock, setIsSelectedVariantInStock] = useState(true)

  const [isContentViewed, setIsContentViewed] = useState(false)

  const [allergens, setAllergens] = useState<
    Record<
      'productAllergens' | 'modifierAllergens' | 'variantAllergens',
      Allergens[]
    >
  >({
    productAllergens: [],
    variantAllergens: [],
    modifierAllergens: []
  })

  const [uniqueAllergens, setUniqueAllergens] = useState<Allergens[]>([])

  const [productOptions, setProductOptions] = useState<ProductOptions[]>([])
  const [selectedOptions, setSelectedOptions] = useState<SelectedOption[]>([])
  const [optionsExtraPrices, setOptionsExtraPrices] =
    useState<OptionsExtraPrices>()

  const [productVariants, setProductVariants] = useState<ProductVariant[]>([])
  const [selectedVariant, setSelectedVariant] = useState<ProductVariant>()
  const [variantQuantity, setVariantQuantity] = useState(1)

  const [productModifiers, setProductModifiers] = useState<
    ProductModifierGroup[]
  >([])
  const [appliedModifiers, setAppliedModifiers] = useState<AppliedModifiers>()
  const [calorieData, setCalorieData] = useState<CalorieData | undefined>()

  const [cartParams, setCartParams] = useState<CartPayload>()

  const [invalidModifierGroups, setInvalidModifierGroups] = useState<string[]>(
    []
  )
  const [hasFailedAddToCart, setHasFailedAddToCart] = useState<boolean>(false)
  const [missingRequiredSections, setMissingRequiredSections] = useState<
    MissingRequiredSection[]
  >([])
  const [hasError, setHasError] = useState<boolean>(false)

  const fulfillmentType = cart
    ? getFulfillmentType({
        fulfillment_type: cart.fulfillment_type,
        metadata: cart.metadata
      })
    : null

  /** CALCULATE SELECTED VARIANT */
  // Filter down possible variants based upon options selected AND set variant inStock state
  useEffect(() => {
    // no product - return
    if (!productState?.id) return
    // not enough options selected - return
    if (productOptions.length !== selectedOptions.length) return

    const matchedVariant = matchVariantToSelectedOptions(
      productVariants,
      selectedOptions
    )
    if (matchedVariant) {
      setSelectedVariant(matchedVariant)
      setIsSelectedVariantInStock(matchedVariant.inStock)

      const variantAllergens = Object.values(
        matchedVariant.options_allergens
      ).reduce(
        (acc: Array<Allergens>, curr: Record<string, Array<Allergens>>) => {
          const allergens = Object.values(curr).flatMap((allergens) =>
            (allergens ?? []).map((allergen) => allergen)
          )

          return [...new Set([...acc, ...allergens])]
        },
        []
      )

      setAllergens((prev) => ({
        ...prev,
        variantAllergens
      }))
    } else {
      // unable to find variant based upon selection.
      // set it to "Out of Stock" just to be sure
      setSelectedVariant(undefined)
      setIsSelectedVariantInStock(false)
    }

    // Only want to change when a user selects an option
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions])

  /** Keep updating the total product price including variants, modifers, quantity */
  const calculatedTotal = useMemo(() => {
    const startPrice = productState?.startPrice.basePrice

    const initialPrice = selectedVariant?.price.basePrice || startPrice || 0

    const total = totalV2(
      Number(initialPrice),
      variantQuantity,
      appliedModifiers
    )
    return total
    // Only change on the following: the product, the selected variant, quanitity, applied modifiers
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productState?.id, selectedVariant?.id, variantQuantity, appliedModifiers])

  /** Keep updating the payload for Add to Cart */
  useEffect(() => {
    let payload: CartPayload = {
      variantId: '',
      amount: calculatedTotal,
      quantity: variantQuantity,
      variantVat: 0,
      variantPrice: 0,
      appliedModifiers: appliedModifiers ? appliedModifiers : {}
    }
    if (selectedVariant) {
      payload = {
        ...payload,
        variantId: selectedVariant.id,
        variantPrice: Number(selectedVariant.price.basePrice)
      }
      setCartParams(payload)
    } else if (productState) {
      payload = {
        ...payload,
        variantId: productState.defaultVariantId,
        variantPrice: Number(productState.minPrice.basePrice)
      }
      setCartParams(payload)
    }
    // Only change on the following: the product, the selected variant, quantity, applied modifiers, total
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productState?.id,
    selectedVariant?.id,
    variantQuantity,
    appliedModifiers,
    calculatedTotal
  ])

  /** QUERY PRODUCT DATA */
  useEffect(
    () => {
      if (cartSession?.isCartLoading) return
      // if we have cartId but no cart, wait for cart to load.
      if (cartSession.cartId && !cartSession.cart) return
      const slug = initProduct?.product?.slug || match.params['product']

      const storeSlug = cartSession.cart
        ? cartSession.cart.store.slug
        : match.params['slug']
          ? match.params['slug']
          : config.store

      setLoadingProduct(true)
      const dateToSend = cart
        ? cart.deliver_by
          ? `${cart.deliver_by}Z`
          : null
        : undefined
      // NEW ELIXIR QUERY
      client
        .query({
          query: QUERY_GET_PRODUCT,
          variables: {
            productId: initProduct?.product?.id
              ? initProduct.product.id
              : undefined,
            productSlug: slug,
            fulfillmentType: fulfillmentType?.toUpperCase(),
            fulfillmentDatetime: dateToSend,
            storeSlug
          }
        })
        .then(({ data }) => {
          const product: Product = data?.getProduct
          if (!product) return undefined
          const {
            image,
            additionalImages,
            inStock,
            variants,
            name,
            calorieData: logicCalorieData,
            options,
            modifierGroups
          } = product

          setAllergens((prev) => ({
            ...prev,
            productAllergens: product?.allergens ?? []
          }))

          setImageUrls([...[image], ...additionalImages])
          setIsProductInStock(inStock)
          // if no variants, use generic product values which represent the default variant
          if (variants?.length === 0) {
            setIsSelectedVariantInStock(inStock)
          }
          setProductVariants(variants)
          setProductOptions(options || [])
          setProductModifiers(modifierGroups)
          setProductName(name)

          // only calculate option extra pricing if there is only 1 set of Options.
          if (options?.length === 1) {
            let optionsPrices: OptionsExtraPrices = {}
            options[0].values.forEach((optionValue) => {
              const matchedVariant = variants.find((variant) =>
                variant.options.find((variantOption) =>
                  variantOption.values.find(
                    (variantOptionValue) => variantOptionValue === optionValue
                  )
                )
              )
              optionsPrices = {
                ...optionsPrices,
                [optionValue]: matchedVariant?.extraPrice.basePrice || '0.00'
              }
            })
            setOptionsExtraPrices(optionsPrices)
          }
          // TODO: CLEANUP - convert type to use "caloriesPerServing"
          if (logicCalorieData)
            setCalorieData({
              calories_per_serving: logicCalorieData.caloriesPerServing
            })
          setProductState(product)

          return product
        })
        .then((passedProduct?: Product) => {
          if (!passedProduct) return
          if (!isContentViewed) {
            setIsContentViewed(true)
            const formattedProduct = formatProductForTrackParamsV2({
              product: passedProduct,
              storeName: cart?.store.name || '',
              merchantName: merchant?.name
            })
            slerpGA4EcommTrackItemView(formattedProduct)
            merchantGA4EcommTrackItemView(formattedProduct)
            trackViewContentCallback({
              product_id: passedProduct.id,
              name: passedProduct.name,
              category: 'Product',
              currency: 'GBP',
              image_url: passedProduct.image.standard,
              price: Number(passedProduct.minPrice.basePrice)
            })
          }
          setHasError(false)
        })
        .catch((error) => {
          console.error(error)
          setHasError(true)
        })
        .finally(() => setLoadingProduct(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      match.params['slug'],
      match.params['product'],
      config.domain,
      cart?.id,
      cartSession?.isCartLoading
    ]
  )

  useEffect(() => {
    if (!appliedModifiers) return

    // extracts applied modifier ids
    const appliedModifierIds = Object.values(appliedModifiers)
      .flatMap((modifierGroup) => Object.values(modifierGroup))
      .map((modifier) => modifier.modifier_id)

    // gets involved modifiers
    const involvedModifiers = productModifiers
      .flatMap((modifierGroup) => modifierGroup.modifiers)
      .filter((modifier) => appliedModifierIds.includes(modifier.id))

    // gets involved modifiers' allergens
    const modifierAllergens = involvedModifiers.flatMap((modifier) =>
      (modifier.allergens ?? []).map((allergen) => allergen)
    )

    setAllergens((prev) => ({
      ...prev,
      modifierAllergens
    }))
  }, [appliedModifiers])

  useEffect(() => {
    const { productAllergens, variantAllergens, modifierAllergens } = allergens

    const removeDuplicates = Array.from(
      new Set([...productAllergens, ...variantAllergens, ...modifierAllergens])
    )

    setUniqueAllergens(removeDuplicates)
  }, [allergens])

  /** Navigate back to Product List if there is an error. */
  useEffect(() => {
    if (hasError) {
      history.push(parentUrl, { hasError })
    }
  }, [hasError, parentUrl, history])

  const OptionSelectComponent = productState ? (
    <OptionSelect
      product={productState}
      modifiers={productModifiers}
      isProductInStock={isProductInStock}
      productOptions={productOptions}
      selectedOptions={selectedOptions}
      setSelectedOptions={setSelectedOptions}
      invalidModifierGroups={invalidModifierGroups}
      setInvalidModifierGroups={setInvalidModifierGroups}
      missingRequiredSections={missingRequiredSections}
      setAppliedModifiers={setAppliedModifiers}
      selectedVariant={selectedVariant}
      optionsExtraPrices={optionsExtraPrices}
    />
  ) : (
    <></>
  )

  const noOptionsOrModifiers: boolean = useMemo(() => {
    return !!(productOptions?.length === 0 && productModifiers?.length === 0)
  }, [productOptions, productModifiers])

  return (
    <Container ref={productScrollRef}>
      {loadingProduct ? (
        <ProductInfoLoader />
      ) : (
        <>
          <Canonical url={window.location.origin + window.location.pathname} />
          <MetaDescription
            seoDescription={productState?.seoDescription}
            type='product'
          />
          <PageTitle productName={productState?.name} />
          <ProductMeta product={productState} />
          <HeaderV2
            name={productState?.name || initProduct?.product?.name || ''}
            parentUrl={parentUrl}
          />
          <ProductDetails
            description={productState?.description}
            calorieData={calorieData?.calories_per_serving}
            allergens={uniqueAllergens}
            forceShowDescription={noOptionsOrModifiers}
          />
          {OptionSelectComponent}
        </>
      )}
      <ControlsBar
        product={productState}
        productVariants={productVariants}
        modifiers={productModifiers}
        loadingProduct={loadingProduct}
        isProductInStock={isProductInStock}
        isSelectedVariantInStock={isSelectedVariantInStock}
        parentUrl={parentUrl}
        productScrollRef={productScrollRef}
        productOptions={productOptions || []}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        cartParams={cartParams}
        setCartParams={setCartParams}
        invalidModifierGroups={invalidModifierGroups}
        setInvalidModifierGroups={setInvalidModifierGroups}
        hasFailedAddToCart={hasFailedAddToCart}
        setHasFailedAddToCart={setHasFailedAddToCart}
        missingRequiredSections={missingRequiredSections}
        setMissingRequiredSections={setMissingRequiredSections}
        selectedVariant={selectedVariant}
        variantQuantity={variantQuantity}
        setVariantQuantity={setVariantQuantity}
      />
    </Container>
  )
}

export default ProductInfo
