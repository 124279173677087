import styled from '@emotion/styled'

interface Props {
  type?: string
  value?: string
  inputName?: string
  icon?: React.ReactNode
  name?: string
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
}

const Input = styled.input((props: any) => ({
  outline: 0,
  border: 0,
  borderBottom: `1px solid ${props.theme.colors.lineColor}`,
  width: '100%',
  height: '40px',
  fontSize: props.theme.fontSizes[1],
  fontWeight: props.theme.fontWeights.light,
  '&.-hasIcon': {
    paddingLeft: '24px'
  }
}))

const Container = styled.div(() => ({
  position: 'relative'
}))

const IconContainer = styled.div(() => ({
  position: 'absolute',
  left: 0,
  top: '50%',
  bottom: 0,
  transform: 'translateY(-50%)'
}))

const LinedInputField = ({
  type,
  value,
  inputName,
  icon,
  name,
  inputProps
}: Props) => {
  return (
    <Container>
      <IconContainer>{icon && icon}</IconContainer>
      <Input
        type={type}
        // defaultValue={value}
        className={icon ? '-hasIcon' : ''}
        data-testid={`${name || ''}Field`}
        onBeforeInput={inputProps && inputProps.onFocus}
        {...inputProps}
      />
    </Container>
  )
}

LinedInputField.defaultProps = {
  type: 'text',
  value: 'Today (09/07/2019)'
}

export default LinedInputField
