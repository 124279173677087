import Heading, { StyledHeading } from './Heading'
export { default as Hero } from './Hero'
export { default as Banner } from './Banner'
export { default as Footer } from './Footer'
export { default as AddressAutoComplete } from './AddressAutoComplete'
export * from './Controls'
export * from './NavBar'
export * from './Product'
export * from './Landing'
export * from './DineIn'
// export * from './Debugger'
export * from './Variants'
export * from './Modifiers'
export * from './Checkout'
export * from './DeliverySummary'
export * from './PromoBanner'
export * from './Metadata'
export * from './SEO'
export { Heading, StyledHeading }
