import styled from '@emotion/styled'

interface Props {
  icon: React.ReactNode
  text: React.ReactNode
}

const Container = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  '> svg': {
    opacity: 0.9,
    marginRight: '4px'
  }
}))

const Info = styled.span(({ theme }: any) => ({
  display: 'inline-block'
}))

const OrderDetail = ({ icon, text }: Props) => {
  return (
    <Container>
      {icon}
      <Info>{text}</Info>
    </Container>
  )
}

export default OrderDetail
