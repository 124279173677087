import { useCallback, useEffect, useRef, useState } from 'react'
import { throttle } from 'lodash'

import styled from '@emotion/styled'
import { ThemedButton } from '../common'
import { useShopPage } from 'shop/hooks'

export const ViewMenuHeader = ({ children }: any) => {
  const { handleAutoScrolling } = useShopPage()

  const header = useRef<null | HTMLDivElement>(null)
  const [isActive, setIsActive] = useState<boolean>(false)

  const handleScroll = useCallback(() => {
    if (!header?.current) {
      return
    }

    const offsetY = 120
    const marginTop = header.current.getBoundingClientRect().top - offsetY

    if (!isActive && marginTop < 0) {
      setIsActive(true)
      return
    }

    if (isActive && marginTop > 0) {
      setIsActive(false)
    }
  }, [isActive])

  const handleClick = useCallback(() => {
    const element = document.getElementById('shop-body-root')

    if (!element) {
      return
    }

    const scrollPositon =
      element.getBoundingClientRect().top + window.pageYOffset

    handleAutoScrolling()
    window.scrollTo({ top: scrollPositon, behavior: 'smooth' })
  }, [handleAutoScrolling])

  useEffect(() => {
    const onScroll = throttle(handleScroll, 300)

    document.addEventListener('scroll', onScroll)

    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [handleScroll])

  return (
    <Root ref={header}>
      <h2>{children}</h2>
      {isActive && <Button onClick={handleClick}>View Menu</Button>}
    </Root>
  )
}

const Root = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: '43px'
}))

const Button = styled(ThemedButton)(({ theme }: any) => ({
  minWidth: '114px',

  [theme.mediaQueries.viewport7]: {
    minWidth: '196px'
  }
}))
