import { useState, useEffect } from 'react'
import { PasswordErrors } from 'shop/components/Account/types'

export const usePassword = (password: string) => {
  const [passwordError, setPasswordError] = useState<PasswordErrors | null>(
    null
  )

  useEffect(() => {
    if (!password.length) return setPasswordError('NO_PASSWORD')
    if (password.length >= 1 && password.length < 8)
      return setPasswordError('PASSWORD_TOO_SHORT')
    return setPasswordError(null)
  }, [password])

  return { passwordError }
}
