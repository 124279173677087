import { CustomerDetails } from './Network'
import { CustomerDetailsParams, DomesticAddress, Price } from 'shop/types/cart'

export type FormFieldsV2 = {
  customerDetails: {
    firstName: string
    lastName: string
    email: string
    contactNumber: string
    contactNumPrefix: string
  }
  recipientDetails: {
    firstName: string
    lastName: string
    contactNumber: string
    contactNumPrefix: string
  }
  forSomeoneElse: boolean
  orderNotes: string
  orderNotesEnabled: boolean
  deliveryAddress: DomesticAddress
  dropoffNotes: string
  marketingOptIn: boolean
  customFieldValue: string
}

export interface CheckoutFormV2 {
  customerDetails: CustomerDetailsCheckoutV2
  recipientDetails: Omit<CustomerDetailsCheckoutV2, 'email'>
  forSomeoneElse: boolean
  orderNotesEnabled: boolean
  orderNotes: string
  deliveryAddress: DomesticAddress
  dropoffNotes: string
  customFieldValue: string
  marketingOptIn: boolean
}

interface CustomerDetailsCheckoutV2 extends CustomerDetailsParams {
  contactNumPrefix: string
}

export const ADDRESS_DEFAULTS_V2: DomesticAddress = {
  lineOne: '',
  lineTwo: '',
  city: '',
  zip: ''
}

export const FORM_DEFAULTS_V2: CheckoutFormV2 = {
  customerDetails: {
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    contactNumPrefix: ''
  },
  recipientDetails: {
    firstName: '',
    lastName: '',
    contactNumber: '',
    contactNumPrefix: ''
  },
  forSomeoneElse: false,
  orderNotesEnabled: false,
  orderNotes: '',
  deliveryAddress: ADDRESS_DEFAULTS_V2,
  dropoffNotes: '',
  customFieldValue: '',
  marketingOptIn: false
}

export type FormFields = {
  customer_details: {
    first_name: string
    last_name: string
    email: string
    contact_num: string
    contact_num_prefix: string
  }
  recipient_details: {
    first_name: string
    last_name: string
    contact_num: string
    contact_num_prefix: string
  }
  for_someone_else: boolean
  order_notes: string
  order_notes_enabled: boolean
  gift_wrapped: boolean
  gift_wrap_message: string
  gift_wrap_placeholder: string
  delivery_address: DeliveryAddress
  dropoff_notes: string
  details_disclosed: boolean
  custom_field_value: string
}

export type SplitBillPayor = {
  name: string
  contactNum: string
  amount: number
  email?: string
}

export type DeliveryAddress = {
  line_1: string
  line_2: string
  city: string
  zip: string
}

export type Discount = {
  discount_code: string
  discount_id: string
  discount_amount: number
  discount_target:
    | 'all_charges'
    | 'all_products'
    | 'delivery_fee'
    | 'product_category'
    | 'subtotal_delivery_fee'
    | null
  discount_trigger?: 'manual' | 'automatic' | null
  discount_value: number
  discount_type: 'percentage' | 'fixed' | null
  subtotal_discount_amount: number
  store_fee_info: Price | null
}

// such is life 🤷
export type TimeSlot = {
  datetime: string
  range: string
  value: string
}

export interface CartValidation extends TimeSlotValidation, DiscountValidation {
  unavailableVariantIds: string[]
  valid: boolean
}

export type DiscountValidation = {
  discountWarningsNew: {
    validationType: string
    minimumValue?: string
  }[]
}

export type TimeSlotValidation = {
  isStoreOpen: boolean
  isPreorder: boolean
  nextAvailable?: TimeSlot
}

export type RedeemableReward = {
  id: string
  reward: Reward
}

export type Reward = {
  description: string
  name: string
  loyalty_card: LoyaltyCard
}

export type LoyaltyCard = {
  id: string
  name: string
  image: string
  description: string
}

export type QuoteResult = {
  data?: {
    quoteAndUpdateAddress: {
      delivery_charge: number
      id: string
      minimum_order_value: number
      deliveryChargeInfo: {
        deliveryCharge: string
        deliveryChargeBeforeDiscount: string
        deliveryChargeReduction: string
        deliveryChargeReductionReason: string
        deliveryPricingBand: {
          lowerThreshold: string
          percentageDiscount: number
          upperThreshold: string
        }
      }
    }
  }
}

export type DeliveryReduction = {
  deliveryCharge: number
  deliveryChargeBeforeDiscount: number
  deliveryChargeReduction: number
  deliveryChargeReductionReason: string
  deliveryPricingBand: {
    lowerThreshold: string
    percentageDiscount: number
    upperThreshold: string
  } | null
}

export interface CheckoutForm {
  customer_details: CustomerDetails
  recipient_details: Omit<CustomerDetails, 'email'>
  for_someone_else: boolean
  order_notes_enabled: boolean
  order_notes: string
  gift_wrapped: boolean
  gift_wrap_message: string
  gift_wrap_placeholder: string
  delivery_address: DeliveryAddress
  dropoff_notes: string
  custom_field_value: string
  details_disclosed: boolean
}
