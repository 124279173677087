import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import {
  IoWarningOutline,
  IoAlertCircleOutline,
  IoCheckmarkCircleOutline
} from 'react-icons/io5'

const stateColors = {
  default: '#FAAD14',
  error: '#CF1322',
  success: '#389E0D'
}

const stateIcons = {
  default: <IoWarningOutline />,
  error: <IoAlertCircleOutline />,
  success: <IoCheckmarkCircleOutline />
}

type StateLevels = 'default' | 'error' | 'success'

const RequiredLabel = ({ state }: { state: StateLevels }) => {
  return (
    <Label state={state}>
      {stateIcons[state]}
      Required
    </Label>
  )
}

const Label = styled.div<
  StyledHTMLElement & { state: StateLevels },
  Required<Theme>
>(({ theme, state }) => ({
  display: 'flex',
  alignItems: 'center',
  // hexadecimal 14 -> opcacity: 8%
  backgroundColor: `${stateColors[state]}14`,
  color: stateColors[state],
  gap: '8px',
  borderRadius: '12px',
  padding: '4px 8px',
  lineHeight: '22px',
  fontWeight: 600,

  '> svg': {
    fontSize: '18px'
  }
}))

export default RequiredLabel
