import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'

const CheckboxInput = styled.input<
  StyledHTMLElement & { disabled: boolean; inputPressed?: boolean },
  Required<Theme>
>(({ theme, disabled, inputPressed }) => ({
  cursor: !disabled ? 'pointer' : 'not-allowed',

  '&[type="checkbox"]': {
    /* Start of removing native styles */
    webkitAppearance: 'none',
    appearance: 'none',
    // For iOS < 15 to remove gradient background
    backgroundColor: '#fff',
    margin: 0,
    /* End of removing native styles */

    WebkitTapHighlightColor: 'transparent',

    font: 'inherit',
    color: '#fff',
    width: '18px',
    height: '18px',
    border: `2px solid ${theme.colors?.['primary']}`,
    opacity: !disabled ? 1 : 0.5,
    borderRadius: '4px',
    display: 'grid',
    placeContent: 'center',

    '&:checked': {
      backgroundColor: theme.colors?.['primary'],
      '&::before': {
        transform: 'scale(1)'
      }
    },

    // no hover style for Mobile
    [theme.mediaQueries.viewport6]: {
      ...(disabled
        ? {}
        : {
            '&:hover': {
              ':not(:checked)': {
                backgroundColor: '#F0F0F0'
              },
              '&::after': {
                opacity: 1
              }
            }
          })
    },

    // inner shadow for Checkbox selection
    '&::before': {
      content: '""',
      width: '24px',
      height: '24px',
      borderRadius: '4px',
      transform: 'scale(0)',
      transition: '120ms transform ease-in-out',
      boxShadow: `inset 12px 12px #fff`,
      backgroundColor: '#fff',
      clipPath:
        'path("M10.5 16.5841L6.5 12.5841L7.9 11.1841L10.5 13.7841L17.1 7.18408L18.5 8.58408L10.5 16.5841Z")',
      opacity: !disabled ? 1 : 0.5
    },

    // outer shadow for hover
    '&::after': {
      content: '""',
      // onClick stylings
      opacity: inputPressed ? 1 : 0,
      width: '40px',
      height: '40px',
      transform: 'translate(-13px, -13px)',
      borderRadius: '50%',
      // onClick stylings
      backgroundColor: inputPressed ? '#D9D9D9' : '#F0F0F0',
      position: 'absolute',
      zIndex: -1
    }
  }
}))

export default CheckboxInput
