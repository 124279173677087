import styled from '@emotion/styled'
import { StyledHTMLElement } from 'shop/theme/types'
import CenterModal from '../Modal/CenterModal'
import { Button } from 'shop/components'
import { slideUp } from '../Shop/commonStyles'

type Props = {
  hasError: boolean
  onClose: () => void
}

const ProductErrorModal = ({ hasError, onClose }: Props) => {
  if (!hasError) return <></>
  return (
    <CenterModal handleCloseModal={onClose}>
      <Container>
        <Header>Unavailable product</Header>
        <p>
          Unfortunately, the product you have chosen is not available on this
          store.
        </p>
        <Button onClick={onClose}>Back</Button>
      </Container>
    </CenterModal>
  )
}

const Container = styled.div<StyledHTMLElement>(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '32px',
  gap: '24px',
  justifyContent: 'center',
  animation: `${slideUp} 100ms ease-out`,
  // TODO: standardise CenterModal close button to new designs.
  // add margin to have header text lower than the button
  marginTop: '20px',

  '& >button': {
    borderRadius: '12px'
  }
}))

const Header = styled.h1<StyledHTMLElement>(() => ({
  fontSize: '24px',
  alignSelf: 'left'
}))

export default ProductErrorModal
